import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AdminLayout from "pages/main/layout/AdminLayout";
import { useDispatch } from "react-redux";
import LoadingComponent from "components/Loading";
import { getCourses } from "store/main/coursesSlice";
import InsCourseTable from "./InsCourseTable";
import InsCourseDialog from "./InsCourseDialog";
import { getCategories } from "store/main/categoriesSlice";
import { getLessons } from "store/main/lessonsSlice";
import { getInstruments } from "store/main/instrumentsSlice";
import { getinstructors } from "store/main/instructorsSlice";
import { getinstructorCourses } from "store/main/instructorCoursesSlice";
import { removeinstructorCourses } from "store/main/instructorCoursesSlice";
import ConfirmDialog from "pages/confirmation/ConfirmDialog";

const AdminInsCoursesDashboard = (props) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  const [instructorsLoading, setinstructorsLoading] = useState(true);
  const [instrumentLoading, setInstrumentLoading] = useState();

  useEffect(() => {
    dispatch(getinstructorCourses()).then(() => setDataLoading(false));  
    dispatch(getinstructors()).then(() => setinstructorsLoading(false));
    dispatch(getInstruments()).then(() => setInstrumentLoading(false));
  }, []);

  if (dataLoading  || instructorsLoading || instrumentLoading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <InsCourseTable />
      <InsCourseDialog />
      <ConfirmDialog handleDeleteData={removeinstructorCourses} />
    </AdminLayout>
  );
};


export default AdminInsCoursesDashboard;
