/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { useForm } from "features/hooks";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "store/auth/authSlice";
import { useEffect, useRef, useState } from "react";
import { Alert, FormControl, FormHelperText, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar } from "@mui/material";
import PasswordPopper from "components/PasswordPopper/PasswordPopper";

const defaultFormState = {
  currentPassword: "",
  newPassword: "",
  newPasswordConfirm: "",
};

function ChangePassword() {
  const userName = useSelector((state) => state.auth.userName);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { form, handleChange, setForm } = useForm(defaultFormState);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      ...form,
      userName,
    }
    console.log(form);
    dispatch(changePassword(data)).then((params) => {
      console.log(params);
      try {
        if (params.payload.success) {
          setPasswordChanged({
            response: true,
            status: "success",
            message: "password_changed_success",
          });
        }
      } catch (error) {
        console.log(error);
        setPasswordChanged({
          response: true,
          status: "error",
          message: "password_changed_failed",
        });
      }
    });
    setForm(defaultFormState);
  }

  const [passwordChanged, setPasswordChanged] = useState({
    response: false,
    status: "success",
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setPasswordChanged({
      response: false,
      status: "success",
      message: "",
    });

  };

  const passwordRequirements = [
    { name: "min_characters", regex: /^.{6,}$/ },
    { name: "special_characters", regex: /[-!$%^&*()_+|~=`{}\[\]:";'<>?,./\\]/ },
    { name: "one_number", regex: /\d/ },
    { name: "one_letter", regex: /[a-zA-Z]/ }
  ];



  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const canBeSubmitted = () => {
    return (
      passwordRequirements[0].regex.test(form.newPassword) &&
      passwordRequirements[1].regex.test(form.newPassword) &&
      passwordRequirements[2].regex.test(form.newPassword) &&
      passwordRequirements[3].regex.test(form.newPassword) &&
      form.newPassword === form.newPasswordConfirm
    )
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const [passwordOpen, setPasswordOpen] = useState(false);

  const passwordRef = useRef(null);


  useEffect(() => {
    // Kontrolleri gerçekleştir
    const areRequirementsMet = passwordRequirements.every(item => item.regex.test(form.newPassword));
    const passwordsMatch = form.newPassword.length > 0 && form.newPassword === form.newPasswordConfirm;

    // Gereksinimlerin tamamı karşılanırsa ve parolalar eşleşiyorsa onClose fonksiyonunu çağır
    if (form.newPassword.length === 0) {
      setPasswordOpen(false);
      setAnchorEl(null);
    }
    else if (areRequirementsMet && passwordsMatch) {
      setPasswordOpen(false);
      onClose();
    }
    else {
      setPasswordOpen(true);
      setAnchorEl(passwordRef.current);
    }
  }, [form.newPassword, form.newPasswordConfirm])

  const onClose = () => {
    setAnchorEl(null);
    setPasswordOpen(false);
  }

  return (
    <Card id="change-password">
      <Snackbar
        open={passwordChanged.response}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={handleClose} severity={passwordChanged.status} sx={{ width: '100%' }}>
          {t(passwordChanged.message)}
        </Alert>
      </Snackbar>
      <MDBox p={3}>
        <MDTypography variant="h5">{t("change_password")}</MDTypography>
      </MDBox>
      <MDBox component="form" role={"form"} pb={3} px={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>

            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="currentPassword">{t("current_password")}</InputLabel>
              <OutlinedInput
                id="currentPassword"
                name="currentPassword"
                value={form.currentPassword}
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("current_password")}
                
              />
              <FormHelperText>
                {!form.currentPassword && t("enterCurrentPassword")}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="new_password">{t("new_password")}</InputLabel>
              <OutlinedInput
                id="newPassword"
                name="newPassword"
                value={form.newPassword}
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("new_password")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="newPasswordConfirm">{t("confirm_new_password")}</InputLabel>
              <OutlinedInput
                ref={passwordRef}
                id="newPasswordConfirm"
                name="newPasswordConfirm"
                value={form.newPasswordConfirm}
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("confirm_new_password")}
              />
            </FormControl>


          </Grid>

          <Grid item xs={12} alignItems={"flex-end"} display={"flex"}>
            <MDBox ml="auto">
              <MDButton disabled={!canBeSubmitted()} onClick={handleSubmit} variant="gradient" color="info" size="small">
                {t("change_password")}
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <PasswordPopper anchorEl={anchorEl} onOpen={passwordOpen} onClose={onClose} password={form.newPassword} confirmPassword={form.newPasswordConfirm} />
      </MDBox>
    </Card>
  );
}

export default ChangePassword;
