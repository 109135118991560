import React, { useEffect, useLayoutEffect, useState } from 'react'
import LaSolistVideoPlayer from './LaSolistVideoPlayer'
import LaSolistNotePlayer from './LaSolistNotePlayer'
import "./LaSolistPlayer.css"
import { Grid } from '@mui/material';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { resetToInitialState } from 'store/main/videoPlayerSlice';
import MDBox from 'components/MDBox';
import { PlayerProvider } from 'hooks/PlayerProvider';
import { selectsongListsById } from 'store/main/songListsSlice';
import VideoControls from 'components/Controls';
import LoadingComponent from 'components/Loading';
import TrialBlocker from 'components/TrialBlocker';

function LaSolistPlayerSite() {
  const handle = useFullScreenHandle();
  const dispatch = useDispatch();
  const [videoAndNoteLoaded, setVideoAndNoteLoaded] = useState(false);

  const { videoData, draggableImageData } = useSelector((state) => state.videoPlayer);

  useEffect(() => {
    dispatch(resetToInitialState());
  }, [dispatch]);

  useLayoutEffect(() => {
    setTimeout(() => {
      setVideoAndNoteLoaded(true);
    }, 500)
  }, []);

  return (
    <PlayerProvider selectSongState={selectsongListsById}>
      {/* {!videoAndNoteLoaded && <LoadingComponent backgroundColor={"#32393D"} />} */}
      <FullScreen handle={handle} className={"mainContainer"}>
        <MDBox sx={{ width: "100%", background: "#32393D", display: "flex", flexDirection: "column" }}>
          <Grid container className={"GridContainer"}>
            <Grid item xs={12} className={"GridItem Player_Steps"}>
              <LaSolistVideoPlayer />
            </Grid>
          </Grid>

        </MDBox>

        <VideoControls
          fullScreenHandler={handle}
          selectSongState={selectsongListsById}
        />
        <LaSolistNotePlayer />
      </FullScreen>
      <TrialBlocker />
    </PlayerProvider>
  )
}

export default LaSolistPlayerSite