import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UserInformation from "./components/UserInformation";
import PricingPlanInformation from "./components/PricingPlanInformation";
import AddressInformation from "./components/AddressInformation";
import { addSubscription } from "store/main/subscriptionsSlice";
import { updateSubscription } from "store/main/subscriptionsSlice";
import { closeEditSubscriptionDialog } from "store/main/subscriptionsSlice";
import { closeNewSubscriptionDialog } from "store/main/subscriptionsSlice";
const defaultFormState = {
  composerId: 0,
  composerName: "",
  status: "active",
  url: "",
  pricingPlanInformation: {
    iyzicoPricingPlanReference: 0,
    price: 0,
    paymentInterval: "",
    trialPeriodDays: 0,
    feature1: false,
    feature2: false,
    feature3: false,
    shouldSong: false,
    shouldLesson: false,
    shouldFeedback: false,
  },
  userInformation: {
    name: "",
    surname: "",
    identitynumber: "",
    email: "",
    gsmNumber: "",
  },
  addressInformation: {
    address: "",
    city: "",
    country: "",
    contactName: "",
  },
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
];

function SubscriptionsDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const subscriptionDialog = useSelector(
    ({ subscriptions }) => subscriptions.subscriptionDialog
  );

  const { form, handleChange, setForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });

  const [pricingPlanValue, setPricingPlanValue] = useState({
    id: "",
    name: "",
  })

  const [userValue, setUserValue] = useState({
    id: "",
    userName: "",
  })
  const initDialog = useCallback(async () => {
    /**
     * Dialog type: 'edit'
     */
    if (subscriptionDialog.type === "edit" && subscriptionDialog.data) {
      setForm({ ...subscriptionDialog.data });
      let status = statusOptions.find(
        (f) => f.name === subscriptionDialog.data.status
      );
      setStatusValue(status);
    }

    /**
     * Dialog type: 'new'
     */
    if (subscriptionDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...subscriptionDialog.data,
      });
      let status = statusOptions.find(
        (f) => f.name === defaultFormState.status
      );
      setStatusValue(status);
    }
  }, [subscriptionDialog.data, subscriptionDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (subscriptionDialog.props.open) {
      initDialog();
    }
  }, [subscriptionDialog.props.open, initDialog]);

  function closeComposeDialog() {
    setPricingPlanValue({
      id: "",
      name: "",
    });
    setExpanded(false);
    return subscriptionDialog.type === "edit"
      ? dispatch(closeEditSubscriptionDialog())
      : dispatch(closeNewSubscriptionDialog());
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (subscriptionDialog.type === "new") {
      let data = {
        ...form,
        status: statusValues.id,
      };
      dispatch(addSubscription(data));
    } else {
      let data = {
        ...form,
        status: statusValues.id,
      };
      dispatch(updateSubscription(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    return form.composerName.length > 0;
  }

  const [expanded, setExpanded] = useState(false);

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...subscriptionDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">{t("subscriptionsInfo")}</DialogTitle>
      <DialogContent>
        <PricingPlanInformation
          expanded={expanded}
          handleExpand={handleExpand}
          pricingPlanValue={pricingPlanValue}
          setPricingPlanValue={setPricingPlanValue}
          form={form}
          handleChange={handleChange}
          setForm={setForm}
        />

        <UserInformation
          expanded={expanded}
          handleExpand={handleExpand}
          userValue={userValue}
          setUserValue={setUserValue}
          form={form}
          setForm={setForm}
          handleChange={handleChange}
        />
        <AddressInformation
          expanded={expanded}
          handleExpand={handleExpand}
          form={form}
          handleChange={handleChange}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SubscriptionsDialog;
