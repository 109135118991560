/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { upgradeSubscription } from "store/main/subscriptionsSlice";
import { checkSubscription } from "store/payment/paymentsSlice";

function PricingCard({ setCheckout, payPlatform, isTrial, color, badge, price, specifications, button, shadow, subscriptionReferenceCode, pricingPlanId, pricingPlanReferenceCode, currentPlan }) {
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <MDBox key={label} display="flex" alignItems="center" p={1}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        mr={2}
        mt={-0.125}
      >
        <MDTypography
          variant="body1"
          color={color === "white" ? "text" : "white"}
          sx={{ lineHeight: 0 }}
        >
          <Icon>{includes ? "done" : "remove"}</Icon>
        </MDTypography>
      </MDBox>
      <MDTypography
        variant="body2"
        color={color === "white" ? "text" : "white"}
        fontWeight="regular"
      >
        {label}
      </MDTypography>
    </MDBox>
  ));

  const dispatch = useDispatch();

  const { userName } = useSelector((state) => state.auth);

  const handleUpgrade = () => {
    let data = {
      subscriptionReferenceCode,
      newPricingPlanReferenceCode: pricingPlanReferenceCode,
      pricingPlanId,
      userName,
    }
    if (payPlatform === "iyzico") {
      dispatch(upgradeSubscription(data)).then((params) => {
        try {
          console.log(params.payload)
          dispatch(checkSubscription({ userName }));
        } catch (error) {
          console.log(error);
        }
      })
    }
    else {
      setCheckout({
        isOpen: true,
        data,
        feedback: {
          response: false,
          status: "success",
          message: "",
        },
      });
    }
  }

  return (
    <Card sx={{ boxShadow: ({ boxShadows: { lg } }) => (shadow ? lg : "none") }}>
      <MDBox
        bgColor={color}
        variant={color === "white" ? "contained" : "gradient"}
        borderRadius="xl"
      >
        <MDBox
          bgColor={badge.color}
          width="max-content"
          px={4}
          pt={0}
          pb={0.5}
          mx="auto"
          mt={-1.375}
          borderRadius="section"
          lineHeight={1}
        >
          <MDTypography
            variant="caption"
            textTransform="uppercase"
            fontWeight="medium"
            color={badge.color === "light" ? "dark" : "white"}
          >
            {badge.label}
          </MDTypography>
        </MDBox>
        <MDBox pt={3} pb={2} px={2} textAlign="center">
          {
            !isTrial && <MDBox my={1}>
              <MDTypography variant="h1" color={color === "white" ? "dark" : "white"}>
                <MDTypography
                  display="inline"
                  component="small"
                  variant="h5"
                  color="inherit"
                  verticalAlign="top"
                >
                  {price.currency}
                </MDTypography>
                {(price.value / price.count).toFixed(2)}
                <MDTypography display="inline" component="small" variant="h5" color="inherit">
                  / {price.type}
                </MDTypography>
              </MDTypography>
            </MDBox>
          }

          <MDBox my={1}>
            <MDTypography variant="subtitle2" color={color === "white" ? "dark" : "white"}>
              <MDTypography
                display="inline"
                component="small"
                variant="subtitle2"
                color="inherit"
                verticalAlign="top"
              >
                {price.currency}
              </MDTypography>
              {price.value}
              <MDTypography display="inline" component="small" variant="subtitle2 " color="inherit">
                /{price.count} {price.type}
              </MDTypography>
            </MDTypography>
          </MDBox>


        </MDBox>
        <MDBox pb={3} px={3}>
          {renderSpecifications}
          <MDBox mt={3}>
            {
              currentPlan === true
                ? <MDButton
                  variant="gradient"
                  color={button.color}
                  fullWidth
                >
                  {button.label}
                </MDButton>
                :
                <MDButton
                  onClick={handleUpgrade}
                  variant="gradient"
                  color={button.color}
                  fullWidth
                >
                  {button.label}&nbsp;
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MDButton>
            }
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the DefaultPricingCard
PricingCard.defaultProps = {
  color: "white",
  shadow: true,
};

// Typechecking props for the DefaultPricingCard
PricingCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "white",
  ]),
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    count: PropTypes.number,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
  subscriptionReferenceCode: PropTypes.string,
  pricingPlanReferenceCode: PropTypes.string,
  pricingPlanId: PropTypes.number.isRequired,
  currentPlan: PropTypes.bool.isRequired,
};

export default PricingCard;
