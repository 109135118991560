import AdminLayout from 'pages/main/layout/AdminLayout'
import React, { useEffect, useState } from 'react'
import PaypalProductsTable from './PaypalProductsTable'
import { useDispatch } from 'react-redux'
import { getProducts } from 'store/paypal/paypalProductsSlice'
import LoadingComponent from 'components/Loading'

function PaypalProductsDashboard() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getProducts()).then(() => setLoading(false));
  }, [dispatch]);

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      {/* <PaypalProductsDialog /> */}
      <PaypalProductsTable />
    </AdminLayout>
  )
}

export default PaypalProductsDashboard