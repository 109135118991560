import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";

export const getStatistics = createAsyncThunk(
  "statistics/getStatistics",
  async (_, { getState }) => {
    const response = await axiosConfig.get(`/api/statistics`);
    // const response = await axios.get(`${proxy}/api/categories`)

    let { data } = await response.data;
    return data;
  }
);


const statisticsAdapter = createEntityAdapter({
  selectId: (statistic) => statistic.statisticId,
});

export const {
  selectAll: selectStatistics,
  selectById: selectStatisticById
} = statisticsAdapter.getSelectors((state) => state.statistics);

const statisticsSlice = createSlice({
  name: "statistics",
  initialState: statisticsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    
  }),
  reducers: {},
  extraReducers: {
    [getStatistics.fulfilled]: statisticsAdapter.setAll,
  },
});

export default statisticsSlice.reducer;
