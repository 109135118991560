import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import axiosConfig from "features/api/axiosConfig";
import setAuthToken from "features/api/setAuthToken";

export const getLocation = createAsyncThunk(
  "location/getLocation", async () => {
    try {
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
      const response = await axiosConfig.get(`api/location`);
      const data = response.data;
      return data.data;
    } catch (error) {

    }
  }
);


const locationsSlice = createSlice({
  name: "location",
  initialState: {
    country_code: "TR",
    country_name: "Turkey",
    city: "",
    latitude: "",
    longitude: "",
    IPv4: "",
    state: "",
  },
  reducers: {},
  extraReducers: {
    [getLocation.fulfilled]: (state, action) => action.payload,
  },
});

export default locationsSlice.reducer;
