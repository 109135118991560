import {
  DataGrid,
  trTR,
  enUS,
  deDE,
  arSD,
  GridActionsCellItem,
  GridToolbar
} from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useMemo, useState } from "react";
import { Box, Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import { useDispatch, useSelector } from "react-redux";
import { darken, lighten } from "@mui/material/styles";
import FileCopyIcon from "@mui/icons-material/DisabledByDefault";
import EditIcon from "@mui/icons-material/Edit";

import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";
import { useTranslation } from "react-i18next";
import {
  selectsongLists,
  openNewSongListDialog,
  updateSongList,
  openEditSongListDialog
} from "store/main/songListsSlice";
import { openConfirmDialog } from "store/main/confirmSlice";
import dayjs from "dayjs";

const SongListTable = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(selectsongLists);
  console.log(data);
  const { t, i18n } = useTranslation();
  const [pageSize, setPageSize] = useState(15);

  const handleOpenNew = () => {
    console.log("open new");
    dispatch(openNewSongListDialog());
  };

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const editStatus = React.useCallback(
    (id) => () => {
      let data = {
        ...id.row,
        status: id.row.status === "active" ? "passive" : "active",
      };
      dispatch(updateSongList(data));
    },
    [dispatch]
  );

  const editData = React.useCallback(
    (id) => () => {
      let data = id.row;
      console.log("data", data);
      dispatch(
        openEditSongListDialog(
          {
            ...data,
            ...data.details
          }));
    },
    [dispatch]
  );

  const deletaData = React.useCallback(
    (id) => () => {
      let data = { ...id.row, table: "songListsTable", id: id.row.listId };
      dispatch(openConfirmDialog(data));
    },
    [dispatch]
  );

  const columns = useMemo(() => [
    {
      field: "actions",
      type: "actions",
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem
          key={"edit"}
          icon={<EditIcon />}
          label="Düzenle"
          onClick={editData(params)}
          disabled={params.row.status === "passive" ? true : false}
        />,
        <GridActionsCellItem
          key={"status"}
          icon={<FileCopyIcon />}
          label={params.row.status === "active" ? "Pasife al" : "active et"}
          onClick={editStatus(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Sil"
          onClick={deletaData(params)}
          showInMenu
        />,
      ],
    },

    {
      headerName: t("id"),
      field: "listId",
      minWidth: 70,
      hideable: false,
      renderCell: (params) => {
        return <MDBox sx={{
          backgroundColor: "rgb(37, 47, 50, 0.5)",
          color: "#fff",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        >
          {params.value}
        </MDBox>
      }
    },
    {
      headerName: t("folderNumber"),
      field: "folderNumber",
      minWidth: 140,
      hideable: false,
    },
    // {
    //   headerName: t("songId"),
    //   field: "songId",
    //   minWidth: 70,
    //   hideable: false,
    // },
    {
      headerName: t("songName"),
      field: "songInfo",
      minWidth: 300,
      hideable: false,
      renderCell: (params) => {
        return params.value ? params.value.songName : null;
      },
    },
    {
      headerName: t("musicianName"),
      field: "songMusicianInfo",
      minWidth: 200,
      hideable: false,
      renderCell: (params) => {
        return params.value ? params.value.musicianName : null;
      },
    },
    {
      headerName: t("instrumentName"),
      field: "songInstrumentInfo",
      minWidth: 200,
      hideable: false,
      renderCell: (params) => {
        return (
          (i18n.language.match("tr") && params.value.instrumentNameTr) ||
          (i18n.language.match("en") && params.value.instrumentNameEn) ||
          (i18n.language.match("de") && params.value.instrumentNameDe) ||
          (i18n.language.match("ar") && params.value.instrumentNameAr)
        )
      },
    },
    {
      headerName: t("description"),
      field: "description",
      minWidth: 70,
      hideable: false,
    },
    {
      headerName: t("level"),
      field: "level",
      minWidth: 70,
      hideable: false,
    },
    {
      headerName: "Video",
      field: "details",
      minWidth: 140,
      hideable: false,
      renderCell: (params) => {
        if (params.value !== null) {
          return (
            <Link href={`video/${params.value.listId}`} target="_blank">
              <MDTypography variant="button" fontWeight="medium">
                &nbsp;{t("view")}&nbsp;
              </MDTypography>
            </Link>
          );
        }
        return null;
      },
    },
    {
      headerName: t("createdDate"),
      field: "createdDate",
      minWidth: 170,
      hideable: false,
      type: "date",
      renderCell: (params) => {
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: t("status"),
      field: "status",
      minWidth: 150,
    },

  ], [editData, editStatus, t, i18n.language, deletaData]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox p={3} lineHeight={1}>
        <TableContainer sx={{ boxShadow: "none" }}>
          <MDBox>
            <Grid container alignItems="center">
              <Grid item xs={12} md={7}>
                <MDBox mb={1}>
                  <MDTypography variant="h5">{t("songList")}</MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2" color="text">
                    {t("songListTable")}
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={handleOpenNew}
                >
                  <Icon>add</Icon>&nbsp;{t("AddNewSongList")}
                </MDButton>
              </Grid>
            </Grid>
            <div style={{ flexGrow: 1, width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      height: "100%",
                      width: 1,
                      "& .super-app-theme--iptal": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.error.main,
                            theme.palette.mode
                          ),
                      },
                      "& .super-app-theme--tamamlandi": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.info.main,
                            theme.palette.mode
                          ),
                      },
                    }}
                  >
                    <DataGrid
                      localeText={
                        (i18n.language.match("tr") && trTR.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("en") && enUS.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("de") && deDE.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("ar") && arSD.components.MuiDataGrid.defaultProps.localeText)
                      }
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      rowsPerPageOptions={[15, 30, 60]}
                      getRowId={(row) => row.listId}
                      pagination
                      autoHeight
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      getRowClassName={(params) =>
                        `super-app-theme--${params.row.status}`
                      }
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                    />
                  </Box>
                </div>
              </div>
            </div>
          </MDBox>
        </TableContainer>
      </MDBox>
    </Card>
  );
};

SongListTable.propTypes = {};

export default SongListTable;
