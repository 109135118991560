import { imageProxy } from 'features/api/proxy';
import { usePlayer } from 'hooks/PlayerProvider';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setVideoData } from 'store/main/videoPlayerSlice';
import { setDraggableImageData } from 'store/main/videoPlayerSlice';

function DraggableImage({ selectSongState }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { videoData, draggableImageData, loopData } = useSelector((state) => state.videoPlayer);
  const details = useSelector((state) => selectSongState(state, id));
  const [photoUrl, setPhotoUrl] = useState("");

  const { handlePause, handlePlay, getMiddlePositionValue } = usePlayer();

  const imageRef = useRef();
  const [previousValue, setPreviousValue] = useState(0);

  const [dragStartX, setDragStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const { leftPosition, transition } = draggableImageData;
  const { isLoop, loopLeftPosition } = loopData;

  useEffect(() => {
    if (videoData.isColored) {
      setPhotoUrl(`${imageProxy}/${details.folderNumber}/${details.folderNumber}_C.svg`);
    }
    else {
      setPhotoUrl(`${imageProxy}/${details.folderNumber}/${details.folderNumber}.svg`);
    }
  }, [videoData.isColored, details.folderNumber]);

  useEffect(() => {
    const handleResize = () => {
      if (imageRef !== undefined) {

        if (imageRef.current.offsetHeight !== previousValue) {
          const { offsetHeight } = imageRef.current;
          setPreviousValue(offsetHeight);
          dispatch(setDraggableImageData({ noteHeight: offsetHeight }))
        }
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [previousValue, dispatch, draggableImageData]);


  const handleDragStart = (event) => {
    dispatch(
      setDraggableImageData({
        ...draggableImageData,
        isDragEnd: false,
        transition: "all 0.01s linear",
        startPos: leftPosition + details.details.noteStart,
      })
    );

    handlePause();
    setDragStartX(event.clientX);
    setIsDragging(true);
  };

  const handleDrag = (event) => {
    if (isDragging) {
      const newPosition = leftPosition + event.clientX - dragStartX;
      const middlePosition = getMiddlePositionValue(newPosition, draggableImageData.leftMinPosition, draggableImageData.leftMaxPosition)
      dispatch(setDraggableImageData({ ...draggableImageData, leftPosition: middlePosition }));
      setDragStartX(event.clientX);
    }
  };

  const handleDragEnd = () => {
    if (isDragging) {
      const { leftPosition } = draggableImageData;
      dispatch(
        setDraggableImageData({
          ...draggableImageData,
          isDragEnd: true,
          transition: "all 0.45s linear",
          endPos: leftPosition
        })
      );
      handlePlay()
      setIsDragging(false);
    }
  }


  return (
    <img
      ref={imageRef}
      onMouseDown={handleDragStart}
      onMouseMove={handleDrag}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      draggable={false}
      src={photoUrl}
      className='blurred ColoredNote'
      alt={photoUrl}
      style={{
        position: 'relative',
        transform: `translateX(${isLoop ? loopLeftPosition : leftPosition}px)`,
        // left: isLoop ? loopLeftPosition : leftPosition,
        transition,
      }}>
    </img>
  );
}

export default DraggableImage;