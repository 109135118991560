import AdminLayout from 'pages/main/layout/AdminLayout'
import React from 'react'
import InsApplicationsTable from './InsApplicationsTable'
import { useDispatch } from 'react-redux'
import { useState } from 'react';
import LoadingComponent from 'components/Loading';
import { useEffect } from 'react';
import { getinstructorApplications } from 'store/main/instructorApplicationsSlice';
import InsApplicationDialog from './InsApplicationDialog';
import { removeinstructor } from 'store/main/instructorApplicationsSlice';
import ConfirmDialog from 'pages/confirmation/ConfirmDialog';

function AdminInsApplicationsDashboard() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getinstructorApplications()).then(() => setLoading(false));
  }, [dispatch]);

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <InsApplicationsTable />
      <InsApplicationDialog />
      <ConfirmDialog handleDeleteData={removeinstructor} />
    </AdminLayout>
  )
}

export default AdminInsApplicationsDashboard