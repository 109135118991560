import { Card, Divider, Fade, Icon, ListItemIcon, MenuItem, MenuList, Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import NotificationItem from 'examples/Items/NotificationItem';
import { usePlayer } from 'hooks/PlayerProvider';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setControlsData } from 'store/main/videoPlayerSlice';

function SpeedMenu() {

  const { setPlaybackRate } = usePlayer();

  const dispatch = useDispatch();
  const {
    videoData,
    controlsData
  } = useSelector((state) => state.videoPlayer);

  const { t } = useTranslation();


  const handleBack = () => {
    dispatch(setControlsData({ showMenu: true, showQuality: false, showSpeed: false }));
  }


  const videoSpeedValues = [
    // { title: "1.2", speed: 1.2, transition: "left 0.3s linear" },
    // { title: "1.1", speed: 1.1, transition: "left 0.4s linear" },
    { title: "1", speed: 1, transition: "all 0.35s linear" },
    { title: "0.9", speed: 0.9, transition: "all 0.55s linear" },
    { title: "0.8", speed: 0.8, transition: "all 0.55s linear" },
  ];

  return (
    <Fade in={controlsData.showSpeed} style={{ transitionDuration: "1000ms" }}>
      <Card sx={{ marginBottom: 4 }}>
        <MenuList className='SpeedMenu' sx={{ padding: 2, }}>
          <MenuItem
            className='SpeedBack'
            onClick={handleBack}
            sx={{
              // background: videoData.quality === item.quality && "#f0f2f5",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <ListItemIcon>
              <Icon fontSize="medium">arrow_back</Icon>
            </ListItemIcon>


            <Typography fontWeight={"500"} color="text.secondary">
              {t("PlaybackRate")}
            </Typography>


          </MenuItem>


          <Divider />
          {
            videoSpeedValues.map((item, key) => {
              return <MenuItem onClick={() => setPlaybackRate(item.speed, item.transition)} key={key} sx={{ background: videoData.speed === item.speed && "#f0f2f5", display: "flex", alignItems: "center" }}>
                <MDBox component={Icon} sx={{ marginRight: 2 }}>{videoData.speed === item.speed ? "check" : ""}</MDBox>
                <Typography fontWeight={"500"} color="text.secondary">
                  {item.title}
                </Typography>
              </MenuItem>
            })
          }


        </MenuList>
      </Card>
    </Fade>

  )
}

export default SpeedMenu