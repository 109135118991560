/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples

import PageLayout from "examples/LayoutContainers/PageLayout";


// Material Dashboard 2 PRO React page layout routes
import webPageRoutes from "routes/webPageRoutes";
import DefaultNavbar from "pages/webPages/navbar";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

function CoverLayout({ coverHeight, image, children }) {
  const { i18n } = useTranslation();
  return (
    <MDBox>
      <MDBox sx={{ minHeight: "100vh", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
        <MDBox>
          <DefaultNavbar
            routes={webPageRoutes}
            action={{
              type: "external",
              route:
                "https://creative-tim.com/product/material-dashboard-pro-react",
              label: `${i18n.language}`,
              languages: ["tr", "en"],
            }}
          />

          <LazyLoad height={coverHeight} once>
            <MDBox
              width="full"
              minHeight={coverHeight}
              borderRadius="xl"
              m={2}
              sx={{
                backgroundImage: ({
                  functions: { linearGradient, rgba },
                  palette: { gradients },
                }) =>
                  image &&
                  `${linearGradient(
                    rgba(gradients.dark.main, 0.4) /* default value was 0.4 */,
                    rgba(gradients.dark.state, 0.4) /* default value was 0.4 */
                  )}, url(${image})`,

                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <MDBox width="calc(100% - 2rem)" mx="auto">
                <Grid
                  container
                  spacing={1}
                  
                  >
                  <Grid item xs={12}>
                    {children}
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </LazyLoad>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default props for the CoverLayout
CoverLayout.defaultProps = {
  coverHeight: "70vh",
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  coverHeight: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
