import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";

export const getProducts = createAsyncThunk(
  "paypalProducts/getProducts",
  async (_, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.get(`/api/paypal/products/`);

      const data = await response.data;


      return data;
    } catch (error) {
      return null;
    }
  }
);

const productsAdapter = createEntityAdapter({
  selectId: (product) => product.id,
});

export const {
  selectAll: selectPaypalProducts,
  selectById: selectPaypalProductsById,
} = productsAdapter.getSelectors((state) => state.paypalProducts);

const paypalProductsSlice = createSlice({
  name: "paypalProducts",
  initialState: productsAdapter.getInitialState({
    id: "",
    searchText: "",
    routeParams: {},
  }),
  reducers: {
  },
  extraReducers: {
    [getProducts.fulfilled]: productsAdapter.setAll,
  },
});



export default paypalProductsSlice.reducer;
