import React from 'react'
import VolumeSlider from './VolumeSlider'
import MuteButton from './MuteButton'

function VolumeButton() {
  return (
    <div className={"VolumeContainer"}>
      <MuteButton />
      <VolumeSlider />
    </div>
  )
}

export default VolumeButton