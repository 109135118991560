/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MDButton from "components/MDButton";
import { useForm } from "features/hooks";

// image proxy
import { imageProxy } from "features/api/proxy";
import ImageUpload from "components/UploadComponents/ImageUpload";
import { useDispatch } from "react-redux";
import { updateInformation } from "store/auth/authSlice";
import { Alert, Collapse, Icon, Snackbar, TextField } from "@mui/material";
import { loadUser } from "store/auth/authSlice";
import MDAvatar from "components/MDAvatar";


function Header() {

  const [isEdit, setIsEdit] = useState(false);
  const [photo, setphoto] = useState();
  const { name, userName, url, surname } = useSelector((state) => state.auth);

  console.log(name, userName, url, surname);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (url) {
      fetch(`${imageProxy}/${url}`)
        .then(response => response.blob())
        .then(blob => {
          console.log(blob);
          const file = new File([blob], url, { type: blob.type });
          setphoto(file);
        })
        .catch(error => {
          console.error('Dosya bulunamadı:', error);
        });
    }
  }, []);

  const defaultFormState = {
    name: "",
    surname: "",
    url: "",
  };
  const { form, handleChange, setInForm } = useForm(defaultFormState);

  useEffect(() => {
    setInForm("name", name);
    setInForm("surname", surname);
  }, [name, surname, setInForm]);

  const handleIsEdit = (e) => {
    setIsEdit(isEdit ? false : true);
  }

  const handleSetImage = (file) => {
    setphoto(file);
    console.log(file);
    if (!file) {
      setInForm("url", null);
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    let data = {
      ...form,
      url: photo,
      userName,
    }

    if (form.name.length > 0 && form.surname.length > 0) {
      dispatch(updateInformation(data)).then((params) => {
        try {
          if (params.payload.success) {
            dispatch(loadUser());
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
    else {

    }
  }

  const canBeSubmitted = () => {
    return (form.name.length > 0 && form.surname.length > 0)
  }

  return (
    <Card id="profile">
      <MDBox p={3}>
        <MDBox display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <MDBox display={"flex"}>
            <MDAvatar src={`${imageProxy}/${url}`} alt="profile-image" size="xl" shadow="sm" sx={{ marginRight: 1 }} />
            <MDBox justifyContent={"center"} display={"flex"} flexDirection={"column"}>
              <MDTypography variant="h5" fontWeight="medium">
                {name} {surname}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="medium">
                {t("student")}
              </MDTypography>
            </MDBox>
          </MDBox>

          <MDBox>
            <MDButton onClick={handleIsEdit} variant="gradient" color={isEdit ? "error" : "info"} size="small">
              <Icon>edit</Icon>&nbsp;&nbsp;&nbsp;{isEdit ? t("cancel") : t("edit")}
            </MDButton>
          </MDBox>
        </MDBox>

        <Collapse in={isEdit}>
          <MDBox component="form" role={"form"} py={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  label={t("name")}
                  fullWidth
                  value={form.name}
                  id="name"
                  name="name"
                  onChange={handleChange}
                  error={form.name ? false : true}
                  helperText={form.name ? "" : t("enterNameError")}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  type="text"
                  label={t("surname")}
                  fullWidth
                  value={form.surname}
                  id="surname"
                  name="surname"
                  onChange={handleChange}
                  error={form.surname ? false : true}
                  helperText={form.surname ? "" : t("enterSurnameError")}
                />
              </Grid>
            </Grid>
            <MDBox mt={2}>
              <ImageUpload
                handleSetImage={handleSetImage}
                file={photo}
                url={form && form.url ? `${imageProxy}/${form.url}` : null}
              />
            </MDBox>

            <MDBox mt={3} display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
              <MDButton disabled={!canBeSubmitted()} onClick={handleSubmit} variant="gradient" color="info" size="small">
                {t("update_information")}
              </MDButton>
            </MDBox>
          </MDBox>
        </Collapse>
      </MDBox>
    </Card>
  );
}

export default Header;