import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "features/api/axiosConfig";
import setAuthToken from "features/api/setAuthToken";
import showToast from "hooks/useCustomToast";

export const loadUser = createAsyncThunk(
  "auth/loadUser", async () => {
    try {
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
      const response = await axiosConfig.post(`api/auth/access-token-panel`, {
        token: localStorage.token,
      });
      const data = response.data;
      console.log("success", response.data.success);
      if (response.data.success) {
        if (data) {
          return data;
        }
      } else {

      }
    } catch (error) {

    }
  }
);

export const acceptPolicies = createAsyncThunk("auth/accept-policies", async () => {
  try {
    const currentDate = new Date().getTime();
    const oneWeekLater = new Date(currentDate + 7 * 24 * 60 * 60 * 100);
    const oneWeekLaterUnix = oneWeekLater.getTime().toString();

    localStorage.setItem("cookieAccepted", true);
    localStorage.setItem("cookieExpireTime", oneWeekLaterUnix);


  } catch (error) {
    console.log(error)
  }
});

export const confirmEmail = createAsyncThunk(
  "auth/confirm-email",
  async (credentials, { dispatch, state }) => {
    try {

      const response = await axiosConfig.post(`api/auth/confirm-email`, credentials);
      const data = response.data;

      return data;

    } catch (error) {
      console.log(error)
    }
  }
);

export const resendVerificationCode = createAsyncThunk(
  "auth/resend-verification-code",
  async (credentials, { dispatch, state }) => {
    try {
      const response = await axiosConfig.post(
        `api/auth/resend-verification-code`,
        credentials
      );
      const data = response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (credentials, { dispatch, state }) => {
    try {
      const response = await axiosConfig.post(`api/auth/login`, credentials);
      const data = response.data;

      if (data.success && data && data.access_token && data.sessionId) {
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("sessionId", data.sessionId);
      }
      console.log(data);

      showToast(data.success ? "check_circle_icon" : "error", data.message, data.success ? "success" : "error");

      return data;
    } catch (error) {

    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (credentials, { dispatch, state }) => {
    try {
      const response = await axiosConfig.post(`api/auth/register`, credentials);
      const data = response.data;

      console.log(data);

      if (data.success) {
        if (data) {
          localStorage.setItem("token", data.access_token);
          return data;
        }
      } else {

      }
    } catch (error) {

    }
  }
);

export const registerStudent = createAsyncThunk(
  "auth/registerStudent",
  async (student, { dispatch, state }) => {
    try {
      const response = await axiosConfig.post(
        `api/auth/student-register`,
        {
          ...student,
          guestId: localStorage.getItem("guestId"),
        }
      );

      const data = response.data;

      if (data.success) {
        if (data) {
          return data;
        }
      } else {

      }
    } catch (error) {

    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (data, { dispatch, state }) => {
    try {
      const response = await axiosConfig.put(
        `api/auth/changepassword`,
        data,
      );
      if (response.data.success) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateInformation = createAsyncThunk(
  "auth/updateInformation",
  async (data, { dispatch, state }) => {
    try {
      const formData = new FormData();
      formData.append("url", data.url);
      formData.append("name", data.name);
      formData.append("surname", data.surname);
      formData.append("userName", data.userName);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.put(
        `api/auth/change-information/${data.userName}`,
        formData,
        config,
      );

      console.log(response);
      if (response.data.success) {
        showToast("check_circle_icon", "profile_changed_success", "success");
        return response.data;
      }
    } catch (error) {

      showToast("error", "profile_changed_error", "error");
      console.log(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data, { dispatch, state }) => {
    try {
      const response = await axiosConfig.post(
        `api/auth/forgotpassword`,
        data,
      );

      console.log(response);

      if (response.data.success) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (newPassword, { dispatch, state }) => {
    console.log(newPassword);
    try {
      const response = await axiosConfig.put(
        `api/auth/resetpassword/`,
        newPassword,
      )

      if (response.data.success) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
)

export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    localStorage.removeItem("token");
    let data = {
      logoutDate: new Date(),
    }

    axiosConfig.put(
      `api/analytics/user-logins/${localStorage.getItem("sessionId")}`,
      data
    );

    localStorage.removeItem("sessionId");

    showToast("check_circle_icon", "logout_successful", "success");

    return {
      token: null,
      isAuthenticated: false,
      loading: false,
    };
  } catch (error) { }
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    loading: true,
    userName: "",
    user: {},
  },
  reducers: {},
  extraReducers: {
    [loadUser.fulfilled]: (state, action) => action.payload,
    [logout.fulfilled]: (state, action) => action.payload,
    [login.fulfilled]: (state, action) => action.payload,
    [register.fulfilled]: (state, action) => action.payload,
    [registerStudent.fulfilled]: (state, action) => action.payload,
  },
});

export default authSlice.reducer;
