import { Card } from '@mui/material'
import MDTypography from 'components/MDTypography'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function Header() {
  const { name, surname } = useSelector((state) => state.auth);

  const { t } = useTranslation();

  return (
    <Card sx={{ padding: 3, marginBottom: 2 }}>
      <MDTypography fontSize={{ xs: "1.1rem", lg: "1.2rem", xl: "1.3rem" }} fontWeight={"bold"}>{t("welcome")} {name}</MDTypography>
      <MDTypography fontSize={{ xs: "1.1rem", lg: "1.2rem", xl: "1.3rem" }} variant={"text"}>{t("welcome_alt")}</MDTypography>
    </Card>
  )
}

export default Header