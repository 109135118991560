import AdminLayout from 'pages/main/layout/AdminLayout'
import React, { useEffect, useState } from 'react'
import PaypalPricingPlansTable from './PaypalPricingPlansTable'
import { useDispatch } from 'react-redux';
import LoadingComponent from 'components/Loading';
import { getPricingPlans } from 'store/paypal/paypalPricingPlansSlice';

function PayPalPricingPlansDashboard() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getPricingPlans()).then(() => setLoading(false));
  }, [dispatch]);

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <PaypalPricingPlansTable />
    </AdminLayout>
  )
}

export default PayPalPricingPlansDashboard