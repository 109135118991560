import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React from 'react'
import { useTranslation } from 'react-i18next'


function ContactItem({ Icon, header, alt, route, target }) {
  const { t } = useTranslation();
  return (
    <MDBox component={"a"} href={route} target={target} sx={{ display: "flex", alignItems: "center", gap: 4 }}>
      <MDBox padding={{ xs: 1, lg: 2 }} sx={{ backgroundColor: "#252F32", alignItems: "center", display: "flex", borderRadius: 2 }}>
        <Icon color='white' size={30} />
      </MDBox>

      <MDBox>
        <MDTypography
          fontSize={{ xs: "1rem", lg: "1.15rem", xl: "1.30rem" }}
          fontWeight={"bold"}
        >
          {t(header)}
        </MDTypography>
        <MDTypography
          fontSize={{ xs: "1rem", lg: "1.10rem", xl: "1.20rem" }}
          fontWeight="light"
        >
          {t(alt)}
        </MDTypography>
      </MDBox>
    </MDBox>
  )
}

export default ContactItem