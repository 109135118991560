import AdminLayout from 'pages/main/layout/AdminLayout'
import React, { useEffect, useState } from 'react'
import CouponCodesTable from './CouponCodesTable'
import CouponCodesDialog from './CouponCodesDialog'
import { useDispatch } from 'react-redux'
import { getCouponCodes } from 'store/main/couponCodesSlice'
import LoadingComponent from 'components/Loading'
import ConfirmDialog from 'pages/confirmation/ConfirmDialog'
import { removeCouponCode } from 'store/main/couponCodesSlice'
import { getinstructors } from 'store/main/instructorsSlice'
import { getCustomPricingPlans } from 'store/main/pricingPlansSlice'

function CouponCodesDashboard() {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [instructorLoading, setInstructorLoading] = useState(true);
  const [pricingPlansLoading, setPricingPlansLoading] = useState(true);

  useEffect(() => {
    dispatch(getCouponCodes()).then(() => setLoading(false));
    dispatch(getinstructors()).then(() => setInstructorLoading(false));
    dispatch(getCustomPricingPlans()).then(() => setPricingPlansLoading(false));
  }, [dispatch])

  if (loading || instructorLoading || pricingPlansLoading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <CouponCodesTable />
      <CouponCodesDialog />
      <ConfirmDialog handleDeleteData={removeCouponCode} />
    </AdminLayout>
  )
}

export default CouponCodesDashboard