import { Icon } from '@mui/material'
import MDBox from 'components/MDBox'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { selectinstructorLessonsById } from 'store/main/instructorLessonsSlice'
import { selectsongListsById } from 'store/main/songListsSlice'

function VideoHeader() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { lessonId } = useParams();
  const details = useSelector((state) => selectinstructorLessonsById(state, lessonId));
  const { controlsData } = useSelector((state) => state.videoPlayer);

  return (
    <MDBox className={"HeaderContainer"}>
      <MDBox
        style={{
          transitionDelay: controlsData.transitionDelay,
          opacity: controlsData.opacity
        }}
        className={"ButtonContainer"}>
        <MDBox color={"white"} onClick={() => navigate(-1)} className={"BackButton"}>
          <Icon>arrow_back</Icon>
        </MDBox>
      </MDBox>

      <p className={"SongTitle"}>{details.lessonNameTr}</p>

      <MDBox className={"ButtonContainer"}></MDBox>
    </MDBox>

  )
}

export default VideoHeader