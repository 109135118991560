import { Card, Popper } from '@mui/material'
import MDTypography from 'components/MDTypography';
import React, {useMemo } from 'react'
import RequirementsItem from './RequirementsItem';
import MDBox from 'components/MDBox';
import { useTranslation } from 'react-i18next';

function PasswordPopper({ anchorEl, onClose, onOpen, password, confirmPassword }) {
  const passwordRequirements = useMemo(() => [
    { name: "one_letter", regex: /[a-zA-Z]/ },
    { name: "one_number", regex: /\d/ },
    { name: "special_characters", regex: /[-!$%^&*()_+|~=`{}\[\]:";'<>?,./\\]/ },
    { name: "min_characters", regex: /^.{6,}$/ },
  ], []);

  const id = onOpen ? 'simple-popper' : undefined;

  const { t } = useTranslation();

  return (
    <Popper
      style={{ zIndex: 100, width: "600px", }}
      id={id}
      open={onOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      placement='bottom'
      disablePortal={true}
      modifiers={[
        {
          name: 'flip',
          enabled: false,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      <Card sx={{ padding: 2, marginTop: 2 }}>
        <MDTypography sx={{ fontWeight: "bold", fontSize: 16 }}>{t("password_requirements")}</MDTypography>
        <MDBox sx={{ marginY: "2px" }}>
          {
            passwordRequirements.map((item, key) => {
              return <RequirementsItem key={key} name={item.name} regex={item.regex.test(password)} />
            })
          }
          <RequirementsItem name={"passwordDoesntMatch"} regex={password.length > 0 && password === confirmPassword} />
        </MDBox>
      </Card>
    </Popper>
  )
}

export default PasswordPopper