import { Collapse, Divider, Grid, Icon, TextField } from '@mui/material';
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography';
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import StepHeader from '../components/StepHeader';
import PricingCard from '../PricingPlansInfo/PricingCard';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'features/hooks';
import { checkCouponCode } from 'store/main/couponCodesSlice';
import { selectPricingPlans } from 'store/main/pricingPlansSlice';
import { setUserInfo } from 'store/main/subscriptionsSlice';
import { addCouponSubscription } from 'store/main/subscriptionsSlice';
import { loadUser } from 'store/auth/authSlice';
import { checkSubscription } from 'store/payment/paymentsSlice';
import { addSubscription } from "store/main/subscriptionsSlice";

const defaultFormState = {
  couponCode: "",
}

function PreviewPlan({ handleNext, handleBack }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { userInfo } = useSelector((state) => state.subscriptions);
  const { userName } = useSelector((state) => state.auth);
  const pricingPlans = useSelector(selectPricingPlans);


  const [pricingPlan, setPricingPlan] = useState({});
  const [currencySymbol, setCurrencySymbol] = useState("");

  console.log(pricingPlan);

  useEffect(() => {
    const plan = pricingPlans.find((f) => f.pricingPlanId === userInfo.pricingPlanId);
    setDiscountAmount(plan.price * plan.discount / 100)
    setPricingPlan(plan);

    if (plan.currencyISO === "EUR") {
      setCurrencySymbol("€")
    }
    else {
      setCurrencySymbol("₺")
    }
  }, [pricingPlans, userInfo.pricingPlanId])


  const { form, handleChange } = useForm(defaultFormState);

  const [couponCodeResponse, setCouponCodeResponse] = useState({
    open: false,
    success: false,
    header: "",
    alt: "",
    date: false,
  })

  const [discountAmount, setDiscountAmount] = useState(0);

  const handleCheckCouponCode = () => {
    console.log(form);
    dispatch(checkCouponCode(form.couponCode)).then((params) => {
      if (params.payload === null) {
        setCouponCodeResponse({
          open: true,
          success: false,
          header: "couponNotFoundHeader",
          alt: "couponNotFoundAlt",
          date: false,
        })
        setDiscountAmount(0);
        dispatch(setUserInfo({ ...userInfo, pricingPlanId: userInfo.previousPlanId }));
      }
      else {
        try {
          setCouponCodeResponse({
            open: true,
            success: true,
            header: "couponSuccessHeader",
            alt: "couponSuccessAlt",
            date: true,
          })
          console.log(params.payload);
          const { couponPlansData } = params.payload;
          if (couponPlansData.length === 1) {
            dispatch(
              setUserInfo({
                ...userInfo,
                previousPlanId: userInfo.pricingPlanId,
                pricingPlanId: couponPlansData[0].pricingPlanId
              })
            )
          }
        } catch (error) {
          console.log(error);
        }
      }
    })
  }

  const handleFinish = () => {
    if (pricingPlan.paymentInterval === "TRIAL") {
      const currentDate = new Date();

      let data = {
        pricingPlanId: pricingPlan.pricingPlanId,
        userName,
        payMethod: "iyzico",
        status: "TRIAL",
        startDate: currentDate,
        endDate: new Date(currentDate.getTime() + (365 * 24 * 60 * 60 * 1000)),
      }
      dispatch(addSubscription(data)).then(() => {
        dispatch(loadUser())
        dispatch(checkSubscription({ userName }));
      });
    }
    else {
      let data = {
        ...userInfo,
        ...form,
        userName,
      }

      dispatch(addCouponSubscription(data)).then(() => {
        dispatch(loadUser())
        dispatch(checkSubscription({ userName }));
      });
    }
  }

  return (
    <MDBox>
      <StepHeader header={"previewPlanHeader"} alt={"previewPlanAlt"} />

      <MDBox>
        <Grid mb={{ xs: 6, md: 0 }} container spacing={{ xs: 6, md: 3 }} display={"flex"} justifyContent={"center"}>
          <Grid item xs={12} md={6} lg={5} xl={4} xxl={3}>
            <PricingCard
              color={"dark"}
              badge={{
                color: "warning",
                label: (
                  (i18n.language.match("tr") && pricingPlan.pricingPlanNameTr) ||
                  (i18n.language.match("en") && pricingPlan.pricingPlanNameEn) ||
                  (i18n.language.match("de") && pricingPlan.pricingPlanNameDe) ||
                  (i18n.language.match("ar") && pricingPlan.pricingPlanNameAr)
                )
              }}
              price={{
                currency: currencySymbol,
                value: pricingPlan.price,
                type: t(userInfo.priceType),
                count: pricingPlan.paymentIntervalCount,
              }}
              specifications={[
                { label: t("feature1"), includes: true },
                { label: t("feature2"), includes: true },
                { label: t("feature3"), includes: true },
                { label: t("feature4"), includes: true },
                { label: t("feature5"), includes: true },
                { label: t("feature6"), includes: true },
                { label: t("feature7"), includes: true },
                { label: t("feature8"), includes: true },
                { label: t("feature9"), includes: true },
              ]}
              handleNext={() => handleNext(1)}
              pricingPlanReferenceCode={pricingPlan.pricingPlanReference}
              pricingPlanId={pricingPlan.pricingPlanId}
              button={{
                color: "info",
                label: t("choosePlan"),
                action: "button",
                disabled: true,
              }}
              shadow={true}
              isTrial={userInfo.isTrial ? true : false}
            />


          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4} xxl={3} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
            <MDBox>
              <Collapse in={couponCodeResponse.open && couponCodeResponse.success}>
                <MDTypography fontWeight={"bold"}>{t("summary")}</MDTypography>
                <MDBox display={"flex"} justifyContent={"space-between"}>
                  <MDTypography>{t("originalPrice")}:</MDTypography>
                  <MDTypography fontWeight={"bold"}>
                    {currencySymbol} {pricingPlan.price}
                  </MDTypography>
                </MDBox>
                <MDBox display={"flex"} justifyContent={"space-between"}>
                  <MDTypography>{t("discountAmount")}:</MDTypography>
                  <MDTypography fontWeight={"bold"}>
                    - {currencySymbol} {discountAmount}
                  </MDTypography>
                </MDBox>

                <Divider />
              </Collapse>

              <MDBox display={"flex"} justifyContent={"space-between"}>
                <MDTypography fontWeight={"bold"}>{t("total")}:</MDTypography>
                <MDTypography fontWeight={"bold"}>
                  {currencySymbol} {pricingPlan.price - discountAmount}
                </MDTypography>
              </MDBox>


              <Collapse in={couponCodeResponse.open}>
                <MDBox mt={2} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                  <MDTypography fontWeight={"bold"} textAlign={"center"} color={couponCodeResponse.success ? "success" : "error"} textGradient>
                    {t(couponCodeResponse.header)}
                  </MDTypography>
                  <MDTypography textAlign={"center"} color={couponCodeResponse.success ? "success" : "error"} textGradient>
                    {t(couponCodeResponse.alt)}
                  </MDTypography>
                  {
                    couponCodeResponse.date && <MDTypography fontWeight={"bold"} textAlign={"center"} color={"success"} textGradient>
                      {new Date().toLocaleDateString()} - {new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleDateString()}
                    </MDTypography>
                  }
                </MDBox>
              </Collapse>
            </MDBox>



            <MDBox display={"flex"} flexDirection="column">

              <MDTypography fontWeight={"bold"}>{t("couponCode")}:</MDTypography>
              <TextField
                autoFocus
                margin="dense"
                id="couponCode"
                name="couponCode"
                label={t("couponCode")}
                inputProps={{ style: { textTransform: "uppercase" } }}
                type="text"
                fullWidth
                variant="outlined"
                value={form.couponCode}
                onChange={handleChange}
                // disabled
                helperText={t("enterCouponCode")}
              />

              <MDButton
                sx={{ marginTop: 1 }}
                variant={"gradient"}
                color={"info"}
                onClick={handleCheckCouponCode}
              >
                {t("apply")}
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>

      </MDBox>
      <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
        <MDButton
          variant="outlined"
          color="dark"
          onClick={() => handleBack(1)}>
          {t("goBack")}
        </MDButton>

        {
          pricingPlan.price - discountAmount === 0 ? <MDButton
            // disabled={!canBeSubmitted()}
            variant="gradient"
            color="dark"
            onClick={handleFinish}
          >
            {t("finish")} <Icon>navigate_next</Icon>
          </MDButton> : <MDButton
            // disabled={!canBeSubmitted()}
            variant="gradient"
            color="dark"
            onClick={() => handleNext(1)}
          >
            {t("next")} <Icon>navigate_next</Icon>
          </MDButton>
        }
      </MDBox>
    </MDBox>
  )
}

export default PreviewPlan