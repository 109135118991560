import React from 'react'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import { useState } from 'react';
import OrderItemCollapse from './OrderItemCollapse';
import { useEffect } from 'react';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  borderRadius: "10px",
  '&:before': {
    display: 'none',
  },
  marginBottom: "10px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon color="info" sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: "10px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



function OrderCollapse({ orders }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [orderExpand, setOrderExpand] = useState(null);
  const [sortedOrders, setSortedOrders] = useState([]);

  useEffect(() => {
    const sorted = [...orders].sort((a, b) => a.startPeriod - b.startPeriod);
    setSortedOrders(sorted);
  }, [orders])


  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography variant="h5">
          {t("orderInformations")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {
          sortedOrders.map((item, key) => {
            return <MDBox key={key} mb={1}>
              <OrderItemCollapse item={item} expanded={orderExpand} setExpanded={setOrderExpand} />
            </MDBox>
          })
        }
      </AccordionDetails>
    </Accordion>
  )
}

export default OrderCollapse