const { useState, useEffect } = require("react");

const useResizer = (videoRef) => {
  const [videoHeight, setVideoHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (videoRef) {
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        const videoHeight = 852;
        const videoWidth = 2048;

        const freeSpaceForHeight = windowHeight - 53;

        if (freeSpaceForHeight * (videoWidth / videoHeight) > windowWidth) {
          const videoHeightForFullWidth = windowWidth * (videoHeight / videoWidth);
          console.log("buraya girdik if")
          setVideoHeight(videoHeightForFullWidth)
        }
        else {
          setVideoHeight(freeSpaceForHeight)
        }
      }
    };

    // Sayfa yüklendiğinde ve pencere boyutu değiştiğinde video yüksekliği kontrol edilir
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      // Event listener'ı temizle
      window.removeEventListener('resize', handleResize);
    };
  }, [videoRef]);

  return { videoHeight };
}

export default useResizer;