/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


/** 
 All of the routes for the Material Dashboard 2 PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.
 
 Once you add a new route on this file it will be visible automatically on
 the Sidenav.
 
 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav. 
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Components
import CoursesDetailsDashboard from "pages/students/educations/details/CoursesDetailsDashboard";
import InstructorDashboard from "pages/students/instructor/InstructorDashboard";
import InstructorLessonDashboard from "pages/students/instructorLesson/InstructorLessonDashboard";
import LessonDashboard from "pages/students/lesson/LessonDashboard";
import LessonsDashboard from "pages/students/lessons/LessonsDashboard";
import ChangeCardDashboard from "pages/students/payment/changeCard/ChangeCardDashboard";
import UpgradePlanDashboard from "pages/students/payment/upgradeSubscription/UpgradePlanDashboard";
import SongDashboard from "pages/students/song/SongDashboard";
import SupportDashboard from "pages/students/support/SupportDashboard";

const routes = [
  {
    type: "href",
    name: "subCourseName",
    route: "subCourseRoute",
    key: "subCourseKey",
    component: <LessonsDashboard />,
    noCollapse: true,
  }, {
    type: "href",
    name: "subEducationName",
    route: "subEducationRoute",
    key: "subEducationKey",
    component: <LessonsDashboard />,
    noCollapse: true,
  },
  {
    type: "href",
    name: "videoName",
    route: "videoRoute",
    key: "videoKey",
    component: <SongDashboard />,
    noCollapse: true,
  },
  {
    type: "href",
    name: "studyName",
    route: "studyRoute",
    key: "studyKey",
    component: <LessonDashboard />,
    noCollapse: true,
  },
  {
    type: "href",
    name: "changeCardName",
    route: "changeCardRoute",
    key: "changeCardKey",
    component: <ChangeCardDashboard />,
    noCollapse: true,
  },
  {
    type: "href",
    name: "upgradePlanName",
    route: "upgradePlanRoute",
    key: "upgradePlanKey",
    component: <UpgradePlanDashboard />,
    noCollapse: true,
  },
  {
    type: "href",
    name: "instructorCourseName",
    route: "instructorCourseRoute",
    key: "instructorCourseKey",
    component: <CoursesDetailsDashboard />,
    noCollapse: true,
  },
  {
    type: "href",
    name: "instructorDashboardName",
    route: "instructorDashboardRoute",
    key: "instructorDashboardKey",
    component: <InstructorDashboard />,
    noCollapse: true,
  },
  {
    type: "href",
    name: "instructorLessonName",
    route: "instructorLessonRoute",
    key: "instructorLessonKey",
    component: <InstructorLessonDashboard />,
    noCollapse: true,
  },
  {
    type: "href",
    name: "supportDetailName",
    route: "supportDetailRoute",
    key: "supportKey",
    component: <SupportDashboard />,
    noCollapse: true,
  },

];

export default routes;
