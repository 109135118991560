import React from 'react'
import LessonNotePlayer from './LessonNotePlayer'
import { Grid } from '@mui/material';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LessonVideoPlayer from './LessonVideoPlayer';
import { selectlessonsById } from 'store/main/lessonsSlice';
import { useSelector } from 'react-redux';
import LessonVideoPlayerWithNote from './LessonVideoPlayerWithNote';
import MDBox from 'components/MDBox';
import { PlayerProvider } from 'hooks/PlayerProvider';



function LessonPlayerSite() {
  const handle = useFullScreenHandle();
  const { id } = useParams();
  const { isHaveNote } = useSelector((state) => selectlessonsById(state, id));


  const { t } = useTranslation();
  return (

    <PlayerProvider selectSongState={selectlessonsById}>
      <FullScreen handle={handle} className={"mainContainer"}>
        <MDBox sx={{ width: "100%", height: "100%", backgroundColor: "#32393D", display: "flex", flexDirection: "column" }}>
          <Grid container className={"GridContainer"}>
            <Grid item xs={12} className={"GridItem"}>
              {
                isHaveNote ?
                  <LessonVideoPlayerWithNote fullScreenHandler={handle} /> :
                  <LessonVideoPlayer />
              }
            </Grid>
          </Grid>

        </MDBox>
        {
          isHaveNote ?
            <LessonNotePlayer /> :
            null
        }
      </FullScreen>
    </PlayerProvider>

  )
}

export default LessonPlayerSite