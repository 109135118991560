import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageUpload from "components/UploadComponents/ImageUpload";
import { imageProxy } from "features/api/proxy";
import { useTranslation } from "react-i18next";
import {
  addCategory,
  closeEditCategoryDialog,
  closeNewCategoryDialog,
  updateCategory,
} from "store/main/categoriesSlice";
import { Grid } from "@mui/material";
const defaultFormState = {
  categoryId: 0,
  categoryNameTr: "",
  categoryDescriptionTr: "",
  categoryNameEn: "",
  categoryDescriptionEn: "",
  categoryNameDe: "",
  categoryDescriptionDe: "",
  categoryNameAr: "",
  categoryDescriptionAr: "",
  status: "active",
  type: "telli",
  url: "",
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
];

const instrumentOptions = [
  { id: "vurmalı", name: "vurmalı" },
  { id: "üflemeli", name: "üflemeli" },
  { id: "tuşlu", name: "tuşlu" },
  { id: "telli", name: "telli" },
  { id: "yaylı", name: "yaylı" },
];
function CategoryDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const categoryDialog = useSelector(
    ({ categories }) => categories.categoryDialog
  );

  const [photo, setphoto] = useState();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });
  const [instrumentValue, setInstrumentValue] = useState({
    id: "telli",
    name: "telli",
  });
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (categoryDialog.type === "edit" && categoryDialog.data) {
      setForm({ ...categoryDialog.data });
      fetch(`${imageProxy}/${categoryDialog.data.url}`)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], categoryDialog.data.url, { type: blob.type });
          setphoto(file);
        })
        .catch(error => {
          console.error('Dosya bulunamadı:', error);
        });
      let status = statusOptions.find(
        (f) => f.name === categoryDialog.data.status
      );
      setStatusValue(status);
      let instrument = instrumentOptions.find(
        (f) => f.name === categoryDialog.data.type
      );
      setInstrumentValue(instrument);

    }

    /**
     * Dialog type: 'new'
     */
    if (categoryDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...categoryDialog.data,
      });
      let status = statusOptions.find(
        (f) => f.name === defaultFormState.status
      );
      setStatusValue(status);
      let instrument = instrumentOptions.find(
        (f) => f.name === defaultFormState.type
      );
      setInstrumentValue(instrument);
      setphoto();
    }
  }, [categoryDialog.data, categoryDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (categoryDialog.props.open) {
      initDialog();
    }
  }, [categoryDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return categoryDialog.type === "edit"
      ? dispatch(closeEditCategoryDialog())
      : dispatch(closeNewCategoryDialog());
  }

  const handleSetImage = (file) => {
    setphoto(file);
    if (!file) {
      setInForm("url", null);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (categoryDialog.type === "new") {
      let data = {
        ...form,
        status: statusValues.id,
        type: instrumentValue.id,
        url: photo,
      };
      setphoto();
      dispatch(addCategory(data));
    } else {
      let data = {
        ...form,
        status: statusValues.id,
        type: instrumentValue.id,
        url: photo,
      };
      setphoto();
      dispatch(updateCategory(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    return (
      form.categoryNameTr.length > 0 && form.categoryDescriptionTr.length > 0 &&
      form.categoryNameEn.length > 0 && form.categoryDescriptionEn.length > 0 &&
      form.categoryNameDe.length > 0 && form.categoryDescriptionDe.length > 0 &&
      form.categoryNameAr.length > 0 && form.categoryDescriptionAr.length > 0 &&
      photo !== undefined
    );
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...categoryDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">{t("categoryInfo")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="categoryId"
          name="categoryId"
          label={t("categoryId")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.categoryId}
          disabled
        />
        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="categoryNameTr"
                name="categoryNameTr"
                label={t("categoryNameTr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.categoryNameTr}
                onChange={handleChange}
                required
                error={form.categoryNameTr ? false : true}
                helperText={t("enterCategoryNameTr")}
                focused
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                focused
                margin="dense"
                id="categoryDescriptionTr"
                name="categoryDescriptionTr"
                label={t("categoryDescriptionTr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.categoryDescriptionTr}
                onChange={handleChange}
                required
                error={form.categoryDescriptionTr ? false : true}
                helperText={t("enterCategoryDescriptionTr")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="categoryNameEn"
                name="categoryNameEn"
                label={t("categoryNameEn")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.categoryNameEn}
                onChange={handleChange}
                required
                error={form.categoryNameEn ? false : true}
                helperText={t("enterCategoryNameEn")}
                focused
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="categoryDescriptionEn"
                name="categoryDescriptionEn"
                label={t("categoryDescriptionEn")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.categoryDescriptionEn}
                onChange={handleChange}
                required
                error={form.categoryDescriptionEn ? false : true}
                helperText={t("enterCategoryDescriptionEn")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="categoryNameDe"
                name="categoryNameDe"
                label={t("categoryNameDe")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.categoryNameDe}
                onChange={handleChange}
                required
                error={form.categoryNameDe ? false : true}
                helperText={t("enterCategoryNameDe")}
                focused
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="categoryDescriptionDe"
                name="categoryDescriptionDe"
                label={t("categoryDescriptionDe")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.categoryDescriptionDe}
                onChange={handleChange}
                required
                error={form.categoryDescriptionDe ? false : true}
                helperText={t("enterCategoryDescriptionDe")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="categoryNameAr"
                name="categoryNameAr"
                label={t("categoryNameAr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.categoryNameAr}
                onChange={handleChange}
                required
                error={form.categoryNameAr ? false : true}
                helperText={t("enterCategoryNameAr")}
                focused
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="categoryDescriptionAr"
                name="categoryDescriptionAr"
                label={t("categoryDescriptionAr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.categoryDescriptionAr}
                onChange={handleChange}
                required
                error={form.categoryDescriptionAr ? false : true}
                helperText={t("enterCategoryDescriptionAr")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Autocomplete
            value={instrumentValue}
            options={instrumentOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("instrumentType")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setInstrumentValue({
                  id: newValue,
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                setInstrumentValue({
                  name: newValue.inputValue,
                });
              } else {
                setInstrumentValue(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox mt={2}>
          <Autocomplete
            value={statusValues} // Dikkat: statusValues -> statusValue olarak değiştirildi
            options={statusOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("status")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                setStatusValue({
                  id: newValue, // newValue.id -> newValue olarak değiştirildi
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                setStatusValue({
                  name: newValue.inputValue,
                });
              } else {
                setStatusValue(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox mt={2}>
          <ImageUpload
            handleSetImage={handleSetImage}
            file={photo}
            url={form && form.url ? `${imageProxy}/${form.url}` : null}
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CategoryDialog;
