import { Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import ImageUpload from "components/UploadComponents/ImageUpload";
import VideoUpload from "components/UploadComponents/VideoUpload";
import { imageProxy } from "features/api/proxy";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectinstruments } from "store/main/instrumentsSlice";
import { selectmusicians } from "store/main/musiciansSlice";
import {
  addSongList,
  closeEditSongListDialog,
  closeNewSongListDialog,
  updateSongList,
} from "store/main/songListsSlice";
import { selectsongs } from "store/main/songsSlice";

const defaultFormState = {
  listId: 0,
  songId: 0,
  musicianId: 0,
  instrumentId: 0,
  level: "",
  status: "active",
  description: "",
  videoStart: 0,
  videoMain: 0,
  videoEnd: 0,
  folderNumber: 0,
  loopNoteStart: 0,
  loopNoteEnd: 0,
  noteStart: 0,
  noteMain: 0,
  noteEnd: 0,
  noteTolerance: 0,
  noteHeight: 0,
  noteWidth: 0,
  duration: "",
  bpm: 0,
  measure: "",
  measureWidth: 0,
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
];

function SongListDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const songListDialog = useSelector(
    ({ songLists }) => songLists.songListDialog
  );
  const songs = useSelector(selectsongs);
  const musicians = useSelector(selectmusicians);
  const instruments = useSelector(selectinstruments);
  const [photo, setphoto] = useState();
  const [video, setVideo] = useState();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });
  const [songValue, setSongValue] = useState({
    id: 0,
    name: "",
  });
  const [musicianValue, setmusicianValue] = useState({
    id: 0,
    name: "",
  });
  const [instrumentValue, setInstrumentValue] = useState({
    id: 0,
    name: "",
  });
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (songListDialog.type === "edit" && songListDialog.data) {
      setForm({ ...songListDialog.data });

      console.log("Dialog", songListDialog.data);

      let song = songs.find((f) => f.songId === songListDialog.data.songId);
      let musician = musicians.find(
        (f) => f.musicianId === songListDialog.data.musicianId
      );
      let instrument = instruments.find(
        (f) => f.instrumentId === songListDialog.data.instrumentId
      );
      let status = statusOptions.find(
        (f) => f.name === songListDialog.data.status
      );
      setSongValue(song);
      setmusicianValue(musician);
      setStatusValue(status);
      setInstrumentValue(instrument);
      fetch(`${imageProxy}/${songListDialog.data.url}`)
        .then(response => response.blob())
        .then(blob => {
          console.log(blob);
          const file = new File([blob], songListDialog.data.url, { type: blob.type });
          setphoto(file);
        })
        .catch(error => {
          console.error('Dosya bulunamadı:', error);
        });
    }

    /**
     * Dialog type: 'new'
     */
    if (songListDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...songListDialog.data,
      });
      setphoto();
      setmusicianValue();
      setSongValue();
      setInstrumentValue();
    }
  }, [songListDialog.data, songListDialog.type, setForm, instruments, musicians, songs]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (songListDialog.props.open) {
      initDialog();
    }
  }, [songListDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return songListDialog.type === "edit"
      ? dispatch(closeEditSongListDialog())
      : dispatch(closeNewSongListDialog());
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (songListDialog.type === "new") {
      let data = {
        ...form,
        url: photo,
        songUrl: video,
        songId: songValue.id,
        musicianId: musicianValue.id,
        instrumentId: instrumentValue.id,
      };
      setphoto();
      setmusicianValue();
      setSongValue();
      setVideo();
      setInstrumentValue();
      dispatch(addSongList(data));
    } else {
      let data = {
        ...form,
        url: photo,
        songUrl: video,
        songId: songValue.id,
        mucisianId: musicianValue.id,
        instrumentId: instrumentValue.id,
      };
      console.log(data);
      setphoto();
      setmusicianValue();
      setSongValue();
      setVideo();
      setInstrumentValue();
      dispatch(updateSongList(data));
    }
    closeComposeDialog();
  }

  const handleSetImage = (file) => {
    setphoto(file);
    if (!file) {
      setInForm("url", null);
    }
  };

  const handleSetVideo = (file) => {
    setVideo(file);
    if (!file) {
      setInForm("songUrl", null);
    }
  };

  function canBeSubmitted() {

    return (
      // songValue !== undefined && instrumentValue !== undefined && musicianValue !== undefined &&
      // form.level.length > 0 && form.description.length > 0 &&
      // form.videoStart > 0 && form.videoMain > 0 && form.videoEnd > 0 &&
      // form.noteStart > 0 && form.noteMain > 0 && form.noteEnd > 0 &&
      // form.noteHeight > 0 && form.noteWidth > 0 && form.bpm > 0
      true
    );
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...songListDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">{t("songListInfo")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="listId"
          name="listId"
          label={t("listId")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.listId}
          disabled
        />

        <Grid container spacing={1}>
          <Grid item xs={12} lg={4} mt={1}>
            <Autocomplete
              value={songValue}
              options={songs}
              getOptionLabel={(option) => option.name}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              // fullWidth
              renderInput={(params) => (
                <TextField {...params} label={t("selectSong")} />
              )}
              onChange={(event, newValue) => {
                if (typeof newValue.name === "string") {
                  setSongValue({
                    id: newValue.id,
                    name: newValue.name,
                  });
                } else if (newValue && newValue.inputValue) {
                  setSongValue({
                    name: newValue.inputValue,
                  });
                } else {
                  setSongValue(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mt={1}>
            <Autocomplete
              value={musicianValue}
              options={musicians}
              getOptionLabel={(option) => option.name}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              // fullWidth
              renderInput={(params) => (
                <TextField {...params} label={t("selectMusician")} />
              )}
              onChange={(event, newValue) => {
                if (typeof newValue.name === "string") {
                  setmusicianValue({
                    id: newValue.id,
                    name: newValue.name,
                  });
                } else if (newValue && newValue.inputValue) {
                  setmusicianValue({
                    name: newValue.inputValue,
                  });
                } else {
                  setmusicianValue(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mt={1} >
            <Autocomplete
              value={instrumentValue}
              options={instruments}
              getOptionLabel={(option) => option.name}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              // fullWidth
              renderInput={(params) => (
                <TextField {...params} label={t("selectInstrument")} />
              )}
              onChange={(event, newValue) => {
                if (typeof newValue.name === "string") {
                  setInstrumentValue({
                    id: newValue.id,
                    name: newValue.name,
                  });
                } else if (newValue && newValue.inputValue) {
                  setInstrumentValue({
                    name: newValue.inputValue,
                  });
                } else {
                  setInstrumentValue(newValue);
                }
              }}
            />
          </Grid>
        </Grid>



        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              id="level"
              name="level"
              label={t("level")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.level}
              onChange={handleChange}
              required
              error={form.level ? false : true}
              focused
              helperText={t("enterLevel")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              id="description"
              name="description"
              label={t("description")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.description}
              onChange={handleChange}
              required
              error={form.description ? false : true}
              focused
              helperText={t("enterDescription")}
            />
          </Grid>
        </Grid>

        <MDBox>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="videoStart"
                name="videoStart"
                label={t("videoStart")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.videoStart}
                onChange={handleChange}
                required
                error={form.videoStart ? false : true}
                focused
                helperText={t("enterVideoStart")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="videoMain"
                name="videoMain"
                label={t("videoMain")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.videoMain}
                onChange={handleChange}
                required
                error={form.videoMain ? false : true}
                focused
                helperText={t("enterVideoMain")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="videoEnd"
                name="videoEnd"
                label={t("videoEnd")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.videoEnd}
                onChange={handleChange}
                required
                error={form.videoEnd ? false : true}
                focused
                helperText={t("enterVideoEnd")}
              />
            </Grid>

          </Grid>
        </MDBox>

        <MDBox>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="noteStart"
                name="noteStart"
                label={t("noteStart")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.noteStart}
                onChange={handleChange}
                required
                error={form.noteStart ? false : true}
                focused
                helperText={t("enterNoteStart")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="noteMain"
                name="noteMain"
                label={t("noteMain")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.noteMain}
                onChange={handleChange}
                required
                error={form.noteMain ? false : true}
                focused
                helperText={t("enterNoteMain")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="noteEnd"
                name="noteEnd"
                label={t("noteEnd")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.noteEnd}
                onChange={handleChange}
                required
                error={form.noteEnd ? false : true}
                focused
                helperText={t("enterNoteEnd")}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="noteTolerance"
                name="noteTolerance"
                label={t("noteTolerance")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.noteTolerance}
                onChange={handleChange}
                required
                focused
                helperText={t("enterNoteTolerance")}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="loopNoteStart"
                name="loopNoteStart"
                label={t("loopNoteStart")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.loopNoteStart}
                onChange={handleChange}
                required
                error={form.loopNoteStart ? false : true}
                focused
                helperText={t("enterLoopNoteStart")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="loopNoteEnd"
                name="loopNoteEnd"
                label={t("loopNoteEnd")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.loopNoteEnd}
                onChange={handleChange}
                required
                error={form.loopNoteEnd ? false : true}
                focused
                helperText={t("enterLoopNoteEnd")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="measure"
                name="measure"
                label={t("measure")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.measure}
                onChange={handleChange}
                required
                error={form.measure ? false : true}
                focused
                helperText={t("enterMeasure")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="measureWidth"
                name="measureWidth"
                label={t("measureWidth")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.measureWidth}
                onChange={handleChange}
                required
                error={form.measureWidth ? false : true}
                focused
                helperText={t("enterMeasureWidth")}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="noteHeight"
                name="noteHeight"
                label={t("noteHeight")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.noteHeight}
                onChange={handleChange}
                required
                error={form.noteHeight ? false : true}
                focused
                helperText={t("enterNoteHeight")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="noteWidth"
                name="noteWidth"
                label={t("noteWidth")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.noteWidth}
                onChange={handleChange}
                required
                error={form.noteWidth ? false : true}
                focused
                helperText={t("enterNoteWidth")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="bpm"
                name="bpm"
                label={t("bpm")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.bpm}
                onChange={handleChange}
                required
                error={form.bpm ? false : true}
                focused
                helperText={t("enterBpm")}
              />
            </Grid>
          </Grid>
        </MDBox>



        <MDBox>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="folderNumber"
                name="folderNumber"
                label={t("folderNumber")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.folderNumber}
                onChange={handleChange}
                required
                error={form.folderNumber ? false : true}
                focused
                helperText={t("enterFolderNumber")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="duration"
                name="duration"
                label={t("duration")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.duration}
                onChange={handleChange}
                required
                error={form.duration ? false : true}
                focused
                helperText={t("enterDuration")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Autocomplete
            value={statusValues}
            options={statusOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("status")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue.name === "string") {
                setStatusValue({
                  id: newValue.id,
                  name: newValue.name,
                });
              } else if (newValue && newValue.inputValue) {
                setStatusValue({
                  name: newValue.inputValue,
                });
              } else {
                setStatusValue(newValue);
              }
            }}
          />
        </MDBox>

        <MDBox mt={2}>
          <ImageUpload
            handleSetImage={handleSetImage}
            file={photo}
            url={form && form.url ? `${imageProxy}/${form.url}` : null}
          />
        </MDBox>

        <MDBox mt={2}>
          <VideoUpload
            handleSetImage={handleSetVideo}
            file={video}
            url={form && form.songUrl ? `${imageProxy}/${form.songUrl}` : null}
          />
        </MDBox>

        <MDBox mt={2}>
          {
            form.folderNumber && form.folderNumber.length > 0 &&
            <img style={{ width: "100%" }} src={`${imageProxy}/${form.folderNumber}/${form.folderNumber}.svg`} alt={form.folderNumber} />
          }
        </MDBox>

        {/* <MDBox mt={2}>
          <SvgUpload
            handleSetImage={handleSetImage}
            file={photo}
            url={form && form.url ? `${imageProxy}/${form.url}` : null}
          />
        </MDBox> */}
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SongListDialog;
