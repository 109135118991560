import { Card, Grid } from '@mui/material'
import InstructorCoursesCard from 'components/Cards/InstructorCoursesCard';
import DefaultCard from 'components/Cards/SongCard/DefaultCard';
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography';
import { imageProxy } from 'features/api/proxy';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectinstructorCourses } from 'store/main/instructorCoursesSlice';

function CoursesList() {
  const courses = useSelector(selectinstructorCourses);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const { t, i18n } = useTranslation();

  console.log(courses);

  const { searchText, instrumentValue, instructorValue } = useSelector((state) => state.instructorCourses);


  useEffect(() => {
    const filteredList = courses
      .filter((f) => {
        if (searchText !== "") {
          return (
            f.courseName.toLowerCase().includes(searchText.toLowerCase()) ||
            (
              (i18n.language.match("tr") && f.courseDescriptionTr.toLowerCase().includes(searchText.toLowerCase())) ||
              (i18n.language.match("en") && f.courseDescriptionEn.toLowerCase().includes(searchText.toLowerCase())) ||
              (i18n.language.match("de") && f.courseDescriptionDe.toLowerCase().includes(searchText.toLowerCase())) ||
              (i18n.language.match("ar") && f.courseDescriptionAr.toLowerCase().includes(searchText.toLowerCase()))
            )
          )
        }
        return f;
      })
      .filter((f) => {
        if (instrumentValue.id !== "") {
          return f.instrumentId === instrumentValue.id
        }
        return f;
      })
      .filter((f) => {
        if (instructorValue.id !== "") {
          return f.instructorId === instructorValue.id
        }
        return f;
      });

    setFilteredCourses(filteredList);
  }, [searchText, courses, instrumentValue, instructorValue, i18n.language]);

  if (filteredCourses.length === 0) {
    return <Card>
      <MDBox p={3}>
        <MDTypography fontSize={{ xs: "1.1rem", lg: "1.2rem", xl: "1.3rem" }} fontWeight={"bold"}>
          {t("courseDoesntMatch")}
        </MDTypography>
      </MDBox>
    </Card>
  }

  return (
    <Card>
      <MDBox p={3}>
        <Grid container spacing={2}>
          {
            filteredCourses.map((course, key) => {
              return <Grid mt={2} key={key} item xs={12} md={6} xl={3}>
                <DefaultCard
                  details={{
                    type: "instructorCourse",
                    route: `/${t("education")}/${course.courseId}`,
                    key: key,
                  }}
                  image={`${imageProxy}/${course.url}`}
                  title={course.courseName}
                  description={
                    (i18n.language.match("tr") && course.courseDescriptionTr) ||
                    (i18n.language.match("en") && course.courseDescriptionEn) ||
                    (i18n.language.match("de") && course.courseDescriptionDe) ||
                    (i18n.language.match("ar") && course.courseDescriptionAr)
                  }
                  duration={course.instructorCourseLessons.length + " Ders"}
                  instructor={{
                    name: course.courseinstructorInfo.instructorName,
                    image: `${imageProxy}/${course.courseinstructorInfo.url}`,
                    id: course.courseinstructorInfo.instructorId,
                  }}
                />
              </Grid>
            })
          }
        </Grid>
      </MDBox>
    </Card>
  )
}

export default CoursesList;