/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FaqCollapse from "./FaqCollapse";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";

const faqs = [
  { question: "question1", answer1: "answer1_1", answer2: "answer1_2" },
  { question: "question2", answer1: "answer2_1", answer2: "answer2_2" },
  { question: "question3", answer1: "answer3_1", answer2: "answer3_2" },
  { question: "question4", answer1: "answer4_1", answer2: "answer4_2" },
  { question: "question5", answer1: "answer5_1", answer2: "answer5_2" },
  { question: "question6", answer1: "answer6_1", answer2: "answer6_2" },
  { question: "question7", answer1: "answer7_1", answer2: "answer7_2" },
  { question: "question8", answer1: "answer8_1", answer2: "answer8_2" },
]

function Faq() {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(false);

  return (
    <MDBox mt={14} mb={14}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={11}>
          <MDTypography
            fontSize={{ xs: "1.4rem", lg: "1.7rem", xl: "2rem" }}
            align="center"
            fontWeight="bold"
            gutterBottom
          >
            {t("faq_header")}
          </MDTypography>
          <MDBox mb={2}>
            <MDTypography
              variant="body2"
              align="center"
              color="text">
              {
                t("faq_description")
              }
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12}>

          {
            faqs.map((item, key) => {
              return <FaqCollapse
                key={key}
                title={t(item.question)}
                open={collapse === key}
                onClick={() => (collapse === key ? setCollapse(false) : setCollapse(key))}
              >
                {t(item.answer1)}
                {t(item.answer2) !== item.answer2 && t(item.answer2)}
              </FaqCollapse>
            })
          }
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Faq;
