import React, { useEffect, useState } from "react";
import AdminLayout from "pages/main/layout/AdminLayout";
import PricingPlansDialog from "./PricingPlansDialog";
import { useDispatch } from "react-redux";
import LoadingComponent from "components/Loading";
import PricingPlansTable from "./PricingPlansTable";
import { getPricingPlans } from "store/main/pricingPlansSlice";
import { removePricingPlan } from "store/main/pricingPlansSlice";
import ConfirmDialog from "pages/confirmation/ConfirmDialog";

const AdminPricingPlansDashboard = (props) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(getPricingPlans()).then(() => setDataLoading(false));
  }, [dispatch]);
  
  if (dataLoading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <PricingPlansTable />
      <PricingPlansDialog />
      <ConfirmDialog handleDeleteData={removePricingPlan} />
    </AdminLayout>
  );
};

AdminPricingPlansDashboard.propTypes = {};

export default AdminPricingPlansDashboard;
