import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useForm } from "features/hooks";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addSubscription,
  closeEditSubscriptionDialog,
  closeNewSubscriptionDialog,
  updateSubscription,
} from "store/iyzico/iyzicoSubscriptionsSlice";
import { Grid } from "@mui/material";
import OrderCollapse from "./components/OrdersCollapse";

const defaultFormState = {
  referenceCode: "",
  parentReferenceCode: "",
  pricingPlanReferenceCode: "",
  pricingPlanName: "",
  customerEmail: "",
  customerGsmNumber: "",
  productName: "",
  customerReferenceCode: "",
  subscriptionStatus: "",
  orders: [],
};

function IyzicoSubscriptionDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const subscriptionDialog = useSelector(
    ({ iyzicoSubscriptions }) => iyzicoSubscriptions.subscriptionDialog
  );

  const { form, handleChange, setForm } = useForm(defaultFormState);

  const initDialog = useCallback(async () => {
    /**
     * Dialog type: 'edit'
     */
    if (subscriptionDialog.type === "edit" && subscriptionDialog.data) {
      setForm({ ...subscriptionDialog.data });
    }

    /**
     * Dialog type: 'new'
     */
    if (subscriptionDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...subscriptionDialog.data,
      });
    }
  }, [subscriptionDialog.data, subscriptionDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (subscriptionDialog.props.open) {
      initDialog();
    }
  }, [subscriptionDialog.props.open, initDialog]);

  function closeSubscriptionDialog() {
    return subscriptionDialog.type === "edit"
      ? dispatch(closeEditSubscriptionDialog())
      : dispatch(closeNewSubscriptionDialog());
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (subscriptionDialog.type === "new") {
      let data = {
        ...form,
        subscriptionReferenceCode: form.referenceCode,
      };
      console.log(data);
      // dispatch(addSubscription(data));
    } else {
      let data = {
        ...form,
        subscriptionReferenceCode: form.referenceCode,
      };
      dispatch(updateSubscription(data));
    }
    closeSubscriptionDialog();
  }

  function canBeSubmitted() {
    return (
      // form.customer.name.length > 0 &&
      // form.customer.surname.length > 0 &&
      // form.customer.email.length > 0 &&
      // form.customer.gsmNumber.length > 0 &&
      // form.customer.billingAddress.address.length > 0 &&
      // form.customer.billingAddress.city.length > 0 &&
      // form.customer.billingAddress.country.length > 0 &&
      // form.customer.billingAddress.zipCode.length > 0 &&
      // form.customer.shippingAddress.address.length > 0 &&
      // form.customer.shippingAddress.city.length > 0 &&
      // form.customer.shippingAddress.country.length > 0 &&
      // form.customer.shippingAddress.zipCode.length > 0
      true
    );
  }

  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...subscriptionDialog.props}
      onClose={closeSubscriptionDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="subscription-dialog-title">
        {t("subscriptionInfo")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              autoFocus
              margin="dense"
              id="referenceCode"
              name="referenceCode"
              label={t("subscriptionReferenceCode")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.referenceCode}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              autoFocus
              margin="dense"
              id="parentReferenceCode"
              name="parentReferenceCode"
              label={t("parentReferenceCode")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.parentReferenceCode}
              onChange={handleChange}
              disabled
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              autoFocus
              margin="dense"
              id="pricingPlanName"
              name="pricingPlanName"
              label={t("pricingPlanName")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.pricingPlanName}
              onChange={handleChange}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              autoFocus
              margin="dense"
              id="pricingPlanReferenceCode"
              name="pricingPlanReferenceCode"
              label={t("pricingPlanReferenceCode")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.pricingPlanReferenceCode}
              onChange={handleChange}
              disabled
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              autoFocus
              margin="dense"
              id="productName"
              name="productName"
              label={t("productName")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.productName}
              onChange={handleChange}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              autoFocus
              margin="dense"
              id="productReferenceCode"
              name="productReferenceCode"
              label={t("productReferenceCode")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.productReferenceCode}
              onChange={handleChange}
              disabled
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <TextField
              autoFocus
              margin="dense"
              id="customerEmail"
              name="customerEmail"
              label={t("email")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.customerEmail}
              onChange={handleChange}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoFocus
              margin="dense"
              id="customerGsmNumber"
              name="customerGsmNumber"
              label={t("gsmNumber")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.customerGsmNumber}
              onChange={handleChange}
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              autoFocus
              margin="dense"
              id="subscriptionStatus"
              name="subscriptionStatus"
              label={t("status")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.subscriptionStatus}
              onChange={handleChange}
              disabled
            />
          </Grid>
        </Grid>
        <OrderCollapse orders={form.orders}/>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeSubscriptionDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default IyzicoSubscriptionDialog;