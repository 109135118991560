import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { imageProxy } from "features/api/proxy";
import { closeEditPricingPlanDialog } from "store/main/pricingPlansSlice";
import { closeNewPricingPlanDialog } from "store/main/pricingPlansSlice";
import { addPricingPlan } from "store/main/pricingPlansSlice";
import { addPaypalPricingPlan } from "store/main/pricingPlansSlice";


import PackageInformation from "./components/PackageInformation";
import IyzicoInformation from "./components/IyzicoInformations";
import MDBox from "components/MDBox";
import PayPalInformations from "./components/PayPalInformation.jsx";
import { productId } from "features/api/paypalConfig";
import { Collapse } from "@mui/material";

const defaultFormState = {
  pricingPlanId: 0,
  pricingPlanReference: "",
  iyzicoProductReference: "bb63e8fd-7dc6-4b9a-8ddb-fbb878bd6ceb",
  paypalProductReference: productId,
  pricingPlanNameTr: "",
  pricingPlanDescriptionTr: "",
  pricingPlanNameEn: "",
  pricingPlanDescriptionEn: "",
  pricingPlanNameDe: "",
  pricingPlanDescriptionDe: "",
  pricingPlanNameAr: "",
  pricingPlanDescriptionAr: "",
  price: 0,
  discount: 0,
  trialPeriodDays: 0,
  currencyISO: "",
  countryISO: "",
  feature1: false,
  feature2: false,
  feature3: false,
  shouldLesson: false,
  shouldSong: false,
  shouldFeedback: false,
  forStudent: false,
  iyzicoPricingPlanName: "",
  paypalPricingPlanName: "",
  pricingPlanDescription: "",
  paymentInterval: "",
  paymentIntervalCount: 1,
  status: "active",
  url: "",
};

const paymentIntervalOptions = [
  { id: "MONTH1", name: "MONTH1", count: 1 },
  { id: "MONTH6", name: "MONTH6", count: 6 },
  { id: "MONTH12", name: "MONTH12", count: 12 },
];

const typeOptions = [
  { id: "TRIAL", name: "TRIAL" },
  { id: "MONTHLY", name: "MONTHLY" },
  { id: "CUSTOM", name: "CUSTOM" },
]

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
];

const payPlatformOptions = [
  { id: "iyzico", name: "iyzico" },
  { id: "paypal", name: "paypal" },
]

function PricingPlansDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const pricingPlanDialog = useSelector(
    ({ pricingPlans }) => pricingPlans.pricingPlanDialog
  );

  const [photo, setphoto] = useState();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [paymentIntervalValue, setPaymentIntervalValue] = useState({
    id: "MONTH1",
    name: "MONTH1",
    count: 1,
  });

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });

  const [payPlatformValue, setPayPlatformValue] = useState({
    id: "iyzico",
    name: "iyzico",
  });

  const [typeValue, setTypeValue] = useState({
    id: "MONTHLY",
    name: "MONYHLY",
  })

  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (pricingPlanDialog.type === "edit" && pricingPlanDialog.data) {
      setForm({ ...pricingPlanDialog.data });
      fetch(`${imageProxy}/${pricingPlanDialog.data.url}`)
        .then(response => response.blob())
        .then(blob => {
          console.log(blob);
          const file = new File([blob], pricingPlanDialog.data.url, { type: blob.type });
          setphoto(file);
        })
        .catch(error => {
          console.error('Dosya bulunamadı:', error);
        });
      let status = statusOptions.find(
        (f) => f.name === pricingPlanDialog.data.status
      );
      setStatusValue(status);
      let paymentInterval = paymentIntervalOptions.find(

        (f) => f.name === pricingPlanDialog.data.paymentInterval
      );
      console.log("GÖR", paymentInterval);
      setPaymentIntervalValue(paymentInterval);
    }

    /**
     * Dialog type: 'new'
     */
    if (pricingPlanDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...pricingPlanDialog.data,
      });
      setphoto();
    }
  }, [pricingPlanDialog.data, pricingPlanDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (pricingPlanDialog.props.open) {
      initDialog();
    }
  }, [pricingPlanDialog.props.open, initDialog]);

  const [expanded, setExpanded] = useState(false);

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function closeComposeDialog() {
    setExpanded(false);
    return pricingPlanDialog.type === "edit"
      ? dispatch(closeEditPricingPlanDialog())
      : dispatch(closeNewPricingPlanDialog());
  }

  const handleSetImage = (file) => {
    setphoto(file);
    if (!file) {
      setInForm("url", null);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (pricingPlanDialog.type === "new") {
      let data = {
        ...form,
        url: photo,
        paymentInterval: paymentIntervalValue.name,
        payPlatform: payPlatformValue.name,
        paymentIntervalCount: paymentIntervalValue.count,
      };
      console.log("new data", data)
      setphoto();
      if (payPlatformValue.name === "iyzico") {
        dispatch(addPricingPlan(data));
      }
      else {
        dispatch(addPaypalPricingPlan(data));
      }
    } else {
      let data = {
        ...form,
        url: photo,
      };
      console.log("data to update", data);
      setphoto();
      // dispatch(updatePricingPlan(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    return (
      form.pricingPlanNameTr.length > 0 && form.pricingPlanDescriptionTr.length > 0 &&
      form.pricingPlanNameEn.length > 0 && form.pricingPlanDescriptionEn.length > 0 &&
      form.pricingPlanNameAr.length > 0 && form.pricingPlanDescriptionAr.length > 0 &&
      form.price > 0 && photo !== undefined
    );
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...pricingPlanDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">
        {t("pricingPlanInfo")}
      </DialogTitle>
      <DialogContent>
        <MDBox >
          <PackageInformation
            expanded={expanded}
            handleExpand={handleExpand}
            form={form}
            handleChange={handleChange}
            photo={photo}
            handleSetImage={handleSetImage}
            paymentIntervalOptions={paymentIntervalOptions}
            paymentIntervalValue={paymentIntervalValue}
            setPaymentIntervalValue={setPaymentIntervalValue}
            setStatusValue={setStatusValue}
            statusOptions={statusOptions}
            statusValues={statusValues}
            setPayPlatformValue={setPayPlatformValue}
            payPlatformOptions={payPlatformOptions}
            payPlatformValue={payPlatformValue}
            typeOptions={typeOptions}
            typeValue={typeValue}
            setTypeValue={setTypeValue}
          />
        </MDBox>

        <MDBox>
          <Collapse in={typeValue.id === "MONTHLY" ? true : false}>
            {
              payPlatformValue.name === "iyzico" ? <IyzicoInformation
                expanded={expanded}
                handleExpand={handleExpand}
                form={form}
                handleChange={handleChange}
                paymentIntervalOptions={paymentIntervalOptions}
                paymentIntervalValue={paymentIntervalValue}
                setPaymentIntervalValue={setPaymentIntervalValue}
              /> : <PayPalInformations
                expanded={expanded}
                handleExpand={handleExpand}
                form={form}
                handleChange={handleChange}
                paymentIntervalOptions={paymentIntervalOptions}
                paymentIntervalValue={paymentIntervalValue}
                setPaymentIntervalValue={setPaymentIntervalValue}
              />
            }
          </Collapse>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PricingPlansDialog;
