/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components

// core components
import defaultImage from "assets/images/image_placeholder.jpg";
import defaultAvatar from "assets/images/placeholder.jpg";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

function VideoUpload(props) {
  const [fileState, setFileState] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  );

  const { handleSetImage } = props;
  const fileInput = React.useRef();
  const { t } = useTranslation();

  useEffect(() => {
    setFileState(props.file);
    let reader = new FileReader();

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    if (props.file) {
      reader.readAsDataURL(props.file);
    } else {
      setFileState(null);
      setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    }
  }, [props.file, props.avatar]);

  useEffect(() => {
    if (props.url && props.url.length > 0) {
      setImagePreviewUrl(props.url);
      setFileState(props.url);
    }
  }, [props.url]);

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFileState(file);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    handleSetImage(file);
  };
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();
    // fileState is the file/image uploaded
    // in this function you can save the image (fileState) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    fileInput.current.value = null;
    setFileState(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    handleSetImage(null);
  };
  return (
    <div className="fileinput text-center">
      <input
        type="file"
        onChange={handleImageChange}
        ref={fileInput}
        accept="video/*"
      />
      <div className={"thumbnail" + (props.avatar ? " img-circle" : "")}>
        <video
          controls
          src={imagePreviewUrl}
          style={{ maxWidth: "500px", width: "300px" }}
          className="mt-3"
        />
      </div>
      <div>
        {fileState === null ? (
          <Button className="btn-round" onClick={() => handleClick()}>
            {props.avatar ? t("addPhoto") : t("selectVideo")}
          </Button>
        ) : (
          <span>
            <Button onClick={() => handleClick()}>{t("change")}</Button>
            {props.avatar ? <br /> : null}
            <Button
              color="secondary"
              className="btn-round mt-5"
              onClick={() => handleRemove()}
            >
              {/* <i className="fa fa-times" /> */}
              {t("remove")}
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

VideoUpload.propTypes = {
  avatar: PropTypes.bool,
};

export default VideoUpload;
