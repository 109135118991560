import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
  } from "@reduxjs/toolkit";
  import axiosConfig from "../../features/api/axiosConfig";
  
  
  export const getinstructors = createAsyncThunk(
    "instructors/getinstructors",
    async () => {
      const response = await axiosConfig.get(`/api/instructors`);
      // const response = await axios.get(`${proxy}/api/instructors`)
  
      let { data } = await response.data;
      return data;
    }
  );
  
  export const addinstructor = createAsyncThunk(
    "instructors/addinstructor",
    async (instructor, { dispatch, getState }) => {
      try {
        let formData = new FormData();
  
        formData.append("url", instructor.url);
        formData.append("instructorName", instructor.instructorName);
        formData.append("instructorSurname", instructor.instructorSurname);
        formData.append("instructorDescriptionTr", instructor.instructorDescriptionTr);
        formData.append("instructorDescriptionEn", instructor.instructorDescriptionEn);
        formData.append("instructorDescriptionDe", instructor.instructorDescriptionDe);
        formData.append("instructorDescriptionAr", instructor.instructorDescriptionAr);
        formData.append("status", instructor.status);
        formData.append("userName", instructor.userName);
        formData.append("password", instructor.password);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            boundary: formData._boundaries,
          },
        };
  
        const response = await axiosConfig.post(
          `/api/users/create-instructor`,
          formData,
          config
        );
        let { data } = await response.data;
        if (response.data.success === true) {
          return data;
        }
      } catch (error) {

        return null;
      }
    }
  );
  
  export const updateinstructor = createAsyncThunk(
    "instructors/updateinstructor",
    async (instructor, { dispatch, getState }) => {
      let formData = new FormData();
  
      formData.append("instructorId", instructor.instructorId);
      formData.append("url", instructor.url);
      formData.append("instructorName", instructor.instructorName);
      formData.append("instructorDescriptionTr", instructor.instructorDescriptionTr);
      formData.append("instructorDescriptionEn", instructor.instructorDescriptionEn);
      formData.append("instructorDescriptionDe", instructor.instructorDescriptionDe);
      formData.append("instructorDescriptionAr", instructor.instructorDescriptionAr);
      formData.append("status", instructor.status);
      formData.append("userName", instructor.userName);
  
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };
  
      const response = await axiosConfig.put(
        `/api/instructors/${instructor.instructorId}`,
        formData,
        config
      );
      const { data } = await response.data;
      if (response.data.success === true) {
        return data;
      }
      return null;
    }
  );
  
  export const removeinstructor = createAsyncThunk(
    "instructors/removeinstructor",
    async (instructorId, { dispatch, getState }) => {
      let response = await axiosConfig.delete(`/api/instructors/${instructorId}`);
      if (response.data.success === true) {

        return instructorId;
      }
      return instructorId;
    }
  );
  
  const instructorsAdapter = createEntityAdapter({
    selectId: (instructor) => instructor.instructorId,
  });
  
  export const { selectAll: selectinstructors, selectById: selectinstructorsById } =
    instructorsAdapter.getSelectors((state) => state.instructors);
  
  const instructorsSlice = createSlice({
    name: "instructors",
    initialState: instructorsAdapter.getInitialState({
      searchText: "",
      routeParams: {},
      instructorDialog: {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      },
      confirmDialog: {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      },
    }),
    reducers: {
      setinstructorSearchText: {
        reducer: (state, action) => {
          state.searchText = action.payload;
        },
        prepare: (event) => ({ payload: event.target.value || "" }),
      },
      openNewinstructorDialog: (state, action) => {
        state.instructorDialog = {
          type: "new",
          props: {
            open: true,
          },
          data: null,
        };
      },
      closeNewinstructorDialog: (state, action) => {
        state.instructorDialog = {
          type: "new",
          props: {
            open: false,
          },
          data: null,
        };
      },
      openEditinstructorDialog: (state, action) => {
        state.instructorDialog = {
          type: "edit",
          props: {
            open: true,
          },
          data: action.payload,
        };
      },
      closeEditinstructorDialog: (state, action) => {
        state.instructorDialog = {
          type: "edit",
          props: {
            open: false,
          },
          data: null,
        };
      },
      openConfirmDialog: (state, action) => {
        state.confirmDialog = {
          type: "new",
          props: {
            open: true,
          },
          data: action.payload,
        };
      },
      closeConfirmDialog: (state, action) => {
        state.confirmDialog = {
          type: "new",
          props: {
            open: false,
          },
          data: action.payload,
        };
      },
    },
    extraReducers: {
      [updateinstructor.fulfilled]: instructorsAdapter.upsertOne,
      [addinstructor.fulfilled]: instructorsAdapter.addOne,
      [removeinstructor.fulfilled]: (state, action) =>
        instructorsAdapter.removeOne(state, action.payload),
      [getinstructors.fulfilled]: instructorsAdapter.setAll,
    },
  });
  
  export const {
    setinstructorSearchText,
    openNewinstructorDialog,
    closeNewinstructorDialog,
    openEditinstructorDialog,
    closeEditinstructorDialog,
    openConfirmDialog,
    closeConfirmDialog,
    openResetPasswordDialog,
    closeResetPasswordDialog,
  } = instructorsSlice.actions;
  
  export default instructorsSlice.reducer;
  