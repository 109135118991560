import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React from 'react'
import { useTranslation } from 'react-i18next'

function StepHeader({ header, alt }) {
  const { t } = useTranslation();

  return (
    <MDBox width="80%" textAlign="center" mx="auto" my={4}>
      <MDBox mb={1}>
        <MDTypography fontSize={{ xs: "1.728rem", lg: "2.074rem", xl: "2.488rem" }} variant="h3" fontWeight="bold">
          {t(header)}
        </MDTypography>
      </MDBox>
      <MDBox mb={1}>
        <MDTypography fontSize={{ xs: "1rem", lg: "1.20rem", xl: "1.44rem" }} variant="body2" fontWeight="light">
          {t(alt)}
        </MDTypography>
      </MDBox>
    </MDBox>
  )
}

export default StepHeader