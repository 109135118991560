/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Icon, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useForm } from "features/hooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateGuestStatus } from "store/analytics/analyticsSlice";
import { loadUser } from "store/auth/authSlice";
import { updateSubscription } from "store/main/subscriptionsSlice";
import { addTrialToPremiumSubscription } from "store/main/subscriptionsSlice";
import { addSubscription } from "store/main/subscriptionsSlice";
import { setUserInfo } from "store/main/subscriptionsSlice";
import { updateUserInfo } from "store/main/userInfosSlice";
import { addUserInfo } from "store/main/userInfosSlice";
import { checkSubscription } from "store/payment/paymentsSlice";
import StepHeader from "../components/StepHeader";

const defaultFormState = {
  contactName: "",
  address: "",
  city: "",
  country: "",
  zipCode: "",
};


function AddressInfo({ handleBack, handleNext }) {
  const { t } = useTranslation();

  const { form, handleChange, setForm } = useForm(defaultFormState);

  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.subscriptions);
  const { userInfoData, userName, name, surname } = useSelector((state) => state.auth);

  const { subscriptionsInfo } = useSelector((state) => state.payments);

  const navigate = useNavigate();

  const handleNextStep = () => {
    let dataWithIdentity = {
      ...userInfo,
      ...form,
    };

    console.log("SUB", subscriptionsInfo);
    if (userInfoData !== null) {
      dispatch(updateUserInfo({ ...dataWithIdentity, identityNumber: "" }));
    }
    if (userInfoData === null || userInfoData === undefined) {
      dispatch(addUserInfo({ ...dataWithIdentity, identityNumber: "" }))
        .then(() => dispatch(loadUser()));
    }
    if (subscriptionsInfo.length === 0) {
      const currentDate = new Date();
      dispatch(addSubscription({
        pricingPlanId: dataWithIdentity.pricingPlanId,
        userName: dataWithIdentity.email,
        status: "WAITING",
        payMethod: "iyzico",
        startDate: currentDate,
        endDate: new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000))
      })).then(() => dispatch(loadUser()));
    }

    if (subscriptionsInfo.length > 0) {
      const pendingSubscription = subscriptionsInfo.find((f) => f.status === "WAITING" || f.status === "TRIAL");



      if (pendingSubscription.status === "WAITING") {
        dispatch(updateSubscription({
          ...pendingSubscription,
          subscriptionId: pendingSubscription.subscriptionId,
          pricingPlanId: dataWithIdentity.pricingPlanId,
          userName: dataWithIdentity.email,
          status: "WAITING",
          payMethod: "iyzico",
          startDate: new Date(),
        }));
      }
      else if (pendingSubscription.status === "TRIAL") {
        dispatch(addTrialToPremiumSubscription({
          ...pendingSubscription,
          subscriptionId: pendingSubscription.subscriptionId,
          pricingPlanId: dataWithIdentity.pricingPlanId,
          userName: dataWithIdentity.email,
          status: "WAITING",
          payMethod: "iyzico",
          trialStartDate: pendingSubscription.startDate,
          premiumStartDate: new Date(),
          startDate: new Date(),
        }));
      }

      
    }


    dispatch(setUserInfo(dataWithIdentity));
    dispatch(loadUser());
    dispatch(checkSubscription({ userName }));
    handleNext(1);
  }

  const handleFinish = () => {
    let dataWithIdentity = {
      ...userInfo,
      ...form,
    };

    if (userInfoData !== null) {
      dispatch(updateUserInfo({ ...dataWithIdentity, identityNumber: "" }));
    }
    if (userInfoData === null || userInfoData === undefined) {
      dispatch(addUserInfo({ ...dataWithIdentity, identityNumber: "" }))
        .then(() => dispatch(loadUser()));
    }

    const currentDate = new Date();
    dispatch(addSubscription({
      pricingPlanId: dataWithIdentity.pricingPlanId,
      userName: dataWithIdentity.email,
      payMethod: "iyzico",
      status: "TRIAL",
      startDate: currentDate,
      endDate: new Date(currentDate.getTime() + (365 * 24 * 60 * 60 * 1000)),
    })).then(() => {
      dispatch(loadUser())
      dispatch(checkSubscription({ userName: dataWithIdentity.email })).then((params) => {
        const { subscriptionLoaded, isTrial } = params.payload;
        if (subscriptionLoaded && isTrial) {
          navigate("/");
        }
      })
    });

    dispatch(updateGuestStatus({
      userName: dataWithIdentity.email,
      status: "trial",
    }));
  }

  useEffect(() => {
    setForm({ ...defaultFormState, ...userInfo, contactName: name + " " + surname });
  }, [userInfo, setForm, name, surname])

  const canBeSubmitted = () => {
    return (
      form.contactName.length > 0 && form.address.length > 0 &&
      form.city.length > 0 && form.country.length > 0
    )
  }

  return (
    <MDBox>
      <StepHeader header={"addressInfoHeader"} alt={"addressInfoAlt"} />
      <MDBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              autoFocus

              id="contactName"
              name="contactName"
              type="text"
              label={t("contactName")}
              onChange={handleChange}
              value={form.contactName}
              fullWidth
              variant="outlined"
              required
              focused
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              autoFocus

              id="address"
              name="address"
              type="text"
              label={t("address")}
              onChange={handleChange}
              value={form.address}
              fullWidth
              variant="outlined"
              required
              focused
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              autoFocus

              id="city"
              name="city"
              type="text"
              label={t("city")}
              onChange={handleChange}
              value={form.city}
              fullWidth
              variant="outlined"
              required
              focused
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              autoFocus

              id="country"
              name="country"
              type="text"
              label={t("country")}
              onChange={handleChange}
              value={form.country}
              fullWidth
              variant="outlined"
              required
              focused
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              autoFocus
              id="zipCode"
              name="zipCode"
              type="text"
              label={t("zipCode")}
              onChange={handleChange}
              value={form.zipCode}
              fullWidth
              variant="outlined"
              required
              focused
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
        <MDButton
          variant="outlined"
          color="dark"
          onClick={() => handleBack(1)}>
          {t("goBack")}
        </MDButton>

        {
          userInfo.isTrial ? <MDButton
            disabled={!canBeSubmitted()}
            variant="gradient"
            color="dark"
            onClick={handleFinish}
          >
            finish <Icon>navigate_next</Icon>
          </MDButton> :
            <MDButton
              disabled={!canBeSubmitted()}
              variant="gradient"
              color="dark"
              onClick={handleNextStep}
            >
              {t("next")} <Icon>navigate_next</Icon>
            </MDButton>
        }

      </MDBox>
    </MDBox>
  );
}

export default AddressInfo;
