import { Card, Grid } from '@mui/material';
import DefaultCard from 'components/Cards/SongCard/DefaultCard';
import { imageProxy } from 'features/api/proxy';
import { PlayerProvider } from 'hooks/PlayerProvider';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectinstructorCoursesById } from 'store/main/instructorCoursesSlice';
import { selectinstructorLessonsById } from 'store/main/instructorLessonsSlice';

function LessonsList() {
  const { courseId } = useParams();
  const params = useParams();

  console.log("params", params);
  const course = useSelector((state) => selectinstructorCoursesById(state, courseId));
  console.log(course);
  const { t, i18n } = useTranslation();



  return (
    <Card sx={{ padding: 3 }}>
      <Grid container spacing={2}>


        {
          course.instructorCourseLessons.map((lesson, key) => {
            console.log(lesson);
            return <PlayerProvider selectSongState={selectinstructorLessonsById} videoId={lesson.lessonId}>
              <Grid item mt={6} xs={12} sm={6} md={4} lg={3}>
                <DefaultCard
                  details={{
                    type: "instructorLesson",
                    route: `/${t("education")}/${courseId}/${lesson.lessonId}`,
                    key: key,
                  }}
                  image={`${imageProxy}/${lesson.url}`}
                  title={
                    (i18n.language.match("tr") && lesson.lessonNameTr) ||
                    (i18n.language.match("en") && lesson.lessonNameEn) ||
                    (i18n.language.match("de") && lesson.lessonNameDe) ||
                    (i18n.language.match("ar") && lesson.lessonNameAr)
                  }
                  description={
                    (i18n.language.match("tr") && lesson.lessonDescriptionTr) ||
                    (i18n.language.match("en") && lesson.lessonDescriptionEn) ||
                    (i18n.language.match("de") && lesson.lessonDescriptionDe) ||
                    (i18n.language.match("ar") && lesson.lessonDescriptionAr)
                  }

                  action={{
                    type: "internal",
                    route: `/${t("education")}/${courseId}/${lesson.lessonId}`,
                    color: "info",
                    label: "Go Somewhere"
                  }}
                />
              </Grid>
            </PlayerProvider>
          })
        }
      </Grid>
    </Card>
  )
}

export default LessonsList