import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";


export const getLessons = createAsyncThunk(
  "lessons/getLessons",
  async (_, { getState }) => {
    const response = await axiosConfig.get(`/api/lessons`);
    // const response = await axios.get(`${proxy}/api/user-favorites`)
    let { data } = await response.data;
    return data;
  }
);

export const addLesson = createAsyncThunk(
  "lessons/addLesson",
  async (lesson, { dispatch, getState }) => {
    try {
      const formData = new FormData();
      formData.append("lessonNameTr", lesson.lessonNameTr);
      formData.append("lessonDescriptionTr", lesson.lessonDescriptionTr);
      formData.append("lessonNameEn", lesson.lessonNameEn);
      formData.append("lessonDescriptionEn", lesson.lessonDescriptionEn);
      formData.append("lessonNameDe", lesson.lessonNameDe);
      formData.append("lessonDescriptionDe", lesson.lessonDescriptionDe);
      formData.append("lessonNameAr", lesson.lessonNameAr);
      formData.append("lessonDescriptionAr", lesson.lessonDescriptionAr);
      formData.append("url", lesson.url);
      formData.append("videoUrl", lesson.videoUrl);
      formData.append("instrumentId", lesson.instrumentId);
      formData.append("musicianId", lesson.musicianId);
      formData.append("level", lesson.level);
      formData.append("status", lesson.status);
      formData.append("courseId", lesson.courseId);
      formData.append("isHaveNote", lesson.isHaveNote);
      formData.append("folderNumber", lesson.folderNumber);
      
      formData.append("duration", lesson.duration);
      formData.append("videoStart", lesson.videoStart);
      formData.append("videoMain", lesson.videoMain);
      formData.append("videoEnd", lesson.videoEnd);
      formData.append("loopNoteStart", lesson.loopNoteStart);
      formData.append("loopNoteEnd", lesson.loopNoteEnd);
      formData.append("noteStart", lesson.noteStart);
      formData.append("noteMain", lesson.noteMain);
      formData.append("noteEnd", lesson.noteEnd);
      formData.append("noteTolerance", lesson.noteTolerance);
      formData.append("noteHeight", lesson.noteHeight);
      formData.append("noteWidth", lesson.noteWidth);
      formData.append("noteUrl", lesson.noteUrl);
      formData.append("bpm", lesson.bpm);
      formData.append("measure", lesson.measure);
      formData.append("measureWidth", lesson.measureWidth);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/lessons`,
        formData,
        config
      );
      let { data } = await response.data;
      if (response.data.success === true) {

        return data;
      }
    } catch (error) {


      return null;
    }
  }
);

export const updateLesson = createAsyncThunk(
  "lessons/updateLesson",
  async (lesson, { dispatch, getState }) => {
    const formData = new FormData();
    formData.append("lessonId", lesson.lessonId);
    formData.append("lessonNameTr", lesson.lessonNameTr);
    formData.append("lessonDescriptionTr", lesson.lessonDescriptionTr);
    formData.append("lessonNameEn", lesson.lessonNameEn);
    formData.append("lessonDescriptionEn", lesson.lessonDescriptionEn);
    formData.append("lessonNameDe", lesson.lessonNameDe);
    formData.append("lessonDescriptionDe", lesson.lessonDescriptionDe);
    formData.append("lessonNameAr", lesson.lessonNameAr);
    formData.append("lessonDescriptionAr", lesson.lessonDescriptionAr);
    formData.append("url", lesson.url);
    formData.append("videoUrl", lesson.videoUrl);
    formData.append("instrumentId", lesson.instrumentId);
    formData.append("musicianId", lesson.musicianId);
    formData.append("level", lesson.level);
    formData.append("status", lesson.status);
    formData.append("courseId", lesson.courseId);
    formData.append("isHaveNote", lesson.isHaveNote);
    formData.append("folderNumber", lesson.folderNumber);

    formData.append("lessonDetailId", lesson.lessonDetailId);
    formData.append("duration", lesson.duration);
    formData.append("videoStart", lesson.videoStart);
    formData.append("videoMain", lesson.videoMain);
    formData.append("videoEnd", lesson.videoEnd);
    formData.append("loopNoteStart", lesson.loopNoteStart);
    formData.append("loopNoteEnd", lesson.loopNoteEnd);
    formData.append("noteStart", lesson.noteStart);
    formData.append("noteMain", lesson.noteMain);
    formData.append("noteEnd", lesson.noteEnd);
    formData.append("noteTolerance", lesson.noteTolerance);
    formData.append("noteHeight", lesson.noteHeight);
    formData.append("noteWidth", lesson.noteWidth);
    formData.append("noteUrl", lesson.noteUrl);
    formData.append("bpm", lesson.bpm);
    formData.append("measure", lesson.measure);
    formData.append("measureWidth", lesson.measureWidth);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/lessons/${lesson.lessonId}`,
      formData,
      config
    );
    const { data } = await response.data;
    if (response.data.success === true) {

      return data;
    }
    return null;
  }
);

export const removeLesson = createAsyncThunk(
  "lessons/removeLesson",
  async (lessonId, { dispatch, getState }) => {
    console.log("buraya girdik");
    let response = await axiosConfig.delete(`/api/lessons/${lessonId}`);
    if (response.data.success === true) {

      return lessonId;
    }
    return lessonId;
  }
);

const lessonsAdapter = createEntityAdapter({
  selectId: (lesson) => lesson.lessonId,
});

export const {
  selectAll: selectlessons,
  selectById: selectlessonsById
} = lessonsAdapter.getSelectors((state) => state.lessons);

const lessonsSlice = createSlice({
  name: "lessons",
  initialState: lessonsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    lessonDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setLessonSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewLessonDialog: (state, action) => {
      state.lessonDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewLessonDialog: (state, action) => {
      state.lessonDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditLessonDialog: (state, action) => {
      state.lessonDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditLessonDialog: (state, action) => {
      state.lessonDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateLesson.fulfilled]: lessonsAdapter.upsertOne,
    [addLesson.fulfilled]: lessonsAdapter.addOne,
    [removeLesson.fulfilled]: (state, action) =>
      lessonsAdapter.removeOne(state, action.payload),
    [getLessons.fulfilled]: lessonsAdapter.setAll,
  },
});


export const {
  setLessonSearchText,
  openNewLessonDialog,
  closeNewLessonDialog,
  openEditLessonDialog,
  closeEditLessonDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = lessonsSlice.actions;

export default lessonsSlice.reducer;

