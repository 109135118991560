import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";


export const getinstructorLessons = createAsyncThunk(
  "instructorLessons/getinstructorLessons",
  async () => {
    const response = await axiosConfig.get(`/api/instructor-lessons`);
    // const response = await axios.get(`${proxy}/api/user-favorites`)
    let { data } = await response.data;
    return data;
  }
);

export const addinstructorLesson = createAsyncThunk(
  "instructorLessons/addinstructorLesson",
  async (instructorLesson, { dispatch, getState }) => {
    try {
      const formData = new FormData();
      formData.append("lessonNameTr", instructorLesson.lessonNameTr);
      formData.append("lessonDescriptionTr", instructorLesson.lessonDescriptionTr);
      formData.append("lessonNameEn", instructorLesson.lessonNameEn);
      formData.append("lessonDescriptionEn", instructorLesson.lessonDescriptionEn);
      formData.append("lessonNameDe", instructorLesson.lessonNameDe);
      formData.append("lessonDescriptionDe", instructorLesson.lessonDescriptionDe);
      formData.append("lessonNameAr", instructorLesson.lessonNameAr);
      formData.append("lessonDescriptionAr", instructorLesson.lessonDescriptionAr);
      formData.append("url", instructorLesson.url);
      formData.append("videoUrl", instructorLesson.videoUrl);
      formData.append("status", instructorLesson.status);
      formData.append("courseId", instructorLesson.courseId);
      formData.append("duration", instructorLesson.duration);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/instructor-lessons`,
        formData,
        config
      );
      let { data } = await response.data;
      if (response.data.success === true) {

        return data;
      }
    } catch (error) {


      return null;
    }
  }
);

export const updateinstructorLesson = createAsyncThunk(
  "instructorLessons/updateinstructorLesson",
  async (instructorLesson, { dispatch, getState }) => {
    const formData = new FormData();
    formData.append("lessonId", instructorLesson.lessonId);
    formData.append("lessonNameTr", instructorLesson.lessonNameTr);
    formData.append("lessonDescriptionTr", instructorLesson.lessonDescriptionTr);
    formData.append("lessonNameEn", instructorLesson.lessonNameEn);
    formData.append("lessonDescriptionEn", instructorLesson.lessonDescriptionEn);
    formData.append("lessonNameDe", instructorLesson.lessonNameDe);
    formData.append("lessonDescriptionDe", instructorLesson.lessonDescriptionDe);
    formData.append("lessonNameAr", instructorLesson.lessonNameAr);
    formData.append("lessonDescriptionAr", instructorLesson.lessonDescriptionAr);
    formData.append("url", instructorLesson.url);
    formData.append("videoUrl", instructorLesson.videoUrl);
    formData.append("status", instructorLesson.status);
    formData.append("courseId", instructorLesson.courseId);
    formData.append("duration", instructorLesson.duration);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/instructor-lessons/${instructorLesson.lessonId}`,
      formData,
      config
    );
    const { data } = await response.data;

    console.log(data);
    if (response.data.success === true) {

      return data;
    }
    return null;
  }
);

export const removeinstructorLesson = createAsyncThunk(
  "instructorLessons/removeinstructorLesson",
  async (lessonId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/instructor-lessons/${lessonId}`);
    if (response.data.success === true) {

      return lessonId;
    }
    return lessonId;
  }
);

const instructorLessonsAdapter = createEntityAdapter({
  selectId: (instructorLesson) => instructorLesson.lessonId,
});

export const {
  selectAll: selectinstructorLessons,
  selectById: selectinstructorLessonsById
} = instructorLessonsAdapter.getSelectors((state) => state.instructorLessons);

const instructorLessonsSlice = createSlice({
  name: "instructorLessons",
  initialState: instructorLessonsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    instructorLessonDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setinstructorLessonSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewinstructorLessonDialog: (state, action) => {
      state.instructorLessonDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewinstructorLessonDialog: (state, action) => {
      state.instructorLessonDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditinstructorLessonDialog: (state, action) => {
      state.instructorLessonDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditinstructorLessonDialog: (state, action) => {
      state.instructorLessonDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateinstructorLesson.fulfilled]: instructorLessonsAdapter.upsertOne,
    [addinstructorLesson.fulfilled]: instructorLessonsAdapter.addOne,
    [removeinstructorLesson.fulfilled]: (state, action) =>
      instructorLessonsAdapter.removeOne(state, action.payload),
    [getinstructorLessons.fulfilled]: instructorLessonsAdapter.setAll,
  },
});


export const {
  setinstructorLessonSearchText,
  openNewinstructorLessonDialog,
  closeNewinstructorLessonDialog,
  openEditinstructorLessonDialog,
  closeEditinstructorLessonDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = instructorLessonsSlice.actions;

export default instructorLessonsSlice.reducer;

