import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageUpload from "components/UploadComponents/ImageUpload";
import { imageProxy } from "features/api/proxy";
import { useTranslation } from "react-i18next";
import {
  addComposer,
  closeEditComposerDialog,
  closeNewComposerDialog,
  updateComposer,
} from "store/main/composersSlice";
import { Card } from "@mui/material";
const defaultFormState = {
  composerId: 0,
  composerName: "",
  status: "active",
  url: "",
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
];

function ComposerDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const composerDialog = useSelector(
    ({ composers }) => composers.composerDialog
  );

  const [photo, setphoto] = useState();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });
  const initDialog = useCallback(async () => {
    /**
     * Dialog type: 'edit'
     */
    if (composerDialog.type === "edit" && composerDialog.data) {
      setForm({ ...composerDialog.data });
      fetch(`${imageProxy}/${composerDialog.data.url}`)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], composerDialog.data.url, { type: blob.type });
          setphoto(file);
        })
        .catch(error => {
          console.error('Dosya bulunamadı:', error);
        });
      let status = statusOptions.find(
        (f) => f.name === composerDialog.data.status
      );
      setStatusValue(status);
    }

    /**
     * Dialog type: 'new'
     */
    if (composerDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...composerDialog.data,
      });
      let status = statusOptions.find(
        (f) => f.name === defaultFormState.status
      );
      setStatusValue(status);
      setphoto();
    }
  }, [composerDialog.data, composerDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (composerDialog.props.open) {
      initDialog();
    }
  }, [composerDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return composerDialog.type === "edit"
      ? dispatch(closeEditComposerDialog())
      : dispatch(closeNewComposerDialog());
  }

  const handleSetImage = (file) => {
    setphoto(file);
    if (!file) {
      setInForm("url", null);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (composerDialog.type === "new") {
      let data = {
        ...form,
        status: statusValues.id,
        url: photo,
      };
      setphoto();
      dispatch(addComposer(data));
    } else {
      let data = {
        ...form,
        status: statusValues.id,
        url: photo,
      };
      setphoto();
      dispatch(updateComposer(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    return form.composerName.length > 0 && photo !== undefined;
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...composerDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <Card>
        <DialogTitle id="company-dialog-title">{t("composerInfo")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="composerId"
          name="composerId"
          label={t("composerId")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.composerId}
          disabled
        />
        <TextField
          autoFocus
          margin="dense"
          id="composerName"
          name="composerName"
          label={t("composerName")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.composerName}
          onChange={handleChange}
          required
          error={form.composerName ? false : true}
          helperText={t("enterComposerName")}
          focused
        />
        <MDBox mt={2}>
          <Autocomplete
            value={statusValues} // Dikkat: statusValues -> statusValue olarak değiştirildi
            options={statusOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("status")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                setStatusValue({
                  id: newValue, // newValue.id -> newValue olarak değiştirildi
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                setStatusValue({
                  name: newValue.inputValue,
                });
              } else {
                setStatusValue(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox mt={2}>
          <ImageUpload
            handleSetImage={handleSetImage}
            file={photo}
            url={form && form.url ? `${imageProxy}/${form.url}` : null}
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
      </Card>
    </Dialog>
  );
}

export default ComposerDialog;
