import React from 'react'
import StudentLayout from 'pages/main/layout/StudentLayout'
import SongsList from './SongsList'
import { getCategories } from 'store/main/categoriesSlice'
import { getInstruments } from 'store/main/instrumentsSlice'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import LoadingComponent from 'components/Loading'
import { useEffect } from 'react'
import { getUserFavoritesByQuery } from 'store/main/userFavoritesSlice'
import { useSelector } from 'react-redux'
import { getSongLists } from 'store/main/songListsSlice'
import SearchBar from './SearchBar'
import { resetSongListSearch } from 'store/main/songListsSlice'

const SongsDashboard = props => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [instrumentsLoading, setInstrumentsLoading] = useState(true);
  const [favoritesLoading, setFavoritesLoading] = useState(true);
  const userName = useSelector((state) => state.auth.userName);

  useEffect(() => {
    dispatch(getUserFavoritesByQuery(userName)).then(() => setFavoritesLoading(false));
    dispatch(getSongLists()).then(() => setDataLoading(false));
    dispatch(getCategories()).then(() => setCategoriesLoading(false));
    dispatch(getInstruments()).then(() => setInstrumentsLoading(false));
    dispatch(resetSongListSearch());
    
  }, [dispatch, userName]);

  if (dataLoading || categoriesLoading || instrumentsLoading || favoritesLoading) {
    return <LoadingComponent />;
  }

  return (
    <StudentLayout>
      <SearchBar />
      <SongsList />
    </StudentLayout>
  )
}

SongsDashboard.propTypes = {

}

export default SongsDashboard
