/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef, useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from "context";
import useTutorial from "hooks/useTutorial";
import { useSelector } from "react-redux";
import ReactJoyride from "react-joyride";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import MDButton from "components/MDButton";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MDTypography from "components/MDTypography";

function PlayerLayout({ background, children }) {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname, dispatch]);


  const { tutorial } = useSelector(state => state.videoPlayer);

  const { handleCallback, stepIndex, steps } = useTutorial();


  const BeaconButton = styled.button`
  background-color: rgba(0, 0, 232, 0.6);
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 3rem;
  width: 3rem;
`;

  const BeaconComponent = forwardRef((props, ref) => {
    return <BeaconButton ref={ref} {...props} />;
  });

  function Tooltip({
    backProps,
    continuous,
    index,
    isLastStep,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
  }) {
    const { t } = useTranslation();
    console.log("burasoı", step);
    return (
      <MDBox
        {...tooltipProps}
        bgColor="#ffffff"
        alignItems={"center"}
        maxWidth={420}
        minWidth={290}
        overflow="hidden"
        borderRadius="md"
        justifyContent={"center"} q
      >
        <Box padding={4}>
          {step.title && (
            <MDTypography textAlign="center" color="primary" mb="md">
              {step.title}
            </MDTypography>
          )}
          {step.content && <MDTypography textAlign="center">{step.content}</MDTypography>}
        </Box>
        {
          !step.disableBeacon && <MDBox margin={2} width={"full"} flexDirection={"row"} display="flex" justifyContent={"space-between"}>
            <MDButton {...skipProps} size="sm">

              {t("skip")}
            </MDButton>
            <MDBox gap={1} display={"flex"}>
              {index > 0 && (
                <MDButton variant="gradient" color={"error"} {...backProps} size="sm">
                  {t("back")}
                </MDButton>
              )}
              <MDButton variant="gradient" color={"info"} {...primaryProps} size="sm">
                {continuous ? t("next") : t("close")}
              </MDButton>
            </MDBox>
          </MDBox>
        }
      </MDBox>
    );
  }

  return (
    <MDBox
      width="100%"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: "hidden" }}
    >
      <ReactJoyride
        tooltipComponent={Tooltip}
        stepIndex={stepIndex}
        steps={steps}
        continuous
        run={tutorial}
        callback={handleCallback}
        disableOverlayClose
        showSkipButton
        styles={{
          options: {
            fontFamily: 'Outfit, sans-serif',
            zIndex: 1000000,
          }
        }}
      />
      {children}
    </MDBox>
  );
}

// Setting default values for the props for PageLayout
PlayerLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PageLayout
PlayerLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default", "#32393D"]),
  children: PropTypes.node.isRequired,
};

export default PlayerLayout;
