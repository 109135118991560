import {
  DataGrid,
  trTR,
  enUS,
  deDE,
  arSD,
  GridActionsCellItem,
  GridToolbar
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openConfirmDialog } from "store/main/confirmSlice";
import { Box, Card } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { darken, lighten } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { imageProxy } from "features/api/proxy";
import MDAvatar from "components/MDAvatar";
import { selectusers } from "store/main/usersSlice";
import { openNewUserDialog } from "store/main/usersSlice";
import { openEditUserDialog } from "store/main/usersSlice";
import { updateUser } from "store/main/usersSlice";
import dayjs from "dayjs";

const UsersTable = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const data = useSelector(selectusers);

  const [pageSize, setPageSize] = useState(15);

  const handleOpenNew = () => {
    dispatch(openNewUserDialog());
  };

  const deletaData = React.useCallback(
    (id) => () => {
      let data = { ...id.row, table: "usersTable" };
      dispatch(openConfirmDialog(data));
    },
    [dispatch]
  );

  const editStatus = React.useCallback(
    (id) => () => {
      let data = {
        ...id.row,
        status: id.row.status === "active" ? "passive" : "active",
      };
      dispatch(updateUser(data));
    },
    [dispatch]
  );
  const editData = React.useCallback(
    (id) => () => {
      let data = {
        ...id.row,
        confirmPassword: id.row.password,
      }
      dispatch(openEditUserDialog(data));
    },
    [dispatch]
  );
  // const acceptStatus = useCallback(
  //   (id) => () => {
  //     let data = {
  //       ...id.row,
  //       status: "active",
  //     }
  //     dispatch(updateUser(data));
  //   }, []
  // );
  // const rejectStatus = useCallback(
  //   (id) => () => {
  //     let data = {
  //       ...id.row,
  //       status: "passive",
  //     }
  //     dispatch(updateUser(data));
  //   }, [dispatch]
  // );
  const columns = useMemo(() => [
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Düzenle"
          onClick={editData(params)}
        />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label={params.row.status === "active" ? "Pasife al" : "active et"}
          onClick={editStatus(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Sil"
          onClick={deletaData(params)}
          showInMenu
        />,
      ],
    },
    {
      headerName: t("userName"),
      field: "userName",
      minWidth: 300,
      hideable: false,
    },
    {
      headerName: t("name"),
      field: "name",
      minWidth: 250,
      hideable: false,
    },
    {
      headerName: t("surname"),
      field: "surname",
      minWidth: 250,
      hideable: false,
    },
    {
      headerName: t("userType"),
      field: "userType",
      minWidth: 170,
      hideable: false,
    },
    {
      headerName: "url",
      field: "url",
      minWidth: 140,
      hideable: false,
      renderCell: (params) => {
        if (params.value !== null || params.value !== undefined) {
          return (
            <MDAvatar src={`${imageProxy}/${params.value}`} alt={params.value} />
          );
        }
      },
    },
    {
      headerName: t("status"),
      field: "status",
      minWidth: 150,
    },
    {
      headerName: t("createdDate"),
      field: "createdDate",
      minWidth: 170,
      hideable: false,
      type: "date",
      renderCell: (params) => {
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },
  ], [deletaData, editStatus, editData, t]);

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox p={3} lineHeight={1}>
        <TableContainer sx={{ boxShadow: "none" }}>
          <MDBox>
            <Grid container alignItems="center">
              <Grid item xs={12} md={7}>
                <MDBox mb={1}>
                  <MDTypography variant="h5">{t("users")}</MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2" color="text">
                    {t("usersList")}
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={handleOpenNew}
                >
                  <Icon>add</Icon>&nbsp;{t("AddNewUser")}
                </MDButton>
              </Grid>
            </Grid>

            <div style={{ flexGrow: 1, width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      height: "100%",
                      width: 1,
                      "& .super-app-theme--iptal": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.error.main,
                            theme.palette.mode
                          ),
                      },
                      "& .super-app-theme--tamamlandi": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.info.main,
                            theme.palette.mode
                          ),
                      },
                    }}
                  >
                    <DataGrid
                      localeText={
                        (i18n.language.match("tr") && trTR.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("en") && enUS.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("de") && deDE.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("ar") && arSD.components.MuiDataGrid.defaultProps.localeText)
                      }
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      rowsPerPageOptions={[15, 30, 60]}
                      pagination
                      autoHeight
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      getRowClassName={(params) =>
                        `super-app-theme--${params.row.status}`
                      }
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                    />
                  </Box>
                </div>
              </div>
            </div>
          </MDBox>
        </TableContainer>
      </MDBox>
    </Card>
  );
};

UsersTable.propTypes = {};

export default UsersTable;
