import React, { useEffect, useState } from 'react'
import AdminLayout from 'pages/main/layout/AdminLayout'
import UsersTable from './UsersTable'
import { useDispatch } from 'react-redux'
import { getUsers } from 'store/main/usersSlice'
import LoadingComponent from 'components/Loading'
import UserDialog from './UserDialog'
import ConfirmDialog from 'pages/confirmation/ConfirmDialog'
import { deleteUser } from 'store/main/usersSlice'

const AdminUsersDashboard = (props) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(getUsers()).then(() => setDataLoading(false));
  }, [dispatch]);

  if (dataLoading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <UsersTable />
      <UserDialog />
      <ConfirmDialog handleDeleteData={deleteUser} />
    </AdminLayout>
  )
}

AdminUsersDashboard.propTypes = {}

export default AdminUsersDashboard