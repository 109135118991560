import { Card, Grid, Icon, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useRef } from "react";
import IpadSvg from "assets/images/ipad_vector_030.svg";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import CoverLayout from "pages/main/layout/CoverLayout";
import LandingLayout from "pages/main/layout/LandingLayout";

function Introduction() {
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(true);

  const videoRef = useRef();

  const handleLoad = () => {
    videoRef.current.volume = 0;
    videoRef.current.play();
    console.log(videoRef);
  };

  const handleClick = () => {
    const { current: video } = videoRef;
    if (isPlaying) {
      video.pause();
      setIsPlaying(false);
    } else {
      video.play();
      setIsPlaying(true);
    }
  };

  return (
    <LandingLayout>
      <MDBox component={Grid} spacing={{ xs: 4, xl: 6 }} container sx={{ height: "full", display: "flex", justifyContent: "center", paddingX: 4, paddingY: 6 }}>
        <MDBox component={Grid} height={{ xs: `300px`, sm: "450px", md: "600px", lg: "900px", xl: "calc(100vh - 2rem)" }} display={"flex"} position={"relative"} justifyContent={"center"} alignItems={"center"} item xs={12} xl={6} xxl={5} mb={{ xs: 5, sm: 7, md: 10, lg: 0 }}>
          <MDBox component="img" position={"absolute"} width={"100%"} maxWidth={{ xl: "954px" }} alt="ipad-svg" src={IpadSvg} />

          <MDBox position={"absolute"} width={"100%"} maxWidth={{ xl: "954px" }} padding={"3.5%"} borderRadius={"lg"} zIndex={10} display={"flex"}>
            <video style={{ width: "100%", borderRadius: "2.5%", cursor: "pointer" }} alt="ipad-svg" ref={videoRef} onClick={handleClick} autoPlay={true} loop={true} onLoadedData={handleLoad} controls={false} src={"https://lasolist.com/hls/landing/LP_iPad_screen_loop.mp4"} />
          </MDBox>
          {
            // <Typography onClick={handleClick} sx={{ opacity: isPlaying ? 1 : 0, transition: "all .075s ease-in", color: "white", cursor: "pointer", fontSize: "150px" }} zIndex={20}>
            //   <Icon color="white">pause</Icon>
            // </Typography>
          }
        </MDBox>

        <MDBox component={Grid} height={{ xs: "full", xl: "calc(100vh - 2rem)" }} display={"flex"} position={"relative"} justifyContent={"center"} flexDirection={"column"} item xs={12} xl={5} xxl={4}>
          <MDTypography fontSize={{ xs: "1.728rem", lg: "2.074rem", xl: "2.488rem" }} fontWeight="bold" lineHeight={1.6} mb={1} sx={{ textAlign: "center", fontWeight: "bold" }}>
            {t("introduction_header_1")}
          </MDTypography>

          <MDTypography fontSize={{ xs: "1rem", lg: "1.20rem", xl: "1.44rem" }} sx={{ textAlign: "center" }} variant="body">
            {t("introduction_alt_1")}
          </MDTypography>
        </MDBox>
      </MDBox>
    </LandingLayout>
  );
}

export default Introduction;
