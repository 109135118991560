import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";


export const getSubscriptions = createAsyncThunk(
  "subscriptions/getSubscriptions",
  async (_, { getState }) => {
    const response = await axiosConfig.get("/api/subscriptions");
    let { data } = await response.data;
    return data;
  }
);

export const getExpiredSubscriptions = createAsyncThunk(
  "subscriptions/getExpiredSubscriptions",
  async (status, { dispatch, getState }) => {
    const response = await axiosConfig.get(`/api/subscriptions/query?status=${status}`);
    let { data } = await response.data;
    return data;
  }
);

export const checkSubscriptionsStatus = createAsyncThunk(
  "subscriptions/getExpiredSubscriptions",
  async () => {
    const response = await axiosConfig.post(`/api/payment/subscriptions/check-subscriptions`);
    let { data } = await response.data;
    return data;
  }
)

export const addSubscription = createAsyncThunk(
  "subscriptions/addSubscription",
  async (subscription, { dispatch, getState }) => {
    try {
      let formData = new FormData();
      formData.append("pricingPlanId", subscription.pricingPlanId);
      formData.append("userName", subscription.userName);
      formData.append("payMethod", subscription.payMethod);
      formData.append("status", subscription.status);
      formData.append("startDate", subscription.startDate);
      formData.append("endDate", subscription.endDate);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        "/api/subscriptions",
        formData,
        config,
      );
      let { data } = await response.data;
      return data;
    } catch (error) {


      return null;
    }
  }
);

export const addCouponSubscription = createAsyncThunk(
  "subscriptions/addCouponSubscription",
  async (subscription, { dispatch, getState }) => {
    try {
      let formData = new FormData();
      const startDate = new Date();
      const endDate = new Date(new Date().setMonth(new Date().getMonth() + 1))

      formData.append("pricingPlanId", subscription.pricingPlanId);
      formData.append("subscriptionReferenceCode", subscription.couponCode);
      formData.append("parentReferenceCode", subscription.couponCode);
      formData.append("pricingPlanReferenceCode", subscription.couponCode);
      formData.append("paymentReferenceCode", subscription.couponCode);
      formData.append("userName", subscription.userName);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("payMethod", "coupon");
      formData.append("status", "ACTIVE");
      
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        "/api/subscriptions",
        formData,
        config,
      );
      let { data } = await response.data;
      return data;
    } catch (error) {


      return null;
    }
  }
);

export const addTrialToPremiumSubscription = createAsyncThunk(
  "subscriptions/addSubscription",
  async (subscription, { dispatch, getState }) => {
    try {
      let formData = new FormData();
      formData.append("pricingPlanId", subscription.pricingPlanId);
      formData.append("userName", subscription.userName);
      formData.append("payMethod", subscription.payMethod);
      formData.append("status", subscription.status);
      formData.append("startDate", subscription.startDate);
      formData.append("trialStartDate", subscription.trialStartDate);
      formData.append("premiumStartDate", subscription.premiumStartDate);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        "/api/subscriptions",
        formData,
        config,
      );
      let { data } = await response.data;
      return data;
    } catch (error) {

      return null;
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "subscriptions/updateSubscription",
  async (subscription, { dispatch, getState }) => {

    let formData = new FormData();
    formData.append("subscriptionId", subscription.subscriptionId);
    formData.append("pricingPlanId", subscription.pricingPlanId);
    formData.append("userName", subscription.userName);
    formData.append("payMethod", subscription.payMethod);


    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/subscriptions/${subscription.subscriptionId}`,
      formData,
      config
    );
    const { data } = await response.data;
    if (response.data.success === true) {

      return data;
    }
    return null;
  }
);

export const upgradeSubscription = createAsyncThunk(
  "iyzicoSubscriptions/upgradeSubscription",
  async (subscription, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(
        `/api/iyzico/subscriptions/upgrade`,
        subscription,
      );


      const { data } = await response.data;
      if (response.data.success === true) {

        return data;
      }

    } catch (error) {

      return null;
    }
  }
);



export const removeSubscriptions = createAsyncThunk(
  "subscriptions/removeSubscriptions",
  async (packageId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/subscriptions/${packageId}`);
    if (response.data.success === true) {

      return packageId;
    }
    return packageId;
  }
);

const subscriptionsAdapter = createEntityAdapter({
  selectId: (subscription) => subscription.subscriptionId,
});

export const {
  selectAll: selectSubscriptions,
  selectById: selectSubscriptionById
} = subscriptionsAdapter.getSelectors((state) => state.subscriptions);

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: subscriptionsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    subscriptionDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    userInfo: {
      name: "",
      surname: "",
      email: "",
      identityNumber: "",
      gsmNumber: "",
      address: "",
      zipCode: "",
      city: "",
      country: "",
      contactName: "",
      pricingPlanReferenceCode: "",
      pricingPlanId: "",
      previousPlanId: "",
      isTrial: false,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setSubscriptionSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewSubscriptionDialog: (state, action) => {
      state.subscriptionDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewSubscriptionDialog: (state, action) => {
      state.subscriptionDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditSubscriptionDialog: (state, action) => {
      state.subscriptionDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditSubscriptionDialog: (state, action) => {
      state.subscriptionDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: {
    [updateSubscription.fulfilled]: subscriptionsAdapter.upsertOne,
    [addSubscription.fulfilled]: subscriptionsAdapter.addOne,
    [addTrialToPremiumSubscription.fulfilled]: subscriptionsAdapter.addOne,
    [removeSubscriptions.fulfilled]: (state, action) =>
      subscriptionsAdapter.removeOne(state, action.payload),
    [getSubscriptions.fulfilled]: subscriptionsAdapter.setAll,
    [getExpiredSubscriptions.fulfilled]: subscriptionsAdapter.setAll,
    [upgradeSubscription.fulfilled]: subscriptionsAdapter.addOne,
  },
});

export const {
  setSubscriptionSearchText,
  openNewSubscriptionDialog,
  closeNewSubscriptionDialog,
  openEditSubscriptionDialog,
  closeEditSubscriptionDialog,
  openConfirmDialog,
  closeConfirmDialog,
  setUserInfo,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;