import LoadingComponent from "components/Loading";
import AdminLayout from "pages/main/layout/AdminLayout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getInstruments } from "store/main/instrumentsSlice";
import { getMusicians } from "store/main/musiciansSlice";
import { getSongLists } from "store/main/songListsSlice";
import { getSongs } from "store/main/songsSlice";
import SongListDialog from "./SongListDialog";
import SongListTable from "./SongListTable";
import { removeSongList } from "store/main/songListsSlice";
import ConfirmDialog from "pages/confirmation/ConfirmDialog";

const AdminSongListDashboard = () => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  const [songLoading, setSongLoading] = useState(true);
  const [musiciandLoading, setMusiciansLoading] = useState(true);
  const [instrumentsLoading, setInstrumentsLoading] = useState(true);

  useEffect(() => {
    dispatch(getSongLists()).then(() => setDataLoading(false));
    dispatch(getSongs()).then(() => setSongLoading(false));
    dispatch(getMusicians()).then(() => setMusiciansLoading(false));
    dispatch(getInstruments()).then(() => setInstrumentsLoading(false));
  }, [dispatch]);
  
  if (dataLoading || musiciandLoading || instrumentsLoading || songLoading) {
    return <LoadingComponent />;
  }

  return (
    <AdminLayout>
      <SongListDialog />
      <SongListTable />
      <ConfirmDialog handleDeleteData={removeSongList} />
    </AdminLayout>
  );
};

AdminSongListDashboard.propTypes = {};

export default AdminSongListDashboard;
