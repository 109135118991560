import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";


export const getUserFavorites = createAsyncThunk(
  "userFavorites/getUserFavorites",
  async () => {
    const response = await axiosConfig.get(`/api/user-favorites`);
    // const response = await axios.get(`${proxy}/api/user-favorites`)
    let { data } = await response.data;
    return data;
  }
);

export const getUserFavoritesByQuery = createAsyncThunk(
  "userFavorites/getUserFavoritesByQuery",
  async (userName, { dispatch, getState }) => {
    const response = await axiosConfig.get(`/api/user-favorites/query?userName=${userName}`);
    let { data } = await response.data;
    return data;
  }
)

export const addUserFavorites = createAsyncThunk(
  "userFavorites/addUserFavorites",
  async (userFavorites, { dispatch, getState }) => {
    try {
      const formData = new FormData();
      formData.append("listId", userFavorites.listId);
      formData.append("userName", userFavorites.userName);
      formData.append("status", userFavorites.status);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/user-favorites`,
        formData,
        config
      );
      let { data } = await response.data;
      if (response.data.success === true) {

        console.log(data);
        return data;
      }
    } catch (error) {


      return null;
    }
  }
);

export const updateUserFavorites = createAsyncThunk(
  "userFavorites/updateUserFavorites",
  async (favorite, { dispatch, getState }) => {

    {/* ??? */ }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: favorite._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/user-favorites/${favorite.favoriteId}`,
      favorite,
      config
    );
    const { data } = await response.data;
    if (response.data.success === true) {

      return data;
    }
    return null;
  }
);

export const removeUserFavorites = createAsyncThunk(
  "userFavorites/removeUserFavorites",
  async (favoriteId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/user-favorites/${favoriteId}`);
    if (response.data.success === true) {

      return favoriteId;
    }
    return favoriteId;
  }
);

const userFavoritesAdapter = createEntityAdapter({
  selectId: (favorite) => favorite.favoriteId,
});

export const {
  selectAll: selectuserfavorites,
  selectById: selectuserfavoritesById
} = userFavoritesAdapter.getSelectors((state) => state.userFavorites);

const userFavoritesSlice = createSlice({
  name: "userFavorites",
  initialState: userFavoritesAdapter.getInitialState({
    searchText: "",
    instrumentValue: {
      id: "",
      name: "",
    },
    routeParams: {},
  }),
  reducers: {
    setUserFavoritesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    setInstrumentValue: (state, action) => {
      state.instrumentValue = action.payload;
    },
    resetUserFavoritesSearch: (state, action) => {
      state.searchText = "";
      state.instrumentValue = {
        id: "",
        name: "",
      };
    }
  },
  extraReducers: {
    [updateUserFavorites.fulfilled]: userFavoritesAdapter.upsertOne,
    [addUserFavorites.fulfilled]: userFavoritesAdapter.addOne,
    [removeUserFavorites.fulfilled]: (state, action) =>
      userFavoritesAdapter.removeOne(state, action.payload),
    [getUserFavorites.fulfilled]: userFavoritesAdapter.setAll,
    [getUserFavoritesByQuery.fulfilled]: userFavoritesAdapter.setAll,
  },
});

export const {
  resetUserFavoritesSearch,
  setUserFavoritesSearchText,
  setInstrumentValue,
} = userFavoritesSlice.actions;

export default userFavoritesSlice.reducer;

