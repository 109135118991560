import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeConfirmDialog } from "store/main/confirmSlice";
import { useForm } from "../../features/hooks";
import DeleteIcon from '@mui/icons-material/Delete';
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";

const defaultFormState = {
  id: null,
};

const ConfirmDialog = ({ handleDeleteData }) => {
  const dispatch = useDispatch();
  const confirmDialog = useSelector(
    ({ confirmDialog }) => confirmDialog.confirmDialog
  );
  const { form, setForm } = useForm(defaultFormState);

  const initDialog = useCallback(() => {
    setForm({
      ...defaultFormState,
      ...confirmDialog.data,
    });
  }, [confirmDialog.data, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (confirmDialog.props.open) {
      initDialog();
    }
  }, [confirmDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return dispatch(closeConfirmDialog());
  }

  function handleSubmit(event) {
    event.preventDefault();
    dispatch(handleDeleteData(form.id));
    return dispatch(closeConfirmDialog());
  }

  const { t } = useTranslation();

  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...confirmDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center" }} id="alert-dialog-title">
        {t("areYouSureYouWantToDelete")}
      </DialogTitle>
      <DialogContent>
        <MDTypography>{t(form.table)}, <b>id:{form.id}</b> {t("areYouSureYouWantToDelete").toLowerCase()}</MDTypography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <MDButton sx={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }} variant={"gradient"} onClick={handleSubmit} color="error" autoFocus>
          {t("remove")}
          <DeleteIcon sx={{ ml: 1 }} />
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
