import { Autocomplete, Card, FormControl, Grid, Icon, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import MDInput from 'components/MDInput';
import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectcategories } from 'store/main/categoriesSlice';
import { selectinstruments } from 'store/main/instrumentsSlice';
import { setCategoriesValue } from 'store/main/songListsSlice';
import { setInstrumentValue } from 'store/main/songListsSlice';
import { setSongListSearchText } from 'store/main/songListsSlice';

function SearchBar() {
  const dispatch = useDispatch();

  const instruments = useSelector(selectinstruments);
  const categories = useSelector(selectcategories);

  const { t, i18n } = useTranslation();

  // const [instrumentValue, setInstrumentValue] = useState({
  //   id: "",
  //   name: "",
  // });

  const { instrumentValue, categoriesValue } = useSelector((state) => state.songLists);


  const handleSearch = (e) => {
    // console.log(e.target.value);
    dispatch(setSongListSearchText(e));
  }

  return (
    <Card sx={{ marginBottom: 2, padding: 2 }}>
      <Grid container spacing={2} display={"flex"} justifyContent={"center"}>
        <Grid item xs={12} xl={3} xxl={2}>
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">{t("SEARCH")}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={'text'}
              onChange={handleSearch}
              sx={{ padding: "4px" }}
              endAdornment={
                <InputAdornment sx={{ mr: "4px" }} position="end">
                  <Icon fontSize={"medium"}>search</Icon>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} xl={3} xxl={2}>
          <Autocomplete
            value={instrumentValue}
            options={instruments}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("instruments")} />
            )}
            onChange={(event, newValue) => {
              if (newValue === null) {
                dispatch(setInstrumentValue({
                  id: "",
                  name: ""
                }));
              } else if (typeof newValue.name === "string") {
                dispatch(setInstrumentValue({
                  id: newValue.id,
                  name: newValue.name,
                }));
              } else if (newValue && newValue.inputValue) {
                dispatch(setInstrumentValue({
                  name: newValue.inputValue,
                }));
              } else {
                dispatch(setInstrumentValue(newValue));
              }
            }}
          />


        </Grid>

        <Grid item xs={12} md={6} xl={3} xxl={2}>
          <Autocomplete
            value={categoriesValue}
            options={categories}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("categories")} />
            )}
            onChange={(event, newValue) => {
              if (newValue === null) {
                dispatch(setCategoriesValue({
                  id: "",
                  name: "",
                }));
              } else if (typeof newValue.name === "string") {
                dispatch(setCategoriesValue({
                  id: newValue.id,
                  name: newValue.name,
                }));
              } else if (newValue && newValue.inputValue) {
                dispatch(setCategoriesValue({
                  name: newValue.inputValue,
                }));
              } else {
                dispatch(setCategoriesValue(newValue));
              }
            }}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default SearchBar