import React from 'react'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Typography } from '@mui/material';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  borderRadius: "10px",
  '&:before': {
    display: 'none',
  },
  marginBottom: "10px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon color="info" sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: "10px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function AddressInformation({ expanded, handleExpand, handleChange, form }) {

  const { t } = useTranslation();

  return (
    <Accordion expanded={expanded === 'panel3'} onChange={handleExpand('panel3')}>
      <AccordionSummary
        aria-controls="panel3bh-content"
        id="panel3bh-header"
      >
        <Typography variant="h5">
          {t("addressInformations")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          // autoFocus
          margin="dense"
          id="addressInformation.address"
          name="addressInformation.address"
          label={t("address")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.addressInformation.address}
          onChange={handleChange}
          required
          error={form.addressInformation.address ? false : true}
          helperText={t("enterAddress")}
        />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              // autoFocus
              margin="dense"
              id="addressInformation.contactName"
              name="addressInformation.contactName"
              label={t("contactName")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.addressInformation.contactName}
              onChange={handleChange}
              required
              error={form.addressInformation.contactName ? false : true}
              helperText={t("enterContactName")}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              // autoFocus
              margin="dense"
              id="addressInformation.city"
              name="addressInformation.city"
              label={t("city")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.addressInformation.city}
              onChange={handleChange}
              required
              error={form.addressInformation.city ? false : true}
              helperText={t("enterCity")}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              // autoFocus
              margin="dense"
              id="addressInformation.country"
              name="addressInformation.country"
              label={t("country")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.addressInformation.country}
              onChange={handleChange}
              required
              error={form.addressInformation.country ? false : true}
              helperText={t("enterCountry")}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default AddressInformation