import React from "react";
import StudentLayout from "pages/main/layout/StudentLayout";

import Header from "./Header";
import LatestSongsDashboard from "./LatestSongs/LatestSongsDashboard";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getSongLists } from "store/main/songListsSlice";
import LoadingComponent from "components/Loading";
import { useState } from "react";
import { getinstructorCourses } from "store/main/instructorCoursesSlice";
import LatestInsCoursesDashboard from "./LatestInsCourses/LatestInsCoursesDashboard";
import { getCourses } from "store/main/coursesSlice";
import LatestCoursesDashboard from "./LatestCourses/LatestCourses";
import { useSelector } from "react-redux";
import { getUserFavoritesByQuery } from "store/main/userFavoritesSlice";

function StudentDashboard() {

  const dispatch = useDispatch();

  const [songsLoading, setSongsLoading] = useState(true);
  const [coursesLoading, setCoursesLoading] = useState(true);
  const [insCoursesLoading, setInsCoursesLoading] = useState(true);
  const [favoritesLoading, setFavoritesLoading] = useState(true);
  const userName = useSelector((state) => state.auth.userName);


  useEffect(() => {
    dispatch(getUserFavoritesByQuery(userName)).then(() => setFavoritesLoading(false));
    dispatch(getSongLists()).then(() => setSongsLoading(false));
    dispatch(getinstructorCourses()).then(() => setInsCoursesLoading(false));
    dispatch(getCourses()).then(() => setCoursesLoading(false));
  }, [dispatch, userName])


  if (songsLoading || insCoursesLoading || coursesLoading || favoritesLoading) {
    return <LoadingComponent />
  }

  return (
    <StudentLayout>
      <Header />
      <LatestSongsDashboard />
      <LatestCoursesDashboard />
      <LatestInsCoursesDashboard />
    </StudentLayout>
  );
};



export default StudentDashboard;
