/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";


// Image
//import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import bgImage from "assets/images/laSolist_player_01.jpeg";
import Checkbox from "@mui/material/Checkbox";
//translation
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "features/hooks";
import { addInstructorApplication } from "store/main/instructorApplicationsSlice";
import { Alert, Card, Snackbar } from "@mui/material";
import { useState } from "react";
import AuthCoverLayout from "pages/main/layout/AuthCoverLayout";

const defaultFormState = {
  email: "",
  surname: "",
  name: "",
  videoUrl: "",
  terms_conditions: false,
}

function InstructorApplication() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { form, handleChange, setForm } = useForm(defaultFormState);
  const { t } = useTranslation();

  const [error, setError] = useState({
    response: false,
    status: "success",
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError({
      response: false,
      status: "success",
      message: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      ...form,
    }


    dispatch(addInstructorApplication(data)).then((params) => {
      console.log(params);
      const { success } = params.payload;
      if (success) {
        setError({
          response: true,
          status: "success",
          message: "application_created",
        });
      };
      setForm(defaultFormState);
    })

  }

  const isValidEmail = (text) => {
    // Basit bir e-posta regex'i
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(text);
  };


  function canBeSubmitted() {
    return (
      form.email.length > 0 &&
      isValidEmail(form.email) &&
      form.name.length > 0 &&
      form.surname.length > 0 &&
      form.videoUrl.length > 0 &&
      form.terms_conditions);
  }
  return (
    <AuthCoverLayout
      image={bgImage}
    >
      <Snackbar
        open={error.response}
        autoHideDuration={7000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={handleClose} severity={error.status} sx={{ width: '100%' }}>
          {t(error.message)}
        </Alert>
      </Snackbar>
      <Card sx={{ maxWidth: "450px", mx: "auto" }}>
        <MDBox p={3} textAlign="center">
          <MDBox mb={1} textAlign="center">
            <MDTypography variant="h4" fontWeight="bold">
              {t("instructor_application_header")}
            </MDTypography>
          </MDBox>
          <MDTypography variant="body2" color="text">
            {t("instructor_application_alt")}
          </MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                name="email"
                id="email"
                label={t("email")}
                value={form.email}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                name="name"
                id="name"
                label={t("name")}
                value={form.name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                name="surname"
                id="surname"
                label={t("surname")}
                value={form.surname}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                name="videoUrl"
                id="videoUrl"
                value={form.videoUrl}
                onChange={handleChange}
                label={t("driveUrl")}
                variant="outlined"
                fullWidth
              />
            </MDBox>
            {/* <MDBox mb={2}>
            <MDInput
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              label={t("confirmPassword")}
              value={form.confirmPassword}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </MDBox> */}
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                name="terms_conditions"
                id="terms_conditions"
                checked={form.terms_conditions}
                onChange={handleChange} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;{t("i_agree")}&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                {t("terms_and_conditions")}
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                disabled={!canBeSubmitted()}
                onClick={handleSubmit}
                variant="gradient"
                color="info"
                fullWidth>
                {t("sign_up")}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {t("already_have_account")}&nbsp;
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {t("sign_in")}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>

    </AuthCoverLayout>
  );
}

export default InstructorApplication;
