import { Slider } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
function VolumeSlider({ setVolume }) {
  const { videoData } = useSelector((state) => state.videoPlayer);

  const handleVolumeChange = (e) => {
    setVolume(e.target.value);
  }

  return (
    <Slider
      className={"VolumeSlider"}
      size="small"
      value={videoData.volume}
      min={0}
      step={0.01}
      max={1}
      onChange={handleVolumeChange}
      sx={{
        margin: "7px 10px",
        padding: 0,
        width: 100,
        '@media (max-width: 50000000px)': {
          padding: "0 !important", // Apply padding only on screens wider than 600px
        },
        '& .MuiSlider-root': {
          padding: "0 !important",
        },
        '& .MuiSlider-track': {
          height: "10px",
          backgroundColor: "#ffffff",
        },
        '& .MuiSlider-thumb': {
          borderRadius: '50%',
          backgroundColor: "#ffffff",
          width: 12,
          height: 12,
        },

        '& .MuiSlider-rail': {
          height: "8px",
          borderRadius: "5px",
          backgroundColor: "rgba(255, 255, 255, 0.3)",
        },
      }}
    />
  )
}

export default VolumeSlider