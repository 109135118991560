import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";


export const getSettings = createAsyncThunk(
  "settings/getSettings", 
  async (_, { getState }) => {
    const response = await axiosConfig.get(`/api/settings`);
    // const response = await axios.get(`${proxy}/api/composers`)

    let { data } = await response.data;
    return data;
  }
);

export const addComposer = createAsyncThunk(
  "composers/addComposer",
  async (composer, { dispatch, getState }) => {
    try {
      let formData = new FormData();

      formData.append("url", composer.url);
      formData.append("composerName", composer.composerName);
      formData.append(
        "composerDescription",
        composer.composerDescription
      );
      formData.append("type", composer.type);
      formData.append("status", composer.status);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/composers`,
        formData,
        config
      );
      let { data } = await response.data;
      if (response.data.success === true) {

        return data;
      }
    } catch (error) {


      return null;
    }
  }
);

export const updateComposer = createAsyncThunk(
  "composers/updateComposer",
  async (composer, { dispatch, getState }) => {
    let formData = new FormData();

    formData.append("composerId", composer.composerId);
    formData.append("url", composer.url);
    formData.append("composerName", composer.composerName);
    formData.append("composerDescription", composer.composerDescription);
    formData.append("type", composer.type);
    formData.append("status", composer.status);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/composers/${composer.composerId}`,
      formData,
      config
    );
    const { data } = await response.data;
    if (response.data.success === true) {
      return data;
    }
    return null;
  }
);

export const removeComposer = createAsyncThunk(
  "composers/removeComposer",
  async (composerId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/composers/${composerId}`);
    if (response.data.success === true) {
      return composerId;
    }
    return composerId;
  }
);

const settingsAdapter = createEntityAdapter({
  selectId: (settings) => settings.settingsId,
});

export const {
  selectAll: selectSettings,
  selectById: selectSettingsById,
} = settingsAdapter.getSelectors((state) => state.settings);

const settingsSlice = createSlice({
  name: "settings",
  initialState: settingsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    composerDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setComposerSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewComposerDialog: (state, action) => {
      state.composerDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewComposerDialog: (state, action) => {
      state.composerDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditComposerDialog: (state, action) => {
      state.composerDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditComposerDialog: (state, action) => {
      state.composerDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateComposer.fulfilled]: settingsAdapter.upsertOne,
    [addComposer.fulfilled]: settingsAdapter.addOne,
    [removeComposer.fulfilled]: (state, action) =>
      settingsAdapter.removeOne(state, action.payload),
    [getSettings.fulfilled]: settingsAdapter.setAll,
  },
});

export const {
  setComposerSearchText,
  openNewComposerDialog,
  closeNewComposerDialog,
  openEditComposerDialog,
  closeEditComposerDialog,
  openConfirmDialog,
  closeConfirmDialog,
  openResetPasswordDialog,
  closeResetPasswordDialog,
} = settingsSlice.actions;

export default settingsSlice.reducer;
