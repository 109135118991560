import React, { useState } from 'react'
import MDBox from 'components/MDBox'
import { useRef } from 'react'
// import HlsPlayer from 'react-hls-player/dist';
import { useDispatch } from 'react-redux';
import { setVideoData } from 'store/main/videoPlayerSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import VideoHeader from './components/other/VideoHeader';
import { setControlsData } from 'store/main/videoPlayerSlice';
import { useRatio } from './components/other/useRatio';
import { setLoopData } from 'store/main/videoPlayerSlice';
import { selectinstructorLessonsById } from 'store/main/instructorLessonsSlice';
import VideoControls from './components/other/VideoControls';
import { useLayoutEffect } from 'react';
import SettingsPopover from 'components/Controls/Menu';

function InsLessonVideoPlayer({ fullScreenHandler }) {
  const { lessonId } = useParams();

  const details = useSelector((state) => selectinstructorLessonsById(state, lessonId));
  const [videoUrl, setVideoUrl] = useState();

  const {
    videoData,
    controlsData,
    loopData,
  } = useSelector((state) => state.videoPlayer);

  const dispatch = useDispatch();

  const videoRef = useRef();

  useLayoutEffect(() => {
    try {
      const qualitySelectedUrl = details.videoUrl.replace("XX", videoData.quality);
      setVideoUrl(qualitySelectedUrl);

      videoRef.current.load();
      videoRef.current.currentTime = videoData.currentTime;

    } catch (error) {
      console.log(error);
    }
  }, [details, dispatch, videoData.quality, videoData.qualityChanged]);

  /* Detect Changes */
  useEffect(() => {
    const {
      isForwarded,
      isRewinded,
      isPlaying,
      progressClick,
      currentTime,
      volume,
      speed
    } = videoData;

    const {
      loopStart,
      loopStartChanged,
    } = loopData;



    const { current: video } = videoRef;

    if (isForwarded) {
      video.currentTime += 5;
      dispatch(setVideoData({ ...videoData, isForwarded: false }));
    } else if (isRewinded) {
      video.currentTime -= 5;
      dispatch(setVideoData({ ...videoData, isRewinded: false }));
    }

    if (isPlaying) {
      video.play();
    } else {
      video.pause();
    }

    if (progressClick) {
      video.currentTime = currentTime;
      dispatch(setVideoData({ ...videoData, progressClick: false }));
    }

    if (video.volume !== volume) {
      video.volume = volume;
    }

    if (video.playbackRate !== speed) {
      video.playbackRate = speed;
    }

    if (loopStartChanged) {
      video.currentTime = loopStart;
      dispatch(setLoopData({ ...loopData, loopStartChanged: false, loopChanged: false }));
    }


  }, [videoData, dispatch, loopData, details]);

  const handlePlay = () => {
    dispatch(setVideoData({ ...videoData, isPlaying: true }));
    dispatch(
      setControlsData({
        ...controlsData,
        transitionDelay: "5s",
        opacity: 0,
        showVolume: false,
        showSpeed: false
      }));
  };

  const handlePause = () => {
    dispatch(setVideoData({ ...videoData, isPlaying: false }));
    dispatch(setControlsData({ ...controlsData, transitionDelay: "0s", opacity: 1 }));
  };

  const handleEnded = () => {
    dispatch(setVideoData({ ...videoData, isPlaying: false }));

  }

  const handleLoadedVideo = () => {
    dispatch(setVideoData({ ...videoData, duration: videoRef.current.duration }));
    if (!videoData.qualityChanged) {
    }
  };

  const handleTimeUpdate = () => {
    const { loopStart, loopEnd, isLoop } = loopData;
    if (isLoop && videoRef.current.currentTime >= loopEnd) {
      videoRef.current.currentTime = loopStart;
    }
    dispatch(setVideoData({ ...videoData, currentTime: videoRef.current.currentTime }));
  };

  const handleMouseEnter = () => {
    dispatch(setControlsData({ ...controlsData, transitionDelay: "0s", opacity: 1 }));
  }

  const handleMouseLeave = () => {

    if (videoData.isPlaying) {
      dispatch(
        setControlsData({
          ...controlsData,
          transitionDelay: "5s",
          opacity: 0,
        })
      );
    }
    else {
      dispatch(
        setControlsData({
          ...controlsData,
        })
      );
    }
  }

  const handleError = () => {
    if (videoData.quality === "4K") {
      dispatch(setVideoData({ ...videoData, quality: "2K", }))
    }
    else if (videoData.quality === "2K") {
      dispatch(setVideoData({ ...videoData, quality: "HD", }))
    }
    else if (videoData.quality === "HD") {
      dispatch(setVideoData({ ...videoData, quality: "SD", }))
    }
    else {
      dispatch(setVideoData({ ...videoData, quality: "XX", }))
    }
  };

  const handleRightClick = (event) => {
    event.preventDefault();
    console.log("clicked");
  }


  return (
    <MDBox className={"GridContainer"}>
      <VideoHeader />
      <video
        style={{ width: "100vw" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={videoRef}
        onClick={videoData.isPlaying ? handlePause : handlePlay}
        onLoadedData={handleLoadedVideo}
        onTimeUpdate={handleTimeUpdate}
        disablePictureInPicture={true}
        src={videoUrl}
        autoPlay={false}
        width="100vw"
        height="auto"
        onEnded={handleEnded}
        onError={handleError}
        onContextMenu={handleRightClick}
      />
      <SettingsPopover videoRef={videoRef} />
      <VideoControls fullScreenHandler={fullScreenHandler} />
    </MDBox>
  )
}

export default InsLessonVideoPlayer