import { Card, Popper } from '@mui/material';
import NotificationItem from 'examples/Items/NotificationItem';
import React from 'react'
import { useTranslation } from 'react-i18next';

function LanguageMenu({ open, anchorEl, handleCloseMenu, placement }) {
  const { i18n } = useTranslation();

  const changeLanguageHandler = (e, lang) => {
    e.preventDefault();
    i18n.changeLanguage(lang);
    handleCloseMenu();
  };

  const id = open ? 'simple-popper' : undefined;

  return (
    <Popper
      style={{ zIndex: 100 }}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleCloseMenu}
      placement={placement}
      disablePortal={true}
      modifiers={[
        {
          name: 'flip',
          enabled: false,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
          },
        },
      ]}
    >
      <Card sx={{ padding: 1, mt: 2 }}>
        <NotificationItem
          icon={
            <img
              srcSet={`https://flagcdn.com/w40/tr.png 2x`}
              src={`https://flagcdn.com/w20/tr.png`}
              alt="tr"
            />
          }
          title="Türkçe"
          onClick={(e) => changeLanguageHandler(e, "tr")}
        />
        <NotificationItem
          icon={
            <img
              srcSet={`https://flagcdn.com/w40/gb.png 2x`}
              src={`https://flagcdn.com/w20/gb.png`}
              alt="en"
            />
          }
          title="English"
          onClick={(e) => changeLanguageHandler(e, "en")}
        />
        <NotificationItem
          icon={
            <img
              srcSet={`https://flagcdn.com/w40/de.png 2x`}
              src={`https://flagcdn.com/w20/de.png`}
              alt="de"
            />
          }
          title="Deutsch"
          onClick={(e) => changeLanguageHandler(e, "de")}
        />
      </Card>
    </Popper>
  )
}

export default LanguageMenu