import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { imageProxy } from "features/api/proxy";
import { Checkbox, Collapse, Fade, FormControlLabel, FormHelperText } from "@mui/material";
import { closeEditLessonDialog } from "store/main/lessonsSlice";
import { closeNewLessonDialog } from "store/main/lessonsSlice";
import { addLesson } from "store/main/lessonsSlice";
import { updateLesson } from "store/main/lessonsSlice";
import { selectmusicians } from "store/main/musiciansSlice";
import { selectinstruments } from "store/main/instrumentsSlice";
import LessonInformation from "./components/LessonInformation";
import LessonDetails from "./components/LessonDetails";

const defaultFormState = {
  lessonId: 0,
  lessonNameTr: "",
  lessonDescriptionTr: "",
  lessonNameEn: "",
  lessonDescriptionEn: "",
  lessonNameDe: "",
  lessonDescriptionDe: "",
  lessonNameAr: "",
  lessonDescriptionAr: "",
  status: "active",
  instrumentId: 1,
  musicianId: 1,
  level: "",
  url: "",
  isHaveNote: 0,
  videoUrl: "",
  duration: "",
  folderNumber: "",

  lessonDetailId: 0,
  songUrl: "",
  description: "",
  videoStart: 0,
  videoMain: 0,
  videoEnd: 0,
  noteUrl: "",
  loopNoteStart: 0,
  loopNoteEnd: 0,
  measure: "",
  measureWidth: 0,
  noteStart: 0,
  noteMain: 0,
  noteEnd: 0,
  noteTolerance: 0,
  noteHeight: 0,
  noteWidth: 0,
  bpm: 0,
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
];

function LessonDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const lessonDialog = useSelector(
    ({ lessons }) => lessons.lessonDialog
  );

  const musicians = useSelector(selectmusicians);
  const instruments = useSelector(selectinstruments);

  const [photo, setphoto] = useState();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });
  const [instrumentValue, setInstrumentValue] = useState({
    id: 0,
    name: "",
  });
  const [musicianValue, setMusicianValue] = useState({
    id: 0,
    name: "",
  });
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (lessonDialog.type === "edit" && lessonDialog.data) {
      setForm({
        ...defaultFormState,
        ...lessonDialog.data.details ,
        ...lessonDialog.data,
      });
      fetch(`${imageProxy}/${lessonDialog.data.url}`)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], lessonDialog.data.url, { type: blob.type });
          setphoto(file);
        })
        .catch(error => {
          console.error('Dosya bulunamadı:', error);
        });
      let status = statusOptions.find(
        (f) => f.name === lessonDialog.data.status
      );
      let musician = musicians.find(
        (f) => f.musicianId === lessonDialog.data.musicianId
      );
      let instrument = instruments.find(
        (f) => f.instrumentId === lessonDialog.data.instrumentId
      );

      console.log(musician);
      setStatusValue(status);
      setMusicianValue(musician);
      setInstrumentValue(instrument);
    }

    /**
     * Dialog type: 'new'
     */
    if (lessonDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...lessonDialog.data,
      });
      setphoto();
      let status = statusOptions.find(
        (f) => f.name === defaultFormState.status
      );
      let musician = musicians.find(
        (f) => f.musicianId === defaultFormState.musicianId
      );
      let instrument = instruments.find(
        (f) => f.instrumentId === defaultFormState.instrumentId
      );
      setStatusValue(status);
      setMusicianValue(musician);
      setInstrumentValue(instrument);
    }
  }, [lessonDialog.data, lessonDialog.type, setForm, instruments, musicians]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (lessonDialog.props.open) {
      initDialog();
    }
  }, [lessonDialog.props.open, initDialog]);

  function closeComposeDialog() {
    setExpanded(false);
    return lessonDialog.type === "edit"
      ? dispatch(closeEditLessonDialog())
      : dispatch(closeNewLessonDialog());
  }

  const handleSetImage = (file) => {
    setphoto(file);
    if (!file) {
      setInForm("url", null);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (lessonDialog.type === "new") {
      let data = {
        ...form,
        status: statusValues.id,
        instrumentId: instrumentValue.id,
        musicianId: musicianValue.id,
        url: photo,
      };
      setphoto();
      dispatch(addLesson(data));
    } else {
      let data = {
        ...form,
        status: statusValues.id,
        instrumentId: instrumentValue.id,
        musicianId: musicianValue.id,
        url: photo,
      };
      setphoto();
      dispatch(updateLesson(data));
    }
    closeComposeDialog();

  }

  const [expanded, setExpanded] = useState(false);

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function canBeSubmitted() {
    return (
      form.lessonNameTr.length > 0 && form.lessonDescriptionTr.length > 0 &&
      form.lessonNameEn.length > 0 && form.lessonDescriptionEn.length > 0 &&
      form.lessonNameDe.length > 0 && form.lessonDescriptionDe.length > 0 &&
      form.lessonNameAr.length > 0 && form.lessonDescriptionAr.length > 0 &&
      form.level.length > 0 && photo !== undefined &&
      instrumentValue !== undefined && musicianValue !== undefined
    );
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...lessonDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">{t("lessonInfo")}</DialogTitle>
      <DialogContent>
        <MDBox>
          <LessonInformation
            form={form}
            handleChange={handleChange}
            photo={photo}
            handleSetImage={handleSetImage}
            expanded={expanded}
            handleExpand={handleExpand}
            statusOptions={statusOptions}
            statusValues={statusValues}
            setStatusValue={setStatusValue}
            instrumentValue={instrumentValue}
            instruments={instruments}
            setInstrumentValue={setInstrumentValue}
            musicians={musicians}
            musicianValue={musicianValue}
            setMusicianValue={setMusicianValue}
          />
        </MDBox>

        <MDBox ml={3} mb={1}>
          <FormControlLabel
            control={
              <Checkbox
                name={"isHaveNote"}
                id={"isHaveNote"}
                checked={form.isHaveNote}
                onChange={handleChange}
              />}
            label={t("isHaveNote")}
          />
          <FormHelperText>
            {t("isHaveNoteHelperText")}
          </FormHelperText>
        </MDBox>


        <Collapse in={form.isHaveNote}>
          <MDBox>
            <LessonDetails
              form={form}
              handleChange={handleChange}
              expanded={expanded}
              handleExpand={handleExpand}
            />
          </MDBox>
        </Collapse>

      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LessonDialog;
