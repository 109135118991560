import { Slider } from '@mui/material';
import useUtils from 'hooks/useUtils';
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setDraggableImageData } from 'store/main/videoPlayerSlice';
import { setLoopData } from 'store/main/videoPlayerSlice';


const VideoSlider = ({selectSongState}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loopData, draggableImageData } = useSelector((state) => state.videoPlayer);
  const { details } = useSelector((state) => selectSongState(state, id));

  const { videoStart, videoEnd, bpm } = details;

  const { calculateLoopPosition, getLoopVariables } = useUtils(details);

  const handleChange = (event, newValue) => {
    const { width, playedTrack } = getLoopVariables(newValue[0]);
    const leftPosition = calculateLoopPosition(newValue[0], playedTrack);
    const calculatedLoopWidth = leftPosition - calculateLoopPosition(newValue[1], playedTrack);
    const browserWidth = window.innerWidth;

    console.log(calculatedLoopWidth, browserWidth);

    if (calculatedLoopWidth < browserWidth) {

      console.log("buraya girdik");
      const loopLeftPosition = leftPosition - calculatedLoopWidth / 2 + width / 2;
      dispatch(setLoopData({
        loopStart: newValue[0],
        loopEnd: newValue[1],
        isLoop: true,
        loopWidth: calculatedLoopWidth,
        loopLeftPosition,
      }));

      if (newValue[0] !== loopData.loopStart) {
        dispatch(
          setLoopData({
            loopStart: newValue[0],
            loopEnd: newValue[1],
            loopStartChanged: true
          })
        );
      }
    }

    else {
      console.log("buradan çıktık");
    }



  };

  const handleMouseDown = () => {
    dispatch(setDraggableImageData({ ...draggableImageData, transition: "left 0.1s linear", }));
  }

  const handleMouseUp = () => {
    dispatch(setDraggableImageData({ ...draggableImageData, transition: "left 0.3s linear", }));
  }

  return (
    <Slider
      min={videoStart / 1000}
      max={videoEnd / 1000 + 60 / bpm}
      value={[loopData.loopStart, loopData.loopEnd]}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onChange={handleChange}
      step={(60 / bpm)}
      className={"Slider"}
      sx={{
        margin: "7px 10px",
        padding: "0px",
        width: "99%",
        '& .MuiSlider-track': {
          height: "8px",
        },
        '& .MuiSlider-thumb': {
          borderRadius: '50%',
          backgroundColor: "yellow",
          width: 24,
          height: 24,
        },

        '& .MuiSlider-rail': {
          height: "8px",
          borderRadius: "5px",
          backgroundColor: "rgba(255, 255, 255, 0.3)",
        },
      }}
    />
  )
}

export default VideoSlider;
