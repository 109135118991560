export const proxy = "https://lasolist.com";
export const imageProxy = "https://lasolist.com/uploads";
export const imageProxyHls = "https://lasolist.com/hls";
export const videoProxy = "https://lasolist.com/hls";
export const socketIoProxy = "https://lasolist.com/";


// export const proxy = "http://localhost:8080";
// export const socketIoProxy = "http://localhost:8080";
// export const imageProxy = "http://localhost:8080/uploads";
// export const webProxy = "164.92.65.62/home";
