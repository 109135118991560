import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function TimeoutComponent({selectSongState}) {
  const { id } = useParams();
  const { bpm, measure } = useSelector((state) => selectSongState(state, id)).details;

  const [count, setCount] = useState(3);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {
        clearInterval(countdown);
      }
    }, ((60 / bpm * 1000 * parseInt(measure.split("/")[0])) / 3));

    return () => {
      clearInterval(countdown);
    };
  }, [count, bpm, measure]);

  return (
    <MDBox sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "250px",
      height: "250px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      borderRadius: "100%",
      transition: "opacity .5s linear",
      opacity: count === 0 ? 0 : 1,
    }}>
      <MDTypography
        color={"white"}
        fontSize={"150px"}
        fontWeight={"bold"}
      >
        {count}
      </MDTypography>
    </MDBox>
  );
}

export default TimeoutComponent;