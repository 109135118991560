import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";
import { addUpload, updateUpload } from "./uploadsSlice";


export const getSongLists = createAsyncThunk(
  "songLists/getSongLists",
  async (_, { getState }) => {
    const response = await axiosConfig.get(`/api/song-list`);
    // const response = await axios.get(`${proxy}/api/songLists`)

    let { data } = await response.data;
    return data;
  }
);

export const getTrialSongLists = createAsyncThunk(
  "songLists/getTrialSongLists",
  async () => {
    const response = await axiosConfig.get(`/api/song-list/query?isTrial=true`);
    // const response = await axios.get(`${proxy}/api/songLists`)

    let { data } = await response.data;
    return data;
  }
);

export const addSongList = createAsyncThunk(
  "songLists/addSongList",
  async (songList, { dispatch, getState }) => {
    try {
      let formData = new FormData();

      formData.append("folderNumber", songList.folderNumber);
      formData.append("songId", songList.songId);
      formData.append("musicianId", songList.musicianId);
      formData.append("instrumentId", songList.instrumentId);
      formData.append("type", songList.type);
      formData.append("level", songList.level);
      formData.append("status", songList.status);
      formData.append("description", songList.description);
      formData.append("videoStart", songList.videoStart);
      formData.append("videoMain", songList.videoMain);
      formData.append("videoEnd", songList.videoEnd);
      formData.append("noteStart", songList.noteStart);
      formData.append("noteMain", songList.noteMain);
      formData.append("noteEnd", songList.noteEnd);
      formData.append("noteTolerance", songList.noteTolerance);
      formData.append("noteHeight", songList.noteHeight);
      formData.append("noteWidth", songList.noteWidth);
      formData.append("bpm", songList.bpm);
      formData.append("duration", songList.duration);
      formData.append("measure", songList.measure);
      formData.append("measureWidth", songList.measureWidth);
      formData.append("loopNoteStart", songList.loopNoteStart);
      formData.append("loopNoteEnd", songList.loopNoteEnd);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/song-list`,
        formData,
        config
      );
      let { data } = await response.data;
      if (response.data.success === true) {
        return data;
      }
    } catch (error) {

      return null;
    }
  }
);

export const updateSongList = createAsyncThunk(
  "songLists/updateSongList",
  async (songList, { dispatch, getState }) => {
    let formData = new FormData();

    const startTime = new Date().getTime(); // Store the start time

    formData.append("listId", songList.listId);
    formData.append("folderNumber", songList.folderNumber);
    formData.append("songId", songList.songId);
    formData.append("musicianId", songList.musicianId);
    formData.append("instrumentId", songList.instrumentId);
    formData.append("type", songList.type);
    formData.append("level", songList.level);
    formData.append("status", songList.status);
    formData.append("description", songList.description);
    formData.append("videoStart", songList.videoStart);
    formData.append("videoMain", songList.videoMain);
    formData.append("videoEnd", songList.videoEnd);
    formData.append("noteStart", songList.noteStart);
    formData.append("noteMain", songList.noteMain);
    formData.append("noteEnd", songList.noteEnd);
    formData.append("noteTolerance", songList.noteTolerance);
    formData.append("noteHeight", songList.noteHeight);
    formData.append("noteWidth", songList.noteWidth);
    formData.append("bpm", songList.bpm);
    formData.append("duration", songList.duration);
    formData.append("measure", songList.measure);
    formData.append("measureWidth", songList.measureWidth);
    formData.append("loopNoteStart", songList.loopNoteStart);
    formData.append("loopNoteEnd", songList.loopNoteEnd);
    formData.append("url", songList.url);
    formData.append("songUrl", songList.songUrl);

    dispatch(addUpload({ folderNumber: songList.folderNumber, fileName: songList.folderNumber + ".mp4", type: "video", status: "uploading", isFinished: false, progress: 0, remainingTime: 0, }));


    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentage = Math.round((loaded * 100) / total);

        const elapsedTime = (Date.now() - startTime) / 1000; // saniye cinsinden
        const uploadedMB = loaded / (1024 * 1024); // MB cinsinden
        const uploadSpeed = uploadedMB / elapsedTime; // MB/s cinsinden

        const remainingTime = (total - loaded) / (uploadSpeed * 1024 * 1024); // saniye cinsinden

        console.log(`Yükleme durumu: %${percentage}`);
        console.log(`Yükleme hızı: ${uploadSpeed.toFixed(2)} MB/s`);
        console.log(`Kalan süre: ${remainingTime.toFixed(2)} saniye`);
        dispatch(updateUpload({
          folderNumber: songList.folderNumber,
          progress: percentage,
          remainingTime: remainingTime.toFixed(2)
        }));
      },
    };

    const response = await axiosConfig.put(
      `/api/song-list/${songList.listId}`,
      formData,
      config
    );

    const { data } = await response.data;
    if (response.data.success === true) {

      return data;
    }
    return null;
  }
);

export const removeSongList = createAsyncThunk(
  "songLists/removeSongList",
  async (listId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/song-list/${listId}`);
    if (response.data.success === true) {

      return listId;
    }
    return listId;
  }
);

const songListsAdapter = createEntityAdapter({
  selectId: (songList) => songList.listId,
});

export const { selectAll: selectsongLists, selectById: selectsongListsById } =
  songListsAdapter.getSelectors((state) => state.songLists);

const songListsSlice = createSlice({
  name: "songLists",
  initialState: songListsAdapter.getInitialState({
    searchText: "",
    instrumentValue: {
      id: "",
      name: "",
    },
    categoriesValue: {
      id: "",
      name: "",
    },
    routeParams: {},
    songListDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setSongListSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    setInstrumentValue: (state, action) => {
      state.instrumentValue = action.payload;
    },
    setCategoriesValue: (state, action) => {
      state.categoriesValue = action.payload;
    },
    openNewSongListDialog: (state, action) => {
      state.songListDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewSongListDialog: (state, action) => {
      state.songListDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditSongListDialog: (state, action) => {
      state.songListDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditSongListDialog: (state, action) => {
      state.songListDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
    resetSongListSearch: (state, action) => {
      state.searchText = "";
      state.instrumentValue = {
        id: "",
        name: "",
      };
      state.categoriesValue = {
        id: "",
        name: "",
      };
    }
  },
  extraReducers: {
    [updateSongList.fulfilled]: songListsAdapter.upsertOne,
    [addSongList.fulfilled]: songListsAdapter.addOne,
    [removeSongList.fulfilled]: (state, action) =>
      songListsAdapter.removeOne(state, action.payload),
    [getSongLists.fulfilled]: songListsAdapter.setAll,
    [getTrialSongLists.fulfilled]: songListsAdapter.setAll,
  },
});

export const {
  resetSongListSearch,
  setSongListSearchText,
  setInstrumentValue,
  setCategoriesValue,
  openNewSongListDialog,
  closeNewSongListDialog,
  openEditSongListDialog,
  closeEditSongListDialog,
  openConfirmDialog,
  closeConfirmDialog,
  openResetPasswordDialog,
  closeResetPasswordDialog,
} = songListsSlice.actions;

export default songListsSlice.reducer;
