import { Badge, Icon, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { usePlayer } from 'hooks/PlayerProvider';
import React, { useEffect, useRef, useState } from 'react'
import ReactHlsPlayer from 'react-hls-player';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setVideoData } from 'store/main/videoPlayerSlice';

function CoverWithPreviewVideo({ details, duration, isHovered }) {
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const { videoData } = useSelector((state) => state.videoPlayer);
  const [playing, setPlaying] = useState(false);

  const { buffer } = videoData;

  const { t } = useTranslation();

  const [isMuted, setIsMuted] = useState(true);

  const { videoRef, onPreviewLoad, videoSource, handleBuffer, onError, handlePreviewPlay, onRightClick } = usePlayer();

  useEffect(() => {
    let timeout;
    dispatch(setVideoData({ quality: "SD" }));
    // Kart üzerine gelindiğinde veya ayrıldığında bu useEffect çalışacak
    if (isHovered) {
      // Kart üzerindeyken, 1 saniye bekleyip önizleme oynatma işlemini başlat
      timeout = setTimeout(() => {
        handlePreviewPlay();
        setPlaying(true);
      }, 1000);

      console.log(timeout);
    } else {
      setIsMuted(true);
    }

    // useEffect içinde tanımlanan bir fonksiyonun temizlenmesi
    return () => {
      clearTimeout(timeout);
    };
  }, [isHovered]);


  const handleMute = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMuted(!isMuted);
  }

  return (
    <MDBox sx={{ position: "relative" }}>
      <MDBox
        sx={{
          borderRadius: 8,
          position: "absolute",
          opacity: isHovered && buffer > 10 ? 1 : 0,
          transition: "all 0.5s ease-in",
          transitionDelay: isHovered ? "1s" : "0s",
          display: "flex",
          postition: "absolute",
          zIndex: 1000,
          left: 0,
          right: 0,
          top: 0,
        }}
      >
        <Tooltip title={isMuted ? t("TurnOnVolume") : t("TurnOffVolume")}>
          <MDBox
            sx={{
              zIndex: 500,
              background: "white",
              width: 30,
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 4,
            }}
            margin={1}
            color={"navbar"}
            onClick={handleMute}
            variant={"gradient"}
          >
            <Icon>{isMuted ? "volume_off" : "volume_up"}</Icon>
          </MDBox>
        </Tooltip>

        {
          isHovered && <ReactHlsPlayer
            width="100%"
            height="auto"
            autoPlay={true}
            muted={isMuted}
            playerRef={videoRef}
            onLoadedData={onPreviewLoad}
            disablePictureInPicture={true}
            onProgress={handleBuffer}
            src={videoSource}
            onError={onError}
            resize={"contain"}
            style={{ borderRadius: 20, zIndex: 0, position: "absolute" }}
            onContextMenu={onRightClick}
          />
        }
      </MDBox>

      <LazyLoad style={{ width: "100%", aspectRatio: "512/213" }} once>
        <MDBox
          className={"imageContainer"}
          ref={imageRef}
          component="img"
          src={"https://lasolist.com/uploads/default-cover/course_lesson_dark.png"}
          alt={"dark_cover.png"}
          sx={{
            borderRadius: 5,
          }}
          width="100%"
          height="auto"
          position="relative"
          resize={"contain"}
        />
      </LazyLoad>


      <MDBox sx={{
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: 40,
        paddingBottom: 2,
        paddingX: 1,
      }}>
        <MDBox
          sx={{
            backgroundColor: "rgb(255, 255, 255)",
            borderRadius: 4
          }}
        >
          <MDTypography
            fontSize={{ xs: "0.9rem", lg: "1rem", xl: "1.1rem" }}
            variant="h6"
            component="p"
            sx={{
              color: "#252F32",
              marginX: 2,
              alignItems: "center",
              display: "flex",
              gap: 1,
            }}
          >
            <Icon fontSize='medium'>visibility_outline</Icon>
            {details.views}
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            backgroundColor: "rgb(255, 255, 255)",
            borderRadius: 4
          }}
        >
          <MDTypography
            fontSize={{ xs: "0.9rem", lg: "1rem", xl: "1.1rem" }}
            variant="h6"
            component="p"
            sx={{
              color: "#252F32",
              marginX: 2,
            }}
          >
            {duration}
          </MDTypography>
        </MDBox>

        
      </MDBox>
    </MDBox>
  )
}

export default CoverWithPreviewVideo