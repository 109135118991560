import { Grid } from '@mui/material'
import DefaultCard from 'components/Cards/SongCard/DefaultCard';
import MDBox from 'components/MDBox'
import { imageProxy } from 'features/api/proxy';
import { PlayerProvider } from 'hooks/PlayerProvider';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectcoursesById } from 'store/main/coursesSlice';
import { selectlessonsById } from 'store/main/lessonsSlice';
import { selectlessons } from 'store/main/lessonsSlice';

function LessonsList() {
  //Todo: Optimize Et
  const { courseId } = useParams();

  const course = useSelector((state) => selectcoursesById(state, courseId));
  const lessons = useSelector(selectlessons);
  const { i18n, t } = useTranslation();

  const [sortedCourseLesson, setSortedCourseLesson] = useState([]);

  useEffect(() => {
    const sorted = [...course.courseLessonData].sort((a, b) => a.id - b.id);
    setSortedCourseLesson(sorted);
  }, [course]);


  return (
    <MDBox>


      <Grid mt={2} container spacing={2}>
        {
          sortedCourseLesson.map((course, key) => {
            const lesson = lessons.find((f) => f.lessonId === course.lessonId);
            return <PlayerProvider selectSongState={selectlessonsById} videoId={course.lessonId}>
              <Grid key={key} item xs={12} md={6} xl={4}>
                <DefaultCard
                  details={{
                    type: "lesson",
                    route: `/${t("course")}/${courseId}/${course.lessonId}`,
                    key: key,
                    videoId: course.lessonId,
                    courseId: courseId,
                    views: course.count,
                    level: lesson.level,
                  }}
                  image={`${imageProxy}/${lesson.url}`}
                  title={
                    (i18n.language.match("tr") && lesson.lessonNameTr) ||
                    (i18n.language.match("en") && lesson.lessonNameEn) ||
                    (i18n.language.match("de") && lesson.lessonNameDe) ||
                    (i18n.language.match("ar") && lesson.lessonNameAr)
                  }
                  description={
                    (i18n.language.match("tr") && lesson.lessonDescriptionTr) ||
                    (i18n.language.match("en") && lesson.lessonDescriptionEn) ||
                    (i18n.language.match("de") && lesson.lessonDescriptionDe) ||
                    (i18n.language.match("ar") && lesson.lessonDescriptionAr)
                  }
                  duration={lesson.duration}
                />
              </Grid>
            </PlayerProvider>
          })
        }
      </Grid>
    </MDBox>
  )
}

export default LessonsList;