import MDButton from 'components/MDButton';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import MDBox from 'components/MDBox';
import { checkSubscription } from 'store/payment/paymentsSlice';
import { useTranslation } from 'react-i18next';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import axiosConfig from "features/api/axiosConfig";

function PayPalUpgradeCheckout({ checkoutData, setCheckout }) {
  const dispatch = useDispatch();
  const { userName } = useSelector((state) => state.auth);

  const { t } = useTranslation();

  const handleBackStep = () => {

    setCheckout({
      isOpen: false,
      data: {},
      feedback: {
        response: true,
        status: "warning",
        message: "Plan Yükseltmekten Vazgeçildi",
      },
    });

    console.log({
      feedback: {
        response: true,
        status: "warning",
        message: "Plan Yükseltmekten Vazgeçildi",
      },
    });
  };


  console.log(checkoutData);

  const initialOptions = {
    clientId: "ATKzrPxY3r1jLT9elPO8sWqmFKtCYm4zCa0wjJdGHM6PNRV6VSGgzfUVTLUaAtH5VT3vl8eqVvnRgfZb",
    currency: "USD",
    vault: "true",
    intent: "subscription",
  };

  return (
    <div>
      <MDBox sx={{ width: "50%", margin: "0 auto" }}>
        <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            createSubscription={async (data, actions) => {
              try {
                return actions.subscription.revise(checkoutData.subscriptionReferenceCode, {
                  plan_id: checkoutData.newPricingPlanReferenceCode,
                });
              } catch (error) {
                console.log(error);
              }
            }}
            onApprove={async (data, actions) => {
              /*
                No need to activate manually since SUBSCRIBE_NOW is being used.
                Learn how to handle other user actions from our docs:
                https://developer.paypal.com/docs/api/subscriptions/v1/#subscriptions_create
              */
              if (data.orderID) {
                let check = {
                  ...data,
                  ...checkoutData,
                  subscriptionId: data.subscriptionID,
                  userName,
                }

                const response = await axiosConfig.post(`/api/paypal/subscriptions/upgrade`, check);
                const status = await response.data;
                if (status.success) {
                  dispatch(checkSubscription({ userName }));
                  setTimeout(() => {
                    setCheckout({
                      isOpen: false,
                      data: {},
                      feedback: {
                        response: true,
                        status: "success",
                        message: "Plan Başarıyla Değiştirildi",
                      },
                    });
                  }, 5000);
                }
              } else {
                console.log("hata");
              }
            }}
          />
        </PayPalScriptProvider>
      </MDBox>
      <MDBox>
        <MDButton variant="outlined" color="dark" onClick={handleBackStep}>
          {t("goBack")}
        </MDButton>
      </MDBox>
    </div>
  )
};

export default PayPalUpgradeCheckout;
