/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useSelector } from "react-redux";
import { selectPricingPlans } from "store/main/pricingPlansSlice";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";

import PricingCard from "./PricingCard";
import Faq from "./Faq";

function PricingPlans({ setCheckout }) {
  const { isStudent } = useSelector((state) => state.auth);
  const { subscriptionsInfo } = useSelector((state) => state.payments);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();
  const pricingPlans = useSelector(selectPricingPlans);
  const [filteredPlans, setFilteredPlans] = useState([]);

  useEffect(() => {

    const activePlan = subscriptionsInfo.find((f) => f.status === "ACTIVE");
    console.log(activePlan);
    const pricingPlan = pricingPlans.filter((f) => f.countryISO === activePlan.pricingPlanInfo.countryISO && f.forStudent === isStudent);
    setFilteredPlans(pricingPlan);
  }, [pricingPlans, subscriptionsInfo]);


  return (
    <MDBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{ position: "relative", pt: 2, textAlign: "center" }}
      >
        <Grid item xs={12} lg={5}>
          <MDBox mb={1}>
            <MDTypography variant="h3" fontWeight="bold">
              {t("plan_header")}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="body2" fontWeight="light">
              {t("plan_description")}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={8} justifyContent="center">

        {
          filteredPlans.map((item, key) => {

            console.log(item);

            // const currentPlan = subscriptionsInfo.find((f) => f.pricingPlanId === item.pricingPlanId);
            const activePlan = subscriptionsInfo.find((f) => f.status === "ACTIVE")
            return (
              <Grid key={key} item xs={12} lg={4}>
                <PricingCard
                  setCheckout={setCheckout}
                  color={activePlan.pricingPlanId === item.pricingPlanId ? "dark" : "white"}
                  badge={{ color: darkMode ? "warning" : "light", label: item.pricingPlanNameTr }}
                  price={{
                    currency: (
                      (item.currencyISO === "EUR" && "€") ||
                      (item.currencyISO === "TRY" && "₺")
                    ),
                    value: item.price,
                    type: activePlan.pricingPlanInfo.paymentInterval === "MONTHLY" ? t("monthlyInterval") : t("trialInterval"),
                    count: item.paymentIntervalCount,
                  }}
                  specifications={[
                    { label: t("feature1"), includes: true },
                    { label: t("feature2"), includes: true },
                    { label: t("feature3"), includes: true },
                    { label: t("feature4"), includes: true },
                    { label: t("feature5"), includes: true },
                    { label: t("feature6"), includes: true },
                    { label: t("feature7"), includes: true },
                    { label: t("feature8"), includes: true },
                    { label: t("feature9"), includes: true },
                  ]}
                  subscriptionReferenceCode={activePlan.subscriptionReferenceCode}
                  currentPlan={activePlan !== undefined && activePlan.pricingPlanId === item.pricingPlanId ? true : false}
                  pricingPlanReferenceCode={item.pricingPlanReference}
                  pricingPlanId={item.pricingPlanId}
                  payPlatform={activePlan.pricingPlanInfo.payPlatform}
                  button={{
                    color: activePlan.pricingPlanId === item.pricingPlanId ? "info" : "dark",
                    label: activePlan.pricingPlanId === item.pricingPlanId ? t("currentPlan") : t("upgradePlan"),
                  }}
                  shadow={darkMode}
                />
              </Grid>
            )
          })
        }
      </Grid>

      <Faq />
    </MDBox>
  );
}

export default PricingPlans;
