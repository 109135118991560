import { Card, Dialog, DialogActions, DialogTitle } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import React from 'react'
import { useTranslation } from 'react-i18next';

function TermsConditionsDialog(props) {
  const { onClose, open, setInForm } = props;
  const { t } = useTranslation();

  const handleAccept = () => {
    setInForm("terms_conditions", true);
    onClose();
  }

  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="lg"
      sx={{ padding: 10, overflow: "scroll" }}

    >
      <MDBox sx={{ margin: 3 }}>
        <DialogTitle><MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
          {t("userA_agreement")}
        </MDTypography></DialogTitle>
        <MDBox marginX={2}>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("user_agreement_latest_update_date")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("user_agreement_p1")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("user_agreement_p2")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("user_agreement_privacy")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("user_agreement_privacy_alt")}
          </MDTypography>

          <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("scope_of_service")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("scope_of_service_alt")}
          </MDTypography>

          <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("copyright")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("copyright_alt")}
          </MDTypography>

          <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("general_provisions")}
          </MDTypography>

          <ol style={{ paddingLeft: "40px" }}>
            <li>
              <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                {t("general_provisions_a")}
              </MDTypography>
            </li>
            <li>
              <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                {t("general_provisions_b")}
              </MDTypography>
            </li>
            <li>
              <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                {t("general_provisions_c")}
              </MDTypography>
            </li>
            <li>
              <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                {t("general_provisions_d")}
              </MDTypography>
            </li>
            <li>
              <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
                {t("general_provisions_e")}
              </MDTypography>
            </li>
          </ol>

          <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("limitations_of_responsibility")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("limitations_of_responsibility_p1")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("limitations_of_responsibility_p2")}
          </MDTypography>
        </MDBox>

      </MDBox>
      <DialogActions>
        <MDButton
          color="info"
          autoFocus
          variant="gradient"
          onClick={handleAccept}
        >
          {t("accept")}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default TermsConditionsDialog