import { Icon } from '@mui/material';
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setVideoData } from 'store/main/videoPlayerSlice';

function ForwardButton() {

  const dispatch = useDispatch();
  const { videoData } = useSelector((state) => state.videoPlayer);

  const handleForward = () => {
    dispatch(setVideoData({ ...videoData, isForwarded: true }));
  }

  return (
    <button onClick={handleForward}>
      <Icon>fast_forward</Icon>
    </button>
  )
}

export default ForwardButton