import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";

export const getProducts = createAsyncThunk(
  "iyzicoProducts/getProducts",
  async () => {
    const response = await axiosConfig.get(`/api/iyzico/products`);
    let { items } = await response.data.result.data;
    return items;
  }
);

export const getProduct = createAsyncThunk(
  "iyzicoProducts/getProduct",
  async (product, { dispatch, getState }) => {
    console.log("BURAYA GİRDİK");
    console.log(product.iyzicoProductReference);
    const response = await axiosConfig.get(`/api/iyzico/products/${product.productReference}`);
    let { data } = await response.data.result;
    return data;
  }
);

export const addProduct = createAsyncThunk(
  "iyzicoProducts/addProduct",
  async (product, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(
        `/api/iyzico/products`,
        product,
      );

      const { data } = await response.data.result;
      if (response.data.success === true) {
        return data;
      }
    } catch (error) {
      return null;
    }
  }
);

export const updateProduct = createAsyncThunk(
  "iyzicoProducts/updateProduct",
  async (product, { dispatch, getState }) => {
    try {

      const response = await axiosConfig.put(
        `/api/iyzico/products/${product.referenceCode}`,
        product,
      );

      const { data } = await response.data.result;



      if (response.data.success === true) {
        return data;
      }
    } catch (error) {

      return null;
    }
  }
);

export const removeProduct = createAsyncThunk(
  "iyzicoProducts/removeProduct",
  async (productId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/iyzico/products/${productId}`);
    if (response.data.success === true) {
      return productId;
    }
    return productId;
  }
);

const productsAdapter = createEntityAdapter({
  selectId: (product) => product.referenceCode,
});

export const {
  selectAll: selectiyzicoproducts,
  selectById: selectiyzicoproductsById,
} = productsAdapter.getSelectors((state) => state.iyzicoProducts);

const iyzicoProductsSlice = createSlice({
  name: "iyzicoProducts",
  initialState: productsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    productDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setProductSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewProductDialog: (state, action) => {
      state.productDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewProductDialog: (state, action) => {
      state.productDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditProductDialog: (state, action) => {
      state.productDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditProductDialog: (state, action) => {
      state.productDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateProduct.fulfilled]: productsAdapter.upsertOne,
    [addProduct.fulfilled]: productsAdapter.addOne,
    [removeProduct.fulfilled]: (state, action) =>
      productsAdapter.removeOne(state, action.payload),
    [getProducts.fulfilled]: productsAdapter.setAll,
  },
});

export const {
  setProductSearchText,
  openNewProductDialog,
  closeNewProductDialog,
  openEditProductDialog,
  closeEditProductDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = iyzicoProductsSlice.actions;

export default iyzicoProductsSlice.reducer;