
import StudentLayout from 'pages/main/layout/StudentLayout'
import React, { useEffect, useState } from 'react'
import FeedbackList from './FeedbackList';
import { useDispatch } from 'react-redux';
import { getFeedbacks } from 'store/main/feedbacksSlice';
import LoadingComponent from 'components/Loading';
import FeedbacksDialog from './FeedbacksDialog';
import { getFeedbacksByQuery } from 'store/main/feedbacksSlice';
import { useSelector } from 'react-redux';

function FeedbacksDashboard() {
  const dispatch = useDispatch();
  const { userName } = useSelector((state) => state.auth);

  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    dispatch(getFeedbacksByQuery(userName)).then(() => setDataLoading(false));
  }, [dispatch, userName]);

  if (dataLoading) {
    return <LoadingComponent />;
  }

  return (
    <StudentLayout>
      <FeedbackList />
      <FeedbacksDialog />
    </StudentLayout>
  )
}

export default FeedbacksDashboard