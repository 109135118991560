import { Card } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { imageProxy } from 'features/api/proxy';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectcoursesById } from 'store/main/coursesSlice';

function Header() {

  const { courseId } = useParams();

  const course = useSelector((state) => selectcoursesById(state, courseId));
  const { t, i18n } = useTranslation();

  return (
    <Card sx={{ padding: 3, marginBottom: 2, display: "flex", flexDirection: "row" }}>
      <MDBox component="img" src={`${imageProxy}/${course.url}`} sx={{ width: "200px", marginRight: 3 }} />

      <MDBox>
        <MDBox>
          <MDTypography display="inline" variant="h4" textTransform="capitalize" fontWeight="bold">
            {course.courseName}
          </MDTypography>
        </MDBox>

        <MDBox>
          <MDTypography display="inline" variant="text" textTransform="capitalize">
            {
              (i18n.language.match("tr") && course.courseDescriptionTr) ||
              (i18n.language.match("en") && course.courseDescriptionEn) ||
              (i18n.language.match("de") && course.courseDescriptionDe) ||
              (i18n.language.match("ar") && course.courseDescriptionAr)
            }
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default Header