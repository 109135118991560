import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axiosConfig from "features/api/axiosConfig";


export const getVideoAnalyze = createAsyncThunk(
  "videoAnalyze/getVideoAnalyze",
  async () => {
    const response = await axiosConfig.get(`/api/analytics/video-analyzes`);

    let { data } = await response.data;
    return data;
  }
);

export const addVideoAnalyze = createAsyncThunk(
  "videoAnalyze/addVideoAnalyze",
  async (songAnalyze, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(
        `/api/analytics/video-analyzes`,
        songAnalyze,
      );

      const data = response.data;
      console.log("bak", data);
      if (data.success) {
        localStorage.setItem("activeVideoAnalyzeId", data.data.videoAnalyzeId)
      }

      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateVideoAnalyze = createAsyncThunk(
  "videoAnalyze/updateVideoAnalyze",
  async (songAnalyze, { dispatch, state }) => {
    try {
      const response = await axiosConfig.put(
        `/api/analytics/video-analyzes/${songAnalyze.videoAnalyzeId}`,
        songAnalyze,
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getVideoAnalyzeByUserId = createAsyncThunk(
  "videoAnalyze/getsongAnalyzeByUserId",
  async () => {
    const response = await axiosConfig.get(`/api/analytics/song-analyze`);

    let { data } = await response.data;
    console.log(data);
    return data;
  }
);

const videoAnalyzeAdapter = createEntityAdapter({
  selectId: (videoAnalyze) => videoAnalyze.videoAnalyzeId,
});

export const {
  selectAll: selectVideoAnalyze,
  selectById: selectVideoAnalyzeById
} = videoAnalyzeAdapter.getSelectors((state) => state.videoAnalyze);


const videoAnalyzeSlice = createSlice({
  name: "videoAnalyze",
  initialState: videoAnalyzeAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    videoAnalyzeDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setsongAnalyzeSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewVideoAnalyzeDialog: (state, action) => {
      state.videoAnalyzeDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewVideoAnalyzeDialog: (state, action) => {
      state.videoAnalyzeDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditVideoAnalyzeDialog: (state, action) => {
      state.videoAnalyzeDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditVideoAnalyzeDialog: (state, action) => {
      state.videoAnalyzeDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateVideoAnalyze.fulfilled]: videoAnalyzeAdapter.upsertOne,
    [addVideoAnalyze.fulfilled]: videoAnalyzeAdapter.addOne,
    [getVideoAnalyze.fulfilled]: videoAnalyzeAdapter.setAll,
    [getVideoAnalyzeByUserId.fulfilled]: videoAnalyzeAdapter.setAll,
  },
});


export const {
  setsongAnalyzeSearchText,
  openNewsongAnalyzeDialog,
  closeNewsongAnalyzeDialog,
  openEditsongAnalyzeDialog,
  closeEditsongAnalyzeDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = videoAnalyzeSlice.actions;

export default videoAnalyzeSlice.reducer;
