import { Card, Grid } from '@mui/material'
import DefaultCard from 'components/Cards/SongCard/DefaultCard'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { imageProxy } from 'features/api/proxy'
import { PlayerProvider } from 'hooks/PlayerProvider'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectsongListsById } from 'store/main/songListsSlice'
import { selectsongLists } from 'store/main/songListsSlice'
import { selectuserfavorites } from 'store/main/userFavoritesSlice'

function LatestSongsDashboard() {
  const songs = useSelector(selectsongLists);
  const favorites = useSelector(selectuserfavorites);

  const [lastSongs, setLastSongs] = useState([]);

  const { i18n, t } = useTranslation();

  useEffect(() => {
    const last5 = songs.slice((songs.length - 3), songs.length);
    setLastSongs(last5);
  }, [songs])

  return (
    <MDBox sx={{ marginBottom: 8, }}>
      <MDBox display={"flex"} marginX={2} justifyContent={"space-between"} alignItems={"center"}>
        <MDTypography fontSize={{ xs: "1.1rem", lg: "1.2rem", xl: "1.3rem" }} variant={"h4"}>
          {t("latest_songs")}
        </MDTypography>

        <MDButton component={Link} variant={"gradient"} color={"info"} to={t("songsRoute")} size={"small"}>
          {t("view_all")}
        </MDButton>
      </MDBox>

      <Grid mt={1} container spacing={2}>
        {
          lastSongs.map((song, key) => {
            const { songInfo } = song;
            console.log(song)
            const favorite = favorites.find((f) => f.listId === song.listId);
            return <PlayerProvider selectSongState={selectsongListsById} videoId={song.listId}>
              <Grid key={key} item xs={12} md={6} xl={4}>
                <DefaultCard
                  details={{
                    type: "song",
                    route: `/video/${song.listId}`,
                    key: key,
                    views: song.count,
                    videoId: song.listId,
                    level: song.level,
                    courseId: 0,
                  }}
                  image={`${imageProxy}/${song.songInfo.url}`}
                  title={song.songInfo.songName}
                  description={
                    (i18n.language.match("tr") &&
                      songInfo.songDescriptionTr) ||
                    (i18n.language.match("en") &&
                      songInfo.songDescriptionEn) ||
                    (i18n.language.match("de") &&
                      songInfo.songDescriptionDe) ||
                    (i18n.language.match("ar") &&
                      songInfo.songDescriptionAr)
                  }
                  date={song.details.createdDate}
                  duration={song.details.duration}
                  listId={song.listId}
                  favoriteId={favorite ? favorite.favoriteId : 0}
                />

              </Grid>
            </PlayerProvider>
          })
        }
      </Grid>
    </MDBox>
  )
}

export default LatestSongsDashboard