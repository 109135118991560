import React from 'react'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Checkbox, FormControlLabel, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { selectPricingPlans } from 'store/main/pricingPlansSlice';
import { useSelector } from 'react-redux';
import MDBox from 'components/MDBox';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  borderRadius: "10px",
  '&:before': {
    display: 'none',
  },
  marginBottom: "10px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon color="info" sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: "10px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function PricingPlanInformation({ expanded, handleExpand, pricingPlanValue, setPricingPlanValue, form, setForm }) {

  const { t } = useTranslation();

  const pricingPlansOptions = useSelector(selectPricingPlans);

  return (
    <Accordion expanded={expanded === 'panel1'} onChange={handleExpand('panel1')}>
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography variant="h5">
          {t("pricingPlanInformations")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>


        <MDBox mb={1}>
          <Autocomplete
            value={pricingPlanValue}
            options={pricingPlansOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("pricingPlanName")} />
            )}
            onChange={(event, pricingPlanInformation) => {

              if (typeof pricingPlanInformation === "string") { // newValue.name -> newValue olarak değiştirildi
                console.log("1")
                setPricingPlanValue({
                  id: pricingPlanInformation, // newValue.id -> newValue olarak değiştirildi
                  name: pricingPlanInformation,
                });
              } else if (pricingPlanInformation && pricingPlanInformation.inputValue) {
                console.log("2")
                setPricingPlanValue({
                  name: pricingPlanInformation.inputValue,
                });
              } else {
                console.log("3")
                setPricingPlanValue(pricingPlanInformation);
                setForm({
                  ...form,
                  pricingPlanInformation
                })
              }
            }}
          />
        </MDBox>

        <TextField

          autoFocus
          margin="dense"
          label={t("iyzicoPricingPlanReference")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.pricingPlanInformation.iyzicoPricingPlanReference}
          disabled
        />

        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                margin="dense"
                label={t("paymentInterval")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.pricingPlanInformation.paymentInterval}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="dense"
                label={t("price")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.pricingPlanInformation.price}
                disabled
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                margin="dense"
                label={t("trialPeriodDays")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.pricingPlanInformation.trialPeriodDays}
                disabled
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={4} display={"flex"} flexDirection={"column"} alignItems={"center"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.pricingPlanInformation.feature1}
                    disabled
                  />}
                label={t("feature1")}
              />
              <FormHelperText>
                {t("makeChoice")}
              </FormHelperText>
            </Grid>
            <Grid item xs={4} display={"flex"} flexDirection={"column"} alignItems={"center"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.pricingPlanInformation.feature2}
                    disabled
                  />
                }
                label={t("feature2")}
              />
              <FormHelperText>
                {t("makeChoice")}
              </FormHelperText>
            </Grid>
            <Grid item xs={4} display={"flex"} flexDirection={"column"} alignItems={"center"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.pricingPlanInformation.feature3}
                    disabled
                  />
                }
                label={t("feature3")}
              />
              <FormHelperText>
                {t("makeChoice")}
              </FormHelperText>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={4} display={"flex"} flexDirection={"column"} alignItems={"center"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.pricingPlanInformation.shouldLesson}
                    disabled
                  />}
                label={t("shouldLesson")}
              />
              <FormHelperText>
                {t("makeChoice")}
              </FormHelperText>
            </Grid>
            <Grid item xs={4} display={"flex"} flexDirection={"column"} alignItems={"center"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.pricingPlanInformation.shouldSong}         
                    disabled          
                  />
                }
                label={t("shouldSong")}
              />
              <FormHelperText>
                {t("makeChoice")}
              </FormHelperText>
            </Grid>
            <Grid item xs={4} display={"flex"} flexDirection={"column"} alignItems={"center"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.pricingPlanInformation.shouldFeedback}
                    disabled
                  />
                }
                label={t("shouldFeedback")}
              />
              <FormHelperText>
                {t("makeChoice")}
              </FormHelperText>
            </Grid>
          </Grid>
        </MDBox>
      </AccordionDetails>
    </Accordion>
  )
}

export default PricingPlanInformation