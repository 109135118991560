import { Card, Container } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import CoverLayout from 'pages/main/layout/CoverLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'

import bgImage from "assets/images/laSolist_player_01.jpeg";

function DeliveryAndRefund() {
  const { t } = useTranslation();

  return (
    <CoverLayout image={bgImage} coverHeight={"calc(100vh - 2rem)"}>
      <Container sx={{ transform: "translate(0, 40%)" }}>
        <Card
          sx={{
            background: "rgba(255,255,255, .90)",
            padding: "30px",
          }}>
          <MDBox marginX={2}>
            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("deliveryAndRefundName")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("delivery_and_refund_latest_update_date")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("delivery_and_refund_alt_p1")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("delivery_and_refund_alt_p2")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("delivery_header")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("delivery_alt")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("pricing_and_payment_header")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("pricing_and_payment_alt")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("cancel_and_refund_header")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("cancel_and_refund_alt_p1")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("cancel_and_refund_alt_p2")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("privicy_security_header")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("privicy_security_alt")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("changes_header")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("changes_alt_p1")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("changes_alt_p2")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("laSolist_company")}
            </MDTypography>
          </MDBox>
        </Card>
      </Container>
    </CoverLayout>
  )
}

export default DeliveryAndRefund