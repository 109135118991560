const locale = {
  ID: "ID",
  sign_in_header: "Sign In",
  sign_in_message: "Sign in by entering your email and password",
  email: "Email",
  password: "Password",
  remember_me: "Remember me",
  dont_have_account: "Don't have an account?",
  sign_up_header: "Sign Up",
  sign_up_message: "Register by entering your details",
  name: "Your Name",
  i_agree_terms_and_conditions: "I agree terms and conditions",
  already_have_account: "Do you already have an account?",
  SEARCH: "Search",
  cancel: "cancel",
  save: "save",
  send: "Send",
  selectImage: "Select Image",
  selectVideo: "Select Video",
  change: "Change",
  remove: "Sil",
  home: "Home",
  sign_in: "Sign In",
  sign_up: "Sign up",
  app_registration: "Apply",
  confirm: "Confirm",
  resend_verification_code: "Resend Verification Code",
  confirm_email: "Confirm Email Address",
  confirm_email_alt: "Enter the code sent to your email below to confirm your email address",

  beginner: "Beginner",
  advanced: "Advanced",

  forgot_password: "Forgot your password?",
  forgot_password_header: "Forgot Password",
  forgot_password_message: "You can reset your password by entering your email.",
  reset_password_header: "Reset Password",
  reset_password_message: "Please enter your new password.",
  reset_password: "Reset Password",
  wrong_password: "Your password is incorrect. Please try again",
  forgotPasswordName: "Forgot Password",
  forgotPasswordRoute: "/forgot-password",
  forgotPasswordKey: "Forgot Password",
  forgot_password_success: "Your password reset link was successfully sent to your email!",
  forgot_password_error: "No user matching this email was found!",
  resetPasswordName: "Reset Password",
  resetPasswordRoute: "/reset-password/:token",
  resetPasswordKey: "Reset Password",
  reset_password_success: "Your password has been reset successfully!",
  reset_password_error: "There was an error resetting your password. Please try again!",

  instructor_application_header: "Create Your Application to Become an Instructor",
  instructor_application_alt: "Please fill out the form below to provide training on our platform. You need to add the link to your demo videos (Google Drive, DropBox, etc.) in the `Video Link` field.",
  driveUrl: "Video Link [Google Drive, DropBox etc.]",
  application_created: "Your instructor application was created successfully. We will get back to you after evaluating your application.",

  not_found: "The account for the username was not found. Please try again.",
  account_waitingkey: "This account is pending approval. You will receive an email when your account is approved. You can try again later.",
  account_rejected: "Unfortunately, your account has not been approved. Your application will be reviewed again later.",

  message_sent_successfully: "Message Sent Successfully!",
  message_couldnt_send: "Message could not be sent",

  signUpRoute: "/sign-up/:token",

  dashboardName: "Dashborad",
  dashboardRoute: "dashboard/analytics",
  dashboardKey: "Dashboard",

  studentDashboardName: "Home",
  studentDashboardRoute: "/home",
  studentDashboardKey: "Home",

  instructorCollapse: "Teacher",
  songsCollapse: "Song",
  courseCollapse: "Courses",
  userCollapse: "User",
  iyzicoCollapse: "Iyzico",
  paypalCollapse: "PayPal",
  subscriptionsCollapse: "Membership",

  instructorsName: "Teacher",
  instructorsRoute: "/teacher",
  instructorsKey: "Teacher",

  instructorCoursesName: "Courses",
  instructorCoursesRoute: "/teacher/courses",
  instructorCoursesKey: "Courses",

  instructorLessonsName: "Lessons",
  instructorLessonsRoute: "/teacher/courses/lessons",
  instructorLessonsKey: "Lessons",

  instructorApplicationsName: "Applications",
  instructorApplicationsRoute: "/teacher/applications",
  instructorApplicationsKey: "Applications",

  applicationName: "Applications",
  applicationRoute: "/applications",
  applicationKey: "Applications",

  instrumentsName: "Instruments",
  instrumentsRoute: "/instruments",
  instrumentsKey: "Instruments",

  categoriesName: "Categories",
  categoriesRoute: "/categories",
  categoriesKey: "Categories",

  composersName: "Composers",
  composersRoute: "/composers",
  composersKey: "Composers",

  musiciansName: "Musicians",
  musiciansRoute: "/musicians",
  musiciansKey: "Musicians",

  lessonsName: "Lessons",
  lessonsRoute: "/lessons",
  lessonsKey: "Lessons",

  songsName: "Songs",
  songsRoute: "/songs",
  songsKey: "Songs",

  songsTrialName: "Songs",
  songsTrialRoute: "/trial/songs",
  songsTrialKey: "Songs",

  songListName: "Song Detail",
  songListRoute: "/song-details",
  songListKey: "Song Detail",

  usersName: "Users",
  usersRoute: "/users",
  usersKey: "Users",

  feedbacksName: "Feedback",
  feedbacksRoute: "/feedback",
  feedbacksKey: "Feedback",

  pricingPlansName: "Payment Plans",
  pricingPlansRoute: "/payment-plans",
  pricingPlansKey: "Payment Plans",

  subscriptionsName: "Subscribers",
  subscriptionsRoute: "/subscribers",
  subscriptionsKey: "Subscribers",

  favoritesName: "Favorites",
  favoritesRoute: "/favorites",
  favoritesKey: "Favorites",
  favoritesNotFound: "There is no song added to your favorites.",
  favoritesFilterNotFound: "No songs were found matching your filter.",

  profileName: "Profile",
  profileRoute: "/profile",
  profileKey: "Profile",

  //master courses
  coursesName: "Courses",
  coursesRoute: "/courses",
  coursesKey: "Courses",
  course: "course",

  //detail courses
  subCourseName: "Course",
  subCourseRoute: "/course/:courseId",
  subCourseKey: "Course",

  //course video
  studyName: "Study",
  studyRoute: "/course/:courseId/:id",
  studyKey: "Study",

  //Course video for admin
  lessonVideoName: "Lesson Video",
  lessonVideoRoute: "lesson/video/:id",
  lessonVideoKey: "Lesson Video",

  //instructor courses master
  educationName: "Educations",
  educationsRoute: "/educations",
  educationsKey: "Educations",

  //instructor courses detail
  instructorCourseName: "Instructor Courses",
  instructorCourseRoute: "/education/:courseId",
  instructorCourseKey: "Instructor Courses",
  education: "education",
  instructor: "instructor",

  //instructor lesson player
  instructorLessonName: "Instructor Lesson Player",
  instructorLessonRoute: "/education/:courseId/:lessonId",
  instructorLessonKey: "Instructor Lesson Player",

  //instructor detail dashboard
  instructorDashboardName: "Instructor Dashboard",
  instructorDashboardRoute: "/instructor/:id",
  instructorDashboardKey: "Instructor Dashboard",

  videoName: "Video",
  videoRoute: "/video/:id",
  videoKey: "Video",

  iyzicoProductsDahsboard: "Products",
  iyzicoProductsKey: "products",
  iyzicoProductsRoute: "/iyzico/products",

  iyzicoPricingPlanName: "Payment Plans",
  iyzicoPricingPlanKey: "odeme-planlari",
  iyzicoPricingPlanRoute: "/iyzico/pricing-plans",

  iyzicoSubscriptionName: "Subscribers",
  iyzicoSubscriptionKey: "subscribers",
  iyzicoSubscriptionRoute: "/iyzico/subscribers",

  paypalProductsName: "Products",
  paypalProductsKey: "paypal-products",
  paypalProductsRoute: "/paypal/products",

  paypalPricingPlanName: "Pricing Plans",
  paypalPricingPlanKey: "paypal-pricing-plans",
  paypalPricingPlanRoute: "/paypal/pricing-plans",

  paypalSubscriptionName: "Subscribers",
  paypalSubscriptionKey: "paypal-subcribers",
  paypalSubscriptionRoute: "/paypal/subscribers",

  newSubscriptionName: "New Subscription",
  newSubscriptionRoute: "/payment/new-subscription",
  newSubscriptionKey: "New Subscription",

  upgradePlanName: "Upgrade Plan",
  upgradePlanRoute: "/payment/upgrade-plan",
  upgradePlanKey: "Upgrade Plan",

  changeCardName: "Change Card Information",
  changeCardRoute: "/payment/change-card-information/:subscriptionReferenceCode",
  changeCardKey: "Change Card Information",

  cookiesName: "Cookie",
  cookiesRoute: "/cookie",
  cookiesKey: "Cookie",

  privacyName: "Privacy",
  privacyRoute: "/privacy",
  privacyKey: "Privacy",

  userAgreementName: "Licence",
  userAgreementRoute: "/licence",
  userAgreementKey: "Licence",

  deliveryAndRefundName: "Delivery and Refund Terms",
  deliveryAndRefundRoute: "/delivery-and-refund-terms",
  deliveryAndRefundKey: "Delivery and Refund Terms",

  distanceSellingName: "Distance Selling Agreement",
  distanceSellingRoute: "/distance-selling-agreement",
  distanceSellingKey: "Distance Selling Agreement",

  aboutUsName: "About Us",
  aboutUsRoute: "/about-us",
  aboutUsKey: "About Us",

  contactsName: "Contact",
  contactsRoute: "/contact",
  contactsKey: "Contact",

  faqsName: "F.A.Q.",
  faqsRoute: "/faq",
  faqsKey: "F.A.Q.",

  pricingName: "Pricing",
  pricingRoute: "/pricing",
  pricingKey: "Pricing",

  analyticsCollapseName: "Analytics",

  videoAnalyzeName: "Video Analyze",
  videoAnalyzeRoute: "/analytics/video-analyze",
  videoAnalyzeKey: "Video Analyze",

  couponCodesName: "Coupon Codes",
  couponCodesRoute: "/coupon-codes",
  couponCodesKey: "Coupon Codes",

  instructorSearch: "Instructor",
  courseDoesntMatch: "No courses were found matching your filter.",

  instrumentInfo: "Instrument Information",
  instruments: "Instruments",
  instrumentList: "Instruments List",
  instrumentsTable: "Instrument Table",
  AddNewInstrument: "Add New Instrument",
  instrumentId: "Instrument ID",
  instrumentNameTr: "Instrument Name [TR]",
  instrumentDescriptionTr: "Instrument Description [TR]",
  instrumentNameEn: "Instrument Name [EN]",
  instrumentDescriptionEn: "Instrument Description [EN]",
  instrumentNameDe: "Instrument Name [DE]",
  instrumentDescriptionDe: "Instrument Description [DE]",
  instrumentNameAr: "Instrument Name [AR]",
  instrumentDescriptionAr: "Instrument Description [AR]",
  instrumentType: "Instrument Type",
  createdDate: "Created Date",
  status: "Status",
  enterInstrumentNameTr: "Enter Instrument Name [TR]",
  enterInstrumentDescriptionTr: "Enter Instrument Description [TR]",
  enterInstrumentNameEn: "Enter Instrument Name [EN]",
  enterInstrumentDescriptionEn: "Enter Instrument Description [EN]",
  enterInstrumentNameDe: "Enter Instrument Name [DE]",
  enterInstrumentDescriptionDe: "Enter Instrument Description [DE]",
  enterInstrumentNameAr: "Enter Instrument Name [AR]",
  enterInstrumentDescriptionAr: "Enter Instrument Description [AR]",

  categoryInfo: "Category Information",
  categories: "Categories",
  categoryList: "Category List",
  categoriesTable: "Category Table",
  AddNewCategory: "Add New Category",
  categoryId: "Category ID",
  categoryName: "Category Name",
  categoryDescription: "Category Description",
  categoryNameTr: "Category Name [TR]",
  categoryDescriptionTr: "Category Description [TR]",
  categoryNameEn: "Category Name [EN]",
  categoryDescriptionEn: "Category Description [En]",
  categoryNameDe: "Category Name [DE]",
  categoryDescriptionDe: "Category Description [DE]",
  categoryNameAr: "Category Name [AR]",
  categoryDescriptionAr: "Category Description [AR]",
  categoryType: "Category Type",
  enterCategoryNameTr: "Enter Category Name [TR]",
  enterCategoryDescriptionTr: "Enter Category Description [TR]",
  enterCategoryNameEn: "Enter Category Name [EN]",
  enterCategoryDescriptionEn: "Enter Category Description [EN]",
  enterCategoryNameDe: "Enter Category Name [DE]",
  enterCategoryDescriptionDe: "Enter Category Description [DE]",
  enterCategoryNameAr: "Enter Category Name [AR]",
  enterCategoryDescriptionAr: "Enter Category Description [AR]",
  isIncludeTrial: "Included in the Trial Period?",

  composerInfo: "Composer Information",
  composers: "Composers",
  composerList: "Composer List",
  composersTable: "Composers Table",
  AddNewComposer: "Add New Composer",
  composerId: "Composer ID",
  composerName: "Composer Name",
  enterComposerName: "Enter Composer Name",

  musiciansInfo: "Musician Information",
  musicians: "Musicians",
  musicianList: "Musician List",
  musiciansTable: "Musicians Table",
  AddNewMusician: "Add New Musician",
  musicianId: "Musician ID",
  userName: "User Name",
  musicianName: "Musician Name",
  musicianDescription: "Musician Description",
  enterMusicianName: "Enter Musician Name",
  musicianDescriptionTr: "Musician Description [TR]",
  enterMusicianDescriptionTr: "Enter Musician Description [TR]",
  musicianDescriptionEn: "Musician Description [EN]",
  enterMusicianDescriptionEn: "Enter Musician Description [EN]",
  musicianDescriptionDe: "Musician Description [DE]",
  enterMusicianDescriptionDe: "Enter Musician Description [DE]",
  musicianDescriptionAr: "Musician Description [AR]",
  enterMusicianDescriptionAr: "Enter Musician Description [AR]",
  confirmPassword: "Confirm Password",
  enterUserName: "Enter User Name",
  enterPassword: "Enter Password",
  enterConfirmPassword: "Enter Confirm Password",

  lessonInfo: "Lesson Information",
  noteDetails: "Note Details",
  lessons: "Lessons",
  lessonsTable: "Lessons Table",
  lessonList: "Lesson List",
  AddNewLesson: "Add New Lesson",
  lessonId: "Lesson ID",
  lessonName: "Lesson Name",
  lessonDescription: "Lesson Description",
  lessonNameTr: "Lesson Name [TR]",
  enterLessonNameTr: "Enter Lesson Name [TR]",
  lessonDescriptionTr: "Lesson Description [TR]",
  enterLessonDescriptionTr: "Enter Lesson Description [TR]",
  lessonNameEn: "Lesson Name [EN]",
  enterLessonNameEn: "Enter Lesson Name [EN]",
  lessonDescriptionEn: "Lesson Description [EN]",
  enterLessonDescriptionEn: "Enter Lesson Description [EN]",
  lessonNameDe: "Lesson Name [DE]",
  enterLessonNameDe: "Enter Course Name [DE]",
  lessonDescriptionDe: "Lesson Description [DE]",
  enterLessonDescriptionDe: "Enter Course Description [DE]",
  lessonNameAr: "Lesson Name [AR]",
  enterLessonNameAr: "Enter Lesson Name [AR]",
  lessonDescriptionAr: "Lesson Description [AR]",
  enterLessonDescriptionAr: "Enter Lesson Description [AR]",
  type: "Tip",
  courseId: "Course ID",
  enterCourseId: "Enter Course ID",
  enterLevel: "Enter Level",
  isHaveNote: "Is There a Note?",
  isHaveNoteHelperText: "Is there any note information to add for this lesson?",

  courses: "Courses",
  courseList: "Course Table",
  coursesTable: "Courses Table",
  AddNewCourse: "Add New Course",
  courseName: "Course Name",
  enterCourseName: "Enter Course Name",
  courseDescriptionTr: "Course Description [TR]",
  enterCourseDescriptionTr: "Enter Course Description [TR]",
  courseDescriptionEn: "Course Description [EN]",
  enterCourseDescriptionEn: "Enter Course Description [EN]",
  courseDescriptionDe: "Course Description [DE]",
  enterCourseDescriptionDe: "Enter Course Description [DE]",
  courseDescriptionAr: "Course Description [AR]",
  enterCourseDescriptionAr: "Enter Course Description [AR]",
  selectLessons: "Select Lessons",

  songInfo: "Song Information",
  songs: "Songs",
  songTable: "Songs Table",
  AddNewSong: "Add New Song",
  songId: "Song ID",
  songName: "Song Name",
  songDescription: "Song Description",
  songDescriptionTr: "Song Description [TR]",
  songDescriptionEn: "Song Description [EN]",
  songDescriptionDe: "Song Description [DE]",
  songDescriptionAr: "Song Description [AR]",
  enterSongName: "Enter Song Name",
  enterSongDescriptionTr: "Enter Song Description [TR]",
  enterSongDescriptionEn: "Enter Song Description [EN]",
  enterSongDescriptionDe: "Enter Song Description [DE]",
  enterSongDescriptionAr: "Enter Song Description [AR]",
  selectComposer: "Select Composer",
  selectCategories: "Select Categories",
  songDoesntMatch: "No song matching your filter was found.",

  songListInfo: "Song Detail Information",
  songList: "Song Detail",
  songListId: "Song Detail ID",
  songListsTable: "Song Details Table",
  AddNewSongList: "Add New Song Detail",
  listId: "Detail ID",
  description: "Description",
  enterDescription: "Enter Detail Description",
  level: "Level",
  instrumentName: "Instrument Name",
  instrumentDescription: "Instrument Description",
  selectSong: "Select Song",
  selectMusician: "Select Musician",
  videoStart: "Video Start [ms]",
  enterVideoStart: "Enter Video Start ms",
  videoMain: "Video General [ms]",
  enterVideoMain: "Enter Video General ms",
  videoEnd: "Video End [ms]",
  enterVideoEnd: "Enter Video End ms",
  loopNoteStart: "Loop Start [px]",
  enterLoopNoteStart: "Enter Loop Start Pixel",
  loopNoteEnd: "Loop End [px]",
  enterLoopNoteEnd: "Enter Loop End Pixel",
  noteTolerance: "Note Tolerance [px]",
  enterNoteTolerance: "Enter Note Tolerance",

  noteStart: "Note Start [px]",
  enterNoteStart: "Enter Note Start Pixel",
  noteMain: "Nota General [px]",
  enterNoteMain: "Enter Note General Pixel",
  noteEnd: "Note End [px]",
  enterNoteEnd: "Enter Note End Pixel",
  noteHeight: "Note Height [px]",
  enterNoteHeight: "Enter Note Height",
  noteWidth: "Note Width [px]",
  enterNoteWidth: "Enter Note Width",
  bpm: "Metronome [bpm]",
  enterBpm: "Enter Song Metronome [bpm]",
  videoUrl: "Video URL [vps]",
  enterVideoUrl: "Enter Song URL",
  noteUrl: "Note URL [vps]",
  enterNoteUrl: "Enter Note URL",
  minute: " min.",
  folderNumber: "Folder Number",
  enterFolderNumber: "Enter Folder Number",
  measure: "Song Measure",
  enterMeasure: "Enter Song Measure",
  measureWidth: "Measure Length [px]",
  enterMeasureWidth: "Enter Measure Length",

  iyzicoProductReference: "Iyzico Product References",
  iyzicoPricingPlanReference: "Iyzico Payment Plan Reference",
  paypalReferenceCode: "PayPal Reference Number",
  pricingPlanId: "Plan ID",
  pricingPlanNameTr: "Plan Name [TR]",
  enterPricingPlanNameTr: "Enter Plan Name [TR]",
  pricingPlanDescriptionTr: "Plan Description [TR]",
  enterPricingPlanDescriptionTr: "Enter Plan Description [TR]",
  pricingPlanNameEn: "Plan Name [EN]",
  enterPricingPlanNameEn: "Enter Plan Name [EN]",
  pricingPlanDescriptionEn: "Plan Description [EN]",
  enterPricingPlanDescriptionEn: "Enter Plan Description [EN]",
  pricingPlanNameDe: "Plan Name [DE]",
  enterPricingPlanNameDe: "Enter Plan Name [DE]",
  pricingPlanDescriptionDe: "Plan Description [DE]",
  enterPricingPlanDescriptionDe: "Enter Plan Description [DE]",
  pricingPlanNameAr: "Plan Name [AR]",
  enterPricingPlanNameAr: "Enter Plan Name [AR]",
  pricingPlanDescriptionAr: "Plan Description [AR]",
  enterPricingPlanDescriptionAr: "Enter Plan Description [AR]",
  duration: "Duration",
  enterDuration: "Enter Duration",
  discount: "Discount",
  enterDiscount: "Enter Discount",
  price: "Price",
  enterPrice: "Enter Price",

  instructors: "Instructors",
  instructorList: "Instructor List",
  instructorsTable: "Instructors Table",
  AddNewInstructor: "Add New Instructor",
  instructorId: "Instructor ID",
  instructorName: "Instructor Name",
  enterInstructorName: "Enter Instructor Name",
  instructorDescription: "Instructor Description",
  instructorDescriptionTr: "Instructor Description [TR]",
  enterInstructorDescriptionTr: "Enter Instructor Description [TR]",
  instructorDescriptionEn: "Instructor Description [En]",
  enterInstructorDescriptionEN: "Enter Instructor Description [EN]",
  instructorDescriptionDe: "Instructor Description [DE]",
  enterInstructorDescriptionDe: "Enter Instructor Description [DE]",
  instructorDescriptionAr: "Instructor Description [AR]",
  enterInstructorDescriptionAr: "Enter Instructor Description [AR]",

  insCourses: "Instructor Courses",
  insCoursesList: "Instructor Courses List",
  courseDescription: "Course Description",
  insCourseInfo: "Instructor Course Information",
  instructorCoursesTable: "Instructor Courses Table",
  instructorLessonsTable: "Instructor Lessons Table",

  videoAnalyze: "Video Analyzes",
  videoAnalyzeList: "Video Analysis List",
  AddNewSongAnalyze: "Add New Song Analysis",
  videoAnalyzeId: "Video Analyze ID",
  videoId: "Video ID",
  userType: "User Type",
  enterTime: "Enter Song Time",
  exitTime: "Time to Enter Song",
  lastVideoTime: "Last Second of the Song Played",

  instructorApplications: "Instructor Applications",
  applicationId: "Instructor Application ID",
  instructorApplicationsList: "Instructor Applications List",
  AddNewInstructorApplications: "Add New Instructor Application",
  instructorApplicationsTable: "Instructor Applications Table",
  enterApplicantName: "Enter the Name of the Applicant",
  enterApplicantSurname: "Enter the Applicant's Surname",
  enterApplicantEmail: "Enter the Email of the Applicant",
  enterApplicantVideUrl: "Enter the Video URL of the Applicant",

  feature1: "Comprehensive Song Selection",
  feature2: "The Complete Education Program for Every Song",
  feature3: "Easy Access to Sheet Music",
  feature4: "Continuously Updated Content",
  feature5: "Education Options at Different Levels",
  feature6: "Revisit Difficult Sections with the Loop Function",
  feature7: "Beginner to Advanced Training",
  feature8: "Personal Help from Professional Trainers",
  feature9: "Expert Trainer Staff",
  subscribe: "Subscribe",

  shouldLesson: "Are Lessons Included?",
  shouldSong: "Including Songs?",
  shouldFeedback: "Is Feedback Included?",
  makeChoice: "Please Make a Choice?",
  packageInformations: "Package Information",
  iyzicoInformations: "Iyzico Information",

  paypalInformations: "PayPal Information",
  enterPaypalPricingPlanName: "Enter PayPal Payment Plan Name",
  paypalProductReference: "PayPal Product Reference Code",
  paypalPricingPlanReference: "PayPal Payment Plan Reference Code",
  paypalPricingPlanNameAdmin: "PayPal Payment Plan Name",
  paypalPricingPlanDescription: "PayPal Payment Plan Description",
  enterPaypalPricingPlanDescription: "Enter PayPal Payment Plan Description",

  subscriptions: "Subscribers",
  subscriptionList: "Subscriber List",
  checkSubscriptionStatus: "Check Subscribers",
  subscriptionsInfo: "Subscriber Information",
  pricingPlanInformations: "Payment Plan Information",
  userInformations: "User Information",
  addressInformations: "Address Information",
  cardInformations: "Card Information",
  cardHolderName: "Cardholder Name",
  enterCardHolderName: "Enter Cardholder Name",
  cardNumber: "Card Number",
  enterCardNumber: "Enter Card Number",
  expireMonth: "Expiration [Month]",
  enterExpireMonth: "Enter Expiration [Month]",
  expireYear: "Expiration [Year]",
  enterExpireYear: "Enter Expiration [Year]",
  cvc: "CVC Code",
  enterCvc: "Enter CVC Code",
  expires: "Expiration",
  enterName: "Enter Your Name",
  surname: "Your Last Name",
  enterSurname: "Enter your last name",
  gsmNumber: "Phone Number",
  zipCode: "Zip Code",
  parentReferenceCode: "Parent Reference Code",
  payMethod: "Payment Method",
  noTrial: "No Trial Period",
  trialStartDate: "Trial Period Start",
  trialEndDate: "Trial End Date",
  startDate: "Start Date",
  endDate: "End Date",
  expireDate: "Expiration",
  retryPayment: "Repeat Payment",

  couponCodes: "Coupon Codes",
  couponCodesList: "Coupon Code List",
  AddNewCouponCode: "Add New Coupon Code",
  couponCodeInfo: "Coupon Code Information",
  couponCodeId: "Coupon Code ID",
  referenceCode: "Reference Code",
  enterReferenceCode: "Enter Reference Code",
  discountPercentage: "Discount Rate",
  enterDiscountPercentage: "Enter Discount Rate",
  creditPercentageToInstructor: "Credit Percentage to Instructor",
  enterCreditPercentageToInstructor: "Enter the Credit Percentage to Instructor",
  creditPercentageToStudent: "Loan Percentage to Student",
  enterCreditPercentageToStudent: "Enter the Credit Percentage To Student",
  enterCreatedDate: "Enter Start Time",
  enterExpirationDate: "Enter Expiration Time",
  selectPricingPlans: "Select Payment Plans to Apply",

  addressInfoHeader: "Address Information",
  addressInfoAlt: "Select one of the following plans",
  enterGsmNumber: "Enter your 10 Digit Phone Number",
  identityNumber: "Identity Number",
  enterIdentityNumber: "Enter your 11-Digit Identity Number",
  address: "Billing Address",
  enterAddress: "Enter Billing Address",
  contactName: "Enter Invoice Recipient Name",
  enterContactName: "Enter Invoice Recipient Name",
  city: "City",
  enterCity: "Enter City",
  country: "Country",
  enterCountry: "Enter Country",

  userInfoHeader: "User Information",
  userInfoAlt: "Please fill in the missing information",

  selectInstrument: "Select Instrument",

  users: "Users",
  usersList: "Users List",
  AddNewUser: "Create New User",
  usersTable: "Users Table",
  enterEmail: "Enter Email",
  resetToken: "Reset Token",
  resetTokenExpire: "Reset Token Expiration Date",

  feedbackInfo: "Feedback Information",
  feedbacks: "Feedbacks",
  feedbacks_header: "Help us get better and improve our service for you. Your feedback is very valuable to us, thank you!",
  feedbackList: "Feedback List",
  feedbacksTable: "Feedbacks Table",
  AddNewFeedback: "Add New Feedback",
  feedbackId: "Feedback ID",
  feedbackTitle: "Feedback Title",
  enterFeedbackTitle: "Enter Feedback Title",
  feedbackDetails: "Feedback Details",
  enterFeedbackDetails: "Enter Feedback Details",
  feedbackReply: "Feedback Response",
  enterFeedbackReply: "Enter Feedback Response",
  feedbackType: "Feedback Type",
  problem: "Problem",
  suggestion: "Suggestion",
  complaint: "Complaint",
  general: "general",
  waiting: "Pending",
  success: "Success",
  processing: "Processing",
  solved: "Solved",
  cancelled: "Canceled",
  rejected: "Rejected",

  //Paypal Translations
  update_time: "Update Date",
  pricing_plan_reference: "Payment Plan Reference Code",

  accept: "Accept",
  decline: "Reddet",

  productInfo: "Product Information",
  products: "Products",
  productsList: "Products List",
  AddNewProducts: "Add New Product",
  productReferenceCode: "Product Reference Number",
  productName: "Product Name",
  enterProductName: "Enter Product Name",
  productDescription: "Product Description",
  enterProductDescription: "Enter Product Description",

  pricingPlanInfo: "Payment Plan Information",
  pricingPlans: "Payment Plans",
  pricingPlansList: "Payment Plans List",
  pricingPlansTable: "Payment Plans Table",
  AddNewPricingPlan: "Add New Payment Plan",
  pricingPlanReferenceCode: "Payment Plan Reference Code",
  pricingPlanName: "Payment Plan Name",
  enterPricingPlanName: "Enter Payment Plan Name",
  paymentInterval: "Payment Interval",
  paymentIntervalCount: "Payment Frequency",
  enterPaymentIntervalCount: "Enter Payment Frequency",
  trialPeriodDays: "Trial Period Days",
  enterTrialPeriodDays: "Enter Number of Days of Trial Period",
  MONTH1: "1 Month",
  MONTH6: "6 Months",
  MONTH12: "12 Months",
  payPlatform: "Payment Platform",
  countryISO: "ISO Code of the country",
  enterCountryISO: "Enter the ISO Code of the Country",
  currencyISO: "ISO Code of the Currency",
  enterCurrencyISO: "Enter the ISO Code of the Currency",

  subscriptionReferenceCode: "Membership Reference Number",
  subscriptionName: "Member Name",
  subscriptionSurname: "Member Surname",
  subscriptionEmail: "Member Email",
  subscriptionGsmNumber: "Member Phone Number",
  subscriptionInfo: "Subscription Information",
  orderInformations: "Payment Information",
  paymentAttempts: "Payment Attempts",
  subscriptionsList: "Subscriber List",

  profile_profile: "profile",
  profile_password: "change password",
  profile_subscription: "your plan",
  profile_email: "change email",
  profile_payment: "payment method",
  profile_past_subscriptions: "subscription info",
  profile_cancel: "cancel subscription",
  ACTIVE: "Active Plan",
  FINISHED: "Past Plan",
  UPGRADED: "Upgraded Plan",
  CANCELLED: "Canceled Plan",
  WAITING: "Waiting Plan",

  student: "Student",
  change_password: "Change Password",
  password_requirements: "Password Requirements",
  password_requirements_description: "For a strong password, please follow the requirements below",
  special_characters: "1 Special Character [.,-_ etc.]",
  min_characters: "Minimum 6 Characters",
  one_number: "1 Rakam",
  one_letter: "1 Letter",
  current_password: "Current Password",
  new_password: "New Password",
  confirm_new_password: "Confirm New Password",
  enterCurrentPassword: "Enter your current password",
  enterMinCharacters: "Your password must be a minimum of 6 characters",
  enterSpecialCharacters: "Your password must have at least 1 special character [.,!-_, etc.]",
  enterOneNumber: "Your password must have at least 1 number",
  enterOneLetter: "Your Password Must Have At Least 1 Letter",
  passwordDoesntMatch: "Your password does not match the confirmation password",

  profile_changed_success: "Your information has been updated successfully.",
  profile_changed_error: "An error occurred while updating your information. Try again later",
  enterNameError: "Your name cannot be blank. Please enter your name.",
  enterSurnameError: "Your surname cannot be empty. Please enter your last name.",

  password_changed_success: "Your password has been updated successfully.",
  password_changed_failed: "Your password does not match, try again.",
  update_information: "Update Information",
  edit: "Edit",
  past_subscriptions_header: "Subscription Information",

  change_email: "Change Email",
  new_email: "New Email",
  confirm_current_password: "Confirm Current Password",

  subscription_header: "Your Payment Plan",
  change_subscription: "Change Payment Schedule",
  cannotProvideThisService: "We cannot provide this service at this time",

  payment_method: "Payment Method",
  payment_method_description: "Are you sure you want to do this?",
  change_payment_method: "Update Payment Method",

  cancel_subscription: "Cancel Subscription",
  cancel_subscription_description: "Are you sure you want to do this?",
  cancel_subscription_success: "Your subscription has been successfully canceled.",
  cancel_subscription_failure: "Your subscription could not be canceled.",
  canceled_subscription_description: "Your subscription has been canceled. ",
  canceled_subscription_description2: "You can continue to use until",

  register_header: "Apply",
  register_message: "Apply to access the platform.",
  referenceUserName: "Reference Email",
  referenceName: "Reference Name",
  note: "Application Note",

  feature_main: "Dive into the Magical World of Baglama with us",
  feature_alt: "Dive into the magical world of Baglama with us. We are happy to share our passion for Baglama with you. The content prepared by our qualified teachers is ready to take you to the magical world of Baglama.",

  feature1_header: "Start with Your Favorite Songs",
  feature1_alt: "Choose your favorite songs from hundreds of titles and make learning fun.",

  feature2_header: "Video & Sheet Music",
  feature2_alt: "Watch professionals perform, follow the notes at the same time and learn easily.",

  feature3_header: "Gradual Learning Paces",
  feature3_alt: "Choose the tempo that suits you to master each song.",

  feature4_header: "Loop Function",
  feature4_alt: "Repeat the parts you want to practice and concentrate fully on playing",

  feature5_header: "Explore Songs",
  feature5_alt: "Discover new songs of all levels and genres or start playing unforgettable classics.",

  feature6_header: "Constant New Content",
  feature6_alt: "Continuously improve yourself with new works and courses added regularly.",

  call_main: "Sign Up Now and Start Your Free Trial!",
  call_alt: "laSolist offers a new way of learning for those who want to learn to play the baglama or are already actively playing. Our technology combines videos with musical notes to create a whole new learning experience. Sign up now and try it for free!",

  trialInterval: "Forever",
  monthlyInterval: "Month",
  couponInterval: "Month",
  includesSong: "Songs Included",
  includesLesson: "Lessons Included",
  includesFeedback: "Feedback Included",
  trial: "Trial Packages",
  paid: "Premium Packages",
  monthly: "Monthly Packages",
  yearly: "Annual Packages",
  choosePlan: "Choose Plan",
  currentPlan: "Mevuct Plan",
  upgradePlan: "Upgrade Plan",
  checkout: "Checkout",
  plan_header: "Select Plan",
  plan_description: "Select one of the following plans",
  logout: "Logout",
  goBack: "Back",
  next: "Next",

  Quality: "Quality",
  QualityButton: "Video Quality",
  PlaybackRate: "Playback Rate",
  SpeedButton: "Video Speed",
  TurnOffVolume: "Mute",
  TurnOnVolume: "Unmute",
  ForwardButton: "Fast Forward",
  RewindButton: "Rewind",
  ReturnStart: "Back To Start",
  Stop: "Stop",
  Play: "Play",
  EnterLoop: "Enter Loop",
  ExitLoop: "Exit Loop",
  Fullscreen: "Full Screen",
  VideoSettings: "Video Settings",

  areYouSureYouWantToDelete: "Are you sure you want to delete?",

  new_subscription_wizard_header: "Create Your Subscription",
  new_subscription_wizard_description: "Fill in the following information to create your subscription",

  upgrade_plan_wizard_header: "Change Your Plan",
  upgrade_plan_wizard_description: "Fill in the following information to change plan",

  change_card_wizard_header: "Change Your Payment Method",
  change_card_wizard_description: "Enter your new card details below or select from your saved cards",

  faq_header: "Frequently Asked Questions",
  faq_description: "",

  question1: "Is laSolist free?",
  answer1_1: "You can use the completely free version of LaSolist by choosing our trial package after registration. To access the full scope of our trainings, you can opt for a paid plan.",

  question2: "Which payment methods are available?",
  answer2_1: "We cooperate with Iyzico and you can use all payment methods and options offered by Iyzico.",

  question3: "How can I extend my laSolist membership?",
  answer3_1: "According to the plan you choose, your training will continue automatically.",

  question4: "How can I terminate my laSolist membership?",
  answer4_1: "You can enter your profile and terminate your active membership. You can continue to use laSolist until the end date of your package.",

  question5: "Do you have special packages for students?",
  answer5_1: "If you are a student, you can benefit from a special discount. You can apply to iletisim@lasolist.com with your student ID for a discount.",

  question6: "I am a music teacher, how can laSolist benefit me?",
  answer6_1: "laSolist is the perfect support for you. You can make your lessons even better and motivate your students to practice independently. A new way of learning that makes your students more engaged, learn faster and empowers you as a teacher. ",
  answer6_2:
    "laSolist means that by helping your students stick to their weekly practice schedule, you don't have to spend time in your lesson catching up on things they haven't practiced. Instead, you can really engage with your students in the lesson and dedicate yourself to their individual strengths and weaknesses. You can also use the time you save to review important concepts that are best taught sitting side by side, such as technique, intonation or playing style. After all, this is exactly what makes your lessons so valuable and irreplaceable.",

  question7: "I am a music teacher, do you collaborate with the instructors?",
  answer7_1: "Yes, we offer customized solutions for teachers and schools. Together with laSolist you can create your own educational series. ",
  answer7_2: "Please contact us for details, our email address is: mail@lasolist.com",

  question8: "Do you offer customized training series for schools?",
  answer8_1: "Yes, we offer customized solutions for teachers and schools. Together with laSolist you can create your own customized training series for your school. ",
  answer8_2: "Please contact us for details, our email address is: mail@lasolist.com",

  introduction_header_1: "Mastery of music making - learning made easy",
  introduction_alt_1:
    "With hundreds of songs you know and love, we show you how to play them from three different camera angles. Following the fingers of a pro makes playing much easier. At the same time, the corresponding notes are displayed and learned as you play. Choose your own tempo to master each song. Our practical looping feature lets you repeat the parts you want to practise. You can then concentrate on playing. This makes learning much more efficient and easier.",

  introduction_header_2: "Mastery of music making - learning made easy",
  introduction_alt_2:
    "With hundreds of songs you know and love, we show you how to play them from three different camera angles. Following the fingers of a pro makes playing much easier. At the same time, the corresponding notes are displayed and learned as you play. Choose your own tempo to master each song. Our practical looping feature lets you repeat the parts you want to practise. You can then concentrate on playing. This makes learning much more efficient and easier.",

  welcome: "Welcome ",
  welcome_alt: "Bağlama adventure starts here with us!",

  latest_songs: "Latest Songs",
  latest_courses: "Latest Courses",
  latest_instructor_courses: "Latest Instructor Courses",
  lesson: " Lesson",
  view_all: "View All",
  view: "View",

  cookies_header: "Cookie Policy",
  cookies_latest_update_date: "Last updated: 24/10/2023",
  cookies_alt_p1: "As laSolist, we care about your security and with this Cookie Policy, we aim to briefly inform our valued visitors about which cookies we use on our website, for what purpose and how you can change your cookie settings",
  cookies_alt_p2:
    "In order to provide you with a better service, please read this Cookie Policy to have brief information about what kind of personal data is collected through cookies, for what purpose and how it is processed. For more information, you can review our Privacy Policy or feel free to contact us.",

  what_is_cookie: "What is a Cookie?",
  what_is_cookie_p1:
    "Cookies are small files that are saved on users' devices so that they can use websites more efficiently. Since users' information is processed through cookies, users must be informed and their consent must be obtained in accordance with the Personal Data Protection Law No. 6698.",
  what_is_cookie_p2: "We use various cookies to ensure that our valued visitors can benefit from our website in the most efficient way and to improve the user experience of our valued visitors.",

  mandatory_cookie: "1. Mandatory Cookies",
  mandatory_cookie_alt: "Mandatory cookies are cookies that enable the website to be usable by providing basic functionality of the website. Without these cookies, the website will not function properly.",

  performance_cookie: "2. Performance Cookies",
  performance_cookie_alt: "Performance cookies are cookies that anonymously collect visitors' usage information and preferences regarding the website, thus enabling the performance of the website to be improved.",

  functional_cookie: "3. Functional Cookies",
  functional_cookie_alt: "Functional cookies are cookies that allow users to be recognized and remembered on future visits based on their past use of the website, thus allowing websites to provide a personalized service to users, such as language, region, etc.",

  ad_cookie: "4. Advertising Cookies",
  ad_cookie_alt:
    "Advertising cookies are cookies owned by third parties and are used to track visitors' behavior on websites. The purpose of these cookies is to help display advertisements that will be of interest to the needs of visitors and the responsibility belongs to the third party that owns the cookie.",

  data_processed_with_cookie: "What are the Personal Data Processed with Cookies?",
  data_processed_with_cookie_alt:
    "Your identity (name, surname, date of birth, etc.) and contact (address, e-mail address, telephone, IP, location, etc.) information will be processed by us through cookies. Your identity (name, surname, date of birth, etc.) and contact (address, e-mail address, telephone, IP, location, etc.) information will be processed by us through cookies, by automatic or non-automatic methods and sometimes within the framework of the service and contractual relationship between us and during the service and contractual relationship between us, by obtaining, recording, storing and updating from third parties such as analytics providers, advertising networks, search information providers, technology providers, based on the legitimate interest processing condition.",

  what_purpose: "For what purpose are cookies used?",
  what_purpose_alt:
    "Our website uses cookies to ensure security by detecting suspicious movements, to increase functionality and performance in line with users' preferences, to improve and personalize products and services and to facilitate access to these services, to fulfill contractual and legal responsibilities. In addition, advertising cookies and information sharing with third parties are also used to connect users with a wider range of service providers.",

  how_to_manage: "How to manage cookies",
  how_to_manage_alt_p1:
    "After all these explanations, the issue of which cookies to use is left entirely to the free will of our users. You can manage your cookie preferences by deleting or blocking them from your browser settings as soon as you step into our website, or you can change these settings at any time in the future. For more detailed information, you can review our Privacy Policy or contact us at ",
  how_to_manage_alt_p2: "You can contact via email address",

  privacy_header: "Privacy Policy",
  privacy_latest_update_date: "Last updated: 24/10/2023",
  privacy_alt_p1: "Your security is important to us. For this reason, your personal data that you will share with us is sensitively protected.",
  privacy_alt_p2:
    "We, Company Name, as the data controller, with this privacy and personal data protection policy, which of your personal data will be processed for what purpose, with whom and why the processed data can be shared, our data processing method and legal reasons; We aim to inform you about what your rights are regarding your processed data.",

  collected_data_reason: "Your Personal Data Collected, Method of Collection and Legal Reason",
  collected_data_alt:
    "Your IP address and user agent information will be processed solely for the purpose of analysis and by means of technologies such as cookies, automatic or non-automatic methods, and sometimes by obtaining, recording, storing and updating from third parties such as analytics providers, advertising networks, search information providers, technology providers, within the framework and duration of the service and contractual relationship between us, based on the legitimate interest processing condition.",

  privacy_purpose: "Purpose of Processing Your Personal Data",
  privacy_purpose_alt:
    "Your personal data that you share with us will be processed and updated in a purposeful and measured manner during the contract and service period in order to fulfill the requirements of the services we offer in the best way possible, to ensure that these services can be accessed and utilized by you at the maximum level, to improve our services in line with your needs and to bring you together with a wider range of service providers within legal frameworks, and to fulfill the obligations arising from the law (sharing personal data with judicial and administrative authorities upon request).",

  transferred_collected_data: "To whom and for what purposes the collected personal data may be transferred",
  transferred_collected_data_alt:
    "Your personal data that you share with us may be transferred to third parties and institutions and organizations in Turkey and abroad from which we receive and / or provide services to carry out our activities, with which we have a contractual relationship, with which we cooperate, and to judicial and administrative authorities upon request, provided that necessary technical and administrative measures are taken.",

  your_rights: "Your Rights as a Person Whose Personal Data is Processed",
  your_rights_alt_p1: "Pursuant to Article 11 of the LPPD, everyone can exercise the following rights by applying to the data controller:",
  your_rights_a: "To learn whether personal data is being processed,",
  your_rights_b: "Request information if your personal data has been processed",
  your_rights_c: "To learn the purpose of the processing of personal data and whether they are used for their intended purpose",
  your_rights_d: "To know the third parties in Turkey or abroad to whom personal data are transferred,",
  your_rights_e: "To request correction of personal data in case of incomplete or incorrect processing,",
  your_rights_f: "Request erasure or destruction of personal data,",
  your_rights_g: "Request notification of the transactions made pursuant to subparagraphs (e) and (f) to third parties to whom personal data are transferred,",
  your_rights_h: "To object to the occurrence of a result to the detriment of the person himself/herself by analyzing the processed data exclusively through automated systems,",
  your_rights_i: "In the event that personal data is damaged due to unlawful processing of personal data, it has the right to demand the compensation of the damage.",
  your_rights_alt_p2: "You can contact us via email@site.com to exercise your rights listed above.",

  contact: "Contact",
  contact_alt_p1:
    "You are completely free to accept or not to accept the processing of your personal data, which is only necessary in order to make analyzes in order to provide services to you, in accordance with this privacy and personal data processing policy. If you continue to use the Site, your acceptance will be assumed by us and you can contact us for more detailed information ",
  contact_email: "contact@lasolist.com",
  contact_alt_p2: " Please do not hesitate to contact us via e-mail",

  userA_agreement: "Terms of Use",
  user_agreement_latest_update_date: "Last updated: 24/10/2023",
  user_agreement_p1:
    "Dear visitor, please read this Terms of Use Agreement carefully before visiting our website https://lasolist.com. Your access to the site is entirely dependent on your acceptance of this agreement and your compliance with the terms set forth in this agreement. If you do not agree to any terms written in this agreement, please terminate your access to the site. Please note that if you continue to access the Site, we will assume that you unconditionally and unrestrictedly accept the entire text of this agreement.",
  user_agreement_p2:
    "https://lasolist.com website is managed by the Company Name and hereinafter referred to as the SITE. The Terms of Use for this site shall enter into force upon publication. The right to make changes unilaterally belongs to the SITE and all our users are deemed to have accepted these changes, which will be shared up-to-date on the SITE.",

  user_agreement_privacy: "Privacy",
  user_agreement_privacy_alt: "Privacy is available on a separate page to regulate the principles of processing of your personal data by us. If you use the SITE, you agree that the processing of this data takes place in accordance with the privacy policy.",

  scope_of_service: "Scope of Service",
  scope_of_service_alt:
    "As Company Name, we are completely free to determine the scope and nature of the services we will provide within the framework of the law; the changes we will make regarding the services will be deemed to have entered into force upon publication on the SITE.",

  copyright: "Copyrights",
  copyright_alt:
    "The owner of all text, code, graphics, logos, images, sound files and software used (hereinafter and hereinafter referred to as 'content') published on the SITE is the Company Name and all rights are reserved. Reproduction or copying of site content without written permission is strictly prohibited.",

  general_provisions: "General Provisions",
  general_provisions_a:
    "All users undertake that they will use the SITE only for lawful and personal purposes and that they will not engage in any activity that may infringe the rights of third parties. The legal and criminal responsibilities in their transactions and actions within the SITE belong to them. The SITE has no direct and / or indirect liability for any damages suffered or may be suffered by third parties due to these works and actions.",
  general_provisions_b:
    "We do our best to ensure the accuracy and currency of the information available on the SITE. However, despite our efforts, this information may lag behind the actual changes and there may be some differences. For this reason, we do not give any guarantee, express or implied, regarding the accuracy and timeliness of the information contained on the site, and no commitment is made.",
  general_provisions_c:
    "The SITE may contain hyperlinks to other websites, applications and platforms operated by third parties and whose contents are unknown to us. The functionality of the SITE only provides access to these sites and we do not accept any responsibility for their content.",
  general_provisions_d:
    "Although we do our best to keep the SITE free of viruses, we do not guarantee that viruses are completely free of viruses. Therefore, it is the responsibility of the users to take the necessary precautions against viruses when downloading data. We cannot accept responsibility for any damage caused by malicious programs, code or materials such as viruses, etc.",
  general_provisions_e: "We do not guarantee that there will be no defects or errors in the services offered on the SITE or that uninterrupted service will be provided. We may terminate your access to the SITE and its services or any part of it at any time without prior notice.",

  limitations_of_responsibility: "Limitation of Liability",
  limitations_of_responsibility_p1:
    "Our liability for damages arising from the use of the SITE is limited to intent and gross negligence. For damages arising from breach of contract, the total compensation that may be claimed is limited to foreseeable damages. The above-mentioned limitations of liability also do not apply in case of damage to human life, bodily injury or health of a person. In all cases of force majeure, we shall not be liable for any compensation for delay, non-performance or default.",
  limitations_of_responsibility_p2: "Dispute Resolution: The laws of the Republic of Turkey shall apply to the resolution of any dispute arising out of the application or interpretation of this Agreement.",

  delivery_and_refund_latest_update_date: "Last updated: 07/02/2024",
  delivery_and_refund_alt_p1: "These Delivery and Return Terms are made between 'laSolist Yazılım Eğitim Bilişim A.Ş.' and the customer.",
  delivery_and_refund_alt_p2: "The subject of this agreement is to regulate the delivery and return terms of digital educational content sold through the www.lasolist.com platform operated by the Company.",
  delivery_header: "Delivery",
  delivery_alt:
    "The Company will provide the content to the user in a digitally accessible way after the user has purchased the training package of the user's choice. The training contents will be accessible for the period of time determined according to the subscription package chosen by the user.",
  pricing_and_payment_header: "Pricing and Payments",
  pricing_and_payment_alt: "Users agree to pay the fee based on the subscription package they have selected. Pricing will be charged monthly starting from the subscription start date. Payments will be made through secure payment systems designated by the Company.",
  cancel_and_refund_header: "Cancel and Refund",
  cancel_and_refund_alt_p1:
    "Users are obliged to notify the Company a certain period of time in advance (for example, one month in advance) if they wish to cancel the subscription package they have purchased. Upon receipt of the cancellation request, the user's subscription will be terminated and access to the content will be suspended.",
  cancel_and_refund_alt_p2:
    "There is no refund for canceled subscriptions. However, in cases where the user cannot fully benefit from the service (e.g. disruption in access to content due to technical problems) The company may offer alternative solutions or partial refunds to the user.",
  privicy_security_header: "Privacy and Security",
  privicy_security_alt: "The Company is committed to keeping users' personal information confidential and ensuring its security. Users' personal information is processed and stored within the framework of the Company's privacy policy.",
  changes_header: "Changes",
  changes_alt_p1: "The Company reserves the right to make changes to this agreement. Changes become effective upon notification to users.",
  changes_alt_p2: "This Delivery and Return Agreement is binding between the parties.",
  laSolist_company: "laSolist Yazılım Eğitim Bilişim Anonim Şirketi",

  distance_latest_update_date: "Last updated: 07/02/2023",
  distance_sides_header: "sides",
  distance_sides_alt_p1: "This contract is entered into between the following parties on the following terms:",
  distance_seller_address: "Address: [Seller Company Address]",
  distance_seller_telephone: "Phone: [Seller Phone Number]",
  distance_seller_mail: "Email: mail@lasolist.com",
  distance_seller_tax_number: "Tax Number: [Seller Tax Number]",
  distance_seller_alt: "(Customer hereinafter referred to as 'Seller')",
  distance_buyer_name_placeholder: "[Buyer First Name Last Name]",
  distance_buyer_address: "Address: [Buyer Address]",
  distance_buyer_telephone: "Phone: [Buyer Phone Number]",
  distance_buyer_mail: "Email: [Buyer Email Address]",
  distance_buyer_alt: "(Customer hereinafter referred to as 'Buyer')",
  distance_sides_alt_p2: "This contract relates to the sale and delivery by the Buyer, in relation to distance sales made through the platform operated by the Seller, of the following products or services.",
  product_info_header: "Product/Service Information",
  product_info_alt: "Seller will provide Buyer with the following product or service:",
  product_info_1: "Product/Service Name:",
  product_info_2: "Product/Service Quantity:",
  product_info_3: "Product/Service Price:",
  product_info_4: "Delivery Date:",
  order_and_delivery_header: "Order and Delivery",
  order_and_delivery_alt: "The Buyer has ordered the product or service provided by the Seller by filling out the order form on the platform. The Seller undertakes to deliver the product/service to the Buyer on the delivery date specified after receiving the order.",
  pricing_and_payment_requirements_header: "Price and Payment Requirements",
  pricing_and_payment_requirements_alt: "The price of the product/service is the amount specified at the time of order. The buyer undertakes to pay the price set for the product/service via the platform or other specified payment methods during checkout.",
  distance_cancel_header: "Cancellation and Returns",
  distance_cancel_alt: "The Buyer agrees to comply with the return procedures specified by the Seller in case the delivered product/service needs to be returned. The Buyer's shipping costs for the return of the returned product/service shall be at its own expense.",
  distance_guarantee_header: "Guarantee",
  distance_guarantee_alt: "The Seller undertakes to provide the Buyer with a free exchange or refund if the product/service sold is found to be defective or damaged.",
  distance_effect_header: "Effective",
  distance_effect_alt: "This agreement enters into force upon electronic acceptance between the parties.",
  distance_dispute_header: "Dispute",
  distance_dispute_alt:
    "In the event of any dispute concerning the application or interpretation of this agreement, the parties shall first negotiate amicably for resolution. If negotiations fail, the dispute shall be submitted to the jurisdiction of the Istanbul Courts, subject to the laws of the Republic of Turkey.",
  other_provisions_header: "Other Provisions",
  other_provisions_alt_p1: "This agreement is a supplemental and binding agreement between the parties. If any provision of this agreement is found to be unlawful or invalid, this shall not affect the validity of the other provisions.",
  other_provisions_alt_p2: "This agreement is entered into by agreement of the parties on [Date]: [Date].",
  seller: "Seller:",
  sign: "Signature:",
  buyer: "Buyer:",

  aboutUsHeader: "About Us",
  aboutUs_p1: "Hello! We are here to accompany you as you step into the fascinating world of music. As LaSolist, our aim as an online education platform is to take baglama enthusiasts and aficionados on a journey into the depths of the art.",
  aboutUs_p2:
    "Playing the baglama is an adventure that goes back to the roots of traditional Turkish music. By combining this traditional instrument with the possibilities of modern technology, we aim to enable everyone to learn this beautiful instrument. By offering our students a detailed curriculum ranging from beginner to advanced level, we are committed to being with them every step of the way.",
  aboutUs_p3:
    "At LaSolist, we not only teach how to play the baglama, but also open a window into the depths of art and culture for our students. Baglama is not only nourished by notes, but also by the rich history, emotional depths and heritage of Turkish music. Therefore, in each of our lessons, we both encourage our students to develop their musical skills and offer a cultural experience.",
  aboutUs_p4: "As the LaSolist family, we believe in the potential of each of our students and are happy to be with them on their musical journey. Join us and discover the power of music by stepping into the magical world of the baglama.",
  aboutUs_p5: "Start your baglama playing adventure today and join us on the magical journey of music!",
  aboutUs_p6: "With love,",
  aboutUs_p7: "LaSolist Team",
  confirmationCodeDoesntMatch: "Your email verification code does not match, please resend the code and try again",
  wrongCodePasted: "Wrong code entered",

  cookies_and_privacy_header: "Cookie Usage and Privacy Policy",
  cookies_and_privacy_alt_p1: "Our website uses cookies to provide a better user experience and to improve our site. Cookies perform functions such as recognizing users and remembering their preferences.",
  cookies_and_privacy_alt_p2: "you can visit the links on the side for more information about cookies.",
  our_user_agreement: "Our User Agreement",
  our_privacy: "Our Privacy Policy",
  our_cookies: "Our Cookie Policy",
  and: "and",
  acceptPrivacyUser: "Accept",

  updateHeader: "We're adding new features to improve the user experience.",

  pricing_header: "Choose the Best Plan for You",
  pricing_description: "Explore our plans and find the one that best suits your needs.",

  forStudent: "For Students?",

  /* Tutorial Section */
  skip: "Skip",
  back: "Back",
  finish: "Finish",

  tutorialHeader: "Welcome to the laSolist Player Tour",
  tutorialContent:
    "Welcome to laSolist Player! In this tour, you will explore the basic features and usage of our application. Throughout the tour, you will be provided with detailed information and visual guidance on each feature. If you are ready, you can start the tour by clicking the 'Next' button.",

  backButtonTitle: "Back Button",
  backButtonContent: "By pressing this button, you can go back to the song list. The song list contains all available songs and categories.",

  videoNameTitle: "Video Name",
  videoNameContent: "Here you can see the name of the song currently playing.",

  tutorialButtonTitle: "App Tour Button",
  tutorialButtonContent: "If you have difficulty using the application, you can access detailed information by clicking here. The tour guide will help you by explaining all the features of our application step by step.",

  controlsTitle: "Video Controls",
  controlsContent: "Video controls allow you to control basic video functions such as play, pause, forward and rewind. You can also control audio controls here, such as volume on/off and adjusting the volume.",

  rewinButtonTitle: "Rewind",
  rewinButtonContent: "Click this button to rewind the video back 5 seconds.",

  returnStartButtonTitle: "Back to the beginning",
  returnStartButtonContent: "You can return to the beginning of the video by clicking this button.",

  playPauseButtonTile: "Play/Pause",
  playPauseButtonContent: "Click this button to play or pause the video.",

  forwardButtonTitle: "Fast Forward",
  forwardButtonContent: "You can fast forward the video for 5 seconds by clicking this button.",

  muteButtonTitle: "Volume On/Off",
  muteButtonContent: "Click this button to mute or unmute the video.",

  volumeSliderTitle: "Volume",
  volumeSliderContent: "Use this slider to adjust the volume of the video.",

  colorSwitchTitle: "Color Notes",
  colorSwitchContent: "laSolist Player uses the colored notes system to help you learn notes faster. Each note is shown in a different color so that it can be easily distinguished. This feature is especially useful for beginners.",

  coloredNoteTitle: "Colored Note Bar",
  coloredNoteContent: "The colored note bar shows the color of the note currently being played. This makes it easier to follow the notes and see which notes you need to play.",

  coloredVideoTitle: "Color Video",
  coloredVideoContent: "Color videos make notes and strings more visually understandable. In the video, the notes played are shown in the upper left corner, while the strings struck are shown in the lower right corner with a close-up camera.",

  colorSwitchEndTitle: "Color Notes",
  colorSwitchEndContent: "Press again to see normal notes.",

  loopButtonTitle: "Loop Property",
  loopButtonContent: "The loop feature allows a specific section to be played repeatedly. This way, you can more easily practice parts that you have difficulty with or want to learn.",

  loopRangeTitle: "Loop Range",
  loopRangeContent: "After entering the loop, the loop range is created up to 1 size.",

  sliderStartTitle: "Loop Start",
  sliderStartContent: "This is the start of the loop. You can change this point using the slider.",

  sliderEndTitle: "Loop End",
  sliderEndContent: "This is the end point of the loop. You can change this point using the slider.",

  loopButtonEndTitle: "Loop Property",
  loopButtonEndContent: "Press again to exit the loop.",

  settingsButtonTitle: "Settings Button",
  settingsButtonContent: "Click this button to access the settings menu. From this menu, you can change various settings such as video quality, video speed and full screen mode.",

  qualityTitle: "Quality Option",
  qualityContent: "Use this menu to select the quality of the video. Higher quality offers a clearer image, but consumes more internet data.",

  qualityMenuTitle: "Quality Menu",
  qualityMenuContent: "The quality menu shows all available video quality options. Click to select the option you want.",

  qualityBackTitle: "Back to the Settings Menu",
  qualityBackContent: "Click this button to return to the settings menu.",

  speedTitle: "Speed Option",
  speedContent: "Use this menu to select the playback speed of the video. A higher speed will play the video faster, but may make it harder to learn.",

  speedMenuTitle: "Speed Menu",
  speedMenuContent: "The speed menu shows all available video speed options. Click to select the option you want.",

  speedBackTitle: "Back from the Speed Menu",
  speedBackContent: "Click this button to return to the settings menu.",

  fullScreenButtonTitle: "Full Screen Mode",
  fullScreenButtonContent: "Click this button to view the video and sheet music in full screen mode. Full screen mode provides a more immersive experience.",

  settingsMenuCloseTitle: "Closing the Settings Menu",
  settingsMenuCloseContent: "Click this button to close the settings menu.",

  tutorialEndTitle: "Ending the Tour",
  tutorialEndContent: "You have completed the laSolist Player tour! You are now ready to use our app. If you have any questions, please refer to the help center within the app or the laSolist Player website.",

  /* src/pages/main/footer/MainFooter.jsx */
  links: "Quick Links",
  company: "Company",
  legal: "Legal",

  couponCode: "Coupon Code",
  enterCouponCode: "Enter Coupon Code",
  apply: "Apply",
  total: "Total",
  summary: "Summary",
  originalPrice: "Original Price",
  discountAmount: "Discount Applied",
  couponSuccessHeader: "The coupon code was successfully applied.",
  couponSuccessAlt: "You have successfully entered your coupon code and received a 1-month membership. You can use our app between the following dates!",
  couponNotFoundHeader: "Invalid coupon code.",
  couponNotFoundAlt: "Make sure your coupon code is correct. Pay attention to capitalization and do not add spaces to the code.",

  settingsCollapseName: "Ayarlar",
  settingsCollapseKey: "Ayarlar&Konfigürasyonlar",

  settingsName: "Ayarlar",
  settingsRoute: "/ayarlar",
  settingsKey: "Ayarlar",

  configurationName: "Konfigürasyonlar",
  configurationRoute: "/konfigurasyonlar",
  configurationKey: "Konfigürasyonlar",

  previewPlanHeader: "Plan Preview",
  previewPlanAlt: "Information about the package you have selected is below",
};

export default locale;
