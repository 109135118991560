import React, { useEffect, useState } from "react";
import AdminLayout from "pages/main/layout/AdminLayout";
import { useDispatch } from "react-redux";
import LoadingComponent from "components/Loading";
import { getMusicians } from "store/main/musiciansSlice";
import MusicianDialog from "./MusicianDialog";
import MusiciansTable from "./MusiciansTable";
import { removeMusician } from "store/main/musiciansSlice";
import ConfirmDialog from "pages/confirmation/ConfirmDialog";

const AdminMusiciansDashboard = (props) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(getMusicians()).then(() => setDataLoading(false));
  }, [dispatch]);
  
  if (dataLoading) {
    return <LoadingComponent />;
  }
  
  return (
    <AdminLayout>
      <MusicianDialog />
      <MusiciansTable />
      <ConfirmDialog handleDeleteData={removeMusician} />
    </AdminLayout>
  );
};

AdminMusiciansDashboard.propTypes = {};

export default AdminMusiciansDashboard;
