import { Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import ImageUpload from "components/UploadComponents/ImageUpload";
import { imageProxy } from "features/api/proxy";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectinstructorCourses } from "store/main/instructorCoursesSlice";
import {
  addinstructorLesson,
  closeEditinstructorLessonDialog,
  closeNewinstructorLessonDialog,
  updateinstructorLesson,
} from "store/main/instructorLessonsSlice";

const defaultFormState = {
  lessonId: 0,
  lessonNameTr: "",
  lessonDescriptionTr: "",
  lessonNameEn: "",
  lessonDescriptionEn: "",
  lessonNameDe: "",
  lessonDescriptionDe: "",
  lessonNameAr: "",
  lessonDescriptionAr: "",
  status: "active",
  courseId: 0,
  level: "",
  url: "",
  videoUrl: "",
  duration: "",
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
];

function InsLessonDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const instructorLessonDialog = useSelector(
    ({ instructorLessons }) => instructorLessons.instructorLessonDialog
  );

  const courses = useSelector(selectinstructorCourses);

  const [photo, setphoto] = useState();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });
  const [courseValue, setCourseValue] = useState({
    id: 0,
    name: "",
  });
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (instructorLessonDialog.type === "edit" && instructorLessonDialog.data) {
      setForm({ ...instructorLessonDialog.data });
      fetch(`${imageProxy}/${instructorLessonDialog.data.url}`)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], instructorLessonDialog.data.url, {
            type: blob.type,
          });
          setphoto(file);
        })
        .catch((error) => {
          console.error("Dosya bulunamadı:", error);
        });
      let status = statusOptions.find(
        (f) => f.name === instructorLessonDialog.data.status
      );
      let course = courses.find(
        (f) => f.courseId === instructorLessonDialog.data.courseId
      );

      setStatusValue(status);
      setCourseValue(course);
    }

    /**
     * Dialog type: 'new'
     */
    if (instructorLessonDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...instructorLessonDialog.data,
      });
      setphoto();
      let status = statusOptions.find(
        (f) => f.name === defaultFormState.status
      );
      let course = courses.find(
        (f) => f.courseId === defaultFormState.courseId
      );

      setStatusValue(status);
      setCourseValue(course);
    }
  }, [
    instructorLessonDialog.data,
    instructorLessonDialog.type,
    setForm,
    courses,
  ]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (instructorLessonDialog.props.open) {
      initDialog();
    }
  }, [instructorLessonDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return instructorLessonDialog.type === "edit"
      ? dispatch(closeEditinstructorLessonDialog())
      : dispatch(closeNewinstructorLessonDialog());
  }

  const handleSetImage = (file) => {
    setphoto(file);
    if (!file) {
      setInForm("url", null);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (instructorLessonDialog.type === "new") {
      let data = {
        ...form,
        status: statusValues.id,
        courseId: courseValue.id,
        url: photo,
      };
      setphoto();
      dispatch(addinstructorLesson(data));
    } else {
      let data = {
        ...form,
        status: statusValues.id,
        courseId: courseValue.id,
        url: photo,
      };
      setphoto();
      dispatch(updateinstructorLesson(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    return (
      form.lessonNameTr.length > 0 &&
      form.lessonDescriptionTr.length > 0 &&
      form.lessonNameEn.length > 0 &&
      form.lessonDescriptionEn.length > 0 &&
      form.lessonNameDe.length > 0 &&
      form.lessonDescriptionDe.length > 0 &&
      form.lessonNameAr.length > 0 &&
      form.lessonDescriptionAr.length > 0 &&
      photo !== undefined &&
      courseValue !== undefined
    );
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...instructorLessonDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">{t("instrumentInfo")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="lessonId"
          name="lessonId"
          label={t("lessonId")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.lessonId}
          disabled
        />

        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                value={courseValue}
                options={courses}
                getOptionLabel={(option) => option.name}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderInput={(params) => (
                  <TextField {...params} label={t("courseType")} />
                )}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setCourseValue({
                      id: newValue,
                      name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    setCourseValue({
                      name: newValue.inputValue,
                    });
                  } else {
                    setCourseValue(newValue);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="duration"
                name="duration"
                label={t("duration")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.duration}
                onChange={handleChange}
                required
                error={form.duration ? false : true}
                helperText={t("enterDuration")}
                focused
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="lessonNameTr"
                name="lessonNameTr"
                label={t("lessonNameTr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonNameTr}
                onChange={handleChange}
                required
                error={form.lessonNameTr ? false : true}
                helperText={t("enterLessonNameTr")}
                focused
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="lessonDescriptionTr"
                name="lessonDescriptionTr"
                label={t("lessonDescriptionTr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonDescriptionTr}
                onChange={handleChange}
                required
                error={form.lessonDescriptionTr ? false : true}
                helperText={t("enterLessonDescriptionTr")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="lessonNameEn"
                name="lessonNameEn"
                label={t("lessonNameEn")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonNameEn}
                onChange={handleChange}
                required
                error={form.lessonNameEn ? false : true}
                helperText={t("enterLessonNameEn")}
                focused
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="lessonDescriptionEn"
                name="lessonDescriptionEn"
                label={t("lessonDescriptionEn")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonDescriptionEn}
                onChange={handleChange}
                required
                error={form.lessonDescriptionEn ? false : true}
                helperText={t("enterLessonDescriptionEn")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="lessonNameDe"
                name="lessonNameDe"
                label={t("lessonNameDe")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonNameDe}
                onChange={handleChange}
                required
                error={form.lessonNameDe ? false : true}
                helperText={t("enterLessonNameDe")}
                focused
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="lessonDescriptionDe"
                name="lessonDescriptionDe"
                label={t("lessonDescriptionDe")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonDescriptionDe}
                onChange={handleChange}
                required
                error={form.lessonDescriptionDe ? false : true}
                helperText={t("enterLessonDescriptionDe")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="lessonNameAr"
                name="lessonNameAr"
                label={t("lessonNameAr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonNameAr}
                onChange={handleChange}
                required
                error={form.lessonNameAr ? false : true}
                helperText={t("enterLessonNameAr")}
                focused
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="lessonDescriptionAr"
                name="lessonDescriptionAr"
                label={t("lessonDescriptionAr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonDescriptionAr}
                onChange={handleChange}
                required
                error={form.lessonDescriptionAr ? false : true}
                helperText={t("enterLessonDescriptionAr")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <TextField
            autoFocus
            margin="dense"
            id="videoUrl"
            name="videoUrl"
            label={t("videoUrl")}
            type="text"
            fullWidth
            variant="outlined"
            value={form.videoUrl}
            onChange={handleChange}
            required
            error={form.videoUrl ? false : true}
            helperText={t("enterVideoUrl")}
            focused
          />
        </MDBox>
        <MDBox mt={1}>
          <Autocomplete
            value={statusValues} // Dikkat: statusValues -> statusValue olarak değiştirildi
            options={statusOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("status")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                // newValue.name -> newValue olarak değiştirildi
                setStatusValue({
                  id: newValue, // newValue.id -> newValue olarak değiştirildi
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                setStatusValue({
                  name: newValue.inputValue,
                });
              } else {
                setStatusValue(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox mt={2}>
          <ImageUpload
            handleSetImage={handleSetImage}
            file={photo}
            url={form && form.url ? `${imageProxy}/${form.url}` : null}
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InsLessonDialog;
