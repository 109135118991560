import React from "react";
import DashboardLayout from "pages/main/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/main/DashboardNavbar";

import MDBox from "components/MDBox";

const AdminLayout = ({ children }) => {
  return (
    <DashboardLayout>
      <div style={{ height: "calc(100vh - 3rem)", display: "flex", flexDirection: "column" }}>
        <DashboardNavbar />
        <MDBox sx={{ flexGrow: 1 }}>
          {children}
        </MDBox>
      </div>
    </DashboardLayout>
  );
};

AdminLayout.propTypes = {};

export default AdminLayout;

