import LoadingComponent from 'components/Loading';
import StudentLayout from 'pages/main/layout/StudentLayout';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { getInstructorCoursesById } from 'store/main/instructorCoursesSlice';
import CoursesList from '../educations/courses/CoursesList';
import Header from './Header';

function InstructorDashboard() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getInstructorCoursesById(id)).then(() => setLoading(false));
  }, [dispatch, id]);


  if (loading) {
    return <LoadingComponent />
  }

  return (
    <StudentLayout>
      <Header />
      <CoursesList />
    </StudentLayout>
  )
}

export default InstructorDashboard