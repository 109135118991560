import React from "react";

const LoadingComponent = ({ backgroundColor }) => {
  return (
    <div style={{ backgroundColor }} className="loading">
      <div className="spinner"></div>
    </div>
  );
};

LoadingComponent.propTypes = {};

export default LoadingComponent;