import React, { useState } from 'react';
import { Box, TextField, Button, List, ListItem, ListItemText, Icon, Card, Grid } from '@mui/material';
import MDButton from 'components/MDButton';
import { useForm } from 'features/hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { selectLiveSupportsById } from 'store/main/liveSupportsSlice';
import { useSelector } from 'react-redux';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { useDispatch } from 'react-redux';
import { addMessage } from 'store/main/messagesSlice';
import io from 'socket.io-client';
import { useSocket } from 'hooks/AdminSocketContext';
import { getLiveSupports } from 'store/main/liveSupportsSlice';

const defaultFormState = {
  message: "",
};

const SendMessageSection = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const liveSupport = useSelector((state) => selectLiveSupportsById(state, id));
  const { form, handleChange, setForm } = useForm(defaultFormState);
  const { userName } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const { socket } = useSocket();

  const handleSubmit = () => {
    console.log(form);
    let data = {
      ...form,
      liveSupportId: id,
      sentFrom: "admin",
      userName,
      createdDate: new Date(),
    }
    dispatch(addMessage(data)).then(() => {
      dispatch(getLiveSupports());
      socket.emit('refresh_live_supports_user', {
        receiverUser: liveSupport.userName,
      });
      setForm(defaultFormState)
    });
  }


  return (
    <Box container spacing={1} sx={{ display: "flex", gap: 2 }}>

      <MDButton variant="gradient" color="dark">
        <Icon>attach_file</Icon>
      </MDButton>

      <TextField
        variant="outlined"
        type="text"
        label={t("message")}
        fullWidth
        value={form.message}
        onChange={handleChange}
        id="message"
        name="message"
      />

      <MDButton onClick={handleSubmit} sx={{ height: "100%" }} variant="gradient" color="info">
        <Icon>send</Icon>
      </MDButton>
    </Box>
  );
};

export default SendMessageSection;