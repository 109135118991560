import { Card, Container } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import CoverLayout from 'pages/main/layout/CoverLayout'
import React from 'react'

import bgImage from "assets/images/laSolist_player_01.jpeg";
import { useTranslation } from 'react-i18next'

function DistanceSelling() {

  const { t } = useTranslation();

  return (
    <CoverLayout image={bgImage} coverHeight={"calc(100vh - 2rem)"}>
      <Container sx={{ transform: "translate(0, 45%)" }}>
        <Card
          sx={{
            background: "rgba(255,255,255, .90)",
            padding: "30px",
          }}>
          <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("distanceSellingName")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("distance_latest_update_date")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("distance_sides_header")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("distance_sides_alt_p1")}
          </MDTypography>
          <MDBox mb={2}>
            <ul style={{ listStyle: "none", paddingLeft: 40 }}>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  <b>{t("seller")}</b> {t("laSolist_company")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("distance_seller_address")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("distance_seller_telephone")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("distance_seller_mail")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("distance_seller_tax_number")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("distance_seller_alt")}
                </MDTypography>
              </li>
            </ul>
          </MDBox>
          <MDBox marginBottom={2}>
            <ul style={{ listStyle: "none", paddingLeft: 40 }}>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  <b>{t("buyer")}</b> {t("distance_buyer_name_placeholder")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("distance_buyer_address")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("distance_buyer_telephone")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("distance_buyer_mail")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("distance_buyer_alt")}
                </MDTypography>
              </li>
            </ul>
          </MDBox>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("distance_sides_alt_p2")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("product_info_header")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("product_info_alt")}
          </MDTypography>
          <MDBox marginBottom={2}>
            <ul style={{ listStyle: "none", paddingLeft: 40 }}>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("product_info_1")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("product_info_2")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("product_info_3")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("product_info_4")}
                </MDTypography>
              </li>
            </ul>
          </MDBox>
          <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("order_and_delivery_header")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("order_and_delivery_alt")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("pricing_and_payment_requirements_header")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("pricing_and_payment_requirements_alt")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("distance_cancel_header")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("distance_cancel_alt")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("distance_guarantee_header")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("distance_guarantee_alt")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("distance_effect_header")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("distance_effect_alt")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("other_provisions_header")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("other_provisions_alt_p1")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("other_provisions_alt_p2")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }}>
            <b>{t("seller")}</b>
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("sign")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }}>
            <b>{t("buyer")}</b>
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }}>
            {t("sign")}
          </MDTypography>
        </Card>
      </Container>
    </CoverLayout>
  )
}

export default DistanceSelling