import CoverLayout from 'pages/main/layout/CoverLayout'
import React from 'react'
import FaqSection from 'pages/landing/components/FaqSection'

function FAQs() {
  return (
    <CoverLayout>
      <FaqSection />
    </CoverLayout>
  )
}

export default FAQs