import LoadingComponent from 'components/Loading';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { changeCardInformation } from 'store/iyzico/iyzicoSubscriptionsSlice';


function CheckoutForm() {
  const dispatch = useDispatch();

  const { subscriptionReferenceCode } = useParams();

  const { userInfo } = useSelector((state) => state.subscriptions);
  const [htmlContent, setHtmlContent] = useState(null);

  useEffect(() => {
    dispatch(changeCardInformation({ subscriptionReferenceCode }))
      .then((params) => {
        const content = params.payload.checkoutFormContent + '<div id="iyzipay-checkout-form" className="responsive"></div>';
        setHtmlContent(content);
      })
  }, [userInfo]);

  const iframeRef = useRef(null);



  return (
    <div>
      <iframe ref={iframeRef} style={{ border: "none", borderRadius: "8px" }} width={"100%"} height={"600"} srcDoc={htmlContent} />
    </div>
  );
};

export default CheckoutForm;