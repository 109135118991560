import { Autocomplete, Card, FormControl, Grid, Icon, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectinstruments } from 'store/main/instrumentsSlice';
import { selectuserfavorites } from 'store/main/userFavoritesSlice';
import { setInstrumentValue } from 'store/main/userFavoritesSlice';
import { setUserFavoritesSearchText } from 'store/main/userFavoritesSlice';

function SearchBar() {
  const dispatch = useDispatch();
  const favorites = useSelector(selectuserfavorites);
  const instruments = useSelector(selectinstruments);


  const { t } = useTranslation();

  const { instrumentValue } = useSelector((state) => state.userFavorites);

  const handleSearch = (e) => {
    // console.log(e.target.value);
    dispatch(setUserFavoritesSearchText(e));
  }

  if (favorites.length === 0) {
    return null;
  }


  return (
    <Card sx={{ marginBottom: 2, padding: 2 }}>
      <Grid container spacing={2} display={"flex"} justifyContent={"center"}>
        <Grid item xs={12} md={2}>
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">{t("SEARCH")}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={'text'}
              onChange={handleSearch}
              sx={{ padding: "4px" }}
              endAdornment={
                <InputAdornment sx={{ mr: "4px" }} position="end">
                  <Icon fontSize={"medium"}>search</Icon>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={2}>
          <Autocomplete
            value={instrumentValue}
            options={instruments}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("instruments")} />
            )}
            onChange={(event, newValue) => {
              if (newValue === null) {
                dispatch(setInstrumentValue({
                  id: "",
                  name: ""
                }));
              } else if (typeof newValue.name === "string") {
                dispatch(setInstrumentValue({
                  id: newValue.id,
                  name: newValue.name,
                }));
              } else if (newValue && newValue.inputValue) {
                dispatch(setInstrumentValue({
                  name: newValue.inputValue,
                }));
              } else {
                dispatch(setInstrumentValue(newValue));
              }
            }}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default SearchBar