import React from 'react'
import { useState } from 'react';
import { FullscreenExit, Fullscreen } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

function FullScreenButton({ handle }) {
  const [enter, setEnter] = useState(false);

  const { t } = useTranslation();

  const handleClick = () => {
    if (enter) {
      handle.exit()
      setEnter(false);
    }
    else {
      handle.enter()
      setEnter(true);
    }
  }

  return (
    <Tooltip title={t("Fullscreen")}>
      <button className='FullScreenButton' onClick={handleClick}>
        {
          enter ? <FullscreenExit sx={{ height: "full" }} /> : <Fullscreen sx={{ height: "full" }} />

        }
      </button>
    </Tooltip>
  )
}

export default FullScreenButton