
import MDBox from 'components/MDBox'
import React from 'react'
import { useSelector } from 'react-redux'
import RewindButton from '../buttons/RewindButton';
import PlayPauseButton from '../buttons/PlayPauseButton';
import ForwardButton from '../buttons/ForwardButton';
import Progress from '../progress/Progress';
import LoopButton from '../buttons/LoopButton';
import VolumeButton from '../buttons/VolumeButton/VolumeButton';
import SpeedButton from '../buttons/SpeedButton/SpeedButton';
import FullScreenButton from '../buttons/FullScreenButton';
import SettingsButton from 'components/Controls/Menu/Settings/SettingsButton';

function VideoControls({ fullScreenHandler }) {

  return (
    <div className={"ControlsContainerV2"}>
      < Progress />
      <MDBox display={"flex"} justifyContent={"space-between"} width={"100%"}>
        <MDBox display={"flex"}>
          <RewindButton />
          <PlayPauseButton />
          <ForwardButton />
          <VolumeButton />
        </MDBox>

        <MDBox display={"flex"}>
          <SettingsButton />
          <FullScreenButton handle={fullScreenHandler} />
        </MDBox>
      </MDBox>
    </div >
  )
}

export default VideoControls