import React from "react";
import StudentLayout from "pages/main/layout/StudentLayout";
import FavoritesList from "./FavoritesList";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import LoadingComponent from "components/Loading";
import { getCategories } from "store/main/categoriesSlice";
import { getInstruments } from "store/main/instrumentsSlice";
import { getUserFavoritesByQuery } from "store/main/userFavoritesSlice";
import { useSelector } from "react-redux";
import SearchBar from "./SearchBar";
import { resetUserFavoritesSearch } from "store/main/userFavoritesSlice";
import { getSongLists } from "store/main/songListsSlice";

const FavoritesDashboard = (props) => {

  const dispatch = useDispatch();

  const { userName } = useSelector((state) => state.auth);

  const [dataLoading, setDataLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [instrumentsLoading, setInstrumentsLoading] = useState(true);
  const [songLoading, setSongLoading] = useState(true);

  useEffect(() => {
    dispatch(getUserFavoritesByQuery(userName)).then(() => setDataLoading(false));
    dispatch(getSongLists()).then(() => setSongLoading(false));
    dispatch(getCategories()).then(() => setCategoriesLoading(false));
    dispatch(getInstruments()).then(() => setInstrumentsLoading(false));
    dispatch(resetUserFavoritesSearch());
  }, [dispatch, userName]);

  if (dataLoading || categoriesLoading || instrumentsLoading || songLoading) {
    return <LoadingComponent />;
  }

  return (
    <StudentLayout>
      <SearchBar />
      <FavoritesList />
    </StudentLayout>
  );
};

FavoritesDashboard.propTypes = {};

export default FavoritesDashboard;
