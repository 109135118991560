/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples


// Wizard page components
import { Icon } from "@mui/material";
import MainFooter from "pages/main/footer/MainFooter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";






function Wizard() {


  const { t } = useTranslation();



  return (
    <MDBox mx={1}>
      <MDBox pt={3}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
              <Link to={"/profil"}>
                <MDButton color={"error"} variant={"gradient"} sx={{ width: "150px" }}>
                  <Icon sx={{ transform: "scaleX(-1)" }}>logout</Icon>&nbsp;
                  {t("goBack")}
                </MDButton>
              </Link>
              <MDButton sx={{ width: "150px" }} color={"info"} variant={"gradient"}>TR</MDButton>
            </MDBox>
          </Grid>

          <Grid item xs={12} lg={8}>

            <MDBox mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">

                  {t("change_card_wizard_header")}
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" color="secondary" fontWeight="regular">

                {t("change_card_wizard_description")}
              </MDTypography>
            </MDBox>
            <Card>

              <MDBox p={2}>
                <MDBox>
                  <CheckoutForm />

                </MDBox>
              </MDBox>
            </Card>
          </Grid>

          <Grid my={4} item xs={12} md={8}>
            <MainFooter />
          </Grid>
        </Grid>


      </MDBox>
    </MDBox >
  );
}

export default Wizard;
