import { Box, Grid } from '@mui/material'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectStatistics } from 'store/main/statisticsSlice'

function Statistics() {
  const statistics = useSelector(selectStatistics);

  const renderPercentageColor = (percentage) => {
    if (percentage > 0) {
      return "success"
    }
    else if (percentage < 0) {
      return "error"
    }
  }

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        {statistics.map((statistic, index) => (
          <Grid key={index} item xs={12} lg={4}>
            <ComplexStatisticsCard
              color={statistic.color}
              icon={statistic.icon}
              title={statistic.title}
              count={statistic.count}
              percentage={{
                color: renderPercentageColor(statistic.percentage),
                amount: "%" + statistic.percentage,
                label: "than last month",
              }} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Statistics