import LoadingComponent from 'components/Loading';
import StudentLayout from 'pages/main/layout/StudentLayout';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCourses } from 'store/main/coursesSlice';
import { getLessons } from 'store/main/lessonsSlice';
import LessonsList from './LessonsList';
import Header from './components/Header';

function LessonsDashboard() {

  const dispatch = useDispatch();
  const [dataLoading, setDataLoading] = useState(true);
  const [lessonsLoading, setLessonsLoading] = useState(true);

  useEffect(() => {
    dispatch(getCourses()).then(() => setDataLoading(false));
    dispatch(getLessons()).then(() => setLessonsLoading(false));
  }, [dispatch]);

  if (dataLoading || lessonsLoading) {
    return <LoadingComponent />;
  }

  return (
    <StudentLayout>
      <Header />
      <LessonsList />
    </StudentLayout>
  )
}

export default LessonsDashboard