import React from 'react'
import bgImage from "assets/images/laSolist_player_01.jpeg";
import CoverLayout from "pages/main/layout/CoverLayout";
import { Card, Container } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';



import { useTranslation } from "react-i18next";
import MainFooter from 'pages/main/footer/MainFooter';

function Cookies() {

  const { t } = useTranslation();
  return (
    <CoverLayout image={bgImage} coverHeight={"calc(100vh - 2rem)"}>
      <Container sx={{ transform: "translate(0, 40%)", }}>
        <Card
          sx={{
            background: "rgba(255,255,255, .90)",
            padding: "30px",
          }}>
          <MDBox marginX={2}>
            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("cookies_header")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("cookies_latest_update_date")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("cookies_alt_p1")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("cookies_alt_p2")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("what_is_cookie")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("what_is_cookie_p1")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("what_is_cookie_p2")}
            </MDTypography>

            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("mandatory_cookie")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("mandatory_cookie_alt")}
            </MDTypography>

            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("performance_cookie")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("performance_cookie_alt")}
            </MDTypography>

            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("functional_cookie")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("functional_cookie_alt")}
            </MDTypography>

            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("ad_cookie")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("ad_cookie_alt")}
            </MDTypography>

            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("data_proccessed_with_cookie")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("data_proccessed_with_cookie_alt")}
            </MDTypography>

            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("what_purpose")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("what_purpose_alt")}
            </MDTypography>

            <MDTypography fontSize={{ xs: "1.4rem", lg: "1.45rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("how_to_manage")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("how_to_manage_alt_p1")}
              <MDTypography component={"a"} fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} fontWeight={"bold"} href={`mailto:${t("contact_email")}`}>
                {t("contact_email")}
              </MDTypography>
              {t("how_to_manage_alt_p2")}
            </MDTypography>
          </MDBox>
        </Card>
      </Container>
    </CoverLayout>
  )
}

export default Cookies