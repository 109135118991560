import React from 'react'
import VolumeSlider from './VolumeSlider'
import MuteButton from './MuteButton'

function VolumeButton({ setVolume }) {
  return (
    <div className={"VolumeContainer"}>
      <MuteButton setVolume={setVolume} />
      <VolumeSlider setVolume={setVolume} />
    </div>
  )
}

export default VolumeButton