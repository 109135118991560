import { Typography } from '@mui/material'
import MDBox from 'components/MDBox'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

function RequirementsItem({ name, regex }) {
  const { t } = useTranslation();

  return (
    <MDBox sx={{ display: "flex", gap: 6, justifyContent: "space-between" }}>
      <Typography sx={{ fontSize: 16, textDecoration: regex ? "line-through" : ""}}>{t(name)}</Typography>

      <CheckCircleTwoToneIcon color={regex ? "success" : ""} fontSize='medium'/>
      {/* <Icon color='success' fontSize='medium'>check_circle_two_tone</Icon> */}
    </MDBox>
  )
}

export default RequirementsItem