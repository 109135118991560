import { Card, Grid } from '@mui/material';
import DefaultCard from 'components/Cards/SongCard/DefaultCard';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { imageProxy } from 'features/api/proxy';
import { PlayerProvider } from 'hooks/PlayerProvider';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectsongListsById } from 'store/main/songListsSlice';
import { selectuserfavorites } from 'store/main/userFavoritesSlice';

function FavoritesList() {
  const favorites = useSelector(selectuserfavorites);
  const { t, i18n } = useTranslation();

  console.log(favorites);

  const { searchText, instrumentValue } = useSelector((state) => state.userFavorites);

  const [filteredSongList, setFilteredSongList] = useState([]);


  useEffect(() => {
    const list = favorites
      .filter((f) => f.songInfo.songInfo.songName.toLowerCase().includes(searchText.toLowerCase()))
    setFilteredSongList(list)
  }, [searchText, favorites]);

  if (favorites.length === 0) {
    return (
      <Card>
        <MDBox p={3}>
          <MDTypography fontSize={{ xs: "1.1rem", lg: "1.2rem", xl: "1.3rem" }} fontWeight={"bold"}>
            {t("favoritesNotFound")}
          </MDTypography>
        </MDBox>
      </Card>
    )
  }

  if (filteredSongList.length === 0) {
    return (
      <Card>
        <MDBox p={3}>
          <MDTypography fontSize={{ xs: "1.1rem", lg: "1.2rem", xl: "1.3rem" }} fontWeight={"bold"}>
            {t("songDoesntMatch")}
          </MDTypography>
        </MDBox>
      </Card>
    )
  }

  return (
    <MDBox>
      <Grid container spacing={2}>
        {filteredSongList.map((favorite, key) => {
          const { songInfo } = favorite.songInfo;

          console.log("fav", favorite);

          if (instrumentValue.id !== "") {
            if (instrumentValue.id === favorite.songInfo.instrumentId) {
              return (
                <PlayerProvider selectSongState={selectsongListsById} videoId={favorite.listId}>
                  <Grid key={key} item xs={12} md={6} xl={4}>
                    <DefaultCard
                      details={{
                        type: "song",
                        route: `/video/${favorite.listId}`,
                        views: favorite.count,
                        key: key,
                      }}
                      image={`${imageProxy}/${songInfo.url}`}
                      title={songInfo.songName}
                      description={
                        (i18n.language.match("tr") &&
                          songInfo.songDescriptionTr) ||
                        (i18n.language.match("en") &&
                          songInfo.songDescriptionEn) ||
                        (i18n.language.match("de") &&
                          songInfo.songDescriptionDe) ||
                        (i18n.language.match("ar") &&
                          songInfo.songDescriptionAr)
                      }
                      duration={favorite.songInfo.details.duration}
                      listId={favorite.listId}
                      favoriteId={favorite.favoriteId}
                    />
                  </Grid>
                </PlayerProvider>
              );
            }
          }
          else {
            return (
              <PlayerProvider selectSongState={selectsongListsById} videoId={favorite.listId}>
                <Grid key={key} item xs={12} md={6} xl={4}>
                  <DefaultCard
                    details={{
                      type: "song",
                      route: `/video/${favorite.listId}`,
                      key: key,
                      views: favorite.count,
                    }}
                    image={`${imageProxy}/${songInfo.url}`}
                    title={songInfo.songName}
                    description={
                      (i18n.language.match("tr") &&
                        songInfo.songDescriptionTr) ||
                      (i18n.language.match("en") &&
                        songInfo.songDescriptionEn) ||
                      (i18n.language.match("de") &&
                        songInfo.songDescriptionDe) ||
                      (i18n.language.match("ar") &&
                        songInfo.songDescriptionAr)
                    }
                    duration={favorite.songInfo.details.duration}
                    listId={favorite.listId}
                    favoriteId={favorite.favoriteId}
                  />
                </Grid>
              </PlayerProvider>
            );
          }

          return null;
        })}
      </Grid>
    </MDBox>
  );
}

export default FavoritesList;
