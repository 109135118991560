import AdminLayout from 'pages/main/layout/AdminLayout'
import React from 'react'
import IyzicoSubscriptionTable from './IyzicoSubscriptionTable'
import IyzicoSubscriptionDialog from './IyzicoSubscriptionDialog'
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { getSubscriptions } from 'store/iyzico/iyzicoSubscriptionsSlice';
import LoadingComponent from 'components/Loading';

function IyzicoSubscriptionDashboard() {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(getSubscriptions()).then(() => setDataLoading(true));
  }, [dispatch]);
  if (dataLoading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <IyzicoSubscriptionTable />
      <IyzicoSubscriptionDialog />
    </AdminLayout>
  )
}

export default IyzicoSubscriptionDashboard