import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";

export const getCourses = createAsyncThunk(
  "courses/getCourses",
  async (_, { getState }) => {
    const response = await axiosConfig.get(`/api/courses`);
    // const response = await axios.get(`${proxy}/api/courses`)

    let { data } = await response.data;
    console.log(data);
    return data;
  }
);

export const addCourses = createAsyncThunk(
  "courses/addCourses",
  async (course, { dispatch, getState }) => {
    try {
      let formData = new FormData();

      formData.append("courseName", course.courseName);
      formData.append("courseDescriptionTr", course.courseDescriptionTr);
      formData.append("courseDescriptionEn", course.courseDescriptionEn);
      formData.append("courseDescriptionDe", course.courseDescriptionDe);
      formData.append("courseDescriptionAr", course.courseDescriptionAr);
      formData.append("url", course.url);
      formData.append("duration", course.duration);
      formData.append("level", course.level);
      formData.append("instrumentId", course.instrumentId);
      formData.append("status", course.status);
      formData.append(
        "courseCategoriesData",
        JSON.stringify(course.courseCategoriesData)
      );
      formData.append(
        "courseLessonsData",
        JSON.stringify(course.courseLessonsData)
      );

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/courses`,
        formData,
        config
      );
      let { data } = await response.data;
      if (response.data.success === true) {

        return data;
      }
    } catch (error) {


      return null;
    }
  }
);

export const updateCourses = createAsyncThunk(
  "courses/updateCourses",
  async (course, { dispatch, getState }) => {
    let formData = new FormData();

    formData.append("courseId", course.courseId);
    formData.append("courseName", course.courseName);
    formData.append("courseDescriptionTr", course.courseDescriptionTr);
    formData.append("courseDescriptionEn", course.courseDescriptionEn);
    formData.append("courseDescriptionDe", course.courseDescriptionDe);
    formData.append("courseDescriptionAr", course.courseDescriptionAr);
    formData.append("url", course.url);
    formData.append("duration", course.duration);
    formData.append("level", course.level);
    formData.append("instrumentId", course.instrumentId);
    formData.append("status", course.status);
    formData.append(
      "courseCategoriesData",
      JSON.stringify(course.courseCategoriesData)
    );
    formData.append(
      "courseLessonsData",
      JSON.stringify(course.courseLessonsData)
    );
    
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/courses/${course.courseId}`,
      formData,
      config
    );
    const { data } = await response.data;
    if (response.data.success === true) {
      return data;
    }
    return null;
  }
);

export const removeCourses = createAsyncThunk(
  "courses/removeCourses",
  async (courseId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/courses/${courseId}`);
    if (response.data.success === true) {

      return courseId;
    }
    return courseId;
  }
);

const coursesAdapter = createEntityAdapter({
  selectId: (course) => course.courseId,
});

export const {
  selectAll: selectcourses,
  selectById: selectcoursesById,
} = coursesAdapter.getSelectors((state) => state.courses);

const coursesSlice = createSlice({
  name: "courses",
  initialState: coursesAdapter.getInitialState({
    searchText: "",
    instrumentValue: {
      id: "",
      name: "",
    },
    categoriesValue: {
      id: "",
      name: "",
    },
    routeParams: {},
    courseDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setCoursesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    setInstrumentValue: (state, action) => {
      state.instrumentValue = action.payload;
    },
    setCategoriesValue: (state, action) => {
      state.categoriesValue = action.payload;
    },
    openNewCoursesDialog: (state, action) => {
      state.courseDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewCoursesDialog: (state, action) => {
      state.courseDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditCoursesDialog: (state, action) => {
      state.courseDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditCoursesDialog: (state, action) => {
      state.courseDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateCourses.fulfilled]: coursesAdapter.upsertOne,
    [addCourses.fulfilled]: coursesAdapter.addOne,
    [removeCourses.fulfilled]: (state, action) =>
      coursesAdapter.removeOne(state, action.payload),
    [getCourses.fulfilled]: coursesAdapter.setAll,
  },
});

export const {
  setCoursesSearchText,
  setInstrumentValue,
  setCategoriesValue,
  openNewCoursesDialog,
  closeNewCoursesDialog,
  openEditCoursesDialog,
  closeEditCoursesDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = coursesSlice.actions;

export default coursesSlice.reducer;
