import { Alert, Card, Container, Snackbar } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import React from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login } from 'store/auth/authSlice'
import { resendVerificationCode } from 'store/auth/authSlice'
import { confirmEmail } from 'store/auth/authSlice'
import { checkSubscription } from 'store/payment/paymentsSlice'

function ConfirmEmail({ handleClose, form }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const [number1, setNumber1] = useState("");
  const [number2, setNumber2] = useState("");
  const [number3, setNumber3] = useState("");
  const [number4, setNumber4] = useState("");
  const [number5, setNumber5] = useState("");
  const [number6, setNumber6] = useState("");

  const [error, setError] = useState({
    response: false,
    status: "success",
    message: "",
  });

  const number1ref = useRef();
  const number2ref = useRef();
  const number3ref = useRef();
  const number4ref = useRef();
  const number5ref = useRef();
  const number6ref = useRef();

  const handleChangeNumber = (e) => {
    console.log(e.target.value)
    var regex = /^\d+$/;
    const inputValue = e.target.value;
    const targetName = e.target.name;

    if (e.target.value.length > 1) {
      // const pastedText = e.clipboardData.getData('text');
      // const trimmedString = pastedText.trim();

      // setNumber1(trimmedString[0]);
      // setNumber2(trimmedString[1]);
      // setNumber3(trimmedString[2]);
      // setNumber4(trimmedString[3]);
      // setNumber5(trimmedString[4]);
      // setNumber6(trimmedString[5]);
    }
    else if (targetName === "number1") {
      if (inputValue === "") {
        setNumber1(inputValue);
      }
      else if (regex.test(inputValue)) {
        setNumber1(inputValue);
        number2ref.current.focus();
      }
    }
    else if (targetName === "number2") {

      if (inputValue === "") {
        setNumber2(inputValue);
      }
      else if (regex.test(inputValue)) {
        setNumber2(inputValue);
        number3ref.current.focus();
      }
    }
    else if (targetName === "number3") {
      if (inputValue === "") {
        setNumber3(inputValue);
      }
      else if (regex.test(inputValue)) {
        setNumber3(inputValue);
        number4ref.current.focus();
      }
    }
    else if (targetName === "number4") {
      if (inputValue === "") {
        setNumber4(inputValue);
      }
      else if (regex.test(inputValue)) {
        setNumber4(inputValue);
        number5ref.current.focus();
      }
    }
    else if (targetName === "number5") {
      if (inputValue === "") {
        setNumber5(inputValue);
      }
      else if (regex.test(inputValue)) {
        setNumber5(inputValue);
        number6ref.current.focus();
      }
    }
    else if (targetName === "number6") {
      if (inputValue === "") {
        setNumber6(inputValue);
      }
      else if (regex.test(inputValue)) {
        setNumber6(inputValue);
      }
    }
  }

  const handleKeyDown = (e) => {
    const keyValue = e.key;
    const target = e.target.name;

    if (target === "number2" && number2 === "" && keyValue === "Backspace") {
      number1ref.current.focus();
    }
    if (target === "number3" && number3 === "" && keyValue === "Backspace") {
      number2ref.current.focus();
    }
    if (target === "number4" && number4 === "" && keyValue === "Backspace") {
      number3ref.current.focus();
    }
    if (target === "number5" && number5 === "" && keyValue === "Backspace") {
      number4ref.current.focus();
    }
    if (target === "number6" && number6 === "" && keyValue === "Backspace") {
      number5ref.current.focus();
    }
  }

  const handleSubmit = () => {
    const number = number1 + number2 + number3 + number4 + number5 + number6;
    console.log(number);
    let data = {
      registrationNumber: number,
      userName: form.email,
    }
    dispatch(confirmEmail(data))
      .then((params) => {
        if (params.payload.isConfirmed) {
          handleClose();
          dispatch(login(form)).then((params) => {
            try {
              const { isAuthenticated, userName } = params.payload;
              dispatch(checkSubscription({ userName }));
              if (isAuthenticated) {
                navigate("/payment/new-subscription");
              }
            } catch (error) {
              console.log("HATA Burada 2 1231656", error);
            }
          })
        }
        else {
          setError({
            response: true,
            status: "error",
            message: "confirmationCodeDoesntMatch"
          });
          setNumber1("");
          setNumber2("");
          setNumber3("");
          setNumber4("");
          setNumber5("");
          setNumber6("");
          number1ref.current.focus();
        }
      });
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError({
      response: false,
      status: "success",
      message: "",
    });
  };

  const handleResendCode = () => {
    dispatch(resendVerificationCode({
      locale: i18n.language,
      userName: form.email
    }));
  };

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData('text');
    const trimmedString = pastedText.trim();
    var regex = /^\d+$/;
    if (regex.test(trimmedString)) {
      setNumber1(trimmedString[0]);
      setNumber2(trimmedString[1]);
      setNumber3(trimmedString[2]);
      setNumber4(trimmedString[3]);
      setNumber5(trimmedString[4]);
      setNumber6(trimmedString[5]);
      number6ref.current.focus();
    }
    else {
      setError({
        response: true,
        status: "error",
        message: "wrongCodePasted",
      });
    }


  }

  return (
    <Container sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
      <Card sx={{ padding: 5, gap: 2, display: "flex", maxWidth: "800px", justifyContent: "center", alignItems: "center" }}>
        <MDBox display={"flex"} alignItems={"center"} flexDirection={"column"}>
          <MDTypography variant={"h4"}>
            {t("confirm_email")}
          </MDTypography>

          <MDTypography textAlign={"center"}>
            {t("confirm_email_alt")}
          </MDTypography>
        </MDBox>

        <Snackbar
          open={error.response}
          autoHideDuration={7000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Alert onClose={handleAlertClose} severity={error.status} sx={{ width: '100%' }}>
            {t(error.message)}
          </Alert>
        </Snackbar>

        <MDBox display={"flex"} gap={1}>
          <input
            ref={number1ref}
            style={{
              border: "1px solid rgba(52, 71, 103, 0.2)",
              color: "#344767",
              padding: "40px 10px",
              textAlign: "center",
              width: "70px",
              fontSize: "40px",
              borderRadius: "12px",
              fontWeight: "bold",
              outline: 0,

            }}
            value={number1}
            component={"input"}
            variant="outlined"
            type="text"
            name="number1"
            onChange={handleChangeNumber}
            id="number1"
            inputMode="numeric"
            pattern="[0-9]*"
            onPaste={handlePaste}
          />
          <input
            ref={number2ref}
            style={{
              border: "1px solid rgba(52, 71, 103, 0.2)",
              color: "#344767",
              padding: "40px 20px",
              textAlign: "center",
              width: "70px",
              fontSize: "40px",
              borderRadius: "12px",
              fontWeight: "bold",
              outline: 0,
            }}
            value={number2}
            component={"input"}
            variant="outlined"
            type="text"
            name="number2"
            onChange={handleChangeNumber}
            onKeyDown={handleKeyDown}
            id="number2"
            inputMode="numeric"
            pattern="[0-9]*"
            onPaste={handlePaste}
          />
          <input
            ref={number3ref}
            style={{
              border: "1px solid rgba(52, 71, 103, 0.2)",
              color: "#344767",
              padding: "40px 20px",
              textAlign: "center",
              width: "70px",
              fontSize: "40px",
              borderRadius: "12px",
              fontWeight: "bold",
              outline: 0,
            }}
            value={number3}
            component={"input"}
            variant="outlined"
            type="text"
            name="number3"
            onChange={handleChangeNumber}
            onKeyDown={handleKeyDown}
            id="number3"
            inputMode="numeric"
            pattern="[0-9]*"
            onPaste={handlePaste}
          />
          <input
            ref={number4ref}
            style={{
              border: "1px solid rgba(52, 71, 103, 0.2)",
              color: "#344767",
              padding: "40px 20px",
              textAlign: "center",
              width: "70px",
              fontSize: "40px",
              borderRadius: "12px",
              fontWeight: "bold",
              outline: 0,
            }}
            value={number4}
            component={"input"}
            variant="outlined"
            type="text"
            name="number4"
            onChange={handleChangeNumber}
            onKeyDown={handleKeyDown}
            id="number4"
            inputMode="numeric"
            pattern="[0-9]*"
            onPaste={handlePaste}
          />
          <input
            ref={number5ref}
            style={{
              border: "1px solid rgba(52, 71, 103, 0.2)",
              color: "#344767",
              padding: "40px 20px",
              textAlign: "center",
              width: "70px",
              fontSize: "40px",
              borderRadius: "12px",
              fontWeight: "bold",
              outline: 0,
            }}
            value={number5}
            component={"input"}
            variant="outlined"
            type="text"
            name="number5"
            onChange={handleChangeNumber}
            onKeyDown={handleKeyDown}
            id="number5"
            inputMode="numeric"
            pattern="[0-9]*"
            onPaste={handlePaste}
          />
          <input
            ref={number6ref}
            style={{
              border: "1px solid rgba(52, 71, 103, 0.2)",
              color: "#344767",
              padding: "40px 20px",
              textAlign: "center",
              width: "70px",
              fontSize: "40px",
              borderRadius: "12px",
              fontWeight: "bold",
              outline: 0,
            }}
            value={number6}
            component={"input"}
            variant="outlined"
            type="text"
            name="number6"
            onChange={handleChangeNumber}
            onKeyDown={handleKeyDown}
            id="number6"
            inputMode="numeric"
            pattern="[0-9]*"
            onPaste={handlePaste}
          />

        </MDBox>

        <MDButton onClick={handleSubmit} variant={"gradient"} color="info">{t("confirm")}</MDButton>
        <MDButton onClick={handleResendCode}>{t("resend_verification_code")}</MDButton>
      </Card>
    </Container>
  )
}

export default ConfirmEmail