import { usePlayer } from 'hooks/PlayerProvider'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import MDBox from './MDBox';
import zIndex from '@mui/material/styles/zIndex';
import { Card, Container } from '@mui/material';
import PricingPlans from 'pages/students/payment/newSubscription/PricingPlansInfo/PricingPlans';
import RenderPricingPlans from './PricingPlans/RenderPricingPlans';
import { useDispatch } from 'react-redux';
import { getPricingPlans } from 'store/main/pricingPlansSlice';
import StepHeader from 'pages/students/payment/newSubscription/components/StepHeader';
import MDButton from './MDButton';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function TrialBlocker() {
  const dispatch = useDispatch();
  const { songInfo } = usePlayer();
  const { isTrial } = useSelector((state) => state.payments);

  const { t } = useTranslation();


  useEffect(() => {
    dispatch(getPricingPlans());
  }, [])

  if (isTrial && !songInfo.isTrial) {
    return (
      <MDBox sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        maxHeight: "100vh",
        backgroundColor: "rgba(255,255,255,0.2)",
        backdropFilter: "blur(20px)",
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

      }}>

        <Container sx={{ transform: "scale(0.9)" }}>
          <Card sx={{ padding: 3, justifyContent: "center", alignItems: "center", transform: "scale(0.9)" }}>
            <StepHeader header={"plan_header"} alt={"plan_description_trial"} />
            <RenderPricingPlans />
            <MDButton component={Link} to={"/"} sx={{ mt: 4 }}>{t("continue_with_trial")}</MDButton>
          </Card>
        </Container>

      </MDBox>
    )
  }

  return null;
}

export default TrialBlocker