import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useForm } from "features/hooks";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addProduct,
  closeEditProductDialog,
  closeNewProductDialog,
  updateProduct,
} from "store/iyzico/iyzicoProductsSlice";

const defaultFormState = {
  referenceCode: 0,
  name: "",
  description: "",
  locale: "TR",
};

function IyzicoProductsDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const productDialog = useSelector(
    ({ iyzicoProducts }) => iyzicoProducts.productDialog
  );

  const { form, handleChange, setForm } = useForm(defaultFormState);

  const initDialog = useCallback(async () => {
    /**
     * Dialog type: 'edit'
     */
    if (productDialog.type === "edit" && productDialog.data) {
      setForm({ ...productDialog.data });
    }

    /**
     * Dialog type: 'new'
     */
    if (productDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...productDialog.data,
      });
    }
  }, [productDialog.data, productDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (productDialog.props.open) {
      initDialog();
    }
  }, [productDialog.props.open, initDialog]);

  function closeProductDialog() {
    return productDialog.type === "edit"
      ? dispatch(closeEditProductDialog())
      : dispatch(closeNewProductDialog());
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (productDialog.type === "new") {
      let data = {
        ...form,
        productReferenceCode: form.referenceCode,
      }
      dispatch(addProduct(data));
    } else {
      let data = {
        ...form,
        productReferenceCode: form.referenceCode,
      }
      dispatch(updateProduct(data));
    }
    closeProductDialog();
  }

  function canBeSubmitted() {
    return form.name.length > 0 && form.description.length > 0;
  }

  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...productDialog.props}
      onClose={closeProductDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="product-dialog-title">{t("productInfo")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="referenceCode"
          name="referenceCode"
          label={t("productReferenceCode")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.referenceCode}
          disabled
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label={t("productName")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.name}
          onChange={handleChange}
          required
          error={form.name ? false : true}
          helperText={t("enterProductName")}
        />
        <TextField
          margin="dense"
          id="description"
          name="description"
          label={t("description")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.description}
          onChange={handleChange}
          required
          error={form.description ? false : true}
          helperText={t("enterProductDescription")}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={closeProductDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default IyzicoProductsDialog;