import { Icon, Tooltip } from '@mui/material'
import React from 'react'

function NoteSheetButton() {

  // i have a pdf file that i want to download create a download function
  const download = () => {
    const link = document.createElement('a')
    link.href = 'https://lasolist.com/uploads/0153/empty.pdf'
    link.download = 'file.pdf'
    link.click()
  }
  

  return (
    <Tooltip title={"Notaları İndir"} placement='bottom'>
      <button
        onClick={download}
        className={"LoopButton"}
        // style={{ backgroundColor: loopData.isLoop ? "rgba(255, 255, 255, 0.3)" : "rgba(255, 255, 255, 0)" }}
      >
        <Icon>save_alt_outlined</Icon>
      </button>
    </Tooltip>
  )
}

export default NoteSheetButton