import { Card, Container, Slide } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { acceptPolicies } from 'store/auth/authSlice';

function Cookies() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  console.log(t("cookies_and_privacy_header", "berat123"))

  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
    dispatch(acceptPolicies());
  }

  useEffect(() => {
    const currentDateUnix = new Date().getTime().toString();

    const cookieExpireTime = localStorage.getItem("cookieExpireTime");
    console.log(cookieExpireTime, "buraya bak")

    if (parseFloat(currentDateUnix) < parseFloat(localStorage.getItem("cookieExpireTime"))) {
      setAccepted(true);
    }
    else {
      setAccepted(false);
    }
  }, [])

  return (
    <MDBox margin={2} >
      <MDBox sx={{
        width: "calc(100vw - 2rem)",
        position: "fixed",
        bottom: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "1000",
      }}>
        <Slide direction="up" in={!accepted} mountOnEnter unmountOnExit>
          <Container>
            <Card sx={{ padding: "20px" }}>
              <MDTypography mb={1} variant={"h6"}>{t("cookies_and_privacy_header")} </MDTypography>
              <MDTypography mb={2} variant={"subtitle2"}>
                <MDTypography as={Link} style={{ fontWeight: "bold" }} to={t("cookiesRoute")}><u>{t("our_cookies")}</u></MDTypography>, <MDTypography as={Link} style={{ fontWeight: "bold" }} to={t("privacyRoute")}><u></u></MDTypography>  {t("and")} <MDTypography as={Link} style={{ fontWeight: "bold" }} to={t("userAgreementRoute")}><u>{t("our_user_agreement")}</u></MDTypography> {t("cookies_and_privacy_alt_p2")}
              </MDTypography>



              <MDButton sx={{ alignSelf: "flex-end" }} onClick={handleAccept} variant={"gradient"} color={"info"}>{t("acceptPrivacyUser")}</MDButton>
            </Card>
          </Container>
        </Slide>
      </MDBox>

    </MDBox>
  )
}

export default Cookies