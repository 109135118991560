import React from 'react'

function CustomProgressBar({ onClick, value, bufferValue }) {
  return (

    <div onClick={onClick} style={{ width: "99%", position: "relative", display: "flex", height: 18, alignItems: "center", }}>
      <div style={{ width: `${value}%`, position: "absolute", marginBottom: 8, height: 8, cursor: "pointer", borderRadius: "5px", backgroundColor: "rgba(255, 255, 255, 1)", transition: "all 0.3s ease-in-out" }}></div>
      
      <div style={{ width: `${bufferValue}%`, position: "absolute", marginBottom: 8, height: 8, cursor: "pointer", borderRadius: "5px", backgroundColor: "rgba(255, 255, 255, 0.3)", transition: "all 0.5s ease-in-out" }}></div>
      
      <div style={{ width: "100%", position: "absolute", marginBottom: 8, height: 8, cursor: "pointer", borderRadius: "5px", backgroundColor: "rgba(255, 255, 255, 0.3)" }}></div>
    </div>
  )
}

export default CustomProgressBar