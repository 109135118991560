
import { Icon, Tooltip } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';

function ReturnStartButton({ handleReturned }) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("ReturnStart")} placement='bottom'>
      <button className='ReturnStart' onClick={handleReturned}>
        <Icon>skip_previous</Icon>
      </button>
    </Tooltip>

  )
}

export default ReturnStartButton