/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import MDButton from "components/MDButton";

function DefaultNavbarDropdown({
  name,
  icon,
  children,
  collapseStatus,
  light,
  href,
  route,
  collapse,
  open,
  ...rest
}) {


  const routeComponent = {
    component: Link,
    to: route,
  };

  const { t } = useTranslation();

  return (
    <>
      <MDBox
        {...rest}
        mx={0}
        pl={2}
        display="flex"
        alignItems="center"
        color={"white"}
        opacity={1}
        sx={{ cursor: "pointer", userSelect: "none", gap: "0px" }}
        {...(route && routeComponent)}


      >

        <MDButton
          color={name === "sign_up" ? "info" : "white"}
          fontSize={"16px"}
          variant={name === "sign_up" ? "gradient" : "text"}
          size="small"
          fontWeight="regular"
          textTransform="capitalize"
          sx={{ fontWeight: "100%", ml: 0, height: "100%", justifyContent: "center", alignItems: "center" }}
        >

          {icon && (
            <MDTypography
              variant="body2"
              lineHeight={1}
              color="inherit"
              sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" }, marginRight: 1 }}
            >
              {icon}
            </MDTypography>
          )}
          {t(name)}
        </MDButton>
      </MDBox>
    </>
  );
}

// Setting default values for the props of DefaultNavbarDropdown
DefaultNavbarDropdown.defaultProps = {
  icon: false,
  children: false,
  collapseStatus: false,
  light: false,
  href: "",
  route: "",
};

// Typechecking props for the DefaultNavbarDropdown
DefaultNavbarDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  href: PropTypes.string,
  route: PropTypes.string,
  collapse: PropTypes.bool.isRequired,
};

export default DefaultNavbarDropdown;
