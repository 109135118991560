import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, List, ListItem, ListItemText, Icon, Card, Grid } from '@mui/material';
import MDButton from 'components/MDButton';
import { useForm } from 'features/hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { selectLiveSupportsById } from 'store/main/liveSupportsSlice';
import { useSelector } from 'react-redux';
import MDTypography from 'components/MDTypography';
import SendMessageSection from './SendMessageSection';
import Messages from './Messages';
import io from 'socket.io-client';

const defaultFormState = {
  message: "",
};

const ChatRoom = () => {
  const { id } = useParams();
  const support = useSelector((state) => selectLiveSupportsById(state, id));

  return (
    <Card sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 4, height: "100%" }}>
      <MDTypography variant="h4">{support.supportHeader}</MDTypography>

      <Messages />

      <SendMessageSection />
    </Card>
  );
};

export default ChatRoom;