import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { closeEditInstructorApplicationDialog } from "store/main/instructorApplicationsSlice";
import { closeNewInstructorApplicationDialog } from "store/main/instructorApplicationsSlice";
import { addInstructorApplication } from "store/main/instructorApplicationsSlice";
import { updateInstructorApplication } from "store/main/instructorApplicationsSlice";

const defaultFormState = {
  applicationId: 0,
  name: "",
  surname: "",
  email: "",
  videoUrl: "",
  status: "active",
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
  { id: "accepted", name: "accepted" },
  { id: "rejected", name: "rejected" },
];

function InsApplicationDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const instructorApplicationDialog = useSelector(
    ({ instructorApplications }) => instructorApplications.instructorApplicationDialog
  );


  const { form, handleChange, setForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });
  const initDialog = useCallback(async () => {
    /**
     * Dialog type: 'edit'
     */
    if (instructorApplicationDialog.type === "edit" && instructorApplicationDialog.data) {
      setForm({ ...instructorApplicationDialog.data });

      let status = statusOptions.find(
        (f) => f.name === instructorApplicationDialog.data.status
      );
      setStatusValue(status);
    }

    /**
     * Dialog type: 'new'
     */
    if (instructorApplicationDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...instructorApplicationDialog.data,
      });
      let status = statusOptions.find(
        (f) => f.name === defaultFormState.status
      );
      setStatusValue(status);
    }
  }, [instructorApplicationDialog.data, instructorApplicationDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (instructorApplicationDialog.props.open) {
      initDialog();
    }
  }, [instructorApplicationDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return instructorApplicationDialog.type === "edit"
      ? dispatch(closeEditInstructorApplicationDialog())
      : dispatch(closeNewInstructorApplicationDialog());
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (instructorApplicationDialog.type === "new") {
      let data = {
        ...form,
        status: statusValues.id,
      };
      dispatch(addInstructorApplication(data));
    } else {
      let data = {
        ...form,
        status: statusValues.id,
      };
      dispatch(updateInstructorApplication(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    return (
      form.name.length > 0 && form.surname.length > 0 &&
      form.email.length > 0 && form.videoUrl.length > 0
    );
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...instructorApplicationDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">{t("composerInfo")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="applicationId"
          name="applicationId"
          label={t("applicationId")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.applicationId}
          disabled
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label={t("name")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.name}
          onChange={handleChange}
          required
          error={form.name ? false : true}
          helperText={t("enterApplicantName")}
          focused
        />
        <TextField
          autoFocus
          margin="dense"
          id="surname"
          name="surname"
          label={t("surname")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.surname}
          onChange={handleChange}
          required
          error={form.surname ? false : true}
          helperText={t("enterApplicantSurname")}
          focused
        />
        <TextField
          autoFocus
          margin="dense"
          id="email"
          name="email"
          label={t("email")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.email}
          onChange={handleChange}
          required
          error={form.email ? false : true}
          helperText={t("enterApplicantEmail")}
          focused
        />
        <TextField
          autoFocus
          margin="dense"
          id="videoUrl"
          name="videoUrl"
          label={t("driveUrl")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.videoUrl}
          onChange={handleChange}
          required
          error={form.videoUrl ? false : true}
          helperText={t("enterApplicantVideoUrl")}
          focused
        />
        <MDBox mt={2}>
          <Autocomplete
            value={statusValues} // Dikkat: statusValues -> statusValue olarak değiştirildi
            options={statusOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("status")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                setStatusValue({
                  id: newValue, // newValue.id -> newValue olarak değiştirildi
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                setStatusValue({
                  name: newValue.inputValue,
                });
              } else {
                setStatusValue(newValue);
              }
            }}
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InsApplicationDialog;
