import { Icon } from '@mui/material';
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setVideoData } from 'store/main/videoPlayerSlice';

function RewindButton() {

  const dispatch = useDispatch();
  const { videoData } = useSelector((state) => state.videoPlayer);

  const handleRewind = () => {
    dispatch(setVideoData({ ...videoData, isRewinded: true }));
  }

  return (
    <button onClick={handleRewind}>
      <Icon>fast_rewind</Icon>
    </button>
  )
}

export default RewindButton