import { combineReducers } from "@reduxjs/toolkit";
// import { reducer as toastrReducer } from "react-redux-toastr";
import auth from "./auth/authSlice";
//aws
import aws from "./aws/awsSlice";

import i18n from "./i18nSlice";
import iyzicoPricingPlans from "./iyzico/iyzicoPricingPlansSlice";
//iyzico
import iyzicoProducts from "./iyzico/iyzicoProductsSlice";
import iyzicoSubscriptions from "./iyzico/iyzicoSubscriptionsSlice";
import categories from "./main/categoriesSlice";
import composers from "./main/composersSlice";
import courses from "./main/coursesSlice";
import feedbacks from "./main/feedbacksSlice";
import instructorCourses from "./main/instructorCoursesSlice";
import instructorLessons from "./main/instructorLessonsSlice";
import instructors from "./main/instructorsSlice";
import instruments from "./main/instrumentsSlice";
import lessons from "./main/lessonsSlice";
import musicians from "./main/musiciansSlice";
import pricingPlans from "./main/pricingPlansSlice";
import songLists from "./main/songListsSlice";
import songs from "./main/songsSlice";
import subscriptions from "./main/subscriptionsSlice";
import userFavorites from "./main/userFavoritesSlice";
import userInfo from "./main/userInfosSlice";
import users from "./main/usersSlice";
import videoPlayer from "./main/videoPlayerSlice";
import payments from "./payment/paymentsSlice";
import instructorApplications from "./main/instructorApplicationsSlice";
import paypalSubscriptions from "./paypal/paypalSubscriptionsSlice";
import paypalProducts from "./paypal/paypalProductsSlice";
import paypalPricingPlans from "./paypal/paypalPricingPlansSlice";
import location from "./main/locationsSlice";
import analytics from "./analytics/analyticsSlice";
import videoAnalyze from "./analytics/videoAnalyzeSlice";
import couponCodes from "./main/couponCodesSlice";
import confirmDialog from "./main/confirmSlice";
import settings from "./main/settingsSlice";
import messages from "./main/messagesSlice";
import uploads from "./main/uploadsSlice";
import liveSupports from "./main/liveSupportsSlice";
import statistics from "./main/statisticsSlice";

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    i18n,
    confirmDialog,
    categories,
    instruments,
    musicians,
    composers,
    couponCodes,
    songs,
    songLists,
    pricingPlans,
    subscriptions,
    userFavorites,
    lessons,
    courses,
    videoPlayer,
    feedbacks,
    users,
    iyzicoProducts,
    iyzicoPricingPlans,
    iyzicoSubscriptions,
    userInfo,
    aws,
    payments,
    instructors,
    instructorCourses,
    instructorLessons,
    instructorApplications,
    paypalSubscriptions,
    location,
    analytics,
    paypalProducts,
    paypalPricingPlans,
    videoAnalyze,
    settings,
    messages,
    uploads,
    liveSupports,
    statistics,
    ...asyncReducers,
  });

  /*
  Reset the redux store when user logged out
   */
  if (action.type === "auth/user/userLoggedOut") {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
