/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Custom Pricing Card
import PricingCardSubscription from "./PricingCardSubscription";

// Other Components
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectPricingPlanById } from "store/main/pricingPlansSlice";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import LoadingComponent from "components/Loading";
import { Tooltip } from "@mui/material";

function Subscription() {
  const { t } = useTranslation();
  const { country_code } = useSelector((state) => state.location);
  const { subscriptionsInfo, payPlatform } = useSelector((state) => state.payments);

  const activeSubs = subscriptionsInfo.filter(f => (f.status === "ACTIVE" || f.status === "TRIAL"));
  const upgradedSubs = subscriptionsInfo.filter(f => f.status === "UPGRADED");


  const activePricingPlan = useSelector((state) => selectPricingPlanById(
    state,
    activeSubs[activeSubs.length - 1].pricingPlanId));

  const upgradedPricingPlan = useSelector((state) => {
    if (upgradedSubs.length > 0) {
      return selectPricingPlanById(
        state,
        upgradedSubs[upgradedSubs.length - 1].pricingPlanId
      );
    } else {
      return null;
    }
  });


  const [currentSubscription, setCurrentSubscription] = useState(null);

  useEffect(() => {
    const withoutWaiting = subscriptionsInfo.filter((f) => f.status !== "WAITING");
    setCurrentSubscription(withoutWaiting[withoutWaiting.length - 1]);
  }, [subscriptionsInfo])


  const renderPaymentInterval = () => {
    if (activePricingPlan.paymentInterval === "MONTHLY") {
      return t("monthlyInterval")
    }
    if (activePricingPlan.paymentInterval === "TRIAL") {
      return t("monthlyInterval")
    }
    if (activePricingPlan.paymentInterval === "COUPON") {
      return t("couponInterval")
    }
  }

  if (currentSubscription === null) {
    return <LoadingComponent />
  }

  return (
    <Card id="change-subscription">
      <MDBox display={"flex"} flexDirection={{ xs: "column", sm: "row" }} alignItems={{ xs: "center", sm: "flex-end" }}>
        <MDBox p={3} width={"100%"} lineHeight={1} >
          <MDBox width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} mb={5}>
            <MDTypography variant="h5">{t("subscription_header")}</MDTypography>

            {
              payPlatform === "iyzico" && <MDButton
                component={Link}
                to={`/payment/change-card-information/${subscriptionsInfo[subscriptionsInfo.length - 1].subscriptionReferenceCode}`}
                variant="gradient"
                color="info"
                size="small"
                sx={{ height: "100%" }}
              >
                {t("change_payment_method")}
              </MDButton>
              // : <Tooltip title={t("cannotProvideThisService")}>
              //   <MDButton

              //     variant="gradient"
              //     color="info"
              //     size="small"
              //     sx={{ height: "100%" }}
              //   >
              //     {t("change_payment_method")}
              //   </MDButton>
              // </Tooltip>
            }


          </MDBox>

          <MDBox display={"flex"} justifyContent={"center"}>
            <PricingCardSubscription
              isTrial={currentSubscription.status === "TRIAL" ? true : false}
              color={"dark"}
              badge={{ color: "light", label: activePricingPlan.pricingPlanNameTr }}
              price={{
                currency: (
                  (country_code === "DE" && "€") ||
                  (country_code === "TR" && "₺")
                ),
                value: activePricingPlan.price,
                type: renderPaymentInterval(),
                count: activePricingPlan.paymentIntervalCount,
              }}
              specifications={[
                { label: t("feature1"), includes: true },
                { label: t("feature2"), includes: true },
                { label: t("feature3"), includes: true },
                { label: t("feature4"), includes: true },
                { label: t("feature5"), includes: true },
                { label: t("feature6"), includes: true },
                { label: t("feature7"), includes: true },
                { label: t("feature8"), includes: true },
                { label: t("feature9"), includes: true },
              ]}
              pricingPlanReferenceCode={activePricingPlan.iyzicoPricingPlanReference}
              pricingPlanId={activePricingPlan.pricingPlanId}
              button={{
                color: "light",
                label: t("change_subscription"),
              }}
              shadow={true}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Subscription;
