import MDBox from 'components/MDBox'
import React from 'react'
import BlueArea from 'components/Player/BlueArea'
import DraggableImage from 'components/Player/DraggableImage'
import { selectlessonsById } from 'store/main/lessonsSlice'


function LessonNotePlayer() {
  return (
    <MDBox className={"NotePlayer"}>
      <BlueArea />
      <DraggableImage selectSongState={selectlessonsById} />
    </MDBox>
  )
}

export default LessonNotePlayer