import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";


export const getFeedbacks = createAsyncThunk(
  "feedbacks/getFeedbacks",
  async () => {
    const response = await axiosConfig.get(`/api/feedbacks`);
    // const response = await axios.get(`${proxy}/api/courses`)

    let { data } = await response.data;
    console.log(data);
    return data;
  }
);

export const getFeedbacksByQuery = createAsyncThunk(
  "feedbacks/getFeedbacksByQuery",
  async (userName, { dispatch, getState }) => {
    const response = await axiosConfig.get(`/api/feedbacks/query?userName=${userName}`);
    let { data } = await response.data;
    return data;
  }
)

export const addFeedbacks = createAsyncThunk(
  "feedbacks/addFeedbacks",
  async (feedback, { dispatch, getState }) => {
    try {
      let formData = new FormData();

      console.log(feedback);

      formData.append("userName", feedback.userName);
      formData.append("feedbackTitle", feedback.feedbackTitle);
      formData.append("feedbackDetails", feedback.feedbackDetails);
      formData.append("feedbackReply", feedback.feedbackReply);
      formData.append("feedbackType", feedback.feedbackType);
      formData.append("status", feedback.status);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/feedbacks`,
        formData,
        config
      );
      let { data } = await response.data;
      if (response.data.success === true) {

        return data;
      }
    } catch (error) {


      return null;
    }
  }
);

export const updateFeedbacks = createAsyncThunk(
  "feedbacks/updateFeedbacks",
  async (feedback, { dispatch, getState }) => {
    let formData = new FormData();

    formData.append("feedbackId", feedback.feedbackId)
    formData.append("userName", feedback.userName);
    formData.append("feedbackTitle", feedback.feedbackTitle);
    formData.append("feedbackDetails", feedback.feedbackDetails);
    formData.append("feedbackReply", feedback.feedbackReply);
    formData.append("feedbackType", feedback.feedbackType);
    formData.append("status", feedback.status);


    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/feedbacks/${feedback.feedbackId}`,
      formData,
      config
    );
    const { data } = await response.data;
    if (response.data.success === true) {

      return data;
    }
    return null;
  }
);

export const removeFeedbacks = createAsyncThunk(
  "feedbacks/removeFeedbacks",
  async (feedbackId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/feedbacks/${feedbackId}`);
    if (response.data.success === true) {

      return feedbackId;
    }
    return feedbackId;
  }
);

const feedbacksAdapter = createEntityAdapter({
  selectId: (feedback) => feedback.feedbackId,
});

export const {
  selectAll: selectfeedbacks,
  selectById: selectfeedbacksById,
} = feedbacksAdapter.getSelectors((state) => state.feedbacks);

const feedbacksSlice = createSlice({
  name: "feedbacks",
  initialState: feedbacksAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    feedbackDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setFeedbacksSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewFeedbacksDialog: (state, action) => {
      state.feedbackDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewFeedbacksDialog: (state, action) => {
      state.feedbackDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditFeedbacksDialog: (state, action) => {
      state.feedbackDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditFeedbacksDialog: (state, action) => {
      state.feedbackDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateFeedbacks.fulfilled]: feedbacksAdapter.upsertOne,
    [addFeedbacks.fulfilled]: feedbacksAdapter.addOne,
    [removeFeedbacks.fulfilled]: (state, action) =>
      feedbacksAdapter.removeOne(state, action.payload),
    [getFeedbacks.fulfilled]: feedbacksAdapter.setAll,
    [getFeedbacksByQuery.fulfilled]: feedbacksAdapter.setAll,
  },
});

export const {
  setFeedbacksSearchText,
  openNewFeedbacksDialog,
  closeNewFeedbacksDialog,
  openEditFeedbacksDialog,
  closeEditFeedbacksDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = feedbacksSlice.actions;

export default feedbacksSlice.reducer;
