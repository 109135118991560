import { Card, Grid } from '@mui/material'
import CourseCard from 'components/Cards/CourseCard'
import DefaultCard from 'components/Cards/SongCard/DefaultCard'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { imageProxy } from 'features/api/proxy'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectcourses } from 'store/main/coursesSlice'
function LatestCoursesDashboard() {

  const courses = useSelector(selectcourses);

  const [lastCourses, setLastCourses] = useState([]);

  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (courses.length >= 4) {
      const last4 = courses.slice((courses.length - 4), courses.length);
      setLastCourses(last4);
    }
    else {
      setLastCourses(courses);
    }
  }, [courses])

  return (
    <MDBox sx={{ marginBottom: 8, }}>
      <MDBox display={"flex"} marginX={2} justifyContent={"space-between"} alignItems={"center"}>
        <MDTypography fontSize={{ xs: "1.1rem", lg: "1.2rem", xl: "1.3rem" }} variant={"h4"}>
          {t("latest_courses")}
        </MDTypography>

        <MDButton component={Link} variant={"gradient"} color={"info"} to={t("coursesRoute")} size={"small"}>
          {t("view_all")}
        </MDButton>
      </MDBox>

      <Grid mt={1} container spacing={2}>
        {
          lastCourses.map((course, key) => {
            return <Grid key={key} item xs={12} md={6} xl={4}>
              <DefaultCard
                details={{
                  type: "course",
                  route: `/${t("course")}/${course.courseId}`,
                  key: key,
                  views: course.courseViewCount
                }}
                image={`${imageProxy}/${course.url}`}
                title={course.courseName}
                description={
                  (i18n.language.match("tr") && course.courseDescriptionTr) ||
                  (i18n.language.match("en") && course.courseDescriptionEn) ||
                  (i18n.language.match("de") && course.courseDescriptionDe) ||
                  (i18n.language.match("ar") && course.courseDescriptionAr)
                }
                duration={course.courseLessonData.length + t("lesson")}
              />

            </Grid>
          })
        }
      </Grid>
    </MDBox>
  )
}

export default LatestCoursesDashboard