import AdminLayout from 'pages/main/layout/AdminLayout'
import React, { useEffect, useState } from 'react'
import IyzicoProductsTable from './IyzicoProductsTable'
import { useDispatch } from 'react-redux';
import { getProducts } from 'store/iyzico/iyzicoProductsSlice';
import LoadingComponent from 'components/Loading';
import IyzicoProductsDialog from './IyzicoProductsDialog';

function IyzicoProductsDashboard() {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(getProducts()).then(() => setDataLoading(false));
  }, [dispatch]);
  
  if (dataLoading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <IyzicoProductsTable />
      <IyzicoProductsDialog />
    </AdminLayout>
  )
}

export default IyzicoProductsDashboard