import React from "react";
import Hero from "./components/Hero";
import FeatureMain from "./components/features/FeatureMain";
import MainFooter from "../main/footer/MainFooter";
import MDBox from "components/MDBox";
import FaqSection from "./components/FaqSection";
import Introduction from "./components/Introduction";
import Pricing from "./components/Pricing";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { getPricingPlans } from "store/main/pricingPlansSlice";
import LoadingComponent from "components/Loading";
import Introduction2 from "./components/Introduction2";
import Cookies from "./components/Cookies";


function LandingPage() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPricingPlans()).then(() => setLoading(false));
  }, [dispatch]);

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <MDBox>
      <Hero />
      <Introduction />
      <Introduction2 />
      <FeatureMain />
      <Pricing />
      <FaqSection />
      <MainFooter />
      <Cookies />
    </MDBox>
  );
}

export default LandingPage;
