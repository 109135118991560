import { Grid, Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import React from 'react'
import MDBox from 'components/MDBox';
import styled from '@emotion/styled';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import FeedbackBox from './FeedbackBox';
import StatusBox from './StatusBox';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  borderRadius: "10px",
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon color="info" sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: "10px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function Feedback({ feedbackTitle, feedbackDetail, feedbackStatus, feedbackType, feedbackReply, feedbackDate }) {
  const [expanded, setExpanded] = useState(false);

  const { t } = useTranslation();
  const handleChange = () => {
    setExpanded(expanded === true ? false : true);
  };
  return (
    <MDBox mt={1} mx={3}>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Grid container spacing={2} mr={2}>
            <Grid item xs={12} sm={6} md={8} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
              <Typography variant={"h5"}>
                {feedbackTitle}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <FeedbackBox value={feedbackType} />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <StatusBox value={feedbackStatus} />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='subtitle2' fontWeight={"600"}>
            {t("createdDate")} : {dayjs(feedbackDate).format("DD/MM/YYYY")}
          </Typography>

          <Typography>
            {feedbackDetail}
          </Typography>

          {
            feedbackReply !== "" && <MDBox mt={2} color={"black"} padding={2} borderRadius={"xl"} sx={{
              backgroundColor: 'rgba(0, 0, 0, .05)',
            }}>
              <Typography variant='h6'>
                {t("feedbackReply")}:
              </Typography>
              <Typography>
                {feedbackReply}
              </Typography>
            </MDBox>
          }

        </AccordionDetails>
      </Accordion>
    </MDBox>
  )
}

export default Feedback