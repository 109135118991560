const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  videoData: {
    isLoaded: false,
    buffer: 0,
    isLoading: false,
    isPlaying: false,
    videoIndicator: false,
    isForwarded: false,
    isRewinded: false,
    isReturned: false,
    isColored: false,
    progressClick: false,
    currentTime: 0,
    duration: 0,
    volume: 1,
    speed: 1,
    isLoop: false,
    loopStart: 0,
    loopEnd: 0,
    loopStartChanged: false,
    quality: localStorage.getItem("quality") ? localStorage.getItem("quality") : "2K",
    qualityChanged: false,
  },
  draggableImageData: {
    isLoaded: false,
    isDragEnd: false,
    leftMinPosition: 0,
    leftPosition: 0,
    leftMaxPosition: 0,
    transition: "all 0.35s linear",
    startPos: 0,
    endPos: 0,
    noteHeight: 150,
  },
  controlsData: {
    tutorialCanClose: false,
    showMenu: false,
    showVolume: false,
    showSpeed: false,
    showQuality: false,
    volumeDelay: "0s",
    speedDelay: "0s",
    transitionDelay: "5s",
    opacity: 1,
  },
  loopData: {
    isLoop: false,
    loopStart: 0,
    loopEnd: 0,
    loopWidth: 0,
    loopLeftPosition: 0,
    loopStartChanged: false,
    loopChanged: 0,
  },
  tutorial: false,
};

const videoPlayerSlice = createSlice({
  name: "videoPlayer",
  initialState,
  reducers: {
    setVideoData: (state, action) => {
      state.videoData = { ...state.videoData, ...action.payload };
    },
    setDraggableImageData: (state, action) => {
      state.draggableImageData = { ...state.draggableImageData, ...action.payload };
    },
    setControlsData: (state, action) => {
      state.controlsData = { ...state.controlsData, ...action.payload };
    },
    setLoopData: (state, action) => {
      state.loopData = { ...state.loopData, ...action.payload };
    },
    startTutorial: (state, action) => {
      state.tutorial = true
    },
    endTutorial: (state, action) => {
      state.tutorial = false;
    },
    resetToInitialState: (state) => {
      // Bu reducer, tüm verileri başlangıç (initialState) değerlerine geri döndürecektir.
      return initialState;
    },
  },
});

export const {
  setVideoData,
  setDraggableImageData,
  setControlsData,
  setLoopData,
  resetToInitialState,
  startTutorial,
  endTutorial
} = videoPlayerSlice.actions;

export default videoPlayerSlice.reducer;