import React from 'react'
import MDBox from 'components/MDBox'
import { useRef } from 'react'
import { useSelector } from 'react-redux';
import { selectsongListsById } from 'store/main/songListsSlice';
import { useParams } from 'react-router-dom';
import ReactHlsPlayer from 'react-hls-player';
import VideoControls from 'components/Controls';
import SettingsPopover from 'components/Controls/Menu';
import VideoHeader from 'components/Player/VideoHeader';
import VideoIndicator from 'components/Player/VideoIndicator';
import useShortcuts from 'hooks/useShortcuts';
import useResizerWithNote from 'hooks/useResizerWithNote';
import { usePlayer } from 'hooks/PlayerProvider';

function LaSolistVideoPlayer({ fullScreenHandler }) {
  const { id } = useParams();
  const { songInfo } = useSelector((state) => selectsongListsById(state, id));
  // const videoRef = useRef();

  const {
    videoRef,
    onError,
    videoSource,
    handlePlay,
    handlePause,
    handleEnded,
    onLoad,
    onRightClick,
    onTimeUpdate,
    handleBuffer,
  } = usePlayer(selectsongListsById);

  useShortcuts(videoRef, selectsongListsById);

  const {
    videoData,
  } = useSelector((state) => state.videoPlayer);


  const { videoHeight } = useResizerWithNote(videoRef);

  return (
    <MDBox bg={"red"} maxWidth={"100vw"} className={"GridContainer"}>
      <VideoHeader name={songInfo.name} />
      <ReactHlsPlayer
        className='Video'
        playerRef={videoRef}
        onClick={videoData.isPlaying ? handlePause : handlePlay}
        onLoadedData={onLoad}
        onTimeUpdate={onTimeUpdate}
        onProgress={handleBuffer}
        disablePictureInPicture={true}
        src={videoSource}
        autoPlay={false}
        onError={onError}
        playsInline
        onEnded={handleEnded}
        height={videoHeight}
        width={"auto"}
        onContextMenu={onRightClick}
        preload='auto'
      />
      <SettingsPopover videoRef={videoRef} selectSongState={selectsongListsById} />
      <VideoIndicator selectSongState={selectsongListsById} />
    </MDBox>
  )
}

export default LaSolistVideoPlayer