import { Card, Grid } from '@mui/material'
import InstructorCoursesCard from 'components/Cards/InstructorCoursesCard'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { imageProxy } from 'features/api/proxy'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectinstructorCourses } from 'store/main/instructorCoursesSlice'

function LatestInsCoursesDashboard() {

  const courses = useSelector(selectinstructorCourses);
  const [lastCourses, setLastCourses] = useState([]);

  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (courses.length >= 4) {
      const last4 = courses.slice((courses.length - 4), courses.length);
      setLastCourses(last4);
    }
    else {
      setLastCourses(courses);
    }
  }, [courses])

  if (courses.length === 0) {
    return null;
  }

  return (
    <Card sx={{ padding: 3, marginBottom: 2 }}>
      <MDBox display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <MDTypography fontSize={{ xs: "1.1rem", lg: "1.2rem", xl: "1.3rem" }} variant={"h4"}>
          {t("latest_instructor_courses")}
        </MDTypography>

        <MDButton component={Link} variant={"gradient"} color={"info"} to={t("educationsRoute")} size={"small"}>
          {t("view_all")}
        </MDButton>
      </MDBox>

      <Grid container spacing={2}>
        {
          lastCourses.map((course, key) => {
            console.log(course);
            return <Grid mt={6} key={key} item xs={12} sm={6} md={4} lg={3}>
              <InstructorCoursesCard
                image={`${imageProxy}/${course.url}`}
                title={course.courseName}
                description={
                  (i18n.language.match("tr") && course.courseDescriptionTr) ||
                  (i18n.language.match("en") && course.courseDescriptionEn) ||
                  (i18n.language.match("de") && course.courseDescriptionDe) ||
                  (i18n.language.match("ar") && course.courseDescriptionAr)
                }
                duration={course.instructorCourseLessons.length + " Ders"}
                action={{
                  type: "internal",
                  route: `/egitimler/${course.courseId}`,
                  color: "info",
                  label: "Go Somewhere"
                }}
                instructor={{
                  name: course.courseinstructorInfo.instructorName,
                  image: `${imageProxy}/${course.courseinstructorInfo.url}`,
                  id: course.courseinstructorInfo.instructorId,
                }}
              />

            </Grid>
          })
        }
      </Grid>
    </Card>
  )
}

export default LatestInsCoursesDashboard