/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PastSubscription from "./PastSubscription";
import { selectPricingPlans } from "store/main/pricingPlansSlice";
import { useState } from "react";
import { useEffect } from "react";

function PastSubscriptions() {
  const { t, i18n } = useTranslation();

  const { subscriptionsInfo } = useSelector((state) => state.payments);
  const pricingPlans = useSelector(selectPricingPlans);

  const [sortedSubscription, setSortedSubscription] = useState(null);

  useEffect(() => {
    const sorted = [...subscriptionsInfo].sort((a, b) => b.subscriptionId - a.subscriptionId);
    const withoutWaiting = sorted.filter((f) => f.status !== "WAITING");
    setSortedSubscription(withoutWaiting);
  }, [subscriptionsInfo]);




  // console.log(pricingPlans);
  return (
    <Card id={"past-subscriptions"} sx={{ height: "100%" }}>
      <MDBox pt={3} px={3} mb={1} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h5">
          {t("past_subscriptions_header")}
        </MDTypography>
      </MDBox>
      <MDBox >
        <MDBox component="ul" display="flex" flexDirection="column" px={3} pb={3}>
          {
            sortedSubscription && sortedSubscription.map((item, key) => {

              const pricingPlan = pricingPlans.find((f) => f.pricingPlanId === item.pricingPlanId);
              return <PastSubscription
                key={key}
                subscriptionId={item.subscriptionReferenceCode}
                name={
                  (i18n.language.match("tr") && pricingPlan.pricingPlanNameTr) ||
                  (i18n.language.match("en") && pricingPlan.pricingPlanNameEn) ||
                  (i18n.language.match("de") && pricingPlan.pricingPlanNameDe) ||
                  (i18n.language.match("ar") && pricingPlan.pricingPlanNameAr)
                }
                payMethod={item.payMethod}
                discount={pricingPlan.discount}
                currencyISO={pricingPlan.currencyISO}
                startDate={item.startDate}
                endDate={item.endDate}
                price={pricingPlan.price}
                status={item.status
                } />
            })
          }
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default PastSubscriptions;
