const locale = {
  ID: "ID",
  sign_in_header: "Anmelden",
  sign_in_message: "Melden Sie sich an, indem Sie Ihre E-Mail und Ihr Passwort eingeben",
  email: "E-Mail",
  password: "Kennwort",
  remember_me: "An mich erinnern",
  dont_have_account: "Sie haben kein Konto?",
  sign_up_header: "Anmelden",
  sign_up_message: "Registrieren Sie sich, indem Sie Ihre Daten eingeben",
  name: "Ihr Name",
  i_agree_terms_and_conditions: "Ich akzeptiere die AGB",
  already_have_account: "Haben Sie bereits ein Konto?",
  SEARCH: "Suchen",
  cancel: "Abbrechen",
  save: "speichern",
  senden: "Senden",
  selectImage: "Bild auswählen",
  selectVideo: "Video auswählen",
  change: "Ändern",
  remove: "Sil",
  home: "Zuhause",
  sign_in: "Einloggen",
  sign_up: "Anmelden",
  app_registration: "Bewerben",
  confirm: "Bestätigen",
  resend_verification_code: "Verifizierungscode erneut senden",
  confirm_email: "E-Mail Adresse bestätigen",
  confirm_email_alt: "Geben Sie den an Ihre E-Mail gesendeten Code ein, um Ihre E-Mail-Adresse zu bestätigen",

  beginner: "Anfänger",
  advanced: "Fortgeschritten",

  forgot_password: "Haben Sie Ihr Passwort vergessen?",
  forgot_password_header: "Passwort vergessen",
  passwort_vergessen_nachricht: "Sie können Ihr Passwort zurücksetzen, indem Sie Ihre E-Mail-Adresse eingeben.",
  reset_password_header: "Passwort zurücksetzen",
  reset_password_message: "Bitte geben Sie Ihr neues Passwort ein.",
  reset_password: "Passwort zurücksetzen",
  wrong_password: "Ihr Passwort ist falsch. Bitte versuchen Sie es erneut",
  forgotPasswordName: "Passwort vergessen",
  forgotPasswordRoute: "/forgot-password",
  forgotPasswordKey: "Passwort vergessen",
  forgot_password_success: "Ihr Link zum Zurücksetzen des Passworts wurde erfolgreich an Ihre E-Mail gesendet!",
  forgot_password_error: "Es wurde kein zu dieser E-Mail passender Benutzer gefunden!",
  resetPasswortName: "Passwort zurücksetzen",
  resetPasswordRoute: "/reset-password/:token",
  resetPasswortSchlüssel: "Passwort zurücksetzen",
  reset_password_success: "Ihr Passwort wurde erfolgreich zurückgesetzt!",
  reset_password_error: "Es ist ein Fehler beim Zurücksetzen Ihres Passworts aufgetreten. Bitte versuchen Sie es erneut!",

  instructor_application_header: "Anmelden als Musiklehrer. Schicken Sie uns bitte Ihre Anfrage.",
  instructor_application_alt: "Bitte füllen Sie das folgende Formular aus, um Schulungen auf unserer Plattform anzubieten. Sie müssen den Link zu Ihren Demo-Videos (Google Drive, DropBox, etc.) in das Feld `Video Link` eintragen.",
  driveUrl: "Video Link [Google Drive, DropBox etc.]",
  application_created: "Ihre Bewerbung als Dozent wurde erfolgreich erstellt. Wir werden uns nach der Auswertung Ihrer Bewerbung bei Ihnen melden.",

  not_found: "Das Konto für den Benutzernamen wurde nicht gefunden. Bitte versuchen Sie es erneut.",
  account_waitingkey: "Dieses Konto befindet sich in der Genehmigungsphase. Sie erhalten eine E-Mail, wenn Ihr Konto genehmigt ist. Sie können es später erneut versuchen.",
  account_rejected: "Leider wurde Ihr Konto nicht genehmigt. Ihr Antrag wird später erneut geprüft.",

  message_sent_successfully: "Nachricht erfolgreich gesendet",
  message_couldnt_send: "Nachricht konnte nicht gesendet werden",

  signUpRoute: "/sign-up/:token",

  dashboardName: "Dashborad",
  dashboardRoute: "dashboard/analytics",
  dashboardKey: "Dashboard",

  studentDashboardName: "Startseite",
  studentDashboardRoute: "/home",
  studentDashboardKey: "Zuhause",

  instructorCollapse: "Lehrer",
  songsCollapse: "Lied",
  courseCollapse: "Kurse",
  userCollapse: "Benutzer",
  iyzicoCollapse: "Iyzico",
  paypalCollapse: "PayPal",
  subscriptionsCollapse: "Mitgliedschaft",

  instructorsName: "Lehrkraft",
  instructorsRoute: "/Lehrer",
  instructorsKey: "Lehrkraft",

  instructorCoursesName: "Kurse",
  lehrerKurseRoute: "/lehrer/kurse",
  instructorCoursesKey: "Kurse",

  instructorLessonsName: "Unterrichtsstunden",
  instructorLessonsRoute: "/lehrer/kurse/unterricht",
  instructorLessonsKey: "Lektionen",

  instructorApplicationsName: "Anwendungen",
  instructorApplicationsRoute: "/teacher/applications",
  instructorApplicationsKey: "Anwendungen",

  applicationName: "Anwendungen",
  applicationRoute: "/applications",
  applicationKey: "Anwendungen",

  instrumentsName: "Instrumente",
  instrumentsRoute: "/instrumente",
  instrumentsKey: "Instrumente",

  categoriesName: "Kategorien",
  categoriesRoute: "/categories",
  categoriesKey: "Kategorien",

  composersName: "Komponisten",
  composersRoute: "/composers",
  composersKey: "Komponisten",

  musiciansName: "Musiker",
  musiciansRoute: "/musiker",
  musiciansKey: "Musiker",

  lessonsName: "Unterrichtsstunden",
  lessonsRoute: "/unterricht",
  lessonsKey: "Lektionen",

  songsName: "Lieder",
  songsRoute: "/songs",
  songsKey: "Lieder",

  songsTrialName: "Lieder",
  songsTrialRoute: "/versuch/lieder",
  songsTrialKey: "Lieder",

  songListName: "Lied-Detail",
  songListRoute: "/lied-details",
  songListKey: "Lied Detail",

  usersName: "Benutzer",
  usersRoute: "/benutzer",
  usersKey: "Benutzer",

  feedbacksName: "Rückmeldungen",
  feedbacksRoute: "/ruckmeldungen",
  feedbacksKey: "Rückmeldung",

  pricingPlansName: "Zahlungspläne",
  pricingPlansRoute: "/payment-plans",
  pricingPlansKey: "Zahlungspläne",

  subscriptionsName: "Abonnenten",
  subscriptionsRoute: "/abonnenten",
  subscriptionsKey: "Abonnenten",

  favoritesName: "Favoriten",
  favoritesRoute: "/favoriten",
  favoritesKey: "Favoriten",
  favoritesNotFound: "Es wurde kein Lied zu Ihren Favoriten hinzugefügt.",
  favoritesFilterNotFound: "Es wurden keine Lieder gefunden, die Ihrem Filter entsprechen.",

  profileName: "Profil",
  profileRoute: "/profil",
  profileKey: "Profil",

  //Masterkurse
  coursesName: "Kurse",
  coursesRoute: "/kurse",
  coursesKey: "Kurse",
  course: "kurse",

  //Detailkurse
  subCourseName: "Kurse",
  subCourseRoute: "/kurse/:courseId",
  subCourseKey: "Kurse",

  //Kursvideo
  studyName: "Studie",
  studyRoute: "/kurse/:courseId/:id",
  studyKey: "Studie",

  //Kursvideo für den Administrator
  lessonVideoName: "Lektionsvideo",
  lessonVideoRoute: "/lektion/video/:id",
  lessonVideoKey: "Lektionsvideo",

  //Lehrerkurse Master
  educationName: "Ausbildungen",
  educationsRoute: "/Bildungen",
  educationsKey: "Ausbildungen",

  //Detail Ausbilderkurse
  lehrerKursName: "Ausbilderkurse",
  instructorCourseRoute: "/ausbilderkurse/:courseId",
  instructorCourseKey: "Ausbilder-Kurse",
  education: "Ausbildung",
  instructor: "lehrer",

  //Lehrer-Lektion-Player
  lehrerLessonName: "Ausbilder Lektion Spieler",
  instructorLessonRoute: "/ausbilder/:courseId/:lessonId",
  instructorLessonKey: "Ausbilder Lektion Player",

  //Instructor Detail Dashboard
  instructorDashboardName: "Instructor Dashboard",
  instructorDashboardRoute: "/instructor/:id",
  instructorDashboardKey: "Dashboard für Ausbilder",

  videoName: "Video",
  videoRoute: "/video/:id",
  videoKey: "Video",

  iyzicoProductsDahsboard: "Produkte",
  iyzicoProductsKey: "Produkte",
  iyzicoProdukteRoute: "/iyzico/products",

  iyzicoPreisePlanName: "Zahlungspläne",
  iyzicoPreisPlanSchlüssel: "odeme-planlari",
  iyzicoPreisgestaltungPlanRoute: "/iyzico/preisplanlari",

  iyzicoAbonnementName: "Abonnenten",
  iyzicoAbonnementSchlüssel: "Abonnenten",
  iyzicoSubscriptionRoute: "/iyzico/abonnenten",

  paypalProductsName: "Produkte",
  paypalProductsKey: "paypal-products",
  paypalProductsRoute: "/paypal/products",

  paypalPreisPlanName: "Preisgestaltungspläne",
  paypalPreisPlanSchlüssel: "paypal-preisgestaltung-pläne",
  paypalPreisgestaltungsPlanRoute: "/paypal/preispläne",

  paypalAbonnementName: "Abonnenten",
  paypalSubscriptionKey: "paypal-abonnenten",
  paypalSubscriptionRoute: "/paypal/subscribers",

  newSubscriptionName: "Neues Abonnement",
  newSubscriptionRoute: "/payment/neues-abonnement",
  newSubscriptionKey: "Neues Abonnement",

  upgradePlanName: "Upgrade-Plan",
  upgradePlanRoute: "/payment/upgrade-plan",
  upgradePlanSchlüssel: "Upgrade-Plan",

  changeCardName: "Karteninformationen ändern",
  changeCardRoute: "/payment/change-card-information/:subscriptionReferenceCode",
  changeCardKey: "Karteninformationen ändern",

  cookiesName: "Cookie",
  cookiesRoute: "/cookie",
  cookiesKey: "Cookie",

  privacyName: "Datenschutz",
  privacyRoute: "/datenschutz",
  privacyKey: "Datenschutz",

  userAgreementName: "Lizenz",
  userAgreementRoute: "/lizenz",
  userAgreementKey: "Lizenz",

  deliveryAndRefundName: "Liefer- und Erstattungsbedingungen",
  deliveryAndRefundRoute: "/delivery-and-refund-terms",
  deliveryAndRefundKey: "Liefer- und Erstattungsbedingungen",

  distanceSellingName: "Fernabsatzvereinbarung",
  distanceSellingRoute: "/distance-selling-agreement",
  distanceSellingKey: "Fernabsatzvertrag",

  aboutUsName: "Über uns",
  aboutUsRoute: "/uber-us",
  aboutUsKey: "Über uns",

  contactsName: "Kontakt",
  contactsRoute: "/kontakt",
  contactsKey: "Kontakt",

  faqsName: "F.A.Q.",
  faqsRoute: "/faq",
  faqsKey: "F.A.Q.",

  pricingName: "Preisgestaltung",
  pricingRoute: "/preisgestaltung",
  pricingKey: "Preisgestaltung",

  analyticsCollapseName: "Analytics",

  videoAnalyzeName: "Video-Analyse",
  videoAnalyzeRoute: "/analyse/video-analyse",
  videoAnalyzeSchlüssel: "Video-Analyse",

  couponCodesName: "Gutschein-Codes",
  couponCodesRoute: "/gutschein-Codes",
  couponCodesKey: "Gutschein-Codes",

  instructorSearch: "Ausbilder",
  courseDoesntMatch: "Es wurden keine Kurse gefunden, die Ihrem Filter entsprechen.",

  instrumentInfo: "Informationen zum Instrument",
  instruments: "Instrumente",
  instrumentList: "Liste der Instrumente",
  instrumentsTable: "Instrumententabelle",
  AddNewInstrument: "Neues Instrument hinzufügen",
  instrumentId: "Instrumenten-ID",
  instrumentNameTr: "Instrumentenname [TR]",
  instrumentDescriptionTr: "Instrumentenbeschreibung [TR]",
  instrumentNameEn: "Instrumentenname [DE]",
  instrumentDescriptionEn: "Instrumentenbeschreibung [DE]",
  instrumentNameDe: "Instrumentenname [DE]",
  instrumentDescriptionDe: "Instrumentenbeschreibung [DE]",
  instrumentNameAr: "Instrumentenname [AR]",
  instrumentDescriptionAr: "Instrumentenbeschreibung [GB]",
  instrumentType: "Instrumentenart",
  createdDate: "Erstellungsdatum",
  status: "Status",
  enterInstrumentNameTr: "Instrumentenname eingeben [TR]",
  enterInstrumentDescriptionTr: "Instrumentenbeschreibung eingeben [TR]",
  enterInstrumentNameEn: "Instrumentenname eingeben [DE]",
  enterInstrumentDescriptionEn: "Instrumentenbeschreibung eingeben [DE]",
  enterInstrumentNameDe: "Instrumentenname eingeben [DE]",
  enterInstrumentDescriptionDe: "Instrumentenbeschreibung eingeben [DE]",
  enterInstrumentNameAr: "Instrumentenname eingeben [AR]",
  enterInstrumentDescriptionAr: "Instrumentenbeschreibung eingeben [GB]",

  categoryInfo: "Informationen zur Kategorie",
  categories: "Kategorien",
  categoryList: "Kategorie-Liste",
  categoriesTable: "Kategorie-Tabelle",
  AddNewCategory: "Neue Kategorie hinzufügen",
  categoryId: "Kategorie-ID",
  categoryName: "Name der Kategorie",
  categoryDescription: "Beschreibung der Kategorie",
  categoryNameTr: "Name der Kategorie [TR]",
  categoryDescriptionTr: "Kategoriebeschreibung [TR]",
  categoryNameEn: "Name der Kategorie [DE]",
  categoryDescriptionEn: "Kategoriebeschreibung [En]",
  categoryNameDe: "Kategorie-Name [DE]",
  categoryDescriptionDe: "Kategoriebeschreibung [DE]",
  categoryNameAr: "Kategorie-Name [AR]",
  categoryDescriptionAr: "Kategoriebeschreibung [AR]",
  categoryType: "Kategorie-Typ",
  enterCategoryNameTr: "Kategorie-Name eingeben [TR]",
  enterCategoryDescriptionTr: "Beschreibung der Kategorie eingeben [TR]",
  enterCategoryNameEn: "Name der Kategorie eingeben [DE]",
  enterCategoryDescriptionEn: "Beschreibung der Kategorie eingeben [EN]",
  enterCategoryNameDe: "Kategorie-Name eingeben [DE]",
  enterCategoryDescriptionDe: "Beschreibung der Kategorie eingeben [DE]",
  enterCategoryNameAr: "Kategorie-Name eingeben [AR]",
  enterCategoryDescriptionAr: "Beschreibung der Kategorie eingeben [GB]",
  isIncludeTrial: "In der Probezeit enthalten?",

  composerInfo: "Informationen zum Komponisten",
  composers: "Komponisten",
  composerList: "Komponistenliste",
  composersTable: "Tabelle der Komponisten",
  AddNewComposer: "Neuen Komponisten hinzufügen",
  composerId: "Komponisten-ID",
  composerName: "Name des Komponisten",
  enterComposerName: "Name des Komponisten eingeben",

  musiciansInfo: "Informationen zum Musiker",
  musicians: "Musiker",
  musicianList: "Musiker-Liste",
  musiciansTable: "Tabelle der Musiker",
  AddNewMusician: "Neuen Musiker hinzufügen",
  musicianId: "Musiker-ID",
  userName: "Benutzer-Name",
  musicianName: "Name des Musikers",
  musicianDescription: "Beschreibung des Musikers",
  enterMusicianName: "Name des Musikers eingeben",
  musicianDescriptionTr: "Beschreibung des Musikers [TR]",
  enterMusicianDescriptionTr: "Beschreibung des Musikers eingeben [TR]",
  musicianDescriptionEn: "Beschreibung des Musikers [EN]",
  enterMusicianDescriptionEn: "Beschreibung des Musikers eingeben [EN]",
  musicianDescriptionDe: "Beschreibung des Musikers [DE]",
  enterMusicianDescriptionDe: "Beschreibung des Musikers eingeben [DE]",
  musicianDescriptionAr: "Beschreibung des Musikers [AR]",
  enterMusicianDescriptionAr: "Beschreibung des Musikers eingeben [AR]",
  confirmPassword: "Passwort bestätigen",
  enterUserName: "Benutzernamen eingeben",
  enterPassword: "Passwort eingeben",
  enterConfirmPassword: "Kennwort zur Bestätigung eingeben",

  lessonInfo: "Informationen zur Lektion",
  noteDetails: "Noten-Details",
  lessons: "Lektionen",
  lessonsTable: "Tabelle der Lektionen",
  lessonList: "Lektionsliste",
  AddNewLesson: "Neue Lektion hinzufügen",
  lessonId: "Lektions-ID",
  lessonName: "Name der Lektion",
  lessonDescription: "Lektionsbeschreibung",
  lessonNameTr: "Lektionsname [TR]",
  enterLessonNameTr: "Name der Lektion eingeben [TR]",
  lessonDescriptionTr: "Lektionsbeschreibung [TR]",
  enterLessonDescriptionTr: "Lektionsbeschreibung eingeben [TR]",
  lessonNameEn: "Lektionsname [DE]",
  enterLessonNameEn: "Lektionsname eingeben [EN]",
  lessonDescriptionEn: "Lektionsbeschreibung [EN]",
  enterLessonDescriptionEn: "Lektionsbeschreibung eingeben [EN]",
  LektionsnameDe: "Lektionsname [DE]",
  enterLessonNameDe: "Kursnamen eingeben [DE]",
  lessonDescriptionDe: "Lektionsbeschreibung [DE]",
  enterLessonDescriptionDe: "Kursbeschreibung eingeben [DE]",
  lessonNameAr: "Lektionsname [AR]",
  enterLessonNameAr: "Lektionsname eingeben [AR]",
  lessonDescriptionAr: "Lektionsbeschreibung [GB]",
  enterLessonDescriptionAr: "Lektionsbeschreibung eingeben [GB]",
  type: "Tip",
  courseId: "Kurs-ID",
  enterCourseId: "Kurs-ID eingeben",
  enterLevel: "Stufe eingeben",
  isHaveNote: "Gibt es eine Note?",
  isHaveNoteHelperText: "Gibt es für diese Lektion Informationen zur Note?",

  courses: "Kurse",
  courseList: "Kurstabelle",
  coursesTable: "Kurstabelle",
  AddNewCourse: "Neuen Kurs hinzufügen",
  courseName: "Kursname",
  enterCourseName: "Kursnamen eingeben",
  courseDescriptionTr: "Kursbeschreibung [TR]",
  enterCourseDescriptionTr: "Kursbeschreibung eingeben [TR]",
  courseDescriptionEn: "Kursbeschreibung [EN]",
  enterCourseDescriptionEn: "Kursbeschreibung eingeben [EN]",
  courseDescriptionDe: "Kursbeschreibung [DE]",
  enterCourseDescriptionDe: "Kursbeschreibung eingeben [DE]",
  courseDescriptionAr: "Kursbeschreibung [AR]",
  enterCourseDescriptionAr: "Kursbeschreibung eingeben [AR]",
  selectLessons: "Lektionen auswählen",

  songInfo: "Lied-Informationen",
  songs: "Lieder",
  songTable: "Lieder-Tabelle",
  AddNewSong: "Neuen Song hinzufügen",
  songId: "Lied-ID",
  songName: "Liedname",
  songDescription: "Liedbeschreibung",
  songDescriptionTr: "Liedbeschreibung [TR]",
  songDescriptionEn: "Liedbeschreibung [EN]",
  songDescriptionDe: "Liedbeschreibung [DE]",
  songDescriptionAr: "Songbeschreibung [AR]",
  enterSongName: "Song-Namen eingeben",
  enterSongDescriptionTr: "Liedbeschreibung eingeben [TR]",
  enterSongDescriptionEn: "Liedbeschreibung eingeben [EN]",
  enterSongDescriptionDe: "Songbeschreibung eingeben [DE]",
  enterSongDescriptionAr: "Song-Beschreibung eingeben [AR]",
  selectComposer: "Komponist auswählen",
  selectCategories: "Kategorien auswählen",
  songDoesntMatch: "Es wurde kein Lied gefunden, das Ihrem Filter entspricht.",

  songListInfo: "Lied-Detailinformationen",
  songList: "Lied-Detail",
  songListId: "Lied Detail ID",
  songListsTable: "Song-Detail-Tabelle",
  AddNewSongList: "Neues Lieddetail hinzufügen",
  listId: "Detail ID",
  description: "Beschreibung",
  enterDescription: "Detailbeschreibung eingeben",
  level: "Ebene",
  instrumentName: "Instrumentenname",
  instrumentDescription: "Instrumentenbeschreibung",
  selectSong: "Lied auswählen",
  selectMusician: "Musiker auswählen",
  videoStart: "Video Start [ms]",
  enterVideoStart: "Videostart eingeben [ms]",
  videoMain: "Video allgemein [ms]",
  enterVideoMain: "Video allgemein eingeben ms",
  videoEnd: "Videoende [ms]",
  enterVideoEnd: "Videoende eingeben ms",
  loopNoteStart: "Schleifenstart [px]",
  enterLoopNoteStart: "Schleifenstart Pixel eingeben",
  loopNoteEnd: "Schleifenende [px]",
  enterLoopNoteEnd: "Schleifenende-Pixel eingeben",
  noteTolerance: "Notentoleranz [px]",
  enterNoteTolerance: "Notentoleranz eingeben",

  noteStart: "Notenanfang [px]",
  enterNoteStart: "Startpixel der Note eingeben",
  noteMain: "Nota General [px]",
  enterNoteMain: "Allgemeines Pixel für die Note eingeben",
  noteEnd: "Notenende [px]",
  enterNoteEnd: "Notenende-Pixel eingeben",
  noteHeight: "Notenhöhe [px]",
  enterNoteHeight: "Notenhöhe eingeben",
  noteWidth: "Notenbreite [px]",
  enterNoteWidth: "Notenbreite eingeben",
  bpm: "Metronom [bpm]",
  enterBpm: "Song-Metronom eingeben [bpm]",
  videoUrl: "Video URL [vps]",
  enterVideoUrl: "Lied-URL eingeben",
  noteUrl: "Noten-URL [vps]",
  enterNoteUrl: "Noten-URL eingeben",
  minute: " min.",
  folderNumber: "Ordnernummer",
  enterFolderNumber: "Ordnernummer eingeben",
  measure: "Song-Takt",
  enterMeasure: "Song-Takt eingeben",
  measureWidth: "Taktlänge [px]",
  enterMeasureWidth: "Länge des Taktes eingeben",

  iyzicoProductReference: "Iyzico-Produktreferenzen",
  iyzicoPricingPlanReference: "Iyzico Zahlungsplan-Referenz",
  paypalReferenceCode: "PayPal-Referenznummer",
  pricingPlanId: "Plan-ID",
  pricingPlanNameTr: "Plan-Name [TR]",
  enterPricingPlanNameTr: "Name des Plans eingeben [TR]",
  pricingPlanDescriptionTr: "Planbeschreibung [TR]",
  enterPricingPlanDescriptionTr: "Planbeschreibung eingeben [TR]",
  pricingPlanNameEn: "Planname [DE]",
  enterPricingPlanNameEn: "Name des Plans eingeben [DE]",
  pricingPlanDescriptionEn: "Planbeschreibung [DE]",
  enterPricingPlanDescriptionEn: "Planbeschreibung eingeben [DE]",
  pricingPlanNameDe: "Planname [DE]",
  enterPricingPlanNameDe: "Name des Tarifs eingeben [DE]",
  pricingPlanDescriptionDe: "Planbeschreibung [DE]",
  enterPricingPlanDescriptionDe: "Planbeschreibung eingeben [DE]",
  pricingPlanNameAr: "Planname [AR]",
  enterPricingPlanNameAr: "Name des Tarifs eingeben [AR]",
  pricingPlanDescriptionAr: "Planbeschreibung [GB]",
  enterPricingPlanDescriptionAr: "Planbeschreibung eingeben [GB]",
  duration: "Dauer",
  enterDuration: "Dauer eingeben",
  discount: "Rabatt",
  enterDiscount: "Rabatt eingeben",
  price: "Preis",
  enterPrice: "Preis eingeben",

  instructors: "Ausbilder",
  instructorList: "Ausbilderliste",
  instructorsTable: "Tabelle der Ausbilder",
  AddNewInstructor: "Neuen Ausbilder hinzufügen",
  instructorId: "Ausbilder-ID",
  instructorName: "Name des Ausbilders",
  enterInstructorName: "Name des Ausbilders eingeben",
  instructorDescription: "Beschreibung des Ausbilders",
  instructorDescriptionTr: "Beschreibung des Ausbilders [TR]",
  enterInstructorDescriptionTr: "Beschreibung des Ausbilders eingeben [TR]",
  instructorDescriptionEn: "Beschreibung des Ausbilders [En]",
  enterInstructorDescriptionEN: "Beschreibung des Ausbilders eingeben [EN]",
  instructorDescriptionDe: "Beschreibung des Ausbilders [DE]",
  enterInstructorDescriptionDe: "Beschreibung des Ausbilders eingeben [DE]",
  instructorDescriptionAr: "Beschreibung des Ausbilders [AR]",
  enterInstructorDescriptionAr: "Beschreibung des Ausbilders eingeben [AR]",

  insCourses: "Ausbilderkurse",
  insCoursesList: "Liste der Ausbilderkurse",
  courseDescription: "Kursbeschreibung",
  insCourseInfo: "Informationen zum Ausbilderkurs",
  instructorCoursesTable: "Tabelle der Ausbilderkurse",
  instructorLessonsTable: "Tabelle der Ausbilder-Lektionen",

  videoAnalyze: "Video-Analysen",
  videoAnalyzeList: "Liste der Videoanalysen",
  AddNewSongAnalyze: "Neue Song-Analyse hinzufügen",
  videoAnalyzeId: "Video-Analyse-ID",
  videoId: "Video-ID",
  userType: "Benutzertyp",
  enterTime: "Eingabezeit des Liedes",
  exitTime: "Zeit bis zum Ende des Liedes",
  lastVideoTime: "Letzte Sekunde des gespielten Liedes",

  instructorApplications: "Musiklehrer Anmeldung",
  applicationId: "Ausbilder-Anwendungs-ID",
  instructorApplicationsList: "Liste der Bewerbungen von Ausbildern",
  AddNewInstructorApplications: "Neue Kursleiterbewerbung hinzufügen",
  instructorApplicationsTable: "Tabelle der Bewerbungen von Ausbildern",
  enterApplicantName: "Geben Sie den Namen des Bewerbers ein",
  enterApplicantSurname: "Geben Sie den Nachnamen des Bewerbers ein",
  enterApplicantEmail: "Geben Sie die E-Mail des Bewerbers ein",
  enterApplicantVideUrl: "Geben Sie die Video-URL des Bewerbers ein",

  feature1: "Umfassende Songauswahl",
  feature2: "Das komplette Lernprogramm für jeden Song",
  feature3: "Einfacher Zugang zu Noten",
  feature4: "Ständig aktualisierte Inhalte",
  feature5: "Lernoptionen auf verschiedenen Niveaus",
  feature6: "Wiederholen Sie schwierige Abschnitte mit der Schleifenfunktion",
  feature7: "Training für Anfänger und Fortgeschrittene",
  feature8: "Persönliche Hilfe von professionellen Trainern",
  feature9: "Fachkundiges Trainerpersonal",
  subscribe: "Anmelden",

  shouldLesson: "Sind Lektionen inbegriffen?",
  shouldSong: "Sind Lieder enthalten?",
  shouldFeedback: "Ist Feedback inbegriffen?",
  makeChoice: "Bitte eine Auswahl treffen?",
  packageInformations: "Paketinformationen",
  iyzicoInformationen: "Iyzico-Informationen",

  paypalInformations: "PayPal-Informationen",
  enterPaypalPricingPlanName: "Geben Sie den Namen des PayPal-Zahlungsplans ein",
  paypalProductReference: "PayPal-Produktreferenzcode",
  paypalPricingPlanReference: "PayPal-Referenzcode für den Zahlungsplan",
  paypalPricingPlanNameAdmin: "Name des PayPal-Zahlungsplans",
  paypalPricingPlanDescription: "Beschreibung des PayPal-Zahlungsplans",
  enterPaypalPricingPlanDescription: "Beschreibung des PayPal-Zahlungsplans eingeben",

  subscriptions: "Abonnenten",
  subscriptionList: "Abonnentenliste",
  checkSubscriptionStatus: "Abonnenten prüfen",
  subscriptionsInfo: "Informationen für Abonnenten",
  pricingPlanInformations: "Informationen zum Zahlungsplan",
  userInformations: "Benutzer-Informationen",
  addressInformations: "Adressinformationen",
  cardInformations: "Karten-Informationen",
  cardHolderName: "Name des Karteninhabers",
  enterCardHolderName: "Name des Karteninhabers eingeben",
  cardNumber: "Kartennummer",
  enterCardNumber: "Kartennummer eingeben",
  expireMonth: "Gültigkeitsdauer [Monat]",
  enterExpireMonth: "Verfallsdatum [Monat] eingeben",
  expireYear: "Verfall [Jahr]",
  enterExpireYear: "Verfallsdatum [Jahr] eingeben",
  cvc: "CVC-Code",
  enterCvc: "CVC-Code eingeben",
  expires: "Verfall",
  enterName: "Gebe deinen Namen ein",
  surname: "Nachname",
  enterSurname: "Geben deinen Nachnamen ein",
  gsmNumber: "Telefonnummer",
  zipCode: "Postleitzahl",
  parentReferenceCode: "Übergeordneter Referenzcode",
  payMethod: "Zahlungsmethode",
  noTrial: "Keine Probezeit",
  trialStartDate: "Beginn des Versuchszeitraums",
  trialEndDate: "Enddatum des Versuchszeitraums",
  startDate: "Startdatum",
  endDate: "Enddatum",
  expireDate: "Ablaufdatum",
  retryPayment: "Wiederholte Zahlung",

  couponCodes: "Coupon Codes",
  couponCodesList: "Gutscheincode-Liste",
  AddNewCouponCode: "Neuen Gutscheincode hinzufügen",
  couponCodeInfo: "Gutscheincode-Informationen",
  couponCodeId: "Gutscheincode-ID",
  referenceCode: "Referenzcode",
  enterReferenceCode: "Referenzcode eingeben",
  discountPercentage: "Rabattsatz",
  enterDiscountPercentage: "Rabattsatz eingeben",
  creditPercentageToInstructor: "Prozentsatz der Gutschrift für den Dozenten",
  enterCreditPercentageToInstructor: "Geben Sie den Prozentsatz der Gutschrift an den Dozenten ein",
  creditPercentageToStudent: "Darlehensprozentsatz an den Studenten",
  enterCreditPercentageToStudent: "Geben Sie den Kreditprozentsatz an den Studenten ein",
  enterCreatedDate: "Startzeitpunkt eingeben",
  enterExpirationDate: "Geben Sie die Verfallszeit ein",
  selectPricingPlans: "Wählen Sie die anzuwendenden Zahlungspläne",

  addressInfoHeader: "Adressinformationen",
  addressInfoAlt: "Wählen Sie einen der folgenden Pläne",
  enterGsmNumber: "Geben Sie Ihre 10-stellige Telefonnummer ein",
  identityNumber: "Identitätsnummer",
  enterIdentityNumber: "Geben Sie Ihre 11-stellige Identitätsnummer ein",
  Adresse: "Rechnungsadresse",
  enterAddress: "Rechnungsadresse eingeben",
  contactName: "Name des Rechnungsempfängers eingeben",
  enterContactName: "Name des Rechnungsempfängers eingeben",
  enterContactName: "Enter Invoice Recipient Name",
  city: "Stadt",
  enterCity: "Stadt eingeben",
  country: "Land",
  enterCountry: "Land eingeben",

  userInfoHeader: "Benutzerinformationen",
  userInfoAlt: "Bitte tragen Sie die fehlenden Informationen ein",

  selectInstrument: "Instrument auswählen",

  users: "Benutzer",
  usersList: "Benutzerliste",
  AddNewUser: "Neuen Benutzer anlegen",
  usersTable: "Benutzer-Tabelle",
  enterEmail: "E-Mail eingeben",
  resetToken: "Token zurücksetzen",
  resetTokenExpire: "Verfallsdatum des Tokens zurücksetzen",

  feedbackInfo: "Rückmeldungsinformationen",
  feedbacks: "Rückmeldungen",
  feedbacks_header: "Helfen Sie uns, besser zu werden und unseren Service für Sie zu verbessern. Ihr Feedback ist sehr wertvoll für uns, vielen Dank!",
  feedbackList: "Feedback-Liste",
  feedbacksTable: "Rückmeldungen Tabelle",
  AddNewFeedback: "Neues Feedback hinzufügen",
  feedbackId: "Rückmelde-ID",
  feedbackTitle: "Feedback-Titel",
  enterFeedbackTitle: "Feedback-Titel eingeben",
  feedbackDetails: "Feedback-Details",
  enterFeedbackDetails: "Feedback-Details eingeben",
  feedbackReply: "Feedback-Antwort",
  enterFeedbackReply: "Feedback-Antwort eingeben",
  feedbackType: "Rückmeldungsart",
  problem: "Problem",
  suggestion: "Anregung",
  complaint: "Beanstandung",
  general: "Allgemein",
  waiting: "Schwebend",
  success: "Erfolg",
  processing: "In Bearbeitung",
  solved: "Gelöst",
  cancelled: "Abgebrochen",
  rejected: "Abgelehnt",

  //Paypal-Übersetzungen
  update_time: "Aktualisierungsdatum",
  pricing_plan_reference: "Zahlungsplan-Referenzcode",

  accept: "Annehmen",
  decline: "Abgelehnt",

  productInfo: "Produktinformation",
  products: "Produkte",
  productsList: "Produktliste",
  AddNewProducts: "Neues Produkt hinzufügen",
  productReferenceCode: "Produkt-Referenznummer",
  productName: "Produktname",
  enterProductName: "Produktnamen eingeben",
  productDescription: "Produktbeschreibung",
  enterProductDescription: "Produktbeschreibung eingeben",

  pricingPlanInfo: "Informationen zum Zahlungsplan",
  pricingPlans: "Zahlungspläne",
  pricingPlansList: "Liste der Zahlungspläne",
  pricingPlansTable: "Tabelle mit Zahlungsplänen",
  AddNewPricingPlan: "Neuen Zahlungsplan hinzufügen",
  pricePlanReferenceCode: "Zahlungsplan-Referenzcode",
  pricingPlanName: "Name des Zahlungsplans",
  enterPricingPlanName: "Name des Zahlungsplans eingeben",
  paymentInterval: "Zahlungsintervall",
  paymentIntervalCount: "Zahlungsfrequenz",
  enterPaymentIntervalCount: "Zahlungsfrequenz eingeben",
  trialPeriodDays: "Tage der Probezeit",
  enterTrialPeriodDays: "Anzahl der Tage des Testzeitraums eingeben",
  MONTH1: "1 Monat",
  MONTH6: "6 Monate",
  MONTH12: "12 Monate",
  payPlatform: "Zahlungsplattform",
  countryISO: "ISO-Code des Landes",
  enterCountryISO: "Geben Sie den ISO-Code des Landes ein",
  currencyISO: "ISO-Code der Währung",
  enterCurrencyISO: "Geben Sie den ISO-Code der Währung ein",

  subscriptionReferenceCode: "Referenznummer der Mitgliedschaft",
  subscriptionName: "Name des Mitglieds",
  subscriptionSurname: "Nachname des Mitglieds",
  subscriptionEmail: "E-Mail des Mitglieds",
  subscriptionGsmNumber: "Telefonnummer des Mitglieds",
  subscriptionInfo: "Informationen zum Abonnement",
  orderInformations: "Zahlungsinformationen",
  paymentAttempts: "Zahlungsversuche",
  subscriptionsList: "Abonnentenliste",

  profile_profile: "Profil",
  profile_password: "Passwort ändern",
  profile_subscription: "Ihr Plan",
  profil_email: "E-Mail ändern",
  profile_payment: "Zahlungsmethode",
  profile_past_subscriptions: "Abonnement-Informationen",
  profile_cancel: "Abonnement kündigen",
  ACTIVE: "Aktiver Plan",
  FINISHED: "Vergangenes Abo",
  UPGRADED: "Aufgestockter Plan",
  CANCELLED: "Abgebrochener Plan",
  WAITING: "Wartender Plan",

  Student: "Student",
  change_password: "Passwort ändern",
  password_requirements: "Kennwortanforderungen",
  password_requirements_description: "Für ein sicheres Kennwort beachten Sie bitte die folgenden Anforderungen",
  special_characters: "1 Sonderzeichen [.,-_ etc.]",
  min_characters: "Mindestens 6 Zeichen",
  one_number: "1 Rakam",
  one_letter: "1 Buchstabe",
  current_password: "Aktuelles Passwort",
  new_password: "Neues Passwort",
  confirm_new_password: "Bestätigen Sie das neue Passwort",
  enterCurrentPassword: "Geben Sie Ihr aktuelles Passwort ein",
  enterMinCharacters: "Ihr Kennwort muss aus mindestens 6 Zeichen bestehen",
  enterSpecialCharacters: "Ihr Passwort muss mindestens 1 Sonderzeichen enthalten [.,!-_, etc.]",
  enterOneNumber: "Ihr Passwort muss mindestens 1 Zahl enthalten",
  enterOneLetter: "Ihr Kennwort muss mindestens 1 Buchstaben enthalten",
  passwordDoesntMatch: "Ihr Passwort stimmt nicht mit dem Bestätigungspasswort überein",

  profile_changed_success: "Ihre Daten wurden erfolgreich aktualisiert.",
  profile_changed_error: "Beim Aktualisieren Ihrer Daten ist ein Fehler aufgetreten. Versuchen Sie es später erneut",
  enterNameError: "Ihr Name kann nicht leer sein. Bitte geben Sie Ihren Namen ein.",
  enterSurnameError: "Ihr Nachname kann nicht leer sein. Bitte geben Sie Ihren Nachnamen ein.",

  password_changed_success: "Ihr Passwort wurde erfolgreich aktualisiert.",
  password_changed_failed: "Ihr Passwort stimmt nicht überein, versuchen Sie es erneut.",
  update_information: "Informationen aktualisieren",
  edit: "Bearbeiten",
  past_subscriptions_header: "Abonnement-Informationen",

  change_email: "E-Mail ändern",
  new_email: "Neue E-Mail",
  confirm_current_password: "Aktuelles Passwort bestätigen",

  subscription_header: "Ihr Zahlungsplan",
  change_subscription: "Zahlungsplan ändern",
  cannotProvideThisService: "Wir können diese Dienstleistung derzeit nicht erbringen",

  payment_method: "Zahlungsmethode",
  payment_method_description: "Sind Sie sicher, dass Sie dies tun wollen?",
  change_payment_method: "Zahlungsmethode aktualisieren",

  cancel_subscription: "Abonnement kündigen",
  cancel_subscription_description: "Sind Sie sicher, dass Sie dies tun wollen?",
  cancel_subscription_success: "Ihr Abonnement wurde erfolgreich gekündigt.",
  cancel_subscription_failure: "Ihr Abonnement konnte nicht gekündigt werden.",
  canceled_subscription_description: "Ihr Abonnement wurde gekündigt. ",
  canceled_subscription_description2: "Sie können Ihr Abonnement weiter nutzen bis",

  register_header: "Anwenden",
  register_message: "Bewerben Sie sich für den Zugriff auf die Plattform.",
  referenceUserName: "Referenz-E-Mail",
  referenceName: "Referenzname",
  note: "Anwendungshinweis",

  feature_main: "Bağlama bedeutet, mit Musik das Herz der Menschen zu berühren",
  feature_alt:
    "Bağlama-Enthusiasten, ihr habt jetzt Zugang zu einer einzigartigen Online-Musiklernplattform. Kompakte Lektionen von Meister-Bağlama-Spielern und viele Stücke erwarten dich. Spüre den Rhythmus der Musik in deinem Herzen. Registriere dich jetzt und bringe deine Fähigkeiten auf die nächste Stufe.",

  feature1_header: "Starte mit deinen Lieblingsliedern",
  feature1_alt: "Wähle aus Hunderten von Titeln deine Lieblingslieder aus, damit das Lernen Spaß macht.",

  feature2_header: "Video & Noten",
  feature2_alt: "Sieh den Profis beim Musizieren zu, folge gleichzeitig den Noten und lerne ganz einfach.",

  feature3_header: "Stufenweises Lerntempo",
  feature3_alt: "Wähle das Tempo, das zu dir passt, um jeden Song zu meistern.",

  feature4_header: "Schleifenfunktion",
  feature4_alt: "Wiederholen die Teile, die du üben willst, und konzentrieren dich ganz auf das Spielen",

  feature5_header: "Songs entdecken",
  feature5_alt: "Entdecke neue Songs aller Niveaus und Genres oder unvergessliche Klassiker zum spielen.",

  feature6_header: "Ständig neue Inhalte",
  feature6_alt: "Verbessern dich kontinuierlich mit neuen Werken und Kursen, die regelmäßig hinzugefügt werden.",

  call_main: "Die einzigartigen Melodien der Bağlama stehen dir jetzt zur Verfügung!",
  call_alt:
    "Wir laden dich in die einzigartige Welt der Bağlama ein, mit einer umfangreichen Songbibliothek, die Noten mit Farben zusammenbringt und das Lerntempo an dich anpasst. Mit unseren hilfreichen Funktionen helfen wir dich gezielt zu verbessern und ein Meister der Bağlama zu werden. Melde dich jetzt an und beginne deine Musikreise mit uns!",
  call_alt_2: "Vergiss nicht, Musik bedeutet, die Herzen der Menschen zu berühren 🎵 💙",

  trialInterval: "Forever",
  monthlyInterval: "Monat",
  couponInterval: "Monat",
  includesSong: "Enthaltene Lieder",
  includesLesson: "Enthaltene Lektionen",
  includesFeedback: "Feedback inbegriffen",
  trial: "Schnupperpakete",
  paid: "Premium-Pakete",
  monthly: "Monatliche Pakete",
  yearly: "Jahrespakete",
  choosePlan: "Plan wählen",
  currentPlan: "Aktueller Plan",
  upgradePlan: "Upgrade-Plan",
  checkout: "Checkout",
  plan_header: "Plan auswählen",
  plan_description: "Wähle einen der folgenden Pläne",
  logout: "Abmelden",
  goBack: "Zurück",
  next: "Weiter",

  Quality: "Qualität",
  QualityButton: "Videoqualität",
  PlaybackRate: "Wiedergaberate",
  SpeedButton: "Videogeschwindigkeit",
  TurnOffVolume: "Stumm",
  TurnOnVolume: "Stummschaltung aufheben",
  ForwardButton: "Schneller Vorlauf",
  RewindButton: "Zurückspulen",
  ReturnStart: "Zurück zum Anfang",
  Stop: "Anhalten",
  Play: "Abspielen",
  EnterLoop: "Schleife betreten",
  ExitLoop: "Schleife beenden",
  Fullscreen: "Vollbild",
  VideoSettings: "Video-Einstellungen",

  areYouSureYouWantToDelete: "Bist du sicher, dass du löschen willst?",

  new_subscription_wizard_header: "Erstelle dein Abonnement",
  new_subscription_wizard_description: "Fülle bitte die folgenden Informationen aus, um dein Abonnement zu erstellen",

  upgrade_plan_wizard_header: "Ändere deinen Plan",
  upgrade_plan_wizard_description: "Geben bitte die folgenden Informationen ein, um den Plan zu ändern",

  change_card_wizard_header: "Ändern deiner Zahlungsmethode",
  change_card_wizard_description: "Geben bitte unten deine neuee Kartendaten ein oder wählen eine aus deinen gespeicherten Karten aus",

  faq_header: "Häufig gestellte Fragen",
  faq_description: "",

  question1: "Ist laSolist kostenlos?",
  answer1_1: "Du kannst die völlig kostenlose Test-Version von LaSolist nutzen, indem du nach der Registrierung unser Testpaket wählen.",

  question2: "Welche Zahlungsmöglichkeiten gibt es?",
  answer2_1: "Wir arbeiten mit Iyzico zusammen und Sie können alle von Iyzico angebotenen Zahlungsmethoden und Optionen nutzen.",

  question3: "Wie kann ich meine laSolist-Mitgliedschaft verlängern?",
  answer3_1: "Je nach dem von Ihnen gewählten Plan wird Ihr Training automatisch verlängert.",

  question4: "Wie kann ich meine laSolist-Mitgliedschaft kündigen?",
  answer4_1: "Sie können Ihr Profil eingeben und Ihre aktive Mitgliedschaft kündigen. Sie können laSolist bis zum Enddatum Ihres Pakets weiter nutzen.",

  question5: "Habt Ihr spezielle Pakete für Studenten?",
  answer5_1: "Wenn du ein Student bist, kannst du von einem speziellen Rabatt profitieren. Melde dich unter iletisim@lasolist.com mit deinem Studentenausweis um einen Studenten-Rabatt zu erhalten.",

  question6: "Ich bin Musiklehrer, wie kann ich von laSolist profitieren?",
  answer6_1: "laSolist ist die perfekte Unterstützung für Sie. Sie können Ihren Unterricht noch besser gestalten und Ihre Schüler zum selbstständigen Üben motivieren. Eine neue Art des Lernens, die Ihre Schüler engagierter und schneller lernen lässt und Sie als Lehrer stärkt. ",
  answer6_2:
    "laSolist hilft Ihren Schülern, sich an ihren wöchentlichen Übungsplan zu halten, so dass Sie in Ihrem Unterricht keine Zeit damit verbringen müssen, Dinge nachzuholen, die sie nicht geübt haben. Stattdessen können Sie sich in der Stunde wirklich auf Ihre Schüler einlassen und sich ihren individuellen Stärken und Schwächen widmen. Außerdem können Sie die gesparte Zeit nutzen, um wichtige Konzepte zu wiederholen, die am besten im Sitzen gelehrt werden, z. B. Technik, Intonation oder Spielstil. Schließlich ist es genau das, was Ihren Unterricht so wertvoll und unersetzlich macht.",

  question7: "Ich bin Musiklehrer, arbeiten Sie mit den Lehrern zusammen?",
  answer7_1: "Ja, wir bieten maßgeschneiderte Lösungen für Lehrer und Schulen. Gemeinsam mit laSolist können Sie Ihre eigene Unterrichtsreihe erstellen. ",
  answer7_2: "Bitte kontaktieren Sie uns für weitere Details, unsere Email-Adresse lautet: mail@lasolist.com",

  question8: "Bieten Sie maßgeschneiderte Fortbildungsreihen für Schulen an?",
  answer8_1: "Ja, wir bieten maßgeschneiderte Lösungen für Lehrer und Schulen an. Zusammen mit laSolist können Sie Ihre eigene maßgeschneiderte Fortbildungsreihe für Ihre Schule erstellen. ",
  answer8_2: "Bitte kontaktieren Sie uns für weitere Details, unsere Email-Adresse lautet: mail@lasolist.com",

  introduction_header_1: "Meisterhaftes Musizieren - Lernen leicht gemacht",
  introduction_alt_1:
    "Egal, ob du ein Musikanfänger oder ein erfahrener Bağlama-Spieler bist, der sich verbessern möchte, mit unserer Online-Plattform zum Bağlama-Lernen erreichst du den Höhepunkt deines Könnens. Sieh dir die Darbietung des Profis aus verschiedenen Blickwinkeln an, begleitet von einem klar verständlichen Notensystem. Unsere spezielle Schleifenfunktion ermöglicht es dir, schwierige Passagen in einer Endlosschleife zu üben, um dein Können gezielt zu verbessern.",

  introduction_header_2: "Freude am musizieren",
  introduction_alt_2: "Unsere innovative Farbnoten-Technologie hilft dir, Musikstücke schneller zu erfassen, und unterstützt dich beim Erlernen von Taktarten und Tonabfolgen. Erweitere deine Kenntnisse, entdecke neue Techniken und perfektioniere deine Fähigkeiten.",

  welcome: "Willkommen ",
  welcome_alt: "Das Bağlama-Abenteuer beginnt hier bei uns!",

  latest_songs: "Neueste Lieder",
  latest_courses: "Neueste Kurse",
  latest_instructor_courses: "Neueste Ausbilderkurse",
  lesson: " Neueste",
  view_all: "Alle anzeigen",
  view: "Ansicht",

  cookies_header: "Cookie-Richtlinie",
  cookies_latest_update_date: "Zuletzt aktualisiert: 24/10/2023",
  cookies_alt_p1:
    "Als laSolist liegt uns Ihre Sicherheit am Herzen und mit dieser Cookie-Richtlinie möchten wir unsere geschätzten Besucher kurz darüber informieren, welche Cookies wir auf unserer Website verwenden, zu welchem Zweck und wie Sie Ihre Cookie-Einstellungen ändern können",
  cookies_alt_p2:
    "Um Ihnen einen besseren Service bieten zu können, bitten wir Sie, diese Cookie-Richtlinie zu lesen, um sich kurz darüber zu informieren, welche Art von personenbezogenen Daten durch Cookies gesammelt werden, zu welchem Zweck und wie sie verarbeitet werden. Für weitere Informationen können Sie unsere Datenschutzrichtlinie einsehen oder uns kontaktieren.",

  what_is_cookie: "Was ist ein Cookie?",
  what_is_cookie_p1:
    "Cookies sind kleine Dateien, die auf den Geräten der Nutzer gespeichert werden, damit diese Websites effizienter nutzen können. Da die Informationen der Nutzer durch Cookies verarbeitet werden, müssen die Nutzer gemäß dem Gesetz Nr. 6698 über den Schutz personenbezogener Daten informiert werden und ihre Zustimmung einholen",
  what_is_cookie_p2: "Wir verwenden verschiedene Cookies, um sicherzustellen, dass unsere geschätzten Besucher unsere Website so effizient wie möglich nutzen können, und um die Nutzererfahrung unserer geschätzten Besucher zu verbessern.",

  mandatory_cookie: "1. Obligatorische Cookies",
  mandatory_cookie_alt: "Obligatorische Cookies sind Cookies, die es ermöglichen, die Website zu nutzen, indem sie grundlegende Funktionen der Website bereitstellen. Ohne diese Cookies wird die Website nicht richtig funktionieren.",

  performance_cookie: "2. Performance Cookies",
  performance_cookie_alt: "Performance-Cookies sind Cookies, die in anonymer Form Informationen über die Nutzung der Website und Präferenzen der Besucher sammeln, um die Leistung der Website zu verbessern.",

  functional_cookie: "3. Funktionale Cookies",
  functional_cookie_alt:
    "Funktionale Cookies sind Cookies, die es ermöglichen, Benutzer bei zukünftigen Besuchen auf der Grundlage ihrer früheren Nutzung der Website wiederzuerkennen und sich an sie zu erinnern, so dass Websites den Benutzern einen personalisierten Service bieten können, z. B. Sprache, Region usw.",

  ad_cookie: "4. Werbe-Cookies",
  ad_cookie_alt:
    "Werbe-Cookies sind Cookies im Besitz von Dritten und werden verwendet, um das Verhalten der Besucher auf Websites zu verfolgen. Der Zweck dieser Cookies ist es, Werbung anzuzeigen, die für die Bedürfnisse der Besucher von Interesse ist, und die Verantwortung liegt bei der dritten Partei, die Eigentümer des Cookies ist.",

  data_processed_with_cookie: "Welche personenbezogenen Daten werden mit Cookies verarbeitet?",
  data_processed_with_cookie_alt:
    "Ihre Identitäts- (Vorname, Nachname, Geburtsdatum, etc.) und Kontaktinformationen (Adresse, E-Mail-Adresse, Telefon, IP, Standort, etc.) werden von uns durch Cookies verarbeitet. Ihre Identitäts- (Vorname, Nachname, Geburtsdatum usw.) und Kontaktinformationen (Adresse, E-Mail-Adresse, Telefon, IP-Adresse, Standort usw.) werden von uns durch Cookies, durch automatische oder nicht-automatische Methoden und manchmal im Rahmen der Dienstleistungs- und Vertragsbeziehung zwischen uns und während der Dienstleistungs- und Vertragsbeziehung zwischen uns verarbeitet, indem wir sie von Dritten wie Analytik-Anbietern, Werbenetzwerken, Anbietern von Suchinformationen und Technologieanbietern auf der Grundlage der Bedingung des berechtigten Interesses erhalten, aufzeichnen, speichern und aktualisieren.",

  what_purpose: "Zu welchem Zweck werden Cookies verwendet?",
  what_purpose_alt:
    "Unsere Website verwendet Cookies, um die Sicherheit zu gewährleisten, indem verdächtige Bewegungen erkannt werden, um die Funktionalität und Leistung entsprechend den Präferenzen der Nutzer zu verbessern, um Produkte und Dienstleistungen zu verbessern und zu personalisieren und um den Zugang zu diesen Dienstleistungen zu erleichtern, um vertragliche und gesetzliche Verpflichtungen zu erfüllen. Darüber hinaus werden auch Werbe-Cookies und der Informationsaustausch mit Dritten verwendet, um die Nutzer mit einer breiteren Palette von Dienstleistern in Verbindung zu bringen",

  how_to_manage: "Wie man Cookies verwaltet",
  how_to_manage_alt_p1:
    "Nach all diesen Erklärungen bleibt die Frage, welche Cookies verwendet werden sollen, dem freien Willen unserer Nutzer überlassen. Sie können Ihre Cookie-Präferenzen verwalten, indem Sie sie in Ihren Browser-Einstellungen löschen oder blockieren, sobald Sie unsere Website betreten, oder Sie können diese Einstellungen jederzeit in der Zukunft ändern. Ausführlichere Informationen finden Sie in unserer Datenschutzrichtlinie oder kontaktieren Sie uns unter ",
  how_to_manage_alt_p2: "Sie können uns über die E-Mail-Adresse kontaktieren",

  privacy_header: "Datenschutzrichtlinie",
  privacy_latest_update_date: "Zuletzt aktualisiert: 24/10/2023",
  privacy_alt_p1: "Ihre Sicherheit ist uns wichtig. Aus diesem Grund sind Ihre persönlichen Daten, die Sie uns zur Verfügung stellen, besonders geschützt.",
  privacy_alt_p2:
    "Wir, Name des Unternehmens, als der für die Datenverarbeitung Verantwortliche, informieren Sie mit dieser Datenschutzerklärung darüber, welche Ihrer personenbezogenen Daten zu welchem Zweck verarbeitet werden, an wen und warum die verarbeiteten Daten weitergegeben werden können, über die Art und Weise der Datenverarbeitung und über rechtliche Gründe; wir möchten Sie darüber informieren, welche Rechte Sie in Bezug auf Ihre verarbeiteten Daten haben.",

  collected_data_reason: "Ihre erhobenen personenbezogenen Daten, Erhebungsmethode und Rechtsgrund",
  collected_data_alt:
    "Ihre IP-Adresse und User-Agent-Informationen werden ausschließlich zu Analysezwecken und mit Hilfe von Technologien wie Cookies, automatischen oder nicht-automatischen Methoden und manchmal durch Beschaffung, Aufzeichnung, Speicherung und Aktualisierung von Dritten wie Analytik-Anbietern, Werbenetzwerken, Suchinformationsanbietern, Technologieanbietern im Rahmen und während der Dauer des Dienstes und der vertraglichen Beziehung zwischen uns auf der Grundlage der Bedingung des berechtigten Interesses an der Verarbeitung verarbeitet.",

  privacy_purpose: "Zweck der Verarbeitung Ihrer personenbezogenen Daten",
  privacy_purpose_alt:
    "Ihre personenbezogenen Daten, die Sie uns zur Verfügung stellen, werden während der Vertrags- und Dienstleistungsdauer zweckgebunden und in angemessenem Umfang verarbeitet und aktualisiert, um die Anforderungen der von uns angebotenen Dienstleistungen bestmöglich zu erfüllen, um sicherzustellen, dass diese Dienstleistungen von Ihnen maximal genutzt werden können, um unsere Dienstleistungen entsprechend Ihren Bedürfnissen zu verbessern und um Sie innerhalb des gesetzlichen Rahmens mit einer breiteren Palette von Dienstleistern zusammenzubringen, und um die sich aus dem Gesetz ergebenden Verpflichtungen zu erfüllen (Weitergabe personenbezogener Daten an Justiz- und Verwaltungsbehörden auf Anfrage).",

  transferred_collected_data: "An wen und zu welchen Zwecken die erhobenen personenbezogenen Daten übermittelt werden können",
  transferred_collected_data_alt:
    "Ihre personenbezogenen Daten, die Sie uns mitteilen, können an Dritte sowie an Institutionen und Organisationen in der Türkei und im Ausland übermittelt werden, von denen wir Dienstleistungen zur Durchführung unserer Aktivitäten erhalten und/oder erbringen, mit denen wir in einer vertraglichen Beziehung stehen, mit denen wir zusammenarbeiten, sowie auf Anfrage an Justiz- und Verwaltungsbehörden, sofern die erforderlichen technischen und administrativen Maßnahmen getroffen werden.",

  your_rights: "Ihre Rechte als Person, deren personenbezogene Daten verarbeitet werden",
  your_rights_alt_p1: "Gemäss Artikel 11 des BVG kann jede Person die folgenden Rechte ausüben, indem sie sich an den für die Datenverarbeitung Verantwortlichen wendet:",
  your_rights_a: "Zu erfahren, ob personenbezogene Daten bearbeitet werden",
  your_rights_b: "Auskunft zu verlangen, wenn Ihre personenbezogenen Daten verarbeitet wurden",
  your_rights_c: "Zu erfahren, zu welchem Zweck die personenbezogenen Daten verarbeitet werden und ob sie für den vorgesehenen Zweck verwendet werden",
  your_rights_d: "Zu erfahren, an welche Dritten in der Türkei oder im Ausland personenbezogene Daten übermittelt werden",
  your_rights_e: "Die Berichtigung personenbezogener Daten im Falle einer unvollständigen oder unrichtigen Verarbeitung zu verlangen,",
  your_rights_f: "Löschung oder Vernichtung personenbezogener Daten zu verlangen,",
  your_rights_g: "Verlangen, dass Dritte, an die personenbezogene Daten übermittelt werden, über die unter den Buchstaben e) und f) genannten Vorgänge unterrichtet werden,",
  your_rights_h: "Einspruch gegen das Eintreten eines Ergebnisses zum Nachteil der eigenen Person zu erheben, indem die verarbeiteten Daten ausschließlich durch automatisierte Systeme analysiert werden,",
  your_rights_i: "Für den Fall, dass personenbezogene Daten aufgrund einer unrechtmäßigen Verarbeitung personenbezogener Daten beschädigt werden, hat sie das Recht, den Ersatz des Schadens zu verlangen.",
  your_rights_alt_p2: "Sie können uns über email@site.com kontaktieren, um Ihre oben genannten Rechte auszuüben.",

  contact: "Kontakt",
  contact_alt_p1:
    "Es steht Ihnen völlig frei, der Verarbeitung Ihrer personenbezogenen Daten zuzustimmen oder sie abzulehnen, die nur für die Durchführung von Analysen erforderlich ist, um Ihnen in Übereinstimmung mit dieser Datenschutzrichtlinie und der Richtlinie zur Verarbeitung personenbezogener Daten Dienstleistungen zu erbringen. Wenn Sie die Website weiter nutzen, gehen wir davon aus, dass Sie damit einverstanden sind, und Sie können sich für weitere Informationen an uns wenden ",
  contact_email: "contact@lasolist.com",
  contact_alt_p2: "Bitte zögern Sie nicht, uns per E-Mail zu kontaktieren",

  userA_agreement: "Terms of Use",
  user_agreement_latest_update_date: "Zuletzt aktualisiert: 24/10/2023",
  user_agreement_p1:
    "Sehr geehrter Besucher, bitte lesen Sie diese Nutzungsbedingungen sorgfältig durch, bevor Sie unsere Website https://lasolist.com besuchen. Ihr Zugriff auf die Website hängt vollständig davon ab, dass Sie dieser Vereinbarung zustimmen und die in dieser Vereinbarung festgelegten Bedingungen einhalten. Wenn Sie mit den Bedingungen dieser Vereinbarung nicht einverstanden sind, beenden Sie bitte Ihren Zugang zu dieser Website. Bitte beachten Sie, dass wir, wenn Sie weiterhin auf die Website zugreifen, davon ausgehen, dass Sie den gesamten Text dieser Vereinbarung bedingungslos und uneingeschränkt akzeptieren.",
  user_agreement_p2:
    "Die Website https://lasolist.com wird von der Firma verwaltet und wird im Folgenden als SITE bezeichnet. Die Nutzungsbedingungen für diese Website treten mit ihrer Veröffentlichung in Kraft. Das Recht, einseitig Änderungen vorzunehmen, liegt bei der SITE, und es wird davon ausgegangen, dass alle unsere Nutzer diese Änderungen akzeptieren, die auf der SITE aktuell veröffentlicht werden.",

  user_agreement_privacy: "Datenschutz",
  user_agreement_privacy_alt:
    "Der Datenschutz ist auf einer separaten Seite verfügbar, um die Grundsätze der Verarbeitung Ihrer personenbezogenen Daten durch uns zu regeln. Wenn Sie die SITE nutzen, erklären Sie sich damit einverstanden, dass die Verarbeitung dieser Daten in Übereinstimmung mit der Datenschutzrichtlinie erfolgt.",

  scope_of_service: "Scope of Service",
  scope_of_service_alt:
    "Als Company Name sind wir völlig frei, den Umfang und die Art der Dienstleistungen, die wir im Rahmen des Gesetzes erbringen, zu bestimmen; die Änderungen, die wir in Bezug auf die Dienstleistungen vornehmen, gelten mit der Veröffentlichung auf der SITE als in Kraft getreten.",

  copyright: "Urheberrechte",
  copyright_alt:
    "Der Eigentümer aller auf der SITE veröffentlichten Texte, Codes, Grafiken, Logos, Bilder, Tondateien und der verwendeten Software (im Folgenden als 'Inhalt' bezeichnet) ist das Unternehmen und alle Rechte sind vorbehalten. Die Reproduktion oder das Kopieren von Inhalten der Site ohne schriftliche Genehmigung ist strengstens untersagt.",

  general_provisions: "Allgemeine Bestimmungen",
  general_provisions_a:
    "Alle Nutzer verpflichten sich, die SITE nur für rechtmäßige und persönliche Zwecke zu nutzen und sich nicht an Aktivitäten zu beteiligen, die die Rechte Dritter verletzen könnten. Die rechtliche und strafrechtliche Verantwortung für ihre Transaktionen und Handlungen innerhalb der SITE liegt bei ihnen selbst. Die SITE übernimmt keine direkte und/oder indirekte Haftung für Schäden, die Dritte aufgrund dieser Arbeiten und Handlungen erleiden oder erleiden könnten.",
  general_provisions_b:
    "Wir tun unser Bestes, um die Richtigkeit und Aktualität der auf der SITE verfügbaren Informationen zu gewährleisten. Trotz unserer Bemühungen können diese Informationen jedoch hinter den tatsächlichen Änderungen zurückbleiben und es kann zu Abweichungen kommen. Aus diesem Grund übernehmen wir weder ausdrücklich noch stillschweigend eine Garantie für die Richtigkeit und Aktualität der auf der Site enthaltenen Informationen und gehen keinerlei Verpflichtung ein.",
  general_provisions_c:
    "Die SITE kann Hyperlinks zu anderen Websites, Anwendungen und Plattformen enthalten, die von Dritten betrieben werden und deren Inhalte uns nicht bekannt sind. Die Funktionalität der SITE ermöglicht lediglich den Zugang zu diesen Websites und wir übernehmen keine Verantwortung für deren Inhalt.",
  general_provisions_d:
    "Obwohl wir unser Bestes tun, um die SITE frei von Viren zu halten, können wir nicht garantieren, dass sie völlig frei von Viren ist. Es liegt daher in der Verantwortung der Nutzer, beim Herunterladen von Daten die erforderlichen Vorsichtsmaßnahmen gegen Viren zu treffen. Wir können keine Verantwortung für Schäden übernehmen, die durch bösartige Programme, Codes oder Materialien wie Viren usw. verursacht werden",
  general_provisions_e:
    "Wir garantieren nicht, dass die auf der SITE angebotenen Dienste keine Mängel oder Fehler aufweisen oder dass der Dienst ununterbrochen zur Verfügung steht. Wir können Ihren Zugriff auf die SITE und ihre Dienste oder Teile davon jederzeit ohne vorherige Ankündigung beenden.",

  limitations_of_responsibility: "Haftungsbeschränkung",
  limitations_of_responsibility_p1:
    "Unsere Haftung für Schäden, die sich aus der Nutzung der SITE ergeben, ist auf Vorsatz und grobe Fahrlässigkeit beschränkt. Bei Schäden, die auf einer Vertragsverletzung beruhen, ist der Schadensersatzanspruch insgesamt auf den vorhersehbaren Schaden begrenzt. Die vorgenannten Haftungsbeschränkungen gelten auch nicht bei Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit einer Person. In allen Fällen höherer Gewalt haften wir nicht auf Schadensersatz wegen Verzögerung, Nichterfüllung oder Verzug.",
  limitations_of_responsibility_p2: "Streitschlichtung: Für die Beilegung von Streitigkeiten, die sich aus der Anwendung oder Auslegung dieser Vereinbarung ergeben, gilt das Recht der Republik Türkei.",

  delivery_and_refund_latest_update_date: "Zuletzt aktualisiert: 07/02/2024",
  delivery_and_refund_alt_p1: "Diese Liefer- und Rückgabebedingungen werden zwischen 'laSolist Yazılım Eğitim Bilişim A.Ş.' und dem Kunden geschlossen.",
  delivery_and_refund_alt_p2: "Gegenstand dieser Vereinbarung ist die Regelung der Liefer- und Rückgabebedingungen für digitale Bildungsinhalte, die über die vom Unternehmen betriebene Plattform www.lasolist.com verkauft werden.",
  delivery_header: "Lieferung",
  delivery_alt:
    "Das Unternehmen wird dem Nutzer die Inhalte in digitaler Form zur Verfügung stellen, nachdem der Nutzer das von ihm gewählte Schulungspaket erworben hat. Die Schulungsinhalte sind für den Zeitraum zugänglich, der sich nach dem vom Nutzer gewählten Abonnementpaket richtet.",
  pricing_and_payment_header: "Preisgestaltung und Zahlungen",
  pricing_and_payment_alt:
    "Der Nutzer erklärt sich bereit, die Gebühr auf der Grundlage des von ihm gewählten Abonnementpakets zu zahlen. Die Gebühren werden monatlich ab dem Startdatum des Abonnements berechnet. Die Zahlungen erfolgen über sichere, vom Unternehmen festgelegte Zahlungssysteme.",
  cancel_and_refund_header: "Stornierung und Rückerstattung",
  cancel_and_refund_alt_p1:
    "Der Nutzer ist verpflichtet, das Unternehmen eine bestimmte Zeit im Voraus zu informieren (z. B. einen Monat im Voraus), wenn er das von ihm erworbene Abonnementpaket kündigen möchte. Nach Eingang des Kündigungsantrags wird das Abonnement des Nutzers beendet und der Zugang zu den Inhalten gesperrt",
  cancel_and_refund_alt_p2:
    "Es gibt keine Erstattung für gekündigte Abonnements. In Fällen, in denen der Nutzer den Dienst jedoch nicht in vollem Umfang nutzen kann (z. B. Unterbrechung des Zugriffs auf Inhalte aufgrund technischer Probleme), kann das Unternehmen dem Nutzer alternative Lösungen oder eine teilweise Rückerstattung anbieten.",
  privicy_security_header: "Datenschutz und Sicherheit",
  privicy_security_alt: "Das Unternehmen verpflichtet sich, die persönlichen Daten der Nutzer vertraulich zu behandeln und ihre Sicherheit zu gewährleisten. Die persönlichen Daten der Nutzer werden im Rahmen der Datenschutzpolitik des Unternehmens verarbeitet und gespeichert.",
  changes_header: "Änderungen",
  changes_alt_p1: "Das Unternehmen behält sich das Recht vor, Änderungen an dieser Vereinbarung vorzunehmen. Änderungen treten mit der Benachrichtigung der Nutzer in Kraft.",
  changes_alt_p2: "Diese Liefer- und Rückgabevereinbarung ist zwischen den Parteien verbindlich.",
  laSolist_company: "laSolist Yazılım Eğitim Bilişim Anonim Şirketi",

  distance_latest_update_date: "Zuletzt aktualisiert: 07/02/2023",
  distance_sides_header: "Seiten",
  distance_sides_alt_p1: "Dieser Vertrag wird zwischen den folgenden Parteien zu den folgenden Bedingungen geschlossen:",
  distance_seller_address: "Adresse: [Adresse der Verkäuferfirma]",
  distance_seller_telephone: "Telefon: [Telefonnummer des Verkäufers]",
  distance_seller_mail: "E-Mail: mail@lasolist.com",
  distance_seller_tax_number: "Steuernummer: [Steuernummer des Verkäufers]",
  distance_seller_alt: "(Kunde wird im Folgenden als 'Verkäufer' bezeichnet)",
  distance_buyer_name_placeholder: "[Vorname Nachname des Käufers]",
  distance_buyer_address: "Adresse: [Adresse des Käufers]",
  distance_buyer_telephone: "Telefon: [Telefonnummer des Käufers]",
  distance_buyer_mail: "E-Mail: [E-Mail-Adresse des Käufers]",
  distance_buyer_alt: "(Kunde wird im Folgenden als 'Käufer' bezeichnet)",
  distance_sides_alt_p2: "Dieser Vertrag bezieht sich auf den Verkauf und die Lieferung der folgenden Produkte oder Dienstleistungen durch den Käufer in Bezug auf Fernverkäufe über die vom Verkäufer betriebene Plattform",
  product_info_header: "Informationen zum Produkt/Dienstleistung",
  product_info_alt: "Der Verkäufer wird dem Käufer das folgende Produkt oder die folgende Dienstleistung liefern:",
  product_info_1: "Produkt-/Dienstleistungsname:",
  product_info_2: "Produkt/Dienstleistung Menge:",
  product_info_3: "Produkt/Dienstleistung Preis:",
  product_info_4: "Liefertermin:",
  order_and_delivery_header: "Bestellung und Lieferung",
  order_and_delivery_alt:
    "Der Käufer hat das vom Verkäufer angebotene Produkt oder die Dienstleistung durch Ausfüllen des Bestellformulars auf der Plattform bestellt. Der Verkäufer verpflichtet sich, dem Käufer das Produkt/die Dienstleistung zum angegebenen Liefertermin nach Erhalt der Bestellung zu liefern.",
  pricing_and_payment_requirements_header: "Preis- und Zahlungsanforderungen",
  pricing_and_payment_requirements_alt:
    "Der Preis der Ware/Dienstleistung ist der zum Zeitpunkt der Bestellung angegebene Betrag. Der Käufer verpflichtet sich, den für das Produkt/die Dienstleistung festgesetzten Preis über die Plattform oder andere angegebene Zahlungsmethoden während der Kaufabwicklung zu zahlen.",
  distance_cancel_header: "Stornierung und Rückgabe",
  distance_cancel_alt:
    "Der Käufer erklärt sich damit einverstanden, die vom Verkäufer festgelegten Rückgabeverfahren einzuhalten, falls das gelieferte Produkt/die Dienstleistung zurückgegeben werden muss. Der Käufer trägt die Versandkosten für die Rücksendung des Produkts/der Dienstleistung auf eigene Kosten",
  distance_guarantee_header: "Garantie",
  distance_guarantee_alt: "Der Verkäufer verpflichtet sich, dem Käufer einen kostenlosen Umtausch oder eine Rückerstattung zu gewähren, wenn sich das verkaufte Produkt/die verkaufte Dienstleistung als fehlerhaft oder beschädigt erweist.",
  distance_effect_header: "Wirksam",
  distance_effect_alt: "Diese Vereinbarung tritt mit der elektronischen Annahme durch die Parteien in Kraft.",
  distance_dispute_header: "Streitfall",
  distance_dispute_alt:
    "Im Falle von Streitigkeiten über die Anwendung oder Auslegung dieser Vereinbarung werden die Parteien zunächst gütlich über eine Lösung verhandeln. Scheitern die Verhandlungen, so unterliegt die Streitigkeit der Zuständigkeit der Istanbuler Gerichte, die den Gesetzen der Republik Türkei unterliegen.",
  other_provisions_header: "Sonstige Bestimmungen",
  other_provisions_alt_p1: "Diese Vereinbarung ist eine ergänzende und verbindliche Vereinbarung zwischen den Parteien. Sollte sich eine Bestimmung dieser Vereinbarung als rechtswidrig oder ungültig erweisen, so berührt dies die Gültigkeit der übrigen Bestimmungen nicht.",
  other_provisions_alt_p2: "Diese Vereinbarung wird im Einvernehmen zwischen den Parteien am [Datum] geschlossen: [Datum].",
  seller: "Verkäufer:",
  sign: "Unterschrift:",
  buyer: "Käufer:",

  aboutUsHeader: "Über uns",
  aboutUs_p1: "Hallo! Wir sind hier, um Sie bei Ihrem Schritt in die faszinierende Welt der Musik zu begleiten. Als LaSolist haben wir uns zum Ziel gesetzt, als Online-Bildungsplattform Baglama-Enthusiasten und -Liebhaber auf eine Reise in die Tiefen der Kunst mitzunehmen.",
  aboutUs_p2:
    "Das Spielen der Baglama ist ein Abenteuer, das zu den Wurzeln der traditionellen türkischen Musik zurückführt. Indem wir dieses traditionelle Instrument mit den Möglichkeiten der modernen Technologie kombinieren, wollen wir es jedem ermöglichen, dieses wunderschöne Instrument zu erlernen. Wir bieten unseren Schülern einen detaillierten Lehrplan, der vom Anfänger bis zum Fortgeschrittenen reicht, und begleiten sie bei jedem Schritt auf ihrem Weg",
  aboutUs_p3:
    "Bei LaSolist lehren wir nicht nur, wie man die Baglama spielt, sondern öffnen unseren Schülern auch ein Fenster in die Tiefen der Kunst und Kultur. Die Baglama wird nicht nur von Noten genährt, sondern auch von der reichen Geschichte, der emotionalen Tiefe und dem Erbe der türkischen Musik. Deshalb ermutigen wir unsere Schüler in jedem unserer Unterrichtsstunden, ihre musikalischen Fähigkeiten zu entwickeln, und bieten ihnen gleichzeitig eine kulturelle Erfahrung",
  aboutUs_p4: "Als LaSolist-Familie glauben wir an das Potenzial jedes unserer Schüler und freuen uns, sie auf ihrer musikalischen Reise zu begleiten. Komm zu uns und entdecke die Kraft der Musik, indem du in die magische Welt der Baglama eintauchst.",
  aboutUs_p5: "Starten Sie noch heute Ihr Baglama-Abenteuer und begleiten Sie uns auf der magischen Reise der Musik!",
  aboutUs_p6: "Mit Liebe,",
  aboutUs_p7: "LaSolist Team",
  confirmationCodeDoesntMatch: "Ihr E-Mail-Bestätigungscode stimmt nicht überein, bitte senden Sie den Code erneut und versuchen Sie es erneut",
  wrongCodePasted: "Falscher Code eingegeben",

  cookies_and_privacy_header: "Cookie-Nutzung und Datenschutzrichtlinie",
  cookies_and_privacy_alt_p1: "Unsere Website verwendet Cookies, um eine bessere Nutzererfahrung zu ermöglichen und unsere Website zu verbessern. Cookies erfüllen Funktionen wie die Wiedererkennung von Benutzern und die Speicherung ihrer Präferenzen.",
  cookies_and_privacy_alt_p2: "Sie können die Links auf der Seite besuchen, um weitere Informationen über Cookies zu erhalten.",
  our_user_agreement: "Unsere Nutzungsvereinbarung",
  our_privacy: "Unsere Datenschutzrichtlinie",
  our_cookies: "Unsere Cookie-Richtlinie",
  und: "und",
  acceptPrivacyUser: "Akzeptieren",

  updateHeader: "Wir fügen neue Funktionen hinzu, um die Benutzerfreundlichkeit zu verbessern.",

  pricing_header: "Wählen Sie den besten Tarif für Sie",
  pricing_description: "Entdecken Sie unsere Tarife und finden Sie denjenigen, der Ihren Bedürfnissen am besten entspricht.",

  forStudent: "Für Studenten?",

  /* Abschnitt Tutorial */
  skip: "Überspringen",
  back: "Zurück",
  finish: "Weiter",

  tutorialHeader: "Willkommen bei der laSolist Player Tour",
  tutorialInhalt:
    "Willkommen beim laSolist Player! In dieser Tour wirst du die grundlegenden Funktionen und die Verwendung unserer Anwendung kennenlernen. Während der Tour erhälst du detaillierte Informationen und visuelle Anleitungen zu jeder Funktion. Wenn du bereit bist, kannst du die Tour starten, indem du auf die Schaltfläche 'Weiter' klickst.",

  backButtonTitle: "Zurück-Schaltfläche",
  backButtonContent: "Durch Drücken dieser Schaltfläche kannst du zur Songliste zurückkehren. Die Titelliste enthält alle verfügbaren Titel und Kategorien.",

  videoNameTitle: "Videoname",
  videoNameContent: "Hier siehst du den Namen des Liedes, das gerade abgespielt wird.",

  tutorialButtonTitle: "App-Tour-Schaltfläche",
  tutorialButtonContent: "Wenn du Schwierigkeiten bei der Bedienung der Anwendung hast, kannst du hier detaillierte Informationen abrufen. Der Tourguide hilft dir, indem er alle Funktionen unserer Anwendung Schritt für Schritt erklärt.",

  controlsTitle: "Video-Steuerelemente",
  controlsContent: "Mit den Video-Steuerelementen kannst du grundlegende Videofunktionen wie Abspielen, Anhalten, Vorspulen und Zurückspulen steuern. Du kannst hier auch Audiosteuerungen vornehmen, wie z. B. die Lautstärke ein-/ausschalten und die Lautstärke anpassen.",

  rewinButtonTitle: "Zurückspulen",
  rewinButtonContent: "Klick auf diese Schaltfläche, um das Video 5 Sekunden zurückzuspulen.",

  returnStartButtonTitle: "Zurück zum Anfang",
  returnStartButtonContent: "Du kannst zum Anfang des Videos zurückkehren, indem du auf diese Schaltfläche klickst.",

  playPauseButtonTile: "Abspielen/Pause",
  playPauseButtonContent: "Klick auf diese Schaltfläche, um das Video abzuspielen oder anzuhalten.",

  forwardButtonTitle: "Schneller Vorlauf",
  forwardButtonContent: "Du kannst das Video 5 Sekunden vorspulen, indem du auf diese Schaltfläche klickst.",

  muteButtonTitle: "Lautstärke ein/aus",
  muteButtonContent: "Klicke auf diese Schaltfläche, um das Video stumm zu schalten oder die Stummschaltung aufzuheben.",

  volumeSliderTitle: "Lautstärke",
  volumeSliderContent: "Mit diesem Schieberegler kannst die Lautstärke des Videos einstellen.",

  colorSwitchTitle: "Farbhinweise",
  colorSwitchContent: "laSolist Player verwendet das System der farbigen Noten, damit du Noten schneller lernen können. Jede Note wird in einer anderen Farbe dargestellt, so dass sie leicht unterschieden werden kann. Diese Funktion ist besonders für Anfänger nützlich.",

  coloredNoteTitle: "Farbige Notenleiste",
  coloredNoteContent: "Der farbige Notenbalken zeigt die Farbe der gerade gespielten Note an. Das macht es einfacher, den Noten zu folgen und zu sehen, welche Noten du spielen müssen.",

  coloredVideoTitle: "Farbiges Video",
  coloredVideoContent: "Farbvideos machen Noten und Saiten visuell verständlicher. Im Video werden die gespielten Noten in der oberen linken Ecke angezeigt, während die angeschlagenen Saiten in der unteren rechten Ecke mit einer Nahaufnahmekamera gezeigt werden.",

  colorSwitchEndTitle: "Farbige Noten",
  colorSwitchEndContent: "Drücke erneut, um normale Noten zu sehen.",

  loopButtonTitle: "Schleifen-Eigenschaft",
  loopButtonContent: "Mit der Schleifenfunktion kann ein bestimmter Abschnitt wiederholt abgespielt werden. Auf diese Weise kannst du Teile, mit denen due Schwierigkeiten hast oder die du lernen möchten, leichter üben.",

  loopRangeTitle: "Schleifenbereich",
  loopRangeContent: "Nach Eingabe der Schleife wird der Schleifenbereich bis zu einer Größe von 1 erstellt.",

  sliderStartTitle: "Schleifenstart",
  sliderStartContent: "Dies ist der Startpunkt der Schleife. Du kannst diesen Punkt mit dem Schieberegler ändern.",

  sliderEndTitle: "Schleifenende",
  sliderEndContent: "Dies ist der Endpunkt der Schleife. Du kannst diesen Punkt mit dem Schieberegler ändern.",

  loopButtonEndTitle: "Schleifen-Eigenschaft",
  loopButtonEndContent: "Drücke erneut, um die Schleife zu beenden.",

  settingsButtonTitle: "Einstellungs-Schaltfläche",
  settingsButtonContent: "Klicke auf diese Schaltfläche, um das Einstellungsmenü aufzurufen. In diesem Menü kannst du verschiedene Einstellungen wie die Videoqualität, die Videogeschwindigkeit und den Vollbildmodus ändern.",

  qualityTitle: "Qualitätsoption",
  qualityContent: "Verwende dieses Menü, um die Qualität des Videos auszuwählen. Eine höhere Qualität bietet ein klareres Bild, verbraucht aber mehr Internetdaten.",

  qualityMenuTitle: "Qualitätsmenü",
  qualityMenuContent: "Das Qualitätsmenü zeigt alle verfügbaren Videoqualitätsoptionen an. Klicke, um die gewünschte Option auszuwählen.",

  qualityBackTitle: "Zurück zum Einstellungsmenü",
  qualityBackContent: "Klicke auf diese Schaltfläche, um zum Einstellungsmenü zurückzukehren.",

  speedTitle: "Geschwindigkeitsoption",
  speedContent: "Verwende dieses Menü, um die Abspielgeschwindigkeit des Videos auszuwählen. Mit einer höheren Geschwindigkeit wird das Video schneller abgespielt, ist aber möglicherweise schwieriger zu lernen.",

  speedMenuTitle: "Geschwindigkeitsmenü",
  speedMenuContent: "Das Geschwindigkeitsmenü zeigt alle verfügbaren Optionen für die Videogeschwindigkeit an. Klicke, um die gewünschte Option auszuwählen.",

  speedBackTitle: "Zurück vom Geschwindigkeitsmenü",
  speedBackContent: "Klicke auf diese Schaltfläche, um zum Einstellungsmenü zurückzukehren.",

  fullScreenButtonTitle: "Vollbildmodus",
  fullScreenButtonContent: "Klicke auf diese Schaltfläche, um das Video und die Noten im Vollbildmodus anzuzeigen. Der Vollbildmodus bietet ein noch intensiveres Erlebnis.",

  settingsMenuCloseTitle: "Schließen des Einstellungsmenüs",
  settingsMenuCloseContent: "Klicke auf diese Schaltfläche, um das Einstellungsmenü zu schließen.",

  tutorialEndTitle: "Beenden der Tour",
  tutorialEndContent: "Gratuliere! Du hast die laSolist Player Tour abgeschlossen! Du bist nun bereit, unsere App zu benutzen. Wenn du noch Fragen hast, wenden dich bitte an das Hilfe-Center in der App oder an die laSolist Player-Website.",

  /* src/pages/main/footer/MainFooter.jsx */
  links: "Quick Links",
  company: "Unternehmen",
  legal: "Rechtlich",

  couponCode: "Gutschein-Code",
  enterCouponCode: "Gutscheincode eingeben",
  apply: "Anwenden",
  total: "Gesamt",
  summary: "Zusammenfassung",
  originalPrice: "Originalpreis",
  discountAmount: "Angewandter Rabatt",
  couponSuccessHeader: "Der Gutscheincode wurde erfolgreich angewendet.",
  couponSuccessAlt: "Sie haben Ihren Gutscheincode erfolgreich eingegeben und eine 1-monatige Mitgliedschaft erhalten. Sie können unsere App zwischen den folgenden Daten nutzen!",
  couponNotFoundHeader: "Ungültiger Gutscheincode.",
  couponNotFoundAlt: "Stellen Sie sicher, dass Ihr Gutscheincode korrekt ist. Achten Sie auf Groß- und Kleinschreibung und fügen Sie keine Leerzeichen in den Code ein.",

  settingsCollapseName: "Einstellungen",
  settingsCollapseKey: "Einstellungen & Konfigurationen",
  settingsName: "Einstellungen",
  settingsRoute: "/einstellungen",
  settingsKey: "Einstellungen",

  configurationName: "Konfigurationen",
  configurationRoute: "/konfigurationen",
  configurationKey: "Konfigurationen",

  previewPlanHeader: "Planvorschau",
  previewPlanAlt: "Informationen über das von Ihnen ausgewählte Paket finden Sie unten",
};

export default locale;
