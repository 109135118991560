import React from 'react'
import { useSelector } from 'react-redux'

function BlueArea() {
  const { loopData, videoData } = useSelector((state) => state.videoPlayer);

  if (loopData.isLoop) {
    return <div className='BlueArea' style={{ width: `${loopData.loopWidth}px` }}>
      {
        !videoData.videoIndicator && <div className='WhiteArea'
          style={{
            animation: `expandWidth ${(loopData.loopEnd - loopData.loopStart) * 1000}ms linear`,
            animationPlayState: videoData.isPlaying ? "running" : "paused",
          }}
        />
      }
    </div>
  }

  return (
    <div className='BlueArea' />
  )
}

export default BlueArea