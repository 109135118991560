import { Icon, Tooltip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function MuteButton({ setVolume }) {

  const { t } = useTranslation();

  const { videoData } = useSelector((state) => state.videoPlayer);

  const handleMute = () => {
    if (videoData.volume === 0) {
      setVolume(1)
    }
    else {
      setVolume(0)
    }
  }

  return (
    <Tooltip title={videoData.volume > 0 ? t("TurnOffVolume") : t("TurnOnVolume")}>
      <button className='MuteButton' onClick={handleMute}>
        {
          videoData.volume > 0 ? <Icon color="white">volume_up</Icon> : <Icon color="white">volume_mute</Icon>
        }
      </button>
    </Tooltip>
  )
}

export default MuteButton