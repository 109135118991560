/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


/** 
 All of the routes for the Material Dashboard 2 PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.
 
 Once you add a new route on this file it will be visible automatically on
 the Sidenav.
 
 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav. 
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Components
import ForgotPassword from "pages/auth/ForgotPassword";
import InstructorApplication from "pages/auth/InstructorApplication";
import ResetPassword from "pages/auth/ResetPassword";
import Cookies from "pages/contracts/Cookies";
import DeliveryAndRefund from "pages/contracts/DeliveryAndRefund";
import DistanceSelling from "pages/contracts/DistanceSelling";
import Privacy from "pages/contracts/Privacy";
import UserAgreement from "pages/contracts/UserAgreement";
import LandingPage from "pages/landing/LandingPage";
import AboutUs from "pages/other/AboutUs";
import FAQs from "pages/other/FAQs";
import Home from "assets/icons/home.svg";
import SignUp from "pages/auth/SignUp/SignUp";
import Contact from "pages/other/Contact/Contact";


const routes = [
  {
    name: "home",
    icon: <img style={{height: "24px"}} src={Home} alt="home"></img>,
    route: "/",
    key: "home",
    component: <LandingPage />,
  },
  {
    type: "collapse",
    name: "forgotPasswordName",
    route: "forgotPasswordRoute",
    key: "forgotPassforgowordKey",
    component: <ForgotPassword />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Reset Password",
    route: "resetPasswordRoute",
    key: "Reset Password",
    component: <ResetPassword />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign Up Register",
    route: "signUpRoute",
    key: "Sign Up Register",
    component: <SignUp />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "applicationName",
    route: "applicationRoute",
    key: "applicationKey",
    component: <InstructorApplication />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "cookiesName",
    route: "cookiesRoute",
    key: "cookiesKey",
    component: <Cookies />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "privacyName",
    route: "privacyRoute",
    key: "privacyKey",
    component: <Privacy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "userAgreementName",
    route: "userAgreementRoute",
    key: "userAgreementKey",
    component: <UserAgreement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "distanceSellingName",
    route: "distanceSellingRoute",
    key: "distanceSellingKey",
    component: <DistanceSelling />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "deliveryAndRefundName",
    route: "deliveryAndRefundRoute",
    key: "deliveryAndRefundKey",
    component: <DeliveryAndRefund />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "aboutUsName",
    route: "aboutUsRoute",
    key: "aboutUsKey",
    component: <AboutUs />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "contactsName",
    route: "contactsRoute",
    key: "contactsKey",
    component: <Contact />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "pricingName",
    route: "pricingRoute",
    key: "pricingKey",
    component: <AboutUs />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "faqsName",
    route: "faqsRoute",
    key: "faqsKey",
    component: <FAQs />,
    noCollapse: true,
  }
];

export default routes;
