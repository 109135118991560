import MDButton from 'components/MDButton';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import MDBox from 'components/MDBox';
import { checkSubscription } from 'store/payment/paymentsSlice';
import { useTranslation } from 'react-i18next';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';


import axiosConfig from "features/api/axiosConfig";
import { clientId } from 'features/api/paypalConfig';

;

function PayPalCheckout({ handleBack }) {
  const dispatch = useDispatch();

  const { userInfo, isSubscribed } = useSelector((state) => state.subscriptions);
  const { userName } = useSelector((state) => state.auth);

  const { t } = useTranslation();

  const handleBackStep = () => {

    handleBack(2);
  };

  const initialOptions = {
    clientId: clientId,
    currency: "USD",
    vault: "true",
    intent: "subscription",
  };

  return (
    <div>
      <MDBox sx={{ width: "50%", margin: "0 auto" }}>
        <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            createSubscription={async () => {
              try {
                let subscription = {
                  pricingPlanReference: userInfo.pricingPlanReferenceCode,

                }
                const response = await axiosConfig.post(`/api/paypal/subscriptions/`, subscription);

                const data = await response.data;
                console.log(data);
                return data.id;
              } catch (error) {
                console.log(error);
              }
            }}
            onApprove={async (data, actions) => {
              /*
                No need to activate manually since SUBSCRIBE_NOW is being used.
                Learn how to handle other user actions from our docs:
                https://developer.paypal.com/docs/api/subscriptions/v1/#subscriptions_create
              */
              if (data.orderID) {
                console.log("Vallaha oldu", data);
                let check = {
                  subscriptionId: data.subscriptionID,
                  userName,
                }

                const response = await axiosConfig.post(`/api/payment/subscriptions/check-paypal-subscription`, check);
                const status = await response.data;
                if (status.success) {
                  dispatch(checkSubscription({ userName }));
                }
              } else {
                console.log("hata");
              }
            }}
          />
        </PayPalScriptProvider>
      </MDBox>
      <MDBox>
        <MDButton variant="outlined" color="dark" onClick={handleBackStep}>
          {t("goBack")}
        </MDButton>
      </MDBox>
    </div>
  )
};

export default PayPalCheckout;
