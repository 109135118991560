import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";

export const getPricingPlans = createAsyncThunk(
  "iyzicoPricingPlans/getPricingPlans",
  async () => {
    const response = await axiosConfig.get(`/api/pricing-plans`);
    let { items } = await response.data.result.data;
    return items;
  }
);

const pricingPlansAdapter = createEntityAdapter({
  selectId: (pricingPlan) => pricingPlan.referenceCode,
});

export const {
  selectAll: selectPricingPlans,
  selectById: selectPricingPlanById,
} = pricingPlansAdapter.getSelectors((state) => state.iyzicoPricingPlans);

const pricingPlanSlice = createSlice({
  name: "iyzicoPricingPlans",
  initialState: pricingPlansAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    pricingPlanDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setPricingPlanSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewPricingPlanDialog: (state, action) => {
      state.pricingPlanDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewPricingPlanDialog: (state, action) => {
      state.pricingPlanDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditPricingPlanDialog: (state, action) => {
      state.pricingPlanDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditPricingPlanDialog: (state, action) => {
      state.pricingPlanDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [getPricingPlans.fulfilled]: pricingPlansAdapter.setAll,
  },
});

export const {
  setPricingPlanSearchText,
  openNewPricingPlanDialog,
  closeNewPricingPlanDialog,
  openEditPricingPlanDialog,
  closeEditPricingPlanDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = pricingPlanSlice.actions;

export default pricingPlanSlice.reducer;