import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";

export const getLiveSupports = createAsyncThunk(
  "liveSupports/getLiveSupports",
  async () => {
    const response = await axiosConfig.get(`/api/live-supports`);
    let { data } = await response.data;
    return data;
  }
);

export const getLiveSupportsByQuery = createAsyncThunk(
  "liveSupports/getLiveSupportsByQuery",
  async (_, { dispatch, getState }) => {
    const userName = getState().auth.userName;
    const response = await axiosConfig.get(`/api/live-supports/query?userName=${userName}`);
    let { data } = await response.data;
    console.log("dataa", data);
    return data;
  }
);

export const addLiveSupport = createAsyncThunk(
  "liveSupports/addLiveSupport",
  async (liveSupport, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(
        `/api/live-supports`,
        liveSupport
      );
      let { data } = await response.data;
      if (response.data.success === true) {
        return data;
      }
    } catch (error) {
      return null;
    }
  }
);

export const updateLiveSupport = createAsyncThunk(
  "liveSupports/updateLiveSupport",
  async (liveSupport, { dispatch, getState }) => {


    const response = await axiosConfig.put(
      `/api/live-supports/${liveSupport.supportId}`,
      liveSupport
    );
    const { data } = await response.data;
    if (response.data.success === true) {
      return data;
    }
    return null;
  }
);

export const removeLiveSupport = createAsyncThunk(
  "liveSupports/removeLiveSupport",
  async (supportId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/live-supports/${supportId}`);
    if (response.data.success === true) {
      return supportId;
    }
    return supportId;
  }
);

const liveSupportsAdapter = createEntityAdapter({
  selectId: (liveSupport) => liveSupport.liveSupportId,
});

export const {
  selectAll: selectLiveSupports,
  selectById: selectLiveSupportsById,
} = liveSupportsAdapter.getSelectors((state) => state.liveSupports);

const liveSupportsSlice = createSlice({
  name: "liveSupports",
  initialState: liveSupportsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    liveSupportDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setLiveSupportsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewLiveSupportDialog: (state, action) => {
      state.liveSupportDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewLiveSupportDialog: (state, action) => {
      state.liveSupportDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditLiveSupportDialog: (state, action) => {
      state.liveSupportDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditLiveSupportDialog: (state, action) => {
      state.liveSupportDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateLiveSupport.fulfilled]: liveSupportsAdapter.upsertOne,
    [addLiveSupport.fulfilled]: liveSupportsAdapter.addOne,
    [removeLiveSupport.fulfilled]: (state, action) =>
      liveSupportsAdapter.removeOne(state, action.payload),
    [getLiveSupports.fulfilled]: liveSupportsAdapter.setAll,
    [getLiveSupportsByQuery.fulfilled]: liveSupportsAdapter.setAll,
  },
});

export const {
  setLiveSupportsSearchText,
  openNewLiveSupportDialog,
  closeNewLiveSupportDialog,
  openEditLiveSupportDialog,
  closeEditLiveSupportDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = liveSupportsSlice.actions;

export default liveSupportsSlice.reducer;
