/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FaqCollapse from "./FaqCollapse";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";



function Faq() {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(false);

  return (
    <MDBox my={14}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={11}>
          <MDTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
            {t("faq_header")}
          </MDTypography>
          <MDBox mb={2}>
            <MDTypography variant="body2" align="center" color="text">
              {
                t("faq_description")
              }
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={11}>
          <FaqCollapse
            title={t("question1")}
            open={collapse === 1}
            onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
          >
            {t("answer1_1")}
            {t("answer1_2") !== "answer1_2" && t("answer1_2")}
          </FaqCollapse>
          <FaqCollapse
            title={t("question2")}
            open={collapse === 2}
            onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
          >
            {t("answer2_1")}
            {t("answer2_2") !== "answer2_2" && t("answer2_2")}
          </FaqCollapse>
          <FaqCollapse
            title={t("question3")}
            open={collapse === 3}
            onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
          >
            {t("answer3_1")}
            {t("answer3_2") !== "answer3_2" && t("answer3_2")}
          </FaqCollapse>
          <FaqCollapse
            title={t("question4")}
            open={collapse === 4}
            onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
          >
            {t("answer4_1")}
            {t("answer4_2") !== "answer4_2" && t("answer4_2")}
          </FaqCollapse>
          <FaqCollapse
            title={t("question5")}
            open={collapse === 5}
            onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
          >
            {t("answer5_1")}
            {t("answer5_2") !== "answer5_2" && t("answer5_2")}
          </FaqCollapse>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Faq;
