import { Card } from '@mui/material';
import React, { useState } from 'react'
import PropTypes from "prop-types";
import MDBox from 'components/MDBox';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { addVideoAnalyze } from 'store/analytics/videoAnalyzeSlice';
import { Link } from 'react-router-dom';
import FavoriteButton from './components/FavoriteButton';
import Description from './components/Description';
import CoverWithPreviewVideo from './components/CoverWithPreviewVideo';
import Cover from './components/Cover';
import { setVideoData } from 'store/main/videoPlayerSlice';

function DefaultCard(props) {
  const { details } = props;

  const dispatch = useDispatch();
  const { userName, userType } = useSelector((state) => state.auth);
  const { isTrial } = useSelector((state) => state.payments);
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (isTrial) {

      if (isPlayableContent() && details.isTrial) {
        let data = {
          videoId: details.videoId,
          courseId: details.courseId,
          type: details.type,
          userName,
          userType,
        }
        dispatch(addVideoAnalyze(data));
      }
    }
    else {
      if (isPlayableContent()) {
        let data = {
          videoId: details.videoId,
          courseId: details.courseId,
          type: details.type,
          userName,
          userType,
        }
        dispatch(addVideoAnalyze(data));
      }
    }
  }

  const handleMouseEnter = () => {
    if (isTrial) {
      if (isPlayableContent() && details.isTrial) {
        setIsHovered(true);
      }
    }
    else {
      if (isPlayableContent()) {
        setIsHovered(true);
      }
    }
  }

  const handleMouseLeave = () => {
    if (isTrial) {
      if (isPlayableContent() && details.isTrial) {
        setIsHovered(false);
        dispatch(setVideoData({ buffer: 0 }))
      }
    }
    else {
      if (isPlayableContent()) {
        setIsHovered(false);
        dispatch(setVideoData({ buffer: 0 }))
      }
    }
  }

  const renderCover = () => {

    if (isPlayableContent()) {
      return <CoverWithPreviewVideo {...props} isHovered={isHovered} />
    }
    else {
      return <Cover {...props} />
    }
  }

  const isPlayableContent = () => {
    const { type } = details;
    if (type === "song" || type == "lesson" || type === "instructorLesson") {
      return true;
    }
    return false;
  }
  return (
    <Link to={details.route}>
      <Card
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        sx={{
          height: "100%",
          padding: 2,
          borderRadius: 7,
          cursor: "pointer"
        }}
      >
        {renderCover()}

        <MDBox
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={1}
        >
          <Description  {...props} />
        </MDBox>
      </Card>
    </Link >
  )
}

// Typechecking props for the SimpleBlogCard
DefaultCard.propTypes = {
  details: PropTypes.shape({
    type: PropTypes.oneOf([
      "song",
      "course",
      "lesson",
      "instructorCourse",
      "instructorLesson",
    ]).isRequired,
    route: PropTypes.string.isRequired,
    views: PropTypes.number,
  }),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  videoId: PropTypes.number.isRequired,
  favoriteId: PropTypes.number,
};

export default DefaultCard