import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  closeEditPricingPlanDialog,
  closeNewPricingPlanDialog,
} from "store/iyzico/iyzicoPricingPlansSlice";
import {
  getProducts
} from "store/iyzico/iyzicoProductsSlice";
import { selectiyzicoproducts } from "store/iyzico/iyzicoProductsSlice";

const defaultFormState = {
  pricingPlanReferenceCode: 0,
  name: "",
  price: 0,
  paymentInterval: "",
  paymentIntervalCount: 0,
  trialPeriodDays: 0,
};

const paymentIntervalOptions = [
  { id: "DAILY,", name: "DAILY" },
  { id: "WEEKLY", name: "WEEKLY" },
  { id: "MONTHLY", name: "MONTHLY" },
  { id: "YEARLY ", name: "YEARLY " },
];

function IyzicoPricingPlanDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const pricingPlanDialog = useSelector(
    ({ iyzicoPricingPlans }) => iyzicoPricingPlans.pricingPlanDialog
  );

  const { form, handleChange, setForm } = useForm(defaultFormState);

  const products = useSelector(selectiyzicoproducts);
  const [paymentIntervalValue, setPaymentIntervalValue] = useState({
    id: "MONTHLY",
    name: "MONTHLY",
  });

  const [productValue, setProductValue] = useState({
    id: "",
    name: "",
  })

  const initDialog = useCallback(async () => {
    /**
     * Dialog type: 'edit'
     */
    if (pricingPlanDialog.type === "edit" && pricingPlanDialog.data) {
      setForm({ ...pricingPlanDialog.data });
      let product = products.find(
        (f) => f.referenceCode === pricingPlanDialog.data.productReferenceCode
      );


      console.log(product)
      setProductValue(product);
    }

    /**
     * Dialog type: 'new'
     */
    if (pricingPlanDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...pricingPlanDialog.data,
      });
    }
  }, [pricingPlanDialog.data, pricingPlanDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (pricingPlanDialog.props.open) {
      initDialog();
    }
  }, [pricingPlanDialog.props.open, initDialog]);

  function closePricingPlanDialog() {
    return pricingPlanDialog.type === "edit"
      ? dispatch(closeEditPricingPlanDialog())
      : dispatch(closeNewPricingPlanDialog());
  }

  function handleSubmit(event) {
    event.preventDefault();

    // if (pricingPlanDialog.type === "new") {
    //   let data = {
    //     ...form,
    //     productReferenceCode: productValue.referenceCode
    //   }
    //   dispatch(addPricingPlan(data)).then(() => {
    //     dispatch(getProducts());
    //   });
    // } else {
    //   dispatch(updatePricingPlan(form)).then(() => {
    //     dispatch(getProducts());
    //   });
    // }
    closePricingPlanDialog();
  }

  function canBeSubmitted() {
    return (
      form.name.length > 0 &&
      form.price > 0 &&
      form.paymentIntervalCount >= 0 &&
      form.trialPeriodDays >= 0
    );
  }

  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...pricingPlanDialog.props}
      onClose={closePricingPlanDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="pricing-plan-dialog-title">{t("pricingPlanInfo")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="pricingPlanReferenceCode"
          name="pricingPlanReferenceCode"
          label={t("pricingPlanReferenceCode")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.pricingPlanReferenceCode}
          disabled
        />
        <MDBox my={1}>
          <Autocomplete
            value={productValue}
            options={products}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("productName")} />
            )}
            onChange={(event, newValue) => {
              console.log(newValue);
              if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                setProductValue({
                  id: newValue.referenceCode, // newValue.id -> newValue olarak değiştirildi
                  name: newValue.name,
                });
              } else if (newValue && newValue.inputValue) {
                setProductValue({
                  name: newValue.inputValue,
                });
              } else {
                setProductValue(newValue);
              }
              console.log(productValue);
            }}
          />
        </MDBox>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label={t("pricingPlanName")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.name}
          onChange={handleChange}
          required
          error={form.name ? false : true}
          helperText={t("enterPricingPlanName")}
        />
        <TextField
          autoFocus
          margin="dense"
          id="price"
          name="price"
          label={t("price")}
          type="number"
          fullWidth
          variant="outlined"
          value={form.price}
          onChange={handleChange}
          required
          error={form.price ? false : true}
          helperText={t("enterPrice")}
        />
        <MDBox my={1}>
          <Autocomplete
            value={paymentIntervalValue} // Dikkat: statusValues -> statusValue olarak değiştirildi
            options={paymentIntervalOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("paymentInterval")} />
            )}
            onChange={(event, newValue) => {
              console.log(newValue);
              if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                setPaymentIntervalValue({
                  id: newValue, // newValue.id -> newValue olarak değiştirildi
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                setPaymentIntervalValue({
                  name: newValue.inputValue,
                });
              } else {
                setPaymentIntervalValue(newValue);
              }
            }}
          />
        </MDBox>
        <TextField
          autoFocus
          margin="dense"
          id="paymentIntervalCount"
          name="paymentIntervalCount"
          label={t("paymentIntervalCount")}
          type="number"
          fullWidth
          variant="outlined"
          value={form.paymentIntervalCount}
          onChange={handleChange}
          required
          error={form.paymentIntervalCount ? false : true}
          helperText={t("enterPaymentIntervalCount")}
        />
        <TextField
          autoFocus
          margin="dense"
          id="trialPeriodDays"
          name="trialPeriodDays"
          label={t("trialPeriodDays")}
          type="number"
          fullWidth
          variant="outlined"
          value={form.trialPeriodDays}
          onChange={handleChange}
          required
          error={form.trialPeriodDays < 0 ? true : false}
          helperText={t("enterTrialPeriodDays")}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={closePricingPlanDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default IyzicoPricingPlanDialog;