import UploadHistory from 'components/UploadComponents/UploadHistory'
import AdminLayout from 'pages/main/layout/AdminLayout'
import React, { useEffect } from 'react'
import Statistics from './Statistics'
import { useDispatch } from 'react-redux'
import { getStatistics } from 'store/main/statisticsSlice'
import LoadingComponent from 'components/Loading'

function AdminDashboard() {
  const dispatch = useDispatch()

  const [loading, setLoading] = React.useState(true)
  useEffect(() => {
    dispatch(getStatistics()).then(() => setTimeout(() => {
      setLoading(false)
    }, 500));
  });

  if (loading) {
    return <AdminLayout>
      <LoadingComponent />
    </AdminLayout>
  }

  return (
    <AdminLayout>
      {/* <UploadHistory /> */}
      <Statistics />
    </AdminLayout>
  )
}

export default AdminDashboard