import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { closeEditFeedbacksDialog } from "store/main/feedbacksSlice";
import { closeNewFeedbacksDialog } from "store/main/feedbacksSlice";
import { addFeedbacks } from "store/main/feedbacksSlice";
import { updateFeedbacks } from "store/main/feedbacksSlice";

const defaultFormState = {
  feedbackId: 0,
  userName: "",
  feedbackTitle: "",
  feedbackDetails: "",
  feedbackReply: "",
};

const statusOptions = [
  { id: "waiting", name: "waiting" },
  { id: "processing", name: "processing" },
  { id: "solved", name: "solved" },
  { id: "rejected", name: "rejected" },
  { id: "cancelled", name: "cancelled" },
];

const feedbackOptions = [
  { id: "problem", name: "problem" },
  { id: "suggestion", name: "suggestion" },
  { id: "complaint", name: "complaint" },
  { id: "general", name: "general" },
]

function FeedbacksDialog(props) {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const feedbackDialog = useSelector(({ feedbacks }) => feedbacks.feedbackDialog);
  const userName = useSelector((state) => state.auth.userName);
  console.log(userName);

  const { form, handleChange, setForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "waiting",
    name: "waiting",
  });

  const [feedbackValues, setFeedbackValue] = useState({
    id: "problem",
    name: "problem",
  })

  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (feedbackDialog.type === "edit" && feedbackDialog.data) {
      setForm({ ...feedbackDialog.data });

      let status = statusOptions.find(
        (f) => f.name === feedbackDialog.data.status
      );
      setStatusValue(status);
    }

    /**
     * Dialog type: 'new'
     */
    if (feedbackDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...feedbackDialog.data,
        userName,
      });
    }
  }, [feedbackDialog.data, feedbackDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (feedbackDialog.props.open) {
      initDialog();
    }
  }, [feedbackDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return feedbackDialog.type === "edit"
      ? dispatch(closeEditFeedbacksDialog())
      : dispatch(closeNewFeedbacksDialog());
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (feedbackDialog.type === "new") {
      let data = {
        ...form,
        userName,
        status: statusValues.id,
        feedbackType: feedbackValues.id,
      };
      dispatch(addFeedbacks(data));
    } else {
      let data = {
        ...form,
        status: statusValues.id,
        feedbackType: feedbackValues.id,
      };
      dispatch(updateFeedbacks(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    return (
      // form.songName.length > 0 &&
      // form.songDescriptionTr.length > 0 &&
      // form.songDescriptionEn.length > 0 &&
      // form.songDescriptionDe.length > 0 &&
      // form.songDescriptionAr.length > 0
      true
    );

  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...feedbackDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">{t("feedbackInfo")}</DialogTitle>
      <DialogContent>
        <MDBox>
          <TextField
            autoFocus
            margin="dense"
            id="feedbackId"
            name="feedbackId"
            label={t("feedbackId")}
            type="text"
            fullWidth
            variant="outlined"
            value={form.feedbackId}
            disabled
          />
        </MDBox>
        <MDBox>
          <TextField
            autoFocus
            margin="dense"
            id="userName"
            name="userName"
            label={t("userName")}
            type="text"
            fullWidth
            variant="outlined"
            value={form.userName}
            disabled
          />
        </MDBox>
        <MDBox>
          <TextField
            autoFocus
            margin="dense"
            id="feedbackTitle"
            name="feedbackTitle"
            label={t("feedbackTitle")}
            type="text"
            fullWidth
            variant="outlined"
            value={form.feedbackTitle}
            onChange={handleChange}
            required
            error={form.feedbackTitle ? false : true}
            helperText={t("enterFeedbackTitle")}
            focused
          />
        </MDBox>
        <MDBox>
          <TextField
            autoFocus
            margin="dense"
            id="feedbackDetails"
            name="feedbackDetails"
            label={t("feedbackDetails")}
            type="text"
            fullWidth
            variant="outlined"
            value={form.feedbackDetails}
            onChange={handleChange}
            required
            error={form.feedbackDetails ? false : true}
            helperText={t("enterFeedbackDetails")}
            focused
            multiline
            rows={2}
          />
        </MDBox>
        <MDBox mt={1}>
          <Autocomplete
            value={feedbackValues}
            options={feedbackOptions}
            getOptionLabel={(option) => t(option.name)}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("feedbackType")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue.name === "string") {
                setFeedbackValue({
                  id: newValue.id,
                  name: newValue.name,
                });
              } else if (newValue && newValue.inputValue) {
                setFeedbackValue({
                  name: newValue.inputValue,
                });
              } else {
                setStatusValue(newValue);
              }
            }}
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FeedbacksDialog;
