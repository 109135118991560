/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useEffect } from "react";
import { loadUser } from "store/auth/authSlice";
import { cancelSubscription } from "store/iyzico/iyzicoSubscriptionsSlice";
import { cancelPaypalSubscription } from "store/paypal/paypalSubscriptionsSlice";
import dayjs from "dayjs";

function CancelSubscription() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { subscriptionsInfo } = useSelector((state) => state.payments);
  const { isTrial } = useSelector((state) => state.payments);

  const [subscription, setSubscription] = useState(false);

  useEffect(() => {
    if (subscriptionsInfo) {
      const active = subscriptionsInfo.find((f) => (f.status === "ACTIVE" || f.status === "CANCELED"));
      setSubscription(active);
    }
  }, [subscriptionsInfo]);



  const [cancelResponse, setCancelResponse] = useState({
    response: false,
    status: "success",
    message: "",
  });

  const handleClick = () => {
    const subscription = subscriptionsInfo.find((f) => (f.status === "ACTIVE" || f.status === "EXPIRED"))
    const { payPlatform } = subscription.pricingPlanInfo;
    console.log(subscription);

    let data = {
      subscriptionReferenceCode: subscription.subscriptionReferenceCode,
    }

    if (payPlatform === "iyzico") {
      dispatch(cancelSubscription(data)).then((params) => {
        if (params.payload.status === "success") {
          setCancelResponse({
            response: true,
            status: "success",
            message: "cancel_subscription_success",
          });
          dispatch(loadUser());
        }
        else {
          setCancelResponse({
            response: true,
            status: "error",
            message: "cancel_subscription_failure",
          });
        }
      })
    }
    else {
      dispatch(cancelPaypalSubscription(data)).then((params) => {
        if (params.payload.success) {
          setCancelResponse({
            response: true,
            status: "success",
            message: "cancel_subscription_success",
          });
          dispatch(loadUser());
        }
        else {
          setCancelResponse({
            response: true,
            status: "error",
            message: "cancel_subscription_failure",
          });
        }
      })
    }




  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCancelResponse({
      response: false,
      status: "",
      message: "",
    })
  };



  return (
    <Card id="cancel-subscription">
      <Snackbar
        open={cancelResponse.response}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={handleClose} severity={cancelResponse.status} sx={{ width: '100%' }}>
          {t(cancelResponse.message)}
        </Alert>
      </Snackbar>
      <MDBox display={"flex"} justifyContent={"space-between"} flexDirection={{ xs: "column", sm: "row" }} alignItems={"center"}>
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">{t("cancel_subscription")}</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="text">
            {
              subscription &&
              subscription.status === "CANCELED" &&
              new Date().getTime() > new Date(subscription.endDate).getTime()
              && t("canceled_subscription_description") + dayjs(subscription.endDate).format("DD.MM.YYYY") + t("canceled_subscription_description2")
            }

            {
              subscription &&
                subscription.status === "CANCELED" &&
                new Date().getTime() < new Date(subscription.endDate).getTime()
                ? t("canceled_subscription_description") + dayjs(subscription.endDate).format("DD.MM.YYYY") + t("canceled_subscription_description2") : t("cancel_subscription_description")
            }
          </MDTypography>
        </MDBox>



        <MDBox pr={{ xs: 0, sm: 3 }} pb={{ xs: 3, sm: 0 }}>
          {
            subscription && (subscription.status === "CANCELED" || subscription.payMethod === "coupon") ? null : <MDButton onClick={handleClick} variant="gradient" color="error" size="small" sx={{ height: "100%" }}>
              {t("cancel_subscription")}
            </MDButton>
          }
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default CancelSubscription;
