import LoadingComponent from "components/Loading";
import AdminLayout from "pages/main/layout/AdminLayout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCategories } from "store/main/categoriesSlice";
import CategoriesTable from "./CategoriesTable";
import CategoryDialog from "./CategoryDialog";
import { removeCategory } from "store/main/categoriesSlice";
import ConfirmDialog from "pages/confirmation/ConfirmDialog";

const AdminCategoriesDashboard = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    dispatch(getCategories()).then(() => setLoading(false));
  }, [dispatch]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <AdminLayout>
      <CategoryDialog />
      <CategoriesTable />
      <ConfirmDialog handleDeleteData={removeCategory} />
    </AdminLayout> 
  );
};


export default AdminCategoriesDashboard;
