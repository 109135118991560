// SocketContext.js
import { socketIoProxy } from 'features/api/proxy';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import { getLiveSupportsByQuery } from 'store/main/liveSupportsSlice';

// Context oluştur
const UserSocketContext = createContext();



// Provider oluştur
export const UserSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [activeSupportRoom, setActiveSupportRoom] = useState(0);
  const dispatch = useDispatch();
  const { userName } = useSelector(state => state.auth);

  useEffect(() => {
    const newSocket = io(socketIoProxy);
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('User Socket Connected');
      newSocket.emit('user_join_room', { userName });

      newSocket.on("admin_joined_room", (data) => {
        console.log("admin_joined_room", data);
        setActiveSupportRoom(data.liveSupportId);
      });

      newSocket.on("refresh_live_supports_user", (data) => {
        dispatch(getLiveSupportsByQuery());
      });

    });

    newSocket.on("connect_error", (err) => {
      console.error("Socket connection error:", err);
    });



    return () => {
      newSocket.off('connect');

      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, []);

  return (
    <UserSocketContext.Provider value={{ socket, activeSupportRoom }}>
      {children}
    </UserSocketContext.Provider>
  );
};
// Hook oluştur
export const useUserSocket = () => {
  const context = useContext(UserSocketContext);
  if (!context) {
    throw new Error('useSocket hook must be used within a UserSocketProvider');
  }
  return context;
};
