import React from 'react'
import { useSelector } from 'react-redux';
import TimeoutComponent from './TimeoutComponent';
import VideoLoading from './VideoLoading';
import { useDispatch } from 'react-redux';

function VideoIndicator({ selectSongState }) {
  const dispatch = useDispatch();

  const {
    videoData,
    loopData,
  } = useSelector((state) => state.videoPlayer);

  console.log(videoData.currentTime, videoData.buffer)

  // useEffect(() => {
  //   if (videoData.isPlaying && videoData.currentTime < videoData.duration - 0.5 && videoData.currentTime > videoData.buffer - 0.5) {
  //     dispatch(setVideoData({ isPlaying: false }));
  //     console.log("useffect firdik");
  //   }
  // }, [videoData.currentTime, videoData.buffer, dispatch, videoData.isPlaying])

  if (videoData.videoIndicator) {
    return <TimeoutComponent selectSongState={selectSongState} />
  }

  if ((!videoData.isPlaying && videoData.isLoading)) {
    return <VideoLoading />
  }

  return null;
}

export default VideoIndicator