import { Icon } from "@mui/material";
import { t } from "i18next";
import toast from "react-hot-toast";


const showToast = (icon, message, status) => {
  return toast(() => (
    <span style={{ fontSize: 16, width: "300px", height: "100%" }}>
      <p style={{ display: "flex", alignItems: "center", marginBottom: 2, fontWeight: "bold" }}>
        <Icon color={status === "success" ? "success" : "error"} sx={{ marginRight: 1 }}>{icon}</Icon> {t(status)}
      </p>

      <p>{t(message)}</p>
    </span>
  ), { position: "bottom-center", duration: 2000 });
};

export default showToast;

