/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples


// Wizard page components
import { Alert, Icon, Snackbar } from "@mui/material";
import MainFooter from "pages/main/footer/MainFooter";
import { useTranslation } from "react-i18next";
import PricingPlans from "./PricingPlansInfo/PricingPlans";
import { Link } from "react-router-dom";
import PayPalUpgradeCheckout from "./checkoutForm/PayPalUpgradeCheckout";
import { useState } from "react";

function Wizard() {
  const { t } = useTranslation();

  const [checkout, setCheckout] = useState({
    isOpen: false,
    data: {},
    feedback: {
      response: false,
      status: "success",
      message: "",
    }
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCheckout({
      isOpen: false,
      data: {},
      feedback: {
        response: false,
        status: "success",
        message: "",
      }
    });
  };


  return (
    <MDBox mx={1}>
      <Snackbar
        open={checkout.feedback.response}
        autoHideDuration={7000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={handleClose} severity={checkout.feedback.status} sx={{ width: '100%' }}>
          {t(checkout.feedback.message)}
        </Alert>
      </Snackbar>
      <MDBox pt={3}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>

          <Grid item xs={12} lg={8}>
            <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
              <Link to={"/profil"}>
                <MDButton color={"error"} variant={"gradient"} sx={{ width: "150px" }}>
                  <Icon sx={{ transform: "scaleX(-1)" }}>logout</Icon>&nbsp;
                  {t("goBack")}
                </MDButton>
              </Link>
              <MDButton sx={{ width: "150px" }} color={"info"} variant={"gradient"}>TR</MDButton>
            </MDBox>
          </Grid>

          <Grid item xs={12} lg={8}>

            <MDBox mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">

                  {t("upgrade_plan_wizard_header")}
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" color="secondary" fontWeight="regular">

                {t("upgrade_plan_wizard_description")}
              </MDTypography>
            </MDBox>
            <Card>

              <MDBox p={2}>
                <MDBox>
                  {
                    checkout.isOpen ? <PayPalUpgradeCheckout checkoutData={checkout.data} setCheckout={setCheckout} /> : <PricingPlans setCheckout={setCheckout} />
                  }
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox >
  );
}

export default Wizard;
