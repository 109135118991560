import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";

export const getPricingPlans = createAsyncThunk(
  "paypalPricingPlans/getPricingPlans",
  async (_, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.get(`/api/paypal/pricing-plans/`);

      const data = await response.data;


      return data;
    } catch (error) {

      return null;
    }
  }
);

const pricingPlansAdapter = createEntityAdapter({
  selectId: (product) => product.id,
});

export const {
  selectAll: selectPaypalPricingPlans,
  selectById: selectPaypalPricingPlansById,
} = pricingPlansAdapter.getSelectors((state) => state.paypalPricingPlans);

const paypalPricingPlansSlice = createSlice({
  name: "paypalPricingPlans",
  initialState: pricingPlansAdapter.getInitialState({
    id: "",
    searchText: "",
    routeParams: {},
  }),
  reducers: {
  },
  extraReducers: {
    [getPricingPlans.fulfilled]: pricingPlansAdapter.setAll,
  },
});



export default paypalPricingPlansSlice.reducer;
