import React, { useState } from 'react';
import { Box, TextField, Button, List, ListItem, ListItemText, Icon, Card, Grid } from '@mui/material';
import MDButton from 'components/MDButton';
import { useForm } from 'features/hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { addMessage } from 'store/main/messagesSlice';
import { io } from 'socket.io-client';
import { getLiveSupportsByQuery } from 'store/main/liveSupportsSlice';
import { useUserSocket } from 'hooks/UserSocketContext';

const defaultFormState = {
  message: "",
};

const SendMessageSection = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { form, handleChange, setForm } = useForm(defaultFormState);
  const { userName } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const { socket } = useUserSocket();

  const handleSubmit = () => {
    console.log(form);
    let data = {
      ...form,
      liveSupportId: id,
      sentFrom: "user",
      userName,
      createdDate: new Date(),
    }
    dispatch(addMessage(data)).then((params) => {
      dispatch(getLiveSupportsByQuery());
      socket.emit('refresh_live_supports', {
        liveSupportId: id,
      });
      setForm(defaultFormState);
    })
  }

  return (
    <Box container spacing={1} sx={{ display: "flex", gap: 2 }}>

      <MDButton variant="gradient" color="dark">
        <Icon>attach_file</Icon>
      </MDButton>

      <TextField
        variant="outlined"
        type="text"
        label={t("message")}
        fullWidth
        value={form.message}
        onChange={handleChange}
        id="message"
        name="message"
      />

      <MDButton onClick={handleSubmit} sx={{ height: "100%" }} variant="gradient" color="info">
        <Icon>send</Icon>
      </MDButton>
    </Box>
  );
};

export default SendMessageSection;