import { useCallback, useState } from "react";
import { useRatio } from "hooks/useRatio";

const useUtils = (details) => {
  const { ratio, width } = useRatio(details && details.noteHeight ? details.noteHeight : 150);

  const [widthDifferenceOnLoop, setWidthDifferenceOnLoop] = useState(0);



  const calculatePosition = useCallback((videoTime) => {
    const { noteWidth, noteStart, videoStart, noteMain, videoMain } = details;
    const noteStartPos = (ratio * noteWidth / 2 - width /2) - (ratio * noteStart);
    const videoPlayedTime = videoTime - (videoStart / 1000);
    const pixel = (ratio * noteMain) / (videoMain / 1000);
    const pos = noteStartPos + videoPlayedTime * -pixel;
    return pos;
  }, [details, ratio, width]);

  const calculateVideoTime = useCallback((startPos, endPos) => {
    const { noteMain, noteStart, videoMain } = details;
    const pixel = (ratio * (noteMain)) / (videoMain / 1000);
    const second = ((startPos - endPos) - noteStart) / pixel;
    return second / 2;
  }, [details, ratio]);

  const calculateLoopPosition = useCallback((videoTime, playedTrack) => {
    try {
      const { noteWidth, loopNoteStart, videoStart, noteMain, videoMain } = details;
      const widthDifference = widthDifferenceOnLoop; // Use the current state directly
      const noteStartPos = (ratio * noteWidth / 2 - width / 2) - (ratio * loopNoteStart) - (playedTrack * widthDifference);
      const videoPlayedTime = videoTime - (videoStart / 1000);
      const pixel = (ratio * (noteMain)) / ((videoMain) / 1000);
      const pos = noteStartPos + videoPlayedTime * -pixel;
      return pos;
    } catch (error) {
      console.log(error);
    }
  }, [details, ratio, width, widthDifferenceOnLoop]);

  const calculateLoopDifference = useCallback(() => {
    const { videoStart, measure, measureWidth, bpm } = details;
    const measureSplitted = measure.split("/");
    const trackTime = 60 / bpm * (parseInt(measureSplitted[0]) / parseInt(measureSplitted[1])) * parseInt(measureSplitted[1]);
    const loopWidth = calculatePosition(videoStart / 1000) - calculatePosition(videoStart / 1000 + trackTime);
    setWidthDifferenceOnLoop((measureWidth * ratio) - loopWidth);
  }, [calculatePosition, details, ratio]);


  const getLoopVariables = (currentTime) => {
    calculateLoopDifference();
    const { videoStart, measure, bpm } = details;
    const measureSplitted = measure.split("/");
    const beatsTime = 60 / bpm * (parseInt(measureSplitted[0]) / parseInt(measureSplitted[1]));
    const trackTime = beatsTime * parseInt(measureSplitted[1]);
    const videoPlayedTime = currentTime - (videoStart / 1000);
    const playedTrack = parseInt(videoPlayedTime / trackTime);
    const playedBeats = parseInt(videoPlayedTime / beatsTime);
    const startTime = videoStart / 1000;
    return { trackTime, playedTrack, width, playedBeats, beatsTime, startTime }
  }



  return {
    calculatePosition,
    calculateLoopPosition,
    calculateLoopDifference,
    getLoopVariables,
    calculateVideoTime
  }
}

export default useUtils;