import { Card, Grid } from '@mui/material'
import DefaultCard from 'components/Cards/SongCard/DefaultCard';
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography';
import { imageProxy } from 'features/api/proxy';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectcourses } from 'store/main/coursesSlice';


function CoursesList() {
  const courses = useSelector(selectcourses);

  console.log(courses);

  const { t, i18n } = useTranslation();

  const [filteredCourses, setFilteredCourses] = useState([]);

  const { searchText, instrumentValue, categoriesValue } = useSelector((state) => state.courses);

  useEffect(() => {
    const filteredList = courses
      .filter((f) => {
        if (searchText !== "") {
          return (
            f.courseName.toLowerCase().includes(searchText.toLowerCase()) ||
            (
              (i18n.language.match("tr") && f.courseDescriptionTr.toLowerCase().includes(searchText.toLowerCase())) ||
              (i18n.language.match("en") && f.courseDescriptionEn.toLowerCase().includes(searchText.toLowerCase())) ||
              (i18n.language.match("de") && f.courseDescriptionDe.toLowerCase().includes(searchText.toLowerCase())) ||
              (i18n.language.match("ar") && f.courseDescriptionAr.toLowerCase().includes(searchText.toLowerCase()))
            )
          )
        }
        return f;
      })
      .filter((f) => {
        if (instrumentValue.id !== "") {
          return f.instrumentId === instrumentValue.id
        }
        return f;
      })
      .filter((f) => {
        const categoriesMatch = f.courseCategoryData.find(
          (f) => f.categoryId === categoriesValue.id
        );
        if (categoriesMatch !== undefined) {
          return categoriesMatch
        }
        return f;
      });

    setFilteredCourses(filteredList);
  }, [searchText, courses, instrumentValue, categoriesValue, i18n.language]);

  if (filteredCourses.length === 0) {
    return <Card>
      <MDBox p={3}>
        <MDTypography fontSize={{ xs: "1.1rem", lg: "1.2rem", xl: "1.3rem" }} fontWeight={"bold"}>
          {t("courseDoesntMatch")}
        </MDTypography>
      </MDBox>
    </Card>
  }

  return (

    <MDBox>
      <Grid container spacing={2}>
        {
          filteredCourses.map((course, key) => {
            return <Grid key={key} item xs={12} md={6} xl={4}>
              <DefaultCard
                details={{
                  type: "course",
                  route: `/${t("course")}/${course.courseId}`,
                  key: key,
                  views: course.courseViewCount
                }}
                image={`${imageProxy}/${course.url}`}
                title={course.courseName}
                description={
                  (i18n.language.match("tr") && course.courseDescriptionTr) ||
                  (i18n.language.match("en") && course.courseDescriptionEn) ||
                  (i18n.language.match("de") && course.courseDescriptionDe) ||
                  (i18n.language.match("ar") && course.courseDescriptionAr)
                }
                duration={course.courseLessonData.length + t("lesson")}
              />
            </Grid>;
          })
        }
      </Grid>
    </MDBox>
  )
}

export default CoursesList;