import { Slider } from '@mui/material';
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectsongListsById } from 'store/main/songListsSlice';
import { setDraggableImageData } from 'store/main/videoPlayerSlice';
import { setLoopData } from 'store/main/videoPlayerSlice';
import { setVideoData } from 'store/main/videoPlayerSlice';


const VideoSlider = () => {
  const dispatch = useDispatch();
  const { loopData, videoData } = useSelector((state) => state.videoPlayer);
  


  const handleChange = (event, newValue) => {
    dispatch(setLoopData({ ...loopData, loopStart: newValue[0], loopEnd: newValue[1], loopChanged: true }));
    console.log(newValue[0]);
    if (newValue[0] !== loopData.loopStart) {
      dispatch(setLoopData({ ...loopData, loopStart: newValue[0], loopEnd: newValue[1], loopStartChanged: true }));
    }
  };

  return (
    <Slider 
      min={0}
      max={videoData.duration}
      value={[loopData.loopStart, loopData.loopEnd]}
      onChange={handleChange}
      step={0.01}
      sx={{
        margin: "7px 10px",
        padding: "0px",
        width: "100%",
        '& .MuiSlider-track': {
          height: "8px",
        },
        '& .MuiSlider-thumb': {
          borderRadius: '50%',

        },
        '& .MuiSlider-rail': {
          height: "8px",
          borderRadius: "5px",
          backgroundColor: "rgba(255, 255, 255, 0.3)",
        },
      }}
    />
  )
}

export default VideoSlider;
