import React from 'react'
import PageLayout from 'pages/main/LayoutContainers/PageLayout'

import { useDispatch } from 'react-redux'
import { useState } from 'react';
import LoadingComponent from 'components/Loading';
import { useEffect } from 'react';
import { getinstructorLessons } from 'store/main/instructorLessonsSlice';
import InsLessonPlayerSite from '../insLessonPlayer/InsLessonPlayerSite';

function InstructorLessonDashboard() {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getinstructorLessons()).then(() => setLoading(false));
  }, [dispatch])

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <PageLayout>
      <InsLessonPlayerSite />
    </PageLayout>
  )
}

export default InstructorLessonDashboard