import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

function PastSubscription({ discount, payMethod, name, startDate, endDate, price, status, currencyISO }) {
  const { country_code } = useSelector((state) => state.location);
  const { t } = useTranslation();

  const renderCurrencySymbol = () => {
    if ((payMethod === "coupon" && country_code === "TR") || currencyISO === "TRY") {
      return "₺"
    }
    else if ((payMethod === "coupon" && country_code === "DE") || currencyISO === "EUR") {
      return "€"
    }
  }

  const renderPrice = () => {
    return (price - price * discount / 100);
  }

  return (
    <MDBox bgColor={"light"} mb={2} borderRadius={"md"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} py={1}
      px={3} shadow={"md"}>

      <MDBox display={"flex"} flexDirection={"column"}>
        <MDTypography color={"dark"} variant="h6" fontWeight="medium">
          {name}
        </MDTypography>
        {/* <MDTypography color={"dark"} variant="button" fontWeight="light">
          {subscriptionId}
        </MDTypography> */}
        <MDTypography color={"dark"} variant="button" fontWeight="light">
          {dayjs(startDate).format("DD.MM.YYYY")} - {dayjs(endDate).format("DD.MM.YYYY")}
        </MDTypography>
      </MDBox>



      <MDBox display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
        <MDTypography color={"dark"} variant="h6" fontWeight="medium">
          {t(status)}
        </MDTypography>
        <MDTypography color={"dark"} variant="button" fontWeight="light">
          {renderCurrencySymbol()} {renderPrice()}
        </MDTypography>
      </MDBox>
    </MDBox>
  )
}

export default PastSubscription