import { useSelector } from "react-redux";
import useUtils from "./useUtils";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setVideoData } from "store/main/videoPlayerSlice";

const { useEffect, useCallback } = require("react");

const useShortcuts = (videoRef, selectSongState, videoId) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { details, songInfo } = useSelector((state) => selectSongState(state, videoId ? videoId : id));
  const { videoData } = useSelector((state) => state.videoPlayer);
  const { getLoopVariables, } = useUtils(details);
  const { isTrial } = useSelector((state) => state.payments);

  console.log(isTrial, songInfo)

  const keyPress = useCallback((event) => {
    //Todo

    if (isTrial && !songInfo.isTrial) {
      return false;
    }

    if (event.code === "Space") {
      if (videoData.isPlaying) {
        videoRef.current.pause();
        dispatch(setVideoData({ isPlaying: false }));
      }
      else {
        videoRef.current.play();
        dispatch(setVideoData({ isPlaying: true }))
      }
    }
    else if (event.code === "KeyM") {
      if (videoData.volume !== 0) {
        videoRef.current.volume = 0;
        dispatch(setVideoData({ volume: 0 }));
      }
      else {
        videoRef.current.volume = 1;
        dispatch(setVideoData({ volume: 1 }));
      }
    }
    // else if (event.code === "ArrowRight") {
    //   const { playedBeats, beatsTime, startTime } = getLoopVariables(videoData.currentTime);

    //   videoRef.current.currentTime = startTime + (playedBeats + 1) * beatsTime;
    //   console.log("girdik", videoData.currentTime);
    //   dispatch(setVideoData({ currentTime: startTime + (playedBeats + 1) * beatsTime }));
    // }
    // else if (event.code === "ArrowLeft") {
    //   const { playedBeats, beatsTime, startTime } = getLoopVariables(videoData.currentTime);

    //   videoRef.current.currentTime = startTime + (playedBeats - 1) * beatsTime;
    //   console.log("girdik");
    //   dispatch(setVideoData({ currentTime: startTime + (playedBeats - 1) * beatsTime }));
    // }
  }, [getLoopVariables, videoRef, videoData.currentTime, dispatch, videoData.isPlaying, videoData.volume])

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);
}

export default useShortcuts;