import { Icon, Tooltip } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';

function RewindButton({ handleRewind }) {
  const { t } = useTranslation();
  

  return (
    <Tooltip title={t("RewindButton")} placement='bottom'>
      <button className='RewindButton' onClick={handleRewind}>
        <Icon>fast_rewind</Icon>
      </button>
    </Tooltip>
  )
}

export default RewindButton