import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "features/api/axiosConfig";
import showToast from "hooks/useCustomToast";

export const checkSubscription = createAsyncThunk(
  "payments/checkSubscription",
  async (credentials, { dispatch, state }) => {
    try {
      const response = await axiosConfig.post(`api/payment/subscriptions/check-subscription`, credentials);
      const data = response.data;

      if (response.data.success) {
        if (data.isCoupon) {
          showToast("check_circle_icon", "successfully_logged_in_coupon", "success");
        }
        return data;
      } else {

      }
    } catch (error) {

    }
  }
);


const paymentsSlice = createSlice({
  name: "payments",
  initialState: {
    subscriptionLoaded: false,
    isSubscribed: false,
    isTrial: false,
    subscriptionsInfo: [],
    pricingPlan: {},
  },
  reducers: {
    setPaymentInitialState: (state, action) => {
      // Yeni bir durum nesnesi oluşturarak initialState'e dönün
      return {
        isSubscribed: false,
        isTrial: false,
        pricingPlan: {}
      };
    },
  },
  extraReducers: {
    [checkSubscription.fulfilled]: (state, action) => action.payload,
  },
});

export const {
  setPaymentInitialState
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
