import { Card, Container } from '@mui/material'
import MDBox from 'components/MDBox'
import CoverLayout from 'pages/main/layout/CoverLayout'
import LandingLayout from 'pages/main/layout/LandingLayout'
import Faq from 'pages/students/payment/newSubscription/PricingPlansInfo/Faq'
import React from 'react'

function FaqSection() {
  return (
    <LandingLayout>
      <Container>
        <Faq />
      </Container>
    </LandingLayout>
  )
}

export default FaqSection