// Image
import { Alert, Card, FormControl, FormHelperText, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar } from "@mui/material";
import bgImage from "assets/images/laSolist_player_01.jpeg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useForm } from "features/hooks";
import AuthCoverLayout from "pages/main/layout/AuthCoverLayout";

import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from "store/auth/authSlice";

const defaultFormState = {
  password: "",
  confirmPassword: "",
};

const passwordRequirements = [
  { name: "min_characters", regex: /^.{6,}$/ },
  { name: "special_characters", regex: /[-!$%^&*()_+|~=`{}\[\]:";'<>?,./\\]/ },
  { name: "one_number", regex: /\d/ },
  { name: "one_letter", regex: /[a-zA-Z]/ },
];

function ResetPassword() {

  const [passwordUpdated, setPasswordUpdated] = useState({
    response: false,
    status: "success",
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setPasswordUpdated({
      response: false,
      status: "success",
      message: "",
    })
  };

  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useDispatch();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);


  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      ...form,
      token,
    };
    dispatch(resetPassword(data)).then((params) => {
      try {
        if (params.payload.success) {
          setPasswordUpdated({
            response: true,
            status: "success",
            message: "reset_password_success",
          });

          setTimeout(() => {
            navigate("/sign-in");
          }, 2000);
        }
      } catch (error) {
        setPasswordUpdated({
          response: true,
          status: "error",
          message: "reset_password_error",
        });
      }
    }
    );
  }

  function canBeSubmitted() {
    return (
      form.password === form.confirmPassword &&
      passwordRequirements[0].regex.test(form.password) &&
      passwordRequirements[1].regex.test(form.password) &&
      passwordRequirements[2].regex.test(form.password) &&
      passwordRequirements[3].regex.test(form.password)
    );
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <AuthCoverLayout
      image={bgImage}
    >
      <Card sx={{ maxWidth: "450px", mx: "auto" }}>
        <MDBox p={3} textAlign="center">
          <MDBox mb={1} textAlign="center">
            <MDTypography variant="h4" fontWeight="bold">
              {t("reset_password_header")}
            </MDTypography>
          </MDBox>
          <MDTypography variant="body2" color="text">
            {t("reset_password_message")}
          </MDTypography>
        </MDBox>
        <Snackbar
          open={passwordUpdated.response}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Alert onClose={handleClose} severity={passwordUpdated.status} sx={{ width: '100%' }}>
            {t(passwordUpdated.message)}
          </Alert>
        </Snackbar>
        <MDBox p={3} component="form" role="form">
          <MDBox mb={2}>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="password">{t("password")}</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                value={form.password}
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("password")}
              />
              <FormHelperText error={true}>
                {
                  (form.password) &&
                  (
                    (passwordRequirements[0].regex.test(form.password) ? "" : t("enterMinCharacters")) ||
                    (passwordRequirements[1].regex.test(form.password) ? "" : t("enterSpecialCharacters")) ||
                    (passwordRequirements[2].regex.test(form.password) ? "" : t("enterOneNumber")) ||
                    (passwordRequirements[3].regex.test(form.password) ? "" : t("enterOneLetter")) ||
                    (form.password === form.confirmPassword ? "" : t("passwordDoesntMatch"))
                  )
                }
              </FormHelperText>
            </FormControl>
          </MDBox>
          <MDBox mb={2}>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="password">{t("confirmPassword")}</InputLabel>
              <OutlinedInput
                id="confirmPassword"
                name="confirmPassword"
                value={form.confirmPassword}
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("confirmPassword")}
              />

            </FormControl>
          </MDBox>

          <MDButton
            variant="gradient"
            color="info"
            size="large"
            fullWidth
            onClick={handleLogin}
            disabled={!canBeSubmitted()}
          >
            {t("reset_password")}
          </MDButton>


        </MDBox>
      </Card>
    </AuthCoverLayout>
  )
}

export default ResetPassword