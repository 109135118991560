import { Icon } from '@mui/material';
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography';
import React, { useRef } from 'react'
import LazyLoad from 'react-lazyload'

function Cover({ details, duration }) {

  const imageRef = useRef();

  return (
    <MDBox sx={{ position: "relative" }}>
      <LazyLoad style={{ width: "100%", aspectRatio: "512/213" }} once>
        <MDBox
          className={"imageContainer"}
          ref={imageRef}
          component="img"
          src={"https://lasolist.com/uploads/default-cover/course_lesson_dark.png"}
          alt={"dark_cover.png"}
          sx={{
            borderRadius: 5,
          }}
          width="100%"
          height="auto"
          position="relative"
          resize={"contain"}
        />
      </LazyLoad>


      <MDBox sx={{
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: 40,
        paddingBottom: 2,
        paddingX: 1,
      }}>
        <MDBox
          sx={{
            backgroundColor: "rgb(255, 255, 255)",
            borderRadius: 4

          }}
        >
          <MDTypography
            fontSize={{ xs: "0.9rem", lg: "1rem", xl: "1.1rem" }}
            variant="h6"
            component="p"
            sx={{
              color: "#252F32",
              marginX: 2,
              alignItems: "center",
              display: "flex",
              gap: 1,
            }}
          >
            <Icon fontSize='medium'>visibility_outline</Icon>
            {details.views}
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            backgroundColor: "rgb(255, 255, 255)",
            borderRadius: 4
          }}
        >
          <MDTypography
            fontSize={{ xs: "0.9rem", lg: "1rem", xl: "1.1rem" }}
            variant="h6"
            component="p"
            sx={{
              color: "#252F32",
              marginX: 2,
            }}
          >
            {duration}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  )
}

export default Cover