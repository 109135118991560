import React from 'react'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Checkbox, Collapse, FormControlLabel, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import ImageUpload from 'components/UploadComponents/ImageUpload';
import { imageProxy } from "features/api/proxy";
import { useSelector } from 'react-redux';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  borderRadius: "10px",
  '&:before': {
    display: 'none',
  },
  marginBottom: "10px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon color="info" sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: "10px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



function PackageInformation({ expanded, handleExpand, form, handleChange, photo, handleSetImage, paymentIntervalValue, paymentIntervalOptions, setPaymentIntervalValue, statusOptions, statusValues, setStatusValue, payPlatformOptions, payPlatformValue, setPayPlatformValue, typeOptions, typeValue, setTypeValue }) {

  const pricingPlanDialog = useSelector(
    ({ pricingPlans }) => pricingPlans.pricingPlanDialog
  );

  const { t } = useTranslation();



  return (
    <Accordion expanded={expanded === 'panel1'} onChange={handleExpand('panel1')}>
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography variant="h5">
          {t("packageInformations")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          autoFocus
          margin="dense"
          id="pricingPlanId"
          name="pricingPlanId"
          label={t("pricingPlanId")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.pricingPlanId}
          disabled
        />
        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="pricingPlanNameTr"
                name="pricingPlanNameTr"
                label={t("pricingPlanNameTr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.pricingPlanNameTr}
                onChange={handleChange}
                required
                error={form.pricingPlanNameTr ? false : true}
                helperText={t("enterPricingPlanNameTr")}
                focused
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="pricingPlanDescriptionTr"
                name="pricingPlanDescriptionTr"
                label={t("pricingPlanDescriptionTr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.pricingPlanDescriptionTr}
                onChange={handleChange}
                required
                error={form.pricingPlanDescriptionTr ? false : true}
                helperText={t("enterPricingPlanDescriptionTr")}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="pricingPlanNameEn"
                name="pricingPlanNameEn"
                label={t("pricingPlanNameEn")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.pricingPlanNameEn}
                onChange={handleChange}
                required
                error={form.pricingPlanNameEn ? false : true}
                helperText={t("enterPricingPlanNameEn")}
                focused
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="pricingPlanDescriptionEn"
                name="pricingPlanDescriptionEn"
                label={t("pricingPlanDescriptionEn")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.pricingPlanDescriptionEn}
                onChange={handleChange}
                required
                error={form.pricingPlanDescriptionEn ? false : true}
                helperText={t("enterPricingPlanDescriptionEn")}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="pricingPlanNameDe"
                name="pricingPlanNameDe"
                label={t("pricingPlanNameDe")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.pricingPlanNameDe}
                onChange={handleChange}
                required
                error={form.pricingPlanNameDe ? false : true}
                helperText={t("enterPricingPlanNameDe")}
                focused
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="pricingPlanDescriptionDe"
                name="pricingPlanDescriptionDe"
                label={t("pricingPlanDescriptionDe")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.pricingPlanDescriptionDe}
                onChange={handleChange}
                required
                error={form.pricingPlanDescriptionDe ? false : true}
                helperText={t("enterPricingPlanDescriptionDe")}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="pricingPlanNameAr"
                name="pricingPlanNameAr"
                label={t("pricingPlanNameAr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.pricingPlanNameAr}
                onChange={handleChange}
                required
                error={form.pricingPlanNameAr ? false : true}
                helperText={t("enterPricingPlanNameAr")}
                focused
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="pricingPlanDescriptionAr"
                name="pricingPlanDescriptionAr"
                label={t("pricingPlanDescriptionAr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.pricingPlanDescriptionAr}
                onChange={handleChange}
                required
                error={form.pricingPlanDescriptionAr ? false : true}
                helperText={t("enterPricingPlanDescriptionAr")}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                value={statusValues}
                options={statusOptions}
                getOptionLabel={(option) => option.name}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                // fullWidth
                renderInput={(params) => (
                  <TextField {...params} label={t("status")} />
                )}
                onChange={(event, newValue) => {
                  if (typeof newValue.name === "string") {
                    setStatusValue({
                      id: newValue.id,
                      name: newValue.name,
                    });
                  } else if (newValue && newValue.inputValue) {
                    setStatusValue({
                      name: newValue.inputValue,
                    });
                  } else {
                    setStatusValue(newValue);
                  }
                  console.log(newValue);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                value={typeValue}
                options={typeOptions}
                getOptionLabel={(option) => option.name}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                // fullWidth
                renderInput={(params) => (
                  <TextField {...params} label={t("type")} />
                )}
                onChange={(event, newValue) => {
                  if (typeof newValue.name === "string") {
                    setTypeValue({
                      id: newValue.id,
                      name: newValue.name,
                    });
                  } else if (newValue && newValue.inputValue) {
                    setTypeValue({
                      name: newValue.inputValue,
                    });
                  } else {
                    setTypeValue(newValue);
                  }
                  console.log(newValue);
                }}
              />
            </Grid>
          </Grid>
        </MDBox>

        <Collapse in={typeValue.id === "MONTHLY" ? true : false}>
          <MDBox mt={1}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <MDBox>
                  <Autocomplete
                    value={paymentIntervalValue} // Dikkat: statusValues -> statusValue olarak değiştirildi
                    options={paymentIntervalOptions}
                    getOptionLabel={(option) => t(option.name)}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disabled={pricingPlanDialog.type === "edit" ? true : false}
                    // fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label={t("paymentInterval")} />
                    )}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                        setPaymentIntervalValue({
                          id: newValue, // newValue.id -> newValue olarak değiştirildi
                          name: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        setPaymentIntervalValue({
                          name: newValue.inputValue,
                        });
                      } else {
                        setPaymentIntervalValue(newValue);
                      }
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  // autoFocus
                  margin="dense"
                  id="price"
                  name="price"
                  label={t("price")}
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={form.price}
                  onChange={handleChange}
                  required
                  error={form.price ? false : true}
                  helperText={t("enterPrice")}
                  disabled={pricingPlanDialog.type === "edit" ? true : false}
                />
              </Grid>
              <Grid item xs={4} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"forStudent"}
                      id={"forStudent"}
                      checked={form.forStudent}
                      onChange={handleChange}
                    />
                  }
                  label={t("forStudent")}
                />
                <FormHelperText>
                  {t("makeChoice")}
                </FormHelperText>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox mt={1}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Autocomplete
                  value={payPlatformValue}
                  options={payPlatformOptions}
                  getOptionLabel={(option) => option.name}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  // fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label={t("payPlatform")} />
                  )}
                  onChange={(event, newValue) => {
                    if (typeof newValue.name === "string") {
                      setPayPlatformValue({
                        id: newValue.id,
                        name: newValue.name,
                      });
                    } else if (newValue && newValue.inputValue) {
                      setPayPlatformValue({
                        name: newValue.inputValue,
                      });
                    } else {
                      setPayPlatformValue(newValue);
                    }
                    console.log(newValue);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  // autoFocus
                  margin="dense"
                  id="countryISO"
                  name="countryISO"
                  label={t("countryISO")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={form.countryISO}
                  onChange={handleChange}
                  required
                  inputProps={{ maxLength: 2, textTransform: "uppercase" }}
                  error={form.countryISO ? false : true}
                  helperText={t("enterCountryISO")}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  // autoFocus
                  margin="dense"
                  id="currencyISO"
                  name="currencyISO"
                  label={t("currencyISO")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={form.currencyISO}
                  onChange={handleChange}
                  required
                  error={form.currencyISO ? false : true}
                  helperText={t("enterCurrencyISO")}
                />
              </Grid>
            </Grid>
          </MDBox>
        </Collapse>

        <MDBox mt={2}>
          <ImageUpload
            handleSetImage={handleSetImage}
            file={photo}
            url={form && form.url ? `${imageProxy}/${form.url}` : null}
          />
        </MDBox>
      </AccordionDetails>
    </Accordion>
  )
}

export default PackageInformation