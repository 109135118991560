const locale = {
  ID: "ID",
  sign_in_header: "Giriş Yap",
  sign_in_message: "Email ve şifrenizi girerek giriş yapınız",
  email: "Email",
  password: "Şifre",
  remember_me: "Beni hatırla",
  dont_have_account: "Hesabınız yok mu?",
  sign_up_header: "Kayıt Ol",
  sign_up_message: "Bilgilerinizi girerek kayıt olunuz",
  name: "Adınız",
  i_agree_terms_and_conditions: "Şartları ve koşulları kabul ediyorum",
  already_have_account: "Zaten hesabınız var mı?",
  SEARCH: "Ara",
  cancel: "İptal",
  save: "Kaydet",
  send: "Gönder",
  selectImage: "Fotoğrafı Seç",
  selectVideo: "Video Seç",
  change: "Değiştir",
  remove: "Sil",
  home: "Anasayfa",
  sign_in: "Giriş Yap",
  sign_up: "Kayıt ol",
  app_registration: "Başvuru Yap",
  confirm: "Onayla",
  resend_verification_code: "Onay Kodunu Yeniden Gönder",
  confirm_email: "Email Adresinizi Doğrulayın",
  confirm_email_alt: "Email adresinizi doğrulamak için emailinize gönderilen kodu aşağıya giriniz",

  beginner: "Başlangıç Seviyesi",
  advanced: "İleri Seviye",

  forgot_password: "Şifrenizi mi unuttunuz?",
  forgot_password_header: "Şifremi Unuttum",
  forgot_password_message: "Emailinizi girerek şifrenizi sıfırlayabilirsiniz.",
  reset_password_header: "Şifreyi Sıfırla",
  reset_password_message: "Lütfen yeni şifrenizi giriniz.",
  reset_password: "Şifreyi Sıfırla",
  wrong_password: "Şifreniz hatalı. Lütfen tekrar deneyiniz",
  forgotPasswordName: "Şifremi Unuttum",
  forgotPasswordRoute: "/forgot-password",
  forgotPasswordKey: "Şifremi Unuttum",
  forgot_password_success: "Şifre sıfırlama linkiniz emailinize başarılı şekilde gönderildi!",
  forgot_password_error: "Bu email ile eşleşen bir kullanıcı bulunamadı!",
  resetPasswordName: "Şifreyi Sıfırla",
  resetPasswordRoute: "/reset-password/:token",
  resetPasswordKey: "Şifreyi Sıfırla",
  reset_password_success: "Şifreniz başarılı şekilde sıfırlanmıştır!",
  reset_password_error: "Şifreniz sıfırlanırken hata oluştu. Lütfen tekrar deneyiniz!",

  instructor_application_header: "Eğitmen Olmak için Başvurunu Oluştur",
  instructor_application_alt: "Platformumuzda eğitim verebilmek için aşağıdaki formu doldurunuz. Demo videolarınızın bulunduğu (Google Drive, DropBox vb.) linkini, `Video Linki` alanına eklemeniz gerekmektedir.",
  driveUrl: "Video Linki [Google Drive, DropBox vb.]",
  application_created: "Eğitmen başvurunuz başarıyla oluşturuldu. Başvurunuz değerlendirildikten sonra size geri dönüş yapılacaktır.",

  error: "Hata",
  not_found: "Kullanıcı adına ait hesap bulunamadı. Lütfen tekrar deneyiniz.",
  login_successful : "Başarıyla giriş yapıldı.",
  logout_successful: "Başarıyla çıkış yapıldı.",
  username_already_in_use: "Bu mail adresi kullanılmaktadır. Lütfen başka bir mail adresi deneyin.",
  registered_successfully: "Başarıyla kayıt oldunuz. Lütfen emailinizi doğrulayın.",

  message_sent_successfully: "Mesaj Başarıyla Gönderildi!",
  message_couldnt_send: "Mesaj GÖnderilemedi",

  signUpRoute: "/sign-up/:email",

  dashboardName: "Dashboard",
  dashboardRoute: "dashboard/analytics",
  dashboardKey: "Dashboard",

  studentDashboardName: "Anasayfa",
  studentDashboardRoute: "/anasayfa",
  studentDashboardKey: "Anasayfa",

  instructorCollapse: "Eğitmen",
  songsCollapse: "Şarkı",
  courseCollapse: "Kurs",
  userCollapse: "Kullanıcı",
  iyzicoCollapse: "İyzico",
  paypalCollapse: "PayPal",
  subscriptionsCollapse: "Üyelik",

  instructorsName: "Eğitmenler",
  instructorsRoute: "/egitmenler",
  instructorsKey: "Eğitmenler",

  instructorCourseName: "Eğitmen Kursları",
  instructorCoursesRoute: "/egitmen/kurslar",
  instructorCoursesKey: "Eğitmen Kursları",

  instructorLessonsName: "Dersler",
  instructorLessonsRoute: "/egitmen/kurslar/dersler",
  instructorLessonsKey: "Dersler",

  instructorApplicationsName: "Başvurular",
  instructorApplicationsRoute: "/egitmen/basvurular",
  instructorApplicationsKey: "Başvurular",

  applicationName: "Eğitmen Başvuru",
  applicationRoute: "/basvuru",
  applicationKey: "Eğitmen Başvuru",

  instrumentsName: "Enstrümanlar",
  instrumentsRoute: "/enstrumanlar",
  instrumentsKey: "Enstrümanlar",

  categoriesName: "Kategoriler",
  categoriesRoute: "/kategoriler",
  categoriesKey: "Kategoriler",

  composersName: "Besteciler",
  composersRoute: "/besteciler",
  composersKey: "Besteciler",

  musiciansName: "Müzisyenler",
  musiciansRoute: "/muzisyenler",
  musiciansKey: "Müzisyenler",

  lessonsName: "Dersler",
  lessonsRoute: "/dersler",
  lessonsKey: "Dersler",

  songsName: "Şarkılar",
  songsRoute: "/sarkilar",
  songsKey: "Şarkılar",

  songsTrialName: "Şarkılar",
  songsTrialRoute: "/trial/sarkilar",
  songsTrialKey: "Şarkılar",

  songListName: "Şarkı Detayı",
  songListRoute: "/sarki-detayi",
  songListKey: "Şarkı Detayı",

  usersName: "Kullanıcılar",
  usersRoute: "/kullanicilar",
  usersKey: "Kullanıcılar",

  feedbacksName: "Yardım",
  feedbacksRoute: "/feedback",
  feedbacksKey: "Feedback",

  supportName: "Canlı Destek",
  supportRoute: "/canli-destek",
  supportKey: "Canlı Destek",

  supportDetailName: "Canlı Destek Detay",
  supportDetailRoute: "/canli-destek/:id",

  new: "yeni",

  pricingPlansName: "Ödeme Planları",
  pricingPlansRoute: "/odeme-planlari",
  pricingPlansKey: "Ödeme Planları",

  subscriptionsName: "Aboneler",
  subscriptionsRoute: "/aboneler",
  subscriptionsKey: "Aboneler",

  favoritesName: "Favoriler",
  favoritesRoute: "/favoriler",
  favoritesKey: "Favoriler",
  favoritesNotFound: "Favorilere eklediğiniz bir şarkı bulunmamakta.",
  favoritesFilterNotFound: "Filtrenizle eşleşen bir şarkı bulunamadı.",

  profileName: "Profil",
  profileRoute: "/profil",
  profileKey: "Profil",

  //Master Courses
  coursesName: "Kurslar",
  coursesRoute: "/kurslar",
  coursesKey: "Kurslar",
  course: "kurs",
  lesson: " Ders",

  //Detail Courses
  subCourseName: "Kurs",
  subCourseRoute: "/kurs/:courseId",
  subCourseKey: "Kurs",

  //Course Video
  studyName: "Kurs",
  studyRoute: "/kurs/:courseId/:id",
  studyKey: "Dersler",

  //Course video for admin
  lessonVideoName: "Lesson Video",
  lessonVideoRoute: "lesson/video/:id",
  lessonVideoKey: "Lesson Video",

  //instructor courses master
  educationName: "Egitimler",
  educationsRoute: "/egitimler",
  educationsKey: "Egitimler",

  //instructor courses detail
  instructorCoursesName: "Eğitmen Kursları",
  instructorCourseRoute: "/egitim/:courseId",
  instructorCourseKey: "Eğitmen Kursları",
  education: "egitim",
  instructor: "egitmen",

  //instructor lesson player
  instructorLessonName: "Ders Player",
  instructorLessonRoute: "/egitim/:courseId/:lessonId",
  instructorLessonKey: "Ders Player",

  //instructor detail dashboard
  instructorDashboardName: "Eğitmen Dashboard",
  instructorDashboardRoute: "/egitmen/:id",
  instructorDashboardKey: "Eğitmen Dashboard",

  videoName: "Video",
  videoRoute: "/video/:id",
  videoKey: "Video",

  iyzicoProductsDahsboard: "Ürünler",
  iyzicoProductsKey: "iyzico-urunler",
  iyzicoProductsRoute: "/iyzico/urunler",

  iyzicoPricingPlanName: "Ödeme Planları",
  iyzicoPricingPlanKey: "iyzico-odeme-planlari",
  iyzicoPricingPlanRoute: "/iyzico/odeme-planlari",

  iyzicoSubscriptionName: "Aboneler",
  iyzicoSubscriptionKey: "aboneler",
  iyzicoSubscriptionRoute: "/iyzico/aboneler",

  paypalProductsName: "Ürünler",
  paypalProductsKey: "paypal-urunler",
  paypalProductsRoute: "/paypal/urunler",

  paypalPricingPlanName: "Ödeme Planları",
  paypalPricingPlanKey: "paypal-odeme-planlari",
  paypalPricingPlanRoute: "/paypal/odeme-planlari",

  paypalSubscriptionName: "Aboneler",
  paypalSubscriptionKey: "paypal-aboneler",
  paypalSubscriptionRoute: "/paypal/aboneler",

  newSubscriptionName: "New Subscription",
  newSubscriptionRoute: "/payment/new-subscription",
  newSubscriptionKey: "New Subscription",

  upgradePlanName: "Upgrade Plan",
  upgradePlanRoute: "/payment/upgrade-plan",
  upgradePlanKey: "Upgrade Plan",

  changeCardName: "Kart Bilgilerini Değiştir",
  changeCardRoute: "/payment/change-card-information/:subscriptionReferenceCode",
  changeCardKey: "Kart Bilgilerini Değiştir",

  cookiesName: "Çerez Politikası",
  cookiesRoute: "/cerez-politikasi",
  cookiesKey: "Çerez Politikası",

  privacyName: "Gizlilik Sözleşmesi",
  privacyRoute: "/gizlilik-sozlesmesi",
  privacyKey: "Gizlilik Sözleşmesi",

  userAgreementName: "Kullanıcı Sözleşmesi",
  userAgreementRoute: "/kullanici-sozlesmesi",
  userAgreementKey: "Kullanıcı Sözleşmesi",

  deliveryAndRefundName: "Teslimat ve İade Şartları",
  deliveryAndRefundRoute: "/teslimat-ve-iade-sartlari",
  deliveryAndRefundKey: "Teslimat ve İade Şartları",

  distanceSellingName: "Mesafeli Satış Sözleşmesi",
  distanceSellingRoute: "/mesafeli-satis-sozlesmesi",
  distanceSellingKey: "Mesafeli Satış Sözleşmesi",

  aboutUsName: "Hakkımızda",
  aboutUsRoute: "/hakkimizda",
  aboutUsKey: "Hakkımızda",

  contactsName: "İletişim",
  contactsRoute: "/iletisim",
  contactsKey: "İletişim",

  faqsName: "S.S.S.",
  faqsRoute: "/sikca-sorulan-sorular",
  faqsKey: "S.S.S",

  pricingName: "Fiyatlandırma",
  pricingRoute: "/fiyatlandirma",
  pricingKey: "Fiyatlandırma",

  analyticsCollapseName: "Analizler",

  videoAnalyzeName: "Video Analizleri",
  videoAnalyzeRoute: "/analizler/video-analizleri",
  videoAnalyzeKey: "Video Analizleri",

  couponCodesName: "Kupon Kodu",
  couponCodesRoute: "/kupon-kodu",
  couponCodesKey: "Kupon Kodu",

  instructorSearch: "Eğitmen",
  courseDoesntMatch: "Filtrenizle eşleşen bir kurs bulunamadı.",

  instrumentInfo: "Enstrüman Bilgileri",
  instruments: "Enstrümanlar",
  instrumentList: "Enstrümanlar Listesi",
  instrumentsTable: "Enstrümanlar Tablosu",
  AddNewInstrument: "Yeni Enstrüman Ekle",
  instrumentId: "Enstrüman ID",
  instrumentNameTr: "Enstrüman Adı [TR]",
  instrumentDescriptionTr: "Enstrüman Açıklaması [TR]",
  instrumentNameEn: "Enstrüman Adı [EN]",
  instrumentDescriptionEn: "Enstrüman Açıklaması [EN]",
  instrumentNameDe: "Enstrüman Adı [DE]",
  instrumentDescriptionDe: "Enstrüman Açıklaması [DE]",
  instrumentNameAr: "Enstrüman Adı [AR]",
  instrumentDescriptionAr: "Enstrüman Açıklaması [AR]",
  instrumentType: "Enstrüman Türü",
  createdDate: "Oluşturulma Tarihi",
  status: "Durum",
  enterInstrumentNameTr: "Entrüman Adını Giriniz [TR]",
  enterInstrumentDescriptionTr: "Enstrüman Açıklamasını Giriniz [TR]",
  enterInstrumentNameEn: "Entrüman Adını Giriniz [EN]",
  enterInstrumentDescriptionEn: "Enstrüman Açıklamasını Giriniz [EN]",
  enterInstrumentNameDe: "Entrüman Adını Giriniz [DE]",
  enterInstrumentDescriptionDe: "Enstrüman Açıklamasını Giriniz [DE]",
  enterInstrumentNameAr: "Entrüman Adını Giriniz [AR]",
  enterInstrumentDescriptionAr: "Enstrüman Açıklamasını Giriniz [AR]",

  categoryInfo: "Kategori Bilgileri",
  categories: "Kategoriler",
  categoryList: "Kategori Listesi",
  categoriesTable: "Kategori Tablosu",
  AddNewCategory: "Yeni Kategori Ekle",
  categoryId: "Kategori ID",
  categoryName: "Kategori Adı",
  categoryDescription: "Kategori Açıklaması",
  categoryNameTr: "Kategori Adı [TR]",
  categoryDescriptionTr: "Kategori Açıklaması [TR]",
  categoryNameEn: "Kategori Adı [EN]",
  categoryDescriptionEn: "Kategori Açıklaması [En]",
  categoryNameDe: "Kategori Adı [DE]",
  categoryDescriptionDe: "Kategori Açıklaması [DE]",
  categoryNameAr: "Kategori Adı [AR]",
  categoryDescriptionAr: "Kategori Açıklaması [AR]",
  categoryType: "Kategori Türü",
  enterCategoryNameTr: "Kategori Adı Giriniz [TR]",
  enterCategoryDescriptionTr: "Kategori Açıklaması Giriniz [TR]",
  enterCategoryNameEn: "Kategori Adı Giriniz [EN]",
  enterCategoryDescriptionEn: "Kategori Açıklaması Giriniz [EN]",
  enterCategoryNameDe: "Kategori Adı Giriniz [DE]",
  enterCategoryDescriptionDe: "Kategori Açıklaması Giriniz [DE]",
  enterCategoryNameAr: "Kategori Adı Giriniz [AR]",
  enterCategoryDescriptionAr: "Kategori Açıklaması Giriniz [AR]",
  isIncludeTrial: "Deneme Süresine Dahil Mi?",

  composerInfo: "Besteci Bilgileri",
  composers: "Besteciler",
  composerList: "Besteci Listesi",
  composersTable: "Besteciler Tablosu",
  AddNewComposer: "Yeni Besteci Ekle",
  composerId: "Besteci ID",
  composerName: "Besteci Adı",
  enterComposerName: "Besteci Adını Giriniz",

  musiciansInfo: "Müzisyen Bilgileri",
  musicians: "Müzisyenler",
  musicianList: "Müzisyen Listesi",
  musiciansTable: "Müzisyenler Tablosu",
  AddNewMusician: "Yeni Müzisyen Ekle",
  musicianId: "Müzisyen ID",
  userName: "Kullanıcı Adı",
  musicianName: "Müzisyen Adı",
  musicianDescription: "Müzisyen Açıklaması",
  enterMusicianName: "Müzisyen Adını Giriniz",
  musicianDescriptionTr: "Müzisyen Açıklaması [TR]",
  enterMusicianDescriptionTr: "Müzisyen Açıklaması Giriniz [TR]",
  musicianDescriptionEn: "Müzisyen Açıklaması [EN]",
  enterMusicianDescriptionEn: "Müzisyen Açıklaması Giriniz [EN]",
  musicianDescriptionDe: "Müzisyen Açıklaması [DE]",
  enterMusicianDescriptionDe: "Müzisyen Açıklaması Giriniz [DE]",
  musicianDescriptionAr: "Müzisyen Açıklaması [AR]",
  enterMusicianDescriptionAr: "Müzisyen Açıklaması Giriniz [AR]",
  confirmPassword: "Şifreyi Onayla",
  enterUserName: "Kullanıcı Adını Giriniz",
  enterPassword: "Şifreyi Giriniz",
  enterConfirmPassword: "Onay Şifresini Giriniz",

  lessonInfo: "Ders Bilgileri",
  noteDetails: "Nota Detayları",
  lessons: "Dersler",
  lessonsTable: "Dersler Tablosu",
  lessonList: "Ders Listesi",
  AddNewLesson: "Yeni Ders ekle",
  lessonId: "Ders ID",
  lessonName: "Ders Adı",
  lessonDescription: "Ders Açıklaması",
  lessonNameTr: "Ders Adı [TR]",
  enterLessonNameTr: "Ders Adı Giriniz [TR]",
  lessonDescriptionTr: "Ders Açıklaması [TR]",
  enterLessonDescriptionTr: "Ders Açıklaması Giriniz [TR]",
  lessonNameEn: "Ders Adı [EN]",
  enterLessonNameEn: "Ders Adı Giriniz [EN]",
  lessonDescriptionEn: "Ders Açıklaması [EN]",
  enterLessonDescriptionEn: "Ders Açıklaması Giriniz [EN]",
  lessonNameDe: "Ders Adı [DE]",
  enterLessonNameDe: "Ders Adı Giriniz [DE]",
  lessonDescriptionDe: "Ders Açıklaması [DE]",
  enterLessonDescriptionDe: "Ders Açıklaması Giriniz [DE]",
  lessonNameAr: "Ders Adı [AR]",
  enterLessonNameAr: "Ders Adı Giriniz [AR]",
  lessonDescriptionAr: "Ders Açıklaması [AR]",
  enterLessonDescriptionAr: "Ders Açıklaması Giriniz [AR]",
  type: "Tip",
  courseId: "Kurs ID",
  enterCourseId: "Kurs ID Giriniz",
  enterLevel: "Leveli Giriniz",
  isHaveNote: "Nota Var Mı?",
  isHaveNoteHelperText: "Bu ders için eklenecek nota bilgisi var mı?",

  courses: "Kurslar",
  courseList: "Kurs Tablosu",
  coursesTable: "Kurslar Tablosu",
  AddNewCourse: "Yeni Kurs Ekle",
  courseName: "Kurs Adı",
  enterCourseName: "Kurs Adı Giriniz",
  courseDescriptionTr: "Kurs Açıklaması [TR]",
  enterCourseDescriptionTr: "Kurs Açıklaması Giriniz [TR]",
  courseDescriptionEn: "Kurs Açıklaması [EN]",
  enterCourseDescriptionEn: "Kurs Açıklaması Giriniz [EN]",
  courseDescriptionDe: "Kurs Açıklaması [DE]",
  enterCourseDescriptionDe: "Kurs Açıklaması Giriniz [DE]",
  courseDescriptionAr: "Kurs Açıklaması [AR]",
  enterCourseDescriptionAr: "Kurs Açıklaması Giriniz [AR]",
  selectLessons: "Dersleri Seçiniz",

  songInfo: "Şarkı Bilgileri",
  songs: "Şarkılar",
  songTable: "Şarkılar Tablosu",
  AddNewSong: "Yeni Şarkı Ekle",
  songId: "Şarkı ID",
  songName: "Şarkı Adı",
  songDescription: "Şarkı Açıklaması",
  songDescriptionTr: "Şarkı Açıklaması [TR]",
  songDescriptionEn: "Şarkı Açıklaması [EN]",
  songDescriptionDe: "Şarkı Açıklaması [DE]",
  songDescriptionAr: "Şarkı Açıklaması [AR]",
  enterSongName: "Şarkı Adını Giriniz",
  enterSongDescriptionTr: "Şarkı Açıklaması Giriniz [TR]",
  enterSongDescriptionEn: "Şarkı Açıklaması Giriniz [EN]",
  enterSongDescriptionDe: "Şarkı Açıklaması Giriniz [DE]",
  enterSongDescriptionAr: "Şarkı Açıklaması Giriniz [AR]",
  selectComposer: "Besteci Seç",
  selectCategories: "Kategorileri Seçiniz",
  songDoesntMatch: "Filtrenizle eşleşen bir şarkı bulunamadı.",

  songListInfo: "Şarkı Detayı Bilgileri",
  songList: "Şarkı Detayı",
  songListId: "Şarkı Detay ID",
  songListsTable: "Şarkı Detayları Tablosu",
  AddNewSongList: "Yeni Şarkı Detayı Ekle",
  listId: "Detay ID",
  description: "Açıklama",
  enterDescription: "Detay Açıklaması Giriniz",
  level: "Seviye",
  instrumentName: "Enstrüman Adı",
  instrumentDescription: "Entrüman Açıklaması",
  selectSong: "Şarkı Seç",
  selectMusician: "Müzisyen Seç",
  videoStart: "Video Başlangıç [ms]",
  enterVideoStart: "Video Başlangıç ms'i Giriniz",
  videoMain: "Video Genel [ms]",
  enterVideoMain: "Video Genel ms'i Giriniz",
  videoEnd: "Video Bitiş [ms]",
  enterVideoEnd: "Video Bitiş ms'i Giriniz",
  loopNoteStart: "Döngü Başlangıç [px]",
  enterLoopNoteStart: "Döngü Başlangıç Pikselini Giriniz",
  loopNoteEnd: "Döngü Bitiş [px]",
  enterLoopNoteEnd: "Döngü Bitiş Pikslini Giriniz",
  noteTolerance: "Nota Toleransı [px]",
  enterNoteTolerance: "Nota Toleransını Giriniz",

  noteStart: "Nota Başlangıç [px]",
  enterNoteStart: "Nota Başlangıç Pikselini Giriniz",
  noteMain: "Nota Genel [px]",
  enterNoteMain: "Nota Genel Pikselini Giriniz",
  noteEnd: "Nota Bitiş [px]",
  enterNoteEnd: "Nota Bitiş Pikselini Giriniz",
  noteHeight: "Nota Yükseklik [px]",
  enterNoteHeight: "Nota Yükseliğini Giriniz",
  noteWidth: "Nota Genişlik [px]",
  enterNoteWidth: "Nota Genişliğini Giriniz",
  bpm: "Metronom [bpm]",
  enterBpm: "Şarkı Metronomu [bpm] Giriniz",
  videoUrl: "Video URL [vps]",
  enterVideoUrl: "Şarkı URL'sini Giriniz",
  noteUrl: "Nota URL [vps]",
  enterNoteUrl: "Nota URL'sini Giriniz",
  minute: " dk.",
  folderNumber: "Klasör Numarası",
  enterFolderNumber: "Klasör Numarasını Giriniz",
  measure: "Şarkı Ölçüsü",
  enterMeasure: "Şarkı Ölçüsünü Giriniz",
  measureWidth: "Ölçü Uzunluğunu [px]",
  enterMeasureWidth: "Ölçü Uzunluğunu Giriniz",

  iyzicoProductReference: "İyzico Ürün Referens Kodu",
  iyzicoPricingPlanReference: "İyzico Ödeme Planı Referans Kodu",
  paypalReferenceCode: "PayPal Referans Numarası",
  pricingPlanId: "Plan ID",
  pricingPlanNameTr: "Plan Adı [TR]",
  enterPricingPlanNameTr: "Plan Adı Giriniz [TR]",
  pricingPlanDescriptionTr: "Plan Açıklaması [TR]",
  enterPricingPlanDescriptionTr: "Plan Açıklaması Giriniz [TR]",
  pricingPlanNameEn: "Plan Adı [EN]",
  enterPricingPlanNameEn: "Plan Adı Giriniz [EN]",
  pricingPlanDescriptionEn: "Plan Açıklaması [EN]",
  enterPricingPlanDescriptionEn: "Plan Açıklaması Giriniz [EN]",
  pricingPlanNameDe: "Plan Adı [DE]",
  enterPricingPlanNameDe: "Plan Adı Giriniz [DE]",
  pricingPlanDescriptionDe: "Plan Açıklaması [DE]",
  enterPricingPlanDescriptionDe: "Plan Açıklaması Giriniz [DE]",
  pricingPlanNameAr: "Plan Adı [AR]",
  enterPricingPlanNameAr: "Plan Adı Giriniz [AR]",
  pricingPlanDescriptionAr: "Plan Açıklaması [AR]",
  enterPricingPlanDescriptionAr: "Plan Açıklaması Giriniz [AR]",
  duration: "Süre",
  enterDuration: "Süre Giriniz",
  discount: "İndirim",
  enterDiscount: "İndirim Giriniz",
  price: "Fiyat",
  enterPrice: "Fiyat Giriniz",

  instructors: "Eğitmenler",
  instructorList: "Eğitmen Listesi",
  instructorsTable: "Eğitmen Tablosu",
  AddNewInstructor: "Yeni Eğitmen Ekle",
  instructorId: "Eğitmen ID",
  instructorName: "Eğitmen Adı",
  enterInstructorName: "Eğitmen Adını Giriniz",
  instructorDescription: "Eğitmen Açıklaması",
  instructorDescriptionTr: "Eğitmen Açıklaması [TR]",
  enterInstructorDescriptionTr: "Eğitmen Açıklaması Giriniz [TR]",
  instructorDescriptionEn: "Eğitmen Açıklaması [En]",
  enterInstructorDescriptionEN: "Eğitmen Açıklaması Giriniz [EN]",
  instructorDescriptionDe: "Eğitmen Açıklaması [DE]",
  enterInstructorDescriptionDe: "Eğitmen Açıklaması Giriniz [DE]",
  instructorDescriptionAr: "Eğitmen Açıklaması [AR]",
  enterInstructorDescriptionAr: "Eğitmen Açıklaması Giriniz [AR]",

  insCourses: "Eğitmen Kursları",
  insCoursesList: "Eğitmen Kursları Listesi",
  courseDescription: "Kurs Açıklaması",
  insCourseInfo: "Eğitmen Kurs Bilgileri",
  instructorCoursesTable: "Eğitmen Kursları Tablosu",
  instructorLessonsTable: "Eğitmen Dersleri Tablosu",

  videoAnalyze: "Video Analizleri",
  videoAnalyzeList: "Video Analiz Listesi",
  AddNewSongAnalyze: "Yeni Şarkı Analizi Ekle",
  videoAnalyzeId: "Video Analiz ID",
  videoId: "Video ID",
  userType: "Kullanıcı Türü",
  enterTime: "Şarkıya Giriş Zamanı",
  exitTime: "Şarkıya Giriş Zamanı",
  lastVideoTime: "Şarkının Oynatılan Son Saniyesi",

  instructorApplications: "Eğitmen Başvuruları",
  applicationId: "Eğitmen Başvuru ID",
  instructorApplicationsList: "Eğitmen Başvuruları Listesi",
  AddNewInstructorApplications: "Yeni Eğitmen Başvurusu Ekle",
  instructorApplicationsTable: "Eğitmen Başvuruları Tablosu",
  enterApplicantName: "Başvuran Kişinin Adını Giriniz",
  enterApplicantSurname: "Başvuran Kişinin Soyadını Giriniz",
  enterApplicantEmail: "Başvuran Kişinin Emailini Giriniz",
  enterApplicantVideUrl: "Başvuran Kişinin Video URL'sini Giriniz",

  feature1: "Kapsamlı Şarkı Seçkisi",
  feature2: "Her Şarkı İçin Tam Eğitim Programı",
  feature3: "Notalara Kolay Erişim",
  feature4: "Sürekli Güncellenen İçerik",
  feature5: "Farklı Seviyelerde Eğitim Seçenekleri",
  feature6: "Döngü Fonksiyonu ile Zor Bölümleri Tekrar Çalışın",
  feature7: "Başlangıçtan İleri Seviyeye Eğitim",
  feature8: "Profesyonel Eğitmenlerden Kişisel Yardım",
  feature9: "Alanında Uzman Eğitmen Kadrosu",
  subscribe: "Abone Ol",

  shouldLesson: "Dersler Dahil Mi?",
  shouldSong: "Şarkılar Dahil Mi?",
  shouldFeedback: "Geri Bildirim Dahil Mi?",
  makeChoice: "Lütfen Seçim Yapınız?",
  packageInformations: "Paket Bilgileri",
  iyzicoInformations: "İyzico Bilgileri",

  paypalInformations: "PayPal Bilgileri",
  enterPaypalPricingPlanName: "PayPal Ödeme Planı Adı Giriniz",
  paypalProductReference: "PayPal Ürün Referans Kodu",
  paypalPricingPlanReference: "PayPal Ödeme Planı Referans Kodu",
  paypalPricingPlanNameAdmin: "PayPal Ödeme Planı Adı",
  paypalPricingPlanDescription: "PayPal Ödeme Planı Açıklaması",
  enterPaypalPricingPlanDescription: "PayPal Ödeme Planı Açıklaması Giriniz",

  subscriptions: "Aboneler",
  subscriptionList: "Abone Listesi",
  checkSubscriptionStatus: "Aboneleri Kontrol Et",
  subscriptionsInfo: "Abone Bilgileri",
  pricingPlanInformations: "Ödeme Planı Bilgileri",
  userInformations: "Kullanıcı Bilgileri",
  addressInformations: "Adres Bilgileri",
  cardInformations: "Kart Bilgileri",
  cardHolderName: "Kart Sahibi Adı",
  enterCardHolderName: "Kart Sahibi Adı Giriniz",
  cardNumber: "Kart Numarası",
  enterCardNumber: "Kart Numarası Giriniz",
  expireMonth: "Son Kullanma [Ay]",
  enterExpireMonth: "Son Kullanma [Ay] Giriniz",
  expireYear: "Son Kullanma [Yıl]",
  enterExpireYear: "Son Kullanma [Yıl] Giriniz",
  cvc: "CVC Kodu",
  enterCvc: "CVC Kodunu Giriniz",
  expires: "Son Kullanma",
  enterName: "Adınızı Giriniz",
  surname: "Soyadınız",
  enterSurname: "Soyadınızı Giriniz",
  gsmNumber: "Telefon Numarası",
  zipCode: "Posta Kodu",
  parentReferenceCode: "Ana Referans Kodu",
  payMethod: "Ödeme Yöntemi",
  noTrial: "Deneme Süresi Yok",
  trialStartDate: "Deneme Süresi Başlangıç",
  trialEndDate: "Deneme Süresi Bitiş",
  startDate: "Başlangıç Tarihi",
  endDate: "Bitiş Tarihi",
  expireDate: "Son Kullanma",
  retryPayment: "Ödemeyi Tekrarla",

  couponCodes: "Kupon Kodları",
  couponCodesList: "Kupon Kodu Listesi",
  AddNewCouponCode: "Yeni Kupon Kodu Ekle",
  couponCodeInfo: "Kupon Kodu Bilgileri",
  couponCodeId: "Kupon Kodu ID",
  referenceCode: "Referans Kodu",
  enterReferenceCode: "Referans Kodunu Giriniz",
  discountPercentage: "İndirim Oranı",
  enterDiscountPercentage: "İndirim Oranını Giriniz",
  creditPercentageToInstructor: "Eğitmene Verilecek Kredi Oranı",
  enterCreditPercentageToInstructor: "Eğitmene Verilecek Kredi Oranını Giriniz",
  creditPercentageToStudent: "Öğrenciye Verilecek Kredi Oranı",
  enterCreditPercentageToStudent: "Öğrenciye Verilecek Kredi Oranını Giriniz",
  enterCreatedDate: "Başlangıç Zamanını Giriniz",
  enterExpirationDate: "Bitiş Zamanını Giriniz",
  selectPricingPlans: "Geçerli Olacak Ödeme Planlarını Seçiniz",

  addressInfoHeader: "Adres Bilgileri",
  addressInfoAlt: "Aşağıdaki planlardan birisini seçiniz",
  enterGsmNumber: "10 Haneli Telefon Numaranızı Giriniz",
  identityNumber: "Kimlik Numarası",
  enterIdentityNumber: "11 Haneli Kimlik Numaranızı Giriniz",
  address: "Fatura Adresi",
  enterAddress: "Fatura Adresi Giriniz",
  contactName: "Fatura Alıcı Adı Giriniz",
  enterContactName: "Fatura Alıcı Adı Giriniz",
  city: "Şehir",
  enterCity: "Şehir Giriniz",
  country: "Ülke",
  enterCountry: "Ülke Giriniz",

  userInfoHeader: "Kullanıcı Bilgileri",
  userInfoAlt: "Lütfen eksik bilgileri doldurunuz",

  selectInstrument: "Enstrüman Seçiniz",

  users: "Kullanıcılar",
  usersList: "Kullanıcılar Listesi",
  AddNewUser: "Yeni Kullanıcı Oluştur",
  usersTable: "Kullanıcılar Tablosu",
  enterEmail: "Email Giriniz",
  resetToken: "Sıfırlama Tokeni",
  resetTokenExpire: "Sıfırlama Tokeni Son Kullanım Tarihi",

  feedbackInfo: "Geri Bildirim Bilgileri",
  feedbacks: "Geri Bildirimler",
  feedbacks_header: "Daha iyi olmamıza ve sizin için hizmetimizin iyileştirilmesine yardımcı olun. Geri bildiriminiz bizim için çok kıymetli, teşekkür ederiz! ",
  feedbackList: "Geri Bildirim Listesi",
  feedbacksTable: "Geri Bildirim Tablosu",
  AddNewFeedback: "Yeni Geri Bildirim Ekle",
  feedbackId: "Geri Bildirim ID",
  feedbackTitle: "Geri Bildirim Başlığı",
  enterFeedbackTitle: "Geri Bildirim Başlığını Giriniz",
  feedbackDetails: "Geri Bildirim Detayları",
  enterFeedbackDetails: "Geri Bildirim Detaylarını Giriniz",
  feedbackReply: "Geri Bildirim Cevabı",
  enterFeedbackReply: "Geri Bildirim Cevabını Giriniz",
  feedbackType: "Geri Bildirim Türü",
  problem: "Sorun",
  suggestion: "Öneri",
  complaint: "Şikayet",
  general: "Genel",
  waiting: "Beklemede",
  success: "Başarılı",
  processing: "İşleniyor",
  solved: "Çözüldü",
  cancelled: "İptal Edildi",
  rejected: "Reddedildi",

  //Paypal Translations
  update_time: "Güncelleme Tarihi",
  pricing_plan_reference: "Ödeme Planı Referans Kodu",

  accept: "Kabul Et",
  decline: "Reddet",

  productInfo: "Ürün Bilgileri",
  products: "Ürünler",
  productsList: "Ürünler Listesi",
  AddNewProducts: "Yeni Ürün Ekle",
  productReferenceCode: "Ürün Referans Numarası",
  productName: "Ürün Adı",
  enterProductName: "Ürün Adını Giriniz",
  productDescription: "Ürün Açıklaması",
  enterProductDescription: "Ürün Açıklamasını Giriniz",

  pricingPlanInfo: "Ödeme Planı Bilgileri",
  pricingPlans: "Ödeme Planları",
  pricingPlansList: "Ödeme Planları Listesi",
  pricingPlansTable: "Ödeme Planları Tablosu",
  AddNewPricingPlan: "Yeni Ödeme Planı Ekle",
  pricingPlanReferenceCode: "Ödeme Planı Referans Kodu",
  pricingPlanName: "Ödeme Planı Adı",
  enterPricingPlanName: "Ödeme Planı Adını Giriniz",
  paymentInterval: "Ödeme Aralığı",
  paymentIntervalCount: "Ödeme Sıklığı",
  enterPaymentIntervalCount: "Ödeme Sıklığı Giriniz",
  trialPeriodDays: "Deneme Süresi Gün Sayısı",
  enterTrialPeriodDays: "Deneme Süresi Gün Sayısını Giriniz",
  MONTH1: "1 Aylık",
  MONTH6: "6 Aylık",
  MONTH12: "12 Aylık",
  payPlatform: "Ödeme Platformu",
  countryISO: "Ülkenin ISO Kodu",
  enterCountryISO: "Ülkenin ISO Kodunu Giriniz",
  currencyISO: "Para Biriminin ISO Kodu",
  enterCurrencyISO: "Para Biriminin ISO Kodunu Giriniz",

  subscriptionReferenceCode: "Üyelik Referenas Numarası",
  subscriptionName: "Üye Adı",
  subscriptionSurname: "Üye Soyadı",
  subscriptionEmail: "Üye Email",
  subscriptionGsmNumber: "Üye Telefon Numarası",
  subscriptionInfo: "Abonelik Bilgileri",
  orderInformations: "Ödeme Bilgileri",
  paymentAttempts: "Ödeme Denemeleri",
  subscriptionsList: "Abone Listesi",

  profile_profile: "Profil",
  profile_password: "Şifre Değiştir",
  profile_subscription: "Planınız",
  profile_email: "Email Değiştir",
  profile_payment: "Ödeme Yöntemi",
  profile_past_subscriptions: "Abonelik Bilgisi",
  profile_cancel: "Aboneliği İptal Et",
  ACTIVE: "Aktif Plan",
  FINISHED: "Geçmiş Plan",
  UPGRADED: "Yükseltilmiş Plan",
  CANCELLED: "iptal Edilmiş Plan",
  WAITING: "Gelecek Plan",

  student: "Öğrenci",
  change_password: "Şifreyi Değiştir",
  password_requirements: "Şifre Gereksinimleri",
  password_requirements_description: "Güçlü bir şifre için lütfen aşağıdaki gereksinimleri uygulayın",
  special_characters: "1 Özel Karakter [.,-_ vb.]",
  min_characters: "Minimum 6 Karakter",
  one_number: "1 Rakam",
  one_letter: "1 Harf",
  current_password: "Mevcut Şifre",
  new_password: "Yeni Şifre",
  confirm_new_password: "Yeni Şifreyi Onayla",
  enterCurrentPassword: "Mevcut Şifrenizi Giriniz",
  enterMinCharacters: "Şifren Minimum 6 Karakter Olmalıdır",
  enterSpecialCharacters: "Şifrende En Az 1 Tane Özel Karakter Olmalıdır [.,!-_ vb.]",
  enterOneNumber: "Şifrende En Az 1 Tane Sayı Olmalıdır",
  enterOneLetter: "Şifrende En Az 1 Tane Harf Olmalıdır",
  passwordDoesntMatch: "Şifren, Onay Şifresi ile Eşleşmiyor",

  profile_changed_success: "Bilgilerin başarıyla güncellenmiştir.",
  profile_changed_error: "Bilgilerin güncelenirken bir hata oluşmuştur. Lüften daha sonra tekrar dene.",
  enterNameError: "Adınız boş olamaz. Lütfen Adını gir.",
  enterSurnameError: "Soyadınız boş olamaz. Lütfen soyadınızı girin.",

  password_changed_success: "Şifren başarıyla güncellenmiştir.",
  password_changed_failed: "Şifren eşleşmiyor, lütfen tekrar dene.",
  update_information: "Bilgileri Güncelle",
  edit: "Düzenle",
  past_subscriptions_header: "Abonelik Bilgisi",

  change_email: "Emaili Değiştir",
  new_email: "Yeni Email",
  confirm_current_password: "Mevcut Şifreyi Onayla",

  subscription_header: "Ödeme Planınız",
  change_subscription: "Ödeme Planını Değiştir",
  cannotProvideThisService: "Şuanda bu hizmeti veremiyoruz",

  payment_method: "Ödeme Yöntemi",
  payment_method_description: "Bunu yapmak istediğinize emin misin?",
  change_payment_method: "Ödeme Yöntemini Güncelle",

  cancel_subscription: "Aboneliği İptal Et",
  cancel_subscription_description: "Bunu yapmak istediğinize emin misiniz?",
  cancel_subscription_success: "Aboneliğin Başarıyla İptal Edildi.",
  cancel_subscription_failure: "Aboneliğin İptal Edilemedi.",
  canceled_subscription_description: "Aboneliğin iptal edildi. ",
  canceled_subscription_description2: " tarihine kadar kullanmaya devam edebilirsin.",

  register_header: "Başvuru Yap",
  register_message: "Platforma erişmek için başvuru yapınız.",
  referenceUserName: "Referans Email",
  referenceName: "Referans Adı",
  note: "Başvuru Notu",

  feature_main: "Bağlama, müzikle insanların kalbine dokunmak demek",
  feature_alt:
    "Bağlama tutkunları, artık eşsiz bir online müzik öğrenme platformuna erişiminiz var. Usta bağlama sanatçılarından kompakt dersler ve birçok eser platformumuzda seni bekliyor. Müziğin ritmini kalbinde hisset, bağlama çalmanın tadını çıkart! Şimdi kaydol ve yeteneklerini bir üst seviyeye taşı.",

  feature1_header: "Sevdiğin Şarkılarla Başla",
  feature1_alt: "Yüzlerce eser arasından sevdiğin şarkıları seç ve öğrenmeyi eğlenceli hale getir.",

  feature2_header: "Video & Notalar",
  feature2_alt: "Profesyonellerin performanslarını izle, aynı anda notaları takip et ve kolayca öğren.",

  feature3_header: "Kademeli Öğrenme Hızları",
  feature3_alt: "Her şarkıda ustalaşmak için kendine uygun tempoyu seç.",

  feature4_header: "Döngü Fonksiyonu",
  feature4_alt: "Pratik yapmak istediğin bölümleri tekrarlayıp ve tamamen çalmaya konsantre ol.",

  feature5_header: "Şarkıları Keşfet",
  feature5_alt: "Her seviyeden ve türden yeni şarkılar keşfet veya unutulmaz klasikleri çalmaya başla.",

  feature6_header: "Sürekli Yeni İçerikler",
  feature6_alt: "Düzenli olarak eklenen yeni eserler ve kurslarla kendini sürekli geliştir.",

  call_main: "Kültürel mirasımızın eşsiz melodilerini çalabilmek artık parmaklarının ucunda!",
  call_alt: "Müzik notalarını renklerle buluşturan, öğrenme hızınızı sana göre ayarlayan, döngü özelliğe ustalaşmanı sağlayan ve geniş şarkı kütüphanesi ile seni müziğin eşsiz dünyasına davet ediyoruz. Haydi, hemen kaydol ve müzik yolculuğunuza bizimle başla!",
  call_alt_2: "Unutma ki, müzik insanların kalbine dokunmak demek 🎵 💙",

  trialInterval: "Süresiz",
  monthlyInterval: "Ay",
  couponInterval: "Ay",
  includesSong: "Şarkılar Dahil",
  includesLesson: "Dersler Dahil",
  includesFeedback: "Geri Bildirim Dahil",
  monthly: "Aylık Paketler",
  yearly: "Yıllık Paketler",
  trial: "Deneme Paketi",
  paid: "Eğitim Paketi",
  choosePlan: "Planı Seç",
  currentPlan: "Mevuct Plan",
  upgradePlan: "Plana Yükselt",
  checkout: "Ödeme",
  plan_header: "Plan Seç",
  plan_description: "Aşağıdaki planlardan birisini seç",
  plan_description_trial: "Tüm şarkılara erişmek için aşağıdaki planlardan birisini seç",
  continue_with_trial: "Deneme Planıyla Devam Et",
  upgrade_your_plan: "Planınızı Yükseltin",
  logout: "Çıkış Yap",
  goBack: "Geri",
  next: "İleri",

  Quality: "Kalite",
  QualityButton: "Video Kalitesi",
  PlaybackRate: "Çalma Hızı",
  SpeedButton: "Video Hızı",
  TurnOffVolume: "Sesi Kapat",
  TurnOnVolume: "Sesi Aç",
  ForwardButton: "İleri Sar",
  RewindButton: "Geri Sar",
  ReturnStart: "Başa Dön",
  Stop: "Durdur",
  Play: "Oynat",
  EnterLoop: "Döngüye Gir",
  ExitLoop: "Döngüden Çık",
  Fullscreen: "Tam Ekran",
  VideoSettings: "Video Ayarları",
  mute: "Sesi Kapat",
  volume_up: "Sesi Aç",

  areYouSureYouWantToDelete: "Silmek istediğinizden emin misiniz?",

  new_subscription_wizard_header: "Üyeliğinizi Oluşturun",
  new_subscription_wizard_description: "Üyeliğinizi oluşturabilmek için aşağıdaki bilgileri doldurunuz",

  upgrade_plan_wizard_header: "Planını Değiştir",
  upgrade_plan_wizard_description: "Plan değiştirmek için lütfen aşağıdaki bilgileri doldur",

  change_card_wizard_header: "Ödeme Yöntemini Değiştir",
  change_card_wizard_description: "Yeni kart bilgilerini lüften aşağıda gir ya da kayıtlı kartlarından seç",

  faq_header: "Sıkça Sorulan Sorular",
  faq_description: "",

  question1: "laSolist ücretsiz mi?",
  answer1_1: "Kayıt sonrası deneme paketimizi seçerek, LaSolist'un tamamen ücretsiz versiyonunu kullanabilirsiniz. Eğitimlerimizin tam kapsamına erişmek için ücretli bir planı tercih edebilirsiniz.",

  question2: "Hangi ödeme yöntemleri mevcut?",
  answer2_1: "İyzico ile işbirliği yapmaktayız ve İyzico'nun sunmuş olduğu tüm ödeme yöntemleri ve seçenekleri kullanabilirsiniz.",

  question3: "laSolist üyeliğimi nasıl uzatabilirim?",
  answer3_1: "Seçtiğiniz plana göre, otomatik olarak eğitiminiz devam edecektir.",

  question4: "laSolist üyeliğimi nasıl sonlandırabilirim?",
  answer4_1: "Profilinize girip aktif üyeliğinizi sonlandırabilirsiniz. Paketinizin bitiş tarihine kadar laSolist'i kullanmaya devam edebilirsiniz.",

  question5: "Öğrencilere özel paketleriniz var mı?",
  answer5_1: "Öğrenciyseniz, özel indirimden faydalanabilirsiniz. İndirim için öğrenci kimliğinizle iletisim@lasolist.com adresine başvurabilirsiniz.",

  question6: "Ben bir müzik öğretmeniyim, laSolist bana nasıl bir fayda sağlar?",
  answer6_1:
    "laSolist sizin için mükemmel bir destek. Derslerinizi daha da iyi hale getirebilir ve öğrencilerinizi bağımsız olarak pratik yapmaya motive edebilirsiniz. Öğrencilerinizin daha fazla katılımını, daha hizli ögrenmesini sağlayan ve bir öğretmen olarak sizi de güçlendiren yeni bir öğrenme yöntemi. ",
  answer6_2:
    "laSolist, öğrencilerinizin haftalık uygulama programlarına uymalarına yardımcı olarak, dersinizde pratik yapmadıkları şeyleri yakalamak için zaman harcamak zorunda kalmayacağınız anlamına gelir. Bunun yerine, derste öğrencilerinizle gerçekten ilgilenebilir ve kendinizi onların bireysel güçlü ve zayıf yönlerine adayabilirsiniz. Ayrıca, teknik, tonlama veya çalım tarzı gibi yan yana oturarak en iyi öğretilen önemli kavramları gözden geçirmek için tasarruf ettiğiniz zamanı kullanabilirsiniz. Sonuçta, derslerinizi bu kadar değerli ve yeri doldurulamaz yapan şey tam olarak budur.",

  question7: "Ben bir müzik öğretmeniyim, eğitmenleri ile işbirliğiniz var mı?",
  answer7_1: "Evet, öğretmenlere ve okullara özel çözümler sunuyoruz. laSolist ile birlikte kendi eğitim serinizi oluşturabilirsiniz. ",
  answer7_2: "Detayları için lütfen bizimle iletişime geçin, mail adresimiz: mail@lasolist.com",

  question8: "Okullara özel eğitim serisi sunuyor musunuz?",
  answer8_1: "Evet, öğretmenlere ve okullara özel çözümler sunuyoruz. laSolist ile birlikte kendi okulunuzsa özel eğitim serinizi oluşturabilirsiniz. ",
  answer8_2: "Detayları için lütfen bizimle iletişime geçin, mail adresimiz: mail@lasolist.com",

  introduction_header_1: "Müzik öğrenimin yeni adresi",
  introduction_alt_1:
    "İster yeni başlayan bir müziksever ol, istersen kendini geliştirmek isteyen deneyimli bir bağlama sanatçısı, bizim online bağlama öğrenme platformumuzla öğreniminin zirvesine ulaş. Bunun icin ustanın performansını farklı açılardan izle ve aynı zamanda notaları takip edin. Sende ustalaşmak icin çalışmak istediğin bölümleri döngü özelliğimizle tekrarlayarak tüm dikkatini pratik yapmaya ver.",

  introduction_header_2: "Bağlama çalmanın keyfini çıkart",
  introduction_alt_2: "Kendine özgü tempoda, renkli notalarla çalmayı öğren. Müzik notalarının renklerinde pasta grafiğinde çalınan nota dilimleri sana sunarken, bağlama çalmayı deneyimle ve bir yandan eğlenirken bir yandan da kendini geliştir.",

  welcome: "Hoşgeldin",
  welcome_alt: "Bizimle Bağlama maceran burada başlıyor!",

  latest_songs: "Son Eklenen Şarkılar",
  latest_courses: "Son Eklenen Kurslar",
  latest_instructor_courses: "Son Eklenen Eğitimler",
  lesson: " Ders",
  view_all: "Tümünü Gör",
  view: "Görüntüle",

  cookies_header: "Çerez Politikası",
  cookies_latest_update_date: "Son güncellenme: 24/10/2023",
  cookies_alt_p1: "Biz, laSolist, olarak güvenliğinize önem veriyor ve bu Çerez Politikası ile siz sevgili ziyaretçilerimizi, web sitemizde hangi çerezleri, hangi amaçla kullandığımız ve çerez ayarlarınızı nasıl değiştireceğiniz konularında kısaca bilgilendirmeyi hedefliyoruz",
  cookies_alt_p2:
    "Sizlere daha iyi hizmet verebilmek adına, çerezler vasıtasıyla, ne tür kişisel verilerinizin hangi amaçla toplandığı ve nasıl işlendiği konularında, kısaca bilgi sahibi olmak için lütfen bu Çerez Politikasını okuyunuz. Daha fazla bilgi için Gizlilik Politikamıza göz atabilir ya da bizlerle çekinmeden iletişime geçebilirsiniz.",

  what_is_cookie: "Çerez Nedir?",
  what_is_cookie_p1:
    "Çerezler, kullanıcıların web sitelerini daha verimli bir şekilde kullanabilmeleri adına, cihazlarına kaydedilen küçük dosyacıklardır. Çerezler vasıtasıyla kullanıcıların bilgilerinin işleniyor olması sebebiyle, 6698 sayılı Kişisel Verilerin Korunması Kanunu gereğince, kullanıcıların bilgilendirilmeleri ve onaylarının alınması gerekmektedir.",
  what_is_cookie_p2: "Bizler de siz sevgili ziyaretçilerimizin, web sitemizden en verimli şekilde yararlanabilmelerini ve siz sevgili ziyaretçilerimizin kullanıcı deneyimlerinin geliştirilebilmesini sağlamak adına, çeşitli çerezler kullanmaktayız.",

  mandatory_cookie: "1. Zorunlu Çerezler",
  mandatory_cookie_alt: "Zorunlu çerezler, web sitesine ilişkin temel işlevleri etkinleştirerek web sitesinin kullanılabilir hale gelmesini sağlayan çerezlerdir. Web sitesi bu çerezler olmadan düzgün çalışmaz.",

  performance_cookie: "2. Performans Çerezleri",
  performance_cookie_alt: "Performans çerezleri, ziyaretçilerin web sitesine ilişkin kullanım bilgilerini ve tercihlerini anonim olarak toplayan ve bu sayede web sitesinin performansının geliştirilmesine olanak sağlayan çerezlerdir.",

  functional_cookie: "3. Fonksiyonel Çerezler",
  functional_cookie_alt:
    "Fonksiyonel çerezler, kullanıcıların web sitesine ilişkin geçmiş kullanımlarından yola çıkılarak gelecekteki ziyaretlerinde tanınmalarını ve hatırlanmalarını sağlayan ve bu sayede web sitelerinin kullanıcılara dil, bölge vb. gibi kişiselleştirilmiş bir hizmet sunmasına olanak tanıyan çerezlerdir.",

  ad_cookie: "4. Reklam Çerezleri",
  ad_cookie_alt:
    "Reklam çerezleri, üçüncü taraflara ait çerezlerdir ve web sitelerinde ziyaretçilerin davranışlarını izlemek için kullanılırlar. Bu çerezlerin amaçları, ziyaretçilerin ihtiyaçlarına yönelik ilgilerini çekecek reklamların gösterilmesine yardımcı olmaktır ve sorumluluğu çerez sahibi üçüncü taraflara aittir.",

  data_proccessed_with_cookie: "Çerezler İle İşlenen Kişisel Veriler Nelerdir?",
  data_proccessed_with_cookie_alt:
    "Kimlik (isim, soy isim, doğum tarihi vb.) ve iletişim (adres, e-posta adresi, telefon, IP, konum vb.) bilgileriniz tarafımızca, çerezler (cookies) vasıtasıyla, otomatik veya otomatik olmayan yöntemlerle ve bazen de analitik sağlayıcılar, reklam ağları, arama bilgi sağlayıcıları, teknoloji sağlayıcıları gibi üçüncü taraflardan elde edilerek, kaydedilerek, depolanarak ve güncellenerek, aramızdaki hizmet ve sözleşme ilişkisi çerçevesinde ve süresince, meşru menfaat işleme şartına dayanılarak işlenecektir.",

  what_purpose: "Çerezler Hangi Amaçla Kullanılmaktadır?",
  what_purpose_alt:
    "Web sitemizde, şüpheli eylemlerin tespiti yoluyla güvenliğin sağlanması, kullanıcıların tercihleri doğrultusunda işlevsellik ve performansın artırılması, ürünlerin ve hizmetlerin geliştirilmesi ve kişiselleştirilmesi ile bu hizmetlere ulaşımın kolaylaştırılması, sözleşmesel ve hukuki sorumlulukların yerine getirilmesi amaçlı çerezler kullanmaktadır. Ayrıca kullanıcıların daha geniş kapsamlı hizmet sağlayıcılar ile buluşturulabilmesi amacıyla reklam çerezleri ve üçüncü taraflarla bilgi paylaşımı da söz konusudur.",

  how_to_manage: "Çerezler Nasıl Yönetilmektedir?",
  how_to_manage_alt_p1:
    "Tüm bu açıklamalardan sonra, hangi çerezlerin kullanılacağı konusu, tamamen kullanıcılarımızın özgür iradelerine bırakılmıştır. Çerez tercihlerinizi, tarayıcınızın ayarlarından silerek ya da engelleyerek, web sitemize adım attığınız anda yönetebilir ya da gelecekte, istediğiniz zaman bu ayarları değiştirebilirsiniz. Daha ayrıntılı bilgi için Gizlilik Politikamıza göz atabilir ya da bizlerle ",
  how_to_manage_alt_p2: " e-mail adresi üzerinden iletişime geçebilirsiniz.",

  privacy_header: "Gizlilik Politikası",
  privacy_latest_update_date: "Son güncellenme: 24/10/2023",
  privacy_alt_p1: "Güvenliğiniz bizim için önemli. Bu sebeple bizimle paylaşacağınız kişisel verileriz hassasiyetle korunmaktadır.",
  privacy_alt_p2:
    "Biz, Şirket Adı, veri sorumlusu olarak, bu gizlilik ve kişisel verilerin korunması politikası ile, hangi kişisel verilerinizin hangi amaçla işleneceği, işlenen verilerin kimlerle ve neden paylaşılabileceği, veri işleme yöntemimiz ve hukuki sebeplerimiz ile; işlenen verilerinize ilişkin haklarınızın neler olduğu hususunda sizleri aydınlatmayı amaçlıyoruz.",

  collected_data_reason: "Toplanan Kişisel Verileriniz, Toplanma Yöntemi ve Hukuki Sebebi",
  collected_data_alt:
    "IP adresiniz ve kullanıcı aracısı bilgileriniz, sadece analiz yapmak amacıyla ve çerezler (cookies) vb. teknolojiler vasıtasıyla, otomatik veya otomatik olmayan yöntemlerle ve bazen de analitik sağlayıcılar, reklam ağları, arama bilgi sağlayıcıları, teknoloji sağlayıcıları gibi üçüncü taraflardan elde edilerek, kaydedilerek, depolanarak ve güncellenerek, aramızdaki hizmet ve sözleşme ilişkisi çerçevesinde ve süresince, meşru menfaat işleme şartına dayanılarak işlenecektir.",

  privacy_purpose: "Kişisel Verilerinizin İşlenme Amacı",
  privacy_purpose_alt:
    "Bizimle paylaştığınız kişisel verileriniz sadece analiz yapmak suretiyle; sunduğumuz hizmetlerin gerekliliklerini en iyi şekilde yerine getirebilmek, bu hizmetlere sizin tarafınızdan ulaşılabilmesini ve maksimum düzeyde faydalanılabilmesini sağlamak, hizmetlerimizi, ihtiyaçlarınız doğrultusunda geliştirebilmek ve sizleri daha geniş kapsamlı hizmet sağlayıcıları ile yasal çerçeveler içerisinde buluşturabilmek ve kanundan doğan zorunlulukların (kişisel verilerin talep halinde adli ve idari makamlarla paylaşılması) yerine getirilebilmesi amacıyla, sözleşme ve hizmet süresince, amacına uygun ve ölçülü bir şekilde işlenecek ve güncellenecektir.",

  transferred_collected_data: "Toplanan Kişisel Verilerin Kimlere ve Hangi Amaçlarla Aktarılabileceği",
  transferred_collected_data_alt:
    "Bizimle paylaştığınız kişisel verileriniz; faaliyetlerimizi yürütmek üzere hizmet aldığımız ve/veya verdiğimiz, sözleşmesel ilişki içerisinde bulunduğumuz, iş birliği yaptığımız, yurt içi ve yurt dışındaki 3. şahıslar ile kurum ve kuruluşlara ve talep halinde adli ve idari makamlara, gerekli teknik ve idari önlemler alınması koşulu ile aktarılabilecektir.",

  your_rights: "Kişisel Verileri İşlenen Kişi Olarak Haklarınız",
  your_rights_alt_p1: "KVKK madde 11 uyarınca herkes, veri sorumlusuna başvurarak aşağıdaki haklarını kullanabilir:",
  your_rights_a: "Kişisel veri işlenip işlenmediğini öğrenme,",
  your_rights_b: "Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,",
  your_rights_c: "Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,",
  your_rights_d: "Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,",
  your_rights_e: "Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,",
  your_rights_f: "Kişisel verilerin silinmesini veya yok edilmesini isteme,",
  your_rights_g: "(e) ve (f) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,",
  your_rights_h: "İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,",
  your_rights_i: "Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme, haklarına sahiptir.",
  your_rights_alt_p2: "Yukarıda sayılan haklarınızı kullanmak üzere email@site.com üzerinden bizimle iletişime geçebilirsiniz.",

  contact: "İletişim",
  contact_alt_p1:
    "Sizlere hizmet sunabilmek amaçlı analizler yapabilmek için, sadece gerekli olan kişisel verilerinizin, işbu gizlilik ve kişisel verilerin işlenmesi politikası uyarınca işlenmesini, kabul edip etmemek hususunda tamamen özgürsünüz. Siteyi kullanmaya devam ettiğiniz takdirde kabul etmiş olduğunuz tarafımızca varsayılacak olup, daha ayrıntılı bilgi için bizimle ",
  contact_email: "iletisim@lasolist.com",
  contact_alt_p2: " e-mail adresi üzerinden iletişime geçmekten lütfen çekinmeyiniz.",

  userA_agreement: "Kullanım Koşulları",
  user_agreement_latest_update_date: "Son güncellenme: 24/10/2023",
  user_agreement_p1:
    "Sevgili ziyaretçimiz, lütfen https://lasolist.com web sitemizi ziyaret etmeden önce işbu kullanım koşulları sözleşmesini dikkatlice okuyunuz. Siteye erişiminiz tamamen bu sözleşmeyi kabulünüze ve bu sözleşme ile belirlenen şartlara uymanıza bağlıdır. Şayet bu sözleşmede yazan herhangi bir koşulu kabul etmiyorsanız, lütfen siteye erişiminizi sonlandırınız. Siteye erişiminizi sürdürdüğünüz takdirde, koşulsuz ve kısıtlamasız olarak, işbu sözleşme metninin tamamını kabul ettiğinizin, tarafımızca varsayılacağını lütfen unutmayınız.",
  user_agreement_p2:
    "https://lasolist.com web sitesi Şirket Adı tarafından yönetilmekte olup, bundan sonra SİTE olarak anılacaktır. İşbu siteye ilişkin Kullanım Koşulları, yayınlanmakla yürürlüğe girer. Değişiklik yapma hakkı, tek taraflı olarak SİTE'ye aittir ve SİTE üzerinden güncel olarak paylaşılacak olan bu değişiklikleri, tüm kullanıcılarımız baştan kabul etmiş sayılır.",

  user_agreement_privacy: "Gizlilik",
  user_agreement_privacy_alt: "Gizlilik, ayrı bir sayfada, kişisel verilerinizin tarafımızca işlenmesinin esaslarını düzenlemek üzere mevcuttur. SİTE'yi kullandığınız takdirde, bu verilerin işlenmesinin gizlilik politikasına uygun olarak gerçekleştiğini kabul edersiniz.",

  scope_of_service: "Hizmet Kapsamı",
  scope_of_service_alt: "Şirket Adı olarak, sunacağımız hizmetlerin kapsamını ve niteliğini, yasalar çerçevesinde belirlemekte tamamen serbest olup; hizmetlere ilişkin yapacağımız değişiklikler, SİTE'de yayınlanmakla yürürlüğe girmiş sayılacaktır.",

  copyright: "Telif Hakları",
  copyright_alt:
    "SİTE'de yayınlanan tüm metin, kod, grafikler, logolar, resimler, ses dosyaları ve kullanılan yazılımın sahibi (bundan böyle ve daha sonra 'içerik' olarak anılacaktır) Şirket Adı olup, tüm hakları saklıdır. Yazılı izin olmaksızın site içeriğinin çoğaltılması veya kopyalanması kesinlikle yasaktır.",

  general_provisions: "Genel Hükümler",
  general_provisions_a:
    "Kullanıcıların tamamı, SİTE'yi yalnızca hukuka uygun ve şahsi amaçlarla kullanacaklarını ve üçüncü kişinin haklarına tecavüz teşkil edecek nitelikteki herhangi bir faaliyette bulunmayacağını taahhüt eder. SİTE dâhilinde yaptıkları işlem ve eylemlerindeki, hukuki ve cezai sorumlulukları kendilerine aittir. İşbu iş ve eylemler sebebiyle, üçüncü kişilerin uğradıkları veya uğrayabilecekleri zararlardan dolayı SİTE'nin doğrudan ve/veya dolaylı hiçbir sorumluluğu yoktur.",
  general_provisions_b:
    "SİTE'de mevcut bilgilerin doğruluk ve güncelliğini sağlamak için elimizden geleni yapmaktayız. Lakin gösterdiğimiz çabaya rağmen, bu bilgiler, fiili değişikliklerin gerisinde kalabilir, birtakım farklılıklar olabilir. Bu sebeple, site içerisinde yer alan bilgilerin doğruluğu ve güncelliği ile ilgili tarafımızca, açık veya zımni, herhangi bir garanti verilmemekte, hiçbir taahhütte bulunulmamaktadır.",
  general_provisions_c:
    "SİTE'de üçüncü şahıslar tarafından işletilen ve içerikleri tarafımızca bilinmeyen diğer web sitelerine, uygulamalara ve platformlara köprüler (hyperlink) bulunabilir. SİTE, işlevsellik yalnızca bu sitelere ulaşımı sağlamakta olup, içerikleri ile ilgili hiçbir sorumluluk kabul etmemekteyiz.",
  general_provisions_d:
    "SİTE'yi virüslerden temizlenmiş tutmak konusunda elimizden geleni yapsak da, virüslerin tamamen bulunmadığı garantisini vermemekteyiz. Bu nedenle veri indirirken, virüslere karşı gerekli önlemi almak, kullanıcıların sorumluluğundadır. Virüs vb. kötü amaçlı programlar, kodlar veya materyallerin sebep olabileceği zararlardan dolayı sorumluluk kabul etmemekteyiz.",
  general_provisions_e:
    "SİTE'de sunulan hizmetlerde, kusur veya hata olmayacağına ya da kesintisiz hizmet verileceğine dair garanti vermemekteyiz. SİTE'ye ve sitenin hizmetlerine veya herhangi bir bölümüne olan erişiminizi önceden bildirmeksizin herhangi bir zamanda sonlandırabiliriz.",

  limitations_of_responsibility: "Sorumluluğun Sınırlandırılması",
  limitations_of_responsibility_p1:
    "SİTE'nin kullanımından doğan zararlara ilişkin sorumluluğumuz, kast ve ağır ihmal ile sınırlıdır. Sözleşmenin ihlalinden doğan zararlarda, talep edilebilecek toplam tazminat, öngörülebilir hasarlar ile sınırlıdır. Yukarıda bahsedilen sorumluluk sınırlamaları aynı zamanda insan hayatına, bedeni yaralanmaya veya bir kişinin sağlığına gelebilecek zararlar durumunda geçerli değildir. Hukuken mücbir sebep sayılan tüm durumlarda, gecikme, ifa etmeme veya temerrütten dolayı, herhangi bir tazminat yükümlülüğümüz doğmayacaktır.",
  limitations_of_responsibility_p2: "Uyuşmazlık Çözümü: İşbu Sözleşme'nin uygulanmasından veya yorumlanmasından doğacak her türlü uyuşmazlığın çözümünde, Türkiye Cumhuriyeti yasaları uygulanır.",

  delivery_and_refund_latest_update_date: "Son güncellenme: 07/02/2024",
  delivery_and_refund_alt_p1: "Bu Teslimat ve İade Şartları , 'laSolist Yazılım Eğitim Bilişim A.Ş.' ile müşteri arasında yapılmaktadır.",
  delivery_and_refund_alt_p2: "Bu sözleşmenin konusu, Şirket tarafından işletilen www.lasolist.com platformu üzerinden satılan dijital eğitim içeriklerinin teslimatı ve iade şartlarını düzenlemektir.",
  delivery_header: "Teslimat",
  delivery_alt: "Şirket, kullanıcının seçtiği eğitim paketini satın aldıktan sonra, kullanıcıya dijital olarak erişilebilir bir şekilde içeriği sağlayacaktır. Eğitim içerikleri, kullanıcının seçtiği abonelik paketine göre belirlenen süre boyunca erişilebilir olacaktır.",
  pricing_and_payment_header: "Ücretlendirme ve Ödemeler",
  pricing_and_payment_alt:
    "Kullanıcılar, seçtikleri abonelik paketine göre belirlenen ücreti ödemeyi kabul ederler. Ücretlendirme, abonelik başlangıç tarihinden itibaren aylık olarak tahsil edilecektir. Ödemeler, Şirket tarafından belirlenen güvenli ödeme sistemleri aracılığıyla gerçekleştirilecektir.",
  cancel_and_refund_header: "İptal ve İade",
  cancel_and_refund_alt_p1:
    "Kullanıcılar, satın aldıkları abonelik paketini iptal etmek istediklerinde, iptal talebini belirli bir süre öncesinden (örneğin, bir ay öncesinden) Şirket'e bildirmekle yükümlüdürler. İptal talebi alındıktan sonra, kullanıcının aboneliği sona erer ve içeriklere erişimi durdurulur.",
  cancel_and_refund_alt_p2:
    "İptal edilen abonelikler için herhangi bir geri ödeme yapılmaz. Ancak, kullanıcının hizmetten tam olarak yararlanamadığı durumlarda (örneğin, teknik sorunlar nedeniyle içeriğe erişimde yaşanan süreçlerde aksaklık) Şirket, kullanıcıya alternatif çözümler sunabilir veya kısmi iade yapabilir.",
  privicy_security_header: "Gizlilik ve Güvenlik",
  privicy_security_alt: "Şirket, kullanıcıların kişisel bilgilerini gizli tutmayı ve güvenliğini sağlamayı taahhüt eder. Kullanıcıların kişisel bilgileri, Şirket'in gizlilik politikası çerçevesinde işlenir ve saklanır.",
  changes_header: "Değişiklikler",
  changes_alt_p1: "Şirket, bu sözleşmede değişiklik yapma hakkını saklı tutar. Değişiklikler, kullanıcılara bildirilerek yürürlüğe girer.",
  changes_alt_p2: "Bu Teslimat ve İade Sözleşmesi, taraflar arasında bağlayıcıdır.",
  laSolist_company: "laSolist Yazılım Eğitim Bilişim Anonim Şirketi ",

  distance_latest_update_date: "Son güncelleme: 07/02/2023",
  distance_sides_header: "Taraflar",
  distance_sides_alt_p1: "Bu sözleşme, aşağıda belirtilen taraflar arasında aşağıdaki şartlarla akdedilmiştir:",
  distance_seller_address: "Adres: [Satıcı Firma Adresi]",
  distance_seller_telephone: "Telefon: [Satıcı Telefon Numarası]",
  distance_seller_mail: "E-posta: mail@lasolist.com",
  distance_seller_tax_number: "Vergi Numarası: [Satıcı Vergi Numarası]",
  distance_seller_alt: "(Müşteriye bundan sonra 'Satıcı' olarak anılacaktır)",
  distance_buyer_name_placeholder: "[Alıcı Adı Soyadı]",
  distance_buyer_address: "Adres: [Alıcı Adresi]",
  distance_buyer_telephone: "Telefon: [Alıcı Telefon Numarası]",
  distance_buyer_mail: "E-posta: [Alıcı E-posta Adresi]",
  distance_buyer_alt: "(Müşteriye bundan sonra 'Alıcı' olarak anılacaktır)",
  distance_sides_alt_p2: "Bu sözleşme, Alıcı'nın, Satıcı tarafından işletilen platform üzerinden yapılan uzaktan satışlarla ilgili olarak, aşağıda belirtilen ürün veya hizmetlerin satışı ve teslimi ile ilgilidir.",
  product_info_header: "Ürün/Hizmet Bilgisi",
  product_info_alt: "Satıcı, Alıcı'ya aşağıda belirtilen ürün veya hizmeti sağlayacaktır:",
  product_info_1: "Ürün/Hizmet Adı:",
  product_info_2: "Ürün/Hizmet Miktarı:",
  product_info_3: "Ürün/Hizmet Fiyatı:",
  product_info_4: "Teslimat Tarihi:",
  order_and_delivery_header: "Sipariş ve Teslimat",
  order_and_delivery_alt: "Alıcı, Satıcı tarafından sağlanan ürün veya hizmeti, platform üzerinden verilen sipariş formunu doldurarak sipariş etmiştir. Satıcı, siparişi aldıktan sonra belirtilen teslimat tarihinde Alıcı'ya ürünü/hizmeti teslim etmeyi taahhüt eder.",
  pricing_and_payment_requirements_header: "Fiyat ve Ödeme Şartları",
  pricing_and_payment_requirements_alt: "Ürün/hizmetin fiyatı, sipariş sırasında belirtilen tutardır. Alıcı, ürün/hizmet için belirlenen fiyatı, ödeme sırasında platform üzerinden veya diğer belirtilen ödeme yöntemleri ile ödemeyi taahhüt eder.",
  distance_cancel_header: "İptal ve İade",
  distance_cancel_alt: "Alıcı, teslim edilen ürün/hizmetin iade edilmesi gerektiğinde, Satıcı'nın belirttiği iade prosedürlerine uymayı kabul eder. İade edilen ürün/hizmetin iadesi için Alıcı'nın gönderi masrafları kendisine aittir.",
  distance_guarantee_header: "Garanti",
  distance_guarantee_alt: "Satıcı, satılan ürün/hizmetin kusurlu veya hasarlı olduğunu tespit ederse, Alıcı'ya ücretsiz bir değişim veya iade yapmayı taahhüt eder.",
  distance_effect_header: "Yürürlük",
  distance_effect_alt: "Bu sözleşme, taraflar arasında elektronik ortamda kabul edildikten sonra yürürlüğe girer.",
  distance_dispute_header: "Uyuşmazlık",
  distance_dispute_alt:
    "Bu sözleşmenin uygulanması veya yorumlanmasıyla ilgili herhangi bir uyuşmazlık durumunda taraflar, çözüm için öncelikle dostane bir şekilde müzakere edeceklerdir. Müzakereler sonuç vermezse, uyuşmazlık Türkiye Cumhuriyeti kanunlarına tabi olarak İstanbul Mahkemeleri'nin yetkisine sunulacaktır.",
  other_provisions_header: "Diğer Hükümler",
  other_provisions_alt_p1: "Bu sözleşme, taraflar arasındaki tamamlayıcı ve bağlayıcı bir anlaşmadır. Bu sözleşmenin herhangi bir hükmünün yasalara aykırı veya geçersiz olduğu tespit edilirse, bu durum diğer hükümlerin geçerliliğini etkilemeyecektir.",
  other_provisions_alt_p2: "Bu sözleşme, tarafların mutabakatıyla tarih: [Tarih] tarihinde akdedilmiştir.",
  seller: "Satıcı:",
  sign: "İmza:",
  buyer: "Alıcı:",

  aboutUsHeader: "Hakkımızda",
  aboutUs_p1: "Merhaba! Biz, müziğin büyüleyici dünyasına sizi adım atarken eşlik etmek için buradayız. LaSolist olarak, online bir eğitim platformu olarak amacımız, bağlama tutkunlarını ve meraklılarını sanatın derinliklerine doğru bir yolculuğa çıkarmaktır.",
  aboutUs_p2:
    "Bağlama çalmak, geleneksel Türk müziğinin köklerine uzanan bir serüvendir. Biz, bu geleneksel enstrümanı modern teknolojinin imkanlarıyla bir araya getirerek, herkesin bu güzel enstrümanı öğrenmesini sağlamayı amaçlıyoruz. Öğrencilerimize, başlangıç ​​seviyesinden ileri seviyeye kadar uzanan detaylı bir müfredat sunarak, her adımda onların yanında olmayı taahhüt ediyoruz.",
  aboutUs_p3:
    "LaSolist olarak, sadece bağlama çalmayı öğretmekle kalmıyor, aynı zamanda öğrencilerimize sanatın ve kültürün derinliklerine bir pencere açıyoruz. Bağlama, sadece notalardan değil, aynı zamanda Türk müziğinin zengin tarihinden, duygusal derinliklerinden ve mirasından da beslenir. Bu nedenle, her dersimizde öğrencilerimizi hem müzikal becerilerini geliştirmeye teşvik ediyoruz hem de kültürel bir deneyim sunuyoruz.",
  aboutUs_p4: "LaSolist ailesi olarak, her bir öğrencimizin potansiyeline inanıyor ve onların müzikal yolculuklarında yanlarında olmaktan mutluluk duyuyoruz. Siz de bize katılın ve bağlamanın büyülü dünyasına adım atarak, müziğin gücünü keşfedin.",
  aboutUs_p5: "Bağlama çalma maceranıza bugün başlayın ve müziğin sihirli yolculuğunda bize katılın!",
  aboutUs_p6: "Sevgiyle,",
  aboutUs_p7: "LaSolist Ekibi",
  confirmationCodeDoesntMatch: "Email doğrulama kodunuz eşleşmiyor, lütfen kodu yeniden gönderip tekrar deneyiniz",
  wrongCodePasted: "Yanlış kod girişi yapıldı",

  contactsHeader: "Bizimle İletişime Geçin",
  sendMessage: "Bize Mesaj Gönderin",
  contactsAlt: "İletişim formumuzu kullanarak bize ulaşabilirsiniz. Sorularınızı, geri bildirimlerinizi veya işbirliği taleplerinizi bizimle paylaşın. Size en kısa sürede cevap vereceğiz!",

  ourLocation: "Adresimiz",
  ourLocationAlt: "Savaş, Modern Çarşı İşhanı, 31200 İskenderun/Hatay, Türkiye",

  ourPhone: "Telefon Numaramız",
  ourPhoneAlt: "+90 555 555 55 55",

  ourEmail: "Email Adresimiz",
  ourEmailAlt: "iletisim@lasolist.com",

  message: "Mesajınız",
  enterMessage: "Mesajınızı Giriniz",


  cookies_and_privacy_header: "Çerez Kullanımı ve Gizlilik Politikası",
  cookies_and_privacy_alt_p1: "Web sitemiz, daha iyi bir kullanıcı deneyimi sunmak ve sitemizi geliştirmek amacıyla çerezleri kullanır. Çerezler, kullanıcıları tanıma ve tercihlerini hatırlama gibi işlevleri yerine getirir.",
  cookies_and_privacy_alt_p2: "hakkında daha fazla bilgi almak için yanda bulunan linkleri ziyaret edebilirsiniz.",
  our_user_agreement: "Kullanıcı Sözleşmemiz",
  our_privacy: "Gizlilik Sözleşmemiz",
  our_cookies: "Çerez Politikamız",
  and: "ve",
  acceptPrivacyUser: "Kabul Et",

  updateHeader: "Kullanıcı deneyimini iyileştirmek için yeni özellikler ekliyoruz.",

  pricing_header: "Eğitim Programlarımız",
  pricing_description: "Lüften kendine en uygun Eğitim Programını seç.",

  forStudent: "Öğrenciler İçin Mi?",

  /* Tutorial Section */
  skip: "Atla",
  back: "Geri",
  finish: "Son",

  tutorialHeader: "laSolist Player Turuna Hoşgeldiniz",
  tutorialContent: "laSolist Player'a hoşgeldiniz! Bu turda, uygulamamızın temel özelliklerini ve kullanımını keşfedeceksiniz. Tur boyunca, her bir özellik hakkında detaylı bilgi ve görsel rehberlik sunulacaktır. Hazırsanız, 'İleri' butonuna tıklayarak tura başlayabilirsiniz.",

  backButtonTitle: "Geri Butonu",
  backButtonContent: "Bu butona basarak, şarkı listesine geri dönebilirsiniz. Şarkı listesi, tüm mevcut şarkıları ve kategorileri içerir.",

  videoNameTitle: "Video Adı",
  videoNameContent: "Burada, şu an oynatılan şarkının adını görebilirsiniz.",

  tutorialButtonTitle: "Uygulama Turu Butonu",
  tutorialButtonContent: "Uygulama kullanımında zorlanırsanız buraya tıklayarak detaylı bilgilere erişebilirsiniz. Tur rehberi, uygulamamızın tüm özelliklerini adım adım anlatarak size yardımcı olacaktır.",

  controlsTitle: "Video Kontrolleri",
  controlsContent: "Video kontrolleri, videonun oynatma, duraklatma, ileri ve geri sarma gibi temel işlevlerini kontrol etmenizi sağlar. Ses açma/kapama ve ses seviyesi ayarlama gibi ses kontrollerini de buradan yapabilirsiniz.",

  rewinButtonTitle: "Geri Sarma",
  rewinButtonContent: "Bu butona tıklayarak videoyu 5 saniye geriye sarabilirsiniz.",

  returnStartButtonTitle: "Başa Dönmek",
  returnStartButtonContent: "Bu butona tıklayarak videonun başına dönebilirsiniz.",

  playPauseButtonTile: "Oynatma/Duraklatma",
  playPauseButtonContent: "Videoyu oynatmak veya duraklatmak için bu butona tıklayın.",

  forwardButtonTitle: "İleri Sarma",
  forwardButtonContent: "Bu butona tıklayarak videoyu 5 saniye ileri sarabilirsiniz.",

  muteButtonTitle: "Ses Açma/Kapama",
  muteButtonContent: "Videonun sesini açmak veya kapatmak için bu butona tıklayın.",

  volumeSliderTitle: "Ses Seviyesi",
  volumeSliderContent: "Videonun ses seviyesini ayarlamak için bu kaydırma çubuğunu kullanın.",

  colorSwitchTitle: "Renkli Notalar",
  colorSwitchContent: "laSolist Player, notaları daha hızlı öğrenmenize yardımcı olmak için renkli notalar sistemini kullanır. Her nota, kolayca ayırt edilebilmesi için farklı bir renkte gösterilir. Bu özellik, özellikle yeni başlayanlar için oldukça faydalıdır.",

  coloredNoteTitle: "Renkli Nota Çubuğu",
  coloredNoteContent: "Renkli nota çubuğu, şu an çalınan notanın rengini gösterir. Bu sayede, notaları daha kolay takip edebilir ve hangi notaları çalmanız gerektiğini görebilirsiniz.",

  coloredVideoTitle: "Renkli Video",
  coloredVideoContent: "Renkli videolar, notaları ve telleri görsel olarak daha anlaşılır hale getirir. Videoda, çalınan notalar sol üst köşede gösterilirken, vurulan teller de sağ alt köşede yakın çekim kamerasıyla gösterilir.",

  colorSwitchEndTitle: "Renkli Notalar",
  colorSwitchEndContent: "Normal notaları görmek için tekrar basın.",

  loopButtonTitle: "Döngü Özelliği",
  loopButtonContent: "Döngü özelliği, belirli bir bölümün tekrar tekrar çalınmasını sağlar. Bu sayede, zorlandığınız veya öğrenmek istediğiniz bölümleri daha kolay çalışabilirsiniz.",

  loopRangeTitle: "Döngü Aralığı",
  loopRangeContent: "Döngüye girdikten sonra döngü aralığı 1 ölçü kadar oluşturulur.",

  sliderStartTitle: "Döngü Başlangıcı",
  sliderStartContent: "Döngünün başlangıcı burasıdır. Bu noktayı, kaydırma çubuğunu kullanarak değiştirebilirsiniz.",

  sliderEndTitle: "Döngü Bitişi",
  sliderEndContent: "Döngünün bitiş noktası burasıdır. Bu noktayı, kaydırma çubuğunu kullanarak değiştirebilirsiniz.",

  loopButtonEndTitle: "Döngü Özelliği",
  loopButtonEndContent: "Döngüden çıkmak için tekrar basın.",

  settingsButtonTitle: "Ayarlar Butonu",
  settingsButtonContent: "Ayarlar menüsüne erişmek için bu butona tıklayın. Bu menüden, video kalitesi, video hızı ve tam ekran modu gibi çeşitli ayarları değiştirebilirsiniz.",

  qualityTitle: "Kalite Seçeneği",
  qualityContent: "Videonun kalitesini seçmek için bu menüyü kullanın. Daha yüksek kalite, daha net bir görüntü sunar, ancak daha fazla internet verisi tüketir.",

  qualityMenuTitle: "Kalite Menüsü",
  qualityMenuContent: "Kalite menüsü, mevcut tüm video kalitesi seçeneklerini gösterir. İstediğiniz seçeneği seçmek için tıklayın.",

  qualityBackTitle: "Ayarlar Menüsüne Geri Dönme",
  qualityBackContent: "Ayarlar menüsüne geri dönmek için bu butona tıklayın.",

  speedTitle: "Hız Seçeneği",
  speedContent: "Videonun oynatma hızını seçmek için bu menüyü kullanın. Daha yüksek hız, videoyu daha hızlı oynatır, ancak öğrenmeyi zorlaştırabilir.",

  speedMenuTitle: "Hız Menüsü",
  speedMenuContent: "Hız menüsü, mevcut tüm video hızı seçeneklerini gösterir. İstediğiniz seçeneği seçmek için tıklayın.",

  speedBackTitle: "Hız Menüsünden Geri Dönme",
  speedBackContent: "Ayarlar menüsüne geri dönmek için bu butona tıklayın.",

  fullScreenButtonTitle: "Tam Ekran Modu",
  fullScreenButtonContent: "Videoyu ve notaları tam ekran modunda görüntülemek için bu butona tıklayın. Tam ekran modu, daha sürükleyici bir deneyim sunar.",

  settingsMenuCloseTitle: "Ayarlar Menüsünü Kapatma",
  settingsMenuCloseContent: "Ayarlar menüsünü kapatmak için bu butona tıklayın.",

  tutorialEndTitle: "Turu Sonlandırma",
  tutorialEndContent: "laSolist Player turunu tamamladınız! Artık uygulamamızı kullanmaya hazırsınız. Herhangi bir sorunuz olursa, lütfen uygulama içerisindeki yardım merkezine veya laSolist Player web sitesine bakabilirsiniz.",

  /* src/pages/main/footer/MainFooter.jsx */
  links: "Hızlı Linkler",
  company: "Şirket",
  legal: "Yasal",

  couponCode: "Kupon Kodu",
  enterCouponCode: "Kupon Kodunu Giriniz",
  apply: "Uygula",
  total: "Toplam",
  summary: "Özet",
  originalPrice: "Orijinal Fiyat",
  discountAmount: "Uygulanan İndirim",
  couponSuccessHeader: "Kupon kodu başarıyla uygulandı.",
  couponSuccessAlt: "Kupon kodunuzu başarıyla girdiniz ve 1 aylık üyelik kazandınız. Aşağıdaki tarihler arasında uygulamamızı kullanabilirsiniz!",
  couponNotFoundHeader: "Geçersiz kupon kodu.",
  couponNotFoundAlt: "Kupon kodunuzun doğru olduğundan emin olun. Büyük/küçük harf ayrımına dikkat edin ve koda boşluk eklemeyin.",

  settingsCollapseName: "Ayarlar",
  settingsCollapseKey: "Ayarlar&Konfigürasyonlar",

  settingsName: "Ayarlar",
  settingsRoute: "/ayarlar",
  settingsKey: "Ayarlar",

  configurationName: "Konfigürasyonlar",
  configurationRoute: "/konfigurasyonlar",
  configurationKey: "Konfigürasyonlar",

  previewPlanHeader: "Paket Önizlemesi",
  previewPlanAlt: "Seçtiğiniz pakete ait bilgiler aşağıda yer almaktadır",
};

export default locale;
