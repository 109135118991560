import * as React from 'react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { Card, Fade, Icon } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setControlsData } from 'store/main/videoPlayerSlice';
import { useTranslation } from 'react-i18next';
import MDBox from 'components/MDBox';

export default function SettingsMenu() {
  const dispatch = useDispatch();
  const { controlsData } = useSelector((state) => state.videoPlayer);

  const { t } = useTranslation();

  const handleOpenQuality = () => {
    dispatch(setControlsData({ ...controlsData, showQuality: true, showMenu: false, showSpeed: false }));
  }

  const handleOpenSpeed = () => {
    dispatch(setControlsData({ ...controlsData, showSpeed: true, showMenu: false, showQuality: false }))
  }

  return (
    <Fade in={controlsData.showMenu} style={{ transitionDuration: "1000ms" }}>
      <Card sx={{ marginBottom: 4 }}>
        <MDBox
          className={"SettingsMenu"}
          component={MenuList}
          sx={{
            transition: "all 250ms ease-in",
          
            padding: 2,

          }}
        >
          <MenuItem
            className='Quality'
            onClick={handleOpenQuality}
            sx={{
              // background: videoData.quality === item.quality && "#f0f2f5",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <ListItemIcon>
              <MDBox component={Icon}>tune</MDBox>
            </ListItemIcon>


            <Typography

              fontWeight={"500"}
              color="text.secondary"
            >
              {t("Quality")}
            </Typography>
          </MenuItem>
          <MenuItem
            className='Speed'
            onClick={handleOpenSpeed}
            sx={{
              // background: videoData.quality === item.quality && "#f0f2f5",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <ListItemIcon>
              <MDBox component={Icon}>speed_rounded
              </MDBox>
            </ListItemIcon>


            <Typography
              fontWeight={"500"}
              color="text.secondary"
            >
              {t("PlaybackRate")}
            </Typography>

          </MenuItem>
        </MDBox>
      </Card>
    </Fade >
  );
}