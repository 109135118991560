import { Card, Container, Grid, Icon, TextField } from '@mui/material'
import MDTypography from 'components/MDTypography'
import CoverLayout from 'pages/main/layout/CoverLayout'
import React from 'react'

import bgImage from "assets/images/laSolist_player_01.jpeg";
import { useTranslation } from 'react-i18next'
import { useForm } from 'features/hooks';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';

import { TbHome, TbMail, TbPhone } from "react-icons/tb";
import ContactItem from './ContactItem';

import { grey } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { addMessage } from 'store/analytics/messagesSlice';

const defaultFormState = {
  name: "",
  email: "",
  message: "",
}

function Contact() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { form, handleChange, setForm } = useForm(defaultFormState);

  const handleSubmit = () => {
    dispatch(addMessage(form));
    setForm(defaultFormState);
  }

  const isValidEmail = (text) => {
    // Basit bir e-posta regex'i
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(text);
  };

  const canBeSubmitted = () => {
    return (
      form.name.length > 0 && form.email.length > 0 &&
      form.message.length > 0 && isValidEmail(form.email)
    )
  }

  return (
    <CoverLayout image={bgImage} coverHeight={"calc(100vh - 2rem)"}>
      <Container>
        <Grid container spacing={4} sx={{ width: "full", marginY: 20 }}>
          <Grid item xs={12} lg={7}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                padding: 3
              }} >

              <MDBox>
                <MDTypography
                  fontSize={{ xs: "1rem", lg: "1.44rem", xl: "1.88rem" }}
                  fontWeight="bold">
                  {t("contactsHeader")}
                </MDTypography>

                <MDTypography
                  fontSize={{ xs: "1rem", lg: "1.15rem", xl: "1.30rem" }}
                >
                  {t("contactsAlt")}
                </MDTypography>
              </MDBox>

              <MDBox marginTop={{ xs: 4, lg: 0 }} gap={{ xs: 2, lg: 1 }} sx={{ display: "flex", flexDirection: "column", }}>
                <ContactItem
                  Icon={TbHome}
                  header={"ourLocation"}
                  alt={"ourLocationAlt"}
                  route={"https://maps.app.goo.gl/72CuZAobhdRZ7rNcA"}
                  target={"_blank"}
                />
                <ContactItem
                  Icon={TbPhone}
                  header={"ourPhone"}
                  alt={"ourPhoneAlt"}
                  route={"tel:+905555555555"}
                />
                <ContactItem
                  Icon={TbMail}
                  header={"ourEmail"}
                  alt={"ourEmailAlt"}
                  route={`mailto:${t("ourEmailAlt")}`}
                />
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12} lg={5} sx={{ display: "flex", flexDirection: "column", gap: 1, }}>
            <Card sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", padding: 3, gap: 1 }} >
              <MDTypography
                fontSize={{ xs: "1rem", lg: "1.44rem", xl: "1.88rem" }}
                fontWeight="bold">
                {t("sendMessage")}
              </MDTypography>
              <TextField
                margin="dense"
                id="name"
                name="name"
                label={t("name")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.name}
                onChange={handleChange}
                required
                // error={form.name ? false : true}
                helperText={t("enterName")}
                focused
              />
              <TextField
                margin="dense"
                id="email"
                name="email"
                label={t("email")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.email}
                onChange={handleChange}
                required
                // error={form.email ? false : true}
                helperText={t("enterEmail")}
                focused
              />
              <TextField
                margin="dense"
                id="message"
                name="message"
                label={t("message")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.message}
                onChange={handleChange}
                required
                // error={form.message ? false : true}
                helperText={t("enterMessage")}
                focused
                multiline
                rows={4}
              />
              <MDButton
                disabled={!canBeSubmitted()}
                onClick={handleSubmit}
                color={"info"}
                variant={"gradient"}>
                {t("send")}
              </MDButton>
            </Card>
          </Grid>
        </Grid>

      </Container>
    </CoverLayout>
  )
}

export default Contact