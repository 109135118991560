/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from "react-i18next";
import { useState } from "react";
// Material Dashboard 2 PRO React components


function InstructorCoursesCard({ image, title, description, instructor, action, duration }) {
  const [error, setError] = useState(false);

  const handleError = (error) => {
    setError(true);
  }

  const { t } = useTranslation();
  return (
    <Card sx={{ height: "100%", display: "flex", justifyContent: "space-between" }} component={Link} to={action.route}>
      <MDBox position="relative" borderRadius="lg" mt={-3} mx={2}>
        <MDBox
          component="img"
          src={error ? "https://lasolist.com/uploads/default-cover/course_lesson.png" : image}
          onError={handleError}
          alt={title}
          borderRadius="lg"
          shadow="md"
          width="100%"
          sx={{ height: "300px", objectFit: "cover" }}
          position="relative"
          zIndex={1}
        />
        <MDBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top="3%"
          sx={{
            backgroundImage: `url(${error ? "https://lasolist.com/uploads/default-cover/course_lesson.png" : image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>
      <MDBox sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }} p={3}>
        <MDBox>
          <MDTypography fontSize={{ xs: "1.1rem", lg: "1.2rem", xl: "1.3rem" }} display="inline" variant="h4" textTransform="capitalize" fontWeight="bold">
            {title}
          </MDTypography>
          <MDBox mt={2} mb={3}>
            <MDTypography fontSize={{ xs: "0.9rem", lg: "1.0rem", xl: "1.1rem" }} variant="body2" component="p" color="text">
              {description}
            </MDTypography>
          </MDBox>
          <MDBox component={Link} to={`/${t("instructor")}/${instructor.id}`} mt={1} mb={3} display={"flex"} alignItems={"center"}>
            <MDBox
              component="img"
              src={instructor.image}
              alt={instructor.name}
              shadow={"xl"}
              borderRadius={"100%"}
              width={28}
              height={28}
              marginRight={1}
            />
            <MDTypography fontSize={{ xs: "0.9rem", lg: "1.0rem", xl: "1.1rem" }} variant="h5" component="p" color="text">
              {instructor.name}
            </MDTypography>
          </MDBox>
        </MDBox>
        {action.type === "external" ? (
          <MuiLink href={action.route} target="_blank" rel="noreferrer">
            <MDButton color={action.color ? action.color : "dark"}>{action.label}</MDButton>
          </MuiLink>
        ) : (
          <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <MDTypography variant="h6" fontWeight={"bold"} component="p">
              {duration}
            </MDTypography>

            <MDBox sx={{ display: "flex" }}>
              <MDButton component={Link} to={action.route} sx={{ marginLeft: "4px" }} variant="outlined" color="info" iconOnly>
                <KeyboardArrowRightIcon />
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

// Typechecking props for the SimpleBlogCard
InstructorCoursesCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  type: PropTypes.bool.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  instructor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

export default InstructorCoursesCard;
