/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Icon, InputAdornment, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useForm } from "features/hooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUserInfo } from "store/main/subscriptionsSlice";
import CountrySelect from "./CountrySelect";
import { useState } from "react";
import CountryOptions from "./CountryOptions";
import { updateUserInfo } from "store/main/userInfosSlice";
import { addUserInfo } from "store/main/userInfosSlice";
import { loadUser } from "store/auth/authSlice";
import { checkSubscription } from "store/payment/paymentsSlice";
import { addSubscription } from "store/main/subscriptionsSlice";
import { updateGuestStatus } from "store/analytics/analyticsSlice";
import { useNavigate } from "react-router-dom";
import { addTrialToPremiumSubscription } from "store/main/subscriptionsSlice";
import { updateSubscription } from "store/main/subscriptionsSlice";
import StepHeader from "../components/StepHeader";

const defaultFormState = {
  name: "",
  surname: "",
  gsmNumber: "",
  identityNumber: "",
  email: "",
};

function UserInfo({ handleNext, handleBack }) {
  const { subscriptionsInfo } = useSelector((state) => state.payments);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [countryValue, setCountryValue] = useState({
    code: '',
    label: '',
    phone: ''
  })

  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.subscriptions);
  const { userName, userInfoData, name, surname } = useSelector((state) => state.auth);
  const { country_code } = useSelector((state) => state.location);

  useEffect(() => {
    const country = CountryOptions.find((item) => item.code === country_code);
    setCountryValue(country);
  }, [country_code])

  console.log(name, surname);

  const handleNextStep = () => {
    if (country_code === "TR") {
      dispatch(setUserInfo({ ...userInfo, ...form, userName }));
    }
    else {
      let dataWithIdentity = {
        ...userInfo,
        ...form,
        userName,
      };
  
      console.log("SUB", subscriptionsInfo);
      if (userInfoData !== null) {
        dispatch(updateUserInfo({ ...dataWithIdentity, identityNumber: "" }));
      }
      if (userInfoData === null || userInfoData === undefined) {
        dispatch(addUserInfo({ ...dataWithIdentity, identityNumber: "" }))
          .then(() => dispatch(loadUser()));
      }
      if (subscriptionsInfo.length === 0) {
        const currentDate = new Date();
        dispatch(addSubscription({
          pricingPlanId: dataWithIdentity.pricingPlanId,
          userName: dataWithIdentity.email,
          status: "WAITING",
          payMethod: "paypal",
          startDate: currentDate,
          endDate: new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000))
        })).then(() => dispatch(loadUser()));
      }
  
      if (subscriptionsInfo.length > 0) {
        const pendingSubscription = subscriptionsInfo.find((f) => f.status === "WAITING" || f.status === "TRIAL");
  
  
  
        if (pendingSubscription.status === "WAITING") {
          dispatch(updateSubscription({
            ...pendingSubscription,
            subscriptionId: pendingSubscription.subscriptionId,
            pricingPlanId: dataWithIdentity.pricingPlanId,
            userName: dataWithIdentity.email,
            status: "WAITING",
            payMethod: "paypal",
            startDate: new Date(),
          }));
        }
        else if (pendingSubscription.status === "TRIAL") {
          dispatch(addTrialToPremiumSubscription({
            ...pendingSubscription,
            subscriptionId: pendingSubscription.subscriptionId,
            pricingPlanId: dataWithIdentity.pricingPlanId,
            userName: dataWithIdentity.email,
            status: "WAITING",
            payMethod: "paypal",
            trialStartDate: pendingSubscription.startDate,
            premiumStartDate: new Date(),
            startDate: new Date(),
          }));
        }
  
        
      }
  
  
      dispatch(setUserInfo(dataWithIdentity));
      dispatch(loadUser());
      dispatch(checkSubscription({ userName }));
      handleNext(1);
    }
    handleNext(country_code === "TR" ? 1 : 2);
  }

  useEffect(() => {
    if (userInfoData) {
      console.log({ ...userInfo, ...userInfoData, identityNumber: "" });
      setForm({ ...userInfo, ...userInfoData, identityNumber: "" });
    }
    else if (userInfo) {
      setForm({ ...defaultFormState, ...userInfo });
    }
  }, [userInfo, userInfoData, setForm])


  useEffect(() => {
    setInForm("email", userName);
    setInForm("name", name);
    setInForm("surname", surname);
  }, [userName, setInForm, name, surname])

  const canBeSubmitted = () => {
    return (
      form.name.length > 0 && form.surname.length > 0 &&
      (country_code === "TR" ? form.gsmNumber.length === 10 : true) &&
      (country_code === "TR" ? form.identityNumber.length === 11 : true) &&
      form.email.length > 0
    )
  }

  const handleFinish = () => {
    let dataWithIdentity = {
      ...userInfo,
      ...form,
    };

    if (userInfoData !== null) {
      dispatch(updateUserInfo({ ...dataWithIdentity, identityNumber: "" }));
    }
    if (userInfoData === null || userInfoData === undefined) {
      dispatch(addUserInfo({ ...dataWithIdentity, identityNumber: "" }))
        .then(() => dispatch(loadUser()));
    }

    const currentDate = new Date();
    dispatch(addSubscription({
      pricingPlanId: dataWithIdentity.pricingPlanId,
      userName: dataWithIdentity.email,
      payMethod: country_code === "TR" ? "iyzico" : "paypal",
      status: "TRIAL",
      startDate: currentDate,
      endDate: new Date(currentDate.getTime() + (365 * 24 * 60 * 60 * 1000)),
    })).then(() => {
      dispatch(loadUser())
      dispatch(checkSubscription({ userName: dataWithIdentity.email })).then((params) => {
        const { subscriptionLoaded, isTrial } = params.payload;
        if (subscriptionLoaded && isTrial) {
          navigate("/");
        }
      })
    });

    // dispatch(updateGuestStatus({
    //   userName: dataWithIdentity.email,
    //   status: "trial",
    // }));
  }


  return (
    <MDBox>
      <StepHeader header={"userInfoHeader"} alt={"userInfoAlt"}/>
      <MDBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              autoFocus
              id="name"
              name="name"
              type="text"
              label={t("name")}
              onChange={handleChange}
              value={form.name}
              fullWidth
              variant="outlined"
              error={form.name ? false : true}
              required
              focused
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              autoFocus
              id="surname"
              name="surname"
              type="text"
              label={t("surname")}
              onChange={handleChange}
              value={form.surname}
              fullWidth
              error={form.surname ? false : true}
              variant="outlined"
              required
              focused
            />
          </Grid>
          <Grid item xs={12} md={country_code === "TR" ? 4 : 6}>



            {
              country_code === "TR" ?
                <TextField
                  autoFocus
                  inputProps={{ maxLength: 10 }}
                  id="gsmNumber"
                  name="gsmNumber"
                  type="text"
                  label={t("gsmNumber")}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment sx={{
                      '& .MuiTypography-root': {
                        fontSize: "1rem",
                        fontWeight: "600",
                      },
                    }} position="start">+90</InputAdornment>,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      // paddingRight: "0",
                      padding: "0.75rem 0rem"
                    },
                  }}
                  value={form.gsmNumber}
                  error={form.gsmNumber && form.gsmNumber.length < 10 ? true : false}
                  helperText={t("enterGsmNumber")}
                  fullWidth
                  variant="outlined"
                  focused
                />
                :
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <CountrySelect
                      countryValue={countryValue}
                      countries={CountryOptions}
                      setCountryValue={setCountryValue}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      autoFocus
                      id="gsmNumber"
                      name="gsmNumber"
                      type="text"
                      label={t("gsmNumber")}
                      onChange={handleChange}
                      value={form.gsmNumber}
                      fullWidth
                      variant="outlined"
                      focused
                    />
                  </Grid>
                </Grid>
            }
          </Grid>

          <Grid item xs={12} md={country_code === "TR" ? 4 : 6}>
            <TextField
              autoFocus

              id="email"
              name="email"
              type="text"
              label={t("email")}
              onChange={handleChange}
              value={form.email}
              disabled
              fullWidth
              variant="outlined"
              required
              focused
            />
          </Grid>
          {
            country_code === "TR" && <Grid item xs={12} md={4}>
              <TextField
                autoFocus
                id="identityNumber"
                name="identityNumber"
                type="text"
                label={t("identityNumber")}
                onChange={handleChange}
                value={form.identityNumber}
                inputProps={{ maxLength: 11 }}
                error={form.identityNumber.length < 11 ? true : false}
                helperText={t("enterIdentityNumber")}
                fullWidth
                variant="outlined"
                required
                focused
              />
            </Grid>
          }
        </Grid>
      </MDBox>
      <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
        <MDButton variant="outlined" color="dark" onClick={() => handleBack(1)}>
          {t("goBack")}
        </MDButton>

        {
          userInfo.isTrial && country_code !== "TR" ? <MDButton
            disabled={!canBeSubmitted()}
            variant="gradient"
            color="dark"
            onClick={handleFinish}
          >
            {t("finish")} <Icon>navigate_next</Icon>
          </MDButton> : <MDButton
            variant="gradient"
            color="dark"
            onClick={handleNextStep}
            disabled={!canBeSubmitted()}
          >
            {t("next")} <Icon>navigate_next</Icon>
          </MDButton>
        }
      </MDBox>
    </MDBox>
  );
}

export default UserInfo;
