import { Icon } from '@mui/material';
import React from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setControlsData } from 'store/main/videoPlayerSlice';
import SpeedValues from './SpeedValues';

function SpeedButton() {
  const dispatch = useDispatch();
  const { controlsData } = useSelector((state) => state.videoPlayer);
  const { showSpeed } = controlsData;
  const handleMouseEnter = () => {
    if (showSpeed){
      dispatch(setControlsData({ ...controlsData, showSpeed: false, showVolume: false }));
    }
    else{
      dispatch(setControlsData({ ...controlsData, showSpeed: true, showVolume: false }));
    }
  }

  return (
    <div className={"SpeedContainer"}>
      <SpeedValues />
      <button onClick={handleMouseEnter} className="marginX">
        <Icon>speed_rounded</Icon>
      </button>
    </div>
  )
}

export default SpeedButton