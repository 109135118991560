import React from 'react'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import { useSelector } from 'react-redux';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  borderRadius: "10px",
  '&:before': {
    display: 'none',
  },
  marginBottom: "10px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon color="info" sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: "10px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



function PayPalInformations({ expanded, handleExpand, form, handleChange, paymentIntervalValue, setPaymentIntervalValue, paymentIntervalOptions }) {
  const pricingPlanDialog = useSelector(
    ({ pricingPlans }) => pricingPlans.pricingPlanDialog
  );

  const { t } = useTranslation();


  return (
    <Accordion expanded={expanded === 'panel2'} onChange={handleExpand('panel2')}>
      <AccordionSummary
        aria-controls="panel2bh-content"
        id="panel2bh-header"
      >
        <Typography variant="h5">
          {t("paypalInformations")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          autoFocus
          margin="dense"
          id="paypalProductReference"
          name="paypalProductReference"
          label={t("paypalProductReference")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.paypalProductReference}
          disabled
        />
        {/* <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="productName"
                name="productName"
                label={t("productName")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.productName}
                onChange={handleChange}
                required
                error={form.productName ? false : true}
                helperText={t("enterProductName")}
                focused
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="productDescription"
                name="productDescription"
                label={t("productDescription")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.productDescription}
                onChange={handleChange}
                required
                error={form.productDescription ? false : true}
                helperText={t("enterProductDescription")}
              />
            </Grid>
          </Grid>
        </MDBox> */}

        <TextField
          autoFocus
          margin="dense"
          id="pricingPlanReference"
          name="pricingPlanReference"
          label={t("paypalPricingPlanReference")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.pricingPlanReference}
          disabled
        />

        <TextField
          autoFocus
          margin="dense"
          id="paypalPricingPlanName"
          name="paypalPricingPlanName"
          label={t("paypalPricingPlanNameAdmin")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.paypalPricingPlanName}
          onChange={handleChange}
          required
          error={form.paypalPricingPlanName ? false : true}
          helperText={t("enterPaypalPricingPlanName")}
          focused
        />

        <TextField
          autoFocus
          margin="dense"
          id="pricingPlanDescription"
          name="pricingPlanDescription"
          label={t("paypalPricingPlanDescription")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.pricingPlanDescription}
          onChange={handleChange}
          required
          error={form.pricingPlanDescription ? false : true}
          helperText={t("enterPaypalPricingPlanDescription")}
          focused
        />

        <Grid container spacing={2} mt={0.1}>
          <Grid item xs={6}>
            <MDBox>
              <Autocomplete
                value={paymentIntervalValue} // Dikkat: statusValues -> statusValue olarak değiştirildi
                options={paymentIntervalOptions}
                getOptionLabel={(option) => t(option.name)}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                disabled={pricingPlanDialog.type === "edit" ? true : false}
                // fullWidth
                renderInput={(params) => (
                  <TextField {...params} label={t("paymentInterval")} />
                )}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                    setPaymentIntervalValue({
                      id: newValue, // newValue.id -> newValue olarak değiştirildi
                      name: newValue,
                      count: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    setPaymentIntervalValue({
                      name: newValue.inputValue,
                    });
                  } else {
                    setPaymentIntervalValue(newValue);
                  }
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <TextField
              // autoFocus
              margin="dense"
              id="price"
              name="price"
              label={t("price")}
              type="number"
              fullWidth
              variant="outlined"
              value={form.price}
              onChange={handleChange}
              required
              error={form.price ? false : true}
              helperText={t("enterPrice")}
              disabled={pricingPlanDialog.type === "edit" ? true : false}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default PayPalInformations