import React from 'react'
import PageLayout from 'pages/main/layout/PageLayout'
import CheckoutForm from './CheckoutForm'
import Wizard from './Wizard'

function ChangeCardDashboard() {
  return (
    <PageLayout>
      <Wizard />
    </PageLayout>
  )
}

export default ChangeCardDashboard