// Image
import { Alert, Card, Snackbar } from "@mui/material";
import bgImage from "assets/images/laSolist_player_01.jpeg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useForm } from "features/hooks";
import AuthCoverLayout from "pages/main/layout/AuthCoverLayout";

import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "store/auth/authSlice";

const defaultFormState = {
  email: "",
};

function ForgotPassword() {

  const navigate = useNavigate();

  const [emailSent, setEmailSent] = useState({
    response: false,
    status: "success",
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setEmailSent({
      response: false,
      status: "",
      message: "",
    })
  };

  const dispatch = useDispatch();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);
  const { t, i18n } = useTranslation();

  const handleReset = (e) => {
    e.preventDefault();

    let data = {
      ...form,
      locale: i18n.language
    }

    dispatch(forgotPassword(data)).then((params) => {
      try {
        if (params.payload.success) {
          setEmailSent({
            response: true,
            status: "success",
            message: "forgot_password_success",
          });

          setTimeout(() => {
            navigate("/sign-in");
          }, 2000);
        }
      } catch (error) {
        setEmailSent({
          response: true,
          status: "error",
          message: "forgot_password_error",
        });
      }

    })
  }

  function canBeSubmitted() {
    return (form.email.length > 0)
  }

  return (
    <AuthCoverLayout
      title={t("forgot_password_header")}
      description={t("forgot_password_message")}
      image={bgImage}
    >
      <Card sx={{ maxWidth: "450px", mx: "auto" }}>
        <MDBox p={3} textAlign="center">
          <MDBox mb={1} textAlign="center">
            <MDTypography variant="h4" fontWeight="bold">
              {t("forgot_password_header")}
            </MDTypography>
          </MDBox>
          <MDTypography variant="body2" color="text">
            {t("forgot_password_message")}
          </MDTypography>
        </MDBox>
        <Snackbar
          open={emailSent.response}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Alert onClose={handleClose} severity={emailSent.status} sx={{ width: '100%' }}>
            {t(emailSent.message)}
          </Alert>
        </Snackbar>
        <MDBox p={3} component="form" role="form">
          <MDBox mb={2}>
            <MDInput
              type="email"
              label={t("email")}
              fullWidth
              value={form.email}
              onChange={handleChange}
              id="email"
              name="email"
            />
          </MDBox>

          <MDBox mt={2} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              size="large"
              fullWidth
              disabled={!canBeSubmitted()}
              onClick={handleReset}
            >
              {t("reset_password")}
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </AuthCoverLayout>
  )
}

export default ForgotPassword