import React from 'react'
import MDBox from 'components/MDBox'
import { selectlessonsById } from 'store/main/lessonsSlice';
import ReactHlsPlayer from 'react-hls-player';
import useShortcuts from 'hooks/useShortcuts';
import useResizer from 'hooks/useResizer';
import VideoHeader from 'components/Player/VideoHeader';
import SettingsPopover from 'components/Controls/Menu';
import VideoControls from 'components/Controls';
import { useSelector } from 'react-redux';
import VideoIndicator from 'components/Player/VideoIndicator';
import { usePlayer } from 'hooks/PlayerProvider';

function LessonVideoPlayer({ fullScreenHandler }) {
  const {
    videoData,
  } = useSelector((state) => state.videoPlayer);



  const {
    videoRef,
    onError,
    videoSource,
    handlePlay,
    handlePause,
    handleEnded,
    onLoad,
    onRightClick,
    onTimeUpdateWithoutNote,
    handleBuffer,
    getLessonName,
  } = usePlayer();

  useShortcuts(videoRef, selectlessonsById);
  const { videoHeight } = useResizer(videoRef);




  return (
    <MDBox className={"GridContainer"}>
      <VideoHeader name={getLessonName()} />
      <ReactHlsPlayer
        className='Video'
        playerRef={videoRef}
        onClick={videoData.isPlaying ? handlePause : handlePlay}
        onLoadedData={() => onLoad(false)}
        onTimeUpdate={onTimeUpdateWithoutNote}
        onProgress={handleBuffer}
        disablePictureInPicture={true}
        src={videoSource}
        autoPlay={false}
        onError={onError}
        playsInline
        onEnded={handleEnded}
        height={videoHeight}
        width={"auto"}
        onContextMenu={onRightClick}
      />
      <SettingsPopover videoRef={videoRef} selectSongState={selectlessonsById} />
      <VideoIndicator selectSongState={selectlessonsById} />
      <VideoControls
        videoRef={videoRef}
        fullScreenHandler={fullScreenHandler}
        selectSongState={selectlessonsById}
      />
    </MDBox>
  )
}

export default LessonVideoPlayer