import React, { useEffect, useState } from "react";
import AdminLayout from "pages/main/layout/AdminLayout";
import InstrumentTable from "./InstrumentTable";
import InstrumentDialog from "./InstrumentDialog";
import { getInstruments } from "store/main/instrumentsSlice";
import { useDispatch } from "react-redux";
import LoadingComponent from "components/Loading";
import { removeInstrument } from "store/main/instrumentsSlice";
import ConfirmDialog from "pages/confirmation/ConfirmDialog";

const AdminInstrumentsDashboard = (props) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  
  useEffect(() => {
    dispatch(getInstruments()).then(() => setDataLoading(false));
  }, [dispatch]);

  if (dataLoading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <InstrumentDialog />
      <InstrumentTable />
      <ConfirmDialog handleDeleteData={removeInstrument} />
    </AdminLayout>
  );
};

AdminInstrumentsDashboard.propTypes = {};

export default AdminInstrumentsDashboard;
