import React from 'react'
import { useSelector } from 'react-redux';
import VideoSlider from '../Sliders/VideoSlider';
import CustomProgressBar from 'components/Player/CustomProgressBar';

function Progress({ handleProgressClick, selectSongState }) {
  const { loopData, videoData } = useSelector((state) => state.videoPlayer);

  const { currentTime, duration, buffer } = videoData;

  if (loopData.isLoop) {
    return <VideoSlider selectSongState={selectSongState}/>
  }

  return <CustomProgressBar value={currentTime * 100 / duration} bufferValue={buffer * 100 / duration} onClick={handleProgressClick} />
}

export default Progress