import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useRatio } from './useRatio';
import useUtils from './useUtils';
import { setDraggableImageData } from 'store/main/videoPlayerSlice';
import { setVideoData } from 'store/main/videoPlayerSlice';
import { videoProxy } from 'features/api/proxy';
import { setLoopData } from 'store/main/videoPlayerSlice';
import { useTranslation } from 'react-i18next';
import { updateVideoAnalyze } from 'store/analytics/videoAnalyzeSlice';

const PlayerContext = createContext();

export const PlayerProvider = ({ children, selectSongState, videoId }) => {

  const videoRef = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { videoData, draggableImageData, loopData } = useSelector((state) => state.videoPlayer);
  const video = useSelector((state) => selectSongState(state, videoId ? videoId : id));
  const { details, folderNumber, songInfo } = video;


  const { ratio, width, height } = useRatio(details && details.noteHeight ? details.noteHeight : 150);

  const { calculatePosition, calculateLoopPosition, calculateLoopDifference, getLoopVariables } = useUtils(details);


  const { t, i18n } = useTranslation();

  const [videoSource, setVideoSource] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [lastPos, setLastPos] = useState(true);

  // Forward Button -> src/components/Controls/Buttons/ForwardButton.jsx
  const handleForward = () => {
    videoRef.current.currentTime += 5;
  }

  // Rewind Button -> src/components/Controls/Buttons/RewindButton.jsx
  const handleRewind = () => {
    videoRef.current.currentTime -= 5;
  }

  // Return Start Button -> src/components/Controls/Buttons/ReturnStartButton.jsx 
  const handleReturned = () => {
    const startTime = details.videoStart / 1000;
    videoRef.current.currentTime = startTime;
    dispatch(setDraggableImageData({
      leftPosition: calculatePosition(startTime),
    }));
    dispatch(setVideoData({ currentTime: startTime }))
  }

  /* 
    Set Video Quality and Color Switch
    Color Switch -> src/components/Controls/Buttons/ColorSwitch.jsx
  */
  useEffect(() => {
    try {
      const qualityMap = {
        AUTO: "",
        "2K": "2K",
        HD: "HD",
        SD: "SD",
      };

      const coloredSuffix = videoData.isColored ? "_C" : "";
      const qualitySuffix = qualityMap[videoData.quality] || "";

      if (videoData.quality === "AUTO") {
        const videoSource = `${videoProxy}/${folderNumber}/${folderNumber}${videoData.isColored ? "_C" : ""}.m3u8`;
        console.log(videoSource);
        setVideoSource(videoSource);
      }
      else {
        const videoSource = `${videoProxy}/${folderNumber}/${qualitySuffix}${coloredSuffix}/${qualitySuffix || folderNumber}.m3u8`;
        console.log(videoSource);

        setVideoSource(videoSource);
      }
      if (videoData.currentTime > 0 && isLoaded) {
        videoRef.current.load();
        videoRef.current.currentTime = videoData.currentTime;
        dispatch(setVideoData({ isPlaying: false, isLoading: true }))
        setTimeout(() => {

          if (videoData.isPlaying) {
            videoRef.current.play();
          }
          dispatch(setVideoData({ isLoading: false, isPlaying: true }))

        }, 100);
      }
    } catch (error) {
      console.log(error);
    }
  }, [folderNumber, videoData.quality, videoData.isColored, isLoaded, dispatch, videoRef, videoData.qualityChanged]);

  // Set Video Playback Rate -> src/components/Controls/Menu/SpeedMenu.jsx
  const setPlaybackRate = (rate, transition) => {
    videoRef.current.playbackRate = rate;
    dispatch(setDraggableImageData({ transition }));
    dispatch(setVideoData({ speed: rate }));
  }

  /*
    Handle Volume Change
    Mute Button -> src/components/Controls/Buttons/VolumeButton/MuteButton.jsx
    Volume Slider -> src/components/Controls/Buttons/VolumeButton/VolumeSlider.jsx 
  */
  const setVolume = (volume) => {
    videoRef.current.volume = volume
    dispatch(setVideoData({ volume }));
  }

  // Progress Bar Click -> src/components/Controls/Progress/Progress.jsx
  const handleProgressClick = (e) => {
    const progressWidth = e.target.getBoundingClientRect().width;
    const clickPosition = e.nativeEvent.offsetX;
    const timePercentage = clickPosition / progressWidth;
    const currentTime = timePercentage * videoData.duration;
    dispatch(setVideoData({ currentTime, isPlaying: true }));
    const leftPosition = calculatePosition(currentTime);

    const middlePosition = getMiddlePositionValue(draggableImageData.leftMinPosition, draggableImageData.leftMaxPosition, leftPosition);
    dispatch(setDraggableImageData({ leftPosition: middlePosition }));
    videoRef.current.currentTime = currentTime;
    videoRef.current.play();
  }

  const getMiddlePositionValue = (value1, value2, value3) => {
    let positionArray = [value1, value2, value3];
    positionArray.sort((a, b) => a - b);

    return positionArray[1]
  }

  useEffect(() => {
    const { loopStartChanged, loopStart } = loopData;
    if (loopStartChanged) {
      videoRef.current.currentTime = loopStart;
      dispatch(setLoopData({ loopStartChanged: false, loopChanged: false }));
    }
  }, [loopData, videoRef, dispatch]);

  useEffect(() => {
    const { isDragEnd, startPos, endPos } = draggableImageData;
    if (isDragEnd) {
      const { noteMain, noteStart, videoMain } = details;
      const pixel = (ratio * (noteMain)) / (videoMain / 1000);
      const second = ((startPos - endPos) - noteStart) / pixel;
      if (videoRef.current.currentTime < details.videoStart / 1000) {
        videoRef.current.currentTime += details.videoStart / 1000 + second;
      }
      else {
        videoRef.current.currentTime += second;
      }
      dispatch(setDraggableImageData({ isDragEnd: false }));
    }
  }, [draggableImageData, dispatch, ratio, details, videoRef]);


  const onError = () => {
    if (videoData.quality === "AUTO") {
      dispatch(setVideoData({ quality: "2K", }))
    }
    else if (videoData.quality === "2K") {
      dispatch(setVideoData({ quality: "HD", }))
    }
    else if (videoData.quality === "HD") {
      dispatch(setVideoData({ quality: "SD", }))
    }
    else {
      dispatch(setVideoData({ isColored: "false", quality: "AUTO" }))
      console.log("buraya girdi");
    }
  }

  /* 
    ReactHlsPlayer OnClick Play & Pause Function
    for Songs -> src/pages/students/player/LaSolistVideoPlayer.jsx
    for Lessons -> src/pages/students/lessonPlayer/LessonVideoPlayer.jsx
    Play & Pause Button -> src/components/Controls/Buttons/PlayPauseButton.jsx
  */
  const handlePause = () => {
    dispatch(setVideoData({ isPlaying: false }));
    videoRef.current.pause();
  };

  const handlePlay = () => {
    dispatch(setVideoData({ isPlaying: true }));
    videoRef.current.play();
  };

  const togglePlay = () => {
    if (videoData.isPlaying) {
      dispatch(setVideoData({ isPlaying: false }));
      videoRef.current.pause();
    } else {
      dispatch(setVideoData({ isPlaying: true }));
      videoRef.current.play();
    }
  }



  const handleEnded = () => {
    let data = {
      videoAnalyzeId: parseInt(localStorage.getItem("activeVideoAnalyzeId")),
      status: "finished",
      exitTime: new Date(),
      lastVideoTime: videoData.currentTime,
    }

    console.log("ENDED", data);
    dispatch(updateVideoAnalyze(data))
    dispatch(setVideoData({ isPlaying: false }));
    dispatch(setDraggableImageData({ leftPosition: calculatePosition(details.videoStart / 1000) }));
    videoRef.current.currentTime = 0;
  }

  const onLoad = (isHaveNote) => {
    if (!isLoaded) {
      const videoEndTime = details.videoEnd / 1000;

      const leftValue = calculatePosition(details.videoStart / 1000)

      dispatch(setDraggableImageData({
        leftPosition: leftValue,
        leftMinPosition: leftValue,
        leftMaxPosition: calculatePosition(videoEndTime),
        isLoaded: true,
      }));
      setIsLoaded(true);
    }
    dispatch(setVideoData({ duration: videoRef.current.duration, videoRef: videoRef.current.offsetHeight, isLoaded: true }));
  }



  const onPreviewLoad = () => {
    const { videoStart } = details;
    videoRef.current.currentTime = videoStart / 1000;
  }

  const handlePreviewPlay = () => {
    try {
      const { videoStart } = details;
      videoRef.current.currentTime = videoStart / 1000;
      const playPromise = videoRef.current.play();
      console.log(playPromise);
      if (playPromise !== undefined) {
        playPromise.then(_ => {
          // Automatic playback started!
          // Show playing UI.
        })
          .catch(error => {
            console.log("berat", error);
          });
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handlePreviewPause = () => {
    try {

      videoRef.current.pause();
    } catch (error) {
      console.log(error)
    }
  }

  const handleLoop = () => {
    calculateLoopDifference();
    let timeout = null;

    if (!loopData.isLoop) {
      const { currentTime } = videoRef.current;
      const { videoStart, videoEnd } = details;
      dispatch(setVideoData({ ...videoData, isPlaying: false, videoIndicator: true }));
      videoRef.current.pause();


      const { trackTime, playedTrack } = getLoopVariables(currentTime);

      if (currentTime === 0 || currentTime < (videoStart / 1000)) {
        console.log("Video başında loop tuşuna basılırsa");
        const leftPosition = calculateLoopPosition(videoStart / 1000, playedTrack);

        const loopWidth = calculateLoopPosition(videoStart / 1000, playedTrack) - calculateLoopPosition(videoStart / 1000 + trackTime, playedTrack);

        console.log("LW", loopWidth);
        const loopLeftPosition = leftPosition - loopWidth / 2 + width / 2;

        dispatch(
          setLoopData({
            isLoop: true,
            loopStart: videoStart / 1000,
            loopEnd: (videoStart / 1000) + trackTime,
            loopStartChanged: true,
            loopWidth,
            loopLeftPosition
          })
        );

        timeout = setTimeout(() => {
          try {
            dispatch(setVideoData({ ...videoData, isPlaying: true, videoIndicator: false }));
            videoRef.current.play();
          } catch (error) {
            console.log("exited before timeout completed");
          }
        }, [60 / details.bpm * 1000 * details.measure.split("/")[0]])
      }

      else if (currentTime >= (videoEnd / 1000)) {
        const loopStart = trackTime * (playedTrack - 1) + videoStart / 1000;
        const loopEnd = trackTime * (playedTrack) + videoStart / 1000;


        const leftPosition = calculateLoopPosition(loopStart, playedTrack);
        const loopWidth = calculateLoopPosition(loopStart, playedTrack) - calculateLoopPosition(loopEnd, playedTrack);
        const loopLeftPosition = leftPosition - loopWidth / 2 + width / 2;

        dispatch(
          setLoopData({
            isLoop: true,
            loopStart,
            loopEnd,
            loopStartChanged: true,
            loopWidth,
            loopLeftPosition
          })
        );

      }

      else {
        console.log("video ortasında loop tuşuna basılırsa");


        const loopStart = trackTime * (playedTrack) + videoStart / 1000;
        const loopEnd = trackTime * (playedTrack + 1) + videoStart / 1000;


        const leftPosition = calculateLoopPosition(loopStart, playedTrack);
        const loopWidth = calculateLoopPosition(loopStart, playedTrack) - calculateLoopPosition(loopEnd, playedTrack);
        const loopLeftPosition = leftPosition - loopWidth / 2 + width / 2;



        dispatch(
          setLoopData({
            isLoop: true,
            loopStart: loopStart,
            loopEnd: loopEnd,
            loopStartChanged: true,
            loopWidth,
            loopLeftPosition
          })
        );

        dispatch(setVideoData({ ...videoData, isPlaying: false, videoIndicator: true }));
        timeout = setTimeout(() => {
          try {
            dispatch(setVideoData({ ...videoData, isPlaying: true, videoIndicator: false }));
            videoRef.current.play();
          } catch (error) {
            console.log("exited before timeout completed");
          }
        }, [60 / details.bpm * 1000 * details.measure.split("/")[0]])
      }

    }
    else {
      dispatch(setLoopData({ isLoop: false, loopStart: 0, loopEnd: 0, loopWidth: 0, loopLeftPosition: 0 }));
      videoRef.current.play();
      clearTimeout(timeout);
    }
  }


  /* 
    React HLS Player Context Menu (Right Click) Disable 
    for Songs -> src/pages/students/player/LaSolistVideoPlayer.jsx
    for Lessons -> src/pages/students/lessonPlayer/LessonVideoPlayer.jsx
  */
  const onRightClick = (event) => {
    event.preventDefault();
  }

  const onTimeUpdate = (e) => {
    const { loopStart, loopEnd, isLoop } = loopData;
    if (isLoop && videoRef.current.currentTime >= loopEnd) {
      videoRef.current.currentTime = loopStart;
      dispatch(setVideoData({ ...videoData, isPlaying: false, videoIndicator: true }));
      videoRef.current.pause();
      setTimeout(() => {
        try {
          dispatch(setVideoData({ ...videoData, isPlaying: true, videoIndicator: false }));
          videoRef.current.play();
        } catch (error) {
          console.log("exited before timeout completed");
        }
      }, [60 / details.bpm * 1000 * details.measure.split("/")[0]])
    }

    const videoTime = videoRef.current.currentTime;
    const videoStartTime = details.videoStart / 1000;


    if (!isLoop && videoTime > videoStartTime) {
      const calculatedLeftPosition = calculatePosition(videoRef.current.currentTime);
      const leftPosition = Math.max(calculatedLeftPosition, draggableImageData.leftMaxPosition);
      dispatch(setDraggableImageData({ leftPosition }));
      dispatch(setVideoData({ ...videoData, currentTime: videoRef.current.currentTime }));

    }
  }

  const onTimeUpdateWithoutNote = () => {
    dispatch(setVideoData({ currentTime: videoRef.current.currentTime }));
  }

  const handleBuffer = () => {
    try {
      const element = videoRef.current;
      if (!element.buffered) return;
      const bufferedEnd = element.buffered.end(element.buffered.length - 1);
      dispatch(setVideoData({ buffer: bufferedEnd }));
    } catch (error) {
      console.log(error);
    }
  }

  const handleTutorialColorButtonClick = () => {
    const { currentTime } = videoRef.current;
    const { videoStart, videoEnd } = details;
    if (currentTime < videoStart / 1000) {
      videoRef.current.currentTime = videoStart / 1000;
    }
  }

  const getLessonName = () => {
    if (i18n.language.match("tr")) {
      return video.lessonNameTr;
    }
    else if (i18n.language.match("en")) {
      return video.lessonNameEn;
    }
    else if (i18n.language.match("de")) {
      return video.lessonNameDe;
    }
    else if (i18n.language.match("ar")) {
      return video.lessonNameAr;
    }
  }

  const renderVideoTitle = (title) => {
    if (title.match("(A)")) {
      return title.replace("(A)", `(${t("beginner")})`)
    }
    else if (title.match("(B)")) {
      return title.replace("(B)", `(${t("advanced")})`)
    }
    else {
      return title
    }
  }

  return (
    <PlayerContext.Provider value={{
      onError,
      onLoad,
      onPreviewLoad,
      onRightClick,
      onTimeUpdate,
      onTimeUpdateWithoutNote,
      togglePlay,
      handlePlay,
      handlePreviewPlay,
      handlePause,
      handlePreviewPause,
      handleEnded,
      handleForward,
      handleRewind,
      handleReturned,
      handleProgressClick,
      handleLoop,
      setVolume,
      setPlaybackRate,
      handleBuffer,
      getLessonName,
      renderVideoTitle,
      getMiddlePositionValue,
      handleTutorialColorButtonClick,
      videoSource,
      height,
      videoRef,
      songInfo,
    }}>
      {children}
    </PlayerContext.Provider>
  );
};

// Context'in kullanılması için bir özel kancanın oluşturulması
export const usePlayer = () => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error('useCounter hook must be used within a CounterProvider');
  }
  return context;
};