import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setControlsData } from 'store/main/videoPlayerSlice';
import { setVideoData } from 'store/main/videoPlayerSlice';

function VolumeSlider() {
  const dispatch = useDispatch();
  const { controlsData, videoData } = useSelector((state) => state.videoPlayer);

  const handleVolumeChange = (e) => {
    dispatch(setVideoData({ ...videoData, volume: e.target.value }));
  }

  const handleMouseLeave = () => {
    dispatch(setControlsData({ ...controlsData, showVolume: false, showSpeed: false }));
  }

  return (
    <input
      onMouseLeave={handleMouseLeave}
      type={"range"}
      min={0}
      max={1}
      step={0.01}
      value={videoData.volume}
      onChange={handleVolumeChange}
    />
  )
}

export default VolumeSlider