import React from 'react'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import { imageProxy } from 'features/api/proxy';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  borderRadius: "10px",
  '&:before': {
    display: 'none',
  },
  marginBottom: "10px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon color="info" sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: "10px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



function LessonDetails({
  expanded,
  handleExpand,
  form,
  handleChange,
}) {

  const { t } = useTranslation();



  return (
    <Accordion expanded={expanded === 'panel2'} onChange={handleExpand('panel2')}>
      <AccordionSummary
        aria-controls="panel2bh-content"
        id="panel2bh-header"
      >
        <Typography variant="h5">
          {t("noteDetails")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          autoFocus
          margin="dense"
          id="lessonDetailId"
          name="lessonDetailId"
          label={t("lessonDetailId")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.lessonDetailId}
          disabled
        />

        <MDBox mt={1}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="videoStart"
                name="videoStart"
                label={t("videoStart")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.videoStart}
                onChange={handleChange}
                required
                error={form.videoStart ? false : true}
                focused
                helperText={t("enterVideoStart")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="videoMain"
                name="videoMain"
                label={t("videoMain")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.videoMain}
                onChange={handleChange}
                required
                error={form.videoMain ? false : true}
                focused
                helperText={t("enterVideoMain")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="videoEnd"
                name="videoEnd"
                label={t("videoEnd")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.videoEnd}
                onChange={handleChange}
                required
                error={form.videoEnd ? false : true}
                focused
                helperText={t("enterVideoEnd")}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="noteStart"
                name="noteStart"
                label={t("noteStart")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.noteStart}
                onChange={handleChange}
                required
                error={form.noteStart ? false : true}
                focused
                helperText={t("enterNoteStart")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="noteMain"
                name="noteMain"
                label={t("noteMain")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.noteMain}
                onChange={handleChange}
                required
                error={form.noteMain ? false : true}
                focused
                helperText={t("enterNoteMain")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="noteEnd"
                name="noteEnd"
                label={t("noteEnd")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.noteEnd}
                onChange={handleChange}
                required
                error={form.noteEnd ? false : true}
                focused
                helperText={t("enterNoteEnd")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="noteTolerance"
                name="noteTolerance"
                label={t("noteTolerance")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.noteTolerance}
                onChange={handleChange}
                required
                focused
                helperText={t("enterNoteTolerance")}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="noteHeight"
                name="noteHeight"
                label={t("noteHeight")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.noteHeight}
                onChange={handleChange}
                required
                error={form.noteHeight ? false : true}
                focused
                helperText={t("enterNoteHeight")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="noteWidth"
                name="noteWidth"
                label={t("noteWidth")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.noteWidth}
                onChange={handleChange}
                required
                error={form.noteWidth ? false : true}
                focused
                helperText={t("enterNoteWidth")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="bpm"
                name="bpm"
                label={t("bpm")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.bpm}
                onChange={handleChange}
                required
                error={form.bpm ? false : true}
                focused
                helperText={t("enterBpm")}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="loopNoteStart"
                name="loopNoteStart"
                label={t("loopNoteStart")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.loopNoteStart}
                onChange={handleChange}
                required
                error={form.loopNoteStart ? false : true}
                focused
                helperText={t("enterLoopNoteStart")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="loopNoteEnd"
                name="loopNoteEnd"
                label={t("loopNoteEnd")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.loopNoteEnd}
                onChange={handleChange}
                required
                error={form.loopNoteEnd ? false : true}
                focused
                helperText={t("enterLoopNoteEnd")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="measure"
                name="measure"
                label={t("measure")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.measure}
                onChange={handleChange}
                required
                error={form.measure ? false : true}
                focused
                helperText={t("enterMeasure")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                id="measureWidth"
                name="measureWidth"
                label={t("measureWidth")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.measureWidth}
                onChange={handleChange}
                required
                error={form.measureWidth ? false : true}
                focused
                helperText={t("enterMeasureWidth")}
              />
            </Grid>

          </Grid>
        </MDBox>

        <MDBox mt={2}>
          {
            form.folderNumber && form.folderNumber.length > 0 &&
            <img style={{ width: "100%" }} src={`${imageProxy}/${form.folderNumber}/${form.folderNumber}.svg`} alt={`${imageProxy}/${form.folderNumber}/${form.folderNumber}.svg`} />
          }
        </MDBox>

      </AccordionDetails>
    </Accordion>
  )
}

export default LessonDetails