/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setUserInfo } from "store/main/subscriptionsSlice";
import { Link } from "react-router-dom";

function PricingCard({ color, badge, price, specifications, button, shadow, handleNext, pricingPlanId, pricingPlanReferenceCode, isTrial }) {
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <MDBox key={label} display="flex" alignItems="center" p={1}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        mr={2}
        mt={-0.125}
      >
        <MDTypography
          variant="body1"
          color={color === "white" ? "text" : "white"}
          sx={{ lineHeight: 0 }}
        >
          <Icon>{"done"}</Icon>
        </MDTypography>
      </MDBox>
      <MDTypography
        variant="body2"
        color={color === "white" ? "text" : "white"}
        fontWeight="regular"
      >
        {label}
      </MDTypography>
    </MDBox>
  ));

  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.subscriptions);

  const handleNextStep = () => {
    handleNext();
    dispatch(setUserInfo({ ...userInfo, pricingPlanReferenceCode, pricingPlanId, isTrial, priceType: price.type }));
  }

  return (
    <Card sx={{ boxShadow: ({ boxShadows: { lg } }) => (shadow ? lg : "none") }}>
      <MDBox
        bgColor={color}
        variant={color === "white" ? "contained" : "gradient"}
        borderRadius="xl"
      >
        <MDBox mt={-1.375} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <MDBox
            bgColor={badge.color}
            width="max-content"
            px={4}
            pt={0}
            pb={0.5}
            borderRadius="section"
            lineHeight={1}
          >
            <MDTypography
              variant="caption"
              textTransform="uppercase"
              fontWeight="medium"
              color={badge.color === "light" ? "dark" : "white"}
            >
              {badge.label}
            </MDTypography>
          </MDBox>

        </MDBox>


        <MDBox pt={3} pb={2} px={2} textAlign="center">
          <MDBox my={1}>
            <MDTypography variant="h1" color={color === "white" ? "dark" : "white"}>
              <MDTypography
                display="inline"
                component="small"
                variant="h5"
                color="inherit"
                verticalAlign="top"
              >
                {price.currency}
              </MDTypography>
              {(price.value / price.count)}
              <MDTypography display="inline" component="small" variant="h5" color="inherit">
                / {price.type}
              </MDTypography>
            </MDTypography>
          </MDBox>


          <MDBox visibility={isTrial ? "hidden" : "visible"} my={1}>
            <MDTypography variant="subtitle2" color={color === "white" ? "dark" : "white"}>
              <MDTypography
                display="inline"
                component="small"
                variant="subtitle2"
                color="inherit"
                verticalAlign="top"
              >
                {price.currency}
              </MDTypography>
              {price.value}
              <MDTypography display="inline" component="small" variant="subtitle2" color="inherit">
                /{price.count} {price.type}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox pb={3} px={3}>
          {renderSpecifications}
          {
            !button.disabled && <MDBox mt={3}>
              {
                button.action === "button" ? <MDButton
                  onClick={handleNextStep}
                  variant="gradient"
                  color={button.color}
                  fullWidth
                >
                  {button.label}&nbsp;
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MDButton>
                  : <MDButton
                    component={Link}
                    to={button.href}
                    variant="gradient"
                    color={button.color}
                    fullWidth
                  >
                    {button.label}&nbsp;
                    <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                  </MDButton>
              }
            </MDBox>
          }
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the DefaultPricingCard
PricingCard.defaultProps = {
  color: "white",
  shadow: true,
  isLanding: false,
  button: {
    action: "button",
  },
};

// Typechecking props for the DefaultPricingCard
PricingCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "white",
  ]),
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    count: PropTypes.number,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  button: PropTypes.shape({
    action: PropTypes.oneOf([
      "link",
      "button",
    ]),
    href: PropTypes.string,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    disabled: PropTypes.bool,
  }).isRequired,
  shadow: PropTypes.bool,
  handleNext: PropTypes.func,
  pricingPlanReferenceCode: PropTypes.string,
  pricingPlanId: PropTypes.number.isRequired,
  isTrial: PropTypes.bool,
  isLanding: PropTypes.bool,
};

export default PricingCard;
