import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ImageUpload from "components/UploadComponents/ImageUpload";
import { imageProxy } from "features/api/proxy";
import { useTranslation } from "react-i18next";
import {
  addinstructor,
  closeEditinstructorDialog,
  closeNewinstructorDialog,
  updateinstructor,
} from "store/main/instructorsSlice";
import { Grid } from "@mui/material";
const defaultFormState = {
  instructorId: 0,
  instructorName: "",
  instructorSurname: "",
  instructorDescriptionTr: "",
  instructorDescriptionEn: "",
  instructorDescriptionDe: "",
  instructorDescriptionAr: "",
  userName: "",
  password: "",
  confirmPassword: "",
  status: "active",
  url: "",
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
];

function InstructorDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const instructorDialog = useSelector(
    ({ instructors }) => instructors.instructorDialog
  );

  const [photo, setphoto] = useState();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (instructorDialog.type === "edit" && instructorDialog.data) {
      setForm({ ...instructorDialog.data });
      fetch(`${imageProxy}/${instructorDialog.data.url}`)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], instructorDialog.data.url, { type: blob.type });
          setphoto(file);
        })
        .catch(error => {
          console.error('Dosya bulunamadı:', error);
        });
      let status = statusOptions.find(
        (f) => f.name === instructorDialog.data.status
      );
      setStatusValue(status);
    }

    /**
     * Dialog type: 'new'
     */
    if (instructorDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...instructorDialog.data,
      });
      let status = statusOptions.find(
        (f) => f.name === defaultFormState.status
      );
      setStatusValue(status);
      setphoto();
    }
  }, [instructorDialog.data, instructorDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (instructorDialog.props.open) {
      initDialog();
    }
  }, [instructorDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return instructorDialog.type === "edit"
      ? dispatch(closeEditinstructorDialog())
      : dispatch(closeNewinstructorDialog());
  }

  const handleSetImage = (file) => {
    setphoto(file);
    if (!file) {
      setInForm("url", null);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (instructorDialog.type === "new") {
      let data = {
        ...form,
        status: statusValues.id,
        url: photo,
      };
      setphoto();
      dispatch(addinstructor(data));
    } else {
      let data = {
        ...form,
        status: statusValues.id,
        url: photo,
      };
      setphoto();
      dispatch(updateinstructor(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    if (instructorDialog.type === "new") {
      return (
        form.instructorName.length > 0 && form.userName.length > 0 &&
        form.password.length >= 0 && form.password === form.confirmPassword &&
        form.instructorDescriptionTr.length > 0 && form.instructorDescriptionEn.length > 0 &&
        form.instructorDescriptionDe.length > 0 && form.instructorDescriptionAr.length > 0 &&
        photo !== undefined
      );
    } else {
      return (
        form.instructorName.length > 0 && form.userName.length > 0 &&
        form.instructorDescriptionTr.length > 0 && form.instructorDescriptionEn.length > 0 &&
        form.instructorDescriptionDe.length > 0 && form.instructorDescriptionAr.length > 0 &&
        photo !== undefined
      );
    }
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...instructorDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">{t("instructorsInfo")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="instructorId"
          name="instructorId"
          label={t("instructorId")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.instructorId}
          disabled
        />
        <TextField
          autoFocus
          margin="dense"
          id="instructorName"
          name="instructorName"
          label={t("instructorName")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.instructorName}
          onChange={handleChange}
          required
          error={form.instructorName ? false : true}
          helperText={t("enterInstructorName")}
          focused
          disabled={instructorDialog.type === "new" ? false : true}
        />
        <TextField
          autoFocus
          margin="dense"
          id="instructorSurname"
          name="instructorSurname"
          label={t("instructorSurname")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.instructorSurname}
          onChange={handleChange}
          required
          error={form.instructorSurname ? false : true}
          helperText={t("enterInstructorSurname")}
          focused
          disabled={instructorDialog.type === "new" ? false : true}
        />
        <TextField
          margin="dense"
          id="instructorDescriptionTr"
          name="instructorDescriptionTr"
          label={t("instructorDescriptionTr")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.instructorDescriptionTr}
          onChange={handleChange}
          required
          error={form.instructorDescriptionTr ? false : true}
          helperText={t("enterInstructorDescriptionTr")}
          focused
        />
        <TextField
          margin="dense"
          id="instructorDescriptionEn"
          name="instructorDescriptionEn"
          label={t("instructorDescriptionEn")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.instructorDescriptionEn}
          onChange={handleChange}
          required
          error={form.instructorDescriptionEn ? false : true}
          helperText={t("enterInstructorDescriptionEn")}
          focused
        />
        <TextField
          margin="dense"
          id="instructorDescriptionDe"
          name="instructorDescriptionDe"
          label={t("instructorDescriptionDe")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.instructorDescriptionDe}
          onChange={handleChange}
          required
          error={form.instructorDescriptionDe ? false : true}
          helperText={t("enterInstructorDescriptionDe")}
          focused
        />
        <TextField
          margin="dense"
          id="instructorDescriptionAr"
          name="instructorDescriptionAr"
          label={t("instructorDescriptionAr")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.instructorDescriptionAr}
          onChange={handleChange}
          required
          error={form.instructorDescriptionAr ? false : true}
          helperText={t("enterInstructorDescriptionAr")}
          focused
        />
        {instructorDialog.type === "new" ? (
          <MDBox mt={1}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  id="userName"
                  name="userName"
                  label={t("userName")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={form.userName}
                  onChange={handleChange}
                  required
                  error={form.userName ? false : true}
                  helperText={t("enterUserName")}
                  focused
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  id="password"
                  name="password"
                  label={t("password")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={form.password}
                  onChange={handleChange}
                  required
                  error={form.password ? false : true}
                  helperText={t("enterPassword")}
                  focused
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  id="confirmPassword"
                  name="confirmPassword"
                  label={t("confirmPassword")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={form.confirmPassword}
                  onChange={handleChange}
                  required
                  error={form.password ? false : true}
                  helperText={t("enterConfirmPassword")}
                  focused
                />
              </Grid>
            </Grid>
          </MDBox>
        ) :
          <TextField
            margin="dense"
            id="userName"
            name="userName"
            label={t("userName")}
            type="text"
            fullWidth
            variant="outlined"
            value={form.userName}
            onChange={handleChange}
            required
            error={form.userName ? false : true}
            helperText={t("enterUserName")}
            focused
          />}
        <MDBox mt={2}>
          <Autocomplete
            value={statusValues} // Dikkat: statusValues -> statusValue olarak değiştirildi
            options={statusOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("status")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                setStatusValue({
                  id: newValue, // newValue.id -> newValue olarak değiştirildi
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                setStatusValue({
                  name: newValue.inputValue,
                });
              } else {
                setStatusValue(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox mt={2}>
          <ImageUpload
            handleSetImage={handleSetImage}
            file={photo}
            url={form && form.url ? `${imageProxy}/${form.url}` : null}
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InstructorDialog;
