/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useSelector } from "react-redux";
import { selectPricingPlans } from "store/main/pricingPlansSlice";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { AppBar, Tab, Tabs } from "@mui/material";

import PricingCard from "./PricingCard";
import Faq from "./Faq";
import StepHeader from "../components/StepHeader";

function PricingPlans({ handleNext }) {
  const { country_code } = useSelector((state) => state.location);
  const { isStudent } = useSelector((state) => state.auth);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t, i18n } = useTranslation();
  const pricingPlans = useSelector(selectPricingPlans);
  const [filteredPlans, setFilteredPlans] = useState([]);

  const [tabValue, setTabValue] = useState(1);
  const [paymentInterval, setPaymentInterval] = useState("MONTHLY");
  const [priceType, setPriceType] = useState("monthlyInterval");

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "trial") {
      setPaymentInterval("TRIAL");
      setPriceType("trialInterval");
    }
    else {
      setPaymentInterval("MONTHLY");
      setPriceType("monthlyInterval");
    }
  };

  useEffect(() => {
    const plans = pricingPlans.filter((f) => {
      if (paymentInterval === "TRIAL") {
        return (f.paymentInterval === "TRIAL");
      }
      else if (country_code === "TR") {
        return (
          (f.countryISO === "TR" && f.forStudent === isStudent && f.payPlatform === "iyzico")
        )
      }
      return (f.countryISO === "DE" && f.forStudent === isStudent && f.payPlatform === "paypal")
    });

    setFilteredPlans(plans);
  }, [paymentInterval, pricingPlans, country_code, isStudent]);


  return (
    <MDBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
      <StepHeader header={"plan_header"} alt={"plan_description"} />

      <Grid container justifyContent={"center"} mt={4} mb={8}>
        <Grid item xs={12} xl={5}>
          <AppBar position="static">
            <Tabs value={tabValue} onChange={handleSetTabValue}>
              <Tab
                id="trial"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    {t("trial")}
                  </MDBox>
                }
              />
              <Tab
                id="paid"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    {t("paid")}
                  </MDBox>
                }
              />
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>



      <Grid container spacing={3} justifyContent="center">

        {
          filteredPlans.map((item, key) => {
            return (
              <Grid key={key} item xs={12} xl={4}>
                <PricingCard
                  color={(key % 2 === 1 || filteredPlans.length === 1) ? "dark" : "white"}
                  badge={{
                    color: "warning",
                    label: (
                      (i18n.language.match("tr") && item.pricingPlanNameTr) ||
                      (i18n.language.match("en") && item.pricingPlanNameEn) ||
                      (i18n.language.match("de") && item.pricingPlanNameDe) ||
                      (i18n.language.match("ar") && item.pricingPlanNameAr)
                    )
                  }}
                  price={{
                    currency: (
                      (item.currencyISO === "EUR" && "€") ||
                      (item.currencyISO === "TRY" && "₺") ||
                      (item.paymentInterval === "TRIAL" && country_code === "US" && "€") ||
                      (item.paymentInterval === "TRIAL" && country_code === "TR" && "₺")
                    ),
                    value: item.price,
                    type: t(priceType),
                    count: item.paymentIntervalCount,
                  }}
                  specifications={[
                    { label: t("feature1"), includes: true },
                    { label: t("feature2"), includes: true },
                    { label: t("feature3"), includes: true },
                    { label: t("feature4"), includes: true },
                    { label: t("feature5"), includes: true },
                    { label: t("feature6"), includes: true },
                    { label: t("feature7"), includes: true },
                    { label: t("feature8"), includes: true },
                    { label: t("feature9"), includes: true },
                  ]}
                  handleNext={() => handleNext(1)}
                  pricingPlanReferenceCode={item.pricingPlanReference}
                  pricingPlanId={item.pricingPlanId}
                  button={{
                    color: (key % 2 === 1 || filteredPlans.length === 1) ? "info" : "dark",
                    label: t("choosePlan"),
                    action: "button",
                  }}
                  shadow={darkMode}
                  isTrial={paymentInterval === "TRIAL" ? true : false}
                />
              </Grid>
            )
          })
        }
      </Grid>

      {/* <Faq /> */}
    </MDBox>
  );
}

export default PricingPlans;
