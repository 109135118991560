import { Card } from '@mui/material'
import StudentLayout from 'pages/main/layout/StudentLayout'
import React from 'react'
import CourseHeader from './CourseHeader'
import LessonsList from './LessonsList';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getinstructorCourses } from 'store/main/instructorCoursesSlice';
import { useState } from 'react';
import LoadingComponent from 'components/Loading';

function CoursesDetailsDashboard() {
  const dispatch = useDispatch();
  const { courseId } = useParams();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getinstructorCourses()).then(() => setLoading(false));
  }, [dispatch]);

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <StudentLayout>
      <CourseHeader />
      <LessonsList />
    </StudentLayout>
  )
}

export default CoursesDetailsDashboard