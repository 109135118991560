import React from 'react'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { selectusers } from 'store/main/usersSlice';
import { useSelector } from 'react-redux';
import MDBox from 'components/MDBox';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  borderRadius: "10px",
  '&:before': {
    display: 'none',
  },
  marginBottom: "10px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon color="info" sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: "10px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function UserInformation({ expanded, handleExpand, userValue, setUserValue, form, setForm, handleChange }) {

  const users = useSelector(selectusers);
  const { t } = useTranslation();

  return (
    <Accordion expanded={expanded === 'panel2'} onChange={handleExpand('panel2')}>
      <AccordionSummary
        aria-controls="panel2bh-content"
        id="panel2bh-header"
      >
        <Typography variant="h5">
          {t("userInformations")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <MDBox mb={1}>
          <Autocomplete
            value={userValue}
            options={users}
            getOptionLabel={(option) => option.userName}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("userName")} />
            )}
            onChange={(event, userInformation) => {

              if (typeof userInformation === "string") { // newValue.name -> newValue olarak değiştirildi
                console.log("1")
                setUserValue({
                  id: userInformation, // newValue.id -> newValue olarak değiştirildi
                  name: userInformation,
                });
              } else if (userInformation && userInformation.inputValue) {
                console.log("2")
                setUserValue({
                  name: userInformation.inputValue,
                });
              } else {
                console.log("3")
                setUserValue(userInformation);
                setForm({
                  ...form,
                  userInformation
                })
              }
            }}
          />
        </MDBox>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              // autoFocus
              margin="dense"
              id="userInformation.name"
              name="userInformation.name"
              label={t("name")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.userInformation.name}
              onChange={handleChange}
              required
              error={form.userInformation.name ? false : true}
              helperText={t("enterName")}
            />
          </Grid>
          <Grid item xs={6}>

            <TextField
              // autoFocus
              margin="dense"
              id="userInformation.surname"
              name="userInformation.surname"
              label={t("surname")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.userInformation.surname}
              onChange={handleChange}
              required
              error={form.userInformation.surname ? false : true}
              helperText={t("enterSurname")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              // autoFocus
              margin="dense"
              id="gsmNumber"
              name="gsmNumber"
              label={t("gsmNumber")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.userInformation.gsmNumber}
              onChange={handleChange}
              required
              error={form.userInformation.gsmNumber ? false : true}
              helperText={t("enterGsmNumber")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              // autoFocus
              margin="dense"
              id="identitynumber"
              name="identitynumber"
              label={t("identitynumber")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.userInformation.identitynumber}
              onChange={handleChange}
              required
              error={form.userInformation.identitynumber ? false : true}
              helperText={t("enterIdentityNumber")}
            />

          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion >
  )
}

export default UserInformation