import { useState, useEffect } from 'react'

export const useRatio = (noteHeight = 150) => {
  const [firstTime, setFirstTime] = useState(true);
  const [ratio, setRatio] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const resize = () => {
    if (window.innerWidth <= 600) {
      setRatio(70 / noteHeight);
      setWidth(40);
      setHeight(70)
    }
    else if (window.innerWidth < 768) {
      setRatio(80 / noteHeight);
      setWidth(50);
      setHeight(80)
    }
    else if (window.innerWidth < 992) {
      setRatio(90 / noteHeight);
      setWidth(60);
      setHeight(90)
    }
    else if (window.innerWidth < 1200) {
      setRatio(100 / noteHeight);
      setWidth(70);
      setHeight(100)
    }
    else {
      setRatio(150 / noteHeight);
      setWidth(80);
      setHeight(150);
    }
  }

  useEffect(() => {
    if (firstTime) {
      resize();
      setFirstTime(false);
    }
    window.addEventListener('resize', resize)


    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [firstTime, resize])

  return { ratio, width, height };
}
