import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";


export const getMusicians = createAsyncThunk(
  "musicians/getMusicians",
  async (_, { getState }) => {
    const response = await axiosConfig.get(`/api/musicians`);
    // const response = await axios.get(`${proxy}/api/musicians`)

    let { data } = await response.data;
    return data;
  }
);

export const addMusician = createAsyncThunk(
  "musicians/addMusician",
  async (musician, { dispatch, getState }) => {
    try {
      let formData = new FormData();

      formData.append("url", musician.url);
      formData.append("musicianName", musician.musicianName);
      formData.append("musicianSurname", musician.musicianSurname);
      formData.append("musicianDescriptionTr", musician.musicianDescriptionTr);
      formData.append("musicianDescriptionEn", musician.musicianDescriptionEn);
      formData.append("musicianDescriptionDe", musician.musicianDescriptionDe);
      formData.append("musicianDescriptionAr", musician.musicianDescriptionAr);
      formData.append("status", musician.status);
      formData.append("userName", musician.userName);
      formData.append("password", musician.password);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/users/create-musician`,
        formData,
        config
      );
      let { data } = await response.data;
      if (response.data.success === true) {

        return data;
      }
    } catch (error) {


      return null;
    }
  }
);

export const updateMusician = createAsyncThunk(
  "musicians/updateMusician",
  async (musician, { dispatch, getState }) => {
    let formData = new FormData();

    formData.append("musicianId", musician.musicianId);
    formData.append("url", musician.url);
    formData.append("musicianName", musician.musicianName);
    formData.append("musicianDescriptionTr", musician.musicianDescriptionTr);
    formData.append("musicianDescriptionEn", musician.musicianDescriptionEn);
    formData.append("musicianDescriptionDe", musician.musicianDescriptionDe);
    formData.append("musicianDescriptionAr", musician.musicianDescriptionAr);
    formData.append("status", musician.status);
    formData.append("userName", musician.userName);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/musicians/${musician.musicianId}`,
      formData,
      config
    );
    const { data } = await response.data;
    if (response.data.success === true) {

      return data;
    }
    return null;
  }
);

export const removeMusician = createAsyncThunk(
  "musicians/removeMusician",
  async (musicianId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/musicians/${musicianId}`);
    if (response.data.success === true) {

      return musicianId;
    }
    return musicianId;
  }
);

const musiciansAdapter = createEntityAdapter({
  selectId: (musician) => musician.musicianId,
});

export const { selectAll: selectmusicians, selectById: selectmusiciansById } =
  musiciansAdapter.getSelectors((state) => state.musicians);

const musiciansSlice = createSlice({
  name: "musicians",
  initialState: musiciansAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    musicianDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setMusicianSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewMusicianDialog: (state, action) => {
      state.musicianDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewMusicianDialog: (state, action) => {
      state.musicianDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditMusicianDialog: (state, action) => {
      state.musicianDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditMusicianDialog: (state, action) => {
      state.musicianDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateMusician.fulfilled]: musiciansAdapter.upsertOne,
    [addMusician.fulfilled]: musiciansAdapter.addOne,
    [removeMusician.fulfilled]: (state, action) =>
      musiciansAdapter.removeOne(state, action.payload),
    [getMusicians.fulfilled]: musiciansAdapter.setAll,
  },
});

export const {
  setMusicianSearchText,
  openNewMusicianDialog,
  closeNewMusicianDialog,
  openEditMusicianDialog,
  closeEditMusicianDialog,
  openConfirmDialog,
  closeConfirmDialog,
  openResetPasswordDialog,
  closeResetPasswordDialog,
} = musiciansSlice.actions;

export default musiciansSlice.reducer;
