import { Card, Fade, Grid, Grow } from "@mui/material";
import DefaultCard from "components/Cards/SongCard/DefaultCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { imageProxy } from "features/api/proxy";
import { PlayerProvider } from "hooks/PlayerProvider";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectsongListsById } from "store/main/songListsSlice";
import { selectsongLists } from "store/main/songListsSlice";
import { selectuserfavorites } from "store/main/userFavoritesSlice";

function SongsList() {
  const { i18n, t } = useTranslation();
  const songList = useSelector(selectsongLists);
  const favorites = useSelector(selectuserfavorites);

  const [filteredSongList, setFilteredSongList] = useState(songList);

  const { searchText, instrumentValue, categoriesValue } = useSelector((state) => state.songLists);

  useEffect(() => {
    const filteredList = songList
      .filter((f) => {
        try {
          if (searchText !== "") {
            return (
              f.songInfo.songName.toLowerCase().includes(searchText.toLowerCase()) ||
              (
                (i18n.language.match("tr") && f.songInfo.songDescriptionTr.toLowerCase().includes(searchText.toLowerCase())) ||
                (i18n.language.match("en") && f.songInfo.songDescriptionEn.toLowerCase().includes(searchText.toLowerCase())) ||
                (i18n.language.match("de") && f.songInfo.songDescriptionDe.toLowerCase().includes(searchText.toLowerCase())) ||
                (i18n.language.match("ar") && f.songInfo.songDescriptionAr.toLowerCase().includes(searchText.toLowerCase()))
              )
            )
          }
        } catch (error) {
          console.log(error);
        }
        return f;
      })
      .filter((f) => {
        if (instrumentValue.id !== "") {
          return f.instrumentId === instrumentValue.id
        }
        return f;
      })
      .filter((f) => {
        const categoriesMatch = f.songInfo.songCategoryData.find(
          (f) => f.categoryId === categoriesValue.id
        );
        if (categoriesMatch !== undefined) {
          return categoriesMatch
        }
        return f;
      });

    setFilteredSongList(filteredList);
  }, [searchText, songList, instrumentValue, categoriesValue, i18n.language]);




  if (filteredSongList.length === 0) {
    return (
      <Card>
        <MDBox p={3}>
          <MDTypography fontSize={{ xs: "1.1rem", lg: "1.2rem", xl: "1.3rem" }} fontWeight={"bold"}>
            {t("songDoesntMatch")}
          </MDTypography>
        </MDBox>
      </Card>
    )
  }

  return (

    <Grid mt={2} container spacing={2}>

      {filteredSongList.map((song, key) => {
        const favorite = favorites.find((f) => f.listId === song.listId);
        const { songInfo, details } = song;
        console.log(song);
        return (
          <PlayerProvider selectSongState={selectsongListsById} videoId={song.listId}>
            <Grid key={key} item xs={12} md={6} xl={4}>
              <DefaultCard
                details={{
                  type: "song",
                  route: `/video/${song.listId}`,
                  key: key,
                  views: song.count,
                  videoId: song.listId,
                  courseId: 0,
                  isTrial: songInfo.isTrial,
                  level: song.level,
                }}
                image={`${imageProxy}/${songInfo.url}`}
                title={songInfo.songName}
                description={
                  (i18n.language.match("tr") &&
                    songInfo.songDescriptionTr) ||
                  (i18n.language.match("en") &&
                    songInfo.songDescriptionEn) ||
                  (i18n.language.match("de") &&
                    songInfo.songDescriptionDe) ||
                  (i18n.language.match("ar") &&
                    songInfo.songDescriptionAr)
                }
                date={song.details.createdDate}
                duration={details.duration}
                favoriteId={favorite ? favorite.favoriteId : 0}
              />
            </Grid>
          </PlayerProvider>
        );
      })}
    </Grid >
  );
}

export default SongsList;
