import {
  DataGrid,
  trTR,
  enUS,
  deDE,
  arSD,
  GridToolbar
} from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useMemo, useState } from "react";
import { Box, Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { darken, lighten } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import { useTranslation } from "react-i18next";
import { selectSubscriptions } from "store/main/subscriptionsSlice";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import { checkSubscriptionsStatus } from "store/main/subscriptionsSlice";
import dayjs from "dayjs";

const SubscriptionsTable = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(selectSubscriptions);

  const { t, i18n } = useTranslation();
  console.log(i18n.language);
  const [pageSize, setPageSize] = useState(15);


  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);


  const columns = useMemo(() => [
    {
      headerName: t("id"),
      field: "subscriptionId",
      minWidth: 50,
      hideable: false,
    },
    {
      headerName: t("subscriptionReferenceCode"),
      field: "subscriptionReferenceCode",
      minWidth: 250,
      hideable: false,
    },
    {
      headerName: t("parentReferenceCode"),
      field: "parentReferenceCode",
      minWidth: 250,
      hideable: false,
    },
    {
      headerName: t("pricingPlanReferenceCode"),
      field: "pricingPlanReferenceCode",
      minWidth: 250,
      hideable: false,
    },
    {
      headerName: t("payMethod"),
      field: "payMethod",
      minWidth: 150,
      hideable: false,
    },
    {
      headerName: t("status"),
      field: "status",
      minWidth: 150,
      renderCell: (params) => {
        return t(params.value);
      },
    },
    {
      headerName: t("trialStartDate"),
      field: "trialStartDate",
      minWidth: 200,
      hideable: false,
      type: "date",
      renderCell: (params) => {
        if (params.value !== null) {
          return dayjs(params.value).format("DD-MM-YYYY");
        }
        return t("noTrial");
      },
    },
    {
      headerName: t("trialEndDate"),
      field: "trialEndDate",
      minWidth: 200,
      hideable: false,
      type: "date",
      renderCell: (params) => {
        if (params.value !== null) {
          return dayjs(params.value).format("DD-MM-YYYY");
        }
        return t("noTrial");
      },
    },
    {
      headerName: t("startDate"),
      field: "startDate",
      minWidth: 150,
      hideable: false,
      type: "date",
      renderCell: (params) => {
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: t("endDate"),
      field: "endDate",
      minWidth: 150,
      hideable: false,
      type: "date",
      renderCell: (params) => {
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: t("expireDate"),
      field: "expireDate",
      minWidth: 170,
      hideable: false,
      type: "date",
      renderCell: (params) => {
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: t("createdDate"),
      field: "createdDate",
      minWidth: 170,
      hideable: false,
      type: "date",
      renderCell: (params) => {
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },

  ], [t]);

  const handleCheckSubscriptionStatus = () => {
    dispatch(checkSubscriptionsStatus());
  }

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox p={3} lineHeight={1}>
        <TableContainer sx={{ boxShadow: "none" }}>
          <MDBox>
            <Grid container alignItems="center">
              <Grid item xs={12} md={7}>
                <MDBox mb={1}>
                  <MDTypography variant="h5">{t("subscriptions")}</MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2" color="text">
                    {t("subscriptionList")}
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
                <MDButton
                  onClick={handleCheckSubscriptionStatus}
                  variant="gradient"
                  color="info"
                >
                  {t("checkSubscriptionStatus")}
                </MDButton>
              </Grid>
            </Grid>
            <div style={{ flexGrow: 1, width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      height: "100%",
                      width: 1,
                      "& .super-app-theme--iptal": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.error.main,
                            theme.palette.mode
                          ),
                      },
                      "& .super-app-theme--tamamlandi": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.info.main,
                            theme.palette.mode
                          ),
                      },
                    }}
                  >
                    <DataGrid
                      localeText={
                        (i18n.language.match("tr") && trTR.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("en") && enUS.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("de") && deDE.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("ar") && arSD.components.MuiDataGrid.defaultProps.localeText)
                      }
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      rowsPerPageOptions={[15, 30, 60]}
                      pagination
                      autoHeight
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      getRowClassName={(params) =>
                        `super-app-theme--${params.row.status}`
                      }
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                    />
                  </Box>
                </div>
              </div>
            </div>
          </MDBox>
        </TableContainer>
      </MDBox>
    </Card>
  );
};

SubscriptionsTable.propTypes = {};

export default SubscriptionsTable;
