import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";


export const getInstruments = createAsyncThunk(
  "instruments/getInstruments",
  async (_, { getState }) => {
    const response = await axiosConfig.get(`/api/instruments`);
    // const response = await axios.get(`${proxy}/api/instruments`)

    let { data } = await response.data;
    return data;
  }
);

export const addInstrument = createAsyncThunk(
  "instruments/addInstrument",
  async (instrument, { dispatch, getState }) => {
    try {
      let formData = new FormData();

      formData.append("url", instrument.url);
      formData.append("instrumentNameTr", instrument.instrumentNameTr);
      formData.append(
        "instrumentDescriptionTr",
        instrument.instrumentDescriptionTr
      );
      formData.append("instrumentNameEn", instrument.instrumentNameEn);
      formData.append(
        "instrumentDescriptionEn",
        instrument.instrumentDescriptionEn
      );
      formData.append("instrumentNameDe", instrument.instrumentNameDe);
      formData.append(
        "instrumentDescriptionDe",
        instrument.instrumentDescriptionDe
      );
      formData.append("instrumentNameAr", instrument.instrumentNameAr);
      formData.append(
        "instrumentDescriptionAr",
        instrument.instrumentDescriptionAr
      );
      formData.append("type", instrument.type);
      formData.append("status", instrument.status);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/instruments`,
        formData,
        config
      );
      let { data } = await response.data;
      if (response.data.success === true) {
        return data;
      }
    } catch (error) {


      return null;
    }
  }
);

export const updateInstrument = createAsyncThunk(
  "instruments/updateInstrument",
  async (instrument, { dispatch, getState }) => {
    let formData = new FormData();

    formData.append("instrumentId", instrument.instrumentId);
    formData.append("url", instrument.url);
    formData.append("instrumentNameTr", instrument.instrumentNameTr);
    formData.append(
      "instrumentDescriptionTr",
      instrument.instrumentDescriptionTr
    );
    formData.append("instrumentNameEn", instrument.instrumentNameEn);
    formData.append(
      "instrumentDescriptionEn",
      instrument.instrumentDescriptionEn
    );
    formData.append("instrumentNameDe", instrument.instrumentNameDe);
    formData.append(
      "instrumentDescriptionDe",
      instrument.instrumentDescriptionDe
    );
    formData.append("instrumentNameAr", instrument.instrumentNameAr);
    formData.append(
      "instrumentDescriptionAr",
      instrument.instrumentDescriptionAr
    );
    formData.append("type", instrument.type);
    formData.append("status", instrument.status);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/instruments/${instrument.instrumentId}`,
      formData,
      config
    );
    const { data } = await response.data;
    if (response.data.success === true) {

      return data;
    }
    return null;
  }
);

export const removeInstrument = createAsyncThunk(
  "instruments/removeInstrument",
  async (instrumentId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/instruments/${instrumentId}`);
    if (response.data.success === true) {

      return instrumentId;
    }
    return instrumentId;
  }
);

const instrumentsAdapter = createEntityAdapter({
  selectId: (instrument) => instrument.instrumentId,
});

export const {
  selectAll: selectinstruments,
  selectById: selectinstrumentsById,
} = instrumentsAdapter.getSelectors((state) => state.instruments);

const instrumentsSlice = createSlice({
  name: "instruments",
  initialState: instrumentsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    instrumentDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setInstrumentSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewInstrumentDialog: (state, action) => {
      state.instrumentDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewInstrumentDialog: (state, action) => {
      state.instrumentDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditInstrumentDialog: (state, action) => {
      state.instrumentDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditInstrumentDialog: (state, action) => {
      state.instrumentDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateInstrument.fulfilled]: instrumentsAdapter.upsertOne,
    [addInstrument.fulfilled]: instrumentsAdapter.addOne,
    [removeInstrument.fulfilled]: (state, action) =>
      instrumentsAdapter.removeOne(state, action.payload),
    [getInstruments.fulfilled]: instrumentsAdapter.setAll,
  },
});

export const {
  setInstrumentSearchText,
  openNewInstrumentDialog,
  closeNewInstrumentDialog,
  openEditInstrumentDialog,
  closeEditInstrumentDialog,
  openConfirmDialog,
  closeConfirmDialog,
  openResetPasswordDialog,
  closeResetPasswordDialog,
} = instrumentsSlice.actions;

export default instrumentsSlice.reducer;
