import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";


export const getSongs = createAsyncThunk(
  "songs/getSongs",
  async (_, { getState }) => {
    const { userName } = getState().auth;

    const response = await axiosConfig.get(`/api/songs`, {
      params: {
        userName: userName,
      },
    });

    let { data } = await response.data;
    return data;
  }
);
export const getSongByQuery = createAsyncThunk(
  "songs/getSongByQuery",
  async (songId, { dispatch, getState }) => {
    const response = await axiosConfig.get(`/api/songs/query?songId=${songId}`);
    let { data } = await response.data;

    console.log(data);
    return data;
  }
)

export const addSong = createAsyncThunk(
  "songs/addSong",
  async (song, { dispatch, getState }) => {
    try {
      let formData = new FormData();

      formData.append("url", song.url);
      formData.append("songName", song.songName);
      formData.append("songDescriptionTr", song.songDescriptionTr);
      formData.append("songDescriptionEn", song.songDescriptionEn);
      formData.append("songDescriptionDe", song.songDescriptionDe);
      formData.append("songDescriptionAr", song.songDescriptionAr);
      formData.append("status", song.status);
      formData.append("composerId", song.composerId);
      formData.append("songCategoryData", JSON.stringify(song.songCategoryData));
      formData.append("isTrial", song.isTrial);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(`/api/songs`, formData, config);
      let { data } = await response.data;
      if (response.data.success === true) {

        return data;
      }
    } catch (error) {


      return null;
    }
  }
);

export const updateSong = createAsyncThunk(
  "songs/updateSong",
  async (song, { dispatch, getState }) => {
    let formData = new FormData();

    formData.append("url", song.url);
    formData.append("songName", song.songName);
    formData.append("songDescriptionTr", song.songDescriptionTr);
    formData.append("songDescriptionEn", song.songDescriptionEn);
    formData.append("songDescriptionDe", song.songDescriptionDe);
    formData.append("songDescriptionAr", song.songDescriptionAr);
    formData.append("status", song.status);
    formData.append("songId", song.songId);
    formData.append("composerId", song.composerId);
    formData.append("songCategoryData", JSON.stringify(song.songCategoryData));
    formData.append("isTrial", song.isTrial);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/songs/${song.songId}`,
      formData,
      config
    );
    const { data } = await response.data;
    if (response.data.success === true) {

      return data;
    }
    return null;
  }
);

export const removeSong = createAsyncThunk(
  "songs/removeSong",
  async (songId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/songs/${songId}`);
    if (response.data.success === true) {
      return songId;
    }
    return songId;
  }
);

const songsAdapter = createEntityAdapter({
  selectId: (song) => song.songId,
});

export const { selectAll: selectsongs, selectById: selectsongsById } =
  songsAdapter.getSelectors((state) => state.songs);

const songsSlice = createSlice({
  name: "songs",
  initialState: songsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    songDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setSongSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewSongDialog: (state, action) => {
      state.songDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewSongDialog: (state, action) => {
      state.songDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditSongDialog: (state, action) => {
      state.songDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditSongDialog: (state, action) => {
      state.songDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateSong.fulfilled]: songsAdapter.upsertOne,
    [addSong.fulfilled]: songsAdapter.addOne,
    [removeSong.fulfilled]: (state, action) =>
      songsAdapter.removeOne(state, action.payload),
    [getSongs.fulfilled]: songsAdapter.setAll,
    [getSongByQuery.fulfilled]: songsAdapter.setAll,
  },
});

export const {
  setSongSearchText,
  openNewSongDialog,
  closeNewSongDialog,
  openEditSongDialog,
  closeEditSongDialog,
  openConfirmDialog,
  closeConfirmDialog,
  openResetPasswordDialog,
  closeResetPasswordDialog,
} = songsSlice.actions;

export default songsSlice.reducer;
