import LoadingComponent from "components/Loading";
import AdminLayout from "pages/main/layout/AdminLayout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getinstructors } from "store/main/instructorsSlice";

import { removeinstructor } from "store/main/instructorsSlice";
import ConfirmDialog from "pages/confirmation/ConfirmDialog";
import InstructorsTable from "./InstructorsTable";
import InstructorDialog from "./InstructerDialog";


const AdminInstructorsDashboard = (props) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(getinstructors()).then(() => setDataLoading(false));
  }, [dispatch]);

  if (dataLoading) {
    return <LoadingComponent />;
  }

  return (
    <AdminLayout>
      <InstructorDialog />
      <InstructorsTable />
      <ConfirmDialog handleDeleteData={removeinstructor} />
    </AdminLayout>
  );
};

AdminInstructorsDashboard.propTypes = {};

export default AdminInstructorsDashboard;
