import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";


export const getUserInfos = createAsyncThunk(
  "userInfos/getUserInfos",
  async () => {
    const response = await axiosConfig.get(`/api/userInfos`);
    // const response = await axios.get(`${proxy}/api/userInfos`)

    let { data } = await response.data;
    return data;
  }
);

export const addUserInfo = createAsyncThunk(
  "userInfos/addUserInfo",
  async (userInfo, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(`/api/user-info`, userInfo);
      let { data } = await response.data;
      if (response.data.success === true) {

        return data;
      }
    } catch (error) {

      return null;
    }
  }
);

export const updateUserInfo = createAsyncThunk(
  "userInfos/updateUserInfo",
  async (userInfo, { dispatch, getState }) => {
    const response = await axiosConfig.put(`/api/user-info/${userInfo.userInfoId}`, userInfo);
    const { data } = await response.data;
    if (response.data.success === true) {

      return data;
    }
    return null;
  }
);

export const removeUserInfo = createAsyncThunk(
  "userInfos/removeUserInfo",
  async (userInfoId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/userInfos/${userInfoId}`);
    if (response.data.success === true) {

      return userInfoId;
    }
    return userInfoId;
  }
);

const userInfosAdapter = createEntityAdapter({
  selectId: (userInfo) => userInfo.userInfoId,
});

export const {
  selectAll: selectUserInfos,
  selectById: selectUserInfoById,
} = userInfosAdapter.getSelectors((state) => state.userInfos);

const userInfosSlice = createSlice({
  name: "userInfos",
  initialState: userInfosAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    userInfoDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setUserInfoSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewUserInfoDialog: (state, action) => {
      state.userInfoDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewUserInfoDialog: (state, action) => {
      state.userInfoDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditUserInfoDialog: (state, action) => {
      state.userInfoDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditUserInfoDialog: (state, action) => {
      state.userInfoDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateUserInfo.fulfilled]: userInfosAdapter.upsertOne,
    [addUserInfo.fulfilled]: userInfosAdapter.addOne,
    [removeUserInfo.fulfilled]: (state, action) =>
      userInfosAdapter.removeOne(state, action.payload),
    [getUserInfos.fulfilled]: userInfosAdapter.setAll,
  },
});

export const {
  setUserInfoSearchText,
  openNewUserInfoDialog,
  closeNewUserInfoDialog,
  openEditUserInfoDialog,
  closeEditUserInfoDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = userInfosSlice.actions;

export default userInfosSlice.reducer;
