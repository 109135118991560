import { Icon, Tooltip } from '@mui/material';
import { red } from '@mui/material/colors'
import MDTypography from 'components/MDTypography'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getUserFavoritesByQuery } from 'store/main/userFavoritesSlice';
import { removeUserFavorites } from 'store/main/userFavoritesSlice';
import { addUserFavorites } from 'store/main/userFavoritesSlice';

function FavoriteButton({ favoriteId, listId, details }) {
  const dispatch = useDispatch();
  const { userName } = useSelector((state) => state.auth);
  const { isTrial } = useSelector((state) => state.payments);

  const { t } = useTranslation();


  function handleFavorite(event) {
    event.preventDefault();

    if (favoriteId === 0) {
      const data = {
        status: "active",
        listId,
        userName,
      };
      dispatch(addUserFavorites(data)).then((params) => {
        dispatch(getUserFavoritesByQuery(userName));
      });
    } else {
      dispatch(removeUserFavorites(favoriteId));

    }
  }

  if (isTrial) {
    if (details.isTrial) {
      return <MDTypography
        onClick={handleFavorite}
        fontSize={"2.2rem"}
        sx={{
          color: favoriteId !== 0 && red[700],
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            color: red[700],
          },
        }}>
        <Icon>{favoriteId === 0 ? "favorite_outlined" : "favorite"}</Icon>
      </MDTypography>
    }

    return <MDTypography
      onClick={handleFavorite}
      fontSize={"2.2rem"}>
      <Tooltip title={t("upgrade_your_plan")}>

        <Icon>lock</Icon>
      </Tooltip>
    </MDTypography>;
  }

  return (
    <MDTypography
      onClick={handleFavorite}
      fontSize={"2.2rem"}
      sx={{
        color: favoriteId !== 0 && red[700],
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          color: red[700],
        },
      }}>
      <Icon>{favoriteId === 0 ? "favorite_outlined" : "favorite"}</Icon>
    </MDTypography>
  )
}

export default FavoriteButton