import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";


export const getinstructorCourses = createAsyncThunk(
  "instructorCourses/getinstructorCourses",
  async () => {
    const response = await axiosConfig.get(`/api/instructor-courses`);
    // const response = await axios.get(`${proxy}/api/instructorCourses`)

    let { data } = await response.data;
    console.log(data);
    return data;
  }
);

export const getInstructorCoursesById = createAsyncThunk(
  "instructorCourses/getInstructorCoursesById",
  async (instructorId) => {
    const response = await axiosConfig.get(`/api/instructor-courses/query?instructorId=${instructorId}`);
    let { data } = await response.data;
    return data;
  }
)

export const addinstructorCourses = createAsyncThunk(
  "instructorCourses/addinstructorCourses",
  async (instructorCourse, { dispatch, getState }) => {
    try {
      let formData = new FormData();

      formData.append("courseName", instructorCourse.courseName);
      formData.append("courseDescriptionTr", instructorCourse.courseDescriptionTr);
      formData.append("courseDescriptionEn", instructorCourse.courseDescriptionEn);
      formData.append("courseDescriptionDe", instructorCourse.courseDescriptionDe);
      formData.append("courseDescriptionAr", instructorCourse.courseDescriptionAr);
      formData.append("url", instructorCourse.url);
      formData.append("duration", instructorCourse.duration);
      formData.append("level", instructorCourse.level);
      formData.append("instrumentId", instructorCourse.instrumentId);
      formData.append("instructorId", instructorCourse.instructorId);
      formData.append("status", instructorCourse.status);


      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/instructor-courses`,
        formData,
        config
      );
      let { data } = await response.data;
      if (response.data.success === true) {

        return data;
      }
    } catch (error) {


      return null;
    }
  }
);

export const updateinstructorCourses = createAsyncThunk(
  "instructorCourses/updateinstructorCourses",
  async (instructorCourse, { dispatch, getState }) => {
    let formData = new FormData();

    formData.append("courseId", instructorCourse.courseId);
    formData.append("courseName", instructorCourse.courseName);
    formData.append("courseDescriptionTr", instructorCourse.courseDescriptionTr);
    formData.append("courseDescriptionEn", instructorCourse.courseDescriptionEn);
    formData.append("courseDescriptionDe", instructorCourse.courseDescriptionDe);
    formData.append("courseDescriptionAr", instructorCourse.courseDescriptionAr);
    formData.append("url", instructorCourse.url);
    formData.append("duration", instructorCourse.duration);
    formData.append("level", instructorCourse.level);
    formData.append("instrumentId", instructorCourse.instrumentId);
    formData.append("instructorId", instructorCourse.instructorId);
    formData.append("status", instructorCourse.status);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/instructor-courses/${instructorCourse.courseId}`,
      formData,
      config
    );
    const { data } = await response.data;
    if (response.data.success === true) {
   
      return data;
    }
    return null;
  }
);

export const removeinstructorCourses = createAsyncThunk(
  "instructorCourses/removeinstructorCourses",
  async (courseId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/instructor-courses/${courseId}`);
    if (response.data.success === true) {

      return courseId;
    }
    return courseId;
  }
);

const instructorCoursesAdapter = createEntityAdapter({
  selectId: (instructorCourse) => instructorCourse.courseId,
});

export const {
  selectAll: selectinstructorCourses,
  selectById: selectinstructorCoursesById,
} = instructorCoursesAdapter.getSelectors((state) => state.instructorCourses);

const instructorCoursesSlice = createSlice({
  name: "instructorCourses",
  initialState: instructorCoursesAdapter.getInitialState({
    searchText: "",
    instrumentValue: {
      id: "",
      name: "",
    },
    instructorValue: {
      id: "",
      name: "",
    },
    routeParams: {},
    instructorCourseDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setinstructorCoursesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    setInstrumentValue: (state, action) => {
      state.instrumentValue = action.payload;
    },
    setInstructorValue: (state, action) => {
      state.instructorValue = action.payload;
    },
    openNewinstructorCoursesDialog: (state, action) => {
      state.instructorCourseDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewinstructorCoursesDialog: (state, action) => {
      state.instructorCourseDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditinstructorCoursesDialog: (state, action) => {
      state.instructorCourseDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditinstructorCoursesDialog: (state, action) => {
      state.instructorCourseDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
    resetInstructorCoursesSearch: (state, action) => {
      state.searchText = "";
      state.instrumentValue = {
        id: "",
        name: "",
      };
      state.categoriesValue = {
        id: "",
        name: "",
      };
    }
  },
  extraReducers: {
    [updateinstructorCourses.fulfilled]: instructorCoursesAdapter.upsertOne,
    [addinstructorCourses.fulfilled]: instructorCoursesAdapter.addOne,
    [removeinstructorCourses.fulfilled]: (state, action) =>
      instructorCoursesAdapter.removeOne(state, action.payload),
    [getinstructorCourses.fulfilled]: instructorCoursesAdapter.setAll,
    [getInstructorCoursesById.fulfilled]: instructorCoursesAdapter.setAll,
  },
});

export const {
  resetInstructorCoursesSearch,
  setinstructorCoursesSearchText,
  setInstrumentValue,
  setInstructorValue,
  openNewinstructorCoursesDialog,
  closeNewinstructorCoursesDialog,
  openEditinstructorCoursesDialog,
  closeEditinstructorCoursesDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = instructorCoursesSlice.actions;

export default instructorCoursesSlice.reducer;
