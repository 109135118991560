import React from "react";
import DashboardLayout from "pages/main/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/main/DashboardNavbar";

import MDBox from "components/MDBox";

const StudentLayout = ({ children }) => {
  return (
    <DashboardLayout>
      <div style={{ minHeight: "calc(100vh - 3rem)", display: "flex", flexDirection: "column" }}>
        <DashboardNavbar />
        {children}


      </div>
    </DashboardLayout>
  );
};

StudentLayout.propTypes = {};

export default StudentLayout;
