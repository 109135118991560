import {
  DataGrid,
  trTR,
  enUS,
  deDE,
  arSD,
  GridActionsCellItem,
  GridToolbar
} from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useMemo, useState } from "react";
import { Box, Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { useDispatch, useSelector } from "react-redux";
import { darken, lighten } from "@mui/material/styles";
import FileCopyIcon from "@mui/icons-material/DisabledByDefault";
import EditIcon from "@mui/icons-material/Edit";

import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";
import { useTranslation } from "react-i18next";
import MDAvatar from "components/MDAvatar";
import { imageProxy } from "features/api/proxy";
import {
  openEditSongDialog,
  openNewSongDialog,
  selectsongs,
  updateSong,
} from "store/main/songsSlice";
import { selectcategories } from "store/main/categoriesSlice";
import { openConfirmDialog } from "store/main/confirmSlice";
import dayjs from "dayjs";

const SongsTable = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(selectsongs);
  const categories = useSelector(selectcategories);

  const { t, i18n } = useTranslation();
  const [pageSize, setPageSize] = useState(15);

  const handleOpenNew = () => {
    dispatch(openNewSongDialog());
  };

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const editStatus = React.useCallback(
    (id) => () => {
      let data = {
        ...id.row,
        status: id.row.status === "active" ? "passive" : "active",
      };
      dispatch(updateSong(data));
    }, [dispatch]
  );

  const editData = React.useCallback(
    (id) => () => {
      let data = id.row;
      let categoriesData = data.songCategoryData;
      let categoriesArray = [];
      categoriesData &&
        categoriesData.length > 0 &&
        categoriesData.map((c) => {
          let category = categories.find((f) => f.categoryId === c.categoryId);
          categoriesArray.push(category);
          return category;
        });

      dispatch(openEditSongDialog({ ...data, songCategoryData: categoriesArray }));
    },
    [categories, dispatch]
  );

  const deletaData = React.useCallback(
    (id) => () => {
      let data = { ...id.row, table: "songsTable" };
      dispatch(openConfirmDialog(data));
    },
    [dispatch]
  );

  const columns = useMemo(() => [
    {
      field: "actions",
      type: "actions",
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Düzenle"
          onClick={editData(params)}
          disabled={params.row.status === "passive" ? true : false}
        />,
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label={params.row.status === "active" ? "Pasife al" : "active et"}
          onClick={editStatus(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={t("remove")}
          onClick={deletaData(params)}
          showInMenu
        />,
      ],
    },
    {
      headerName: t("id"),
      field: "songId",
      minWidth: 70,
      hideable: false,
    },
    {
      headerName: t("songName"),
      field: "songName",
      minWidth: 400,
      hideable: false,
    },
    {
      headerName: t("songDescription"),
      field: "songDescription",
      minWidth: 400,
      hideable: false,
      renderCell: (params) => {
        console.log(params);
        if (i18n.language.match("tr")) {
          return params.row.songDescriptionTr;
        }
        else if (i18n.language.match("en")) {
          return params.row.songDescriptionEn;
        }
        else if (i18n.language.match("de")) {
          return params.row.songDescriptionDe;
        }
        else if (i18n.language.match("ar")) {
          return params.row.songDescriptionAr
        }
        else {
          return null;
        }
      }
    },
    {
      headerName: t("composerName"),
      field: "composerInfo",
      minWidth: 200,
      hideable: false,
      renderCell: (params) => {
        return params.value.composerName;
      }
    },
    {
      headerName: "url",
      field: "url",
      minWidth: 140,
      hideable: false,
      renderCell: (params) => {
        return (
          <MDAvatar src={`${imageProxy}/${params.value}`} alt={params.value} />
        );
      },
    },
    {
      headerName: t("createdDate"),
      field: "createdDate",
      minWidth: 170,
      hideable: false,
      type: "date",
      renderCell: (params) => {
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: t("isTrial"),
      field: "isTrial",
      resizable: true,
      minWidth: 150,
    },
    {
      headerName: t("status"),
      field: "status",
      resizable: true,
      minWidth: 150,
    },


  ], [editData, editStatus, t, i18n.language, deletaData],);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox p={3} lineHeight={1}>
        <TableContainer sx={{ boxShadow: "none" }}>
          <MDBox sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={7}>
                <MDBox mb={1}>
                  <MDTypography variant="h5">{t("songs")}</MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2" color="text">
                    {t("songTable")}
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={handleOpenNew}
                >
                  <Icon>add</Icon>&nbsp;{t("AddNewSong")}
                </MDButton>
              </Grid>
            </Grid>
            <div style={{ flexGrow: 1, width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      height: "100%",
                      width: 1,
                      "& .super-app-theme--iptal": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.error.main,
                            theme.palette.mode
                          ),
                      },
                      "& .super-app-theme--tamamlandi": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.info.main,
                            theme.palette.mode
                          ),
                      },
                    }}
                  >
                    <DataGrid
                      localeText={
                        (i18n.language.match("tr") && trTR.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("en") && enUS.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("de") && deDE.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("ar") && arSD.components.MuiDataGrid.defaultProps.localeText)
                      }
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      rowsPerPageOptions={[15, 30, 60]}
                      pagination
                      autoHeight
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      getRowClassName={(params) =>
                        `super-app-theme--${params.row.status}`
                      }
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                    />
                  </Box>
                </div>
              </div>
            </div>
          </MDBox>
        </TableContainer>
      </MDBox>
    </Card>
  );
};

SongsTable.propTypes = {};

export default SongsTable;
