import { Box, Card, Icon, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { selectLiveSupports } from 'store/main/liveSupportsSlice';

function LatestSupports() {
  const { t } = useTranslation();
  const liveSupports = useSelector(selectLiveSupports);
  return (
    <Card sx={{ padding: 3, gap: 2, }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Icon>history</Icon><Typography variant="h5">Son Canlı Destekler</Typography>
      </Box>


      <MDButton component={Link} to={`${t("supportRoute")}/${t("new")}`} variant={"outlined"} color="info">Yeni Canlı Destek Başlat <Icon fontSize="large" sx={{ marginLeft: 1 }}>add_circle_outlined</Icon></MDButton>

      <Box sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
        {liveSupports.length > 0 && liveSupports.map((support) => (
          <MDButton component={Link} to={`${t("supportRoute")}/${t(support.id)}`} sx={{ width: "100%" }} variant={"gradient"} color="info">
            {support.supportHeader}
          </MDButton>
        ))
        }
      </Box>
    </Card>
  )
}

export default LatestSupports