import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React from 'react'
import { useTranslation } from 'react-i18next'

function FeedbackBox({ value }) {

  const { t } = useTranslation();

  const bgValue = (val) => {
    if (val === "problem") {
      return "error"
    }
    else if (val === "complaint") {
      return "warning"
    }
    else if (val === "suggestion") {
      return "success"
    }
    return "info"
  }

  return (
    <MDBox
      variant={"contained"}
      borderRadius="lg"
      p={0.5}
      width={"100%"}
      textAlign={"center"}
      color={"white"}
      bgColor={bgValue(value)}
      shadow="md">
      <MDTypography color={"white"} variant={"h6"}>{t(value)}</MDTypography>
    </MDBox>
  )
}

export default FeedbackBox