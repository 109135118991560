import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ImageUpload from "components/UploadComponents/ImageUpload";
import { imageProxy } from "features/api/proxy";
import { useTranslation } from "react-i18next";
import {
  addMusician,
  closeEditMusicianDialog,
  closeNewMusicianDialog,
  updateMusician,
} from "store/main/musiciansSlice";
import { Grid } from "@mui/material";
const defaultFormState = {
  musicianId: 0,
  musicianName: "",
  musicianSurname: "",
  musicianDescriptionTr: "",
  musicianDescriptionEn: "",
  musicianDescriptionDe: "",
  musicianDescriptionAr: "",
  userName: "",
  password: "",
  confirmPassword: "",
  status: "active",
  url: "",
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
];

function MusicianDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const musicianDialog = useSelector(
    ({ musicians }) => musicians.musicianDialog
  );

  const [photo, setphoto] = useState();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (musicianDialog.type === "edit" && musicianDialog.data) {
      setForm({ ...musicianDialog.data });
      fetch(`${imageProxy}/${musicianDialog.data.url}`)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], musicianDialog.data.url, { type: blob.type });
          setphoto(file);
        })
        .catch(error => {
          console.error('Dosya bulunamadı:', error);
        });
      let status = statusOptions.find(
        (f) => f.name === musicianDialog.data.status
      );
      setStatusValue(status);
    }

    /**
     * Dialog type: 'new'
     */
    if (musicianDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...musicianDialog.data,
      });
      let status = statusOptions.find(
        (f) => f.name === defaultFormState.status
      );
      setStatusValue(status);
      setphoto();
    }
  }, [musicianDialog.data, musicianDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (musicianDialog.props.open) {
      initDialog();
    }
  }, [musicianDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return musicianDialog.type === "edit"
      ? dispatch(closeEditMusicianDialog())
      : dispatch(closeNewMusicianDialog());
  }

  const handleSetImage = (file) => {
    setphoto(file);
    if (!file) {
      setInForm("url", null);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (musicianDialog.type === "new") {
      let data = {
        ...form,
        status: statusValues.id,
        url: photo,
      };
      setphoto();
      dispatch(addMusician(data));
    } else {
      let data = {
        ...form,
        status: statusValues.id,
        url: photo,
      };
      setphoto();
      dispatch(updateMusician(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    if (musicianDialog.type === "new") {
      return (
        form.musicianName.length > 0 && form.userName.length > 0 &&
        form.password.length >= 0 && form.password === form.confirmPassword &&
        form.musicianDescriptionTr.length > 0 && form.musicianDescriptionEn.length > 0 &&
        form.musicianDescriptionDe.length > 0 && form.musicianDescriptionAr.length > 0 &&
        photo !== undefined
      );
    } else {
      return (
        form.musicianName.length > 0 && form.userName.length > 0 &&
        form.musicianDescriptionTr.length > 0 && form.musicianDescriptionEn.length > 0 &&
        form.musicianDescriptionDe.length > 0 && form.musicianDescriptionAr.length > 0 &&
        photo !== undefined
      );
    }
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...musicianDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">{t("musiciansInfo")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="musicianId"
          name="musicianId"
          label={t("musicianId")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.musicianId}
          disabled
        />
        <TextField
          autoFocus
          margin="dense"
          id="musicianName"
          name="musicianName"
          label={t("musicianName")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.musicianName}
          onChange={handleChange}
          required
          error={form.musicianName ? false : true}
          helperText={t("enterMusicianName")}
          focused
          disabled={musicianDialog.type === "new" ? false : true}
        />
        <TextField
          autoFocus
          margin="dense"
          id="musicianSurname"
          name="musicianSurname"
          label={t("musicianSurname")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.musicianSurname}
          onChange={handleChange}
          required
          error={form.musicianSurname ? false : true}
          helperText={t("enterMusicianSurname")}
          focused
          disabled={musicianDialog.type === "new" ? false : true}
        />
        <TextField
          margin="dense"
          id="musicianDescriptionTr"
          name="musicianDescriptionTr"
          label={t("musicianDescriptionTr")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.musicianDescriptionTr}
          onChange={handleChange}
          required
          error={form.musicianDescriptionTr ? false : true}
          helperText={t("enterMusicianDescriptionTr")}
          focused
        />
        <TextField
          margin="dense"
          id="musicianDescriptionEn"
          name="musicianDescriptionEn"
          label={t("musicianDescriptionEn")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.musicianDescriptionEn}
          onChange={handleChange}
          required
          error={form.musicianDescriptionEn ? false : true}
          helperText={t("enterMusicianDescriptionEn")}
          focused
        />
        <TextField
          margin="dense"
          id="musicianDescriptionDe"
          name="musicianDescriptionDe"
          label={t("musicianDescriptionDe")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.musicianDescriptionDe}
          onChange={handleChange}
          required
          error={form.musicianDescriptionDe ? false : true}
          helperText={t("enterMusicianDescriptionDe")}
          focused
        />
        <TextField
          margin="dense"
          id="musicianDescriptionAr"
          name="musicianDescriptionAr"
          label={t("musicianDescriptionAr")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.musicianDescriptionAr}
          onChange={handleChange}
          required
          error={form.musicianDescriptionAr ? false : true}
          helperText={t("enterMusicianDescriptionAr")}
          focused
        />
        {musicianDialog.type === "new" ? (
          <MDBox mt={1}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  id="userName"
                  name="userName"
                  label={t("userName")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={form.userName}
                  onChange={handleChange}
                  required
                  error={form.userName ? false : true}
                  helperText={t("enterUserName")}
                  focused
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  id="password"
                  name="password"
                  label={t("password")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={form.password}
                  onChange={handleChange}
                  required
                  error={form.password ? false : true}
                  helperText={t("enterPassword")}
                  focused
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  id="confirmPassword"
                  name="confirmPassword"
                  label={t("confirmPassword")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={form.confirmPassword}
                  onChange={handleChange}
                  required
                  error={form.password ? false : true}
                  helperText={t("enterConfirmPassword")}
                  focused
                />
              </Grid>
            </Grid>
          </MDBox>
        ) :
          <TextField
            margin="dense"
            id="userName"
            name="userName"
            label={t("userName")}
            type="text"
            fullWidth
            variant="outlined"
            value={form.userName}
            onChange={handleChange}
            required
            error={form.userName ? false : true}
            helperText={t("enterUserName")}
            focused
          />}
        <MDBox mt={2}>
          <Autocomplete
            value={statusValues} // Dikkat: statusValues -> statusValue olarak değiştirildi
            options={statusOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("status")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                setStatusValue({
                  id: newValue, // newValue.id -> newValue olarak değiştirildi
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                setStatusValue({
                  name: newValue.inputValue,
                });
              } else {
                setStatusValue(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox mt={2}>
          <ImageUpload
            handleSetImage={handleSetImage}
            file={photo}
            url={form && form.url ? `${imageProxy}/${form.url}` : null}
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MusicianDialog;
