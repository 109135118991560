import React, { useEffect, useState } from "react";
import AdminLayout from "pages/main/layout/AdminLayout";
import LessonsTable from "./LessonsTable";
import { useDispatch } from "react-redux";
import LoadingComponent from "components/Loading";
import { getLessons } from "store/main/lessonsSlice";
import LessonDialog from "./LessonDialog";
import { getInstruments } from "store/main/instrumentsSlice";
import { getMusicians } from "store/main/musiciansSlice";
import ConfirmDialog from "pages/confirmation/ConfirmDialog";
import { removeLesson } from "store/main/lessonsSlice";

const AdminLessonsDashboard = (props) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  const [instrumentLoading, setInstrumentLoading] = useState(true);
  const [musicianLoading, setMusicianLoading] = useState(true);

  useEffect(() => {
    dispatch(getLessons()).then(() => setDataLoading(false));
    dispatch(getInstruments()).then(() => setInstrumentLoading(false))
    dispatch(getMusicians()).then(() => setMusicianLoading(false))
  }, [dispatch]);

  if (dataLoading || instrumentLoading || musicianLoading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <LessonsTable />
      <LessonDialog />
      <ConfirmDialog handleDeleteData={removeLesson} />
    </AdminLayout>
  );
};


export default AdminLessonsDashboard;
