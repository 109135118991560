/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the page layout of Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the DefaultNavbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `name` key is used for the name of the route on the DefaultNavbar.
  3. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  4. The `icon` key is used for the icon of the route on the DefaultNavbar, you have to add a node.
  5. The `collapse` key is used for making a collapsible item on the DefaultNavbar that contains other routes inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  6. The `route` key is used to store the route location which is used for the react router.
  7. The `href` key is used to store the external links location.
*/
import SignIn from "pages/auth/SignIn"

import Assignment from "assets/icons/assignment.svg";
import SignInIcon from "assets/icons/sign-in.svg";
import SignUp from "pages/auth/SignUp/SignUp";

const webPageRoutes = [
  {
    name: "sign_in",
    key: "sign-in",
    icon: <img style={{height: "24px"}} src={SignInIcon} alt="home"></img>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    name: "sign_up",
    key: "sign-up",
    icon: <img style={{height: "24px"}} src={Assignment} alt="home"></img>,
    route: "/sign-up",
    component: <SignUp />,
  },
  // {
  //   name: "app_registration",
  //   key: "app_registration",
  //   icon: <Icon>app_registration</Icon>,
  //   route: "/register",
  //   component: <Register />,
  // },
];

export default webPageRoutes;
