// SocketContext.js
import { socketIoProxy } from 'features/api/proxy';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import { getLiveSupports } from 'store/main/liveSupportsSlice';

// Context oluştur
const AdminSocketContext = createContext();



// Provider oluştur
export const AdminSocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);
  const { userName } = useSelector(state => state.auth);

  useEffect(() => {
    try {
      const newSocket = io(socketIoProxy);
      setSocket(newSocket);

      newSocket.on('connect', () => {
        console.log('Admin Socket Connected');
        newSocket.emit('admin_join_room', { userName, liveSupportId: 0 });

        newSocket.on("new_live_support_request", (data) => {
          console.log("new_live_support_request", data);
          dispatch(getLiveSupports());
        });

        newSocket.on("refresh_live_supports", (data) => {
          console.log("refresh_live_supports", data);
          dispatch(getLiveSupports());
        });
      });
      
      return () => {
        newSocket.off('connect');
        newSocket.off('new_live_support_request');
        newSocket.off('refresh_live_supports');
  
  
        if (newSocket) {
          newSocket.disconnect();
  
        }
      };
    } catch (error) {
      console.log("Error in AdminSocketProvider", error);
    }

  }, []);

  return (
    <AdminSocketContext.Provider value={{ socket }}>
      {children}
    </AdminSocketContext.Provider>
  );
};
// Hook oluştur
export const useSocket = () => {
  const context = useContext(AdminSocketContext);
  if (!context) {
    throw new Error('useSocket hook must be used within a SocketProvider');
  }
  return context;
};
