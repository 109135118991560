import React from 'react'
import StudentLayout from 'pages/main/layout/StudentLayout'
import MDBox from 'components/MDBox'
import Sidenav from './components/Sidenav'
import { Grid } from '@mui/material'
import Header from './header/Header'
import ChangePassword from './changePassword/ChangePassword'
import CancelSubscription from './cancelSubscription/CancelSubscription'
import PastSubscriptions from './pastSubscription/PastSubscriptions'
import Subscription from './subscription/Subscription'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getPricingPlans } from 'store/main/pricingPlansSlice'
import { useState } from 'react'
import LoadingComponent from 'components/Loading'

function ProfileDashboard() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(getPricingPlans()).then(() => setLoading(false));
  }, [dispatch]);

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <StudentLayout>
      <MDBox>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword />
                </Grid>
                <Grid item xs={12}>
                  <Subscription />
                </Grid>
                <Grid item xs={12}>
                  <PastSubscriptions />
                </Grid>
                <Grid item xs={12}>
                  <CancelSubscription />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </StudentLayout>
  )
}


export default ProfileDashboard
