import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axiosConfig from "features/api/axiosConfig";
import showToast from "hooks/useCustomToast";


export const getVideoAnalyze = createAsyncThunk(
  "messages/getVideoAnalyze",
  async () => {
    const response = await axiosConfig.get(`/api/analytics/video-analyzes`);

    let { data } = await response.data;
    return data;
  }
);

export const addMessage = createAsyncThunk(
  "messages/addMessage",
  async (message, { dispatch, getState }) => {
    try {

      const response = await axiosConfig.post(
        `/api/analytics/messages`,
        message,
      );

      const data = response.data;

      try {
        if (data.success) {
          showToast("check_circle_icon", "message_sent_successfully", "success");
        }
      } catch (error) {
        showToast("error", "message_couldnt_send", "error");
      }

      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

// export const updateVideoAnalyze = createAsyncThunk(
//   "songAnalyze/updateVideoAnalyze",
//   async (songAnalyze, { dispatch, state }) => {
//     try {
//       const response = await axiosConfig.put(
//         `/api/analytics/video-analyzes/${songAnalyze.videoAnalyzeId}`,
//         songAnalyze,
//       );
//       console.log(response);
//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

const messagesAdapter = createEntityAdapter({
  selectId: (message) => message.messageId,
});

export const {
  selectAll: selectMessages,
  selectById: selectMessagesById
} = messagesAdapter.getSelectors((state) => state.messages);


const messagesSlice = createSlice({
  name: "messages",
  initialState: messagesAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    messageDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewMessageDialog: (state, action) => {
      state.messageDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewMessageDialog: (state, action) => {
      state.messageDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditMessageDialog: (state, action) => {
      state.messageDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditMessageDialog: (state, action) => {
      state.messageDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [addMessage.fulfilled]: messagesAdapter.addOne,
    [getVideoAnalyze.fulfilled]: messagesAdapter.setAll,
  },
});


export const {
  openNewMessageDialog,
  closeNewMessageDialog,
  openEditMessageDialog,
  closeEditMessageDialog,
} = messagesSlice.actions;

export default messagesSlice.reducer;
