/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.lasolist.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.lasolist.com)

Coded by www.lasolist.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";
import { useTranslation } from "react-i18next";
import { Card, Container } from "@mui/material";

import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

import IyzicoLogos from "assets/icons/iyzico-logos.svg";
import { Link } from "react-router-dom";



function MainFooter({ company, links, sections }) {
  const { href, name } = company;
  const { size } = typography;
  const { t } = useTranslation();



  const renderLinks = (links) =>
    links.map((link) => (
      <MDBox key={link.name} component="li" lineHeight={1}>
        <Link to={t(link.href)}>
          <MDTypography variant="body2" fontWeight="regular" color="text">
            {t(link.name)}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  const renderSections = () =>
    sections.map((link) => (
      <MDBox key={link.name} component="li" lineHeight={1}>
        <MDTypography variant="h4" color="text">
          {t(link.name)}
        </MDTypography>
        {renderLinks(link.routes)}
      </MDBox>
    ));



  return (
    <MDBox>
      <Card
        sx={{ width: "calc(100% - 2rem)", m: 2, display: "flex", justifyContent: "space-between", paddingY: 4 }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"

      >
        <MDBox
          component={Container}
          display="flex"
          justifyContent={"space-between"}
          flexWrap="wrap"
          color="text"
          fontSize={size.sm}
          flexDirection={{ sm: "column", lg: "row" }}
          alignItems={"center"}
          gap={{ sm: 4 }}
        >
          <MDBox component={Link} to={href} width={{ xs: "100%", lg: "auto" }} alignItems={{ xs: "center", lg: "flex-start" }} gap={{ xs: 3, lg: 1 }} sx={{ display: "flex", flexDirection: "column", height: "full", justifyContent: "space-between", alignItems: "center", }}>
            <div className="CustomFont" style={{ color: "#7B809A", fontSize: 50, lineHeight: "3rem" }}>{name}</div>
            <MDBox sx={{ display: "flex", gap: 3 }}>
              <FaInstagram size={30} />
              <FaFacebook size={30} />
              <FaTwitter size={30} />

            </MDBox>
            <MDBox display={"flex"}>
              &copy; Copyright {new Date().getFullYear()}. All Rights Reserved.

            </MDBox>

            <MDBox>
              <img alt="iyzico-logos" style={{ height: 20 }} src={IyzicoLogos} />
            </MDBox>

          </MDBox>

          <MDBox
            component="ul"
            sx={({ breakpoints }) => ({
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              listStyle: "none",
              mt: 3,
              mb: 0,
              p: 0,
              gap: 4,
              flexDirection: "column",

              [breakpoints.up("lg")]: {
                mt: 0,

              },
              [breakpoints.up("md")]: {
                mt: 0,
                flexDirection: "row",
                alignSelf: "center",
                justifyContent: "center",
              },
            })}
          >
            {renderSections()}
          </MDBox>
        </MDBox>

      </Card>
    </MDBox >
  );
}

// Setting default values for the props of Footer
MainFooter.defaultProps = {
  company: { href: "/", name: "laSolist" },
  links: [
    { href: `cookiesRoute`, name: "cookiesName" },
    { href: `privacyRoute`, name: "privacyName" },
    { href: `userAgreementRoute`, name: "userAgreementName" },
  ],
  sections: [
    {
      name: "links",
      routes: [
        { href: "/sign-in", name: "sign_in" },
        { href: "/sign-up", name: "sign_up" },
        { href: "applicationRoute", name: "instructorApplications" },
        { href: "forgotPasswordRoute", name: "forgotPasswordName" }
      ]
    },
    {
      name: "company",
      routes: [
        { href: "aboutUsRoute", name: "aboutUsName" },
        { href: "contactsRoute", name: "contactsName" },
        { href: "pricingRoute", name: "pricingName" },
        { href: "faqsRoute", name: "faqsName" },
      ]
    },
    {
      name: "legal",
      routes: [
        { href: "cookiesRoute", name: "cookiesName" },
        { href: "privacyRoute", name: "privacyName" },
        { href: "userAgreementRoute", name: "userAgreementName" },
        { href: "deliveryAndRefundRoute", name: "deliveryAndRefundName" },
        { href: "distanceSellingRoute", name: "distanceSellingName" },
      ]
    },
  ],
};

// Typechecking props for the Footer
MainFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
  sections: PropTypes.arrayOf(PropTypes.object),
};

export default MainFooter;
