import { Icon, Tooltip } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function PlayPauseButton({ handleClick }) {
  const { videoData } = useSelector((state) => state.videoPlayer);

  const { t } = useTranslation()

  return (
    <Tooltip title={videoData.isPlaying ? t("Stop") : t("Play")} placement='bottom'>
      <button
        className='PlayPauseButton'
        onClick={handleClick}>
        {videoData.isPlaying ?
          <Icon>pause</Icon> :
          <Icon>play_arrow_rounded</Icon>}
      </button>
    </Tooltip>
  )
}

export default PlayPauseButton