import LoadingComponent from 'components/Loading'
import { React, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSongLists } from 'store/main/songListsSlice'
import LaSolistPlayerSite from '../player/LaSolistPlayerSite'
import { resetToInitialState } from 'store/main/videoPlayerSlice'
import PlayerLayout from 'pages/main/layout/PlayerLayout'

function SongDashboard() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    dispatch(getSongLists()).then(() => setDataLoading(false));
    dispatch(resetToInitialState());
  }, [id, dispatch]);


  if (dataLoading) {
    return <PlayerLayout>
      <LoadingComponent backgroundColor={"#32393D"} />
    </PlayerLayout>
  }


  return (
    <PlayerLayout background={"#32393D"}>
      <LaSolistPlayerSite />
    </PlayerLayout>
  )
}

export default SongDashboard