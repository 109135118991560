import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect } from 'react';

export default function CountrySelect({ countries ,countryValue, setCountryValue }) {
  useEffect(() => {
    console.log(countryValue);
  }, [countryValue])

  return (
    <Autocomplete
      id="country-select-demo"
      value={countryValue}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => "+" + option.phone + " " + option.label}
      renderOption={(props, option, key) => (
        <Box key={key} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
          setCountryValue({
            id: newValue, // newValue.id -> newValue olarak değiştirildi
            name: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          setCountryValue({
            name: newValue.inputValue,
          });
        } else {
          setCountryValue(newValue);
        }
      }}
    />
  );
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
