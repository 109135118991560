import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { closeEditCouponCodeDialog } from "store/main/couponCodesSlice";
import { closeNewCouponCodeDialog } from "store/main/couponCodesSlice";
import { Grid } from "@mui/material";
import { addCouponCode } from "store/main/couponCodesSlice";
import { updateCouponCode } from "store/main/couponCodesSlice";
import MDDatePicker from "components/MDDatePicker";
import { selectinstructors } from "store/main/instructorsSlice";
import { selectPricingPlans } from "store/main/pricingPlansSlice";

const defaultFormState = {
  couponCodeId: 0,
  referenceCode: "",
  discountPercentage: 0,
  creditPercentageToInstructor: 0,
  creditPercentageToStudent: 0,
  createdDate: new Date(),
  expirationDate: new Date(),
  pricingPlans: [],
  status: "active",
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
];

function ComposerDialog(props) {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const couponCodeDialog = useSelector(
    ({ couponCodes }) => couponCodes.couponCodeDialog
  );

  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });

  const instructors = useSelector(selectinstructors);
  const pricingPlans = useSelector(selectPricingPlans);

  const [instructorValue, setInstructorValue] = useState({
    id: 0,
    name: "",
  });

  const initDialog = useCallback(async () => {
    /**
     * Dialog type: 'edit'
     */
    if (couponCodeDialog.type === "edit" && couponCodeDialog.data) {
      setForm({ ...couponCodeDialog.data });
      let status = statusOptions.find(
        (f) => f.name === couponCodeDialog.data.status
      );
      let instructor = instructors.find(
        (f) => f.instructorId === couponCodeDialog.data.instructorId
      )
      const { couponPlansData } = couponCodeDialog.data
      const selectedPlans = couponPlansData.map((plan) => {
        const matchPlan = pricingPlans.find(
          (f) => f.pricingPlanId === plan.pricingPlanId
        );
        return matchPlan;
      })

      setInForm("pricingPlans", selectedPlans)

      console.log(couponCodeDialog.data);
      setStatusValue(status);
      setInstructorValue(instructor);
    }

    /**
     * Dialog type: 'new'
     */
    if (couponCodeDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...couponCodeDialog.data,
      });
      let status = statusOptions.find(
        (f) => f.name === defaultFormState.status
      );
      setStatusValue(status);
    }
  }, [couponCodeDialog.data, couponCodeDialog.type, setForm, setInForm, pricingPlans, instructors]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (couponCodeDialog.props.open) {
      initDialog();
    }
  }, [couponCodeDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return couponCodeDialog.type === "edit"
      ? dispatch(closeEditCouponCodeDialog())
      : dispatch(closeNewCouponCodeDialog());
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (couponCodeDialog.type === "new") {
      let data = {
        ...form,
        status: statusValues.id,
        instructorId: instructorValue.id,
      };
      dispatch(addCouponCode(data));
    } else {
      let data = {
        ...form,
        status: statusValues.id,
        instructorId: instructorValue.id,
      };
      dispatch(updateCouponCode(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    // return form.composerName.length > 0 && photo !== undefined;
    return true
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...couponCodeDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="coupon-code-dialog-title">{t("couponCodeInfo")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="couponCodeId"
          name="couponCodeId"
          label={t("couponCodeId")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.couponCodeId}
          disabled
        />
        <TextField
          autoFocus
          margin="dense"
          id="referenceCode"
          name="referenceCode"
          label={t("referenceCode")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.referenceCode}
          onChange={handleChange}
          required
          error={form.referenceCode ? false : true}
          helperText={t("enterReferenceCode")}
          focused
        />
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="discountPercentage"
                name="discountPercentage"
                label={t("discountPercentage")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.discountPercentage}
                onChange={handleChange}
                required
                error={form.discountPercentage ? false : true}
                helperText={t("enterDiscountPercentage")}
                focused
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="creditPercentageToInstructor"
                name="creditPercentageToInstructor"
                label={t("creditPercentageToInstructor")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.creditPercentageToInstructor}
                onChange={handleChange}
                required
                error={form.creditPercentageToInstructor ? false : true}
                helperText={t("enterCreditPercentageToInstructor")}
                focused
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                id="creditPercentageToStudent"
                name="creditPercentageToStudent"
                label={t("creditPercentageToStudent")}
                type="number"
                fullWidth
                variant="outlined"
                value={form.creditPercentageToStudent}
                onChange={handleChange}
                required
                error={form.creditPercentageToStudent ? false : true}
                helperText={t("enterCreditPercentageToStudent")}
                focused
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MDDatePicker
                value={form.createdDate}
                name={"createdDate"}
                id={"createdDate"}
                onChange={(date) => {
                  const originalDate = new Date(date[0]);
                  setInForm("createdDate", originalDate.toISOString());
                }}
                sx={{ width: "100%" }}
                fullWidth
                input={{
                  placeholder: "Select a date",
                  style: { width: "100%" },
                  helperText: t("enterCreatedDate")
                }} />
            </Grid>
            <Grid item xs={6}>
              <MDDatePicker
                value={form.expirationDate}
                name={"expirationDate"}
                id={"expirationDate"}
                onChange={(date) => {
                  const originalDate = new Date(date[0]);
                  setInForm("expirationDate", originalDate.toISOString());
                }}
                sx={{ width: "100%" }}
                fullWidth
                input={{
                  placeholder: "Select a date",
                  style: { width: "100%" },
                  helperText: t("enterExpirationDate")
                }} />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Autocomplete
            value={instructorValue} // Dikkat: statusValues -> statusValue olarak değiştirildi
            options={instructors}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("instructors")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                setInstructorValue({
                  id: newValue, // newValue.id -> newValue olarak değiştirildi
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                setInstructorValue({
                  name: newValue.inputValue,
                });
              } else {
                setInstructorValue(newValue);
              }
            }}
          />
        </MDBox>

        <MDBox mt={2}>
          <Autocomplete
            multiple
            value={form.pricingPlans}
            options={pricingPlans}
            getOptionLabel={(option) => {
              if (i18n.language.match("tr")) {
                return <MDBox sx={{ color: "rgb(255, 255, 255)" }}>
                  {option.pricingPlanNameTr} - {option.countryISO}
                </MDBox>
              }
              else if (i18n.language.match("en")) {
                return <MDBox sx={{ color: "rgb(255, 255, 255)" }}>
                  {option.pricingPlanNameEn} - {option.countryISO}
                </MDBox>
              }
              else if (i18n.language.match("de")) {
                return <MDBox sx={{ color: "rgb(255, 255, 255)" }}>
                  {option.pricingPlanNameTr} - {option.countryISO}
                </MDBox>
              }
              else {
                return <MDBox sx={{ color: "rgb(255, 255, 255)" }}>
                  {option.pricingPlanNameAr} - {option.countryISO}
                </MDBox>
              }
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("selectPricingPlans")} />
            )}
            onChange={(event, newValue) => {
              setInForm("pricingPlans", newValue);
            }}
          />
        </MDBox>
        <MDBox mt={2}>
          <Autocomplete
            value={statusValues} // Dikkat: statusValues -> statusValue olarak değiştirildi
            options={statusOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("status")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                setStatusValue({
                  id: newValue, // newValue.id -> newValue olarak değiştirildi
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                setStatusValue({
                  name: newValue.inputValue,
                });
              } else {
                setStatusValue(newValue);
              }
            }}
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ComposerDialog;
