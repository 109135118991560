import React from 'react'
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useState } from 'react';
import { FullscreenExit, Fullscreen } from '@mui/icons-material';
function FullScreenButton({ handle }) {

  const [enter, setEnter] = useState(false);

  const handleClick = () => {
    if (enter) {
      handle.exit()
      setEnter(false);
    }
    else {
      handle.enter()
      setEnter(true);
    }
  }

  return (
    <button onClick={handleClick}>
      {
        enter ? <FullscreenExit sx={{ height: "full" }} /> : <Fullscreen sx={{ height: "full" }} />

      }
    </button>
  )
}

export default FullScreenButton