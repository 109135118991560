import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";


export const getPricingPlans = createAsyncThunk(
  "pricingPlans/getPricingPlans",
  async () => {
    const response = await axiosConfig.get(`/api/payment/pricing-plans`);
    let { data } = await response.data;
    return data;
  }
);

export const getCustomPricingPlans = createAsyncThunk(
  "pricingPlans/getCustomPricingPlans",
  async () => {
    const response = await axiosConfig.get("/api/pricing-plans/query?paymentInterval=COUPON");
    let { data } = await response.data;
    return data;
  }
)

export const addPricingPlan = createAsyncThunk(
  "pricingPlans/addPricingPlan",
  async (pricingPlan, { dispatch, getState }) => {
    try {
      let formData = new FormData();
      formData.append("pricingPlanNameTr", pricingPlan.pricingPlanNameTr);
      formData.append("pricingPlanDescriptionTr", pricingPlan.pricingPlanDescriptionTr);
      formData.append("pricingPlanNameEn", pricingPlan.pricingPlanNameEn);
      formData.append("pricingPlanDescriptionEn", pricingPlan.pricingPlanDescriptionEn);
      formData.append("pricingPlanNameDe", pricingPlan.pricingPlanNameDe);
      formData.append("pricingPlanDescriptionDe", pricingPlan.pricingPlanDescriptionDe);
      formData.append("pricingPlanNameAr", pricingPlan.pricingPlanNameAr);
      formData.append("pricingPlanDescriptionAr", pricingPlan.pricingPlanDescriptionAr);
      formData.append("url", pricingPlan.url);
      formData.append("duration", pricingPlan.duration);
      formData.append("price", pricingPlan.price);
      formData.append("discount", pricingPlan.discount);
      formData.append("trialPeriodDays", pricingPlan.trialPeriodDays)
      formData.append("iyzicoProductReference", pricingPlan.iyzicoProductReference);


      formData.append("iyzicoPricingPlanName", pricingPlan.iyzicoPricingPlanName);
      formData.append("paymentInterval", pricingPlan.paymentInterval);
      formData.append("paymentIntervalCount", pricingPlan.paymentIntervalCount);
      formData.append("feature1", pricingPlan.feature1);
      formData.append("feature2", pricingPlan.feature2);
      formData.append("feature3", pricingPlan.feature3);
      formData.append("forStudent", pricingPlan.forStudent);
      formData.append("shouldLesson", pricingPlan.shouldLesson);
      formData.append("shouldSong", pricingPlan.shouldSong);
      formData.append("shouldFeedback", pricingPlan.shouldFeedback);
      formData.append("payPlatform", pricingPlan.payPlatform);
      formData.append("status", pricingPlan.status);

      console.log(formData);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/payment/pricing-plans`,
        formData,
        config
      );

      let { data } = await response.data;
      if (response.data.success === true) {

        return data;
      }
    } catch (error) {


      return null;
    }
  }
);

export const addPaypalPricingPlan = createAsyncThunk(
  "pricingPlans/addPaypalPricingPlan",
  async (pricingPlan, { dispatch, getState }) => {
    try {
      let formData = new FormData();
      formData.append("pricingPlanNameTr", pricingPlan.pricingPlanNameTr);
      formData.append("pricingPlanDescriptionTr", pricingPlan.pricingPlanDescriptionTr);
      formData.append("pricingPlanNameEn", pricingPlan.pricingPlanNameEn);
      formData.append("pricingPlanDescriptionEn", pricingPlan.pricingPlanDescriptionEn);
      formData.append("pricingPlanNameDe", pricingPlan.pricingPlanNameDe);
      formData.append("pricingPlanDescriptionDe", pricingPlan.pricingPlanDescriptionDe);
      formData.append("pricingPlanNameAr", pricingPlan.pricingPlanNameAr);
      formData.append("pricingPlanDescriptionAr", pricingPlan.pricingPlanDescriptionAr);
      formData.append("url", pricingPlan.url);
      formData.append("duration", pricingPlan.duration);
      formData.append("price", pricingPlan.price);
      formData.append("discount", pricingPlan.discount);
      formData.append("trialPeriodDays", pricingPlan.trialPeriodDays);
      formData.append("countryISO", pricingPlan.countryISO);
      formData.append("currencyISO", pricingPlan.currencyISO);
      formData.append("payPlatform", pricingPlan.payPlatform);


      formData.append("paypalProductReference", pricingPlan.paypalProductReference);
      formData.append("paypalPricingPlanName", pricingPlan.paypalPricingPlanName);
      formData.append("pricingPlanDescription", pricingPlan.pricingPlanDescription);
      formData.append("paymentInterval", pricingPlan.paymentInterval);
      formData.append("paymentIntervalCount", pricingPlan.paymentIntervalCount);
      formData.append("feature1", pricingPlan.feature1);
      formData.append("feature2", pricingPlan.feature2);
      formData.append("feature3", pricingPlan.feature3);
      formData.append("forStudent", pricingPlan.forStudent);
      formData.append("shouldLesson", pricingPlan.shouldLesson);
      formData.append("shouldSong", pricingPlan.shouldSong);
      formData.append("shouldFeedback", pricingPlan.shouldFeedback);
      formData.append("status", pricingPlan.status);

      console.log(formData);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/payment/pricing-plans/paypal`,
        formData,
        config
      );

      let { data } = await response.data;
      if (response.data.success === true) {

        return data;
      }
    } catch (error) {


      return null;
    }
  }
)

export const updatePricingPlan = createAsyncThunk(
  "pricingPlans/updatePricingPlan",
  async (pricingPlan, { dispatch, getState }) => {
    let formData = new FormData();

    formData.append("pricingPlanId", pricingPlan.pricingPlanId);
    formData.append("pricingPlanNameTr", pricingPlan.pricingPlanNameTr);
    formData.append("pricingPlanDescriptionTr", pricingPlan.pricingPlanDescriptionTr);
    formData.append("pricingPlanNameEn", pricingPlan.pricingPlanNameEn);
    formData.append("pricingPlanDescriptionEn", pricingPlan.pricingPlanDescriptionEn);
    formData.append("pricingPlanNameDe", pricingPlan.pricingPlanNameDe);
    formData.append("pricingPlanDescriptionDe", pricingPlan.pricingPlanDescriptionDe);
    formData.append("pricingPlanNameAr", pricingPlan.pricingPlanNameAr);
    formData.append("pricingPlanDescriptionAr", pricingPlan.pricingPlanDescriptionAr);
    formData.append("iyzicoProductReference", pricingPlan.iyzicoProductReference);
    formData.append("iyzicoPricingPlanReference", pricingPlan.iyzicoPricingPlanReference);
    formData.append("productName", pricingPlan.productName);
    formData.append("productDescription", pricingPlan.productDescription);
    formData.append("iyzicoPricingPlanName", pricingPlan.iyzicoPricingPlanName);
    formData.append("trialPeriodDays", pricingPlan.trialPeriodDays)
    formData.append("url", pricingPlan.url);
    formData.append("feature1", pricingPlan.feature1);
    formData.append("feature2", pricingPlan.feature2);
    formData.append("feature3", pricingPlan.feature3);
    formData.append("forStudent", pricingPlan.forStudent);
    formData.append("shouldLesson", pricingPlan.shouldLesson);
    formData.append("shouldSong", pricingPlan.shouldSong);
    formData.append("shouldFeedback", pricingPlan.shouldFeedback);
    formData.append("status", pricingPlan.status);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/payment/pricing-plans/${pricingPlan.pricingPlanId}`,
      formData,
      config
    );
    const { data } = await response.data;
    if (response.data.success === true) {
      return data;
    }
    return null;
  }
);

export const removePricingPlan = createAsyncThunk(
  "pricingPlans/removePricingPlan",
  async (pricingPlanId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/pricing-plans/${pricingPlanId}`);
    if (response.data.success === true) {

      return pricingPlanId;
    }
    return pricingPlanId;
  }
);

const pricingPlansAdapter = createEntityAdapter({
  selectId: (pricingPlan) => pricingPlan.pricingPlanId,
});

export const {
  selectAll: selectPricingPlans,
  selectById: selectPricingPlanById
} = pricingPlansAdapter.getSelectors((state) => state.pricingPlans);

const pricingPlansSlice = createSlice({
  name: "pricingPlans",
  initialState: pricingPlansAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    pricingPlanDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setPricingPlanSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewPricingPlanDialog: (state, action) => {
      state.pricingPlanDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewPricingPlanDialog: (state, action) => {
      state.pricingPlanDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditPricingPlanDialog: (state, action) => {
      state.pricingPlanDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditPricingPlanDialog: (state, action) => {
      state.pricingPlanDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updatePricingPlan.fulfilled]: pricingPlansAdapter.upsertOne,
    [addPricingPlan.fulfilled]: pricingPlansAdapter.addOne,
    [addPaypalPricingPlan.fulfilled]: pricingPlansAdapter.addOne,
    [removePricingPlan.fulfilled]: (state, action) =>
      pricingPlansAdapter.removeOne(state, action.payload),
    [getPricingPlans.fulfilled]: pricingPlansAdapter.setAll,
    [getCustomPricingPlans.fulfilled]: pricingPlansAdapter.setAll,
  },
});

export const {
  setPricingPlanSearchText,
  openNewPricingPlanDialog,
  closeNewPricingPlanDialog,
  openEditPricingPlanDialog,
  closeEditPricingPlanDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = pricingPlansSlice.actions;

export default pricingPlansSlice.reducer;