import {
  DataGrid,
  trTR,
  enUS,
  deDE,
  arSD,
  GridActionsCellItem,
  GridToolbar
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openConfirmDialog } from "store/main/confirmSlice";
import { Box, Card } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { darken, lighten } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { imageProxy } from "features/api/proxy";
import MDAvatar from "components/MDAvatar";
import { selectPricingPlans } from "store/main/pricingPlansSlice";
import { openNewPricingPlanDialog } from "store/main/pricingPlansSlice";
import { updatePricingPlan } from "store/main/pricingPlansSlice";
import { openEditPricingPlanDialog } from "store/main/pricingPlansSlice";
import { getProduct } from "store/iyzico/iyzicoProductsSlice";
import dayjs from "dayjs";


const PricingPlansTable = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const data = useSelector(selectPricingPlans);

  const [pageSize, setPageSize] = useState(15);

  const handleOpenNew = () => {
    dispatch(openNewPricingPlanDialog());
  };

  const deletaData = React.useCallback(
    (id) => () => {
      let data = { ...id.row, table: "pricingPlansTable" };
      dispatch(openConfirmDialog(data));
    },
    [dispatch]
  );
  const editStatus = React.useCallback(
    (id) => () => {
      let data = {
        ...id.row,
        status: id.row.status === "active" ? "passive" : "active",
      };
      dispatch(updatePricingPlan(data));
    },
    [dispatch]
  );
  const editData = React.useCallback(
    (id) => () => {
      if (id.row.payPlatform === "iyzico") {
        dispatch(getProduct(id.row)).then((params) => {
          const { payload } = params;

          console.log(payload);
          let data = {
            ...id.row,
            productName: payload.name,
            productDescription: payload.description,
            paymentIntervalCount: payload.pricingPlans[0].paymentIntervalCount,
            pricingPlanName: payload.pricingPlans[0].name
          }
          dispatch(openEditPricingPlanDialog(data));
        })
      }
      else {
        //ToDo

        dispatch(openEditPricingPlanDialog({ ...id.row }));
      }
    },
    [dispatch]
  );
  const columns = useMemo(() => [
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Düzenle"
          onClick={editData(params)}
        />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label={params.row.status === "active" ? "Pasife al" : "active et"}
          onClick={editStatus(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Sil"
          onClick={deletaData(params)}
          showInMenu
        />,
      ],
    },
    {
      headerName: t("id"),
      field: "pricingPlanId",
      minWidth: 70,
      hideable: false,
    },
    {
      headerName: t("productReference"),
      field: "productReference",
      minWidth: 300,
      hideable: false,
    },
    {
      headerName: t("pricingPlanReference"),
      field: "pricingPlanReference",
      minWidth: 300,
      hideable: false,
    },
    {
      field: "pricingPlanName",
      headerName: t("pricingPlanName"),
      minWidth: 250,
      hideable: false,
      renderCell: (params) => {
        console.log(params.row);
        if (i18n.language.match("tr")) {
          return params.row.pricingPlanNameTr;
        }
        else if (i18n.language.match("en")) {
          return params.row.pricingPlanNameEn;
        }
        else if (i18n.language.match("de")) {
          return params.row.pricingPlanNameDe;
        }
        else if (i18n.language.match("ar")) {
          return params.row.pricingPlanNameAr
        }
        else {
          return null;
        }
      }
    },
    {
      field: "lessonDescription",
      headerName: t("lessonDescription"),
      minWidth: 250,
      hideable: false,
      renderCell: (params) => {
        console.log(params.row);
        if (i18n.language.match("tr")) {
          return params.row.pricingPlanDescriptionTr;
        }
        else if (i18n.language.match("en")) {
          return params.row.pricingPlanDescriptionEn;
        }
        else if (i18n.language.match("de")) {
          return params.row.pricingPlanDescriptionDe;
        }
        else if (i18n.language.match("ar")) {
          return params.row.pricingPlanDescriptionAr
        }
        else {
          return null;
        }
      }
    },

    {
      headerName: "url",
      field: "url",
      minWidth: 140,
      hideable: false,
      renderCell: (params) => {
        return (
          <MDAvatar src={`${imageProxy}/${params.value}`} alt={params.value} />
        );
      },
    },
    {
      headerName: t("price"),
      field: "price",
      minWidth: 100,
      hideable: false,
    },

    {
      headerName: t("countryISO"),
      field: "countryISO",
      minWidth: 50,
      hideable: false,
    },
    {
      headerName: t("currencyISO"),
      field: "currencyISO",
      minWidth: 50,
      hideable: false,
    },
    {
      headerName: t("status"),
      field: "status",
      minWidth: 150,
      hideable: false,
    },
    {
      headerName: t("createdDate"),
      field: "createdDate",
      minWidth: 170,
      hideable: false,
      type: "date",
      renderCell: (params) => {
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },

  ], [deletaData, editStatus, editData, t, i18n.language]);

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox p={3} lineHeight={1}>
        <TableContainer sx={{ boxShadow: "none" }}>
          <MDBox>
            <Grid container alignItems="center">
              <Grid item xs={12} md={7}>
                <MDBox mb={1}>
                  <MDTypography variant="h5">{t("pricingPlans")}</MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2" color="text">
                    {t("pricingPlansList")}
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={handleOpenNew}
                >
                  <Icon>add</Icon>&nbsp;{t("AddNewPricingPlan")}
                </MDButton>
              </Grid>
            </Grid>

            <div style={{ flexGrow: 1, width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      height: "100%",
                      width: 1,
                      "& .super-app-theme--iptal": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.error.main,
                            theme.palette.mode
                          ),
                      },
                      "& .super-app-theme--tamamlandi": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.info.main,
                            theme.palette.mode
                          ),
                      },
                    }}
                  >
                    <DataGrid
                      localeText={
                        (i18n.language.match("tr") && trTR.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("en") && enUS.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("de") && deDE.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("ar") && arSD.components.MuiDataGrid.defaultProps.localeText)
                      }
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      rowsPerPageOptions={[15, 30, 60]}
                      pagination
                      autoHeight
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      getRowClassName={(params) =>
                        `super-app-theme--${params.row.status}`
                      }
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                    />
                  </Box>
                </div>
              </div>
            </div>
          </MDBox>
        </TableContainer>
      </MDBox>
    </Card>
  );
};

PricingPlansTable.propTypes = {};

export default PricingPlansTable;
