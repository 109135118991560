import React from 'react'
import InsLessonVideoPlayer from './InsLessonVideoPlayer'
import { Grid } from '@mui/material';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import VideoControls from './components/other/VideoControls';


function InsLessonPlayerSite() {
  const handle = useFullScreenHandle();
  return (

    <FullScreen handle={handle} className={"mainContainer"}>

      <Grid container className={"GridContainer"}>
        <Grid item xs={12} className={"GridItem"}>
          <InsLessonVideoPlayer fullScreenHandler={handle} />
        </Grid>
      </Grid>
    </FullScreen>

  )
}

export default InsLessonPlayerSite