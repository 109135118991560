/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS exampless

import { useTranslation } from "react-i18next";
import DefaultNavbarDropdown from "./DefaultNavbarDropdown";
import { Card, Icon, Menu, Popper } from "@mui/material";
import NotificationItem from "examples/Items/NotificationItem";
import MDButton from "components/MDButton";

import { GrLanguage } from "react-icons/gr";
import MDTypography from "components/MDTypography";
import LanguageMenu from "./LanguageMenu";

function DefaultNavbarMobile({ routes, open }) {

  const { t } = useTranslation();
  const [collapse, setCollapse] = useState("");

  const handleSetCollapse = (name) => (collapse === name ? setCollapse(false) : setCollapse(name));

  const renderNavbarItems = routes.map(
    ({ name, icon, collapse: routeCollapses, href, route, collapse: navCollapse }) => (
      <DefaultNavbarDropdown
        key={name}
        name={t(name)}
        icon={icon}
        collapseStatus={name === collapse}
        onClick={() => handleSetCollapse(name)}
        href={href}
        open={open}
        route={route}
        collapse={Boolean(navCollapse)}
        sx={({
          palette: { transparent: transparentColor, navbar, background },
          functions: { rgba },
        }) => ({
          backgroundColor: rgba(navbar.main, 0.8),
          backdropFilter: `saturate(200%) blur(30px)`,
        })}
      >

      </DefaultNavbarDropdown>
    )
  );
  const [openMenu, setOpenMenu] = useState(false);

  const { i18n } = useTranslation();

  const handleCloseMenu = () => {
    setOpenMenu(false)
    setAnchorEl(null)
  };
  const handleOpenMenu = (event) => {
    setOpenMenu(true);
    setAnchorEl(buttonRef.current);
  };

  const changeLanguageHandler = (e, lang) => {
    e.preventDefault();
    i18n.changeLanguage(lang);
    handleCloseMenu();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);


  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <MDBox width="calc(100% + 1.625rem)" ml={-2}>
        {renderNavbarItems}
      </MDBox>
      <MDBox width="calc(100% + 1.625rem)" mb={2} ml={0}>
        <MDButton
          ref={buttonRef}
          color={"white"}
          fontSize={"16px"}
          variant={"text"}
          size="small"
          fontWeight="regular"
          textTransform="capitalize"

          sx={{ fontWeight: "100%", ml: 0, height: "100%", justifyContent: "center", alignItems: "center", }}
          onClick={openMenu ? handleCloseMenu : handleOpenMenu}>
          {/* <GrLanguage style={{ marginRight: 1, width: 24, height: 24 }} /> */}
          <MDTypography
            variant="body2"
            lineHeight={1}
            color="inherit"
            sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" }, marginRight: 1 }}
          >
            <GrLanguage style={{ width: 24, height: 24 }} />
          </MDTypography>

          {i18n.language}
          <LanguageMenu open={openMenu} handleCloseMenu={handleCloseMenu} anchorEl={anchorEl} placement={"bottom-start"} />
        </MDButton>
      </MDBox>
    </Collapse>
  );
}

// Typechecking props for the DefaultNavbarMobile
DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
