import React, { useEffect, useState } from "react";
import AdminLayout from "pages/main/layout/AdminLayout";
import InsLessonsTable from "./InsLessonsTable";
import { useDispatch } from "react-redux";
import LoadingComponent from "components/Loading";
import InsLessonDialog from "./InsLessonDialog";
import { getinstructorLessons } from "store/main/instructorLessonsSlice";
import { getinstructorCourses } from "store/main/instructorCoursesSlice";
import ConfirmDialog from "pages/confirmation/ConfirmDialog";
import { removeinstructorLesson } from "store/main/instructorLessonsSlice";

const AdminInsLessonsDashboard = (props) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  const [coursesLoading, setCoursesLoading] = useState(true);

  useEffect(() => {
    dispatch(getinstructorLessons()).then(() => setDataLoading(false));
    dispatch(getinstructorCourses()).then(() => setCoursesLoading(false))
  }, [dispatch]);

  if (dataLoading || coursesLoading ) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <InsLessonsTable />
      <InsLessonDialog />
      <ConfirmDialog handleDeleteData={removeinstructorLesson} />
    </AdminLayout>
  );
};


export default AdminInsLessonsDashboard;
