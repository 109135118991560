import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import { useTranslation } from "react-i18next";

const features = [
  { icon: "favorite_border", header: "feature1_header", alt: "feature1_alt" },
  {
    icon: "music_video_rounded",
    header: "feature2_header",
    alt: "feature2_alt",
  },
  { icon: "speed_rounded", header: "feature3_header", alt: "feature3_alt" },
  { icon: "repeat_rounded", header: "feature4_header", alt: "feature4_alt" },
  {
    icon: "music_note_rounded",
    header: "feature5_header",
    alt: "feature5_alt",
  },
  {
    icon: "queue_music_note_rounded",
    header: "feature6_header",
    alt: "feature6_alt",
  },
];

function Features() {
  const { t } = useTranslation();
  return (
    <Grid container mt={5} spacing={4} justifyContent={"center"}>
      {features.map((item, key) => {
        return (
          <Grid key={key} item xs={12} xxl={4} md={6}>
            <Card
              sx={{
                backdropFilter: `saturate(200%) blur(30px)`,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                background: "#252F32",
              }}>
              <MDBox
                display={"flex"}
                px={4}
                pt={4}
                pb={2}
                alignItems={"center"}>
                <MDBox pr={2} display={"flex"} sx={{ color: "white" }} alignItems={"center"}>
                  <Icon sx={{ color: "#ffffff" }} fontSize="large">{item.icon}</Icon>
                </MDBox>

                <MDTypography
                  color={"white"}
                  fontSize={{ xs: "1rem", lg: "1.20rem", xl: "1.44rem" }}
                  textAlign={"left"}
                  variant="h4"
                  fontWeight="medium">
                  {t(item.header)}
                </MDTypography>
              </MDBox>

              <MDTypography
                color={"white"}
                px={4}
                pb={4}
                fontSize={{ xs: "1rem", lg: "1.1rem", xl: "1.2rem" }}
                textAlign={"left"}
                variant="body2">
                {t(item.alt)}
              </MDTypography>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default Features;
