import { Card } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { imageProxy } from 'features/api/proxy';
import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { selectinstructorCoursesById } from 'store/main/instructorCoursesSlice';


//ToDo courseDescription change 
function CourseHeader() {
  const { courseId } = useParams();

  const course = useSelector((state) => selectinstructorCoursesById(state, courseId));

  console.log(course);
  return (
    <Card sx={{ padding: 3, marginBottom: 2, display: "flex", flexDirection: "row" }}>
      {/* {JSON.stringify(course)} */}
      <MDBox component="img" src={`${imageProxy}/${course.courseinstructorInfo.url}`} sx={{ width: "100px", marginRight: 3 }} borderRadius={"md"} />
      
      <MDBox>
        <MDTypography variant="h4">{course.courseName}</MDTypography>
        <MDTypography variant="body">{course.courseDescriptionTr}</MDTypography>
      </MDBox>
    </Card>
  )
}

export default CourseHeader