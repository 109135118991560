import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async () => {
    const response = await axiosConfig.get(`api/users/get-users`);
    // const response = await axios.get(`${proxy}/api/user-favorites`)
    let { data } = await response.data;
    console.log(data);
    return data;
  }
);

export const addUser = createAsyncThunk(
  "users/addUsers",
  async (user, { dispatch, getState }) => {
    try {
      const formData = new FormData();
      formData.append("name", user.name);
      formData.append("userName", user.userName);
      formData.append("userType", user.userType);
      formData.append("url", user.url);
      formData.append("password", user.password);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/users`,
        formData,
        config
      );
      let { data } = await response.data;
      if (response.data.success === true) {
        console.log(data);
        return data;
      }
    } catch (error) {
      
      return null;
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUsers",
  async (user, { dispatch, getState }) => {

    let formData = new FormData();
  
      formData.append("userName", user.userName);
      formData.append("url", user.url);
      formData.append("userType", user.userType);
      formData.append("status", user.status);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };
  
      const response = await axiosConfig.put(
        `/api/users/${user.userName}`,
        formData,
        config
      );
    const { data } = await response.data;
    if (response.data.success === true) {
      return data;
    }
    return null;
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUsers",
  async (favoriteId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/users/${favoriteId}`);
    if (response.data.success === true) {
  
      return favoriteId;
    }
    return favoriteId;
  }
);

const usersAdapter = createEntityAdapter({
  selectId: (user) => user.userName,
});

export const {
  selectAll: selectusers,
  selectById: selectuserById
} = usersAdapter.getSelectors((state) => state.users);

const usersSlice = createSlice({
  name: "users",
  initialState: usersAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    userDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setUserSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewUserDialog: (state, action) => {
      state.userDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewUserDialog: (state, action) => {
      state.userDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditUserDialog: (state, action) => {
      state.userDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditUserDialog: (state, action) => {
      state.userDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateUser.fulfilled]: usersAdapter.upsertOne,
    [addUser.fulfilled]: usersAdapter.addOne,
    [deleteUser.fulfilled]: (state, action) =>
      usersAdapter.removeOne(state, action.payload),
    [getUsers.fulfilled]: usersAdapter.setAll,
  },
});

export const {
  setUserSearchText,
  openNewUserDialog,
  closeNewUserDialog,
  openEditUserDialog,
  closeEditUserDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = usersSlice.actions;

export default usersSlice.reducer;

