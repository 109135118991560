import PageLayout from 'pages/main/layout/PageLayout'
import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux';
import { getPricingPlans } from 'store/main/pricingPlansSlice';
import LoadingComponent from 'components/Loading';
import Wizard from './Wizard';

function UpgradePlanDashboard() {
  const dispatch = useDispatch();


  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getPricingPlans()).then(() => setLoading(false));
  }, [dispatch]);

  if (loading) {
    return <PageLayout>
      <LoadingComponent />
    </PageLayout>
  }

  return (
    <PageLayout>
      <Wizard />
    </PageLayout>
  )
}

export default UpgradePlanDashboard