import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setVideoData } from 'store/main/videoPlayerSlice';
import VideoSlider from '../sliders/VideoSlider';

function Progress() {
  const dispatch = useDispatch();
  const { loopData, videoData } = useSelector((state) => state.videoPlayer);

  const handleProgressClick = (e) => {
    const progressWidth = e.target.getBoundingClientRect().width;
    const clickPosition = e.nativeEvent.offsetX;
    const timePercentage = clickPosition / progressWidth;
    const currentTime = timePercentage * videoData.duration;
    dispatch(setVideoData({ ...videoData, currentTime, progressClick: true }));
  };


  if (loopData.isLoop) {
    return <VideoSlider />
  }

  return <progress onClick={handleProgressClick} value={videoData.currentTime} max={videoData.duration} />
}

export default Progress