import { useTranslation } from "react-i18next";

const useTutorialSteps = () => {
  const { t } = useTranslation();

  const locale = {
    skip: <span style={{ fontFamily: "Outfit, sans-serif" }}>{t("skip")}</span>,
    next: <span style={{ fontFamily: "Outfit, sans-serif" }}>{t("next")}</span>,
    back: <span style={{ fontFamily: "Outfit, sans-serif" }}>{t("back")}</span>,
    last: <span style={{ fontFamily: "Outfit, sans-serif" }}>{t("finish")}</span>,
  }

  const allSteps = [
    {
      title: <h3>{t("tutorialHeader")}</h3>,
      content: t("tutorialContent"),
      locale,
      placement: 'center',
      target: '.GridContainer',
    },
    {
      title: <h3>{t("backButtonTitle")}</h3>,
      content: t("backButtonContent"),
      locale,
      target: '.Step_Back',
    },
    {
      title: <h3>{t("videoNameTitle")}</h3>,
      content: t("videoNameContent"),
      locale,
      target: '.SongTitle',
      spotlightPadding: 20,
    },
    {
      title: <h3>{t("tutorialButtonTitle")}</h3>,
      content: t("tutorialButtonContent"),
      locale,
      target: '.TutorialButton',
      spotlightPadding: 20,
    },
    {
      title: <h3>{t("controlsTitle")}</h3>,
      content: t("controlsContent"),
      locale,
      target: '.ControlsContainerV2',
    },
    {
      title: <h3>{t("returnStartButtonTitle")}</h3>,
      content: t("returnStartButtonContent"),
      locale,
      spotlightClicks: true,
      spotlightPadding: 5,
      target: '.ReturnStart',
    },
    {
      title: <h3>{t("rewinButtonTitle")}</h3>,
      content: t("rewinButtonContent"),
      locale,
      spotlightClicks: true,
      spotlightPadding: 5,
      target: '.RewindButton',
    },
    {
      title: <h3>{t("playPauseButtonTile")}</h3>,
      content: t("playPauseButtonContent"),
      locale,
      spotlightClicks: true,
      spotlightPadding: 5,
      target: '.PlayPauseButton',
    },
    {
      title: <h3>{t("forwardButtonTitle")}</h3>,
      content: t("forwardButtonContent"),
      locale,
      spotlightClicks: true,
      spotlightPadding: 5,
      target: '.ForwardButton',
    },
    {
      title: <h3>{t("muteButtonTitle")}</h3>,
      content: t("muteButtonContent"),
      locale,
      spotlightClicks: true,
      spotlightPadding: 5,
      target: '.MuteButton',
    },
    {
      title: <h3>{t("volumeSliderTitle")}</h3>,
      content: t("volumeSliderTitle"),
      locale,
      spotlightClicks: true,
      spotlightPadding: 10,
      target: '.VolumeSlider',
    },
    {
      title: <h3>{t("colorSwitchTitle")}</h3>,
      content: t("colorSwitchContent"),
      locale,
      target: '.ColorSwitch',
      spotlightClicks: true,
      spotlightPadding: 5,
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'bottom',
    },
    {
      title: <h3>{t("coloredNoteTitle")}</h3>,
      content: t("coloredNoteContent"),
      locale,
      target: '.ColoredNote',
      spotlightPadding: 5,
      placement: 'top-start',
    },
    {
      title: <h3>{t("coloredVideoTitle")}</h3>,
      content: t("coloredVideoContent"),
      locale: {
        skip: <span style={{ fontFamily: "Outfit, sans-serif" }}>Atla</span>,
        next: <span style={{ fontFamily: "Outfit, sans-serif" }}>İleri</span>,
        back: <span style={{ fontFamily: "Outfit, sans-serif" }}>Geri</span>,
        last: <span style={{ fontFamily: "Outfit, sans-serif" }}>Son</span>,
      },
      target: '.Video',
      spotlightPadding: 5,
      placement: 'bottom',
    },
    {
      title: <h3>{t("colorSwitchEndTitle")}</h3>,
      content: t("colorSwitchEndContent"),
      locale,
      target: '.ColorSwitch',
      spotlightClicks: true,
      spotlightPadding: 5,
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'bottom',
    },
    {
      title: <h3>{t("loopButtonTitle")}</h3>,
      content: t("loopButtonContent"),
      locale,
      target: '.LoopButton',
      spotlightClicks: true,
      spotlightPadding: 5,
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'bottom',
    },
    {
      title: <h3>{t("loopRangeTitle")}</h3>,
      content: t("loopRangeContent"),
      locale,
      target: '.BlueArea',
      spotlightClicks: true,
      spotlightPadding: 5,
      placement: 'bottom',
    },
    {
      title: <h3>{t("sliderStartTitle")}</h3>,
      content: t("sliderStartContent"),
      locale,
      target: '.Slider :nth-child(3)',
      spotlightClicks: true,
      spotlightPadding: 5,
      placement: 'auto',
    },
    {
      title: <h3>{t("sliderEndTitle")}</h3>,
      content: t("sliderEndContent"),
      locale,
      target: '.Slider :nth-child(4)',
      spotlightClicks: true,
      spotlightPadding: 5,
      placement: 'auto',
    },
    {
      title: <h3>{t("loopButtonEndTitle")}</h3>,
      content: t("loopButtonEndContent"),
      locale,
      target: '.LoopButton',
      spotlightClicks: true,
      spotlightPadding: 5,
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'bottom',
    },
    {
      title: <h3>{t("settingsButtonTitle")}</h3>,
      content: t("settingsButtonContent"),
      locale,
      target: '.SettingsButton',
      spotlightClicks: true,
      spotlightPadding: 5,
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'bottom',
    },
    {
      title: <h3>{t("qualityTitle")}</h3>,
      content: t("qualityContent"),
      locale,
      target: '.Quality',
      spotlightClicks: true,
      spotlightPadding: 5,
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'left',
    },
    {
      title: <h3>{t("qualityMenuTitle")}</h3>,
      content: t("qualityMenuContent"),
      locale,
      target: '.QualityMenu',
      spotlightClicks: true,
      spotlightPadding: 5,
      placement: 'left',
    },
    {
      title: <h3>{t("qualityBackTitle")}</h3>,
      content: t("qualityBackContent"),
      locale,
      target: '.QualityBack',
      spotlightClicks: true,
      spotlightPadding: 5,
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'left',
    },
    {
      title: <h3>{t("speedTitle")}</h3>,
      content: t("speedContent"),
      locale,
      target: '.Speed',
      spotlightClicks: true,
      spotlightPadding: 5,
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'left',
    },
    {
      title: <h3>{t("speedMenuTitle")}</h3>,
      content: t("speedMenuContent"),
      locale,
      target: '.SpeedMenu',
      spotlightClicks: true,
      spotlightPadding: 5,
      placement: 'left',
    },
    {
      title: <h3>{t("speedBackTitle")}</h3>,
      content: t("speedBackContent"),
      locale,
      target: '.SpeedBack',
      spotlightClicks: true,
      spotlightPadding: 5,
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'left',
    },
    {
      title: <h3>{t("settingsMenuCloseTitle")}</h3>,
      content: t("settingsMenuCloseContent"),
      locale,
      target: '.SettingsButton',
      spotlightClicks: true,
      spotlightPadding: 5,
      placement: 'left',
    },
    {
      title: <h3>{t("fullScreenButtonTitle")}</h3>,
      content: t("fullScreenButtonContent"),
      locale,
      target: '.FullScreenButton',
      spotlightClicks: true,
      spotlightPadding: 5,
      placement: 'left',
    },
    {
      title: <h3>{t("tutorialEndTitle")}</h3>,
      content: t("tutorialEndContent"),
      locale,
      placement: 'center',
      target: '.GridContainer',
    },
  ]

  return { allSteps }
}

export default useTutorialSteps;
