import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";

export const getSignedUrl = createAsyncThunk(
  "aws/getSignedUrl",
  async (url) => {
    try {
      console.log(url);
      const response = await axiosConfig.post(`/api/aws/get-signed-url`, { url });
      const data = response.data;
      console.log(data);
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

const awsSlice = createSlice({
  name: "aws",
  initialState: {
    signedUrl: "",
  },
  reducers: {},
  extraReducers: {
    [getSignedUrl.fulfilled]: (state, action) => action.payload,
  },
});

export default awsSlice.reducer;