import React, { useEffect, useState } from "react";
import AdminLayout from "pages/main/layout/AdminLayout";
import { useDispatch } from "react-redux";
import LoadingComponent from "components/Loading";
import VideoAnalyzeTable from "./VideoAnalyzeTable";
import { getVideoAnalyze } from "store/analytics/videoAnalyzeSlice";


const VideoAnalyzeDashboard = (props) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    dispatch(getVideoAnalyze()).then(() => setDataLoading(false));
  }, [dispatch]);

  if (dataLoading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <VideoAnalyzeTable />
    </AdminLayout>
  );
};


export default VideoAnalyzeDashboard;
