import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";

export const getCouponCodes = createAsyncThunk(
  "couponCodes/getCouponCodes",
  async (_, { getState }) => {
    const response = await axiosConfig.get(`/api/coupon-codes`);
    // const response = await axios.get(`${proxy}/api/coupon-codes`)

    let { data } = await response.data;
    return data;
  }
);

export const addCouponCode = createAsyncThunk(
  "couponCodes/addCouponCode",
  async (couponCode, { dispatch, getState }) => {
    try {
      let formData = new FormData();

      formData.append("referenceCode", couponCode.referenceCode);
      formData.append("pricingPlans", JSON.stringify(couponCode.pricingPlans));
      formData.append("discountPercentage", couponCode.discountPercentage);
      formData.append("creditPercentageToInstructor", couponCode.creditPercentageToInstructor);
      formData.append("creditPercentageToStudent", couponCode.creditPercentageToStudent);
      formData.append("createdDate", couponCode.createdDate);
      formData.append("expirationDate", couponCode.expirationDate);
      formData.append("instructorId", couponCode.instructorId);
      formData.append("status", couponCode.status);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/coupon-codes`,
        formData,
        config
      );
      let { data } = await response.data;
      if (response.data.success === true) {

        return data;
      }
    } catch (error) {

      return null;
    }
  }
);

export const updateCouponCode = createAsyncThunk(
  "couponCodes/updateCouponCode",
  async (couponCode, { dispatch, getState }) => {
    let formData = new FormData();

    formData.append("couponCodeId", couponCode.couponCodeId);
    formData.append("pricingPlans", JSON.stringify(couponCode.pricingPlans));
    formData.append("discountPercentage", couponCode.discountPercentage);
    formData.append("referenceCode", couponCode.referenceCode);
    formData.append("creditPercentageToInstructor", couponCode.creditPercentageToInstructor);
    formData.append("creditPercentageToStudent", couponCode.creditPercentageToStudent);
    formData.append("createdDate", couponCode.createdDate);
    formData.append("expirationDate", couponCode.expirationDate);
    formData.append("instructorId", couponCode.instructorId);
    formData.append("status", couponCode.status);

    console.log("fd", formData, couponCode);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/coupon-codes/${couponCode.couponCodeId}`,
      formData,
      config
    );
    const { data } = await response.data;
    if (response.data.success === true) {

      return data;
    }
    return null;
  }
);

export const removeCouponCode = createAsyncThunk(
  "couponCodes/removeCouponCode",
  async (couponCodeId, { dispatch, getState }) => {
    console.log("buraya girdik", couponCodeId);
    let response = await axiosConfig.delete(`/api/coupon-codes/${couponCodeId}`);
    if (response.data.success === true) {
      return couponCodeId;
    }
    return couponCodeId;
  }
);

export const checkCouponCode = createAsyncThunk(
  "couponCodes/checkCouponCode",
  async (referenceCode, { dispatch, getState }) => {
    let response = await axiosConfig.get(`/api/coupon-codes/query?referenceCode=${referenceCode}`)
    console.log(response);
    const { data } = response.data;
    if (data.length === 0) {
      return null
    }
    return data[0];
  }
)

const couponCodesAdapter = createEntityAdapter({
  selectId: (couponCode) => couponCode.couponCodeId,
});

export const {
  selectAll: selectCouponCodes,
  selectById: selectCouponCodeById,
} = couponCodesAdapter.getSelectors((state) => state.couponCodes);

const couponCodesSlice = createSlice({
  name: "couponCodes",
  initialState: couponCodesAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    couponCodeDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewCouponCodeDialog: (state, action) => {
      state.couponCodeDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewCouponCodeDialog: (state, action) => {
      state.couponCodeDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditCouponCodeDialog: (state, action) => {
      state.couponCodeDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditCouponCodeDialog: (state, action) => {
      state.couponCodeDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.couponCodeDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.couponCodeDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateCouponCode.fulfilled]: couponCodesAdapter.upsertOne,
    [addCouponCode.fulfilled]: couponCodesAdapter.addOne,
    [removeCouponCode.fulfilled]: (state, action) =>
      couponCodesAdapter.removeOne(state, action.payload),
    [getCouponCodes.fulfilled]: couponCodesAdapter.setAll,
  },
});

export const {
  openNewCouponCodeDialog,
  closeNewCouponCodeDialog,
  openEditCouponCodeDialog,
  closeEditCouponCodeDialog,
  openConfirmDialog,
  closeConfirmDialog,
  openResetPasswordDialog,
  closeResetPasswordDialog,
} = couponCodesSlice.actions;

export default couponCodesSlice.reducer;
