import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (_, { getState }) => {
    const response = await axiosConfig.get(`/api/categories`);
    // const response = await axios.get(`${proxy}/api/categories`)

    let { data } = await response.data;
    return data;
  }
);

export const addCategory = createAsyncThunk(
  "categories/addCategory",
  async (category, { dispatch, getState }) => {
    try {
      let formData = new FormData();
      formData.append("url", category.url);
      formData.append("categoryNameTr", category.categoryNameTr);
      formData.append("categoryDescriptionTr", category.categoryDescriptionTr);
      formData.append("categoryNameEn", category.categoryNameEn);
      formData.append("categoryDescriptionEn", category.categoryDescriptionEn);
      formData.append("categoryNameDe", category.categoryNameDe);
      formData.append("categoryDescriptionDe", category.categoryDescriptionDe);
      formData.append("categoryNameAr", category.categoryNameAr);
      formData.append("categoryDescriptionAr", category.categoryDescriptionAr);
      formData.append("type", category.type);
      formData.append("status", category.status);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };

      const response = await axiosConfig.post(
        `/api/categories`,
        formData,
        config
      );
      let { data } = await response.data;
      console.log("data", data);
      console.log("data.success", response.data.success);
      if (response.data.success === true) {
        console.log(
          "data.success e geldin e sorun ne ? ",
          response.data.success
        );
        return data;
      }
    } catch (error) {
      return null;
    }
  }
);

export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async (category, { dispatch, getState }) => {
    let formData = new FormData();

    formData.append("categoryId", category.categoryId);
    formData.append("url", category.url);
    formData.append("categoryNameTr", category.categoryNameTr);
    formData.append("categoryDescriptionTr", category.categoryDescriptionTr);
    formData.append("categoryNameEn", category.categoryNameEn);
    formData.append("categoryDescriptionEn", category.categoryDescriptionEn);
    formData.append("categoryNameDe", category.categoryNameDe);
    formData.append("categoryDescriptionDe", category.categoryDescriptionDe);
    formData.append("categoryNameAr", category.categoryNameAr);
    formData.append("categoryDescriptionAr", category.categoryDescriptionAr);
    formData.append("type", category.type);
    formData.append("status", category.status);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: formData._boundaries,
      },
    };

    const response = await axiosConfig.put(
      `/api/categories/${category.categoryId}`,
      formData,
      config
    );
    const { data } = await response.data;
    if (response.data.success === true) {
      return data;
    }
    return null;
  }
);

export const removeCategory = createAsyncThunk(
  "categories/removeCategory",
  async (categoryId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/categories/${categoryId}`);
    if (response.data.success === true) {
      return categoryId;
    }
    return categoryId;
  }
);

const categoriesAdapter = createEntityAdapter({
  selectId: (category) => category.categoryId,
});

export const { selectAll: selectcategories, selectById: selectcategoriesById } =
  categoriesAdapter.getSelectors((state) => state.categories);

const categoriesSlice = createSlice({
  name: "categories",
  initialState: categoriesAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    categoryDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setCategorySearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewCategoryDialog: (state, action) => {
      state.categoryDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewCategoryDialog: (state, action) => {
      state.categoryDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditCategoryDialog: (state, action) => {
      state.categoryDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditCategoryDialog: (state, action) => {
      state.categoryDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateCategory.fulfilled]: categoriesAdapter.upsertOne,
    [addCategory.fulfilled]: categoriesAdapter.addOne,
    [removeCategory.fulfilled]: (state, action) =>
      categoriesAdapter.removeOne(state, action.payload),
    [getCategories.fulfilled]: categoriesAdapter.setAll,
  },
});

export const {
  setCategorySearchText,
  openNewCategoryDialog,
  closeNewCategoryDialog,
  openEditCategoryDialog,
  closeEditCategoryDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
