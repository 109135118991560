import { Icon, Tooltip } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

function LoopButton({ handleClick }) {
  const { loopData } = useSelector((state) => state.videoPlayer);

  const { t } = useTranslation();

  return (
    <Tooltip title={loopData.isLoop ? t("ExitLoop") : t("EnterLoop")} placement='bottom'>
      <button
        onClick={handleClick}
        className={"LoopButton"}
        style={{ backgroundColor: loopData.isLoop ? "rgba(255, 255, 255, 0.3)" : "rgba(255, 255, 255, 0)" }}
      >
        <Icon>loop</Icon>
      </button>
    </Tooltip>
  )
}

export default LoopButton