import { Grid } from '@mui/material';
import PricingCard from 'pages/students/payment/newSubscription/PricingPlansInfo/PricingCard';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectPricingPlans } from 'store/main/pricingPlansSlice';

function RenderPricingPlans() {
  const { country_code } = useSelector((state) => state.location);
  const { isStudent } = useSelector((state) => state.auth);
  const pricingPlans = useSelector(selectPricingPlans);

  const [filteredPlans, setFilteredPlans] = useState([]);

  const [priceType, setPriceType] = useState("monthlyInterval");

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const plans = pricingPlans.filter((f) => {
      if (country_code === "TR") {
        return (
          (f.countryISO === "TR" && f.forStudent === isStudent && f.payPlatform === "iyzico")
        )
      }
      return (f.countryISO === "DE" && f.forStudent === isStudent && f.payPlatform === "paypal")
    });

    setFilteredPlans(plans);
  }, [pricingPlans, country_code, isStudent]);

  return (
    <Grid container>{
      filteredPlans.map((item, key) => {
        return (
          <Grid key={key} item xs={12} xl={4}>
            <PricingCard
              color={(key % 2 === 1 || filteredPlans.length === 1) ? "dark" : "white"}
              badge={{
                color: "warning",
                label: (
                  (i18n.language.match("tr") && item.pricingPlanNameTr) ||
                  (i18n.language.match("en") && item.pricingPlanNameEn) ||
                  (i18n.language.match("de") && item.pricingPlanNameDe) ||
                  (i18n.language.match("ar") && item.pricingPlanNameAr)
                )
              }}
              price={{
                currency: (
                  (item.currencyISO === "EUR" && "€") ||
                  (item.currencyISO === "TRY" && "₺") ||
                  (item.paymentInterval === "TRIAL" && country_code === "US" && "€") ||
                  (item.paymentInterval === "TRIAL" && country_code === "TR" && "₺")
                ),
                value: item.price,
                type: t(priceType),
                count: item.paymentIntervalCount,
              }}
              specifications={[
                { label: t("feature1"), includes: true },
                { label: t("feature2"), includes: true },
                { label: t("feature3"), includes: true },
                { label: t("feature4"), includes: true },
                { label: t("feature5"), includes: true },
                { label: t("feature6"), includes: true },
                { label: t("feature7"), includes: true },
                { label: t("feature8"), includes: true },
                { label: t("feature9"), includes: true },
              ]}
              // handleNext={() => handleNext(1)}
              pricingPlanReferenceCode={item.pricingPlanReference}
              pricingPlanId={item.pricingPlanId}
              button={{
                color: (key % 2 === 1 || filteredPlans.length === 1) ? "info" : "dark",
                label: t("choosePlan"),
                action: "button",
              }}
              shadow={false}
              isTrial={false}
            />
          </Grid>
        )
      })
    }</Grid>
  )
}

export default RenderPricingPlans