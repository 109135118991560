import React from 'react'
import bgImage from "assets/images/laSolist_player_01.jpeg";
import CoverLayout from "pages/main/layout/CoverLayout";
import { Card, Container, Link } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';



import { useTranslation } from "react-i18next";
import MainFooter from 'pages/main/footer/MainFooter';

function Privacy() {

  const { t } = useTranslation();
  return (
    <CoverLayout image={bgImage} coverHeight={"calc(100vh - 2rem)"}>
      <Container sx={{ transform: "translate(0, 40%)"}}>
        <Card
          sx={{
            background: "rgba(255,255,255, .90)",
            padding: "30px",
          }}>
          <MDBox marginX={2}>
            <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("privacy_header")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("privacy_latest_update_date")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("privacy_alt_p1")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("privacy_alt_p2")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("collected_data_reason")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("collected_data_alt")}
            </MDTypography>


            <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("privacy_purpose")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("privacy_purpose_alt")}
            </MDTypography>

            <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("transferred_collected_data")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("transferred_collected_data_alt")}
            </MDTypography>

            <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("your_rights")}
            </MDTypography>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
              {t("your_rights_alt_p1")}
            </MDTypography>

            <ol style={{ paddingLeft: "40px" }}>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("your_rights_a")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("your_rights_b")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("your_rights_c")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("your_rights_d")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("your_rights_e")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("your_rights_f")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("your_rights_g")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("your_rights_h")}
                </MDTypography>
              </li>
              <li>
                <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={1}>
                  {t("your_rights_i")}
                </MDTypography>
              </li>
            </ol>
            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("your_rights_alt_p2")}
            </MDTypography>

            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("your_rights_alt_p2")}
            </MDTypography>

            <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
              {t("contact")}
            </MDTypography>

            <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
              {t("contact_alt_p1")}
              <MDTypography component={"a"} fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} fontWeight={"bold"} href={`mailto:${t("contact_email")}`}>
                {t("contact_email")}
              </MDTypography>
              {t("contact_alt_p2")}
            </MDTypography>
          </MDBox>
        </Card>
      </Container>
    </CoverLayout>
  )
}

export default Privacy