import React from 'react'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { TextField, Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import dayjs from 'dayjs';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  borderRadius: "10px",
  '&:before': {
    display: 'none',
  },
  marginBottom: "10px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon color="info" sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .005)'
      : 'rgba(0, 0, 0, .01)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: "10px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



function OrderItemCollapse({ expanded, setExpanded, item }) {
  const { t } = useTranslation();

  const renderStatusBox = () => {
    if (item.orderStatus === "SUCCESS") {
      return <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor="success"
        color="white"
        variant="gradient"
        borderRadius="lg"
        shadow="lg"
        px={2}
      >
        <Typography fontSize={"12px"} fontWeight={"bold"}>
          {t("success")}
        </Typography>
      </MDBox>
    }
    else if (item.orderStatus === "WAITING") {
      return <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor="warning"
        color="white"
        variant="gradient"
        borderRadius="lg"
        shadow="lg"
        px={2}
      >
        <Typography fontSize={"12px"} fontWeight={"bold"}>
          {t("waiting")}
        </Typography>
      </MDBox>
    }
  }

  return (
    <Accordion
      expanded={expanded === item.referenceCode ? true : false}
      onChange={() => setExpanded(expanded !== item.referenceCode ? item.referenceCode : null)}>
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <MDBox display={"flex"} gap={1}>
          <Typography variant="h6">
            {dayjs(item.startPeriod).format("DD.MM.YYYY")} - {dayjs(item.endPeriod).format("DD.MM.YYYY")}
          </Typography>
          {renderStatusBox()}

          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor="dark"
            color="white"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            px={2}
          >
            <Typography fontSize={"12px"} fontWeight={"bold"}>
              {item.price} {item.currencyCode}
            </Typography>
          </MDBox>
        </MDBox>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          autoFocus
          margin="dense"
          id="paymentReferenceCode"
          name="paymentReferenceCode"
          label={t("paymentReferenceCode")}
          type="text"
          fullWidth
          variant="outlined"
          value={item.referenceCode}
          disabled
        />

        {
          // item.paymentAttempts.length > 0 && 
          // item.paymentAttempts.map((item, key) => {
          //   return <MDBox mt={2}>
          //     <PaymentAttemptsCollapse />
          //   </MDBox>
          // })
        }
      </AccordionDetails>
    </Accordion>
  )
}

export default OrderItemCollapse