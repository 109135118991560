import StudentLayout from 'pages/main/layout/StudentLayout'
import React, { useEffect } from 'react'
import io from "socket.io-client";
import ChatRoom from './ChatRoom';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import LatestSupports from './LatestSupports';
import { useParams } from 'react-router-dom';
import NewConversation from './NewConversation';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getLiveSupportsByQuery } from 'store/main/liveSupportsSlice';
import LoadingComponent from 'components/Loading';
import { selectLiveSupportsById } from 'store/main/liveSupportsSlice';
import { getLiveSupports } from 'store/main/liveSupportsSlice';
import { AdminSocketProvider } from 'hooks/AdminSocketContext';

function AdminSupportDashboard() {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const liveSupports = useSelector((state) => selectLiveSupportsById(state, id));


  useEffect(() => {
    dispatch(getLiveSupports()).then(() => setTimeout(() => {
      setLoading(false)
    }, 500));
  }, []);

  if (loading) {
    return <StudentLayout><LoadingComponent /></StudentLayout>
  }

  return (
    <AdminSocketProvider>
      <StudentLayout>
        <Grid container spacing={4} sx={{ height: "100%", display: "flex", flexGrow: 1, flexShrink: 1, }}>
          <Grid item xs={4}>
            <LatestSupports />
          </Grid>
          <Grid item xs={8}>
            {
              liveSupports ? <ChatRoom /> : <NewConversation />
            }
          </Grid>
        </Grid>
      </StudentLayout>
    </AdminSocketProvider>
  )
}

export default AdminSupportDashboard