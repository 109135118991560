import { Icon } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography';
import dayjs from 'dayjs';
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { selectLiveSupportsById } from 'store/main/liveSupportsSlice';

function Messages() {
  const { id } = useParams();
  const messages = useSelector((state) => selectLiveSupportsById(state, id)).messagesInfo;
  return (
    <MDBox border={"3px solid #f2f2f2"} borderRadius="xl" sx={{ display: "flex", flexGrow: 1, flexShrink: 1, justifyContent: "flex-end", flexDirection: "column", padding: 1 }}>
      {messages && messages.length > 0 && [...messages].sort((a, b) => a.id - b.id).map((message, index) => (
        <MDBox key={index} sx={{ display: "flex", justifyContent: message.sentFrom === "admin" ? "flex-start" : "flex-end", gap: 1, marginRight: 1 }}>
          <MDBox>

            <MDBox py={1} px={2} borderRadius="xl" bgColor={message.sentFrom === "admin" ? "secondary" : "info"} color={"white"} sx={{ maxWidth: "250px", width: "250px" }}>
              <MDTypography color={"white"} variant="h5">
                {message.message}
              </MDTypography>

            </MDBox>
            <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant={"subtitle2"} sx={{ alignItems: "center", display: "flex", gap: .5 }}>
                <Icon>access_time</Icon> {dayjs(message.createdDate).format("HH:mm")}
              </MDTypography>

              <MDTypography variant={"subtitle2"} sx={{ alignItems: "center", display: "flex", gap: .5 }}>
                <Icon>done_all</Icon>
              </MDTypography>
            </MDBox>
          </MDBox>
          {/* <MDBox sx={{ width: "46px", height: "46px", borderRadius: "24px", backgroundColor: "red" }}></MDBox> */}
        </MDBox>
      ))}
    </MDBox>
  )
}

export default Messages