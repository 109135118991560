const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  isUploading: false,
  isUploaded: true,
  data: [
    {
      folderNumber: 10, fileName: 10 + ".mp4", type: "video", status: "uploading", isFinished: false, progress: 50, remainingTime: 5.12,
    }
  ],
};

const uploadsSlice = createSlice({
  name: "uploads",
  initialState,
  reducers: {
    addUpload: (state, action) => {
      state.isUploading = true;
      state.isUploaded = false;
      state.data.push(action.payload);
    },
    updateUpload: (state, action) => {
      const { folderNumber } = action.payload;
      const index = state.data.findIndex((item) => item.folderNumber === folderNumber);
      if (index !== -1) {
        state.data[index] = { ...state.data[index], ...action.payload, isFinished: action.payload.progress ? true : false };
      }
    },
    setVideoData: (state, action) => {
      state.videoData = { ...state.videoData, ...action.payload };
    },
    setDraggableImageData: (state, action) => {
      state.draggableImageData = { ...state.draggableImageData, ...action.payload };
    },
    setControlsData: (state, action) => {
      state.controlsData = { ...state.controlsData, ...action.payload };
    },
    setLoopData: (state, action) => {
      state.loopData = { ...state.loopData, ...action.payload };
    },
    startTutorial: (state, action) => {
      state.tutorial = true
    },
    endTutorial: (state, action) => {
      state.tutorial = false;
    },
    resetToInitialState: (state) => {
      // Bu reducer, tüm verileri başlangıç (initialState) değerlerine geri döndürecektir.
      return initialState;
    },
  },
});

export const {
  addUpload,
  updateUpload,
  setControlsData,
  setLoopData,
  resetToInitialState,
  startTutorial,
  endTutorial
} = uploadsSlice.actions;

export default uploadsSlice.reducer;