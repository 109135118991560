import { Card, Grid, Icon } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectfeedbacks } from 'store/main/feedbacksSlice'
import Feedback from './components/Feedback'
import { useDispatch } from 'react-redux'
import { openNewFeedbacksDialog } from 'store/main/feedbacksSlice'

function FeedbackList() {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleOpenNew = () => {
    dispatch(openNewFeedbacksDialog());
  };

  const feedbacks = useSelector(selectfeedbacks);



  return (
    <Card>
      <MDBox px={3}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7} py={3}>
            <MDBox>
              <MDTypography variant="h5">{t("feedbacks_header")}</MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={handleOpenNew}
            >
              <Icon>add</Icon>&nbsp;{t("AddNewFeedback")}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>

      {
        feedbacks.map((item, key) => {
          return <Feedback
            key={key}
            feedbackTitle={item.feedbackTitle}
            feedbackDetail={item.feedbackDetails}
            feedbackStatus={item.status}
            feedbackType={item.feedbackType}
            feedbackReply={item.feedbackReply} />
        })
      }

      {
        feedbacks && feedbacks.length > 0 &&
        <MDBox mb={3} />
      }
    </Card>
  )
}

export default FeedbackList