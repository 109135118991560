import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosConfig from "features/api/axiosConfig";

export const checkGuest = createAsyncThunk(
  "analytics/checkGuest",
  async (credentials, { dispatch, state }) => {
    try {
      const response = await axiosConfig.post(`api/analytics/check-guest`, credentials);
      const data = response.data;

      if (data.success && data.guestId) {
        localStorage.setItem("guestId", data.guestId);
      }

      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateGuestStatus = createAsyncThunk(
  "analytics/updateGuestStatus",
  async (credentials, { dispatch, state }) => {
    try {
      console.log(credentials);
      const response = await axiosConfig.post(
        `api/analytics/update-guest`,
        credentials,
      )
      const data = await response.data;
      return data;
    } catch (error) {
      console.log(error);
    }
  }
)

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    isAuthenticated: false,
    loading: true,
    userName: "",
    user: {},
  },
  reducers: {},
  extraReducers: {
    [checkGuest.fulfilled]: (state, action) => action.payload,
    [updateGuestStatus.fulfilled]: (state, action) => action.payload,
  },
});

export default analyticsSlice.reducer;
