import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import {
  closeEditInstrumentDialog,
  closeNewInstrumentDialog,
  addInstrument,
  updateInstrument
} from "store/main/instrumentsSlice";
import ImageUpload from "components/UploadComponents/ImageUpload";
import { imageProxy } from "features/api/proxy";
import { Grid } from "@mui/material";

const defaultFormState = {
  instrumentId: 0,
  instrumentNameTr: "",
  instrumentDescriptionTr: "",
  instrumentNameEn: "",
  instrumentDescriptionEn: "",
  instrumentNameDe: "",
  instrumentDescriptionDe: "",
  instrumentNameAr: "",
  instrumentDescriptionAr: "",
  status: "active",
  type: "telli",
  url: "",
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
];

const instrumentOptions = [
  { id: "vurmalı", name: "vurmalı" },
  { id: "üflemeli", name: "üflemeli" },
  { id: "tuşlu", name: "tuşlu" },
  { id: "telli", name: "telli" },
  { id: "yaylı", name: "yaylı" },
];
function InstrumentDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const instrumentDialog = useSelector(
    ({ instruments }) => instruments.instrumentDialog
  );

  const [photo, setphoto] = useState();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });
  const [instrumentValue, setInstrumentValue] = useState({
    id: "telli",
    name: "telli",
  });

  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (instrumentDialog.type === "edit" && instrumentDialog.data) {
      setForm({ ...instrumentDialog.data });
      fetch(`${imageProxy}/${instrumentDialog.data.url}`)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], instrumentDialog.data.url, { type: blob.type });
          setphoto(file);
        })
        .catch(error => {
          console.error('Dosya bulunamadı:', error);
        });
      let status = statusOptions.find(
        (f) => f.name === instrumentDialog.data.status
      );
      let instrument = instrumentOptions.find(
        (f) => f.name === instrumentDialog.data.type
      );
      setStatusValue(status);
      setInstrumentValue(instrument);
    }

    /**
     * Dialog type: 'new'
     */
    if (instrumentDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...instrumentDialog.data,
      });
      let status = statusOptions.find(
        (f) => f.name === defaultFormState.status
      );
      let instrument = instrumentOptions.find(
        (f) => f.name === defaultFormState.type
      );
      setStatusValue(status);
      setInstrumentValue(instrument);
      setphoto();
    }
  }, [instrumentDialog.data, instrumentDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (instrumentDialog.props.open) {
      initDialog();
    }
  }, [instrumentDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return instrumentDialog.type === "edit"
      ? dispatch(closeEditInstrumentDialog())
      : dispatch(closeNewInstrumentDialog());
  }

  const handleSetImage = (file) => {
    setphoto(file);
    if (!file) {
      setInForm("url", null);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (instrumentDialog.type === "new") {
      let data = {
        ...form,
        status: statusValues.id,
        type: instrumentValue.id,
        url: photo,
      };
      setphoto();
      dispatch(addInstrument(data));
    } else {
      let data = {
        ...form,
        status: statusValues.id,
        type: instrumentValue.id,
        url: photo
      };
      setphoto();
      dispatch(updateInstrument(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    return (
      form.instrumentNameTr.length > 0 && form.instrumentDescriptionTr.length > 0 &&
      form.instrumentNameEn.length > 0 && form.instrumentDescriptionEn.length > 0 &&
      form.instrumentNameDe.length > 0 && form.instrumentDescriptionDe.length > 0 &&
      form.instrumentNameAr.length > 0 && form.instrumentDescriptionAr.length > 0 &&
      photo !== undefined
    );
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...instrumentDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">{t("instrumentInfo")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="instrumentId"
          name="instrumentId"
          label={t("instrumentId")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.instrumentId}
          disabled
        />
        <MDBox mt={1}>
          <Autocomplete
            value={instrumentValue}
            options={instrumentOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("instrumentType")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setInstrumentValue({
                  id: newValue,
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                setInstrumentValue({
                  name: newValue.inputValue,
                });
              } else {
                setInstrumentValue(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="instrumentNameTr"
                name="instrumentNameTr"
                label={t("instrumentNameTr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.instrumentNameTr}
                onChange={handleChange}
                required
                error={form.instrumentNameTr ? false : true}
                helperText={t("enterInstrumentNameTr")}
                focused
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="instrumentDescriptionTr"
                name="instrumentDescriptionTr"
                label={t("instrumentDescriptionTr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.instrumentDescriptionTr}
                onChange={handleChange}
                required
                error={form.instrumentDescriptionTr ? false : true}
                helperText={t("enterInstrumentDescriptionTr")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="instrumentNameEn"
                name="instrumentNameEn"
                label={t("instrumentNameEn")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.instrumentNameEn}
                onChange={handleChange}
                required
                error={form.instrumentNameEn ? false : true}
                helperText={t("enterInstrumentNameEn")}
                focused
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="instrumentDescriptionEn"
                name="instrumentDescriptionEn"
                label={t("instrumentDescriptionEn")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.instrumentDescriptionEn}
                onChange={handleChange}
                required
                error={form.instrumentDescriptionEn ? false : true}
                helperText={t("enterInstrumentDescriptionEn")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="instrumentNameDe"
                name="instrumentNameDe"
                label={t("instrumentNameDe")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.instrumentNameDe}
                onChange={handleChange}
                required
                error={form.instrumentNameDe ? false : true}
                helperText={t("enterInstrumentNameDe")}
                focused
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="instrumentDescriptionDe"
                name="instrumentDescriptionDe"
                label={t("instrumentDescriptionDe")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.instrumentDescriptionDe}
                onChange={handleChange}
                required
                error={form.instrumentDescriptionDe ? false : true}
                helperText={t("enterInstrumentDescriptionDe")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="instrumentNameAr"
                name="instrumentNameAr"
                label={t("instrumentNameAr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.instrumentNameAr}
                onChange={handleChange}
                required
                error={form.instrumentNameAr ? false : true}
                helperText={t("enterInstrumentNameAr")}
                focused
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="instrumentDescriptionAr"
                name="instrumentDescriptionAr"
                label={t("instrumentDescriptionAr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.instrumentDescriptionAr}
                onChange={handleChange}
                required
                error={form.instrumentDescriptionAr ? false : true}
                helperText={t("enterInstrumentDescriptionAr")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Autocomplete
            value={statusValues} // Dikkat: statusValues -> statusValue olarak değiştirildi
            options={statusOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("status")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                setStatusValue({
                  id: newValue, // newValue.id -> newValue olarak değiştirildi
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                setStatusValue({
                  name: newValue.inputValue,
                });
              } else {
                setStatusValue(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox mt={2}>
          <ImageUpload
            handleSetImage={handleSetImage}
            file={photo}
            url={form && form.url ? `${imageProxy}/${form.url}` : null}
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InstrumentDialog;
