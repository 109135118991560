import MDBox from 'components/MDBox'
import BlueArea from 'components/Player/BlueArea'
import DraggableImage from 'components/Player/DraggableImage'
import React from 'react'
import { selectsongListsById } from 'store/main/songListsSlice'


function LaSolistNotePlayer() {
  return (
    <MDBox className={"NotePlayer"}>
      <BlueArea />
      <DraggableImage selectSongState={selectsongListsById} />
    </MDBox>
  )
}

export default LaSolistNotePlayer