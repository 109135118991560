import React, { useEffect, useState } from "react";
import AdminLayout from "pages/main/layout/AdminLayout";
import { useDispatch } from "react-redux";
import LoadingComponent from "components/Loading";
import SubscriptionsTable from "./SubscriptionsTable";
import SubscriptionsDialog from "./SubscriptionsDialog";
import { getSubscriptions } from "store/main/subscriptionsSlice";

const AdminSubscriptionsDashboard = (props) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    dispatch(getSubscriptions()).then(() => setDataLoading(false));
  }, [dispatch]);

  if (dataLoading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <SubscriptionsTable />
      <SubscriptionsDialog />
    </AdminLayout>
  );
};

AdminSubscriptionsDashboard.propTypes = {};

export default AdminSubscriptionsDashboard;
