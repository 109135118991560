import AdminLayout from 'pages/main/layout/AdminLayout'
import React from 'react'
import FeedbacksTable from './FeedbacksTable'
import FeedbacksDialog from './FeedbacksDialog'
import { useDispatch } from 'react-redux';
import { getFeedbacks } from 'store/main/feedbacksSlice';
import LoadingComponent from 'components/Loading';
import { useState } from 'react';
import { useEffect } from 'react';
import ConfirmDialog from 'pages/confirmation/ConfirmDialog';
import { removeFeedbacks } from 'store/main/feedbacksSlice';

function AdminFeedbacksDashboard() {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    dispatch(getFeedbacks()).then(() => setDataLoading(false));
  }, [dispatch]);

  if(dataLoading){
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <FeedbacksTable />
      <FeedbacksDialog />
      <ConfirmDialog handleDeleteData={removeFeedbacks} />
    </AdminLayout>
  )
}

export default AdminFeedbacksDashboard