import { CircularProgress } from '@mui/material';
import MDBox from 'components/MDBox'
import React from 'react'

function VideoLoading() {
  return (
    <MDBox sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "250px",
      height: "250px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      borderRadius: "100%",
      // background: rgba(navbar.main, 0.9),
      transition: "opacity .5s linear",
    }}>
      <CircularProgress color='primary'/>
    </MDBox>
  )
}

export default VideoLoading