import {
  DataGrid,
  trTR,
  enUS,
  deDE,
  arSD,
  GridActionsCellItem,
  GridToolbar
} from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useCallback, useMemo, useState } from "react";
import { Box, Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { useDispatch, useSelector } from "react-redux";
import { darken, lighten } from "@mui/material/styles";
import FileCopyIcon from "@mui/icons-material/DisabledByDefault";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import TableContainer from "@mui/material/TableContainer";
import { useTranslation } from "react-i18next";
import { openConfirmDialog } from "store/main/confirmSlice";
import {
  selectCouponCodes,
  updateCouponCode,
  openEditCouponCodeDialog,
  openNewCouponCodeDialog
} from "store/main/couponCodesSlice";
import dayjs from "dayjs";

const CouponCodesTable = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(selectCouponCodes);

  const { t, i18n } = useTranslation();
  console.log(i18n.language);
  const [pageSize, setPageSize] = useState(15);

  const handleOpenNew = () => {
    dispatch(openNewCouponCodeDialog());
  };

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const editStatus = useCallback(
    (id) => () => {
      let data = {
        ...id.row,
        status: id.row.status === "active" ? "passive" : "active",
      };
      dispatch(updateCouponCode(data));
    },
    [dispatch]
  );

  const editData = useCallback(
    (id) => () => {
      let data = id.row;
      dispatch(openEditCouponCodeDialog(data));
    },
    [dispatch]
  );

  const deleteData = useCallback(
    (id) => () => {
      let data = { ...id.row, table: "couponCodesTable" };
      dispatch(openConfirmDialog(data));
    },
    [dispatch]
  );

  const columns = useMemo(() => [
    {
      field: "actions",
      type: "actions",
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label={t("edit")}
          onClick={editData(params)}
          disabled={params.row.status === "passive" ? true : false}
        />,
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label={params.row.status === "active" ? "Pasife al" : "active et"}
          onClick={editStatus(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={t("remove")}
          onClick={deleteData(params)}
          showInMenu
        />,
      ],
    },
    {
      headerName: t("id"),
      field: "couponCodeId",
      minWidth: 70,
      hideable: false,
    },
    {
      headerName: t("referenceCode"),
      field: "referenceCode",
      minWidth: 150,
      hideable: false,
    },
    {
      headerName: t("discountPercentage"),
      field: "discountPercentage",
      minWidth: 140,
      hideable: false,
      renderCell: (params) => {
        return `%${params.value}`
      }
    },
    {
      headerName: t("creditPercentageToInstructor"),
      field: "creditPercentageToInstructor",
      minWidth: 300,
      hideable: false,
      renderCell: (params) => {
        return `%${params.value}`
      }
    },
    {
      headerName: t("creditPercentageToStudent"),
      field: "creditPercentageToStudent",
      minWidth: 300,
      hideable: false,
      renderCell: (params) => {
        return `%${params.value}`
      }
    },
    {
      headerName: t("instructorId"),
      field: "instructorId",
      minWidth: 140,
      hideable: false,
    },
    {
      headerName: t("createdDate"),
      field: "createdDate",
      minWidth: 170,
      hideable: false,
      type: "date",
      renderCell: (params) => {
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: t("expirationDate"),
      field: "expirationDate",
      minWidth: 170,
      hideable: false,
      type: "date",
      renderCell: (params) => {
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: t("status"),
      field: "status",
      minWidth: 150,
    },
  ], [editData, editStatus, deleteData, t]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox p={3} lineHeight={1}>
        <TableContainer sx={{ boxShadow: "none" }}>
          <MDBox>
            <Grid container alignItems="center">
              <Grid item xs={12} md={7}>
                <MDBox mb={1}>
                  <MDTypography variant="h5">{t("couponCodes")}</MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2" color="text">
                    {t("couponCodesList")}
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={handleOpenNew}
                >
                  <Icon>add</Icon>&nbsp;{t("AddNewCouponCode")}
                </MDButton>
              </Grid>
            </Grid>
            <div style={{ flexGrow: 1, width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      height: "100%",
                      width: 1,
                      "& .super-app-theme--iptal": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.error.main,
                            theme.palette.mode
                          ),
                      },
                      "& .super-app-theme--tamamlandi": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.info.main,
                            theme.palette.mode
                          ),
                      },
                    }}
                  >
                    <DataGrid
                      localeText={
                        (i18n.language.match("tr") && trTR.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("en") && enUS.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("de") && deDE.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("ar") && arSD.components.MuiDataGrid.defaultProps.localeText)
                      }
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      rowsPerPageOptions={[15, 30, 60]}
                      pagination
                      autoHeight
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      getRowClassName={(params) =>
                        `super-app-theme--${params.row.status}`
                      }
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                    />
                  </Box>
                </div>
              </div>
            </div>
          </MDBox>
        </TableContainer>
      </MDBox>
    </Card>
  );
};

CouponCodesTable.propTypes = {};

export default CouponCodesTable;
