import { Icon, Tooltip } from '@mui/material';
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setControlsData } from 'store/main/videoPlayerSlice';
import SettingsPopover from '..';

function SettingsButton({ ref }) {
  const dispatch = useDispatch();
  const { controlsData } = useSelector((state) => state.videoPlayer);

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    const { showMenu, showSpeed, showQuality } = controlsData;

    if (showMenu || showQuality || showSpeed) {
      console.log("buradayız");
      dispatch(setControlsData({ ...controlsData, showMenu: false, showQuality: false, showSpeed: false, }));
      setAnchorEl(null);
    }
    else {
      dispatch(setControlsData({ ...controlsData, showMenu: true }));
      setAnchorEl(event.currentTarget);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <Tooltip title={t("VideoSettings")}>
        <button className='SettingsButton' onClick={handleClick}>
          <Icon sx={{ transform: controlsData.showMenu ? "rotate(90deg)" : "rotate(0deg)", transition: "all 0.2s ease-in" }}>settings_rounded</Icon>
        </button>
      </Tooltip>
      <SettingsPopover id={id} open={open} anchorEl={anchorEl} />
    </>
  )
}

export default SettingsButton