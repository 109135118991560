import { Icon } from '@mui/material'
import MDBox from 'components/MDBox'
import { usePlayer } from 'hooks/PlayerProvider'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateVideoAnalyze } from 'store/analytics/videoAnalyzeSlice'
import { resetToInitialState } from 'store/main/videoPlayerSlice'
import { startTutorial } from 'store/main/videoPlayerSlice'
import { endTutorial } from 'store/main/videoPlayerSlice'

function VideoHeader({ name }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tutorial, videoData } = useSelector((state) => state.videoPlayer);

  const { renderVideoTitle, handlePause } = usePlayer();

  const handleBack = () => {
    navigate(-1);
    dispatch(resetToInitialState());
    try {

      let data = {
        videoAnalyzeId: parseInt(localStorage.getItem("activeVideoAnalyzeId")),
        status: "leaved",
        exitTime: new Date(),
        lastVideoTime: videoData.currentTime,
      }
      dispatch(updateVideoAnalyze(data))
    } catch (error) {
      console.log(error);
    }
  }

  const handleStartTutorial = () => {
    if (tutorial) {
      dispatch(endTutorial());

    }
    else {
      dispatch(startTutorial());
      handlePause();
    }
  }



  return (
    <MDBox className={"HeaderContainer"}>

      <MDBox
        className={"Step_Back BackButton ButtonContainer"}
        sx={{
          border: "3px solid white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          cursor: "pointer"
        }}
        color={"white"}
        onClick={handleBack}
      >
        <Icon>arrow_back</Icon>
      </MDBox>


      <p className={"SongTitle"}>{renderVideoTitle(name)}</p>

      <MDBox
        sx={{
          border: "3px solid white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          cursor: "pointer"
        }}
        color={"white"}
        onClick={handleStartTutorial}
        className={"TutorialButton BackButton ButtonContainer"}
      >
        <Icon>question_mark</Icon>
      </MDBox>

    </MDBox>

  )
}

export default VideoHeader