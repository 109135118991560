import React, { useEffect, useState } from "react";
import AdminLayout from "pages/main/layout/AdminLayout";
import { useDispatch } from "react-redux";
import LoadingComponent from "components/Loading";
import { getCourses } from "store/main/coursesSlice";
import CourseTable from "./CourseTable";
import CourseDialog from "./CourseDialog";
import { getCategories } from "store/main/categoriesSlice";
import { getLessons } from "store/main/lessonsSlice";
import { getInstruments } from "store/main/instrumentsSlice";
import ConfirmDialog from "pages/confirmation/ConfirmDialog";
import { removeCourses } from "store/main/coursesSlice";

const AdminCoursesDashboard = (props) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [lessonsLoading, setLessonsLoading] = useState(true);
  const [instrumentLoading, setInstrumentLoading] = useState();

  useEffect(() => {
    dispatch(getCourses()).then(() => setDataLoading(false));
    dispatch(getCategories()).then(() => setCategoriesLoading(false));    
    dispatch(getLessons()).then(() => setLessonsLoading(false));
    dispatch(getInstruments()).then(() => setInstrumentLoading(false));
  }, [dispatch]);

  if (dataLoading || categoriesLoading || lessonsLoading || instrumentLoading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <CourseTable />
      <CourseDialog />
      <ConfirmDialog handleDeleteData={removeCourses} />
    </AdminLayout>
  );
};


export default AdminCoursesDashboard;
