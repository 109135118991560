/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples


// Wizard page components
import { Icon, Menu } from "@mui/material";
import { useDispatch } from "react-redux";
import { logout } from "store/auth/authSlice";
import MainFooter from "pages/main/footer/MainFooter";
import { useTranslation } from "react-i18next";
import PricingPlans from "./PricingPlansInfo/PricingPlans";
import UserInfo from "./userInfo/UserInfo";
import AddressInfo from "./addressInfo/AddressInfo";
import { setUserInfo } from "store/main/subscriptionsSlice";
import IyzicoCheckout from "./checkoutForm/IyzicoCheckout.jsx";
import { setPaymentInitialState } from "store/payment/paymentsSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotificationItem from "examples/Items/NotificationItem";
import PayPalCheckout from "./checkoutForm/PayPalCheckout";
import PreviewPlan from "./previewPlan/PreviewPlan";



function getSteps() {
  return ["pricingPlanInformations", "previewPlan", "userInformations", "addressInformations", "checkout"];
}

function getStepsForPaypal() {
  return ["pricingPlanInformations", "previewPlan", "userInformations", "checkout"];
}

function getStepContent(stepIndex, handleNext, handleBack, location) {
  switch (stepIndex) {
    case 0:
      return <PricingPlans handleNext={handleNext} />;
    
    case 1:
      return <PreviewPlan handleNext={handleNext} handleBack={handleBack} />
    case 2:
      return <UserInfo handleNext={handleNext} handleBack={handleBack} />;
    case 3:
      if (location === "TR") {
        return <AddressInfo handleNext={handleNext} handleBack={handleBack} />;
      }
      return handleNext()
    case 4:
      if (location === "TR") {
        return <IyzicoCheckout handleBack={handleBack} />;
      }
      else {
        return <PayPalCheckout handleBack={handleBack} />
      }
    default:
      return null;
  }
}

function Wizard() {
  const { isTrial } = useSelector((state) => state.payments);
  const { country_code } = useSelector((state) => state.location);
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const steps = country_code === "TR" ? getSteps() : getStepsForPaypal();

  const handleNext = (stepNumber) => setActiveStep(activeStep + stepNumber);
  const handleBack = (stepNumber) => setActiveStep(activeStep - stepNumber);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(setUserInfo());
    dispatch(setPaymentInitialState());
    dispatch(logout());
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    navigate("/profil");
  }

  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const changeLanguageHandler = (e, lang) => {
    e.preventDefault();
    i18n.changeLanguage(lang);
    handleCloseMenu();
  };

  const renderLanguageMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}>
      <NotificationItem
        icon={
          <img
            srcSet={`https://flagcdn.com/w40/tr.png 2x`}
            src={`https://flagcdn.com/w20/tr.png`}
            alt="tr"
          />
        }
        title="Türkçe"
        onClick={(e) => changeLanguageHandler(e, "tr")}
      />
      <NotificationItem
        icon={
          <img
            srcSet={`https://flagcdn.com/w40/gb.png 2x`}
            src={`https://flagcdn.com/w20/gb.png`}
            alt="en"
          />
        }
        title="English"
        onClick={(e) => changeLanguageHandler(e, "en")}
      />
      <NotificationItem
        icon={
          <img
            srcSet={`https://flagcdn.com/w40/de.png 2x`}
            src={`https://flagcdn.com/w20/de.png`}
            alt="de"
          />
        }
        title="Deutsch"
        onClick={(e) => changeLanguageHandler(e, "de")}
      />
    </Menu>
  );

  return (
    <MDBox mx={1}>
      <MDBox pt={3}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
              {
                isTrial ? <MDButton onClick={handleGoBack} color={"error"} variant={"gradient"} sx={{ width: "150px" }}>
                  <Icon sx={{ transform: "scaleX(-1)" }}>logout</Icon>&nbsp;
                  {t("goBack")}
                </MDButton> : <MDButton onClick={handleLogout} color={"error"} variant={"gradient"} sx={{ width: "150px" }}>
                  <Icon sx={{ transform: "scaleX(-1)" }}>logout</Icon>&nbsp;
                  {t("logout")}
                </MDButton>
              }

              <MDBox display={{ width: "150px", display: "flex" }}>
                <MDButton
                  variant="gradient"
                  color={"info"}
                  sx={{ width: "100%" }}
                  onClick={handleOpenMenu}>
                  {i18n.language}
                </MDButton>
                {renderLanguageMenu()}
              </MDBox>
            </MDBox>
          </Grid>

          <Grid item xs={12} lg={8}>

            <MDBox mb={8} textAlign="center">
              {/* <MDBox mb={1}>
                <MDTypography variant="h3" color="white" fontWeight="bold">
                  Build Your Profile
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" color="white" fontWeight="regular">
                This information will let us know more about you.
              </MDTypography> */}

              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">

                  {t("new_subscription_wizard_header")}
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" color="secondary" fontWeight="regular">

                {t("new_subscription_wizard_description")}
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{t(label)}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep, handleNext, handleBack, country_code)}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>

        </Grid>


      </MDBox>
    </MDBox >
  );
}

export default Wizard;
