import {
  DataGrid,
  trTR,
  enUS,
  deDE,
  arSD,
  GridActionsCellItem,
  GridToolbar
} from "@mui/x-data-grid"; import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useMemo, useState } from "react";
import { Box, Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { useDispatch, useSelector } from "react-redux";
import { darken, lighten } from "@mui/material/styles";
import FileCopyIcon from "@mui/icons-material/DisabledByDefault";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";
import { useTranslation } from "react-i18next";
import MDAvatar from "components/MDAvatar";
import { imageProxy } from "features/api/proxy";
import Link from "@mui/material/Link";
import { updateinstructorLesson } from "store/main/instructorLessonsSlice";
import { openEditinstructorLessonDialog } from "store/main/instructorLessonsSlice";
import { openNewinstructorLessonDialog } from "store/main/instructorLessonsSlice";
import { selectinstructorLessons } from "store/main/instructorLessonsSlice";
import { openConfirmDialog } from "store/main/confirmSlice";
import dayjs from "dayjs";

const InsLessonsTable = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(selectinstructorLessons);
  console.log(data);
  const { t, i18n } = useTranslation();
  const [pageSize, setPageSize] = useState(15);

  const handleOpenNew = () => {
    dispatch(openNewinstructorLessonDialog());
  };

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const editStatus = React.useCallback(
    (id) => () => {
      let data = {
        ...id.row,
        status: id.row.status === "active" ? "passive" : "active",
      };
      dispatch(updateinstructorLesson(data));
    },
    [dispatch]
  );

  const editData = React.useCallback(
    (id) => () => {
      let data = id.row;
      console.log(data);
      dispatch(openEditinstructorLessonDialog(data));
    },
    [dispatch]
  );

  const deletaData = React.useCallback(
    (id) => () => {
      let data = { ...id.row, table: "instructorLessonsTable" };
      dispatch(openConfirmDialog(data));
    },
    [dispatch]
  );

  const columns = useMemo(() => [
    {
      field: "actions",
      type: "actions",
      width: 70,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Düzenle"
          onClick={editData(params)}
          disabled={params.row.status === "passive" ? true : false}
        />,
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label={params.row.status === "active" ? "Pasife al" : "active et"}
          onClick={editStatus(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label={t("remove")}
          onClick={deletaData(params)}
          showInMenu
        />,
      ],
    },
    {
      headerName: t("id"),
      field: "lessonId",
      minWidth: 40,
      hideable: false,
    },
    {
      headerName: t("courseName"),
      field: "courseName",
      minWidth: 180,
      hideable: false,
    },
    {
      headerName: t("instructorName"),
      field: "instructorLessonCourseInfo",
      minWidth: 150,
      hideable: false,
      renderCell: (params) => {
        return params.value.instructorName;
      }
    },
    {
      headerName: t("lessonName"),
      field: "lessonName",
      minWidth: 300,
      hideable: false,
      renderCell: (params) => {
        console.log(params);
        if (i18n.language.match("tr")) {
          return params.row.lessonNameTr;
        }
        else if (i18n.language.match("en")) {
          return params.row.lessonNameEn;
        }
        else if (i18n.language.match("de")) {
          return params.row.lessonNameDe;
        }
        else if (i18n.language.match("ar")) {
          return params.row.lessonNameAr
        }
        else {
          return null;
        }
      }
    },
    {
      headerName: t("lessonDescription"),
      field: "lessonDescription",
      minWidth: 300,
      hideable: false,
      renderCell: (params) => {
        console.log(params);
        if (i18n.language.match("tr")) {
          return params.row.lessonDescriptionTr;
        }
        else if (i18n.language.match("en")) {
          return params.row.lessonDescriptionEn;
        }
        else if (i18n.language.match("de")) {
          return params.row.lessonDescriptionDe;
        }
        else if (i18n.language.match("ar")) {
          return params.row.lessonDescriptionAr
        }
        else {
          return null;
        }
      }
    },
    {
      headerName: "Video",
      field: "videoUrl",
      minWidth: 140,
      hideable: false,
      renderCell: (params) => {
        return params.value ? (
          <Link href={`${imageProxy}/${params.value}`} target="_blank">
            <MDTypography variant="button" fontWeight="medium">
              &nbsp;Görüntüle&nbsp;
            </MDTypography>
          </Link>
        ) : null

      },
    },
    {
      headerName: t("duration"),
      field: "duration",
      minWidth: 140,
      hideable: false,
    },
    {
      headerName: "url",
      field: "url",
      minWidth: 140,
      hideable: false,
      renderCell: (params) => {
        return (
          <MDAvatar src={`${imageProxy}/${params.value}`} alt={params.value} />
        );
      },
    },
    {
      headerName: t("createdDate"),
      field: "createdDate",
      minWidth: 170,
      hideable: false,
      type: "date",
      renderCell: (params) => {
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: t("status"),
      field: "status",
      minWidth: 150,
    },
  ], [editData, editStatus, t, deletaData, i18n.language]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox p={3} lineHeight={1}>
        <TableContainer sx={{ boxShadow: "none" }}>
          <MDBox>
            <Grid container alignItems="center">
              <Grid item xs={12} md={7}>
                <MDBox mb={1}>
                  <MDTypography variant="h5">{t("lessons")}</MDTypography>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="body2" color="text">
                    {t("lessonList")}
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={handleOpenNew}
                >
                  <Icon>add</Icon>&nbsp;{t("AddNewLesson")}
                </MDButton>
              </Grid>
            </Grid>
            <div style={{ flexGrow: 1, width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      height: "100%",
                      width: 1,
                      "& .super-app-theme--iptal": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.error.main,
                            theme.palette.mode
                          ),
                      },
                      "& .super-app-theme--tamamlandi": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.info.main,
                            theme.palette.mode
                          ),
                      },
                    }}
                  >
                    <DataGrid
                      localeText={
                        (i18n.language.match("tr") && trTR.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("en") && enUS.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("de") && deDE.components.MuiDataGrid.defaultProps.localeText) ||
                        (i18n.language.match("ar") && arSD.components.MuiDataGrid.defaultProps.localeText)
                      }
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      rowsPerPageOptions={[15, 30, 60]}
                      pagination
                      autoHeight
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      getRowClassName={(params) =>
                        `super-app-theme--${params.row.status}`
                      }
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                    />
                  </Box>
                </div>
              </div>
            </div>
          </MDBox>
        </TableContainer>
      </MDBox>
    </Card>
  );
};

InsLessonsTable.propTypes = {};

export default InsLessonsTable;
