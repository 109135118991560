import React, { useState, useEffect } from "react";
import AdminLayout from "pages/main/layout/AdminLayout";
import { useDispatch } from "react-redux";
import { getSongs } from "store/main/songsSlice";
import LoadingComponent from "components/Loading";
import { getCategories } from "store/main/categoriesSlice";
import { getComposers } from "store/main/composersSlice";
import SongsTable from "./SongsTable";
import SongDialog from "./SongDialog";
import ConfirmDialog from "pages/confirmation/ConfirmDialog";
import { removeSong } from "store/main/songsSlice";

const AdminSongsDashboard = (props) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [composersLoading, setComposerLoading] = useState(true);

  useEffect(() => {
    dispatch(getSongs()).then(() => setDataLoading(false));
    dispatch(getCategories()).then(() => setCategoriesLoading(false));
    dispatch(getComposers()).then(() => setComposerLoading(false));
  }, [dispatch]);

  if (dataLoading || categoriesLoading || composersLoading) {
    return <LoadingComponent />;
  }

  return (
    <AdminLayout>
      <SongDialog />
      <SongsTable />
      <ConfirmDialog handleDeleteData={removeSong} />
    </AdminLayout>
  );
};

AdminSongsDashboard.propTypes = {};

export default AdminSongsDashboard;
