import { Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import ImageUpload from "components/UploadComponents/ImageUpload";
import { imageProxy } from "features/api/proxy";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addinstructorCourses,
  closeEditinstructorCoursesDialog,
  closeNewinstructorCoursesDialog,
  updateinstructorCourses,
} from "store/main/instructorCoursesSlice";
import { selectinstructors } from "store/main/instructorsSlice";
import { selectinstruments } from "store/main/instrumentsSlice";

const defaultFormState = {
  courseId: 0,
  courseName: "",
  courseDescriptionTr: "",
  courseDescriptionEn: "",
  courseDescriptionDe: "",
  courseDescriptionAr: "",
  level: "",
  duration: "",
  courseCategoriesData: [],
  courseLessonsData: [],
  status: "active",
  instrumentId: "",
  url: "",
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
];

function InsCourseDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const instructorCourseDialog = useSelector(
    ({ instructorCourses }) => instructorCourses.instructorCourseDialog
  );
  const instruments = useSelector(selectinstruments);
  const instructors = useSelector(selectinstructors);

  const [photo, setphoto] = useState();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });

  const [instrumentValue, setInstrumentValue] = useState({
    id: 0,
    name: "",
  });

  const [instructorValue, setinstructorValue] = useState({
    id: 0,
    name: "",
  });

  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (instructorCourseDialog.type === "edit" && instructorCourseDialog.data) {
      setForm({ ...instructorCourseDialog.data });
      fetch(`${imageProxy}/${instructorCourseDialog.data.url}`)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], instructorCourseDialog.data.url, {
            type: blob.type,
          });
          setphoto(file);
        })
        .catch((error) => {
          console.error("Dosya bulunamadı:", error);
        });
      let status = statusOptions.find(
        (f) => f.name === instructorCourseDialog.data.status
      );
      let instrument = instruments.find(
        (f) => f.instrumentId === instructorCourseDialog.data.instrumentId
      );
      let instructor = instructors.find(
        (f) => f.instructorId === instructorCourseDialog.data.instructorId
      );
      setInstrumentValue(instrument);
      setinstructorValue(instructor);
      setStatusValue(status);
    }

    /**
     * Dialog type: 'new'
     */
    if (instructorCourseDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...instructorCourseDialog.data,
      });
      setphoto();
      let status = statusOptions.find(
        (f) => f.name === defaultFormState.status
      );
      let instrument = instruments.find(
        (f) => f.instrumentId === defaultFormState.instrumentId
      );
      let instructor = instructors.find(
        (f) => f.instructorId === defaultFormState.instructorId
      );
      setInstrumentValue(instrument);
      setinstructorValue(instructor);
      setStatusValue(status);
    }
  }, [
    instructorCourseDialog.data,
    instructorCourseDialog.type,
    setForm,
    instruments,
    instructors,
  ]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (instructorCourseDialog.props.open) {
      initDialog();
    }
  }, [instructorCourseDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return instructorCourseDialog.type === "edit"
      ? dispatch(closeEditinstructorCoursesDialog())
      : dispatch(closeNewinstructorCoursesDialog());
  }

  const handleSetImage = (file) => {
    setphoto(file);
    if (!file) {
      setInForm("url", null);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (instructorCourseDialog.type === "new") {
      let data = {
        ...form,
        instrumentId: instrumentValue.id,
        instructorId: instructorValue.id,
        status: statusValues.id,
        url: photo,
      };
      setphoto();
      setInstrumentValue();
      setinstructorValue();
      dispatch(addinstructorCourses(data));
    } else {
      let data = {
        ...form,
        instrumentId: instrumentValue.id,
        instructorId: instructorValue.id,
        status: statusValues.id,
        url: photo,
      };
      setphoto();
      setInstrumentValue();
      setinstructorValue();
      dispatch(updateinstructorCourses(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    return (
      form.courseName.length > 0 &&
      form.courseDescriptionTr.length > 0 &&
      form.courseDescriptionEn.length > 0 &&
      form.courseDescriptionDe.length > 0 &&
      form.courseDescriptionAr.length > 0 &&
      form.level.length > 0 &&
      form.duration.length > 0 &&
      photo !== undefined
    );
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...instructorCourseDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">{t("insCourseInfo")}</DialogTitle>
      <DialogContent>
        <MDBox>
          <TextField
            autoFocus
            margin="dense"
            id="courseId"
            name="courseId"
            label={t("courseId")}
            type="text"
            fullWidth
            variant="outlined"
            value={form.courseId}
            disabled
          />
        </MDBox>
        <MDBox my={1}>
          <Autocomplete
            value={instrumentValue}
            options={instruments}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("instrumentType")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue.name === "string") {
                setInstrumentValue({
                  id: newValue.id,
                  name: newValue.name,
                });
              } else if (newValue && newValue.inputValue) {
                setInstrumentValue({
                  name: newValue.inputValue,
                });
              } else {
                setInstrumentValue(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox my={1}>
          <Autocomplete
            value={instructorValue}
            options={instructors}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("instructors")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue.name === "string") {
                setinstructorValue({
                  id: newValue.id,
                  name: newValue.name,
                });
              } else if (newValue && newValue.inputValue) {
                setinstructorValue({
                  name: newValue.inputValue,
                });
              } else {
                setinstructorValue(newValue);
              }
            }}
          />
        </MDBox>
        <TextField
          autoFocus
          margin="dense"
          id="courseName"
          name="courseName"
          label={t("courseName")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.courseName}
          onChange={handleChange}
          required
          error={form.courseName ? false : true}
          helperText={t("enterCourseName")}
          focused
        />
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="courseDescriptionTr"
                name="courseDescriptionTr"
                label={t("courseDescriptionTr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.courseDescriptionTr}
                onChange={handleChange}
                required
                error={form.courseDescriptionTr ? false : true}
                helperText={t("enterCourseDescriptionTr")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="courseDescriptionEn"
                name="courseDescriptionEn"
                label={t("courseDescriptionEn")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.courseDescriptionEn}
                onChange={handleChange}
                required
                error={form.courseDescriptionEn ? false : true}
                helperText={t("enterCourseDescriptionEn")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="courseDescriptionDe"
                name="courseDescriptionDe"
                label={t("courseDescriptionDe")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.courseDescriptionDe}
                onChange={handleChange}
                required
                error={form.courseDescriptionDe ? false : true}
                helperText={t("enterCourseDescriptionDe")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="courseDescriptionAr"
                name="courseDescriptionAr"
                label={t("courseDescriptionAr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.courseDescriptionAr}
                onChange={handleChange}
                required
                error={form.courseDescriptionAr ? false : true}
                helperText={t("enterCourseDescriptionAr")}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="duration"
                name="duration"
                label={t("duration")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.duration}
                onChange={handleChange}
                required
                error={form.duration ? false : true}
                helperText={t("enterDuration")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="level"
                name="level"
                label={t("level")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.level}
                onChange={handleChange}
                required
                error={form.level ? false : true}
                helperText={t("enterLevel")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Autocomplete
            value={statusValues}
            options={statusOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("status")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue.name === "string") {
                setStatusValue({
                  id: newValue.id,
                  name: newValue.name,
                });
              } else if (newValue && newValue.inputValue) {
                setStatusValue({
                  name: newValue.inputValue,
                });
              } else {
                setStatusValue(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox mt={2}>
          <ImageUpload
            handleSetImage={handleSetImage}
            file={photo}
            url={form && form.url ? `${imageProxy}/${form.url}` : null}
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InsCourseDialog;
