import { Popover, Popper } from '@mui/material'
import QualityMenu from 'components/Controls/Menu/Quality/QualityMenu';
import SettingsMenu from 'components/Controls/Menu/Settings/SettingsMenu';
import SpeedMenu from 'components/Controls/Menu/Speed/SpeedMenu';
import { usePlayer } from 'hooks/PlayerProvider';
import React, { useState } from 'react'
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setControlsData } from 'store/main/videoPlayerSlice';



function SettingsPopover({ id, open, anchorEl }) {
  const { controlsData } = useSelector((state) => state.videoPlayer);
  const { setPlaybackRate } = usePlayer();

  const renderPopoverContent = useCallback(() => {
    if (controlsData.showMenu) {
      return <SettingsMenu />
    }
    else if (controlsData.showQuality) {
      return <QualityMenu />
    }
    else {
      return <SpeedMenu setPlaybackRate={setPlaybackRate} />
    }
  }, [controlsData.showMenu, controlsData.showQuality, setPlaybackRate]);

  return (
    <Popper
      style={{ zIndex: 100 }}
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement='top-end'
      disablePortal={true}
      modifiers={[
        {
          name: 'flip',
          enabled: false,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      {
        renderPopoverContent()
      }


    </Popper>
  )
}

export default SettingsPopover