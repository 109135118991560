import { Card } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { imageProxy } from 'features/api/proxy';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectinstructorCourses } from 'store/main/instructorCoursesSlice';

//ToDo instructorDescription change
function Header() {
  const courses = useSelector(selectinstructorCourses);
  console.log(courses);
  const [instructorInfo, setInstructorInfo] = useState({});

  useEffect(() => {
    if (courses.length > 0) {
      setInstructorInfo(courses[0].courseinstructorInfo);
    }
  }, [courses])
  return (
    <Card sx={{ padding: 3, marginBottom: 2, display: "flex", flexDirection: "row" }}>
      {/* {JSON.stringify(course)} */}
      <MDBox component="img" src={`${imageProxy}/${instructorInfo.url}`} sx={{ width: "100px", marginRight: 3 }} borderRadius={"md"} />

      <MDBox>
        <MDTypography variant="h4">{instructorInfo.instructorName}</MDTypography>
        <MDTypography variant="body">{instructorInfo.instructorDescriptionTr}</MDTypography>
      </MDBox>
    </Card>
  )
}

export default Header