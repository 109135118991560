
import React from 'react'

import bgImage2 from "assets/images/update/update-bg2.jpg";


import OptionsIcons2 from "assets/images/update/options2.svg";
import { Card } from '@mui/material';
import MDTypography from 'components/MDTypography';
import CoverLayout from 'pages/main/layout/CoverLayout';
import { useTranslation } from 'react-i18next';


function Update() {

  const { t } = useTranslation();

  return (
    <CoverLayout image={bgImage2}>
      <Card sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        textAlign: "center",
        padding: "25px",
      }}>
        <img style={{ width: "48px" }} alt='guncelleme' src={OptionsIcons2} />

        <MDTypography variant="h5" mx={"20px"}>
          {t("updateHeader")}
        </MDTypography>

        <img style={{ width: "48px" }} src={OptionsIcons2} alt="" />
      </Card>
    </CoverLayout>
  )
}

export default Update