import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, List, ListItem, ListItemText, Icon, Card, Grid } from '@mui/material';
import MDButton from 'components/MDButton';
import { useForm } from 'features/hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { selectLiveSupportsById } from 'store/main/liveSupportsSlice';
import { useSelector } from 'react-redux';
import MDTypography from 'components/MDTypography';
import SendMessageSection from './SendMessageSection';
import Messages from './Messages';
import { getLiveSupportsByQuery } from 'store/main/liveSupportsSlice';
import { useDispatch } from 'react-redux';
import { useUserSocket } from 'hooks/UserSocketContext';
import MDBox from 'components/MDBox';

const defaultFormState = {
  message: "",
};

const ChatRoom = () => {
  const { id } = useParams();
  const support = useSelector((state) => selectLiveSupportsById(state, id));
  const { form, handleChange, } = useForm(defaultFormState);
  const { t } = useTranslation();

  const { activeSupportRoom } = useUserSocket();

  return (
    <Card sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 4, height: "100%" }}>
      <MDBox>
        <MDTypography variant="h4">{support.supportHeader}</MDTypography>
        <MDBox sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <MDBox sx={{ width: 16, height: 16, borderRadius: 8, transition: "300ms ease-in-out all" }} bgColor={activeSupportRoom == id ? "info" : "error"}></MDBox>
          {activeSupportRoom == id ? "Çevrimiçi" : "Çevrimdışı"}

        </MDBox>
      </MDBox>

      <Messages />

      <SendMessageSection />
    </Card>
  );
};

export default ChatRoom;