import { Icon } from '@mui/material';
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setVideoData } from 'store/main/videoPlayerSlice';

function PlayPauseButton() {
  const dispatch = useDispatch();
  const { videoData } = useSelector((state) => state.videoPlayer);

  const changeIsPlaying = (status) => {
    dispatch(setVideoData({ ...videoData, isPlaying: status }));
  }

  return (
    <button
      onClick={() => changeIsPlaying(!videoData.isPlaying)}>
      {videoData.isPlaying ?
        <Icon>pause</Icon> :
        <Icon>play_arrow_rounded</Icon>}
    </button>
  )
}

export default PlayPauseButton