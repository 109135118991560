import { Card, Container } from '@mui/material'
import MDTypography from 'components/MDTypography'
import CoverLayout from 'pages/main/layout/CoverLayout'
import React from 'react'

import bgImage from "assets/images/laSolist_player_01.jpeg";
import { useTranslation } from 'react-i18next'

function AboutUs() {
  const { t } = useTranslation();
  return (
    <CoverLayout image={bgImage} coverHeight={"calc(100vh - 2rem)"}>
      <Container sx={{ transform: "translate(0, 40%)" }}>
        <Card
          sx={{
            background: "rgba(255,255,255, .90)",
            padding: "30px",
          }}>
          <MDTypography fontSize={{ xs: "1.3rem", lg: "1.4rem", xl: "1.5rem" }} fontWeight="bold" mb={1}>
            {t("aboutUsHeader")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("aboutUs_p1")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("aboutUs_p2")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("aboutUs_p3")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("aboutUs_p4")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("aboutUs_p5")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("aboutUs_p6")}
          </MDTypography>
          <MDTypography fontSize={{ xs: "1rem", lg: "1.05rem", xl: "1.1rem" }} mb={2}>
            {t("aboutUs_p7")}
          </MDTypography>
        </Card>
      </Container>
    </CoverLayout>
  )
}

export default AboutUs