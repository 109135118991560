import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";

export const getSubscriptions = createAsyncThunk(
  "iyzicoSubscriptions/getSubscriptions",
  async () => {
    const response = await axiosConfig.get(`/api/iyzico/subscriptions`);
    let { items } = await response.data.result.data;
    return items;
  }
);

export const addSubscription = createAsyncThunk(
  "iyzicoSubscriptions/addSubscription",
  async (subscription, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(
        `/api/iyzico/subscriptions`,
        subscription,
      );

      const { data } = await response.data.result;
      if (response.data.success === true) {
        return data;
      }
    } catch (error) {

      return null;
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "iyzicoSubscriptions/cancelSubscription",
  async (subscription, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(
        `/api/iyzico/subscriptions/cancel`,
        subscription,
      );


      console.log("BURAYA BAKKK", response);
      return response.data.result;

    } catch (error) {

      return null;
    }
  }
);


export const changeCardInformation = createAsyncThunk(
  "iyzicoSubscriptions/cancelSubscription",
  async (subscription, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(
        `/api/iyzico/subscriptions/changeCardInformation`,
        subscription,
      );

      const { result } = await response.data;
      if (response.data.success === true) {
        return result;
      }
    } catch (error) {

      return null;
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "iyzicoSubscriptions/updateSubscription",
  async (subscription, { dispatch, getState }) => {
    try {

      const response = await axiosConfig.put(
        `/api/iyzico/subscriptions/${subscription.referenceCode}`,
        subscription,
      );

      const { data } = await response.data.result;

      if (response.data.success === true) {
        return data;
      }
    } catch (error) {

      return null;
    }
  }
);

export const removeSubscription = createAsyncThunk(
  "iyzicoSubscriptions/removeSubscription",
  async (subscriptionId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/iyzico/subscriptions/${subscriptionId}`);
    if (response.data.success === true) {
      return subscriptionId;
    }
    return subscriptionId;
  }
);

export const initializeCheckoutForm = createAsyncThunk(
  "iyzicoSubscriptions/initializeCheckoutForm",
  async (userInfo, { dispatch, getState }) => {
    const response = await axiosConfig.post(
      `api/iyzico/subscriptions/initialize-checkout-form`,
      userInfo,
    );

    console.log("bakk", response);

    return response.data.result;

  }
)

export const deneme = createAsyncThunk(
  "iyzicoSubscriptions/deneme",
  async (htmlContent) => {
    const response = await axiosConfig.get(`/api/iyzico/deneme`, htmlContent);

    console.log(response);
  }
)

const subscriptionsAdapter = createEntityAdapter({
  selectId: (iyzicoSubscriptions) => iyzicoSubscriptions.referenceCode,
});

export const {
  selectAll: selectiyzicoSubscriptions,
  selectById: selectiyzicoSubscriptionsById,
} = subscriptionsAdapter.getSelectors((state) => state.iyzicoSubscriptions);

const iyzicoSubscriptionsSlice = createSlice({
  name: "iyzicoSubscriptions",
  initialState: subscriptionsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    subscriptionDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setSubscriptionSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewSubscriptionDialog: (state, action) => {
      state.subscriptionDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewSubscriptionDialog: (state, action) => {
      state.subscriptionDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditSubscriptionDialog: (state, action) => {
      state.subscriptionDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditSubscriptionDialog: (state, action) => {
      state.subscriptionDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateSubscription.fulfilled]: subscriptionsAdapter.upsertOne,
    [addSubscription.fulfilled]: subscriptionsAdapter.addOne,
    [removeSubscription.fulfilled]: (state, action) =>
      subscriptionsAdapter.removeOne(state, action.payload),
    [getSubscriptions.fulfilled]: subscriptionsAdapter.setAll,
  },
});

export const {
  setSubscriptionSearchText,
  openNewSubscriptionDialog,
  closeNewSubscriptionDialog,
  openEditSubscriptionDialog,
  closeEditSubscriptionDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = iyzicoSubscriptionsSlice.actions;

export default iyzicoSubscriptionsSlice.reducer;
