/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React examples
// import Sidenav from "examples/Sidenav";
import Sidenav from "pages/main/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
} from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import setAuthToken from "features/api/setAuthToken";

import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "store/auth/authSlice";


// Routes
import webPageRoutes from "routes/webPageRoutes";
import webPageSubRoutes from "routes/webPageSubRoutes";
import adminRoutes from "routes/adminRoutes";
import musicianRoutes from "routes/musicianRoutes";
import studentRoutes from "routes/studentRoutes";
import studentTrialRoutes from "routes/studentTrialRoutes"
import studentSubRoutes from "routes/studentSubRoutes";
import paymentRoutes from "routes/paymentRoutes";
import adminSubRoutes from "routes/adminSubRoutes";

import { useTranslation } from "react-i18next";

// application update
import data from "./status.json"

import "./index.css";

import { checkSubscription } from "store/payment/paymentsSlice";
import LoadingComponent from "components/Loading";
import Update from "pages/webPages/update/Update";
import { getLocation } from "store/main/locationsSlice";
import { checkGuest } from "store/analytics/analyticsSlice";
import { getSettings } from "store/main/settingsSlice";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(true);
  const [locationLoading, setLocationLoading] = useState(true);

  const dispatchEvent = useDispatch();
  const { isAuthenticated, shouldSong, shouldLesson, userAuth } = useSelector((state) => state.auth);
  const { isSubscribed, isTrial, subscriptionLoaded } = useSelector((state) => state.payments);

  const { t, i18n } = useTranslation();
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // const handleConfiguratorOpen = () =>
  //   setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    setLoading(true);
    setLocationLoading(true);

    if (i18n.language.includes("en")) {
      console.log("en")
    }
    dispatchEvent(loadUser()).then((params) => {
      try {
        const { isAuthenticated, userName, userAuth } = params.payload;
        console.log(userAuth, "buraya girdi");
        if (isAuthenticated && userAuth === "student") {
          dispatchEvent(checkSubscription({ userName }));
        }
        // else if (userAuth === "admin") {
        //   navigate("/dashboard/analytics");
        // }
      } catch (error) {
        console.log(error)
      }
      setTimeout(() => setLoading(false));
    });

    dispatchEvent(getLocation()).then((params) => {
      try {
        console.log(params.payload);
        if (params.payload.country_code === "TR") {
          i18n.changeLanguage("tr")
        }
        else if (params.payload.country_code === "DE") {
          i18n.changeLanguage("de")
        }
        else {
          i18n.changeLanguage("en")
        }
        dispatchEvent(checkGuest(params.payload));
        setLocationLoading(false);
      } catch (error) {
        setLocationLoading(false);
      }
    })

    dispatchEvent(getSettings());
  }, [dispatchEvent, i18n]);

  const getRoutes = (allRoutes) =>
    allRoutes &&
    allRoutes.length > 0 &&
    Array.isArray(allRoutes) &&
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.isDashboard) {

          return (
            <Route
              exact
              path={t(route.route)}
              element={route.component}
              key={route.key}
            />
          );
        }
        else if (route.isSong) {
          if (shouldSong) {
            return (
              <Route
                exact
                path={t(route.route)}
                element={route.component}
                key={route.key}
              />
            );
          }
          else {
            return null;
          }
        }
        else if (route.isLesson) {
          if (shouldLesson) {
            return (
              <Route
                exact
                path={t(route.route)}
                element={route.component}
                key={route.key}
              />
            );
          }
          else {
            return null;
          }
        }
        else {
          return (
            <Route
              exact
              path={t(route.route)}
              element={route.component}
              key={route.key}
            />
          );
        }
      }

      return null;
    });

  if (data.applicationUpdate) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <Update />
      </ThemeProvider>
    );
  }

  if (loading) {
    return <LoadingComponent />;
  }



  if (isAuthenticated === false) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />

        <Routes>
          {getRoutes(webPageRoutes)}
          {getRoutes(webPageSubRoutes)}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

      </ThemeProvider>
    );
  }


  if (userAuth === "admin")
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="laSolist"
              routes={adminRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}

        <Routes>
          {getRoutes(adminRoutes)}
          {getRoutes(adminSubRoutes)}
          {getRoutes(studentRoutes)}
          {getRoutes(studentSubRoutes)}
          {/* <Route path="*" element={<Navigate to="/dashboards/analytics" />} /> */}
          {/* <Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
      </ThemeProvider>
    );


  if (userAuth === "mucisian")
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="laSolist"
              routes={musicianRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}

        <Routes>
          {getRoutes(musicianRoutes)}
          {/* <Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
      </ThemeProvider>
    );

  if (userAuth === "student" && subscriptionLoaded) {

    if (isTrial) {
      console.log("trialdayız");
      return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>

          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={
                  (transparentSidenav && !darkMode) || whiteSidenav
                    ? brandDark
                    : brandWhite
                }
                brandName="laSolist"
                routes={studentTrialRoutes}
              // onMouseEnter={handleOnMouseEnter}
              // onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}

          <Routes>
            {getRoutes(studentTrialRoutes)}
            {getRoutes(studentSubRoutes)}
            {getRoutes(paymentRoutes)}
            <Route path="*" element={<Navigate to={t("studentDashboardRoute")} />} />
          </Routes>
        </ThemeProvider>
      );
    }

    else if (isSubscribed) {
      return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={
                  (transparentSidenav && !darkMode) || whiteSidenav
                    ? brandDark
                    : brandWhite
                }
                brandName="laSolist"
                routes={studentRoutes}
              // onMouseEnter={handleOnMouseEnter}
              // onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}

          <Routes>
            {getRoutes(studentRoutes)}
            {getRoutes(studentSubRoutes)}
            <Route path="*" element={<Navigate to={t("studentDashboardRoute")} />} />
          </Routes>
        </ThemeProvider>
      );
    }

    else if (!isTrial && !isSubscribed) {
      return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />

          {layout === "vr" && <Configurator />}

          <Routes>
            {getRoutes(paymentRoutes)}
            <Route path="*" element={<Navigate to={t("newSubscriptionRoute")} />} />
          </Routes>
        </ThemeProvider>
      );
    }
  }

  return null;
};

export default App;
