import { Icon, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import React from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setVideoData } from 'store/main/videoPlayerSlice';

function ColorSwitch({ selectSongState }) {
  const dispatch = useDispatch();
  const { videoData, tutorial } = useSelector((state) => state.videoPlayer);
  const { id } = useParams();

  const { details } = useSelector((state) => selectSongState(state, id));

  const videoStart = details && details.videoStart ? details.videoStart : 0;

  const handleChange = (event) => {
    if (videoData.currentTime < videoStart / 1000) {
      dispatch(setVideoData({ ...videoData, isColored: !videoData.isColored, isReturned: true }))
    }
    else {
      dispatch(setVideoData({ ...videoData, isColored: !videoData.isColored }))
    }
  };

  return (
    <MDBox>
      <Tooltip title={"Notaları Renklendir"} placement='bottom'>
        <button
          onClick={handleChange}
          className={"ColorSwitch"}
        >
          <Icon color={videoData.isColored ? "primary" : ""}>format_color_fill</Icon>
        </button>
      </Tooltip>
    </MDBox>
  );
}

export default ColorSwitch