import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setLoopData } from 'store/main/videoPlayerSlice';
import { setControlsData } from 'store/main/videoPlayerSlice';
import { setVideoData } from 'store/main/videoPlayerSlice';
import { endTutorial } from 'store/main/videoPlayerSlice';
import useTutorialSteps from './useTutorialSteps';

const useTutorial = () => {
  const dispatch = useDispatch();
  const { tutorial, videoData, loopData, controlsData } = useSelector(state => state.videoPlayer);

  const [isColoredSkip, setIsColoredSkip] = useState(false);
  const [isLoopSkip, setIsLoopSkip] = useState(false);
  const [isSettingsMenu, setIsSettingMenu] = useState(false);
  const [isQualityMenu, setIsQualityMenu] = useState(false);
  const [isSpeedMenu, setIsSpeedMenu] = useState(false);

  const { allSteps } = useTutorialSteps();

  useEffect(() => {
    if (!tutorial) {
      setState({
        stepIndex: 0,
        steps: allSteps,
      })
    }
  }, [tutorial])

  const [{ stepIndex, steps }, setState] = useState({
    stepIndex: 0,
    steps: allSteps,
  });

  const handleCallback = (data) => {
    const { action, index, status, type } = data;
    console.log(action, index, status, type);
    if (data.action === 'reset') {
      dispatch(endTutorial());
    }

    if (action === "next" && type === "step:after") {
      setState({
        stepIndex: index + 1,
        steps,
      });
    }

    if (action === "prev" && type === "step:after") {
      setState({
        stepIndex: index - 1,
        steps,
      })
    }

    if ((action === "close" && type === "step:after") || (action === "skip" && type === "tour:end") || (type === "tour:end")) {
      setState({
        stepIndex: 0,
        steps,
      })
      setIsColoredSkip(false);
      setIsLoopSkip(false);
      setIsSettingMenu(false);
      setIsQualityMenu(false);
      setIsSpeedMenu(false);
      dispatch(endTutorial());
      dispatch(setVideoData({ isColored: false }));
      dispatch(setLoopData({ isLoop: false }));
      dispatch(setControlsData({ showMenu: false, showQuality: false, showSpeed: false }));
    }
  };

  useEffect(() => {
    const { isColored } = videoData;
    const { isLoop } = loopData;
    const { showMenu, showQuality, showSpeed } = controlsData;
    if (isColored && !isColoredSkip) {
      setState({
        stepIndex: stepIndex + 1,
        steps,
      })
      setIsColoredSkip(true);
    }
    if (!isColored && isColoredSkip) {
      setState({
        stepIndex: stepIndex + 1,
        steps,
      })
      setIsColoredSkip(false);
    }

    if (isLoop && !isLoopSkip) {
      setState({
        stepIndex: stepIndex + 1,
        steps,
      })
      setIsLoopSkip(true);
    }

    if (!isLoop && isLoopSkip) {
      setState({
        stepIndex: stepIndex + 1,
        steps,
      })
      setIsLoopSkip(false);
    }

    if (showMenu && !isSettingsMenu) {
      setState({
        stepIndex: stepIndex + 1,
        steps,
      })
      setIsSettingMenu(true);
    }

    if (showQuality && !isQualityMenu) {
      setState({
        stepIndex: stepIndex + 1,
        steps,
      });
      setIsQualityMenu(true);
    }

    if (!showQuality && isQualityMenu) {
      setTimeout(() => {
        setState({
          stepIndex: stepIndex + 1,
          steps,
        });
      }, [500])
      setIsQualityMenu(false);
    }

    if (showSpeed && !isSpeedMenu) {
      setTimeout(() => {
        setState({
          stepIndex: stepIndex + 1,
          steps,
        });
      }, [500]);
      setIsSpeedMenu(true);
    }

    if (!showSpeed && isSpeedMenu) {
      setState({
        stepIndex: stepIndex + 1,
        steps,
      });
      setIsSpeedMenu(false);
    }
  }, [videoData, isColoredSkip, steps, setState, stepIndex, loopData, isLoopSkip, controlsData, isSettingsMenu, isQualityMenu, isSpeedMenu])

  useEffect(() => {
    if (!tutorial) {
      setState({
        stepIndex: 0,
        steps,
      });
      setIsColoredSkip(false);
      setIsLoopSkip(false);
      setIsQualityMenu(false);
      setIsSettingMenu(false);
    }
  }, [tutorial, steps, setState])

  return {
    handleCallback,
    setState,
    steps,
    stepIndex,
  }
}

export default useTutorial;