import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";




export const getinstructorApplications = createAsyncThunk(
  "instructorApplications/getinstructorApplications",
  async () => {
    const response = await axiosConfig.get(`/api/instructor-applications`);
    // const response = await axios.get(`${proxy}/api/instructors`)

    let { data } = await response.data;
    return data;
  }
);

export const addInstructorApplication = createAsyncThunk(
  "instructorApplications/addInstructorApplication",
  async (instructorApplication, { dispatch, getState }) => {
    try {
      const dataToSend = {
        ...instructorApplication,
        status: "active",
      }
      const response = await axiosConfig.post(`/api/instructor-applications`, dataToSend);
      let { data } = await response.data;

      return data;
    } catch (error) {


      return null;
    }
  }
);



export const updateInstructorApplication = createAsyncThunk(
  "instructorApplications/updateInstructorApplication",
  async (instructorApplication, { dispatch, getState }) => {




    const response = await axiosConfig.put(
      `/api/instructor-applications/${instructorApplication.applicationId}`,
      instructorApplication
    );
    const { data } = await response.data;
    if (response.data.success === true) {

      return data;
    }
    return null;
  }
);

export const removeinstructor = createAsyncThunk(
  "instructors/removeinstructor",
  async (instructorId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/instructor-applications/${instructorId}`);
    if (response.data.success === true) {

      return instructorId;
    }
    return instructorId;
  }
);

const instructorApplicationsAdapter = createEntityAdapter({
  selectId: (instructorApplication) => instructorApplication.applicationId,
});

export const {
  selectAll: selectInstructorApplications,
  selectById: selectInstructorApplicationById
} = instructorApplicationsAdapter.getSelectors((state) => state.instructorApplications);

const instructorsSlice = createSlice({
  name: "instructorApplications",
  initialState: instructorApplicationsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    instructorApplicationDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setinstructorSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewInstructorApplicationDialog: (state, action) => {
      state.instructorApplicationDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewInstructorApplicationDialog: (state, action) => {
      state.instructorApplicationDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditInstructorApplicationDialog: (state, action) => {
      state.instructorApplicationDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditInstructorApplicationDialog: (state, action) => {
      state.instructorApplicationDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateInstructorApplication.fulfilled]: instructorApplicationsAdapter.upsertOne,
    [removeinstructor.fulfilled]: (state, action) =>
      instructorApplicationsAdapter.removeOne(state, action.payload),
    [getinstructorApplications.fulfilled]: instructorApplicationsAdapter.setAll,
    [addInstructorApplication.fulfilled]: instructorApplicationsAdapter.addOne,
  },
});

export const {
  setinstructorSearchText,
  openNewInstructorApplicationDialog,
  closeNewInstructorApplicationDialog,
  openEditInstructorApplicationDialog,
  closeEditInstructorApplicationDialog,
  openConfirmDialog,
  closeConfirmDialog,
  openResetPasswordDialog,
  closeResetPasswordDialog,
} = instructorsSlice.actions;

export default instructorsSlice.reducer;
