import AdminLayout from 'pages/main/layout/AdminLayout'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getSettings } from 'store/main/settingsSlice';
import LoadingComponent from 'components/Loading';
import SettingsTable from './SettingsTable';
import SettingsDialog from './SettingsDialog';

function SettingsDashboard() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getSettings()).then(() => setLoading(false));
  }, [dispatch]);

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <SettingsTable />
      <SettingsDialog />
    </AdminLayout>
  )
}

export default SettingsDashboard