import { Card, Container, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import Features from "./components/Features";
import { useTranslation } from "react-i18next";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Link } from "react-router-dom";
import CoverLayout from "pages/main/layout/CoverLayout";
import LandingLayout from "pages/main/layout/LandingLayout";

function FeatureMain() {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");

  return (
    <LandingLayout>
      <Container>
        <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginX: 2, marginY: 4, textAlign: "center" }}>
          <MDTypography fontSize={{ xs: "1.728rem", lg: "2.074rem", xl: "2.488rem" }} fontWeight="bold" mb={1}>
            {t("call_main")}
          </MDTypography>

          <MDTypography fontSize={{ xs: "1rem", lg: "1.20rem", xl: "1.44rem" }} mb={1} variant="body">
            {t("call_alt")}
          </MDTypography>

          <Grid container justifyContent={"center"} alignItems={"center"} mb={5} spacing={2}>
            <Grid item xs={12} lg={4} xl={3} xxl={2}>
              <MDInput
                type="email"
                label={t("email")}
                fullWidth
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  sx: {
                    fontSize: "1.0rem",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <Link to={email ? `/sign-up/${email}` : "/sign-up"}>
                <MDButton sx={{ width: "100%" }} size="large" variant="contained" color="info">
                  {t("sign_up")}
                </MDButton>
              </Link>
            </Grid>
          </Grid>

          <div
            style={{
              width: "300px",
              height: "8px",
              backgroundColor: "#344767",
              borderRadius: "4px",
            }}
          />

          <Features />
          <MDTypography fontSize={{ xs: "1rem", lg: "1.20rem", xl: "1.44rem" }} mt={5} variant="body">
            {t("call_alt_2")}
          </MDTypography>
        </MDBox>
      </Container>
    </LandingLayout>
  );
}

export default FeatureMain;
