import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ImageUpload from "components/UploadComponents/ImageUpload";
import { imageProxy } from "features/api/proxy";
import { Grid } from "@mui/material";
import { closeEditUserDialog } from "store/main/usersSlice";
import { closeNewUserDialog } from "store/main/usersSlice";
import { updateUser } from "store/main/usersSlice";
import { addUser } from "store/main/usersSlice";

const defaultFormState = {
  userName: "",
  name: "",
  password: "",
  confirmPassword: "",
  userType: "student",
  status: "active",
  url: "",
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
  { id: "waiting", name: "waiting" },
];

const userTypeOptions = [
  { id: "admin", name: "admin" },
  { id: "student", name: "student" },
  { id: "musician", name: "musician" },
  { id: "instructor", name: "instructor" },
]

function UserDialog(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const userDialog = useSelector(
    ({ users }) => users.userDialog
  );

  const [photo, setphoto] = useState();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });

  const [userTypeValue, setUserTypeValue] = useState({
    id: "student",
    name: "student",
  })

  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (userDialog.type === "edit" && userDialog.data) {
      setForm({ ...userDialog.data });
      fetch(`${imageProxy}/${userDialog.data.url}`)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], userDialog.data.url, { type: blob.type });
          setphoto(file);
        })
        .catch(error => {
          console.error('Dosya bulunamadı:', error);
        });
      let status = statusOptions.find(
        (f) => f.name === userDialog.data.status
      );
      let userType = userTypeOptions.find(
        (f) => f.name === userDialog.data.userType
      );
      setStatusValue(status);
      setUserTypeValue(userType);
    }

    /**
     * Dialog type: 'new'
     */
    if (userDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...userDialog.data,
      });
      setphoto();
      let status = statusOptions.find(
        (f) => f.name === defaultFormState.status
      );
      let userType = userTypeOptions.find(
        (f) => f.name === defaultFormState.userType
      );
      setStatusValue(status);
      setUserTypeValue(userType);
    }
  }, [userDialog.data, userDialog.type, setForm]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (userDialog.props.open) {
      initDialog();
    }
  }, [userDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return userDialog.type === "edit"
      ? dispatch(closeEditUserDialog())
      : dispatch(closeNewUserDialog());
  }

  const handleSetImage = (file) => {
    setphoto(file);
    if (!file) {
      setInForm("url", null);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (userDialog.type === "new") {
      let data = {
        ...form,
        status: statusValues.id,
        userType: userTypeValue.name,
        url: photo,
      };
      setphoto();
      dispatch(addUser(data));
    } else {
      let data = {
        ...form,
        status: statusValues.id,
        userType: userTypeValue.name,
        url: photo,
      };
      setphoto();
      dispatch(updateUser(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    // userName: "",
    // name: "",
    // password: "",
    // confirmPassword: "",
    // userType: "",
    // status: "active",
    // url: "",
    return (
      form.userName.length > 0 && form.name.length > 0 &&
      form.password === form.confirmPassword &&
      photo !== undefined
    );
  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...userDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">{t("instrumentInfo")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="userName"
          name="userName"
          label={t("email")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.userName}
          onChange={handleChange}
          required
          error={form.userName ? false : true}
          helperText={t("enterEmail")}
          focused
          disabled={userDialog.type === "edit" ? true : false}
        />

        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label={t("name")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.name}
          onChange={handleChange}
          required
          error={form.name ? false : true}
          helperText={t("enterName")}
          focused
          disabled={userDialog.type === "edit" ? true : false}
        />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              id="password"
              name="password"
              label={t("password")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.password}
              onChange={handleChange}
              required
              error={form.password ? false : true}
              helperText={t("enterPassword")}
              focused
              disabled={userDialog.type === "edit" ? true : false}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              id="confirmPassword"
              name="confirmPassword"
              label={t("confirmPassword")}
              type="text"
              fullWidth
              variant="outlined"
              value={form.confirmPassword}
              onChange={handleChange}
              required
              error={form.confirmPassword ? false : true}
              helperText={t("enterConfirmPassword")}
              focused
              disabled={userDialog.type === "edit" ? true : false}
            />
          </Grid>
        </Grid>


        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                value={statusValues} // Dikkat: statusValues -> statusValue olarak değiştirildi
                options={statusOptions}
                getOptionLabel={(option) => option.name}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                // fullWidth
                renderInput={(params) => (
                  <TextField {...params} label={t("status")} />
                )}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                    setStatusValue({
                      id: newValue, // newValue.id -> newValue olarak değiştirildi
                      name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    setStatusValue({
                      name: newValue.inputValue,
                    });
                  } else {
                    setStatusValue(newValue);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                value={userTypeValue} // Dikkat: statusValues -> statusValue olarak değiştirildi
                options={userTypeOptions}
                getOptionLabel={(option) => option.name}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                // fullWidth
                renderInput={(params) => (
                  <TextField {...params} label={t("userType")} />
                )}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") { // newValue.name -> newValue olarak değiştirildi
                    setUserTypeValue({
                      id: newValue, // newValue.id -> newValue olarak değiştirildi
                      name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    setUserTypeValue({
                      name: newValue.inputValue,
                    });
                  } else {
                    setUserTypeValue(newValue);
                  }
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <ImageUpload
            handleSetImage={handleSetImage}
            file={photo}
            url={form && form.url ? `${imageProxy}/${form.url}` : null}
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserDialog;
