import React from 'react'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import ImageUpload from 'components/UploadComponents/ImageUpload';
import { imageProxy } from "features/api/proxy";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  borderRadius: "10px",
  '&:before': {
    display: 'none',
  },
  marginBottom: "10px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon color="info" sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: "10px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



function PackageInformation({
  expanded,
  handleExpand,
  form,
  handleChange,
  photo,
  handleSetImage,
  statusOptions,
  statusValues,
  setStatusValue,
  instrumentValue,
  instruments,
  setInstrumentValue,
  musicianValue,
  musicians,
  setMusicianValue
}) {

  const { t } = useTranslation();



  return (
    <Accordion expanded={expanded === 'panel1'} onChange={handleExpand('panel1')}>
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography variant="h5">
          {t("lessonInfo")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          autoFocus
          margin="dense"
          id="lessonId"
          name="lessonId"
          label={t("lessonId")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.lessonId}
          disabled
        />

        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                value={instrumentValue}
                options={instruments}
                getOptionLabel={(option) => option.name}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderInput={(params) => (
                  <TextField {...params} label={t("instrumentType")} />
                )}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setInstrumentValue({
                      id: newValue,
                      name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    setInstrumentValue({
                      name: newValue.inputValue,
                    });
                  } else {
                    setInstrumentValue(newValue);
                  }
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                value={musicianValue}
                options={musicians}
                getOptionLabel={(option) => option.name}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderInput={(params) => (
                  <TextField {...params} label={t("musicianName")} />
                )}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setMusicianValue({
                      id: newValue,
                      name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    setMusicianValue({
                      name: newValue.inputValue,
                    });
                  } else {
                    setMusicianValue(newValue);
                  }
                }}
              />
            </Grid>
          </Grid>

        </MDBox>

        <MDBox mt={2}>
          <Autocomplete
            value={statusValues}
            options={statusOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("status")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue.name === "string") {
                setStatusValue({
                  id: newValue.id,
                  name: newValue.name,
                });
              } else if (newValue && newValue.inputValue) {
                setStatusValue({
                  name: newValue.inputValue,
                });
              } else {
                setStatusValue(newValue);
              }
              console.log(newValue);
            }}
          />
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="lessonNameTr"
                name="lessonNameTr"
                label={t("lessonNameTr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonNameTr}
                onChange={handleChange}
                required
                error={form.lessonNameTr ? false : true}
                helperText={t("enterLessonNameTr")}
                focused
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="lessonDescriptionTr"
                name="lessonDescriptionTr"
                label={t("lessonDescriptionTr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonDescriptionTr}
                onChange={handleChange}
                required
                error={form.lessonDescriptionTr ? false : true}
                helperText={t("enterLessonDescriptionTr")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="lessonNameEn"
                name="lessonNameEn"
                label={t("lessonNameEn")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonNameEn}
                onChange={handleChange}
                required
                error={form.lessonNameEn ? false : true}
                helperText={t("enterLessonNameEn")}
                focused
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="lessonDescriptionEn"
                name="lessonDescriptionEn"
                label={t("lessonDescriptionEn")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonDescriptionEn}
                onChange={handleChange}
                required
                error={form.lessonDescriptionEn ? false : true}
                helperText={t("enterLessonDescriptionEn")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="lessonNameDe"
                name="lessonNameDe"
                label={t("lessonNameDe")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonNameDe}
                onChange={handleChange}
                required
                error={form.lessonNameDe ? false : true}
                helperText={t("enterLessonNameDe")}
                focused
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="lessonDescriptionDe"
                name="lessonDescriptionDe"
                label={t("lessonDescriptionDe")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonDescriptionDe}
                onChange={handleChange}
                required
                error={form.lessonDescriptionDe ? false : true}
                helperText={t("enterLessonDescriptionDe")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="lessonNameAr"
                name="lessonNameAr"
                label={t("lessonNameAr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonNameAr}
                onChange={handleChange}
                required
                error={form.lessonNameAr ? false : true}
                helperText={t("enterLessonNameAr")}
                focused
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="lessonDescriptionAr"
                name="lessonDescriptionAr"
                label={t("lessonDescriptionAr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.lessonDescriptionAr}
                onChange={handleChange}
                required
                error={form.lessonDescriptionAr ? false : true}
                helperText={t("enterLessonDescriptionAr")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="level"
                name="level"
                label={t("level")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.level}
                onChange={handleChange}
                required
                error={form.level ? false : true}
                helperText={t("enterLevel")}
                focused
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="duration"
                name="duration"
                label={t("duration")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.duration}
                onChange={handleChange}
                required
                error={form.duration ? false : true}
                helperText={t("enterDuration")}
                focused
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <TextField
            autoFocus
            margin="dense"
            id="folderNumber"
            name="folderNumber"
            label={t("folderNumber")}
            type="text"
            fullWidth
            variant="outlined"
            value={form.folderNumber}
            onChange={handleChange}
            required
            error={form.folderNumber ? false : true}
            helperText={t("enterFolderNumber")}
            focused
          />
        </MDBox>
        <MDBox mt={2}>
          <ImageUpload
            handleSetImage={handleSetImage}
            file={photo}
            url={form && form.url ? `${imageProxy}/${form.url}` : null}
          />
        </MDBox>
      </AccordionDetails>
    </Accordion>
  )
}

export default PackageInformation