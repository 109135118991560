import React from "react";
import StudentLayout from "pages/main/layout/StudentLayout";
import CoursesList from "./CoursesList";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { getCategories } from "store/main/categoriesSlice";
import { getInstruments } from "store/main/instrumentsSlice";
import { useEffect } from "react";
import LoadingComponent from "components/Loading";
import { getCourses } from "store/main/coursesSlice";
import CoursesSearchBar from "./CoursesSearchBar";

const CoursesDashboard = (props) => {

  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [instrumentsLoading, setInstrumentsLoading] = useState(true);

  useEffect(() => {
    dispatch(getCourses()).then(() => setDataLoading(false));
    dispatch(getCategories()).then(() => setCategoriesLoading(false));
    dispatch(getInstruments()).then(() => setInstrumentsLoading(false));
  }, [dispatch]);

  if (dataLoading || categoriesLoading || instrumentsLoading) {
    return <LoadingComponent />;
  }

  return (
    <StudentLayout>
      <CoursesSearchBar />
      <CoursesList />
    </StudentLayout>
  );
};

CoursesDashboard.propTypes = {};

export default CoursesDashboard;
