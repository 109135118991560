
import MDBox from 'components/MDBox'
import React from 'react'
import ReturnStartButton from 'components/Controls/Buttons/ReturnStartButton';
import RewindButton from 'components/Controls/Buttons/RewindButton';
import PlayPauseButton from 'components/Controls/Buttons/PlayPauseButton';
import ForwardButton from 'components/Controls/Buttons/ForwardButton';
import LoopButton from 'components/Controls/Buttons/LoopButton';
import FullScreenButton from 'components/Controls/Buttons/FullScreenButton';
import ColorSwitch from 'components/Controls/Buttons/ColorSwitch';
import Progress from 'components/Controls/Progress/Progress';
import SettingsButton from 'components/Controls/Menu/Settings/SettingsButton';
import VolumeButton from 'components/Controls/Buttons/VolumeButton/VolumeButton';
import { usePlayer } from 'hooks/PlayerProvider';
import NoteSheetButton from './Buttons/NoteSheetButton/NoteSheetButton';

function VideoControls({ fullScreenHandler, selectSongState }) {

  const {
    togglePlay,
    handleForward,
    handleRewind,
    setVolume,
    handleReturned,
    handleProgressClick,
    handleLoop,
  } = usePlayer()


  return (
    <MDBox width={"100%"}>
      <div
        className={"ControlsContainerV2"}
      >

        <Progress handleProgressClick={handleProgressClick} selectSongState={selectSongState} />

        <MDBox display={"flex"} justifyContent={"space-between"} width={"100%"}>
          <MDBox display={"flex"} gap={1}>
            <ReturnStartButton handleReturned={handleReturned} />
            <RewindButton handleRewind={handleRewind} />
            <PlayPauseButton handleClick={togglePlay} />
            <ForwardButton handleForward={handleForward} />
            <VolumeButton setVolume={setVolume} />
            
          </MDBox>

          <MDBox display={"flex"} gap={1}>
            <NoteSheetButton />
            <ColorSwitch selectSongState={selectSongState} />
            {/* <SettingsWrapper field={"loopButton"} Component={LoopButton} handleClick={handleLoop} /> */}
            <LoopButton handleClick={handleLoop} />
            <SettingsButton />
            <FullScreenButton handle={fullScreenHandler} />
          </MDBox>
        </MDBox>
      </div>
    </MDBox>
  )
}

export default VideoControls