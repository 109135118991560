import { Icon } from '@mui/material'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setControlsData } from 'store/main/videoPlayerSlice';
import { setVideoData } from 'store/main/videoPlayerSlice';

function MuteButton() {
  const dispatch = useDispatch();
  const { videoData, controlsData } = useSelector((state) => state.videoPlayer);

  const handleMouseEnter = () => {
    dispatch(setControlsData({...controlsData, showVolume: true}));
  }

  const handleMute = () => {
    if (videoData.volume > 0) {
      dispatch(setVideoData({ ...videoData, volume: 0 }));
    }
    else {
      dispatch(setVideoData({ ...videoData, volume: 1 }));
    }
  }

  return (
    <button onClick={handleMute} onMouseEnter={handleMouseEnter}>
      {
        videoData.volume > 0 ? <Icon color="white">volume_up</Icon> : <Icon color="white">volume_mute</Icon>
      }
    </button>
  )
}

export default MuteButton