/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useParams } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
// Image
//import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import bgImage from "assets/images/laSolist_player_01.jpeg";
import Checkbox from "@mui/material/Checkbox";
//translation
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { registerStudent } from "store/auth/authSlice";
import { useForm } from "features/hooks";
import { useEffect, useRef } from "react";
import { Backdrop, Card, CircularProgress, Collapse, FormControl, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { useState } from "react";
import ConfirmEmail from "../ConfirmEmail";
import AuthCoverLayout from "pages/main/layout/AuthCoverLayout";
import RequirementsItem from "components/PasswordPopper/RequirementsItem";
import TermsConditionsDialog from "./components/TermsConditionsDialog";
import showToast from "hooks/useCustomToast";


const defaultFormState = {
  email: "",
  surname: "",
  password: "",
  confirmPassword: "",
  name: "",
  terms_conditions: false,
}

const passwordRequirements = [
  { name: "min_characters", regex: /^.{6,}$/ },
  { name: "special_characters", regex: /[-!$%^&*()_+|~=`{}\[\]:";'<>?,./\\]/ },
  { name: "one_number", regex: /\d/ },
  { name: "one_letter", regex: /[a-zA-Z]/ },
];

function SignUp() {
  const dispatch = useDispatch();
  const { email } = useParams();
  const { form, handleChange, setInForm } = useForm(defaultFormState);
  const { t, i18n } = useTranslation();

  const [termsCondition, setTermsCondition] = useState(false);

  useEffect(() => {
    if (email) {
      setInForm("email", email);
    }
  }, [email, setInForm])

  function isUniversityEmail(email) {
    // E-posta uzantısını al
    const emailParts = email.split("@");
    if (emailParts.length === 2) {
      const domain = emailParts[1].split(".");

      // Üniversiteye özgü e-posta uzantısını kontrol et
      if (domain[1] === "edu") {
        return true; // Üniversite öğrencisine ait
      }
    }

    return false; // Üniversite öğrencisi değil
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsWaiting(true);

    const data = {
      ...form,
      status: "active",
      userType: "student",
      userName: form.email,
      locale: i18n.language,
      isStudent: isUniversityEmail(form.email),
    }
    dispatch(registerStudent(data))
      .then((params) => {
        console.log(params.payload);
        try {
          if (params.payload.success && params.payload.status === "waiting") {
            console.log("buraya girdik la");
            setOpen(true);
            setIsWaiting(false);
          }
          showToast("check_circle_icon", "registered_successfully", "success");
        } catch (error) {
          setOpen(false);
          setIsWaiting(false);

          showToast("error", "username_already_in_use", "error");
        }

      });
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isValidEmail = (text) => {
    // Basit bir e-posta regex'i
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(text);
  };

  function canBeSubmitted() {
    return (
      form.email.length > 0 &&
      form.name.length > 0 &&
      form.password.length > 0 &&
      form.confirmPassword.length > 0 &&
      (form.password === form.confirmPassword) &&
      form.terms_conditions) && isValidEmail(form.email) &&
      passwordRequirements[0].regex.test(form.password) &&
      passwordRequirements[1].regex.test(form.password) &&
      passwordRequirements[2].regex.test(form.password) &&
      passwordRequirements[3].regex.test(form.password);
  }

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [isWaiting, setIsWaiting] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null)
  const [passwordOpen, setPasswordOpen] = useState(false);

  const passwordRef = useRef(null);

  useEffect(() => {
    // Kontrolleri gerçekleştir
    const areRequirementsMet = passwordRequirements.every(item => item.regex.test(form.password));
    const passwordsMatch = form.password.length > 0 && form.password === form.confirmPassword;

    // Gereksinimlerin tamamı karşılanırsa ve parolalar eşleşiyorsa onClose fonksiyonunu çağır
    if (form.password.length === 0) {
      setPasswordOpen(false);
      setAnchorEl(null);
    }
    else if (areRequirementsMet && passwordsMatch) {
      setPasswordOpen(false);
      onClose();
    }
    else {
      setPasswordOpen(true);
      setAnchorEl(passwordRef.current);
    }
  }, [form.password, form.confirmPassword])

  const onClose = () => {
    setAnchorEl(null);
    setPasswordOpen(false);
  }

  const handleOpenPasswordPopover = () => {
    setAnchorEl(passwordRef.current);
    setPasswordOpen(true);
  }

  return (
    <AuthCoverLayout
      image={bgImage}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isWaiting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <ConfirmEmail handleClose={handleClose} form={form} />
      </Backdrop>
      <Card sx={{ maxWidth: "450px", mx: "auto" }}>
        <MDBox p={3} textAlign="center">
          <MDBox mb={1} textAlign="center">
            <MDTypography variant="h4" fontWeight="bold">
              {t("sign_up_header")}
            </MDTypography>
          </MDBox>
          <MDTypography variant="body2" color="text">
            {t("sign_up_message")}
          </MDTypography>
        </MDBox>
        <MDBox p={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    fontFamily: "Outfit",
                    fontWeight: 500,
                    fontSize: "16px",
                  },
                  '& .MuiInputLabel-root': {
                    fontFamily: "Outfit",
                    fontWeight: 500,
                    fontSize: "16px",
                  }
                }}
                variant="outlined"
                type="text"
                name="email"
                id="email"
                label={t("email")}
                value={form.email}
                onChange={handleChange}
                fullWidth
                error={form.email.length > 0 && !isValidEmail(form.email)}
                helperText={form.email.length > 0 && !isValidEmail(form.email) && "enterValidEmail"}
              />
            </MDBox>
            <MDBox mb={2}>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    fontFamily: "Outfit",
                    fontWeight: 500,
                    fontSize: "16px",
                  },
                  '& .MuiInputLabel-root': {
                    fontFamily: "Outfit",
                    fontWeight: 500,
                    fontSize: "16px",
                  }
                }}
                type="text"
                name="name"
                id="name"
                label={t("name")}
                value={form.name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    fontFamily: "Outfit",
                    fontWeight: 500,
                    fontSize: "16px",
                  },
                  '& .MuiInputLabel-root': {
                    fontFamily: "Outfit",
                    fontWeight: 500,
                    fontSize: "16px",
                  }
                }}
                type="text"
                name="surname"
                id="surname"
                label={t("surname")}
                value={form.surname}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="password">
                  {t("password")}
                </InputLabel>
                <OutlinedInput
                  onClick={handleOpenPasswordPopover}
                  id="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("password")}
                />
              </FormControl>
            </MDBox>

            {/* <PasswordPopper anchorEl={anchorEl} onOpen={passwordOpen} onClose={onClose} password={form.password} confirmPassword={form.confirmPassword} /> */}

            <MDBox mb={2}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="password">
                  {t("confirmPassword")}
                </InputLabel>
                <OutlinedInput
                  ref={passwordRef}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={form.confirmPassword}
                  onChange={handleChange}
                  onClick={handleOpenPasswordPopover}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("confirmPassword")}
                />

              </FormControl>
            </MDBox>

            <Collapse in={passwordOpen}>
              <MDBox sx={{ mb: 2, mx: 1 }}>
                {
                  passwordRequirements.map((item, key) => {
                    return <RequirementsItem key={key} name={item.name} regex={item.regex.test(form.password)} />
                  })
                }
                <RequirementsItem name={"passwordDoesntMatch"} regex={form.password.length > 0 && form.password === form.confirmPassword} />
              </MDBox>
            </Collapse>

            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                name="terms_conditions"
                id="terms_conditions"
                checked={form.terms_conditions}
                onChange={handleChange} />
              <MDTypography
                // component={Link}
                // to={`${t("userAgreementRoute")}`}
                onClick={() => setTermsCondition(true)}
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                {t("i_agree_terms_and_conditions")}
              </MDTypography>
            </MDBox>

            <TermsConditionsDialog open={termsCondition} onClose={() => setTermsCondition(false)} setInForm={setInForm} />
            <MDBox mt={4} mb={1}>
              <MDButton
                disabled={!canBeSubmitted()}
                onClick={handleSubmit}
                variant="gradient"
                color="info"
                fullWidth>
                {t("sign_up")}
              </MDButton>
            </MDBox>
            <MDBox display={"flex"} flexDirection={{ xs: "column", md: "row" }} mt={3} mb={1} justifyContent="center" textAlign="center">
              <MDTypography variant="button" color="text" >
                {t("already_have_account")}&nbsp;
              </MDTypography>

              <MDTypography
                component={Link}
                to="/sign-in"
                variant="button"
                color="info"
                fontWeight="bold"
                textGradient
              >
                {t("sign_in")}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </AuthCoverLayout>
  );
}

export default SignUp;
