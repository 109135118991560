import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../features/api/axiosConfig";

export const addSubscription = createAsyncThunk(
  "paypalSubscriptions/addSubscription",
  async (subscription, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(
        `/api/paypal/subscriptions/`,
        subscription,
      );

      const data = await response.data;
      return data.id;
    } catch (error) {

      return null;
    }
  }
);

export const cancelPaypalSubscription = createAsyncThunk(
  "paypalSubscriptions/cancelPaypalSubscription",
  async (subscription, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(
        `/api/paypal/subscriptions/cancel`,
        subscription,
      );

      const data = await response.data;
      return data;
    } catch (error) {
      
    }
  }
);


const paypalSubscriptionsSlice = createSlice({
  name: "paypalSubscriptions",
  initialState: {
    id: "",
    searchText: "",
    routeParams: {},
    subscriptionDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  },
  reducers: {
    setSubscriptionSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewSubscriptionDialog: (state, action) => {
      state.subscriptionDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewSubscriptionDialog: (state, action) => {
      state.subscriptionDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditSubscriptionDialog: (state, action) => {
      state.subscriptionDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditSubscriptionDialog: (state, action) => {
      state.subscriptionDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [addSubscription.fulfilled]: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const {
  setSubscriptionSearchText,
  openNewSubscriptionDialog,
  closeNewSubscriptionDialog,
  openEditSubscriptionDialog,
  closeEditSubscriptionDialog,
  openConfirmDialog,
  closeConfirmDialog,
} = paypalSubscriptionsSlice.actions;

export default paypalSubscriptionsSlice.reducer;
