import React, { useState } from "react";

// Layout

// Images
import bgImage from "assets/images/laSolist_player_01.jpeg";
import { Card, Container, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import CoverLayout from "pages/main/layout/CoverLayout";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

function Hero() {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  return (
    <CoverLayout image={bgImage} coverHeight={"calc(100vh - 2rem)"}>
      <Container>
        <Card
          sx={{
            textAlign: "center",
            background: "rgba(255,255,255, .90)",
            padding: "20px",
            position: "bottom",
          }}
        >
          <MDTypography
            fontSize={{ xs: "1.728rem", lg: "2.074rem", xl: "2.488rem" }}
            fontWeight="bold"
            lineHeight={1.6}
            mb={1}
          >
            {t("feature_main")}
          </MDTypography>

          <MDTypography
            fontWeight={"400"}
            fontSize={{ xs: "1rem", lg: "1.20rem", xl: "1.44rem" }}
            mb={2}
            mx={2}
          >
            {t("feature_alt")}
          </MDTypography>


          <Grid container justifyContent={"center"} alignItems={"center"} mb={1} spacing={2}>
            <Grid item xs={12} lg={4} xl={3} xxl={3}>
              <MDInput
                type="email"
                label={t("email")}
                fullWidth
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  sx: {
                    fontSize: "1rem",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <Link to={email ? `/sign-up/${email}` : "/sign-up"}>
                <MDButton sx={{ width: "100%" }} size={"large"} variant="contained" color="info">
                  {t("sign_up")}
                </MDButton>
              </Link>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </CoverLayout>
  );
}

export default Hero;