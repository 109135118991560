import { Card } from '@mui/material'
import MDBox from 'components/MDBox'
import React from 'react'

function LandingLayout({ children }) {
  return (
    <MDBox sx={{ minHeight: "100vh" }}>
      <Card sx={{ margin: 2, minHeight: "calc(100vh - 2rem)", width: "calc(100vw - 3rem)", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {children}
      </Card>
    </MDBox>
  )
}

export default LandingLayout