import { Icon } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FavoriteButton from './FavoriteButton'

function Description(props) {

  const { title, description, date, details } = props;

  console.log(props)

  const { t } = useTranslation();

  const renderTitle = (title) => {
    if (title.match("(A)")) {
      return title.replace("(A)", `(${t("beginner")})`)
    }
    else if (title.match("(B)")) {
      return title.replace("(B)", `(${t("advanced")})`)
    }
    else {
      return title
    }
  }

  const renderLevelBg = {
    "beginner": "info",
    "intermediate": "warning",
    "advanced": "error",
  }

  return (
    <MDBox sx={{ display: "flex", gap: 1, flex: 1 }}>
      <MDBox shadow="lg" borderRadius="lg" variant="gradient" bgColor={details.level ? renderLevelBg[details.level] : "info"} sx={{ width: 4, height: "full" }}></MDBox>

      <MDBox sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <MDBox sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <MDBox>
            <MDTypography
              fontSize={{ xs: "1.1rem", lg: "1.2rem", xl: "1.3rem" }}
              display="inline"
              variant="h4"
              textTransform="capitalize"
              fontWeight="bold"
            >
              {renderTitle(title)}
            </MDTypography>

            <MDTypography
              fontSize={{ xs: "0.9rem", lg: "1.0rem", xl: "1.1rem" }}
              variant="body2"
              component="p"
              color="text"
            >
              {description}
            </MDTypography>
          </MDBox>

          {details.type === "song" && <FavoriteButton {...props} />}
        </MDBox>

        <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
          <MDTypography
            fontSize={{ xs: "0.9rem", lg: "1.0rem", xl: "1.1rem" }}
            variant="body2"
            component="p"
            color="text"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <Icon>calendar_month</Icon>
            {dayjs(date).format("DD.MM.YYYY")}
          </MDTypography>
        </MDBox>
      </MDBox>


    </MDBox>
  )
}

export default Description