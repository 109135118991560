/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Image
// import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import bgImage from "assets/images/laSolist_player_01.jpeg";

//translation
import { useTranslation } from "react-i18next";

import { useForm } from "features/hooks";
import { login } from "store/auth/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Backdrop, Card, FormControl, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { checkSubscription } from "store/payment/paymentsSlice";
import AuthCoverLayout from "pages/main/layout/AuthCoverLayout";
import ConfirmEmail from "./ConfirmEmail";

const defaultFormState = {
  email: "",
  password: "",
  userAgent: navigator.userAgent,
  browserWidth: window.innerWidth,
  browserHeight: window.innerHeight,
  deviceWidth: window.screen.width,
  deviceHeight: window.screen.height,
  devicePixelRatio: window.devicePixelRatio,
};

function SignIn() {

  const location = useSelector(state => state.location);
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const { form, handleChange } = useForm(defaultFormState);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [error, setError] = useState({
    response: false,
    status: "success",
    message: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError({
      response: false,
      status: "",
      message: "",
    });
  };

  const [open, setOpen] = useState(false);
  const handleCloseBackdrop = () => {
    setOpen(false);
  };


  const handleLogin = (e) => {
    e.preventDefault();
    setIsWaiting(true);

    let data = {
      ...form,
      ...location,
      userName: form.email,
    }

    dispatch(login(data)).then((params) => {
      try {
        console.log("params", params.payload.isAuthenticated);
        setTimeout(() => setIsWaiting(false), 1000);
        const {
          isAuthenticated,
          userName,
          userAuth,
          isWaiting,
        } = params.payload;


        if (isAuthenticated && userAuth === "student") {
          dispatch(checkSubscription({ userName }));
          navigate(t("studentDashboardRoute"))
        }
        else if (!isAuthenticated && isWaiting) {
          setOpen(true)
        }
        else if (userAuth === "admin") {
          navigate("/dashboard/analytics")
        }
      } catch (error) {
        setIsWaiting(false);
        setOpen(false);
      }
    });
  };


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  function canBeSubmitted() {
    return (
      form.email.length > 0 && form.password.length > 0
    );
  }

  return (
    <AuthCoverLayout
      image={bgImage}
    >
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isWaiting}
      >
        <LoadingComponent />
      </Backdrop> */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <ConfirmEmail handleClose={handleCloseBackdrop} form={form} />
      </Backdrop>
      <Card sx={{ maxWidth: "450px", mx: "auto" }}>
        <MDBox p={3} textAlign="center">
          <MDBox mb={1} textAlign="center">
            <MDTypography variant="h4" fontWeight="bold">
              {t("sign_in_header")}
            </MDTypography>
          </MDBox>
          <MDTypography variant="body2" color="text">
            {t("sign_in_message")}
          </MDTypography>
        </MDBox>
        <MDBox p={3}>
          {/* <Snackbar
            open={error.response}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
            <Alert variant="filled" onClose={handleClose} severity={error.status} sx={{ width: '100%' }}>
              {t(error.message)}
            </Alert>
          </Snackbar> */}
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <TextField
                variant="outlined"
                type="email"
                label={t("email")}
                fullWidth
                value={form.email}
                onChange={handleChange}
                id="email"
                name="email"
              />
            </MDBox>
            <MDBox mb={2}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel
                  htmlFor="password">
                  {t("password")}
                </InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("password")}
                />

              </FormControl>
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" justifyContent={"space-between"} ml={-1}>
          <MDBox>
            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
            >
              &nbsp;&nbsp;{t("remember_me")}
            </MDTypography>
          </MDBox>
        </MDBox> */}

            <MDBox mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                size="large"
                fullWidth
                onClick={handleLogin}
                disabled={!canBeSubmitted()}
              >
                {t("sign_in")}
              </MDButton>
            </MDBox>
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                {t("dont_have_account")}&nbsp;
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {t("sign_up")}
                </MDTypography>
              </MDTypography>
            </MDBox>

            <MDBox textAlign="center">
              <MDTypography
                component={Link}
                to={t("forgotPasswordRoute")}
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                {t("forgot_password")}
              </MDTypography>
            </MDBox>


          </MDBox>
        </MDBox>
      </Card>
    </AuthCoverLayout>
  );
}

export default SignIn;
