import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import { useForm } from "features/hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ImageUpload from "components/UploadComponents/ImageUpload";
import { imageProxy } from "features/api/proxy";
import { useTranslation } from "react-i18next";
import { selectcomposers } from "store/main/composersSlice";
import {
  addSong,
  closeEditSongDialog,
  closeNewSongDialog,
  updateSong,
} from "store/main/songsSlice";
import { selectcategories } from "store/main/categoriesSlice";
import { Checkbox, FormControlLabel, FormHelperText, Grid } from "@mui/material";
const defaultFormState = {
  songId: 0,
  songName: "",
  songDescriptionTr: "",
  songDescriptionEn: "",
  songDescriptionDe: "",
  songDescriptionAr: "",
  composerId: 0,
  status: "active",
  url: "",
  isTrial: false,
  songCategoryData: [],
};

const statusOptions = [
  { id: "active", name: "active" },
  { id: "passive", name: "passive" },
];

function SongDialog(props) {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const songDialog = useSelector(({ songs }) => songs.songDialog);
  const composers = useSelector(selectcomposers);
  const categories = useSelector(selectcategories);

  const [photo, setphoto] = useState();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [statusValues, setStatusValue] = useState({
    id: "active",
    name: "active",
  });
  const [composerIdValue, setcomposerIdValue] = useState({
    id: 0,
    name: "",
  });
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (songDialog.type === "edit" && songDialog.data) {
      setForm({ ...songDialog.data });
      let composer = composers.find(
        (f) => f.composerId === songDialog.data.composerId
      );
      let status = statusOptions.find(
        (f) => f.name === songDialog.data.status
      );
      setcomposerIdValue(composer);
      setStatusValue(status);
      fetch(`${imageProxy}/${songDialog.data.url}`)
        .then(response => response.blob())
        .then(blob => {
          console.log(blob);
          const file = new File([blob], songDialog.data.url, { type: blob.type });
          setphoto(file);
        })
        .catch(error => {
          console.error('Dosya bulunamadı:', error);
        });
    }

    /**
     * Dialog type: 'new'
     */
    if (songDialog.type === "new") {
      setForm({
        ...defaultFormState,
        ...songDialog.data,
      });

      setphoto();
      setcomposerIdValue();
    }
  }, [songDialog.data, songDialog.type, setForm, composers]);

  useEffect(() => {
    /**
     * After Dialog Open
     */
    if (songDialog.props.open) {
      initDialog();
    }
  }, [songDialog.props.open, initDialog]);

  function closeComposeDialog() {
    return songDialog.type === "edit"
      ? dispatch(closeEditSongDialog())
      : dispatch(closeNewSongDialog());
  }

  const handleSetImage = (file) => {
    setphoto(file);
    if (!file) {
      setInForm("url", null);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();

    console.log(form);

    if (songDialog.type === "new") {
      let data = {
        ...form,
        status: statusValues.id,
        composerId: composerIdValue.id,
        url: photo,
      };
      setphoto();
      dispatch(addSong(data));
    } else {
      let data = {
        ...form,
        status: statusValues.id,
        composerId: composerIdValue.id,
        url: photo,
      };
      setphoto();
      dispatch(updateSong(data));
    }
    closeComposeDialog();
  }

  function canBeSubmitted() {
    return (
      form.songName.length > 0 && form.songDescriptionTr.length > 0 &&
      form.songDescriptionEn.length > 0 && form.songDescriptionDe.length > 0 &&
      form.songDescriptionAr.length > 0 && photo !== undefined
    );

  }
  return (
    <Dialog
      classes={{
        paper: "m-24 rounded-8",
      }}
      {...songDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="company-dialog-title">{t("songInfo")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="songId"
          name="songId"
          label={t("songId")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.songId}
          disabled
        />
        <TextField
          autoFocus
          margin="dense"
          id="songName"
          name="songName"
          label={t("songName")}
          type="text"
          fullWidth
          variant="outlined"
          value={form.songName}
          onChange={handleChange}
          required
          error={form.songName ? false : true}
          helperText={t("enterSongName")}
          focused
        />
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="songDescriptionTr"
                name="songDescriptionTr"
                label={t("songDescriptionTr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.songDescriptionTr}
                onChange={handleChange}
                required
                error={form.songDescriptionTr ? false : true}
                helperText={t("enterSongDescriptionTr")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="songDescriptionEn"
                name="songDescriptionEn"
                label={t("songDescriptionEn")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.songDescriptionEn}
                onChange={handleChange}
                required
                error={form.songDescriptionEn ? false : true}
                helperText={t("enterSongDescriptionEn")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="songDescriptionDe"
                name="songDescriptionDe"
                label={t("songDescriptionDe")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.songDescriptionDe}
                onChange={handleChange}
                required
                error={form.songDescriptionDe ? false : true}
                helperText={t("enterSongDescriptionDe")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // autoFocus
                margin="dense"
                id="songDescriptionAr"
                name="songDescriptionAr"
                label={t("songDescriptionAr")}
                type="text"
                fullWidth
                variant="outlined"
                value={form.songDescriptionAr}
                onChange={handleChange}
                required
                error={form.songDescriptionAr ? false : true}
                helperText={t("enterSongDescriptionAr")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <Grid container justifyContent={"center"} spacing={2} alignItems={"center"}>
          <Grid item xs={4}>
            <Autocomplete
              value={composerIdValue}
              options={composers}
              getOptionLabel={(option) => option.name}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              // fullWidth
              renderInput={(params) => (
                <TextField {...params} label={t("selectComposer")} />
              )}
              onChange={(event, newValue) => {
                if (typeof newValue.name === "string") {
                  setcomposerIdValue({
                    id: newValue.id,
                    name: newValue.name,
                  });
                } else if (newValue && newValue.inputValue) {
                  setcomposerIdValue({
                    name: newValue.inputValue,
                  });
                } else {
                  setcomposerIdValue(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              value={form.songCategoryData}
              options={categories}
              getOptionLabel={(option) =>
              ((i18n.language.match("tr") && option.categoryNameTr) ||
                (i18n.language.match("en")  && option.categoryNameEn) ||
                (i18n.language.match("de")  && option.categoryNameDe) ||
                (i18n.language.match("ar")  && option.categoryNameAr))
              }
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              // fullWidth
              renderInput={(params) => (
                <TextField {...params} label={t("selectCategories")} />
              )}
              onChange={(event, newValue) => {
                console.log("new", newValue);
                setInForm("songCategoryData", newValue);
              }}
            />
          </Grid>
          <Grid item xs={4} display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <FormControlLabel
              control={
                <Checkbox
                  name={"isTrial"}
                  id={"isTrial"}
                  checked={form.isTrial}
                  onChange={handleChange}
                />
              }
              label={t("isIncludeTrial")}
            />
            <FormHelperText>
              {t("makeChoice")}
            </FormHelperText>
          </Grid>

        </Grid>


        <MDBox mt={2}>
          <Autocomplete
            value={statusValues}
            options={statusOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("status")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue.name === "string") {
                setStatusValue({
                  id: newValue.id,
                  name: newValue.name,
                });
              } else if (newValue && newValue.inputValue) {
                setStatusValue({
                  name: newValue.inputValue,
                });
              } else {
                setStatusValue(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox mt={2}>
          <ImageUpload
            handleSetImage={handleSetImage}
            file={photo}
            url={form && form.url ? `${imageProxy}/${form.url}` : null}
          />
        </MDBox>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeComposeDialog} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!canBeSubmitted()}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SongDialog;
