import LoadingComponent from "components/Loading";
import AdminLayout from "pages/main/layout/AdminLayout";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getComposers } from "store/main/composersSlice";
import ComposerDialog from "./ComposerDialog";
import ComposersTable from "./ComposersTable";
import ConfirmDialog from "pages/confirmation/ConfirmDialog";
import { removeComposer } from "store/main/composersSlice";

const AdminComposersDashboard = (props) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(getComposers()).then(() => setDataLoading(false));
  }, [dispatch]);

  if (dataLoading) {
    return <LoadingComponent />;
  }
  return (
    <AdminLayout>
      <ComposerDialog />
      <ComposersTable />
      <ConfirmDialog handleDeleteData={removeComposer} />
    </AdminLayout>
  );
};

AdminComposersDashboard.propTypes = {};

export default AdminComposersDashboard;
