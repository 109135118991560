/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";


// Material Dashboard 2 PRO React page layout routes
import webPageRoutes from "routes/webPageRoutes";
import DefaultNavbar from "pages/webPages/navbar";
import { useTranslation } from "react-i18next";
import MainFooter from "../footer/MainFooter";
import LazyLoad from "react-lazyload";

function AuthCoverLayout({ coverHeight, image, children }) {

  const { i18n } = useTranslation();

  return (
    <MDBox>
      <MDBox sx={{ minHeight: "100vh", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
        <MDBox>
          <DefaultNavbar
            routes={webPageRoutes}
            action={{
              type: "external",
              route: "https://creative-tim.com/product/material-dashboard-pro-react",
              label: `${i18n.language}`,
              languages: ["tr", "en"]
            }}

          />
          <LazyLoad height={coverHeight} once>
            <MDBox
              width="calc(100% - 2rem)"
              minHeight={coverHeight}
              borderRadius="xl"
              mx={2}
              my={2}
              pt={6}
              pb={28}
              sx={{
                backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                  image &&
                  `${linearGradient(
                    rgba(gradients.dark.main, 0.4),
                    rgba(gradients.dark.state, 0.4)
                  )}, url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
          </LazyLoad>
          <MDBox sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", }} mt={{ xs: -20, lg: -18 }} width="calc(100% - 2rem)" mx="auto">
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={11} sm={10} md={7} lg={5} xl={4}>
                {children}
              </Grid>
            </Grid>

          </MDBox>

        </MDBox>


      </MDBox>
      <MainFooter />
    </MDBox>
  );
}

// Setting default props for the CoverLayout
AuthCoverLayout.defaultProps = {
  coverHeight: "35vh",
};

// Typechecking props for the CoverLayout
AuthCoverLayout.propTypes = {
  coverHeight: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AuthCoverLayout;
