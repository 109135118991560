import React, { useState } from 'react';
import { Box, TextField, Button, List, ListItem, ListItemText, Icon, Card, Autocomplete } from '@mui/material';
import MDButton from 'components/MDButton';
import { useForm } from 'features/hooks';
import { useTranslation } from 'react-i18next';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { useDispatch } from 'react-redux';
import { addLiveSupport } from 'store/main/liveSupportsSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const defaultFormState = {
  supportId: 0,
  supportHeader: "",
};

const feedbackOptions = [
  { id: "problem", name: "problem" },
  { id: "suggestion", name: "suggestion" },
  { id: "complaint", name: "complaint" },
  { id: "general", name: "general" },
]



const NewConversation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userName } = useSelector((state) => state.auth);

  const [feedbackValues, setFeedbackValue] = useState({
    id: "problem",
    name: "problem",
  })

  const { form, handleChange, } = useForm(defaultFormState);
  const { t } = useTranslation();

  const handleSubmit = () => {
    let data = {
      ...form,
      userName,
      liveSupportType: feedbackValues.name,
      sentFrom: "user",
      
    }
    console.log(data);
    dispatch(addLiveSupport(data)).then((params) => {
      console.log(params);
      navigate(`${t("supportRoute")}/${t(params.payload.liveSupportId)}`);
    })
  }

  return (
    <Card sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 4, }}>
      <MDTypography variant="h4">Yeni Canlı Destek Başlat</MDTypography>
      <TextField
        variant="outlined"
        type="text"
        label={"Başlık"}
        fullWidth
        value={form.supportHeader}
        onChange={handleChange}
        id="supportHeader"
        name="supportHeader"
      />
      <MDBox sx={{ mt: 1 }}>
        <Autocomplete
          value={feedbackValues}
          options={feedbackOptions}
          getOptionLabel={(option) => t(option.name)}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          // fullWidth
          renderInput={(params) => (
            <TextField {...params} label={"Destek Türü"} />
          )}
          onChange={(event, newValue) => {
            if (typeof newValue.name === "string") {
              setFeedbackValue({
                id: newValue.id,
                name: newValue.name,
              });
            } else if (newValue && newValue.inputValue) {
              setFeedbackValue({
                name: newValue.inputValue,
              });
            } else {
              setFeedbackValue(newValue);
            }
          }}
        />
      </MDBox>
      <MDButton onClick={handleSubmit} variant="gradient" color="info">
        Yeni Canlı Destek Başlat <Icon sx={{ ml: 1 }}>add_circle_outlined</Icon>
      </MDButton>
    </Card>
  );
};

export default NewConversation;