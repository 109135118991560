import * as React from 'react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { Card, Divider, Fade, Icon } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setVideoData } from 'store/main/videoPlayerSlice';
import { setControlsData } from 'store/main/videoPlayerSlice';
import { useTranslation } from 'react-i18next';

const quailities = [
  { icon: "2k", text: "1440p", quality: "2K", },
  { icon: "hd", text: "1080p", quality: "HD", },
  { icon: "sd", text: "720p", quality: "SD", },
  { icon: "sd", text: "Auto", quality: "AUTO" }
]

export default function QualityMenu() {
  const dispatch = useDispatch();
  const { videoData, controlsData } = useSelector((state) => state.videoPlayer);

  const { t } = useTranslation();

  const handleChangeQuality = (quality) => {
    dispatch(setVideoData({ ...videoData, quality, qualityChanged: true }));
    localStorage.setItem("quality", quality);
  }

  const handleBack = () => {
    dispatch(setControlsData({ ...controlsData, showQuality: false, showMenu: true }));
  }

  return (
    <Fade in={controlsData.showQuality} style={{ transitionDuration: "1000ms" }}>
      <Card sx={{ marginBottom: 4 }}>
        <MenuList className="QualityMenu" sx={{ padding: 2, }}>
          <MenuItem
            className='QualityBack'
            onClick={handleBack}
            sx={{
              // background: videoData.quality === item.quality && "#f0f2f5",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <ListItemIcon>
              <Icon fontSize="medium">arrow_back</Icon>
            </ListItemIcon>


            <Typography className='QualityBackButton' fontWeight={"500"} color="text.secondary">
              {t("Quality")}
            </Typography>
          </MenuItem>

          <Divider />
          {
            quailities.map((item, key) => {
              return (
                <MenuItem
                  onClick={() => handleChangeQuality(item.quality)}
                  key={key}
                  sx={{
                    background: videoData.quality === item.quality && "#f0f2f5",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <ListItemIcon>
                    <Icon fontSize="medium" >{item.icon}</Icon>
                  </ListItemIcon>


                  <Typography fontWeight={"500"} color="text.secondary">
                    {item.text}
                  </Typography>
                </MenuItem>
              )
            })
          }
        </MenuList>
      </Card>
    </Fade>
  );
}