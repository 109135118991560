import { AppBar, Card, Container, Grid, Tab, Tabs } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography';
import { useMaterialUIController } from 'context';
import CoverLayout from 'pages/main/layout/CoverLayout';
import LandingLayout from 'pages/main/layout/LandingLayout';
import PricingCard from 'pages/students/payment/newSubscription/PricingPlansInfo/PricingCard';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { selectPricingPlans } from "store/main/pricingPlansSlice";

function Pricing() {

  const { country_code } = useSelector((state) => state.location);
  const { t } = useTranslation();
  const pricingPlans = useSelector(selectPricingPlans);

  const [filteredPlans, setFilteredPlans] = useState([]);
  const [tabValue, setTabValue] = useState(1);
  const [paymentInterval, setPaymentInterval] = useState("MONTHLY");
  const [priceType, setPriceType] = useState("monthlyInterval");

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    const plans = pricingPlans.filter((f) => {
      console.log(f);
      if (paymentInterval === "TRIAL") {
        return (f.paymentInterval === "TRIAL");
      }
      else if (country_code === "TR") {
        return (
          f.countryISO === "TR" && f.forStudent === false && f.payPlatform === "iyzico"
        )
      }

      return (f.countryISO === "DE" && f.forStudent === false && f.payPlatform === "paypal")
    });

    setFilteredPlans(plans);
  }, [paymentInterval, pricingPlans, country_code]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "trial") {
      setPaymentInterval("TRIAL");
      setPriceType("trialInterval");
    }
    else {
      setPaymentInterval("MONTHLY");
      setPriceType("monthlyInterval");
    }
  };


  return (
    <LandingLayout>
      <Container>
        <MDBox width={"100%"} paddingX={2} paddingY={4}>
          <Grid
            width={"full"}
            container
            spacing={{ xs: 6, md: 3 }}
            justifyContent="center"
            sx={{ textAlign: "center" }}
          >
            <Grid textAlign={"center"} item xs={12}>
              <MDBox mb={1}>
                <MDTypography
                  fontSize={{ xs: "1.728rem", lg: "2.074rem", xl: "2.488rem" }}
                  fontWeight="bold">
                  {t("pricing_header")}
                </MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography
                  fontSize={{ xs: "1rem", lg: "1.20rem", xl: "1.44rem" }}
                  fontWeight="light"
                >
                  {t("pricing_description")}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid width={"100%"} container justifyContent={"center"} mt={4} mb={8}>
            <Grid item xs={8}>
              <AppBar position="static">
                <Tabs value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    id="trial"
                    label={
                      <MDTypography fontSize={{ xs: "0.833rem", lg: "1rem", xl: "1.20rem" }} py={0.5} px={2} color="inherit">
                        {t("trial")}
                      </MDTypography>
                    }
                  />
                  <Tab
                    id="paid"
                    label={
                      <MDTypography fontSize={{ xs: "0.833rem", lg: "1rem", xl: "1.20rem" }} py={0.5} px={2} color="inherit">
                        {t("paid")}
                      </MDTypography>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>



          <Grid container spacing={3} justifyContent="center">

            {
              filteredPlans.map((item, key) => {
                return (
                  <Grid key={key} item xs={12} lg={4}>
                    <PricingCard
                      color={"dark"}
                      badge={{ color: darkMode ? "warning" : "light", label: item.pricingPlanNameTr }}
                      price={{
                        currency: (
                          (item.currencyISO === "EUR" && "€") ||
                          (item.currencyISO === "TRY" && "₺") ||
                          (item.paymentInterval === "TRIAL" && country_code === "US" && "€") ||
                          (item.paymentInterval === "TRIAL" && country_code === "TR" && "₺")
                        ),
                        value: item.price,
                        type: t(priceType),
                        count: item.paymentIntervalCount,

                      }}
                      specifications={[
                        { label: t("feature1"), includes: item.feature1 },
                        { label: t("feature2"), includes: item.feature2 },
                        { label: t("feature3"), includes: item.feature3 },
                        { label: t("feature4"), includes: item.feature4 },
                        { label: t("feature5"), includes: item.feature5 },
                        { label: t("feature6"), includes: item.feature6 },
                        { label: t("feature7"), includes: item.feature7 },
                        { label: t("feature8"), includes: item.feature7 },
                        { label: t("feature9"), includes: item.feature7 },
                      ]}

                      pricingPlanReferenceCode={item.pricingPlanReference}
                      pricingPlanId={item.pricingPlanId}
                      button={{
                        color: "info",
                        label: t("subscribe"),
                        action: "link",
                        href: "/sign-up",
                      }}
                      shadow={darkMode}
                      isTrial={paymentInterval === "TRIAL" ? true : false}
                    />
                  </Grid>
                )
              })
            }
          </Grid>
        </MDBox>

      </Container>
    </LandingLayout >
  )
}

export default Pricing