import MDButton from 'components/MDButton';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { initializeCheckoutForm } from 'store/iyzico/iyzicoSubscriptionsSlice';
import MDBox from 'components/MDBox';
import LoadingComponent from 'components/Loading';
import { checkSubscription } from 'store/payment/paymentsSlice';
import { useTranslation } from 'react-i18next';

function CheckoutForm({ handleBack }) {
  const dispatch = useDispatch();

  const { userInfo, isSubscribed } = useSelector((state) => state.subscriptions);
  const { userName } = useSelector((state) => state.auth);

  const [htmlContent, setHtmlContent] = useState(null);
  const [showHtmlContent, setShowHtmlContent] = useState(false);

  const { t } = useTranslation();

  const handleBackStep = () => {
    setShowHtmlContent(false);
    handleBack(1);
  };

  useEffect(() => {
    dispatch(initializeCheckoutForm(userInfo))
      .then((params) => {
        console.log(params);
        const content = params.payload.checkoutFormContent + '<div id="iyzipay-checkout-form" className="responsive"></div>';
        setHtmlContent(content);
      })
      .then(() => setShowHtmlContent(true));
  }, [userInfo, dispatch, userName]);

  // useEffect(() => {
  //   let intervalId;

  //   if (!isSubscribed) {
  //     intervalId = setInterval(() => {
  //       try {
  //         dispatch(checkSubscription({ userName }))
  //       } catch (error) {
  //         console.log(error)
  //       }
  //     }, 5000);
  //   }

  //   // useEffect içinde bir temizleme fonksiyonu tanımlayın
  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [dispatch, userName, isSubscribed]);

  if (!setHtmlContent) {
    return <LoadingComponent />
  }

  return (
    <MDBox display={"flex"} flexDirection={"column"}>
      {
        showHtmlContent && htmlContent &&
        <iframe title={"newSubscriptionIframe"} style={{ border: "none", borderRadius: "8px" }} width={"100%"} height={"950px"} srcDoc={htmlContent} />
      }

      <MDBox>
        <MDButton variant="outlined" color="dark" onClick={handleBackStep}>
          {t("goBack")}
        </MDButton>
      </MDBox>
    </MDBox>
  );
};

export default CheckoutForm;