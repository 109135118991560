import AdminLayout from 'pages/main/layout/AdminLayout'
import React from 'react'
import IyzicoPricingPlanTable from './IyzicoPricingPlanTable'
import IyzicoPricingPlanDialog from './IyzicoPricingPlanDialog'
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { getProducts } from 'store/iyzico/iyzicoProductsSlice';
import LoadingComponent from 'components/Loading';

function IyzicoPricingPlanDahsboard() {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    dispatch(getProducts()).then(() => setDataLoading(false));
  }, [dispatch]);
  if (dataLoading) {
    return <LoadingComponent />
  }

  return (
    <AdminLayout>
      <IyzicoPricingPlanTable />
      <IyzicoPricingPlanDialog />
    </AdminLayout>
  )
}

export default IyzicoPricingPlanDahsboard